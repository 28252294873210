import { Term } from './term'

export enum INPUT_TYPES {
  INPUT,
  SELECT,
}

export type SettingRule = {
  field: number
  value: number | string | null
  value_text: string
  unique_key: string | number
  type?: INPUT_TYPES
}

export type AutoGenerateSetting = {
  id: number
  from_term_id: number
  from_term?: Term
  to_term_id?: number
  to_term?: Term
  generating_method: number
  generating_scope: number
  length_rule: number
  rules: SettingRule[]
}

type AssetCategorySetting = {
  asset_category_code: string
  asset_category_id: number
  asset_category_name: string
  initial_value: number
  is_editable: boolean
}

export type InitialNumberSetting = {
  initial_value: number
  is_editable: boolean
  is_whole_office: boolean
  items: AssetCategorySetting[]
}
