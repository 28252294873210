import { Checkbox } from 'aa_common/front-end/antd'
import { getNs } from 'common/i18n-config'
import { TableColumn } from 'common/models'
import { setSelectedItem } from 'containers/accounting/depreciation-fixed-asset/store'
import React from 'react'
import { Link } from 'react-router-dom'

const { t } = getNs(['pages/accounting/depreciation-fixed-asset-page'])

const FIELD_NAMES = {
  CHECKBOX: 'checkbox',
  SUBMIT_DESTINATION_CODE: 'submit_destination_code',
  SUBMIT_DESTINATION_NAME: 'submit_destination_name',
  APPLY_DESTINATION: 'apply_destination',
  TOTAL_EXPORT: 'total_export',
}

export const getDepreciationFixedAssetColumns = (isAllowedForMultiExportTaxDeclaration: boolean): TableColumn[] => [
  ...(isAllowedForMultiExportTaxDeclaration
    ? [
        {
          field: FIELD_NAMES.CHECKBOX,
          sortable: false,
        },
      ]
    : [{ field: '', sortable: false }]),
  {
    field: FIELD_NAMES.SUBMIT_DESTINATION_CODE,
    text: t('columns.code'),
    sortable: false,
  },
  {
    field: FIELD_NAMES.SUBMIT_DESTINATION_NAME,
    text: t('columns.submit_destination_name'),
    sortable: false,
  },
  {
    field: FIELD_NAMES.APPLY_DESTINATION,
    text: t('columns.apply_destination'),
    sortable: false,
  },
  {
    field: FIELD_NAMES.TOTAL_EXPORT,
    text: t('columns.output_history'),
    sortable: false,
  },
]

export const convertDataToDisplay = (
  data: any,
  taxYear: number,
  openHistoryModal: (id: number, name: string) => void,
  isAllowedForMultiExportTaxDeclaration: boolean
): any => {
  const columns = getDepreciationFixedAssetColumns(isAllowedForMultiExportTaxDeclaration)

  return (
    data &&
    data.map((dataItem: any) => {
      const displayItem: any = { id: dataItem.id }
      const submitDestinationId = dataItem?.submit_destination_id
      const submitDestinationName = dataItem?.submit_destination_name

      columns.forEach(({ field }: { field: string }) => {
        switch (field) {
          case FIELD_NAMES.SUBMIT_DESTINATION_NAME:
            displayItem[field] = (
              <Link
                to={{
                  pathname: `/tax-declaration/depreciation-fixed-asset/${submitDestinationId}/${taxYear}`,
                  state: { submitDestinationId, submitDestinationName },
                }}
                className="link"
              >
                {dataItem[field]}
              </Link>
            )
            break
          case FIELD_NAMES.TOTAL_EXPORT:
            displayItem[field] = (
              <span
                onClick={() => {
                  dataItem[field] > 0 && openHistoryModal(submitDestinationId, submitDestinationName)
                }}
                className={dataItem[field] === 0 ? '' : 'link'}
              >
                {dataItem[field]}回
              </span>
            )
            break
          case FIELD_NAMES.CHECKBOX:
            displayItem[field] = (
              <Checkbox
                name={`report-${dataItem.submit_destination_id}`}
                checked={dataItem.isSelected}
                onChange={({ checked }) =>
                  setSelectedItem({ submitDestinationId: dataItem.submit_destination_id, isSelected: checked })
                }
              />
            )
            break
          default:
            displayItem[field] = dataItem[field]
            break
        }
      })

      return displayItem
    })
  )
}
