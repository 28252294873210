import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { defaultTo } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { ImportExportButton } from 'components/molecules'
import React from 'react'

interface Props {
  onClickExport: () => void
  terms: any[]
  currentTermYearId: number
  onChangeTerm: (term: any) => void
}

const t = loadNs(['common', 'components/organisms/accounting/under-over-depreciation-report'])
const parseTerms = (terms: any[]) => {
  return defaultTo(
    terms?.map(term => {
      return {
        value: term.id,
        text: `${t('format.year_term', { year: term.year, term: term.period })}`,
      }
    }),
    []
  )
}

const Form: React.FC<Props> = ({ onClickExport, terms, currentTermYearId, onChangeTerm }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label>{t('fiscal_year')}</label>
        <AutoCompleteSelect
          defaultValue={currentTermYearId}
          name="term_id"
          items={parseTerms(terms)}
          valueField="value"
          textField="text"
          style={{ width: 150, marginLeft: 10 }}
          allowClear={false}
          onChange={onChangeTerm}
        />
      </div>
      <ImportExportButton type="export" onClick={onClickExport} />
    </div>
  )
}

export default Form
