export interface ValueFields {
  code: string
  type: string
  content: string
  search_key: string
}

export const validateForm = (values: ValueFields) => {
  const { code, type, content } = values

  return code?.trim() && type && content?.trim()
}
