import styled from '@emotion/styled'

export const MoneyAmountWrapper = styled.div`
  max-width: 270px;
  padding: 10px;
`

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-top: 0 !important;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const Field = styled.div`
  margin-bottom: 25px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Label = styled.label`
  font-weight: bold;
`
