import Form from 'aa_common/front-end/components/form'

export const Row = ({ source, destination, type }: any) => {
  const { FormField, Input, InputNumber } = Form

  return (
    <div className="row">
      <FormField className="source-item" title={source.title}>
        <Input {...source.props} />
      </FormField>
      <FormField title="" required={destination.props.required || false} className="destination-item">
        {type === 'number' ? <InputNumber {...destination.props} /> : <Input {...destination.props} />}
      </FormField>
    </div>
  )
}
