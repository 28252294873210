import React from 'react'

import { Icon } from './styles'

const SortBarIcon = ({ className, ...rest }: { className?: string }) => {
  return (
    <Icon className={className} {...rest}>
      <div />
      <div />
    </Icon>
  )
}

export default SortBarIcon
