import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, Skeleton } from 'antd'
import i18n from 'i18next'
import uniqueId from 'lodash/uniqueId'
import React, { ReactNode } from 'react'

import { BasicTable } from '../basic-table'
import { MenuWrapper, overlayDropdownClass, Title, Wrapper } from './styles'

const columns = [
  {
    text: i18n.t('components.DropdownToggle.accounting'),
    field: 'accounting',
    sortable: false,
  },
  {
    text: i18n.t('components.DropdownToggle.human_resource'),
    field: 'human_resource',
    sortable: false,
  },
  {
    text: i18n.t('components.DropdownToggle.relavant_services'),
    field: 'relavant_services',
    sortable: false,
  },
]

interface BaseDropdownItem {
  text: string
  value: string
}

interface BaseDropdownProps {
  items?: any[]
  onSelectedItem: (item: BaseDropdownItem) => void
  dropdownMenuStyle?: string | React.CSSProperties
  isMenuService?: boolean
}

interface DropdownToggleProps extends BaseDropdownProps {
  label: string | ReactNode
  name: string
  loading?: boolean
  faSortDownOption?: boolean
  dropdownMenuStyle: string | React.CSSProperties
}

const buildList = (
  items: any[],
  dropdownMenuStyle: React.CSSProperties | string,
  onSelectedItem: (item: BaseDropdownItem) => void
) => {
  return (
    <MenuWrapper className={dropdownMenuStyle as string}>
      {items.map(item => (
        <Menu.Item className="dropdown-item" key={uniqueId('menu-item')} onClick={() => onSelectedItem(item)}>
          <span>{item.text}</span>
        </Menu.Item>
      ))}
    </MenuWrapper>
  )
}

const buildMenuSerivceList = (items: any[], dropdownMenuStyle: string) => {
  return (
    <MenuWrapper className={dropdownMenuStyle}>
      <BasicTable columns={columns} columnsWidth={[220, 200, 120]} data={items} isRenderLastColumn={false} />
    </MenuWrapper>
  )
}

export const DropdownToggle = ({
  name,
  label,
  items = [],
  loading,
  onSelectedItem,
  faSortDownOption = true,
  dropdownMenuStyle,
  isMenuService = false,
}: DropdownToggleProps) => {
  if (!isMenuService)
    return (
      <Wrapper className={`dropdown-toggle-${name}`}>
        <Dropdown
          overlayClassName={overlayDropdownClass}
          disabled={loading}
          placement="bottomRight"
          overlay={buildList(items, dropdownMenuStyle as string, onSelectedItem)}
          trigger={['click']}
        >
          <Title>
            {!loading ? (
              <>
                <div>{label}</div>
                {faSortDownOption && <FontAwesomeIcon icon={faSortDown} />}
              </>
            ) : (
              <div hidden={!loading}>
                <Skeleton.Button style={{ maxHeight: 18 }} active={loading} shape="square" />
              </div>
            )}
          </Title>
        </Dropdown>
      </Wrapper>
    )
  return (
    <Wrapper className={`dropdown-toggle-${name}`}>
      <Dropdown
        overlayClassName={overlayDropdownClass}
        disabled={loading}
        placement="bottomRight"
        overlay={buildMenuSerivceList(items, dropdownMenuStyle as string)}
        trigger={['click']}
      >
        <Title>
          {!loading ? (
            <>
              <div>{label}</div>
              {faSortDownOption && <FontAwesomeIcon icon={faSortDown} />}
            </>
          ) : (
            <div hidden={!loading}>
              <Skeleton.Button style={{ maxHeight: 18 }} active={loading} shape="square" />
            </div>
          )}
        </Title>
      </Dropdown>
    </Wrapper>
  )
}

export default DropdownToggle
