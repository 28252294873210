import { loadNs } from 'common/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'
import { Row } from './row'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const DepreciationSettingTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('depreciation_settings_table')}>
      <tbody>
        <Row
          title={t('depreciation')}
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            { name: 'depreciation_debit_account_item.name' },
            { name: 'depreciation_debit_account_sub_item.name' },
            { name: '', isThead: true, title: t('accumulated') },
            { name: 'depreciation_credit_accumulated_account_item.name' },
            { name: 'depreciation_credit_accumulated_account_sub_item.name' },
          ]}
        />
        <Row
          title=""
          item={item}
          fields={[
            { name: '', colspan: 2 },
            { name: '', isThead: true, title: t('asset_category') },
            { name: 'depreciation_credit_asset_category_account_item.name' },
            { name: 'depreciation_credit_asset_category_account_sub_item.name' },
          ]}
          render={renderAccountNameFieldValue}
        />
      </tbody>
    </JournalItemSettingTable>
  )
}

export default DepreciationSettingTableView
