import { loadNs } from 'common/i18n-config'
import { MoneyUnits } from 'common/models'

const t = loadNs(['common', 'pages/accounting/increase-decrease-report'])

export enum ExportByUnit {
  ASSET_UNIT = 2,
  ASSET_CATEGORY = 1,
}

export const getConstant = () => {
  const EXPORT_BY_UNIT_OPTIONS = [
    { label: t('export_by_asset_unit'), value: ExportByUnit.ASSET_UNIT },
    {
      label: t('export_by_asset_category'),
      value: ExportByUnit.ASSET_CATEGORY,
    },
  ]

  const MONEY_UNIT_OPTIONS = [
    {
      label: t('units.yen'),
      value: MoneyUnits.YEN,
    },
    {
      label: t('units.thousand_yen'),
      value: MoneyUnits.THOUSAND_YEN,
    },
  ]

  return {
    EXPORT_BY_UNIT_OPTIONS,
    MONEY_UNIT_OPTIONS,
  }
}
