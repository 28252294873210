import styled from '@emotion/styled'

export const SvgFormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    width: 100%;

    div,
    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 600;

      &[data-id='year'] {
        text-align: center;
        font-size: 16px;
        line-height: 0.7em;
      }
    }

    [data-furigana] {
      position: relative;
      display: inline-flex;
      align-items: center;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: attr(data-furigana) ' ';
        font-size: 0.7em;
        line-height: 1em;
        width: 1000vw;
      }
    }

    g[id^='submit_destination'] {
      font-size: 11px;
      line-height: 1em;

      div[data-id$='_kana'] {
        font-size: 9px;
      }

      div[data-id='submit_destination.apply_destination'],
      div[data-id='submit_destination.owner_address'],
      div[data-id='submit_destination.company_name'],
      div[data-id='submit_destination.industry_category'],
      div[data-id='submit_destination.owner_code'] {
        font-size: 13px;
      }

      div[data-id='submit_destination.owner_address'] {
        line-height: 1.1em;
      }

      div[data-id='submit_destination.company_name'] {
        &::before {
          line-height: 0;
          top: 5px;
        }
      }

      div[data-id='submit_destination.representative_position'] {
        white-space: nowrap;
        span {
          margin-left: 0.5em;
        }
      }

      g[id^='corporate_code'] {
        div {
          font-size: 14px;
          line-height: 1.2em;
          text-align: center;
        }
      }

      div[data-id='submit_destination.industry_category'],
      div[data-id='submit_destination.tax_accountant_name'] {
        line-height: 1.3em;
        text-align: center;
      }

      div[data-id='submit_destination.capital_amount'],
      div[data-id='submit_destination.company_established_at_year'],
      div[data-id='submit_destination.company_established_at_month'] {
        text-align: right;
      }

      div[data-id='submit_destination.person_in_charge_department_and_name'] {
        padding: 2px;
      }

      div[data-id^='submit_destination.asset_location_'] {
        line-height: 1.5em;
      }

      div[data-id='submit_destination.remark'] {
        font-size: 12px;
        line-height: 1.55em;
      }
    }

    #acquisition_cost_ {
      div {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.2em;
        text-align: right;
        padding-right: 2px;
      }
    }

    .text-center {
      text-align: center;
    }
  }
`
