import { ExportUnderOverDepreciationReportRequest } from './action.model'
import { EXPORT_UNDER_OVER_DEPRECIATION_REPORT_REQUEST } from './constant'
import { ExportUnderOverDepreciationReportPayload } from './model'

export const exportUnderOverDepreciationReport = (
  payload: ExportUnderOverDepreciationReportPayload
): ExportUnderOverDepreciationReportRequest => ({
  type: EXPORT_UNDER_OVER_DEPRECIATION_REPORT_REQUEST,
  payload,
})
