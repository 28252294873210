import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Menu } from 'antd'

export const Wrapper = styled.div`
  font-size: 13px;
  white-space: nowrap;
  display: inline-block;
  .ant-dropdown-trigger {
    align-items: baseline;
    margin-top: 4px;
  }

  .dropdown-item {
    span {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const Title = styled.div`
  display: flex;
  cursor: pointer;
  div {
    & + svg {
      color: #7c8291;
      margin-left: 8px;
      margin-top: 1px;
    }
  }
`

export const MenuWrapper = styled(Menu)`
  min-width: 160px;
`

export const overlayDropdownClass = css`
  position: fixed;
  top: 42px !important;
  margin-bottom: 5px;

  .ant-dropdown-menu {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
  }
  .ant-dropdown-menu-item {
    padding: 7px 12px;
    border-bottom: 1px solid #d6d6d6;
    &:last-child {
      border-bottom: none;
    }
  }
`
