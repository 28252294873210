import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import Input from 'components/atoms/v2/Input'
import { RenderFormProps } from 'components/molecules/Form'
import FilterPanel from 'components/molecules/v2/FilterPanel'
import i18n from 'i18n'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOfficeUserList } from 'store/settings/officeUser/actions'
import { selectorOfficeUserList } from 'store/settings/officeUser/selectors'

const { Row, Field, Label } = FilterPanel

type OperationLogFilterProps = {
  onSubmit?: (values: any) => void
}

const initialFormValues = {
  user: null,
  cached_fixed_asset_name: '',
  cached_fixed_asset_code: '',
}

const OperationLogFilterForm = ({ officeUser, formModule }: { officeUser: any[]; formModule: RenderFormProps }) => {
  return (
    <Row>
      <Field>
        <Label>{i18n.t('components.OperationLogFilter.user')}</Label>
        <AutoCompleteSelect
          name="user"
          items={officeUser}
          textField="name"
          valueField="user_id"
          control={formModule.control}
          disabled={formModule.loading}
          placeholder={i18n.t('components.OperationLogFilter.no_specification')}
        />
      </Field>
      <Field>
        <Label>{i18n.t('components.OperationLogFilter.asset_name')}</Label>
        <Input
          name="cached_fixed_asset_name"
          control={formModule.control}
          disabled={formModule.loading}
          maxLength={50}
        />
      </Field>
      <Field>
        <Label>{i18n.t('components.OperationLogFilter.asset_code')}</Label>
        <Input
          name="cached_fixed_asset_code"
          control={formModule.control}
          disabled={formModule.loading}
          maxLength={15}
        />
      </Field>
    </Row>
  )
}

const OperationLogFilter = ({ onSubmit }: OperationLogFilterProps) => {
  const dispatch = useDispatch()
  const { data: officeUser } = useSelector(selectorOfficeUserList)

  useEffect(() => {
    dispatch(fetchOfficeUserList())
  }, []) // eslint-disable-line

  const handleOnSubmit = (payload: any) => {
    onSubmit && onSubmit(payload)
  }

  return (
    <FilterPanel
      initialValues={initialFormValues}
      onSubmit={handleOnSubmit}
      renderForm={form => {
        return <OperationLogFilterForm officeUser={officeUser} formModule={form} />
      }}
    />
  )
}

export default OperationLogFilter
