import { RightDrawer } from 'aa_common/front-end/components'
import * as assetApi from 'api/app/asset'
import * as exciseApi from 'api/app/excise'
import { CAUSE_TYPES, DATE_FORMAT_RFC, EXCISE_TAX_INPUT_FORMAT, EXCISE_TAX_METHOD } from 'common/constants'
import { updateObjectValues } from 'common/helpers/object-helper'
import { loadNs } from 'common/i18n-config'
import { Asset, Excise, FORM_MODES } from 'common/models'
import { BasicForm } from 'components/molecules'
import useResource from 'lib/hooks/useResource'
import { get, isEqual } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseList } from 'store/settings/excise/actions'
import { selectExciseList } from 'store/settings/excise/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { getDepreciationEndedMethods } from '../AssetRetirementForm/helper'
import { useExpandLedgersHook } from '../events/ExpandableLedger'
import Form from './Form'

const t = loadNs(['components/organisms/assets/asset-event-sell-form', 'common'])

type Props = {
  errors?: any
  causes: any[]
  isShow?: boolean
  loading?: boolean
  latestEvent: any
  data?: Asset
  formMode?: FORM_MODES
  onClose?: (e: any) => void
  onSubmit?: (data: any) => void
  onHandleReloadCauses?: () => void
}

const initialDefaultValues = {
  sold_at: null,
  event_cause_id: null,
  sold_amount: 0,
  sold_amount_input: 0,
  sold_amount_excluded_excise: null,
  sold_excise_id: null,
  calculated_sold_excise_value: null,
  manual_sold_excise_value: null,
  sold_destination_name: '',
  disposal_cost: 0,
  disposal_cost_input: 0,
  disposal_cost_excluded_excise: null,
  calculated_disposal_excise_value: null,
  manual_disposal_excise_value: null,
  disposal_excise_id: null,
  disposal_destination_name: '',
  event_photos: [],
  event_attachments: [],
  event_urls: [],
}

const AssetEventSellForm: React.FC<Props> = ({
  isShow = false,
  loading = false,
  formMode = FORM_MODES.NEW,
  errors,
  causes,
  latestEvent,
  data,
  onClose,
  onSubmit,
  onHandleReloadCauses,
}) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(isShow)
  const ledgerSettings = useSelector(selectLedgerSettingList, isEqual)
  const exciseList = useSelector<any, Excise[]>(selectExciseList)
  const [sellCauses, setSellCauses] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<any>(null)
  const [previousAssetResponse, getPreviousAssetOfId] = useResource(assetApi.getPreviousOfId)
  const [exciseSettingResponse, getExciseSettingByDate] = useResource(exciseApi.getExciseSettingsByDate)

  const handleSubmit = (values: any) => {
    onSubmit && onSubmit(values)
  }

  const getDefaultSoldAt = () => {
    const validFrom = get(latestEvent, 'valid_from')
    const today = moment().format(DATE_FORMAT_RFC)

    if (validFrom > today) {
      return validFrom
    }

    return today
  }

  useEffect(() => {
    const filteredCauses = causes.filter(cause => get(cause, 'type') === CAUSE_TYPES.SELL)
    setSellCauses(filteredCauses)
  }, [causes])

  useEffect(() => {
    setVisible(isShow)

    if (isShow && ledgerSettings) {
      const defaultSoldAt = getDefaultSoldAt()

      if (latestEvent && formMode === FORM_MODES.NEW) {
        setInitialValues({
          ...initialDefaultValues,
          biid: get(latestEvent, 'biid'),
          min_date: get(latestEvent, 'valid_from'),
          sold_at: defaultSoldAt,
        })
      } else if (data && previousAssetResponse.data && exciseSettingResponse.data) {
        const objectValues = updateObjectValues(initialDefaultValues, data)
        const isTaxMethodExcluded = get(exciseSettingResponse.data, 'tax_method') === EXCISE_TAX_METHOD.EXCLUDED_TAX
        const isTaxInputFormatExternalTax =
          get(exciseSettingResponse.data, 'tax_input_format') === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX

        let soldAmount = data.sold_amount
        let disposalCost = data.disposal_cost
        if (isTaxMethodExcluded && isTaxInputFormatExternalTax) {
          soldAmount = data.sold_amount_excluded_excise as number
          disposalCost = data.disposal_cost_excluded_excise as number
        }

        setInitialValues({
          ...objectValues,
          calculated_sold_excise_value:
            objectValues.manual_sold_excise_value ?? objectValues.calculated_sold_excise_value,
          calculated_disposal_excise_value:
            objectValues.manual_disposal_excise_value ?? objectValues.calculated_disposal_excise_value,
          id: latestEvent.id,
          biid: latestEvent.biid,
          min_date: get(previousAssetResponse.data, 'valid_from'),
          sold_at: get(latestEvent, 'valid_from'),
          event_cause_id: get(latestEvent, ['event_cause', 'id']),
          retirement_fixed_asset_ledger_requests: getDepreciationEndedMethods(ledgerSettings, data.fixed_asset_ledgers),
          sold_amount_input: soldAmount || 0,
          disposal_cost_input: disposalCost || 0,
        })
      }
    }
  }, [isShow, ledgerSettings, previousAssetResponse.data, exciseSettingResponse.data]) // eslint-disable-line

  useEffect(() => {
    const filters = { is_active: true }

    !ledgerSettings && dispatch(fetchLedgerSettingsList())
    !exciseList?.length && dispatch(fetchExciseList({ filters }))
    data?.sold_at && getExciseSettingByDate(data.sold_at)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (data) {
      const biid = get(data, 'biid')
      const id = get(data, 'id')

      getPreviousAssetOfId(biid, id)
    }
  }, [data]) // eslint-disable-line

  const { width, className, renderer } = useExpandLedgersHook()
  return (
    <RightDrawer title={t('sale')} visible={visible} onClose={onClose} width={width} className={className}>
      {ledgerSettings && (
        <BasicForm
          initialValues={initialValues}
          errors={errors}
          loading={loading}
          preventSubmitOnEnter
          onSubmit={handleSubmit}
        >
          <Form
            causes={sellCauses}
            formMode={formMode}
            ledgerSettings={ledgerSettings}
            onClose={onClose}
            onHandleReloadCauses={onHandleReloadCauses}
            renderDrawerExpandToggle={renderer}
          />
        </BasicForm>
      )}
    </RightDrawer>
  )
}

export default AssetEventSellForm
