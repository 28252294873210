import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: relative;

  .ant-collapse {
    background: none;
    border: none;

    .ant-collapse-item-disabled {
      & > .ant-collapse-header {
        cursor: initial;
        padding-left: 10px;
      }
    }
  }

  .ant-collapse-item {
    border: none;

    .ant-collapse-header {
      color: #333333;
      font-size: 20px;
      font-weight: 700;
      padding: 20px 8px;
    }

    .ant-collapse-content {
      border: none;
      background: none;
    }

    .ant-collapse-content-box {
      padding: 0px;
      display: flex;
      border: none;

      .content {
        width: 100%;
      }

      .action {
        display: flex;
        width: 44px;
        border-left: 1px solid #d4d8dd;
        color: #7c8291;

        .edit-icon {
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
`

export const LeftGroup = styled.div`
  float: left;
`

export const RightGroup = styled.div`
  float: right;
`

export const RightTitle = styled.div<{ collapsable: boolean }>`
  position: absolute;
  top: 20px;
  left: 550px;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  cursor: ${props => (props.collapsable ? 'pointer' : 'initial')};
`

export const ItemWrapper = styled.div`
  align-items: flex-start;
  background-color: none;
  box-sizing: border-box;
  color: #333333;
  display: flex;
  min-height: 48px;
  padding-bottom: 15px;
  margin-left: 10px;
  width: 416px;
`

export const ItemLabel = styled.div`
  min-width: 180px;
  font-weight: 500;
  margin-top: 4px;
  display: flex;
  align-items: center;
`

export const ItemContent = styled.div`
  position: relative;

  .required {
    left: -40px;
    position: absolute;
    top: 5px;
  }

  .ant-input-number {
    height: 30px;
  }

  .value-view.error {
    color: #dc3545;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;

  .ant-btn:last-of-type {
    margin-left: 10px;
  }
`
