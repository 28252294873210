import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 600px;
  text-align: center;

  margin: auto;
  margin-top: 60px;

  background: #ffffff;
  border: 1px solid #d4d8dd;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d4d8dd;
`
