import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 900px;

  .ant-input-number-out-of-range input {
    color: #333333;
  }
`

export const InlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Error = styled.div`
  label {
    color: #dc3545;
  }
`
