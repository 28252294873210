import styled from '@emotion/styled'
import { Menu } from 'antd'
import { css } from '@emotion/css'

export const Wrapper = styled.div`
  width: 720;
  font-size: 13px;

  .ant-modal-wrap {
    .basic-modal {
      .ant-modal-body {
        padding: 0px;
      }
    }
  }
`

export const Row = styled.div`
  padding: 10px;
  display: flex;

  .label {
    width: 200px;
    white-space: nowrap;
  }
`

export const MenuWrapper = styled(Menu)`
  min-width: 160px;
`

export const overlayDropdownClass = css`
  padding-top: 6px;

  .ant-dropdown-menu {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    margin-top: -9px;
    width: 240px;

    .ant-dropdown-menu-submenu {
      border-bottom: solid 1px #dce0e6;
    }
  }

  .ant-dropdown-menu-item {
    border-bottom: solid 1px #dce0e6;

    &:last-child {
      border-bottom: none;
    }
  }
`

export const popupMenuClassName = css`
  border-radius: 4px;

  .ant-dropdown-menu {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    min-width: 150px;

    .ant-dropdown-menu-submenu {
      border-bottom: solid 1px #dce0e6;
    }
  }

  .ant-dropdown-menu-item {
    border-bottom: solid 1px #dce0e6;
  }
`

export const RulesSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #d9d9d9;
  border-radius: 2px;
  width: 240px;

  &.error {
    border-color: #dc3545;
    background-color: #ffeeeb;

    input {
      background-color: #ffeeeb;
    }
  }

  input {
    border: none;
    height: 30px;
    outline: none;
    padding-left: 10px;
    width: 210px;
  }

  .icon {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

export const RulesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 560px;

  .container {
    display: flex;
    align-items: center;
  }

  .container:nth-of-type(n + 3) {
    margin-top: 5px;
  }

  .vertical-line {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 5px;
    background-color: #d4d8dd;
  }
`

export const FormFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  padding-right: 10px;
  border-top: solid 1px #d4d8dd;
`

export const RequiredLabelStyle = css`
  margin-right: 20px;
`

export const titleLineStyle = css`
  border-top: 1px solid #d4d8dd;
  border-bottom: 1px solid #d4d8dd;
  height: 30px;
  width: 100%;
  background: #f4f4f4;
  padding: 0;

  display: flex;
  align-items: center;
`
