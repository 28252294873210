import styled from '@emotion/styled'
import { GREY_BORDER_COLOR } from 'common/constants'

export const StyledWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 70px;

  .journal-item-setting-table {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const SubmitAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .action-button {
    margin-right: 10px;
    border-right: 1px solid ${GREY_BORDER_COLOR};
    padding-right: 11px;
  }
  .select-wrapper {
    margin-left: 10px;
    display: flex;
  }
`
