import { loadNs } from 'common/i18n-config'

import { ACC_PLUS_JOURNAL_LINKAGE_STATUS } from './const'

const t = loadNs('components/organisms/accounting/general-journal-setting-tab')

export const DEPRECIATION_JOURNAL_METHODS = [
  {
    id: 1,
    label: t('depreciationJournalMethod.indirectly'),
  },
  {
    id: 2,
    label: t('depreciationJournalMethod.directly'),
  },
]

export const DEPRECIATION_JOURNAL_CYCLE_TYPES = [
  {
    id: 1,
    label: t('depreciationJournalCycleType.every_month'),
  },
  {
    id: 2,
    label: t('depreciationJournalCycleType.every_quarter'),
  },
]

export const DEPRECIATION_JOURNAL_TYPES = [
  {
    id: 1,
    label: t('depreciationJournalType.common_journal_entry'),
  },
  {
    id: 2,
    label: t('depreciationJournalType.adjusted_journal_entry'),
  },
]

export const CREATE_JOURNAL_METHODS = [
  {
    id: 1,
    label: t('createJournalMethod.create_journal_by_asset_category'),
  },
  {
    id: 2,
    label: t('createJournalMethod.create_journal_by_asset_category_unit_and_details_by_asset_unit'),
  },
  {
    id: 3,
    label: t('createJournalMethod.create_journal_by_asset'),
  },
]

export const SLIP_DATE_TYPES = [
  {
    id: 1,
    label: t('slipDate.date_of_fixed_asset_ledger'),
  },
  {
    id: 2,
    label: t('slipDate.last_day_of_accounting_period'),
  },
]

export const ACCOUNTING_PLUS_STATUS_VALUES = {
  unapproved: {
    id: ACC_PLUS_JOURNAL_LINKAGE_STATUS.UNAPPROVED,
    label: t('accountingPlusStatus.draft'),
  },
  approve: {
    id: ACC_PLUS_JOURNAL_LINKAGE_STATUS.APPROVED,
    label: t('accountingPlusStatus.approved'),
  },
} as const

export const ACCOUNTING_PLUS_STATUS = Object.values(ACCOUNTING_PLUS_STATUS_VALUES)
