import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 100%;
  overflow: auto;

  #ledger-settings-bottom {
    display: flex;
    justify-content: flex-end;

    .submit-button {
      min-width: 120px;
      width: auto;
    }
  }
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const LedgerSettingsTable = styled.table`
  font-size: 13px;
  border-collapse: separate;
  border-radius: 4px;
  border-spacing: 0px;
  margin-bottom: 100px;

  .ant-radio-wrapper {
    display: flex;
    justify-content: center;
  }

  .ant-radio-wrapper,
  .ant-radio-wrapper-checked {
    padding: 10px 0;
  }

  &.view {
    td.ledger-item-data-cell {
      padding: 5px 20px;
    }
  }
  th,
  td {
    background: white;
    padding: 10px;
    border: 1px solid #dce0e6;
  }

  tr {
    &:first-of-type {
      th.label-field {
        border-bottom: none;
        border-top-left-radius: 4px;
      }

      td.ledger-item-data-cell {
        border-bottom: none;
        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }

    &:last-child {
      th.label-field {
        border-bottom: 1px solid #dce0e6;
        border-bottom-left-radius: 4px;
      }

      td.ledger-item-data-cell {
        border-bottom: 1px solid #dce0e6;
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }

    td.ledger-item-data-cell.add-button {
      width: auto;
      min-width: unset;
      max-width: unset;
      border-bottom: 1px solid #dce0e6;
      border-bottom-right-radius: 4px;
      padding: 0 5px;
    }

    .add-ledger-setting {
      width: 18px;
      height: 18px;
      padding: 0;
      min-height: unset;
      border: none;
      background: #7c8291;
      color: white;
      .icon-add {
        font-size: 12px;
        top: 3.3px;
        left: 3.3px;
        position: absolute;
      }

      &:hover {
        background: #f5f5f5;
        color: black;
      }
    }

    td.ledger-item-data-cell {
      width: 220px;
      min-width: 220px;
      max-width: 220px;
      border-left: none;
      border-bottom: none;
      &.ledger-name {
        padding: 15px 20px;
        .ledger-name-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .delete-ledger-setting {
            margin-left: 5px;
            padding: 0;
            height: 100%;
          }
        }
      }
    }

    th.label-field {
      display: table-cell;
      text-align: left;
      width: 360px;
      min-width: 336px;
      max-width: 360px;

      .label-field-wrapper {
        display: flex;
        justify-content: space-between;
        white-space: break-spaces;
        align-items: center;
      }

      + td {
        &:first-of-type {
          border-left: none;
        }
      }
      border-bottom: none;
      h4 {
        margin-bottom: 0;
        padding-right: 5px;
        flex: 1 1 0%;
      }
      .required {
        flex: 1 1 0%;
        max-width: 34px;
        max-height: 20px;
      }
    }

    th.section {
      border-bottom: none;
      text-align: left;
    }
    td.section {
      border-bottom: none;

      &:first-of-type {
        border-left: none;
      }
      &:last-child {
        border-left: none;
        border-right: 1px solid #dce0e6;
      }
    }
    .section {
      background: #f4f4f4;
      padding: 5px 10px;
      h4 {
        color: #999999;
        margin-bottom: 0;
      }
    }
  }
`
export const MessageAtEditMode = styled.div`
  max-width: 372px;
  display: flex;
  margin-top: 15px;
  padding: 10px 15px;
  margin-bottom: 14px;
  background-color: #ffffff;
  border: 1px solid #d4d8dd;
  box-sizing: border-box;
  border-radius: 4px;
`

interface DescriptionProps {
  maxWidth: number
}
export const Description = styled.div<DescriptionProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: ${props => `${props.maxWidth}px`};
  margin-top: 14px;
  margin-bottom: 5px;
  font-weight: 500;
`
