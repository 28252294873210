import get from 'lodash/get'
import React from 'react'

import { detailReportSlice } from '../../corporate-income-tax-report-container/store'
import { FormData } from '../common-report/form-data'
import { getPeriod, getReportGrid } from '../common-report/helper'
import { SvgFormWrapper } from '../common-report/styles'
import reportBackground from './background.svg'

export const ReportForm = () => {
  const cellWidth = 90
  const yCoordinate = 123
  const xCoordinatesReport: number[] = [273, 364, 455, 545, 635]
  const xCoordinatesPeriod: number[] = [427, 488, 510]
  const yCoordinatesPeriod: number[] = [54, 68]
  const heightPerRow: number[] = [
    32, 32, 32, 32, 32, 40, 33, 32, 31, 32, 32, 31, 32, 32, 32, 31, 32, 32, 32, 31, 32, 42, 31, 32, 32, 32, 32, 31,
  ]
  const reportData = detailReportSlice.useSlice(state => state?.data)

  const propsData = {
    reportData: getReportGrid(heightPerRow, cellWidth, xCoordinatesReport, yCoordinate, reportData),
    reportPeriods: getPeriod(reportData, xCoordinatesPeriod, yCoordinatesPeriod, 15),
    companyName: { x: 590, y: 47, w: 135, h: 44, value: get(reportData, 'office_name', '') },
    reportBackground,
  }

  return (
    <SvgFormWrapper>
      <FormData {...propsData} />
    </SvgFormWrapper>
  )
}
