import React from 'react'
import { Control, Controller } from 'react-hook-form'

import InfinityScrollerPicker from './CoreInfinityScrollerPicker'

interface InfinityMultiSelectPickerProps {
  name: string
  control: Control<Record<string, any>>
  listHeight: number
  valueField: string
  textField: string
  additionalQueries: any
  searchFunction: (payload: any) => Promise<any>
  onChange?: (value: any[]) => void
}

const InfinityMultiSelectPicker = ({
  name,
  control,
  listHeight,
  additionalQueries,
  searchFunction,
  onChange,
  ...rest
}: InfinityMultiSelectPickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange: controllerOnChange }) => (
        <InfinityScrollerPicker
          name={name}
          value={value}
          listHeight={listHeight}
          additionalQueries={additionalQueries}
          searchFunction={searchFunction}
          onChange={event => {
            controllerOnChange(event)
            onChange?.(event)
          }}
          onDataSourceChanged={event => controllerOnChange(event)}
          {...rest}
        />
      )}
    />
  )
}

export default InfinityMultiSelectPicker
