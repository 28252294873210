import { ConfirmModal, RightDrawer } from 'aa_common/front-end/components'
import * as assetApi from 'api/app/asset'
import * as assetCategoryApi from 'api/app/assetCategory'
import { CAUSE_TYPES, DATE_FORMAT_RFC } from 'common/constants'
import { updateObjectValues } from 'common/helpers/object-helper'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { BasicForm } from 'components/molecules'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

import Form from './Form'

const t = loadNs(['components/organisms/assets/asset-usage-started-form', 'common'])

type Props = {
  errors?: any
  causes: any[]
  isShow?: boolean
  loading?: boolean
  latestEvent: any
  data?: any
  formMode?: FORM_MODES
  onClose?: (e: any) => void
  onSubmit: (data: any) => void
}

const initialDefaultValues = {
  switched_at: null,
  event_cause_id: null,
  asset_category_id: null,
  asset_type_for_depreciable_property_tax_code: null,
  asset_type_for_corporate_tax_code: null,
  asset_structure_for_corporate_tax: null,
  asset_detail_for_corporate_tax: null,
  event_photos: [],
  event_attachments: [],
  event_urls: [],
}

const SwitchCategoryForm: React.FC<Props> = ({
  isShow = false,
  loading = false,
  formMode = FORM_MODES.NEW,
  errors,
  causes,
  latestEvent,
  data,
  onClose,
  onSubmit,
}) => {
  const [visible, setVisible] = useState(isShow)
  const [switchedCauses, setSwitchedCauses] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<any>(null)
  const [assetCategoryListResponse, fetchAssetCategoryList] = useResource(assetCategoryApi.search)
  const [previousEventResponse, getPreviousEvent] = useResource(assetApi.getPreviousOfId)
  const [assetCategoryList, setAssetCategoryList] = useState([])
  const [previousEvent, setPreviousEvent] = useState(null)
  const [formValues, setFormValues] = useState<any>(null)
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false)

  const handleSubmit = (values: any) => {
    if (get(latestEvent, 'usage_started_at')) {
      onSubmit(values)
    } else {
      setIsShowConfirm(true)
      setFormValues(values)
    }
  }

  const getDefaultSwitchedAt = () => {
    const validFrom = get(latestEvent, 'valid_from')
    const today = moment().format(DATE_FORMAT_RFC)

    if (validFrom > today) {
      return validFrom
    }

    return today
  }

  const filteredAssetCategries = useMemo(() => {
    if (formMode === FORM_MODES.NEW)
      return assetCategoryList.filter(
        (assetCategory: any) => assetCategory.id !== get(latestEvent, ['asset_category', 'id'])
      )
    if (previousEvent)
      return assetCategoryList.filter(
        (assetCategory: any) => assetCategory.id !== get(previousEvent, ['asset_category', 'id'])
      )
    return []
  }, [latestEvent, assetCategoryList, previousEvent, formMode])

  const handleCancelSubmit = () => {
    setIsShowConfirm(false)
    setFormValues(null)
  }

  const handleOkSubmit = () => {
    onSubmit(formValues)
    setIsShowConfirm(false)
  }

  useEffect(() => {
    fetchAssetCategoryList({})
  }, []) // eslint-disable-line

  useEffect(() => {
    if (assetCategoryListResponse.data) {
      setAssetCategoryList(assetCategoryListResponse.data)
    }
  }, [assetCategoryListResponse])

  useEffect(() => {
    const previousEventData = previousEventResponse.data

    if (previousEventData) {
      setPreviousEvent(previousEventData)
    }
  }, [previousEventResponse])

  useEffect(() => {
    const filteredCauses = causes.filter(cause => get(cause, 'type') === CAUSE_TYPES.SWITCH_CATEGORY)
    setSwitchedCauses(filteredCauses)
  }, [causes])

  useEffect(() => {
    setVisible(isShow)

    if (isShow) {
      const defaultSwitchedAt = getDefaultSwitchedAt()

      if (latestEvent && formMode === FORM_MODES.NEW) {
        setInitialValues({
          ...initialDefaultValues,
          min_date: get(latestEvent, 'valid_from'),
          switched_at: defaultSwitchedAt,
          last_asset_category_name: get(latestEvent, ['asset_category', 'name']),
          last_asset_type_for_depreciable_property_tax_name: get(latestEvent, [
            'asset_type_for_depreciable_property_tax',
            'name_jp',
          ]),
          last_asset_type_for_corporate_tax_name: get(latestEvent, ['asset_type_for_corporate_tax', 'name_jp']),
          last_asset_structure_for_corporate_tax: get(latestEvent, ['asset_structure_for_corporate_tax']),
          last_asset_detail_for_corporate_tax: get(latestEvent, ['asset_detail_for_corporate_tax']),
          event_photos: [],
          event_attachments: [],
          event_urls: [],
        })
      } else if (data) {
        const objectValues = updateObjectValues(initialDefaultValues, data)
        setInitialValues({
          ...objectValues,
          id: latestEvent.id,
          biid: latestEvent.biid,
          switched_at: get(latestEvent, 'valid_from'),
          event_cause_id: get(latestEvent, ['event_cause', 'id']),
          asset_category_id: get(latestEvent, ['asset_category', 'id']),
          asset_type_for_depreciable_property_tax_code: get(latestEvent, [
            'asset_type_for_depreciable_property_tax',
            'code',
          ]),
          asset_type_for_corporate_tax_code: get(latestEvent, ['asset_type_for_corporate_tax', 'code']),
        })
      }
    }
  }, [isShow]) // eslint-disable-line

  useEffect(() => {
    if (formMode === FORM_MODES.EDIT) {
      getPreviousEvent(get(data, 'biid'), get(data, 'id'))
    }
  }, []) // eslint-disable-line

  return (
    <RightDrawer title={t('switch_category')} visible={visible} onClose={onClose}>
      <BasicForm initialValues={initialValues} onSubmit={handleSubmit} errors={errors} loading={loading}>
        <Form
          causes={switchedCauses}
          assetCategories={filteredAssetCategries}
          previousEvent={previousEvent}
          onClose={onClose}
        />
      </BasicForm>
      <ConfirmModal
        type={ConfirmModal.CONFIRM_TYPES.CONFIRM}
        title={t('confirm_title')}
        message={t('confirm_message')}
        visible={isShowConfirm}
        onCancel={handleCancelSubmit}
        onOK={handleOkSubmit}
      />
    </RightDrawer>
  )
}

export default SwitchCategoryForm
