/* eslint-disable guard-for-in */
import { CategoryModel, RolePermission } from 'aa_common/front-end/models'

export const handleDataPost = (categories: CategoryModel[], categoriesMap: any) => {
  return categories.map((cate, iCate) => ({
    ...cate,
    permissions: cate.permissions.map((per: RolePermission, iPer) => {
      for (const keyChange in categoriesMap[iCate]) {
        const indexChange = keyChange.split('_')[1]
        if (categoriesMap[iCate][keyChange] && iPer === +indexChange) {
          const { choices }: any = per
          const choicesTemp = { ...choices }
          for (const keyChoice in choicesTemp) {
            if (choicesTemp[keyChoice]) {
              choices[keyChoice] = false
            }
          }
          choices[categoriesMap[iCate][keyChange]] = true
          break
        }
      }
      return per
    }),
  }))
}
