/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Controller } from 'react-hook-form'

import CoreLinkListField from './CoreLinkListField'
import { LinkListFieldProps } from './model'

const LinkListField = ({ value: controlledValue, name, onChange, control, ...rest }: LinkListFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ onChange: controllerOnChange, value, name }) => {
        return (
          <CoreLinkListField
            value={value}
            name={name}
            onChange={value => {
              controllerOnChange(value)
              onChange?.(value)
            }}
            {...rest}
          />
        )
      }}
    />
  )
}

export default LinkListField
