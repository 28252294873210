import toPath from 'lodash/toPath'

export function transformObjectWithPath(obj: any) {
  if (!obj || typeof obj !== 'object') return obj

  Object.keys(obj).forEach(key => {
    const paths = toPath(key)
    if (paths.length > 1) {
      const lastKey = paths[paths.length - 1]
      paths.pop()

      let lastObj = obj
      paths.forEach(p => {
        if (!lastObj[p]) {
          lastObj[p] = {}
        }
        lastObj = lastObj[p]
      })

      if (typeof lastObj === 'object') {
        lastObj[lastKey] = obj[key]
      }
    }
  })

  return obj
}
