import { ASSET_EVENT_FIELD } from 'common/constants'
import { formatDate, formatDateTime, objectToQueryParams, renderAssetFields, yen } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetReport } from 'common/models'
import get from 'lodash/get'
import { assetEventListReportSlice, resetStore } from 'pages/accounting/AssetEventListReportPage/store'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayDataRow } from '../styles'
import { Media } from './media'
import { Row } from './row'
import { SharedHeaderView } from './shared-event-view'

const { t, useNsReady } = getNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const RetirementEventView = ({ assets }: Props) => {
  useNsReady()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => resetStore()
  }, [])

  const handleAssetClick = (asset: AssetReport) => {
    const paramObj = {
      valid_at: asset.retired_at,
      type: ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid || ''}${params}`))
  }

  if (!assets) return null
  return (
    <>
      {assets.map(asset => (
        <RetirementEvent key={asset.id} asset={asset} onAssetClick={handleAssetClick} />
      ))}
    </>
  )
}

const RetirementEvent = ({ asset, onAssetClick }: RetirementEventProps) => {
  useNsReady()
  const { isDetailMode, selectedItem } = assetEventListReportSlice.useSlice(state => ({
    isDetailMode: state?.data?.isDetailMode,
    selectedItem: state?.data?.selectedItem,
  }))

  return (
    <AssetDisplay key={asset.id} onClick={() => onAssetClick(asset)}>
      <SharedHeaderView
        asset={asset}
        selectedItem={selectedItem}
        dataTestId="checkbox-item"
        isDetailMode={isDetailMode}
      />
      {isDetailMode && (
        <>
          <AssetDisplayDataRow>
            <div className="short-label">{asset.valid_from}</div>
            <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
            <div className="label text-color-666">{t('retire_at')}</div>
            <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
          </AssetDisplayDataRow>
          <Row label={t('retire_reason')} value={get(asset.event_cause, 'content')} />
          <Row label={t('acquisition_date')} value={formatDate(asset.acquired_at) as string} />
          <Row label={t('service_start_date')} value={formatDate(asset.usage_started_at) as string} />
          <Row
            label={
              get(asset, 'acquisition_cost_excluded_excise') === get(asset, 'acquisition_cost')
                ? t('acquisition_cost_exclude')
                : t('acquisition_cost_include')
            }
            value={yen(asset.acquisition_cost)}
          />
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_depreciation')}</div>
            <div className="value">
              {renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION)}
            </div>
          </AssetDisplayDataRow>
          <Row label={t('accumulated_impairment')} value={yen(asset.accumulated_impaired_amount)} />
          <Row label={t('disposal_cost')} value={yen(asset.disposal_cost)} />
          <AssetDisplayDataRow>
            <div className="label">{t('loss_on_retirement')}</div>
            <div className="value">{renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.LOSS_AND_GAIN)}</div>
          </AssetDisplayDataRow>
          <Row label={t('department')} value={get(asset.department, 'name')} />
          <Row label={t('area')} value={get(asset.area, 'name')} />
          <Media asset={asset} />
          <Row className="last" label={t('retire_by')} value={get(asset.user_info, 'display_name')} />
        </>
      )}
    </AssetDisplay>
  )
}

type Props = {
  assets: AssetReport[]
}

type RetirementEventProps = {
  asset: AssetReport
  onAssetClick: (asset: AssetReport) => void
}
