import { combineReducers } from 'redux-immutable'

import accounting from './accounting/reducer'
import app from './app/reducer'
import assets from './asset/reducer'
import assetComment from './assetComment/reducer'
import assetImport from './assetImport/reducer'
import depreciation from './depreciation/reducer'
import internalControl from './internalControl/reducer'
import masterData from './masterData/reducer'
import office from './office/reducer'
import session from './session/reducer'
import settings from './settings/reducer'
import tempData from './tempData/reducer'

export default combineReducers({
  assets,
  tempData,
  settings,
  masterData,
  session,
  app,
  depreciation,
  office,
  assetComment,
  assetImport,
  accounting,
  internalControl,
})
