import styled from '@emotion/styled'

export const Wrapper = styled.div`
  th {
    text-align: left;
    padding-left: 5px;
  }
`

export const ActionWrapper = styled.div`
  margin-bottom: 10px;
  text-align: right;
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`
