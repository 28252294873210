import styled from '@emotion/styled'
import { Checkbox } from 'aa_common/front-end/antd'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { getNs } from 'common/i18n-config'
import React from 'react'

import { depreciationFixedAssetSlice, markAll } from '../store'

const { t, useNsReady } = getNs(['pages/accounting/depreciation-fixed-asset-page'])

export const CollectiveCheckbox = ({ disabled }: { disabled: boolean }) => {
  useNsReady()
  const reports = depreciationFixedAssetSlice.useSlice(state => state.data?.reports)
  const amountOfSelectedItems = defaultTo(reports?.filter(report => report.isSelected).length, 0)
  const isCheckedAll = !!amountOfSelectedItems && amountOfSelectedItems === reports?.length
  const isIndeterminate = amountOfSelectedItems > 0 && !isCheckedAll

  const handleCheckboxChanged = (checked: boolean) => {
    if (isIndeterminate || isCheckedAll) {
      markAll(false)
    }

    markAll(checked)
  }

  return (
    <CheckboxWrapper data-testid="collective-checkbox">
      <Checkbox
        className={ifElse(isCheckedAll, '', 'uncheck-collective')}
        name="collective"
        disabled={disabled}
        onChange={({ checked }) => handleCheckboxChanged(checked)}
        checked={isCheckedAll || !!amountOfSelectedItems}
        indeterminateValue={isIndeterminate}
      >
        {amountOfSelectedItems > 0 && <span>{t('selectedItems', { numberOfSelectedRow: amountOfSelectedItems })}</span>}
      </Checkbox>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.div`
  display: inline-block;
  float: left;

  label,
  label > span,
  div {
    display: inline-block;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    height: 2px !important;
  }

  .uncheck-collective {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff;
      border: 1px solid #d9d9d9;
    }
  }

  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper-checked {
    margin-left: 10px;
  }
`
