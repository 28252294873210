import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { ExportModal } from 'aa_common/front-end/components'
import { RadioChangeEvent } from 'antd'
import { loadNs } from 'common/i18n-config'
import { LedgerSettingsItem, Term, TermMonth } from 'common/models'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import { ImportExportButton } from 'components/molecules'
import { RenderFormProps } from 'components/molecules/Form'
import { parseTerms } from 'components/organisms/settings/AccountingPeriodEditForm/helpers'
import { get, isEqual } from 'lodash'
import { convertIncreaseDecreaseReportPayload } from 'pages/accounting/IncreaseDecreaseReportPage/helper'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  exportIncreaseDecreaseReport,
  fetchIncreaseDecreaseReport,
} from 'store/accounting/increaseDecreaseReport/actions'
import { ExportIncreaseDecreaseReportPayload } from 'store/accounting/increaseDecreaseReport/model'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { fetchTermPeriods } from 'store/settings/accountingPeriod/actions'
import { selectTermData } from 'store/settings/accountingPeriod/selectors'
import { useDebouncedCallback } from 'use-debounce'

import MonthTermSlider from './components/MonthTermSlider'
import { ExportByUnit, getConstant } from './constant'
import { FormField, HorizontalBorder, MoneyUnitClass, MoneyUnitWrapper, SummaryLedgerClass } from './styles'

interface AutoJournalSummaryFormFilter {
  terms: Term[]
  ledgers: LedgerSettingsItem[]
  formModule: RenderFormProps
  onMonthFilterChanged: (e: boolean) => void
}

const t = loadNs(['pages/accounting/increase-decrease-report'])
const FormFilter = ({ terms, ledgers, formModule, onMonthFilterChanged }: AutoJournalSummaryFormFilter) => {
  const { MONEY_UNIT_OPTIONS, EXPORT_BY_UNIT_OPTIONS } = getConstant()
  const [isExportModalShow, setShowExportModal] = useState(false)
  const [exportUnitValue, setExportUnitValue] = useState(ExportByUnit.ASSET_UNIT)
  const location = useLocation()
  const isRedirectFromMonthlyClosing = location.pathname.includes('/accounting/monthly-closing')

  const dispatch = useDispatch()

  const termPeriods = useSelector(selectTermData)
  const currentMonthlyClosingSelector = useSelector(selectCurrentMonthlyClosing, isEqual)

  const currentMonthlyClosing = useMemo(() => currentMonthlyClosingSelector.data, [currentMonthlyClosingSelector.data])
  const termMonths: TermMonth[] = useMemo(() => {
    return get(termPeriods?.data, 'term_months', [])
  }, [termPeriods.data])

  const memoizedTermsData = useMemo(() => parseTerms(terms), [terms])

  const ledgersOptions = useMemo(() => {
    return (ledgers || []).map(({ id, name }) => ({ value: id, label: name }))
  }, [ledgers])
  useEffect(() => {
    ledgersOptions.length > 0 && formModule.setValue('ledger', get(ledgersOptions, '[0].value'))
  }, [ledgersOptions]) // eslint-disable-line

  useEffect(() => {
    if (currentMonthlyClosing && memoizedTermsData?.length > 0) {
      const { term_id, month_serial_number } = currentMonthlyClosing?.from_term_month || {}
      formModule.setValue('term_year', term_id)
      formModule.setValue('month_serial_number', [month_serial_number, month_serial_number])
    }
  }, [currentMonthlyClosing, memoizedTermsData]) // eslint-disable-line

  const handleOnExportByUnitChanged = useCallback((event: RadioChangeEvent) => {
    const { value } = event.target
    setExportUnitValue(value)
  }, [])

  const handleExportClick = (charset: string) => {
    const formValues = convertIncreaseDecreaseReportPayload(formModule.getValues())
    const currencyUnit = formModule.getValues('money_unit')
    const currencyUnitFormat = currencyUnit === 1 ? 1 : 1000
    // eslint-disable-next-line
    const payload: ExportIncreaseDecreaseReportPayload = Object.assign({}, formValues, {
      charset,
      output_unit: exportUnitValue,
      currency_unit: currencyUnitFormat,
    })
    dispatch(exportIncreaseDecreaseReport(payload, isRedirectFromMonthlyClosing))
    setShowExportModal(false)
  }

  const msNumber = useWatch({ name: 'month_serial_number', control: formModule.control }) as number[]
  const [from, to] = msNumber || []
  const isMonthFilter = from === to
  useEffect(() => {
    onMonthFilterChanged(isMonthFilter)
  }, [isMonthFilter, onMonthFilterChanged])

  const term_year = useWatch({ name: 'term_year', control: formModule.control }) as number
  useEffect(() => {
    if (term_year) {
      const thunk = dispatch(fetchTermPeriods(term_year)) as any
      if (thunk.then) thunk.then(() => fetchReportData())
    }
  }, [term_year, dispatch]) //eslint-disable-line

  const fetchReportData = useDebouncedCallback(
    () => {
      const formValues = formModule.getValues()
      const payload = convertIncreaseDecreaseReportPayload(formValues)
      dispatch(fetchIncreaseDecreaseReport(payload, isRedirectFromMonthlyClosing))
    },
    100,
    { maxWait: 250 }
  )

  return (
    <>
      <FormField className="term-year">
        <div className="field-label">
          <span>{t('term_year')}</span>
        </div>
        <div className="field-value">
          <AutoCompleteSelect
            items={memoizedTermsData}
            name="term_year"
            allowClear={false}
            control={formModule.control}
            disabled={formModule.loading}
            valueField="value"
            textField="text"
          />
        </div>
        <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 5 }}>{t('term_month')}</span>
          <MonthTermSlider
            name="month_serial_number"
            termMonths={termMonths}
            onChange={fetchReportData}
            control={formModule.control}
          />
        </div>
      </FormField>
      <HorizontalBorder />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <RadioGroup
          options={ledgersOptions as any}
          name="ledger"
          className={SummaryLedgerClass}
          optionType="button"
          disabled={formModule.loading}
          formModule={formModule}
          onChange={fetchReportData}
        />
        <ImportExportButton type="export" onClick={() => setShowExportModal(true)} />
        <ExportModal
          title={t('export_modal_title')}
          isShow={isExportModalShow}
          onCancel={() => setShowExportModal(false)}
          onExport={handleExportClick}
        >
          <div style={{ fontSize: 13 }}>
            <h4>{t('export_modal_description')}</h4>
            <h4>{t('export_modal_description_output_unit')}</h4>
            <RadioGroup
              value={exportUnitValue}
              options={EXPORT_BY_UNIT_OPTIONS}
              name="output_unit"
              style={{ marginBottom: 30 }}
              onChange={handleOnExportByUnitChanged}
            />
            <h4>{t('export_modal_description_format')}</h4>
          </div>
        </ExportModal>
      </div>
      <MoneyUnitWrapper>
        <label className="unit-label">{t('unit')}</label>
        <RadioGroup
          options={MONEY_UNIT_OPTIONS}
          name="money_unit"
          className={MoneyUnitClass}
          optionType="button"
          disabled={formModule.loading}
          formModule={formModule}
        />
      </MoneyUnitWrapper>
    </>
  )
}

export default FormFilter
