import { Spin } from 'aa_common/front-end/antd'
import i18n from 'i18n'
import useFiltersSortsPaging from 'lib/hooks/useFiltersSortsPaging'
import useResource, { Request } from 'lib/hooks/useResource'
import React, { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { setAppMessage } from 'store/app/actions'

import HistoriesContent from './HistoriesContent'
import { HistoryRecordType } from './model'

interface Props {
  dataGetter: Request
  firstCellRenderer?: ComponentProps<typeof HistoriesContent>['firstCellRenderer']
  showAccPlusLink?: boolean
}

const HistoryContentWrapper = ({ dataGetter, ...rest }: Props) => {
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  const currentPageParam = parseInt(params.get('page') || '1', 10)

  const { path } = useRouteMatch()

  const dispatch = useDispatch()
  const [{ isLoading }, getHistoriesResponses] = useResource(dataGetter)
  const [historyList, setHistoryList] = useState<HistoryRecordType[]>([])
  const [totalRecords, setTotalRecords] = useState<number>(0)

  const { totalPages: historiesPageNumber, setPageNumber } = useFiltersSortsPaging({
    totalRecords,
  })

  const handlePageChange: any = useCallback(
    (pageNumber: number) => {
      const url = `${path}?page=${pageNumber}`
      history.replace(url)
      setPageNumber(pageNumber)
    },
    [history, path, setPageNumber]
  )

  useEffect(() => {
    getHistoriesResponses(currentPageParam)
      .then(({ data, meta }) => {
        setHistoryList(data)
        setTotalRecords(meta?.total)
      })
      .catch(() => {
        dispatch(setAppMessage({ type: 'failure', content: i18n.t('common.messages.unexpected_error_occured') }))
      })
  }, [dispatch, getHistoriesResponses, currentPageParam])

  return (
    <Spin loading={isLoading}>
      <HistoriesContent
        historyRecords={historyList}
        totalRecords={totalRecords}
        totalPages={historiesPageNumber}
        paginationName="histories"
        onPageChange={handlePageChange}
        pageNumber={currentPageParam}
        {...rest}
      />
    </Spin>
  )
}

export default HistoryContentWrapper
