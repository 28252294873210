/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'aa_common/front-end/antd'
import { ifElse } from 'aa_common/front-end/helpers'
import { DATE_FORMAT_RFC, FEATURES_ID } from 'common/constants'
import { formatDate } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES, Term, TermMonth } from 'common/models'
import { AutoCompleteSelect, DatePicker, RadioList } from 'components/molecules'
import { useFormikContext } from 'formik'
import useUserPermission from 'lib/hooks/useUserPermission'
import { get, last } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchChangedTermPeriods, fetchTermPeriods, setCurrentTermId } from 'store/settings/accountingPeriod/actions'
import { selectCurrentTerm } from 'store/settings/accountingPeriod/selectors'

import {
  defaultTermMonth,
  determineDefaultTermMonthDate,
  determineLastTermMonth,
  determineMaxEndDate,
  hasLackOfQuarter,
  isFullYearRange,
  parseTermMonths,
  parseTerms,
} from './helpers'
import { DisplayTable, Link } from './style'
import TermList from './TermList'

const t = loadNs(['components/organisms/settings/accounting-period-form', 'common'])

type Props = {
  terms: Term[]
  termDetail: Term | null
  hasAssets: boolean | null
  formMode: FORM_MODES
  onChangeModeToReset: () => void
  fetchTermPeriods: typeof fetchTermPeriods
  fetchChangedTermPeriods: typeof fetchChangedTermPeriods
  mode?: string
  handleSetMode?: () => void
}

const Form: React.FC<Props> = ({
  terms,
  termDetail,
  hasAssets,
  formMode,
  fetchTermPeriods,
  fetchChangedTermPeriods,
  onChangeModeToReset,
  mode,
  handleSetMode,
}) => {
  const currentTermId = useSelector(selectCurrentTerm)
  const dispatch = useDispatch()
  const { values, setFieldValue, resetForm } = useFormikContext<any>()
  const termMonths = termDetail && termDetail.term_months

  const { permissions } = useUserPermission(FEATURES_ID.SETTING_ACCOUNTING_PERIOD)

  const handleTermIdChanged = (value: any) => {
    fetchTermPeriods(value)
    setFieldValue('month_serial_number', null)
    dispatch(setCurrentTermId(value))
  }

  const handleResetTermPeriods = () => onChangeModeToReset()

  const handleEndDateChanged = (endDate: moment.Moment | null) => {
    if (endDate) {
      const endDateString = endDate.format(DATE_FORMAT_RFC)
      const lastTermMonth = determineLastTermMonth(termDetail!.term_months, endDateString)

      let monthSerialNumber = get(values, 'month_serial_number') || lastTermMonth.month_serial_number

      if (isFullYearRange(termDetail!.start_date, endDateString)) {
        monthSerialNumber = 12
      } else if (monthSerialNumber > lastTermMonth.month_serial_number) {
        monthSerialNumber = lastTermMonth.month_serial_number
      }

      fetchChangedTermPeriods({
        ...values,
        end_date: endDate?.format(DATE_FORMAT_RFC),
        month_serial_number: monthSerialNumber,
      })
    }
  }

  const handleTermMonthChanged = (termMonthId: string | undefined) => {
    termMonthId &&
      fetchChangedTermPeriods({
        ...values,
        month_serial_number: parseInt(termMonthId, 10),
      })
  }

  const handleDefaultTermMonthChanged = (value: any) => {
    value &&
      fetchChangedTermPeriods({
        ...values,
        default_term_month_date: parseInt(value, 10),
      })
  }

  const handleLackOfSettingChanged = (value: any) => {
    fetchChangedTermPeriods({
      ...values,
      lack_of_month_setting: value,
    })
  }

  const renderStartDateForMessage = () => {
    if (!termDetail || !termDetail.start_date) return ''

    const startDateMoment = moment(termDetail.start_date)

    return t('editing_notice_msg', {
      year: startDateMoment.year(),
      month: startDateMoment.month() + 1,
    })
  }

  const lackOfMonthSetting = useMemo(() => {
    let quarter = 1

    if (!termMonths) return []

    if (termMonths.length >= 10) {
      quarter = 4
    } else if (termMonths.length >= 7) {
      quarter = 3
    } else if (termMonths.length >= 4) {
      quarter = 2
    }

    return [
      { text: t('lack_of_month_setting_text', { quarter: 1 }), value: 1 },
      { text: t('lack_of_month_setting_text', { quarter }), value: 0 },
    ]
  }, [termMonths])

  useEffect(() => {
    if (terms) {
      const firstTermId = terms[0]?.id

      setFieldValue('term_id', firstTermId)
      if (currentTermId) return
      handleTermIdChanged(firstTermId)
    }
  }, [terms]) // eslint-disable-line

  useEffect(() => {
    if (!termDetail) return

    const lastTermMonth = last(termDetail.term_months) as TermMonth
    let monthSerialNumber = null

    if (isFullYearRange(termDetail.start_date, termDetail.end_date)) {
      monthSerialNumber = lastTermMonth.month_serial_number
    } else {
      monthSerialNumber = get(values, 'month_serial_number')

      if (!monthSerialNumber || monthSerialNumber > lastTermMonth.month_serial_number) {
        monthSerialNumber = lastTermMonth.month_serial_number
      }
    }

    resetForm({
      values: {
        ...values,
        end_date: termDetail.end_date,
        month: moment(termDetail.start_date).month() + 1,
        default_term_month_date: determineDefaultTermMonthDate(termDetail.term_months[0]),
        month_serial_number: monthSerialNumber,
        min_end_date: termDetail.start_date,
        max_end_date: determineMaxEndDate(termDetail.start_date),
        ...(currentTermId && { term_id: currentTermId }),
      },
    })
  }, [termDetail]) // eslint-disable-line

  return (
    termDetail && (
      <>
        <div>
          {hasAssets === false && formMode === FORM_MODES.VIEW && (
            <>
              <div>
                <Link onClick={handleResetTermPeriods}>{t('reset_year_term_msg')}</Link>
                <label>{renderStartDateForMessage()}</label>
              </div>
              <div style={{ backgroundColor: '#D4D8DD', height: 1, width: '100%', marginTop: 10, marginBottom: 10 }} />
            </>
          )}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ marginRight: 10 }}>{t('units.year_1')}</label>
              <AutoCompleteSelect
                name="term_id"
                items={parseTerms(terms)}
                valueField="value"
                textField="text"
                style={{ width: 150 }}
                afterChanged={handleTermIdChanged}
                allowClear={false}
              />
            </div>
            {mode === FORM_MODES.VIEW && (
              <div style={{ display: 'flex' }}>
                <Button
                  showTooltipMessage={ifElse(!permissions.isCRU, t('actions.no_permission'), undefined)}
                  disabled={!permissions.isCRU}
                  name="edit-setting"
                  color="grey"
                  onClick={handleSetMode}
                >
                  {t('edit_setting')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <DisplayTable style={{ marginTop: 10 }}>
          <div className="row">
            <div className="col-1">{t('accounting_period')}</div>
            <div className="col-1" style={{ width: 180 }}>
              {formatDate(termDetail?.start_date)}
            </div>
            <div>〜</div>
            {formMode === FORM_MODES.EDIT ? (
              <DatePicker
                name="end_date"
                style={{
                  width: 150,
                  marginLeft: 10,
                  boxShadow: '0 0 0 2px rgb(24 144 255 / 20%)',
                  borderColor: '#40a9ff',
                }}
                allowClear={false}
                maxDateField="max_end_date"
                minDateField="min_end_date"
                afterChanged={handleEndDateChanged}
              />
            ) : (
              <div style={{ marginLeft: 10 }}>{formatDate(termDetail.end_date)}</div>
            )}
          </div>
          {formMode === FORM_MODES.EDIT && !isFullYearRange(termDetail.start_date, termDetail.end_date) && (
            <div className="row" style={{ borderTop: 'solid 1px #D4D8DD' }}>
              <div className="col-1">{t('change_month')}</div>
              <div>
                <AutoCompleteSelect
                  name="month_serial_number"
                  items={parseTermMonths(termDetail.term_months)}
                  valueField="value"
                  textField="text"
                  style={{ width: 150 }}
                  afterChanged={handleTermMonthChanged}
                  allowClear={false}
                />
              </div>
            </div>
          )}
          {formMode === FORM_MODES.EDIT && moment(termDetail.start_date).date() !== 1 && (
            <div className="row" style={{ borderTop: 'solid 1px #D4D8DD' }}>
              <div className="col-1">{t('monthly_base_date')}</div>
              <div>
                <RadioList
                  name="default_term_month_date"
                  fields={defaultTermMonth}
                  afterChanged={handleDefaultTermMonthChanged}
                />
              </div>
            </div>
          )}
          {formMode === FORM_MODES.EDIT && termMonths && hasLackOfQuarter(termMonths.length) && (
            <div className="row" style={{ borderTop: 'solid 1px #D4D8DD' }}>
              <div className="col-1">{t('quarter_to_shorten')}</div>
              <div>
                <AutoCompleteSelect
                  name="lack_of_month_setting"
                  items={lackOfMonthSetting}
                  style={{ width: 180 }}
                  afterChanged={handleLackOfSettingChanged}
                />
              </div>
            </div>
          )}
        </DisplayTable>
        <TermList
          data={termDetail.term_months}
          style={{ marginTop: 10 }}
          changedFromMonthId={get(values, 'month_serial_number')}
          hasChangedDate={!isFullYearRange(termDetail.start_date, termDetail.end_date)}
        />
      </>
    )
  )
}

export default Form
