import { AutoGenerateSetting, InitialNumberSetting } from 'common/models'

export const selectAssetCodeAutoGenerateSettingListData = (state: any): AutoGenerateSetting[] => {
  return state.getIn(['settings', 'assetCode', 'list', 'data'])
}

export const selectAssetCodeAutoGenerateSettingData = (state: any) => {
  return state.getIn(['settings', 'assetCode', 'item', 'data'])
}

export const selectLoadingState = (state: any): boolean => {
  return state.getIn(['settings', 'assetCode', 'item', 'loading'])
}

export const selectAssetCodeInitialSerialSettingData = (state: any): InitialNumberSetting => {
  return state.getIn(['settings', 'assetCode', 'initialNumberSettingList', 'data'])
}

export const selectAssetCodeInitialSerialSettingErrors = (state: any) => {
  return state.getIn(['settings', 'assetCode', 'initialNumberSettingList', 'errors'])
}

export const selectTermYearSelected = (state: any) => state.getIn(['settings', 'assetCode', 'termYearSelected'])
