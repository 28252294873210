import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: red;
  color: white;
  width: 34px;
  height: 20px;
  font-size: 13px;
`

export const Label = styled.label`
  font-weight: 500;
  position: absolute;
  top: 0px;
  left: 3px;

  @-moz-document url-prefix() {
    top: -1px;
  }
`
