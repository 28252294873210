export interface ValueFields {
  event_attachments: any
  event_cause_id: any
  event_photos: any
  event_urls: any
  min_date: string
  usage_started_at: string
}

export const validate = (values: ValueFields) => {
  const { usage_started_at, event_cause_id } = values

  return usage_started_at && event_cause_id
}
