import {
  ExportTaxDeclarationReports,
  FetchTaxDeclarationDecreaseReport,
  FetchTaxDeclarationIncreaseReport,
  FetchTaxDeclarationReportList,
  FetchTaxDeclarationSummaryReport,
} from './actions.model'
import {
  ADD_TAX_DECLARATION_DECREASE_REPORT_FILTER,
  EXPORT_TAX_DECLARATION_REPORTS,
  EXPORT_TAX_DECLARATION_REPORTS_FAILURE,
  EXPORT_TAX_DECLARATION_REPORTS_SUCCESS,
  FETCH_TAX_DECLARATION_DECREASE_REPORT,
  FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS,
  FETCH_TAX_DECLARATION_INCREASE_REPORT,
  FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS,
  FETCH_TAX_DECLARATION_REPORT_LIST,
  FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE,
  FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS,
} from './constant'
import {
  ExportTaxDeclarationReportsPayload,
  TaxDeclarationDecreaseReportPayload,
  TaxDeclarationIncreaseReportPayload,
  TaxDeclarationReportListPayload,
  TaxDeclarationReportsFormFilterSetting,
  TaxDeclarationSummaryReportPayload,
} from './model'

export const fetchTaxDeclarationSummaryReport = (
  payload: TaxDeclarationSummaryReportPayload
): FetchTaxDeclarationSummaryReport => ({
  type: FETCH_TAX_DECLARATION_SUMMARY_REPORT,
  payload,
})

export const fetchTaxDeclarationSummaryReportSuccess = (payload: any) => ({
  type: FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS,
  payload,
})

export const fetchTaxDeclarationSummaryReportFailure = () => ({
  type: FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE,
})

export const fetchTaxDeclarationReportList = (
  payload: TaxDeclarationReportListPayload
): FetchTaxDeclarationReportList => ({
  type: FETCH_TAX_DECLARATION_REPORT_LIST,
  payload,
})

export const fetchTaxDeclarationReportListSuccess = (payload: any) => ({
  type: FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS,
  payload,
})

export const fetchTaxDeclarationReportListFailure = () => ({
  type: FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE,
})

/**
 * The actions to fetch tax declaration INCREASE report
 */
export const fetchTaxDeclarationIncreaseReport = (
  payload: TaxDeclarationIncreaseReportPayload
): FetchTaxDeclarationIncreaseReport => ({
  type: FETCH_TAX_DECLARATION_INCREASE_REPORT,
  payload,
})

export const fetchTaxDeclarationIncreaseReportSuccess = (payload: any) => ({
  type: FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS,
  payload,
})

export const fetchTaxDeclarationIncreaseReportFailure = () => ({
  type: FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE,
})

/**
 * The actions to fetch tax declaration DECREASE report
 */
export const fetchTaxDeclarationDecreaseReport = (
  payload: TaxDeclarationDecreaseReportPayload
): FetchTaxDeclarationDecreaseReport => ({
  type: FETCH_TAX_DECLARATION_DECREASE_REPORT,
  payload,
})

export const fetchTaxDeclarationDecreaseReportSuccess = (payload: any) => ({
  type: FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS,
  payload,
})

export const fetchTaxDeclarationDecreaseReportFailure = () => ({
  type: FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE,
})

export const exportTaxDeclarationReports = (
  payload: ExportTaxDeclarationReportsPayload
): ExportTaxDeclarationReports => ({
  type: EXPORT_TAX_DECLARATION_REPORTS,
  payload,
  meta: { thunk: true },
})

export const exportTaxDeclarationReportsSuccess = (payload: any) => ({
  type: EXPORT_TAX_DECLARATION_REPORTS_SUCCESS,
  payload,
})

export const exportTaxDeclarationReportsFailure = () => ({
  type: EXPORT_TAX_DECLARATION_REPORTS_FAILURE,
})

export const addTaxDeclarationReportsFormFilterSetting = (payload: TaxDeclarationReportsFormFilterSetting) => ({
  type: ADD_TAX_DECLARATION_DECREASE_REPORT_FILTER,
  payload,
})
