import { DATE_FORMAT, Permission, TIME_FORMAT } from 'common/constants'
import moment from 'moment'

export interface AssetCommentListProps {
  commentList: IAssetCommentItem[]
  listWrapperClassName?: string
  readonly?: boolean
  loading?: boolean
  visible?: boolean
  onItemDeleteClick?: (event: IAssetCommentItem) => void
  onItemEditSubmit?: (item: IAssetCommentItem, editValue: string) => void
  permissions: Permission
}

export interface IAssetCommentItem {
  id: string | number
  commentDate: Date | string
  userId: string | number
  fixedAssetBiid: string
  userName: string
  content: string
  avatarFirstLetter?: string
  avatarImg?: string
}

export interface AssetCommentListItem {
  item: IAssetCommentItem
  readonly?: boolean
  onItemDeleteClick?: (item: IAssetCommentItem) => void
  onItemEditSubmit?: (item: IAssetCommentItem, editValue: string) => void
  permissions: Permission
}

export const convertComments = (data: any[]): IAssetCommentItem[] => {
  if (!data) return []

  return data.map(item => {
    return {
      id: item.id,
      content: item.content,
      userId: item.user_info.id,
      fixedAssetBiid: item.fixed_asset_biid,
      commentDate: moment(item.created_at).format(`${DATE_FORMAT} ${TIME_FORMAT}`),
      avatarFirstLetter: item.user_info?.display_name[0],
      userName: item.user_info.display_name,
    }
  })
}
