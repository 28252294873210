import { loadNs } from 'common/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'
import { Row } from './row'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const ImpairmentTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('impairment_settings_table')}>
      <tbody>
        <Row
          title={t('impairment_loss')}
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            { name: 'impairment_debit_loss_account_item.name' },
            { name: 'impairment_debit_loss_account_sub_item.name' },
            { name: '', isThead: true, title: t('accumulated_impairment_loss') },
            { name: 'impairment_credit_accumulated_impairment_account_item.name' },
            { name: 'impairment_credit_accumulated_impairment_account_sub_item.name' },
          ]}
        />
        <Row
          title=""
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            { name: '', colspan: 2 },
            { name: 'asset_category_title', isThead: true, title: t('asset_category') },
            { name: 'impairment_credit_asset_category_account_item.name' },
            { name: 'impairment_credit_asset_category_account_sub_item.name' },
          ]}
        />
      </tbody>
    </JournalItemSettingTable>
  )
}

export default ImpairmentTableView
