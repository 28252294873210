/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterTenantActivationStatusesRequest, PresenterTenantActivationStatusesResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class InternalApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description return if the office_user is active
   *
   * @tags internal_api
   * @name OfficeUsersDeletableDetail
   * @summary return if the office_user is active
   * @request GET:/internal/office_users/{id}/deletable
   */
  officeUsersDeletableDetail = (id: number | string, params: RequestParams = {}) =>
    this.http.request<any, any>({
      path: `/internal/office_users/${id}/deletable`,
      method: 'GET',
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description return if the office is active
   *
   * @tags internal_api
   * @name OfficesExistenceDetail
   * @summary return if office is active
   * @request GET:/internal/offices/{id}/existence
   */
  officesExistenceDetail = (id: number | string, params: RequestParams = {}) =>
    this.http.request<any, any>({
      path: `/internal/offices/${id}/existence`,
      method: 'GET',
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description return list of office is actived or not
   *
   * @tags internal_api
   * @name TenantActivationStatusesCreate
   * @summary return list of tenant is activated or not
   * @request POST:/internal/tenant_activation_statuses
   */
  tenantActivationStatusesCreate = (body: PresenterTenantActivationStatusesRequest, params: RequestParams = {}) =>
    this.http.request<PresenterTenantActivationStatusesResponse, any>({
      path: `/internal/tenant_activation_statuses`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
