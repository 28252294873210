import mapValues from 'lodash/mapValues'
import React, { useLayoutEffect, useMemo, useRef } from 'react'
import { SubmitDestinationInfo } from 'store/accounting/depreciationFixedAsset/model'

import { calcEraYear, numberThousandSplit } from '../helpers'
import FormData from './FormData'
import { SvgFormWrapper } from './styles'

function formatData(data: any): any {
  return mapValues(data, (value, key) => {
    if (key.startsWith('acquisition_cost_')) {
      return formatData(value)
    }
    if (key === 'asset_types' && Array.isArray(value)) {
      return value.map(formatData)
    }
    if (key === 'total' && typeof value === 'number') return numberThousandSplit(value)
    return value
  })
}

const formatFormData = (data: Props) => {
  const result = formatData(data)
  const submitDestination = result?.submit_destination || {}
  const company_established_at_year =
    typeof submitDestination?.company_established_at_year === 'number' &&
    submitDestination?.company_established_at_year > 0
      ? submitDestination?.company_established_at_year
      : ''
  const company_established_at_month =
    company_established_at_year > 0 &&
    typeof submitDestination?.company_established_at_month === 'number' &&
    submitDestination?.company_established_at_month > 0
      ? submitDestination?.company_established_at_month
      : ''
  // unit for `capital_amount` is 1_000_000 (百万円)
  const capital_amount = `${submitDestination.capital_amount || ''}`.slice(0, -6) || ''
  const assetTypeAmounts = result?.asset_type_amounts?.map((assetType: string[][]) => assetType.flat())

  return {
    ...result,
    year: calcEraYear(data.year),
    asset_type_amounts: assetTypeAmounts,
    submit_destination: {
      ...submitDestination,
      company_established_at_year,
      company_established_at_month,
      capital_amount,
      person_in_charge_department_and_name: `${submitDestination.person_in_charge_department || ''}\u3000${
        submitDestination.person_in_charge_name || ''
      }`,
    },
  }
}

type Props = {
  year: number
  submit_destination: SubmitDestinationInfo
  acquisition_cost_previous_year: any
  acquisition_cost_decreased_current_year: any
  acquisition_cost_increased_current_year: any
  isAllFixedAssetMode?: boolean
}

function useAutoFontSizeEffect(data: any, ref: React.MutableRefObject<HTMLElement | undefined>) {
  useLayoutEffect(() => {
    const targetElements = ref.current?.querySelectorAll('[data-js-autofontsize]') as NodeListOf<HTMLDivElement>
    Array.from(targetElements)?.forEach(element => {
      const style = getComputedStyle(element)
      const width = parseFloat(style.width)
      const fontSize = parseFloat(style.fontSize)
      const textContent = element?.textContent || ''
      if (fontSize * textContent.length > width) {
        element.style.fontSize = `${width / textContent.length}px`
        element.style.lineHeight = style.height
      }
    })
  }, [data, ref])
}

export default React.memo(function Form({ isAllFixedAssetMode, ...props }: Props) {
  const formElRef = useRef<HTMLElement>()
  const data = useMemo(() => formatFormData(props), [props])

  useAutoFontSizeEffect(data, formElRef)
  return (
    <SvgFormWrapper>
      <FormData {...data} isAllFixedAssetMode={isAllFixedAssetMode} ref={formElRef} />
    </SvgFormWrapper>
  )
})
