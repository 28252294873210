import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Button } from 'antd'

export const ActionButton = styled(Button)`
  border: none;
  background: transparent;
  box-shadow: none;

  &.edit {
    color: #3b7de9;
  }
  &.delete {
    color: red;
  }
  &[disabled] {
    color: #00000040 !important;
    background: transparent;
  }
  &:hover {
    background: transparent;
  }
`

export const TableClass = css`
  word-break: break-all;
`
