import { initAsyncSlice, initSlice } from 'aa_common/front-end/zustand'
import { produce } from 'immer'

export enum BulkChangeEvent {
  START_USAGE = 'start_usage',
  MOVEMENT = 'movement',
  RETIREMENT = 'retirement',
  CHANGE_ASSET_TYPE = 'change_asset_type',
}

type RowItem = {
  id: string
  isSelected: boolean
}

export type ImportExportEventTypeSelectorState = {
  exportEventType: number | null
  downloadTemplateType: number | null
  importEventType: number | null
  templateCharset: string | null
}

export const initState: any = {
  idMarkAlls: [],
  markAll: false,
  selectAllOnePage: false,
  selectedItem: {},
  bulkChangeEvent: null,
  errorsBulkChange: null,
  errorBiids: [],
}

const importExportEventTypeSelectorInitState: ImportExportEventTypeSelectorState = {
  exportEventType: null,
  downloadTemplateType: null,
  importEventType: null,
  templateCharset: null,
}

// ALP = AssetListPage
export const rowSelectSliceAssetList = initAsyncSlice('ALP/row-selected', initState)
export const importExportEventTypeSelectorSlice = initSlice('ALP/import-export', importExportEventTypeSelectorInitState)

export const addSelectRows = (items: RowItem[]) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      items.forEach((item: RowItem) => {
        draft.selectedItem[item.id] = item.isSelected
      })
    })
  )
}

export const resetSelectRows = () => {
  rowSelectSliceAssetList.setData(initState)
}

export const markAllRows = (status: boolean) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      draft.markAll = status
    })
  )
}

export const setIdMarkAll = (ids: string[]) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      draft.idMarkAlls = ids
      ids.forEach((id: string) => {
        draft.selectedItem[id] = true
      })
      draft.markAll = false
    })
  )
}

export const markAllOnePage = (status: boolean) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      draft.selectAllOnePage = status
    })
  )
}

export const setBulkChangeEvent = (event?: BulkChangeEvent) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      draft.bulkChangeEvent = event
      if (!event) {
        draft.errorsBulkChange = null
        draft.errorBiids = []
      }
    })
  )
}

export const setErrorsBulkChange = (errors: any) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      draft.errorsBulkChange = errors
    })
  )
}

export const setErrorBiids = (errorBiids: any) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      draft.errorBiids = errorBiids
    })
  )
}

export const unSelectAllRowOnPage = (ids: string[]) => {
  rowSelectSliceAssetList.setData((data: any) =>
    produce(data, (draft: any) => {
      ids.forEach((id: string) => {
        draft.selectedItem[id] = false
      })
    })
  )
}

export const assetListPageStore = {
  rowSelectSliceAssetList,
  resetSelectRows,
  addSelectRows,
  markAllRows,
  setIdMarkAll,
  markAllOnePage,
  setBulkChangeEvent,
  setErrorsBulkChange,
  setErrorBiids,
  unSelectAllRowOnPage,
}

const setExportEventType = (exportEventType: number) => importExportEventTypeSelectorSlice.setState({ exportEventType })

const setDownloadTemplateType = (downloadTemplateType: number) =>
  importExportEventTypeSelectorSlice.setState({ downloadTemplateType })

const setImportEventType = (importEventType: number) => importExportEventTypeSelectorSlice.setState({ importEventType })

const setTemplateCharset = (templateCharset: string) => importExportEventTypeSelectorSlice.setState({ templateCharset })

export const importExportStore = {
  setExportEventType,
  setDownloadTemplateType,
  setImportEventType,
  setTemplateCharset,
}
