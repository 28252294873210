import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { mapCoordinatesWithData } from '../helpers'

export const AssetTypeAmounts = ({ assetTypeAmounts }: { assetTypeAmounts: string[][] }) => {
  const data = mapCoordinatesWithData(assetTypeAmounts, yCoordinates, cellConfig)

  return data?.map(({ x, y, value }) => (
    <g key={uniqueId()}>
      <foreignObject x={x} y={y} width="32" height="19">
        <div className="text-center">{value}</div>
      </foreignObject>
    </g>
  ))
}

// x coordinate will correspond to each column
// y coordinate will correspond to each row
// As you can see, we will have 12 columns and 6 rows such as zones 1, 2, 3 on ticket https://moneyforwardvietnam.atlassian.net/browse/AA-9345
const yCoordinates = [515, 533, 551, 569, 587, 605, 623]
const cellConfig = [
  { x: 120 },
  { x: 151 },
  { x: 182 },
  { x: 213 },
  { x: 258 },
  { x: 289 },
  { x: 321 },
  { x: 352 },
  { x: 399 },
  { x: 430 },
  { x: 462 },
  { x: 493 },
]
