export const FETCH_ASSET_ITEM_COMMENTS_REQUEST = 'FETCH_ASSET_ITEM_COMMENTS_REQUEST'
export const FETCH_ASSET_ITEM_COMMENTS_SUCCESS = 'FETCH_ASSET_ITEM_COMMENTS_SUCCESS'
export const FETCH_ASSET_ITEM_COMMENTS_FAILURE = 'FETCH_ASSET_ITEM_COMMENTS_FAILURE'

export const FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST = 'FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST'
export const FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_SUCCESS = 'FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_SUCCESS'
export const FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_FAILURE = 'FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_FAILURE'

export const CREATE_ASSET_COMMENT_REQUEST = 'CREATE_ASSET_COMMENT_REQUEST'
export const CREATE_ASSET_COMMENT_SUCCESS = 'CREATE_ASSET_COMMENT_SUCCESS'

export const CREATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST = 'CREATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST'
export const CREATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS = 'CREATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS'

export const DELETE_ASSET_ITEM_COMMENT_REQUEST = 'DELETE_ASSET_ITEM_COMMENT_REQUEST'
export const DELETE_ASSET_ITEM_COMMENT_SUCCESS = 'DELETE_ASSET_ITEM_COMMENT_SUCCESS'
export const DELETE_ASSET_ITEM_COMMENT_FAILURE = 'DELETE_ASSET_ITEM_COMMENT_FAILURE'

export const DELETE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST = 'DELETE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST'
export const DELETE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS = 'DELETE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS'

export const UPDATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST = 'UPDATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST'
export const UPDATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS = 'UPDATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS'

export const UPDATE_ASSET_ITEM_COMMENT_REQUEST = 'UPDATE_ASSET_COMMENT_REQUEST'
export const UPDATE_ASSET_ITEM_COMMENT_SUCCESS = 'UPDATE_ASSET_COMMENT_SUCCESS'

export const SUBMIT_ASSET_COMMENT_FAILURE = 'SUBMIT_ASSET_COMMENT_FAILURE'

export const CLEANUP_ASSET_COMMENT = 'CLEANUP_ASSET_COMMENT'
