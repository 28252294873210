import { css } from '@emotion/css'
import styled from '@emotion/styled'

export const ModalClass = css`
  .ant-drawer-content {
    .ant-drawer-body {
      padding: 15px 55px 15px 25px;

      .align-start {
        align-items: flex-start;
        .label-wrapper {
          padding-top: 4px;
        }
      }

      .footer-form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          margin-right: 10px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .impairment-book-value {
        .ledger-name {
          text-align: left;
          padding-right: 5px;

          .ant-typography {
            color: inherit;
          }
        }

        .value {
          text-align: right;
        }
      }
    }
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100% !important;

  > .auto-complete-select-wrapper,
  > .ant-input {
    width: 240px;
  }
`
