import styled from '@emotion/styled'

export const StyledWrapper = styled.div<{ isExportMultiple?: boolean }>`
  margin: 15px 0;

  .first {
    width: ${props => (props.isExportMultiple ? '40px' : 'auto')};
  }

  .link {
    color: #3b7de9;
    cursor: pointer;
  }
`

export const Modal = styled.div`
  .ant-spin-dot {
    color: #3b7de9;
  }

  .ant-spin-container {
    max-height: 500px;
    overflow-y: scroll;
  }
`
