import { Button } from 'aa_common/front-end/antd'
import { CSVCharsetRadios } from 'aa_common/front-end/components'
import { Modal, Radio } from 'antd'
import { CsvCharset, JournalCSVFormat } from 'common/models'
import i18n from 'i18n'
import React, { useState } from 'react'

import { modalWrapperClass } from './styles'

const formats = [
  { label: i18n.t('components.JournalList.export_modal.standard'), value: 'standard' },
  { label: i18n.t('components.JournalList.export_modal.acc_plus'), value: 'plus' },
  { label: i18n.t('components.JournalList.export_modal.acc_smb'), value: 'smb' },
  { label: i18n.t('components.JournalList.export_modal.standard_vertical'), value: 'external' },
]

interface ExportModalProps {
  journalCount: number
  selectJournalFormat?: boolean
  isShow: boolean
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>
  onExportClick: (event: { format: JournalCSVFormat; charset: CsvCharset }) => void
}

const JournalExportModal = ({
  journalCount,
  selectJournalFormat = true,
  isShow,
  setIsShowModal,
  onExportClick,
}: ExportModalProps) => {
  const [fmt, setFormat] = useState<JournalCSVFormat>('standard')
  const [charset, setCharset] = useState<CsvCharset>('utf-8')

  const handleExport = () => {
    onExportClick({ format: fmt, charset })
    setIsShowModal(false)
  }

  const handleCancel = () => setIsShowModal(false)

  return (
    <Modal
      title={i18n.t('common.form_values.export_modal_title', { name: i18n.t('components.JournalList.journal') })}
      visible={isShow}
      onCancel={handleCancel}
      width={540}
      footer={[
        <Button key="back" className="action-button" color="grey" onClick={handleCancel}>
          {i18n.t('common.actions.cancel')}
        </Button>,
        <Button key="submit" className="action-button" onClick={handleExport}>
          {i18n.t('components.ActionsList.export')}
        </Button>,
      ]}
      wrapClassName={modalWrapperClass}
      centered
    >
      <div>
        {i18n.t('components.JournalList.export_modal.export_message_with_count', {
          num: journalCount,
          name: i18n.t('components.JournalList.journal'),
        })}
      </div>
      {selectJournalFormat && (
        <div style={{ marginBottom: 28 }}>
          <div style={{ marginBottom: 17 }}>
            <span>{i18n.t('components.JournalList.export_modal.select_format')}</span>
          </div>
          <Radio.Group
            style={{ verticalAlign: 'middle' }}
            onChange={e => setFormat(e.target.value)}
            defaultValue="standard"
          >
            {formats.map(field => (
              <Radio value={field.value} key={field.value}>
                {field.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      )}
      <div>
        <div style={{ marginBottom: 17 }}>
          <span>{i18n.t('components.JournalList.export_modal.select_charset')}</span>
        </div>
        <CSVCharsetRadios defaultCharset="utf-8" handleChange={(charset: CsvCharset) => setCharset(charset)} />
      </div>
    </Modal>
  )
}

export default JournalExportModal
