import * as assetApi from 'api/app/asset'
import { checkRetroActiveFixedAsset, getAssetPayload } from 'common/helpers'
import useRetroactiveModal from 'components/templates/modal/RetroactiveAssetModal'
import useResource from 'lib/hooks/useResource'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { createAsset } from 'store/asset/actions'

import { checkError, handleResponse, removeImpairInfoLedger } from '../helpers'

export const useHandleSubmit = () => {
  const dispatch = useDispatch()
  const [asset, setAsset] = useState()
  const [showRequiredError, setShowRequiredError] = useState<boolean>(false)
  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const isValidateAssetLoading = validateAssetRes.isLoading
  const [validateErrors, setValidateErrors] = useState(null)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const confirmModal = useRetroactiveModal()

  const handleSubmit = (assetItemParam: any) => {
    const assetItem = removeImpairInfoLedger(assetItemParam)

    setAsset(assetItem)

    if (checkError(assetItem, setShowRequiredError)) return

    const assetItemParams = getAssetPayload(assetItem)
    validateAsset({ ...assetItemParams, dry_run: true })
      .then(res => {
        handleResponse(res, setValidateErrors)
        return checkRetroActiveFixedAsset({
          res,
          assetItem,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then(isOK => {
        isOK === true && dispatch(createAsset(assetItem))
      })
  }
  return {
    handleSubmit,
    asset,
    showRequiredError,
    isValidateAssetLoading,
    validateErrors,
  }
}
