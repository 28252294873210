import { UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import { useMemo } from 'react'

import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { useReferValue } from '../hooks/useReferValue'
import { useResetInput } from '../hooks/useResetInput'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type BookValueAtChangeProps = {
  enabled?: boolean
  isShown: boolean
  fieldName: string
  referValue?: number | null
  isUpdateReferManually?: boolean
  forceResetToRefer?: boolean
}

export const BookValueAtChange = ({
  enabled,
  isShown,
  fieldName,
  referValue,
  isUpdateReferManually,
  forceResetToRefer,
}: React.PropsWithChildren<BookValueAtChangeProps>) => {
  const { values, setFieldValue } = useFormikContext()
  const value = getIn(values, fieldName)

  // Prevent referring value when initializing with data
  const canUpdateOnRefer = useMemo(() => {
    return isShown && !!isUpdateReferManually
  }, [isShown, isUpdateReferManually])

  useReferValue(fieldName, setFieldValue, value, referValue, canUpdateOnRefer, forceResetToRefer)
  useClearValueOnHide(fieldName, setFieldValue, isShown)
  const { hasUpdate, handleReset } = useResetInput(fieldName, value, setFieldValue, referValue, isNumberHaveValue)

  if (!isShown) {
    return null
  }

  return (
    <Item
      label={
        <ItemLabelTooltip
          tooltipMaxWidth={620}
          label={parser(t('book_value_at_change'))}
          tooltip={parser(t('book_value_at_change_tooltip'))}
        />
      }
    >
      <UnderlineMoneyInput
        style={{ textAlign: 'right' }}
        name={fieldName}
        editable={enabled}
        isResetManualValue={hasUpdate && enabled}
        onHandleClickOnReset={handleReset}
        defaultValue={referValue || 0}
      />
    </Item>
  )
}
