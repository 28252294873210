import { ifElse } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { FORM_MODES } from 'common/models'
import { useFormikContext } from 'formik'
import { useCallback } from 'react'

import { getServiceLifeMinValue, getValueWithMin } from './helpers'

export const useDepreciationChanged = (type: FORM_MODES, serviceLife?: number) => {
  const { setFieldValue } = useFormikContext()
  const serviceLifeField = 'service_life'

  return useCallback(
    (value?: string) => {
      if (!value) {
        const serviceLifeValue = getValueWithMin(
          getServiceLifeMinValue(),
          ifElse(type === FORM_MODES.EDIT, serviceLife, undefined)
        )
        setFieldValue(serviceLifeField, serviceLifeValue)
      } else {
        const depreciationCode = parseInt(value, 10)
        const serviceLifeValue = getValueWithMin(
          getServiceLifeMinValue(depreciationCode),
          ifElse(type === FORM_MODES.EDIT, serviceLife, undefined)
        )
        switch (depreciationCode) {
          case DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD:
          case DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE:
          case DEPRECIATION_METHOD_CODE.NON_DEPRECIATION:
            setFieldValue('service_life', undefined)
            break
          case DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD:
            setFieldValue('service_life', 3)
            break
          default:
            setFieldValue('service_life', serviceLifeValue)
            break
        }
      }
    },
    [type, serviceLife, setFieldValue]
  )
}
