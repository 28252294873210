/* eslint-disable no-restricted-syntax */
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

export function getFixedAssetFilterPayload(payload: Record<string, any>) {
  const { asset_category, area, department, depreciation_method, tag, asset_status, ...rest } = payload
  // convert to array of id
  // eslint-disable-next-line
  const submitPayload = Object.assign({}, rest, {
    area_biid: map(area, 'value'),
    asset_category_id: map(asset_category, 'value'),
    asset_status: map(asset_status, 'value'),
    department_biid: map(department, 'value'),
    depreciation_method_code: map(depreciation_method, 'value'),
    tag: map(tag, 'value'),
  })

  for (const field in submitPayload) {
    if (
      !submitPayload[field] ||
      submitPayload[field] === '' ||
      !submitPayload[field]?.length ||
      isEmpty(submitPayload[field])
    ) {
      submitPayload[field] = undefined
    }
  }
  return submitPayload
}
