import { Map } from 'immutable'

import { CauseActionTypes } from './action.model'
import {
  CLEANUP_CAUSE,
  CREATE_CAUSE_REQUEST,
  EXPORT_CAUSE_FAILURE,
  EXPORT_CAUSE_REQUEST,
  EXPORT_CAUSE_SUCCESS,
  FETCH_CAUSE_FAILURE,
  FETCH_CAUSE_REQUEST,
  FETCH_CAUSE_SUCCESS,
  FETCH_CAUSES_FAILURE,
  FETCH_CAUSES_REQUEST,
  FETCH_CAUSES_REQUEST_AUTH,
  FETCH_CAUSES_SUCCESS,
  IMPORT_CAUSE_FAILURE,
  IMPORT_CAUSE_REQUEST,
  IMPORT_CAUSE_SUCCESS,
  SUBMIT_CAUSE_FAILURE,
  SUBMIT_CAUSE_SUCCESS,
  UPDATE_CAUSE_REQUEST,
} from './constant'

const initialState = Map({
  list: Map({
    data: null,
    loading: false,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  export: {
    loading: false,
  },
  import: {
    loading: false,
    error: {
      message: '',
      details: [],
    },
  },
})

export default (state = initialState, action: CauseActionTypes) => {
  switch (action.type) {
    case FETCH_CAUSES_REQUEST:
    case FETCH_CAUSES_REQUEST_AUTH:
      return state.setIn(['list', 'loading'], true)
    case FETCH_CAUSES_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_CAUSES_FAILURE:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'data'], null)
        .setIn(['list', 'errors'], action.payload)
    case FETCH_CAUSE_REQUEST:
    case CREATE_CAUSE_REQUEST:
    case UPDATE_CAUSE_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_CAUSE_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case FETCH_CAUSE_FAILURE:
    case SUBMIT_CAUSE_SUCCESS:
      return state.setIn(['item', 'loading'], false)
    case SUBMIT_CAUSE_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.payload)
    case EXPORT_CAUSE_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_CAUSE_SUCCESS:
    case EXPORT_CAUSE_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case IMPORT_CAUSE_REQUEST:
      return state.setIn(['import', 'loading'], true)
    case IMPORT_CAUSE_SUCCESS:
      return state
        .setIn(['import', 'loading'], false)
        .setIn(['import', 'error'], initialState.getIn(['import', 'error']))
    case IMPORT_CAUSE_FAILURE:
      return state.setIn(['import', 'loading'], false).setIn(['import', 'error'], action.payload)
    case CLEANUP_CAUSE:
      return initialState
    default:
      return state
  }
}
