import { FetchOperationLogItemRequest, FetchOperationLogsRequest } from './action.model'
import {
  CLEANUP_OPERATION_LOG,
  CLEANUP_OPERATION_LOG_ITEM,
  FETCH_OPERATION_LOG_ITEM_REQUEST,
  FETCH_OPERATION_LOGS_REQUEST,
} from './constant'

interface IFetchOperationLogs {
  (params: any, pageNumber: number, pageSize: number): FetchOperationLogsRequest
}
export const fetchOperationLogs: IFetchOperationLogs = (params, pageNumber, pageSize): FetchOperationLogsRequest => {
  return {
    type: FETCH_OPERATION_LOGS_REQUEST,
    params,
    pageNumber,
    pageSize,
  }
}

export const fetchOperationLogItem = (biid: string, id: number): FetchOperationLogItemRequest => {
  return {
    type: FETCH_OPERATION_LOG_ITEM_REQUEST,
    payload: {
      biid,
      id,
    },
  }
}

export const cleanupOperationLogItem = () => ({ type: CLEANUP_OPERATION_LOG_ITEM })

export const cleanupOperationLog = () => ({ type: CLEANUP_OPERATION_LOG })
