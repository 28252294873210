export default {
  assets: require('common/locales/ja/pages/assets'),
  user: {
    TermOfUse: require('common/locales/ja/pages/user/term-of-use'),
    LoginPage: require('common/locales/ja/pages/user/login-page'),
    CreateAccountingPeriod: require('common/locales/ja/pages/user/create-accounting-period'),
  },
  office: {
    New: require('common/locales/ja/pages/office/new'),
  },
  internalControl: {
    operationLog: require('common/locales/ja/pages/internal-control/operation-log'),
  },
  settings: {
    Office: require('common/locales/ja/pages/settings/office'),
    Areas: require('common/locales/ja/pages/settings/areas'),
    Roles: require('common/locales/ja/pages/settings/roles'),
    Users: require('common/locales/ja/pages/settings/users'),
    AssetCategories: require('common/locales/ja/pages/settings/asset-categories'),
    Causes: require('common/locales/ja/pages/settings/causes'),
    Excise: require('common/locales/ja/pages/settings/excise'),
    SubmitDestinations: require('common/locales/ja/pages/settings/submit-destinations'),
    Tags: require('common/locales/ja/pages/settings/tags'),
    Units: require('common/locales/ja/pages/settings/units'),
    Departments: require('common/locales/ja/pages/settings/departments'),
    AssetCode: require('common/locales/ja/pages/settings/asset-code'),
    ExciseSetting: require('common/locales/ja/pages/settings/excise-setting'),
    ReCalculate: require('common/locales/ja/pages/settings/recalculate'),
    Import: require('common/locales/ja/pages/settings/import'),
  },
  accounting: {
    AutoJournalSettings: require('common/locales/ja/pages/accounting/auto-journal-setting-page'),
    IncreaseDecreaseReport: require('common/locales/ja/pages/accounting/increase-decrease-report'),
    JournalList: require('common/locales/ja/pages/accounting/journal-list'),
    HistoryList: require('common/locales/ja/pages/accounting/history-list'),
    DepreciationFixedAssetPage: require('common/locales/ja/pages/accounting/depreciation-fixed-asset-page'),
    UnderOverDepreciationPage: require('common/locales/ja/pages/accounting/under-over-depreciatioin-page'),
  },
  errorPages: require('common/locales/ja/pages/error-pages'),
}
