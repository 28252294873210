import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoCompleteSelect, Button, Tooltip } from 'aa_common/front-end/antd'
import { ColorLabel, If, LinkLabel } from 'aa_common/front-end/components'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FEATURES_ID } from 'common/constants'
import {
  checkSettingBelongToMonthlyClosing,
  checkTermBelongToTermSpan,
  generateTermsOptions,
  getNextTermId,
} from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { Excise, ExciseSetting, Term } from 'common/models'
import { LineAddNew } from 'components/atoms'
import useUserPermission from 'lib/hooks/useUserPermission'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectExciseSettings } from 'store/masterData/selectors'
import { fetchExciseList } from 'store/settings/excise/actions'
import { selectExciseList } from 'store/settings/excise/selectors'

import {
  displayPeriod,
  getEditableText,
  getExciseText,
  getExecuteText,
  getInputTaxMethod,
  getRoundingMethodText,
  getTaxMethodText,
  renderOtherSettingsCount,
} from './helper'
import { LastRow, Row, Table, TitleLineStyle, TopInfo, Wrapper } from './styles'

const t = loadNs([
  'common',
  'components/organisms/settings/excise-setting-view',
  'components/organisms/settings/excise-setting-form-modal',
])

type CallbackFn = (setting: ExciseSetting) => void
type Props = {
  terms: Term[]
  onClickTopEdit: CallbackFn
  onClickAdd: CallbackFn
  onClickEdit: CallbackFn
  onClickDelete: CallbackFn
  exciseSettingListData: ExciseSetting[]
}

const showPermissionMessage = (permission: boolean) => (!permission ? t('actions.no_permission') : undefined)

const ExciseSettingView: React.FC<Props> = ({
  terms,
  onClickEdit,
  onClickDelete,
  onClickTopEdit,
  onClickAdd,
  exciseSettingListData,
}) => {
  const dispatch = useDispatch()
  const { permissions } = useUserPermission(FEATURES_ID.SETTING_EXCISE)

  const exciseMasterSettings = useSelector(selectExciseSettings, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const exciseList = useSelector<any, Excise[]>(selectExciseList, isEqual)

  const [isShowFullYears, setIsShowFullYears] = useState<boolean>(false)
  const [termYearFilter, setTermYearFilter] = useState<number | null>(null)

  const handleCheckAllYears = (e: CheckboxChangeEvent) => {
    setIsShowFullYears(e.target.checked)
  }

  const termsItems = useMemo(() => generateTermsOptions(terms), [terms])

  useEffect(() => {
    if (!termYearFilter && termsItems.length) {
      setTermYearFilter(currentMonthlyClosing?.from_term_month?.term_id)
    }
  }, [termsItems, currentMonthlyClosing, termYearFilter])

  const exciseSettingSorted = useMemo(() => {
    return exciseSettingListData?.sort((a, b) => b.from_term_id - a.from_term_id)
  }, [exciseSettingListData])

  const settingList = useMemo(() => {
    if (exciseSettingListData?.length > 0 && terms?.length > 0) {
      if (isShowFullYears) {
        return exciseSettingSorted
      }

      if (termYearFilter) {
        const term = terms.find(term => term.id === termYearFilter)
        return exciseSettingListData.filter(setting => checkTermBelongToTermSpan(setting, term))
      }

      if (currentMonthlyClosing) {
        return exciseSettingListData
          .filter(setting => checkSettingBelongToMonthlyClosing(setting, currentMonthlyClosing))
          .slice(-1)
      }
    }

    return []
  }, [isShowFullYears, exciseSettingListData, currentMonthlyClosing, termYearFilter, terms, exciseSettingSorted])

  useEffect(() => {
    const filters = { is_active: true }
    dispatch(fetchExciseList({ filters }))
  }, [dispatch])

  return (
    <Wrapper>
      <TopInfo>
        <div>
          <AutoCompleteSelect
            textField="text"
            valueField="value"
            name="term_year_filter"
            allowClear={false}
            items={termsItems}
            disabled={!termsItems.length || isShowFullYears}
            value={termYearFilter}
            onChange={setTermYearFilter}
          />
          <ColorLabel color={isShowFullYears ? 'disabled' : 'normal'}>{t('setting')}</ColorLabel>
          <Checkbox checked={isShowFullYears} onChange={handleCheckAllYears}>
            <span>{t('display_all')}</span>
            <ColorLabel color={isShowFullYears ? 'disabled' : 'normal'}>
              {renderOtherSettingsCount(exciseSettingSorted, currentMonthlyClosing)}
            </ColorLabel>
          </Checkbox>
        </div>
        <Button
          color="grey"
          size="custom"
          showTooltipMessage={showPermissionMessage(permissions.isEdit)}
          style={{ width: 74 }}
          disabled={isShowFullYears || !permissions.isEdit || settingList[0]?.to_term !== null}
          onClick={() => {
            onClickTopEdit({ ...settingList[0] })
          }}
        >
          {t('actions.edit')}
        </Button>
      </TopInfo>
      {isShowFullYears && (
        <LineAddNew
          showTooltipMessage={showPermissionMessage(permissions.isCRU)}
          disabled={!permissions.isCRU}
          onClick={() => {
            onClickAdd({
              ...settingList[0],
              from_term_id: getNextTermId(settingList[0]?.from_term_id, terms),
            })
          }}
        />
      )}
      {settingList &&
        settingList.map((settingItem: ExciseSetting, index) => (
          <div key={settingItem.id}>
            <Table style={{ marginBottom: 20 }}>
              <Row>
                <div className="label">{t('validity_period')}</div>
                <div>{displayPeriod(settingItem)}</div>
                <If condition={checkSettingBelongToMonthlyClosing(settingItem, currentMonthlyClosing)}>
                  <span
                    style={{
                      color: '#666',
                      marginLeft: '5px',
                    }}
                  >
                    {t('current_year')}
                  </span>
                </If>
              </Row>
              <Row>
                {getTaxMethodText(exciseMasterSettings, settingItem.tax_method) && (
                  <>
                    <div style={{ width: 58 }}>{t('tax_method')}</div>
                    <Tooltip
                      content={t('tooltip.tax_method')}
                      tooltipStyles={{ display: 'flex', alignItems: 'center' }}
                      placement="topRight"
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </>
                )}
                <div style={{ marginLeft: 124 }}>{getTaxMethodText(exciseMasterSettings, settingItem.tax_method)}</div>
              </Row>
              {getInputTaxMethod(exciseMasterSettings, settingItem.tax_input_format) && (
                <Row>
                  <div style={{ width: 58 }}>{t('input_format')}</div>
                  <Tooltip content={t('tooltip.input_format')}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }}
                    />
                  </Tooltip>
                  <div style={{ marginLeft: 124 }}>
                    {getInputTaxMethod(exciseMasterSettings, settingItem.tax_input_format)}
                  </div>
                </Row>
              )}
              {getRoundingMethodText(exciseMasterSettings, settingItem.rounding_method) && (
                <Row>
                  <div className="label">{t('rounding_method')}</div>
                  <div>{getRoundingMethodText(exciseMasterSettings, settingItem.rounding_method)}</div>
                </Row>
              )}
              {getEditableText(exciseMasterSettings, settingItem.can_edit_tax) && (
                <Row>
                  <div style={{ width: 122 }}>{t('editable')}</div>
                  <Tooltip content={t('tooltip.editable')}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }}
                    />
                  </Tooltip>
                  <div style={{ marginLeft: 61 }}>
                    {getEditableText(exciseMasterSettings, settingItem.can_edit_tax)}
                  </div>
                </Row>
              )}
              <Row style={{ padding: 0 }}>
                <div className={TitleLineStyle}>
                  <span style={{ color: '#7C8291', marginLeft: 9 }}>{t('excise_account')}</span>
                </div>
              </Row>
              <Row>
                <div style={{ width: 97 }}>{t('create_acquisition_journal')}</div>
                <Tooltip content={t('tooltip.create_acquisition_journal')}>
                  <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
                </Tooltip>
                <div style={{ marginLeft: 88 }}>
                  {getExecuteText(exciseMasterSettings, settingItem.automatic_journal_entry_accquisition_type)}
                </div>
              </Row>
              <Row>
                <div style={{ width: 108 }}>{t('input_vat')}</div>
                <Tooltip content={t('tooltip.input_vat')}>
                  <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
                </Tooltip>
                <div style={{ marginLeft: 76 }}>
                  {getExecuteText(exciseMasterSettings, settingItem.input_tax_when_register_fixed_asset)}
                </div>
              </Row>
              <Row style={{ padding: 0 }}>
                <div className={TitleLineStyle}>
                  <span style={{ color: '#7C8291', marginLeft: 9 }}>{t('excise_default_when_retire_or_sell')}</span>
                </div>
              </Row>
              <Row>
                <div className="label">{t('excise_for_deposal_cost')}</div>
                <div>{getExciseText(exciseList, settingItem.default_excise_disposal_cost_id)}</div>
              </Row>
              <Row>
                <div className="label">{t('excise_for_sell_cost')}</div>
                <div>{getExciseText(exciseList, settingItem.default_excise_sell_id)}</div>
              </Row>
              <If condition={isShowFullYears}>
                <LastRow>
                  <If condition={index === 0 && settingList.length > 1}>
                    <LinkLabel
                      showTooltipMessage={showPermissionMessage(permissions.isDelete)}
                      disabled={!permissions.isDelete}
                      type="danger"
                      onClick={() => onClickDelete(settingItem)}
                    >
                      {t('delete')}
                    </LinkLabel>
                  </If>
                  <LinkLabel
                    showTooltipMessage={showPermissionMessage(permissions.isEdit)}
                    disabled={!permissions.isEdit}
                    onClick={() => onClickEdit(settingItem)}
                  >
                    {t('edit')}
                  </LinkLabel>
                </LastRow>
              </If>
            </Table>
          </div>
        ))}
    </Wrapper>
  )
}

export default React.memo(ExciseSettingView)
