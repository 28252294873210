import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE, RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'common/constants'
import { MasterDataItem } from 'common/models'
import { find } from 'lodash'

import { DEPRECIATION_METHOD } from '../../depreciation/LedgerSettingsTable/model'
import { isNonDepreciationOrNonTarget } from '../AssetRetirementForm/helper'

type LedgerSettingCovertType = {
  ledgerSetting: any
  assetItem: any
  isEditMode: boolean
  retirementDepreciationEndedMethods: MasterDataItem[]
  hasAllTaxAccountant: boolean
}
export const checkAssetLedger = (assetLedger: any) => {
  const isPreviousDepreciationMethodContinue =
    assetLedger && assetLedger.bulk_equal_installment_depreciation_method === DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
  const isBulkEqualInstallment =
    assetLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
  const isSmallAmountDepreciableAsset =
    assetLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
  return { isPreviousDepreciationMethodContinue, isBulkEqualInstallment, isSmallAmountDepreciableAsset }
}
export const convertDataLedgerSettingHelper = ({
  ledgerSetting,
  assetItem,
  isEditMode,
  retirementDepreciationEndedMethods,
  hasAllTaxAccountant,
}: LedgerSettingCovertType) => {
  const isSelectWhenSellorRetire =
    ledgerSetting.retirement_depreciation_ended_method ===
    RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
  const isEndOfCurrentMonth =
    ledgerSetting.retirement_depreciation_ended_method === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH
  const isDepreciationMethodContinue =
    ledgerSetting.bulk_equal_installment_depreciation_method === DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
  const isEndOfLastMonth =
    ledgerSetting.retirement_depreciation_ended_method === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_MONTH
  const assetLedger = find(assetItem?.fixed_asset_ledgers, item => item.ledger_setting_id === ledgerSetting.id)

  const { isSmallAmountDepreciableAsset, isPreviousDepreciationMethodContinue, isBulkEqualInstallment } =
    checkAssetLedger(assetLedger)
  const currentDepreciationMethodContinue = isEditMode
    ? isPreviousDepreciationMethodContinue
    : isDepreciationMethodContinue

  const datasourceItems =
    currentDepreciationMethodContinue && isBulkEqualInstallment
      ? retirementDepreciationEndedMethods
      : retirementDepreciationEndedMethods.filter(item =>
          ledgerSetting.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING && hasAllTaxAccountant
            ? RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION !== item?.code
            : ![
                RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION,
                RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_YEAR,
              ].includes(item.code)
        )
  const isNonDepreciationOrNonTargetMethod = assetLedger && isNonDepreciationOrNonTarget(assetLedger)

  return {
    isSelectWhenSellorRetire,
    isEndOfCurrentMonth,
    isEndOfLastMonth,
    datasourceItems,
    isNonDepreciationOrNonTargetMethod,
    isSmallAmountDepreciableAsset,
    isBulkEqualInstallment,
    currentDepreciationMethodContinue,
  }
}
