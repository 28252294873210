import styled from '@emotion/styled'
import { Button } from 'aa_common/front-end/antd'
import { Dropdown, Menu, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { ComponentProps } from 'react'

const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
  menuSettings = [],
  color = 'blue',
  size = 'short',
  style,
  children,
  trigger = ['click'],
  disabled = false,
  onClick,
  tooltipMessage,
}) => {
  const buildList = (menuSettings: DropdownMenuSettings[]): React.ReactElement => (
    <Menu className="dropdown-menu-button">
      {menuSettings.map(menu => {
        return (
          <Menu.Item key={menu.label} onClick={menu.onClick} disabled={menu.disabled}>
            {!menu.tooltip ? menu.label : <Tooltip {...menu.tooltip}>{menu.label}</Tooltip>}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return (
    <StyledDropdown
      overlay={menuSettings.length > 0 ? buildList(menuSettings) : <div />}
      mouseLeaveDelay={0.3}
      trigger={trigger}
      disabled={disabled}
      className={classNames({ disabled })}
    >
      <Tooltip placement="top" title={tooltipMessage || ''}>
        <Button color={color} size={size} style={style} onClick={onClick}>
          {children}
        </Button>
      </Tooltip>
    </StyledDropdown>
  )
}

type AntTooltipProps = ComponentProps<typeof Tooltip>
export type DropdownMenuSettings = {
  label: string
  disabled?: boolean
  onClick?: () => void
  tooltip?: AntTooltipProps
}

type DropdownMenuButtonProps = {
  id?: string
  menuSettings?: DropdownMenuSettings[]
  color?: 'blue' | 'grey'
  size?: 'shorter' | 'short' | 'long' | 'medium' | 'big' | 'custom'
  style?: React.CSSProperties
  trigger?: ('click' | 'hover' | 'contextMenu')[]
  disabled?: boolean
  onClick?: () => void
  tooltipMessage?: string
}

const StyledDropdown = styled(Dropdown)`
  &.disabled {
    background: #d4d8dd !important;
    color: #999999 !important;
    border: 1px solid rgba(0, 0, 0, 0.15);
    cursor: not-allowed;
  }
`

export default DropdownMenuButton
