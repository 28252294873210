import { Spin } from 'aa_common/front-end/antd'
import { SSO_REDIRECT_PATH_KEY, SSO_TENANT_UID_KEY, URL_AA_LANDING_PAGE } from 'common/constants'
import { SessionTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { redirectUrl } from 'store/app/actions'

import { buildMFIDAuthURL } from '../helper'
import { Wrapper } from './styles'

const SSOPage = () => {
  const dispatch = useDispatch()
  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const tenantUid = params.get('tenant_uid')
    const redirectPath = params.get('redirect_path')
    const loginHint = params.get('login_hint')

    if (!tenantUid) {
      dispatch(redirectUrl(URL_AA_LANDING_PAGE))
      return
    }

    sessionStorage.setItem(SSO_TENANT_UID_KEY, tenantUid)

    if (redirectPath) {
      dispatch(redirectUrl(redirectPath))
      sessionStorage.setItem(SSO_REDIRECT_PATH_KEY, redirectPath)
      return
    }

    const mfidUrl = buildMFIDAuthURL(loginHint)
    dispatch(redirectUrl(mfidUrl))
  }, []) // eslint-disable-line

  return (
    <SessionTemplate>
      <Spin loading size="large" text="Loading ...">
        <Wrapper />
      </Spin>
    </SessionTemplate>
  )
}

export default SSOPage
