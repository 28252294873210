import { LinkLabel } from 'aa_common/front-end'
import { FEATURES_ID } from 'common/constants'
import { formatMonth } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import useUserPermission from 'lib/hooks/useUserPermission'
import { isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'

import {
  DepreciationSettingTableView,
  GetSettingTableView,
  ImpairmentTableView,
  RetirementSettingTableView,
  SaleSettingTableView,
} from '../tables'
import { ItemWrapper } from './styles'

interface ModalAutoJournalSettingListItemProps {
  item: any
  currentSettingItem: any
  isDetailMode: boolean
  onModifyClick: (item: any) => void
  onDeleteClick: (item: any) => void
}

type BaseModalAutoJournalSettingListItem = Omit<ModalAutoJournalSettingListItemProps, 'ref'>
const t = loadNs([
  'common',
  'components/organisms/accounting/auto-journal-setting-tab',
  'components/organisms/accounting/general-journal-setting-tab',
])
const ModalAutoJournalSettingListItem = React.forwardRef<any, BaseModalAutoJournalSettingListItem>(function ListItem(
  { item, currentSettingItem, isDetailMode, onModifyClick, onDeleteClick },
  ref: any
) {
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const {
    permissions: { isCRU, isDelete },
  } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)

  const currentSettingsStartDate = moment(currentSettingItem?.from_term_month?.start_date)
  const currentSettingsEndDate = moment(currentSettingItem?.to_term_month?.end_date)
  const currentMonthlyClosingStartDate = moment(currentMonthlyClosing?.from_term_month.start_date)

  const isCurrent =
    (isDetailMode && currentSettingItem?.from_term_month?.id === item.from_term_month.id) ||
    (!isDetailMode &&
      currentMonthlyClosingStartDate.isBetween(currentSettingsStartDate, currentSettingsEndDate, 'date', '[]'))

  const isLatestSettings = item.from_term_month && !item.to_term_month
  const targetTermEndDate = item.to_term_month ? moment(item.to_term_month.end_date) : null
  const isPast = item.to_term_month && targetTermEndDate && currentSettingsStartDate.isAfter(targetTermEndDate, 'date')

  const renderValidFrom = () => {
    const validFrom = `${t('valid_period')}: ${t('format.term_year_month', {
      year: item.from_term_month.term.year,
      month: formatMonth(item.from_term_month.month),
    })}`
    if (item.to_term_month && item.from_term_month.id !== item.to_term_month.id) {
      const validTo = t('format.term_year_month', {
        year: item.to_term_month.term.year,
        month: formatMonth(item.to_term_month.month),
      })
      return `${validFrom}〜${validTo}`
    }
    if (item.to_term_month && item.from_term_month.id === item.to_term_month.id) {
      return validFrom
    }
    return `${validFrom}〜`
  }

  return (
    <ItemWrapper className="aa-journal-item" ref={ref}>
      <div className="header">
        <span className="period">
          {renderValidFrom()}
          {isCurrent && <span className="current">{t('status.the_processing_year_is_in_this_period')}</span>}
        </span>
      </div>
      <div className="body">
        <GetSettingTableView item={item} />
        <DepreciationSettingTableView item={item} />
        <ImpairmentTableView item={item} />
        <RetirementSettingTableView item={item} />
        <SaleSettingTableView item={item} />
      </div>
      {isDetailMode && (
        <div className="footer">
          {isLatestSettings && (
            <LinkLabel
              showTooltipMessage={!isDelete ? t('actions.no_permission') : undefined}
              disabled={!isDelete}
              tooltipSettings={{
                placement: 'topRight',
              }}
              type="danger"
              onClick={() => onDeleteClick(item)}
              data-testid="delete-button"
            >
              {t('actions.cancel_1')}
            </LinkLabel>
          )}
          {!isPast && (
            <LinkLabel
              showTooltipMessage={!isCRU ? t('actions.no_permission') : undefined}
              disabled={!isCRU}
              tooltipSettings={{
                placement: 'topRight',
              }}
              onClick={() => onModifyClick(item)}
              data-testid="modify-button"
            >
              {t('actions.modify')}
            </LinkLabel>
          )}
        </div>
      )}
    </ItemWrapper>
  )
})

export default ModalAutoJournalSettingListItem
