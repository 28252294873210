import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { hasEventOccurred, parseDataForForm } from 'common/helpers'
import { LedgerSetting } from 'common/models'
import { isEqual } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupAssetItem, fetchAsset } from 'store/asset/actions'
import { selectAssetItemData, selectEventHistories } from 'store/asset/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

export const useFetchAssetDetail = () => {
  const dispatch = useDispatch()
  const assetData = useSelector(selectAssetItemData, isEqual)

  const { biid } = useParams<any>()

  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)

  useEffect(() => {
    const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)

    dispatch(fetchAsset(biid, hasDividingEvent))
    !ledgerSettings && dispatch(fetchLedgerSettingsList({}))

    return function cleanup() {
      dispatch(cleanupAssetItem())
    }
  }, [biid])

  const parsedAssetDataForm = useMemo(() => {
    if (!assetData) return null

    return parseDataForForm({ ...assetData })
  }, [assetData])

  return { parsedAssetDataForm, assetData }
}
