import { loadNs } from 'common/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'
import { Row } from './row'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const RetirementSettingTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('retirement_settings_table')}>
      <tbody>
        <SharedRow item={item} event="retirement" />
        <Row
          title={t('retirement_loss')}
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            {
              name: 'retirement_debit_loss_account_item.name',
            },
            { name: 'retirement_debit_loss_account_sub_item.name' },
            {
              name: '',
              isThead: true,
              title: (
                <>
                  {t('payment_category')} <br />({t('disposal_cost')})
                </>
              ),
            },
            { name: 'retirement_credit_payment_category_account_item.name' },
            { name: 'retirement_credit_payment_category_account_sub_item.name' },
          ]}
        />
      </tbody>
    </JournalItemSettingTable>
  )
}

export default RetirementSettingTableView

export const SharedRow = ({ item, event }: { item: any; event: string }) => (
  <>
    <Row
      title={t('accumulated')}
      item={item}
      render={renderAccountNameFieldValue}
      fields={[
        { name: `${event}_debit_accumulated_depreciation_account_item.name` },
        { name: `${event}_debit_accumulated_depreciation_account_sub_item.name` },
        { name: '', isThead: true, title: t('asset_category') },
        { name: `${event}_credit_asset_category_account_item.name` },
        { name: `${event}_credit_asset_category_account_sub_item.name` },
      ]}
    />
    <Row
      title={t('accumulated_impairment_loss')}
      item={item}
      render={renderAccountNameFieldValue}
      fields={[
        { name: `${event}_debit_accumulated_impairment_account_item.name` },
        { name: `${event}_debit_accumulated_impairment_account_sub_item.name` },
        { name: '', isThead: true, title: '' },
        { name: '' },
      ]}
    />
  </>
)
