import { Spin } from 'aa_common/front-end/antd'
import { MainHeader } from 'aa_common/front-end/components'
import { Col, Layout, Row } from 'antd'
import classNames from 'classnames'
import { BreadcrumbNavigation } from 'components/molecules'
import TopMessage from 'components/organisms/app/TopMessage'
import MainMenu from 'components/organisms/MainMenu'
import React from 'react'

import {
  ActionWrapper,
  Body,
  BreadcrumbArea,
  FilterPanel,
  Header,
  MainTemplateLayout,
  SiteLayout,
  TopMessage as TopMessageWrapper,
  Wrapper,
} from './styles'

export interface MainTemplateProps {
  header?: React.ReactNode
  sidebar?: React.ReactNode
  breadcrumb?: React.ReactNode
  actions?: React.ReactNode
  topMessage?: React.ReactNode
  filterPanel?: React.ReactNode
  containerClassName?: string
  bodyLayoutClassName?: string
  isLoadingApp?: boolean
}

const MainTemplate: React.FC<MainTemplateProps> = ({
  children,
  containerClassName,
  bodyLayoutClassName,
  filterPanel,
  header,
  sidebar,
  breadcrumb,
  actions,
  topMessage,
  isLoadingApp = false,
}) => {
  return (
    <Wrapper>
      <Layout>
        <Header>{header}</Header>
        <Body>
          {sidebar}
          <MainTemplateLayout className={bodyLayoutClassName} id="app-main-body">
            {breadcrumb && (
              <BreadcrumbArea
                className={classNames({
                  'has-search-panel': !!filterPanel,
                })}
              >
                <Row>
                  <Col flex="1 1" style={{ minWidth: 0 }}>
                    {breadcrumb}
                  </Col>
                  {actions && (
                    <Col flex="0 1 auto" style={{ display: 'flex', alignItems: 'center' }}>
                      <ActionWrapper>{actions}</ActionWrapper>
                    </Col>
                  )}
                </Row>
                {filterPanel && <FilterPanel>{filterPanel}</FilterPanel>}
              </BreadcrumbArea>
            )}
            <TopMessageWrapper>{topMessage}</TopMessageWrapper>
            <SiteLayout className={containerClassName}>
              <Spin loading={isLoadingApp}>{children}</Spin>
            </SiteLayout>
          </MainTemplateLayout>
        </Body>
      </Layout>
    </Wrapper>
  )
}

MainTemplate.defaultProps = {
  header: <MainHeader />,
  sidebar: <MainMenu id="app-main-menu" />,
  breadcrumb: <BreadcrumbNavigation sectionName="Home" />,
  actions: undefined,
  topMessage: <TopMessage />,
  filterPanel: null,
}

export default MainTemplate
