/* eslint-disable jsx-a11y/control-has-associated-label */
import i18n from 'i18n'
import React from 'react'

import { StyledTable, TableTitle } from './styles'

const JournalItemSettingTable: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <div className="journal-item-setting-table">
      <TableTitle>{title}</TableTitle>
      <StyledTable>
        <thead>
          <tr>
            <th className="header-level-0" colSpan={3}>
              {i18n.t('components.AutoJournalSettings.debit')}
            </th>
            <th className="header-level-0" colSpan={3}>
              {i18n.t('components.AutoJournalSettings.credit')}
            </th>
          </tr>
          <tr>
            <th className="section-name" />
            <td className="category-header">{i18n.t('components.AutoJournalSettings.account_item')}</td>
            <td className="category-header">{i18n.t('components.AutoJournalSettings.account_subitem')}</td>
            <th className="section-name" />
            <td className="category-header">{i18n.t('components.AutoJournalSettings.account_item')}</td>
            <td className="category-header">{i18n.t('components.AutoJournalSettings.account_subitem')}</td>
          </tr>
        </thead>
        {children}
      </StyledTable>
    </div>
  )
}

export default JournalItemSettingTable
