import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { DatePicker } from 'antd'
import { YEAR_FORMAT } from 'common/constants'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  placeholder?: string
  name: string
  min?: number
  max?: number
  style?: React.CSSProperties
  allowClear?: boolean
  afterChanged?: (value: number | null) => void
}

const YearSelect = ({ placeholder = '', name, min, max, style, allowClear = true, afterChanged }: Props) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [value, setValue] = useState<Moment | null>(null)

  let isInValid = null

  if (get(errors, name)) {
    isInValid = true
  }

  const handleChange = (value: Moment | null) => {
    const year = value ? value.year() : null
    setValue(value)
    setFieldValue(name, year)
    afterChanged && afterChanged(year)
  }

  const disabledDate = useCallback(
    (current: Moment) => {
      if (min && get(values, name)) {
        if (current.year() < min) return true
      }

      if (max && get(values, name)) {
        if (current.year() > max) return true
      }

      return false
    },
    [values, min, max, name]
  )

  useEffect(() => {
    const yearValue = get(values, name)

    if (yearValue) {
      const year = moment(yearValue, YEAR_FORMAT)
      setValue(year)
    }
  }, []) // eslint-disable-line

  return (
    <Wrapper>
      <DatePicker
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className={isInValid ? 'error' : ''}
        picker="year"
        format={YEAR_FORMAT}
        disabledDate={min ? disabledDate : undefined}
        style={style}
        allowClear={allowClear}
      />
      {isInValid && <CommonErrorMsg>{get(errors, name)}</CommonErrorMsg>}
    </Wrapper>
  )
}

export default YearSelect
