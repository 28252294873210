import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const Wrapper = styled.div`
  padding: 0px;

  .color-grey.btn-delete {
    color: #ec4949;
    min-width: 70px;
  }

  .btn-confirm {
    min-width: 70px;
  }

  .btn-cancel {
    min-width: 85px;
  }
`

interface WrapperClassProps {
  closable: boolean
}
export const WrapperClass = (props: WrapperClassProps) => css`
  .ant-modal-header {
    padding: 0;
    border-radius: 4px 4px 0 0;

    h4.modal-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      margin-left: ${props.closable ? '24px' : 0};
    }
  }

  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-close {
      position: absolute;
      left: 0;
    }

    .ant-modal-close-x {
      font-size: 20px;
      width: 45px;
      height: 45px;
    }

    svg {
      margin-bottom: 1px;
    }
  }
`

export const TitleHeader = styled.div`
  padding: 15px;
  border-radius: 4px;
  background-color: #fafafa;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 15px;

  .anticon {
    font-size: 22px;
    color: #e31c1c;
    margin-right: 16px;
  }
`

export const Message = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 13px;
`

export const Actions = styled.div`
  text-align: right;
  padding: 15px;
  border-top: 1px solid #d4d8dd;

  button:last-of-type {
    margin-left: 10px;
  }
`
