import { message, Upload } from 'antd'
import { HttpRequestHeader, ItemRender, UploadFile } from 'antd/es/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { CONFIRM_TYPES, CSRF_TOKEN_KEY, FILE_UPLOAD_PATH, MAX_FILE_UPLOAD_SIZE } from 'common/constants'
import { validateFileSize } from 'common/helpers/file-helper'
import useModal from 'hooks/useModalHook'
import i18n from 'i18n'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button } from '../../../antd'
import UploadItem from './components/upload-item'
import { ICoreUploadAttachment } from './model'
import { DefaultClass } from './styles'

const CoreUploadAttachment = ({ value, onChange }: ICoreUploadAttachment) => {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])
  const modal = useModal()

  useEffect(() => {
    value?.length && setFileList(value)
  }, [value])

  const handleOnFileRemove = (file: any) => {
    return modal
      .confirm({
        title: '',
        message: i18n.t('common.messages.confirm_delete', { name: file?.name }),
        type: CONFIRM_TYPES.DELETE,
      })
      .then(isOKClick => !!isOKClick)
  }

  const handleOnChange = ({ file, fileList }: UploadChangeParam<UploadFile<any>>) => {
    if (file.status === 'error') {
      message.error(i18n.t('components.UploadImageListField.upload_failure', { name: file.name }))
    }

    const updatedFileList = fileList
      .filter(item => item.status !== 'error')
      .map(item => {
        const responseData = item.response?.data
        return {
          ...item,
          fileName: responseData ? responseData.filename : item.fileName,
          uid: responseData ? responseData.id : item.uid,
          url: responseData ? responseData.url : item.url,
          type: responseData ? responseData.mime_type : item.type,
        }
      })

    onChange?.(updatedFileList)
    setFileList(updatedFileList)
  }

  const handleBeforeUpload = (file: any) => {
    if (!validateFileSize(file, MAX_FILE_UPLOAD_SIZE)) {
      message.error(i18n.t('common.messages.over_file_size_limit', { name: file.name }))
      file.status = 'error'
      return false
    }

    return true
  }

  const requestHeader: HttpRequestHeader = useMemo(
    () => ({
      'X-CSRF-Token': localStorage.getItem(CSRF_TOKEN_KEY) as string,
    }),
    []
  )

  const itemRender: ItemRender = useCallback((_, file, __, actions) => <UploadItem file={file} actions={actions} />, [])

  return (
    <Upload
      fileList={fileList}
      action={FILE_UPLOAD_PATH}
      multiple
      className={DefaultClass}
      onChange={handleOnChange}
      beforeUpload={handleBeforeUpload}
      itemRender={itemRender}
      headers={requestHeader}
      withCredentials
      showUploadList={fileList?.length > 0}
      onRemove={handleOnFileRemove}
    >
      <Button style={{ padding: '0 10px', width: 'auto' }} color="grey">
        {i18n.t('components.UploadFileListField.upload')}
      </Button>
    </Upload>
  )
}

export default CoreUploadAttachment
