import { depreciationFixedAssetSlice } from '../store'

export const useCountSelectedReports = () => {
  const reports = depreciationFixedAssetSlice.useSlice(state => state.data?.reports)

  const reportsSelected = reports?.filter(report => report.isSelected)
  const countSelectedReports = reportsSelected?.length
  const submit_destination_ids = reportsSelected?.map(report => report.submit_destination_id)
  return {
    countSelectedReports,
    submit_destination_ids,
  }
}
