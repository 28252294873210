import { parseError, parseResponse } from 'common/helpers'
import { buildPaginationParams, buildQueryFilterParams } from 'common/helpers/request-helper'
import { CsvCharset } from 'common/models'

import base from './base'

export const exportFile = (fmt: string, charset: string) => {
  return base.get(`/v1/export/current_monthly_closing/journals?format=${fmt}&charset=${charset}`, {
    responseType: 'blob',
  })
}

export const getJournals = (params: any, pageNumber: number, pageSize: number) => {
  return base
    .get('/v1/journals', {
      params: {
        ...buildQueryFilterParams(params),
        ...buildPaginationParams(pageNumber, pageSize),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getCountOfJournalEvents = (params: any) => {
  return base
    .get('/v1/journals/total', {
      params: {
        ...buildQueryFilterParams(params),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getJournalStatus = () => {
  const apiUrl = '/v1/acc_plus/journal_linkage_status'
  return base
    .get(apiUrl)
    .then(res => {
      return parseResponse(parseResponse(res))
    })
    .catch(err => {
      return parseError(err)
    })
}

export const sendJournal = () => {
  return base.post('/v1/acc_plus/journal_linkage_submit')
}

export const getJournalLinkageDetail = (linkageHistoryId: string | number) => {
  return base
    .get('/v1/acc_plus/journal_linkage_history_detail', {
      params: {
        linkage_history_id: linkageHistoryId,
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const exportJournalLinkageDetail = (linkage_history_id: string, charset: CsvCharset) => {
  // TODO: integrate with export api
  return base.get('/v1/export/journal_linkage_history', {
    responseType: 'blob',
    params: {
      linkage_history_id,
      charset,
    },
  })
}
