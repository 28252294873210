import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu } from 'antd'
import i18n from 'i18n'
import { get, isEqual } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'

import { Tooltip } from '../../antd'
import { ReactComponent as CheckIcon } from '../../assets/icons/check-icon.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg'
import { LinkLabel } from '../link-label'
import { copyTextToClipBoard, parseRegisterNumber } from './helpers'

export const OfficeDropdown = () => {
  const dispatch = useDispatch()
  const [isShowOffice, setIsShowOffice] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const userInfoSelector = useSelector(selectUserInfo, isEqual)
  const currentOfficeSelector = useSelector(selectCurrentOffice, isEqual)

  const handleOfficeSelect = useCallback(
    (item: any) => {
      if (item.key === 'change-office') {
        dispatch(changeLocation('/offices/select'))
      }
    },
    [dispatch]
  )

  const handleVisibleChange = (visible: boolean) => {
    setIsShowOffice(visible)
    visible && setIsCopied(false)
  }

  const menuItemStyle = {
    cursor: 'pointer',
    width: '100%',
    borderBottom: '1px solid #D4D8DD',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  }

  const menu = (
    <Menu style={{ padding: 0, width: 290, borderRadius: 4, border: '1px solid #d4d8dd' }} onClick={handleOfficeSelect}>
      <Menu.Item style={{ borderBottom: '1px solid #D4D8DD' }} key="register-number">
        <div>
          <LinkLabel onClick={() => dispatch(changeLocation('/setting/office'))}>
            {get(currentOfficeSelector.data, 'name', '')}
          </LinkLabel>
        </div>
        <span style={{ margin: '10px 3px 10px 0', display: 'inline-block', cursor: 'initial' }}>
          {i18n.t('components.MainHeader.office.register_number')}
        </span>
        :
        <span id="register-number" style={{ marginLeft: '3px', cursor: 'initial' }}>
          {parseRegisterNumber(get(userInfoSelector.data, 'office_identification_code', ''))}
        </span>
        <Tooltip
          content={i18n.t('components.MainHeader.office.copy_register_number')}
          placement="topRight"
          tooltipStyles={{ display: 'inline-block', marginLeft: '10px', width: 20 }}
        >
          {userInfoSelector.data && (
            <>
              {isCopied ? (
                <CheckIcon style={{ width: 16, height: 16 }} />
              ) : (
                <CopyIcon
                  fill="#999"
                  style={{ position: 'relative', top: 4, width: 16, height: 16 }}
                  onClick={() => {
                    setIsCopied(true)
                    copyTextToClipBoard()
                  }}
                />
              )}
            </>
          )}
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={menuItemStyle} key="change-office">
        {i18n.t('components.MainHeader.office.change_office')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      onVisibleChange={handleVisibleChange}
      visible={isShowOffice}
      trigger={['click']}
      overlayStyle={{ padding: '8px 0px', position: 'fixed', top: '40px !important' }}
    >
      <div style={{ display: 'flex', alignItems: 'center', height: '100%', cursor: 'pointer' }}>
        <span style={{ marginRight: 5 }} className="office-name">
          {get(currentOfficeSelector.data, 'name', '')}
        </span>
        <div style={{ position: 'relative', bottom: '1px' }}>
          <FontAwesomeIcon icon={faSortDown} />
        </div>
      </div>
    </Dropdown>
  )
}
