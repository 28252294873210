import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spin } from 'aa_common/front-end/antd'
import { Col, Layout, RadioChangeEvent, Row } from 'antd'
import { DATETIME_NO_DOT_FORMAT } from 'common/constants'
import { Asset, IMasterData, OperationLog } from 'common/models'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import { BreadcrumbNavigation, ImageCarousel } from 'components/molecules'
import find from 'lodash/find'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOperationLogItem } from 'store/internalControl/operationLog/actions'
import { selectOperationLogItem } from 'store/internalControl/operationLog/selectors'

import BasicInfo from './BasicInfo'
import { OperationLogTarget, OperationLogType, UPDATE_CHANGE_MODES, UpdateChangeMode } from './constant'
import { BodyContent, BreadcrumbArea, Carousel, LogListItemDetailWrapper, TopContent, Wrapper } from './styles'
import EventHistory from './tabs/EventHistory'
import Information from './tabs/Information'

interface LogListItemDetailProps {
  masterData: IMasterData
  logItem: OperationLog | null
  onModalClose: () => void
}

const updateTypes = [OperationLogType.UPDATE, OperationLogType.CORRECT, OperationLogType.CORRECT_FA]
const deleteTypes = [OperationLogType.DELETE, OperationLogType.REMOVE]

const LogListItemDetail = ({ logItem, masterData, onModalClose }: LogListItemDetailProps) => {
  const [viewUpdateMode, setViewUpdateMode] = useState<UpdateChangeMode>(UpdateChangeMode.BEFORE)

  const operationLogItemSelector = useSelector(selectOperationLogItem, isEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    if (logItem) {
      setViewUpdateMode(UpdateChangeMode.BEFORE)
      const updateType = updateTypes.indexOf(logItem.type) >= 0
      const deleteType = deleteTypes.indexOf(logItem.type) >= 0
      if (updateType || deleteType) {
        dispatch(fetchOperationLogItem(logItem.asset.biid, logItem.beforeId))
      } else {
        dispatch(fetchOperationLogItem(logItem.asset.biid, logItem.afterId))
      }
    }
  }, [logItem]) // eslint-disable-line

  const handleModeChange = (event: RadioChangeEvent) => {
    const mode = event.target.value
    const isBefore = mode === UpdateChangeMode.BEFORE
    setViewUpdateMode(mode)
    logItem && dispatch(fetchOperationLogItem(logItem.asset.biid, isBefore ? logItem.beforeId : logItem.afterId))
  }

  const isUpdateType = logItem && updateTypes.indexOf(logItem.type) >= 0
  const isFixedAssetMode = logItem && logItem.target === OperationLogTarget.FIXED_ASSET
  const isEventOfFixedAssetMode = logItem && logItem.target === OperationLogTarget.EVENT_OF_FIXED_ASSET

  const fixedAsset = useMemo<Asset>(() => operationLogItemSelector.data || null, [operationLogItemSelector.data])
  const renderBreadcrumbModalClose = useMemo(
    () => <BreadcrumbNavigation.CloseIconTemplate onCloseClick={onModalClose} />,
    [onModalClose]
  )

  const renderBreadcrumbTitle = useMemo(() => {
    if (logItem) {
      const date = moment(logItem.createdAt).format(DATETIME_NO_DOT_FORMAT)
      const target = find(masterData.operation_target, item => item.code === logItem.target)?.name_jp
      return (
        <div className="breadcrumb-title-text">
          <span style={{ marginRight: 20 }}>{date}</span>
          <span>{target}</span>
        </div>
      )
    }

    return null
  }, [logItem, masterData])

  return (
    logItem && (
      <LogListItemDetailWrapper>
        <Spin loading={operationLogItemSelector.isLoading}>
          <div className="help">
            <FontAwesomeIcon style={{ color: '#7C8291', fontSize: 20 }} icon={faQuestionCircle} />
          </div>
          <BreadcrumbArea>
            <Row>
              <Col flex="1 1" style={{ minWidth: 0 }}>
                <BreadcrumbNavigation
                  customBackElement={renderBreadcrumbModalClose}
                  sectionName={renderBreadcrumbTitle}
                />
              </Col>
            </Row>
          </BreadcrumbArea>
          <Layout>
            <Layout.Content>
              <Wrapper className="content">
                {isUpdateType && (
                  <div style={{ marginBottom: 20 }}>
                    <RadioGroup
                      value={viewUpdateMode}
                      name="mode"
                      options={UPDATE_CHANGE_MODES}
                      onChange={handleModeChange}
                    />
                  </div>
                )}
                <TopContent>
                  <Carousel>
                    <ImageCarousel photos={get(fixedAsset, 'photos', [])} />
                  </Carousel>
                  <BasicInfo data={fixedAsset} />
                </TopContent>
                <BodyContent>
                  {isFixedAssetMode && (
                    <div className="section">
                      <Information data={fixedAsset} />
                    </div>
                  )}
                  {isEventOfFixedAssetMode && (
                    <div className="section">
                      <EventHistory item={fixedAsset} />
                    </div>
                  )}
                </BodyContent>
              </Wrapper>
            </Layout.Content>
          </Layout>
        </Spin>
      </LogListItemDetailWrapper>
    )
  )
}

export default LogListItemDetail
