import React from 'react'

import { Wrapper } from './styles'

interface Props {
  color?: 'disabled' | 'notice' | 'normal'
  style?: React.CSSProperties
}

export const ColorLabel: React.FC<Props> = ({ color = 'notice', style, children }) => {
  return (
    <Wrapper className={`color-${color}`} style={style}>
      {children}
    </Wrapper>
  )
}
