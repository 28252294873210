import { CommonErrorMsg } from 'aa_common/front-end/antd'
import classNames from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'

import { InputV2Props } from './model'
import { CoreInput } from './styles'

const Input = ({
  name,
  className,
  disabled,
  type = 'text',
  isShowError = true,
  placeholder,
  maxLength,
  control,
  error,
  onChange,
  ...rest
}: InputV2Props) => {
  // *** Form Input ***
  if (control) {
    const isInvalid = !!error

    return (
      <>
        <Controller
          name={name}
          control={control}
          defaultValue={rest?.defaultValue}
          render={({ value, ...controlRest }) => (
            <CoreInput
              className={classNames(className, 'aa-input', {
                error: isInvalid,
              })}
              value={value}
              onChange={event => {
                controlRest.onChange(event)
                onChange && onChange(event)
              }}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              name={name}
              type={type}
              {...rest}
            />
          )}
        />
        {isInvalid && isShowError && <CommonErrorMsg fontSize="13px">{error}</CommonErrorMsg>}
      </>
    )
  }

  // *** Regular Input ***
  return (
    <CoreInput
      className={classNames('aa-input', className)}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      name={name}
      type={type}
      {...rest}
    />
  )
}

export default React.memo(Input)
