import { initSlice } from 'aa_common/front-end/zustand/init-slice'
import { PresenterOneOfficeResponse, PresenterOneOfficeUserResponse } from 'common/open-api/gen/data-contracts'

type SessionType = {
  currentOffice?: PresenterOneOfficeResponse
  currentUser?: PresenterOneOfficeUserResponse
}

const initData: SessionType = {}

export const sessionsSlice = initSlice('share/sessions', initData)
