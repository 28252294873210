import { css } from '@emotion/css'
import styled from '@emotion/styled'

export const ModalClass = css`
  .ant-drawer-content-wrapper {
    width: unset !important;
    .ant-drawer-content {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
      .ant-drawer-body {
        padding: 15px;

        .align-start {
          .label-wrapper {
            padding-top: 4px;
          }
        }
        .multilField {
          .field-content {
            max-width: unset;
          }
          .underline-text-content {
            display: flex;
            min-width: 240px;
            justify-content: space-between;
            padding: 0px 5px 4px 5px;
          }
        }

        .field-content {
          max-width: 250px;
        }

        .footer-form {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            margin-right: 10px;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }

        .impairment-book-value {
          .ledger-name {
            text-align: left;
            padding-right: 5px;

            .ant-typography {
              color: inherit;
            }
          }

          .value {
            text-align: right;
          }
        }
      }
    }
    .aa-link-list-field {
      input {
        height: 30px;
      }
    }
    .dividing_date {
      position: absolute;
      top: 3px;
      bottom: 2px;
      left: 2px;
      right: 30px;
      align-items: center;
      z-index: 1;
      background: transparent;
      pointer-events: none;
      text-align: center;
      height: 24px;
    }
  }
`
export const CollapSeDiViDing = css`
  .collapseView {
    position: relative;
  }
  .label {
    flex: unset !important;
    width: 200px !important;
  }
  .collapseBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    position: absolute;
    padding: 5px;
    height: 83px;
    text-align: center;
    right: -35px;
    bottom: -10px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    background: #e6eaed;
    border-radius: 4px;
    color: #3b7de9;
    cursor: pointer;
    writing-mode: vertical-lr;
    text-orientation: upright;
    letter-spacing: 2px;
    position: absolute;
  }
`

export const StyledTableWrapper = styled.div`
  margin-top: 20px;
  .item-content {
    display: flex;
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0;
    max-width: calc(794px - 25px);
    .source-item {
      margin-bottom: 0;
      width: calc(200px + 240px);

      .field-content {
        width: 240px;

        & > div {
          width: 240px;
          height: 32px;
        }

        input {
          height: 30px;
        }
      }
    }

    .destination-item {
      width: calc(100% - 460px);
      display: grid;
      grid-template-columns: 40px 200px;
      grid-template-rows: 30px auto;

      .label {
        max-width: 100%;
        grid-row: 1 / 2;

        .label-text {
          display: none;
        }
      }

      .field-content {
        padding-left: 0;
        grid-row: 1 / 3;
        width: 240px;

        input {
          width: 240px;
          &:read-only:not(.underline) {
            background-color: #f4f4f4;
          }
        }
      }

      &.asset-code {
        .field-content {
          display: grid;
          grid-template-columns: 127px auto 80px;
          gap: 10px;
          align-items: center;

          input {
            width: 100%;
          }
        }
      }
    }

    .text-right {
      text-align: right;
    }
  }
`
export const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + 20px);
  height: 40px;
  background: #f4f4f4;
  margin-left: -10px;
  padding-left: 210px;

  & > div {
    &:first-of-type {
      flex: 0 0 310px;
      padding-left: 20px;
    }

    &:last-child {
      flex: 0 0 calc(100% - 310px);
    }
  }
`

export const StyledFormContentWrapper = styled.div`
  .dividing-form-content {
    padding: 0 10px;
  }
  .btn-cancel {
    margin-right: 10px;
  }

  .btn-submit {
    width: 70px;
  }

  .divided_at {
    input {
      color: transparent;
    }
  }
`

export const NewBranchCodeWrapper = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  .new_branch_code {
    width: 60px !important;
    margin-right: 10px;
  }
  .ant-tooltip {
    max-width: unset !important;
  }
`
