import styled from '@emotion/styled'
import { Checkbox } from 'aa_common/front-end/antd'

export const Wrapper = styled.div`
  .ant-table {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      height: 40px;
      padding: 10px 10px;
      border-bottom: unset;
    }

    .ant-table-tbody > tr > td {
      border-bottom: unset;
      word-break: break-all;
    }

    .ant-table-cell {
      &.border-left {
        border-left: 1px solid #e2e2e2;
      }
    }
  }

  .ant-table-row-level-0 {
    background-color: #f4f4f4;
    .ant-table-cell {
      border-top: 1px solid #e2e2e2;
    }
  }
`

export const RightWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: #ffffff;
      border-color: #e2e2e2;

      &:after {
        border-color: #ffffff;
      }
    }
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    opacity: 0.5;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #3b7de9;
      border-color: #3b7de9 !important;
    }
  }
`
