import { message, Upload } from 'antd'
import { MAX_IMPORT_FILE_SIZE } from 'common/constants'
import { isSupportedCharset, validateFileSize } from 'common/helpers'
import i18n from 'i18n'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import { Button } from '../../../antd'
import { useModalState } from '../../../hooks'
import { StyledModal } from './styles'

type BaseProp = {
  title: string
  children: React.ReactNode
}

type Props = BaseProp & {
  isShow: boolean
  onCancel: () => void
  onSubmit: (file: File) => void
}

const Modal = ({ children, isShow, title, onCancel, onSubmit }: Props) => {
  const [fileList, setFileList] = useState<any>([])

  useEffect(() => {
    if (!isShow) {
      setFileList([])
    }
  }, [isShow])

  const beforeUpload = (file: any): boolean => {
    if (!validateFileSize(file, MAX_IMPORT_FILE_SIZE)) {
      message.error(i18n.t('components.ImportModal.msg_file_size_limit'), 1.5)
    }

    if (fileList.length === 0) {
      setFileList([file])
    } else {
      setFileList([])

      setTimeout(() => {
        setFileList([file])
      }, 250)
    }

    return false
  }

  const handleRemoveFile = (): void => {
    setFileList([])
  }

  const handleSubmit = () => onSubmit(fileList[0])

  return (
    <StyledModal
      title={title}
      visible={isShow}
      onOk={handleSubmit}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button key="back" color="grey" onClick={onCancel}>
          {i18n.t('components.ImportModal.cancel')}
        </Button>,
        <Button key="submit" onClick={handleSubmit} disabled={fileList.length === 0}>
          {i18n.t('components.ImportModal.import')}
        </Button>,
      ]}
      centered
    >
      {children}
      <div style={{ marginTop: '0.5em' }}>{i18n.t('components.ImportModal.support_charset_note')}</div>
      <div className="upload">
        <Upload accept=".csv" fileList={fileList} beforeUpload={beforeUpload} onRemove={handleRemoveFile}>
          <Button color="grey" className="attachment-btn">
            {i18n.t('components.ImportModal.select_attachment')}
          </Button>
        </Upload>
      </div>
    </StyledModal>
  )
}

export const ImportModal = forwardRef<any, BaseProp>(function ImportModalWrapper({ children, title }, ref) {
  const { isShow, handleOnClose } = useModalState(ref)

  const handleOnSubmit = useCallback(
    (file: File) => {
      isSupportedCharset(file).then(({ charset, isSupported, notSupportedMsg }) => {
        if (isSupported) {
          handleOnClose({ file, charset })
        } else {
          handleOnClose(notSupportedMsg)
        }
      })
    },
    [handleOnClose]
  )

  return (
    <Modal title={title} isShow={isShow} onCancel={() => handleOnClose()} onSubmit={handleOnSubmit}>
      {children}
    </Modal>
  )
})
