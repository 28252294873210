import { EXCISE_TAX_METHOD } from 'common/constants'
import { get } from 'lodash'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

export const useTaxMethodExciseAsset = () => {
  const [exciseTaxMethod, setExciseTaxMethod] = React.useState<number | undefined>(undefined)
  const [isIncludedTax, setIsIncludedTax] = React.useState(false)
  const exciseSettingsByDateData = useSelector(getExciseSettingsByDateList, isEqual).data

  React.useEffect(() => {
    const exicseTax = get(exciseSettingsByDateData, 'tax_method')
    setIsIncludedTax(exicseTax === EXCISE_TAX_METHOD.INCLUDED_TAX)
    setExciseTaxMethod(exicseTax)
  }, [JSON.stringify(exciseSettingsByDateData)])
  return { exciseTaxMethod, isIncludedTax }
}
