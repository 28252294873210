import { ExclamationCircleFilled } from '@ant-design/icons'
import { messageSuccess } from 'aa_common/front-end'
import { DECLARATION_REPORT_TYPES } from 'AppExport/model'
import { DATE_FORMAT_RFC } from 'common/constants'
import { formatDateTimeBase } from 'common/helpers/format-helper'
import { getNs } from 'common/i18n-config'
import { ReportsService } from 'common/open-api'
import useModal from 'hooks/useModalHook'
import { useWebSocket } from 'hooks/useWebSocket'
import i18n from 'i18n'
import ModalContent from 'pages/accounting/DepreciationFixedAssetReviewPage/Modal'
import { ModalWrapper } from 'pages/accounting/DepreciationFixedAssetReviewPage/styles'
import React, { useEffect } from 'react'

import { ExportType } from '../constant'
import { depreciationFixedAssetSlice } from '../store'

const { useNsReady, t } = getNs(['pages/accounting/depreciation-fixed-asset-page'])

export const useExportPdf = (props: {
  timeout?: number
  handleOpenModal?: (visible: boolean) => void
  year: number
}) => {
  useNsReady()
  const modal = useModal()

  const { data } = useWebSocket<WebSocketExport>()
  const modalRef = React.useRef<any>(null)
  const [uuid, setUuid] = React.useState<string | null>(null)
  const showErrors = () => {
    modalRef.current.cancel()
    modal.confirm({
      message: (
        <ModalWrapper>
          <ModalContent
            titleName={i18n.t('pages.accounting.DepreciationFixedAssetPage.modals.error_title')}
            statusProgress="exception"
            icon={<ExclamationCircleFilled style={{ marginLeft: 5, marginTop: 5, color: '#EC4949', fontSize: 13 }} />}
          />
        </ModalWrapper>
      ),
      cancelText: i18n.t('common.actions.ok'),
    })
  }
  const handleExport = async (payload: ExportPdfMultileDto) => {
    const parsePayload = {
      ...payload,
      path: 'export',
      submit_date: formatDateTimeBase(payload.submit_date, DATE_FORMAT_RFC)!,
      type: payload?.exportType === ExportType.PDF ? DECLARATION_REPORT_TYPES.ALL : payload.type,
      with_asset_code: !!payload.with_asset_code,
    }
    modalRef.current = modal.confirm({
      cancelText: i18n.t('components.ConfirmModal.cancel_process'),
      maskClosable: false,
      message: (
        <ModalWrapper>
          <ModalContent titleName={i18n.t('pages.accounting.DepreciationFixedAssetPage.modals.progress_title')} />
        </ModalWrapper>
      ),
    })
    try {
      const response: any = await ReportsService.taxDeclarationExportMultipleList(parsePayload)
      setUuid(response?.data?.uuid)
    } catch (error) {
      showErrors()
    }
  }

  useEffect(() => {
    if (!uuid) return

    if (data?.uuid === uuid) {
      modalRef.current.cancel()
      if (data?.error) {
        showErrors()
      } else {
        props.handleOpenModal?.(false)
        depreciationFixedAssetSlice.setFilters({ year: props.year }, true)
        messageSuccess(t('export_modal.outputted_the_file'))
        window.open(data?.data?.url as string, '_blank')
      }
    } else {
      const timeoutId = setTimeout(showErrors, (props?.timeout ?? 15) * 60 * 1000) // 15 minutes
      return () => clearTimeout(timeoutId)
    }
  }, [data, uuid])

  return { handleExport }
}

type WebSocketExport = {
  topic: string
  data?: {
    url?: string
  }
  uuid: string
  error: string
  office_id: number
  user_id: number
}

export type ExportPdfMultileDto = {
  /** export type: all, summary, decrease, increase (1,2,3,4) */
  type: number | string
  /** IDs array of submit destinations */
  submit_destination_ids: any
  /** fiscal year of depreciable fixed asset tax declaration */
  year: number | string
  /** submit date, example: 2021-01-25 */
  submit_date: string
  /** value must be 'export' */
  path: string
  /** mode of export: 1: all, 2: in_fiscal_year */
  mode: number | string
  with_asset_code?: boolean
  exportType?: ExportType
}
