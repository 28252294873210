import React from 'react'

import { Wrapper } from './styles'

type Props = {
  color?: string
  style?: React.CSSProperties
}

const CaretIcon: React.FC<Props> = ({ color = '#7C8291', style }) => {
  return (
    <Wrapper style={style}>
      <svg width="10" height="5" viewBox="0 0 10 5" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M5 4.71582L9.33013 0.49707H0.669873L5 4.71582Z" />
      </svg>
    </Wrapper>
  )
}

export default CaretIcon
