import styled from '@emotion/styled'
import { ErrorMessage } from '@hookform/error-message'
import { Col, Row, Typography } from 'antd'
import i18n from 'i18n'
import { cloneElement, PropsWithChildren, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ErrorTextStyles, LabelRequired } from '../form-styles'

const { Text } = Typography
export type FormItemRowProps = {
  label?: ReactNode
  required?: boolean
  name: string
  element: any
  isEdit?: boolean
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
}

export const RowFormItem = ({
  label,
  name,
  element,
  isEdit = true,
  style,
  required,
  labelStyle,
}: PropsWithChildren<FormItemRowProps>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <RowWrapper className="row-form-item" style={style}>
      <Col span={6}>
        <Typography className="label" style={labelStyle}>
          {label}
        </Typography>
      </Col>
      <Col span={18} className="item">
        <div style={{ width: '100%', display: 'relative' }}>
          <Controller
            control={control}
            render={field => {
              return cloneElement(element, { ...field, disabled: !isEdit })
            }}
            name={name}
          />

          <ErrorTextStyles>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => {
                return <ErrorText type="danger">{message}</ErrorText>
              }}
            />
          </ErrorTextStyles>

          {required && <LabelRequired>{i18n.t('common.required')}</LabelRequired>}
        </div>
      </Col>
    </RowWrapper>
  )
}

const RowWrapper = styled(Row)`
  width: 100%;
  padding-top: 16px;
  .label {
    font-size: 14px;
    font-weight: 400;
  }
  input {
    border-radius: 6px;
  }
  .item {
    display: flex;
    align-items: center;
  }
`

const ErrorText = styled(Text)`
  font-size: 12px;
`
