export enum API_ERROR_META_CODE {
  UNAUTHORIZED = '40101',
  OFFICE_NOT_SELECTED = '40102',
  OFFICE_EXPIRED = '40103',
  TERM_OF_USE_NOT_CONFIRMED = '40301',
  JOURNAL_SENDING_IN_PROCESS = '40001',
}

export enum API_STATUS_CODE {
  SERVER_ERROR = 500,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  UNPROCESSABLE_ENTITY = 422,
  SERVICE_UNAVAILABLE = 503,
  CREATED = 201,
  OK = 200,
}

export const DATA_IN_USED_ERROR_CODE = 'ERR_DELETE_DATA_IN_USE'
export const NOT_INTEGRAL_DATA_RELATION_ERROR_CODE = 'ERR_NOT_INTEGRAL_DATA_RELATION'
export const ERR_EXCISE_NOT_VALID_AT_TIME = 'ERR_EXCISE_NOT_VALID_AT_TIME'
export const ERR_ACCOUNT_ITEM_DISABLED = 'ERR_ACCOUNT_ITEM_DISABLED'
export const ERR_ACCOUNT_SUB_ITEM_DISABLED = 'ERR_ACCOUNT_SUB_ITEM_DISABLED'
