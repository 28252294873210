import { all } from 'redux-saga/effects'

import assetEventsReport from './assetEventsReport/saga'
import depreciationFixedAssetReport from './depreciationFixedAsset/saga'
import increaseDecreaseReport from './increaseDecreaseReport/saga'
import journal from './journal/saga'
import monthlyClosing from './monthlyClosing/saga'
import underOverDepreciationReport from './underOverDepreciationReport/saga'

export default function* settingsSaga() {
  yield all([
    journal(),
    monthlyClosing(),
    increaseDecreaseReport(),
    assetEventsReport(),
    depreciationFixedAssetReport(),
    underOverDepreciationReport(),
  ])
}
