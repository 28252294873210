import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Input as AntdInput } from 'antd'
import { InputProps } from 'antd/lib/input'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import { get, initial, last } from 'lodash'
import React, { useEffect, useState } from 'react'

import { findNewJPCharacters, isDeleteAction, toKatakana } from './helper'
import { Wrapper } from './styles'

interface Props extends InputProps {
  isShowError?: boolean
  kanaField: string
  className?: string
}

const AutoKanaInput: React.FC<Props> = ({
  name = '',
  isShowError = true,
  kanaField,
  maxLength,
  placeholder,
  style,
  className,
}) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [value, setValue] = useState<string>(get(values, name))
  const [previousValue, setPreviousValue] = useState(getIn(values, name))
  const [previousKanaValue, setPreviousKanaValue] = useState<string>(get(values, kanaField)) // previous kana value
  const [newCharacters, setNewCharacters] = useState<string[]>([])
  const fieldValue = getIn(values, name)

  let isInValid = null

  if (get(errors, name)) {
    isInValid = true
  }

  const handleNewConvert = (newValue: string) => {
    const newKanaString = previousKanaValue + toKatakana(newCharacters.join(''))

    setNewCharacters([])
    if (newKanaString !== previousKanaValue) {
      setPreviousKanaValue(newKanaString)
      setFieldValue(kanaField, newKanaString)
    }

    setFieldValue(name, newValue)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const newJPCharacters = findNewJPCharacters(newValue, value)

    if (newJPCharacters) {
      setNewCharacters([...newCharacters, ...newJPCharacters.split('')])
    } else if (isDeleteAction(newValue, value)) {
      const deletedCharacter: string = value.slice(value.length - 1)

      deletedCharacter === last(newCharacters) && setNewCharacters(initial(newCharacters))
    } else if (Math.abs(newValue.length - value.length) > 1) {
      handleNewConvert(newValue)
    }

    setValue(newValue)
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    if (previousValue !== newValue) {
      setPreviousValue(newValue)
      handleNewConvert(newValue)
    }
  }

  const handleFocus = () => {
    setPreviousKanaValue(get(values, kanaField))
  }

  const handlePressEnter = () => {
    handleNewConvert(value)
  }

  useEffect(() => {
    setValue(fieldValue)
    setPreviousValue(fieldValue)
  }, [fieldValue])

  return (
    <Wrapper>
      <AntdInput
        value={value}
        name={name}
        className={classNames(className, { error: isInValid })}
        style={style}
        autoComplete="off"
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onPressEnter={handlePressEnter}
        onFocus={handleFocus}
      />
      {isInValid && isShowError && <CommonErrorMsg>{get(errors, name)}</CommonErrorMsg>}
    </Wrapper>
  )
}

export default AutoKanaInput
