import {
  GeneralJournalSettingItem,
  GeneralJournalSettingItemFormPayload,
  GeneralJournalSettingItemSubmitPayload,
  Term,
} from 'common/models'
import { find, first, get } from 'lodash'
import moment from 'moment/moment'
import React from 'react'

export const renderItemFieldValue = (value: any, valueField: string, labelField: string, dataSource: any[]) => {
  const targetItem = find(dataSource, item => get(item, valueField) === value)
  const renderText = targetItem ? get(targetItem, labelField) : ''

  return renderText !== '' ? <span>{renderText}</span> : <div style={{ minHeight: 24 }} />
}

export const convertFormPayload = (item: GeneralJournalSettingItem): GeneralJournalSettingItemFormPayload | null => {
  if (!item) return null

  return {
    depreciation_journal_cycle_type: item.depreciation_journal_cycle_type,
    depreciation_journal_method: item.depreciation_journal_method,
    depreciation_journal_type: item.depreciation_journal_type,
    create_journal_method: item.create_journal_method,
    paid_excise_debit_account_item: item.paid_excise_debit_account_item,
    paid_excise_debit_account_item_id: item?.paid_excise_debit_account_item?.is_active
      ? item.paid_excise_debit_account_item_id
      : null,
    received_excise_debit_account_item: item.received_excise_debit_account_item,
    received_excise_debit_account_item_id: item?.received_excise_debit_account_item?.is_active
      ? item.received_excise_debit_account_item_id
      : null,
    slip_date_type: item.slip_date_type,
    term_year_period: item.from_term_month.term_id,
    month_id: item.from_term_month.id,
    journal_linkage_status: item.journal_linkage_status,
  }
}

export const convertSubmitPayload = (
  item: GeneralJournalSettingItemFormPayload
): GeneralJournalSettingItemSubmitPayload | null => {
  if (!item) return null

  return {
    depreciation_journal_cycle_type: item.depreciation_journal_cycle_type,
    depreciation_journal_method: item.depreciation_journal_method,
    depreciation_journal_type: item.depreciation_journal_type,
    create_journal_method: item.create_journal_method,
    paid_excise_debit_account_item_id: item.paid_excise_debit_account_item_id,
    received_excise_debit_account_item_id: item.received_excise_debit_account_item_id,
    slip_date_type: item.slip_date_type,
    from_term_month_id: item.month_id,
    journal_linkage_status: item.journal_linkage_status,
  }
}

export const getDateToCompare = (item: any, nextSettings: any, isAddMode: boolean) => {
  const settingStartDate = moment(item?.from_term_month?.start_date || nextSettings.start_date)
  const settingEndDate = moment(item?.from_term_month?.end_date || nextSettings.end_date)
  return isAddMode ? settingEndDate : settingStartDate
}

export const handleTermYearChanged = (
  termYearPeriod: any,
  accountPeriods: Term[],
  setValue: Function,
  isAddMode: boolean,
  isEditMode: boolean,
  item: any,
  nextSettings: any
) => {
  const termPeriod = find(accountPeriods, item => item.id === termYearPeriod)
  const dateSelectedToCompare = getDateToCompare(item, nextSettings, isAddMode)

  const firstTermMonth = find(termPeriod?.term_months, item => {
    const startDate = moment(item.start_date)
    const endDate = moment(item.end_date)
    return dateSelectedToCompare.isBetween(startDate, endDate, 'date', '[]')
  })

  // Get and choose the correct minimum first term month option when user choose the first enabled term year option
  // other enabled term years options, it will get the first term month of the term year.
  setValue(
    'month_id',
    (isAddMode || isEditMode) && firstTermMonth ? firstTermMonth.id : first(termPeriod?.term_months)?.id
  )
} // eslint-disable-line

export const checkDisable = (isAllowEdit: boolean, loading: boolean | undefined, isAccountPeriodLoading: boolean) => {
  return !isAllowEdit || loading || isAccountPeriodLoading
}
