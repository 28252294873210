import React from 'react'
import { Controller } from 'react-hook-form'

import CoreUploadAttachment from './core-upload-attachment'
import { ICoreUploadAttachment, UPLOAD_ATTACHMENT_MODE } from './model'

export const UploadAttachment = ({ name, mode = UPLOAD_ATTACHMENT_MODE.EDIT, onChange }: ICoreUploadAttachment) => {
  return (
    <Controller
      name={name}
      defaultValue={[]}
      render={({ onChange: controllerOnChange, value, name }) => {
        return (
          <CoreUploadAttachment
            value={value}
            name={name}
            mode={mode}
            onChange={fileList => {
              controllerOnChange(fileList)
              onChange?.(fileList)
            }}
          />
        )
      }}
    />
  )
}
