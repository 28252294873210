
// Memo: These values should be in-sync with
// https://github.com/moneyforwardvietnam/asset_accounting_backend/blob/fdd708338fe019d92ebb8b51e188edaf7b22034f/app/domain/valueobject/tax_declaration_report.go#L5-L10
export enum DECLARATION_REPORT_TYPES {
  ALL = '1',
  SUMMARY = '2',
  DECREASE = '3',
  INCREASE = '4',
}

export enum DECLARATION_REPORT_EXPORT_MODE {
  ALL = '1',
  IN_FISCAL_YEAR = '2'
}

export type AppParams = {
  submit_destination_id: string;
  year: string | number;
  type: DECLARATION_REPORT_TYPES;
  mode: DECLARATION_REPORT_EXPORT_MODE;
  access_token: string;
}
