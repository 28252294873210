import AccountingPeriodForm from 'components/organisms/settings/AccountingPeriodForm'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { fetchCurrentOffice, getUserInfo } from 'store/session/actions'
import {
  cleanupTermPeriods,
  cleanupTerms,
  createTermPeriodsRequest,
  fetchTermPeriods,
  fetchTerms,
  generateTermPeriods,
} from 'store/settings/accountingPeriod/actions'

import { UserGuideWrapper, Wrapper } from './styles'

type Props = {
  terms: any[]
  termPeriods: any
  errors?: any[]
  loading: boolean
  fetchTerms: typeof fetchTerms
  generateTermPeriods: typeof generateTermPeriods
  createTermPeriodsRequest: typeof createTermPeriodsRequest
  cleanupTermPeriods: typeof cleanupTermPeriods
  cleanupTerms: typeof cleanupTerms
}

const AccountingPeriodCreatePage: React.FC<Props> = ({
  terms,
  termPeriods,
  errors,
  loading,
  fetchTerms,
  generateTermPeriods,
  createTermPeriodsRequest,
  cleanupTermPeriods,
  cleanupTerms,
}) => {
  const dispatch = useDispatch()

  const handleFormValuesChanged = (values: any) => {
    if (get(values, 'start_date')) {
      generateTermPeriods(values)
    } else {
      termPeriods && cleanupTermPeriods()
    }
  }

  const handleSubmit = (values: any) => {
    createTermPeriodsRequest(values)
  }

  useEffect(() => {
    fetchTerms()

    return function cleanup() {
      cleanupTerms()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    dispatch(getUserInfo())
    dispatch(fetchCurrentOffice())
  }, [dispatch])

  useEffect(() => {
    if (terms && terms.length > 0) {
      dispatch(changeLocation('/'))
    }
  }, [terms, dispatch])

  return (
    <Wrapper>
      <MainTemplate breadcrumb={null} sidebar={null} bodyLayoutClassName="body-accoungting-period">
        <UserGuideWrapper>
          <div>{i18n.t('pages.user.CreateAccountingPeriod.user_guide')}</div>
        </UserGuideWrapper>
        <AccountingPeriodForm
          defaultFormValues={terms && terms[0]}
          values={termPeriods}
          errors={errors}
          loading={loading}
          onValuesChanged={handleFormValuesChanged}
          onSubmit={handleSubmit}
        />
      </MainTemplate>
    </Wrapper>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  terms: state.getIn(['settings', 'accountingPeriod', 'terms', 'data']),
  termPeriods: state.getIn(['settings', 'accountingPeriod', 'termPeriods', 'data']) || null,
  errors: state.getIn(['settings', 'accountingPeriod', 'termPeriods', 'errors']) || null,
  loading: state.getIn(['settings', 'accountingPeriod', 'termPeriods', 'loading']),
})

const mapDispatchToProps = {
  fetchTerms,
  fetchTermPeriods,
  generateTermPeriods,
  createTermPeriodsRequest,
  cleanupTermPeriods,
  cleanupTerms,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPeriodCreatePage)
