import { ASSET_CHANGE_SITUATION_TYPES, EMPTY_SYMBOL } from 'common/constants'
import { PhotoView } from 'components/atoms'
import get from 'lodash/get'
import React from 'react'

export const Photos = ({ assetData }: any) => {
  let photos = get(assetData, 'photos') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    photos = get(assetData, 'event_photos') || []
  }

  if (photos.length === 0) return EMPTY_SYMBOL

  return photos.map((photo: any) => (
    <div key={photo?.id}>
      <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
    </div>
  ))
}
