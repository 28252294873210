import styled from '@emotion/styled'
import { Button } from 'antd'

export const StyledButton = styled(Button)`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 10px;
  height: 30px;

  &.ant-btn-loading {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.size-shorter {
    min-width: 90px;
  }

  &.size-short {
    min-width: 100px;
  }

  &.size-long {
    min-width: 240px;
  }

  &.size-medium {
    min-width: 130px;
  }

  &.size-big {
    min-width: 240px;
    height: 42px;
  }

  &.color-blue {
    background: linear-gradient(180deg, #3b7de9 0%, #0054ac 100%);
    border: 1px solid #3b7de9;
    color: #ffffff;

    &:not([disabled]) {
      &:hover,
      &:active {
        background: linear-gradient(180deg, #0057ac 0%, #3b7de9 100%);
        color: #ffffff;
      }
    }
  }

  &.color-grey {
    background: linear-gradient(180deg, #ffffff 0%, #eff1f4 100%);
    border: 1px solid #d4d8dd;
    color: #3b7de9;

    &:not([disabled]) {
      &:hover,
      &:active {
        background: linear-gradient(180deg, #eff1f4 0%, #ffffff 100%);
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: #d4d8dd;
    color: #999999;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  &.ant-btn-two-chinese-chars > :not(.anticon) {
    letter-spacing: 0;
    margin-right: 0;
  }
`

interface TooltipWrapperProps {
  disabled?: boolean
}
export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
  display: inline-block;

  .ant-btn {
    &:disabled {
      background: #d4d8dd;
      color: #999999;
      border: 1px solid rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }
  }
`
