import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const DefaultHalfModalClass = css`
  .ant-drawer-body {
    padding: 0;
    background: #f0f2f5;
  }
`

export const Wrapper = styled.div`
  .help {
    display: flex;
    flex-flow: row nowrap;
    padding: 8px;
    color: #3a3a3a;
    justify-content: flex-end;
    align-items: center;
    min-height: 46px;
    border-bottom: 1px solid #d4d8dd;
    background-color: #ffffff;
  }

  .half-modal-content {
    padding: 15px;
    font-size: 13px;
  }

  .top-header-template {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
    }
  }
`

export const BreadcrumbArea = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
`
