import { combineReducers } from 'redux-immutable'

import assetEventsReport from './assetEventsReport/reducer'
import depreciationFixedAsset from './depreciationFixedAsset/reducer'
import increaseDecreaseReport from './increaseDecreaseReport/reducer'
import journal from './journal/reducer'
import monthlyClosing from './monthlyClosing/reducer'
import underOverDepreciationReport from './underOverDepreciationReport/reducer'

export default combineReducers({
  journal,
  monthlyClosing,
  increaseDecreaseReport,
  assetEventsReport,
  depreciationFixedAsset,
  underOverDepreciationReport,
})
