import { Permission } from 'common/constants'
import CommentTextbox from 'components/molecules/CommentTextbox'
import AssetCommentList from 'components/organisms/assets/AssetCommentList'
import { IAssetCommentItem } from 'components/organisms/assets/AssetCommentList/model'

import { CommentBoxClass, ListCommentClass } from './styles'

interface CommentPopupContentProps {
  isEditMode: boolean
  comments: IAssetCommentItem[]
  permissions: Permission
  onAddComment: (value: string) => void
  onEditComment: (item: IAssetCommentItem, editValue: string) => void
  onDeleteComment: (item: IAssetCommentItem) => void
}

const CommentPopupContent = ({
  isEditMode,
  comments,
  permissions,
  onAddComment,
  onEditComment,
  onDeleteComment,
}: CommentPopupContentProps) => {
  return (
    <div>
      <AssetCommentList
        commentList={comments}
        readonly={!isEditMode}
        listWrapperClassName={ListCommentClass}
        onItemEditSubmit={onEditComment}
        onItemDeleteClick={onDeleteComment}
        permissions={permissions}
      />
      {isEditMode && (
        <CommentTextbox
          className={comments.length > 0 ? CommentBoxClass : 'comment-empty-box'}
          onCommentSubmit={onAddComment}
          permissions={permissions}
        />
      )}
    </div>
  )
}

export default CommentPopupContent
