import { Modal } from 'antd'
import classNames from 'classnames'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  bodyStyle?: React.CSSProperties
  className?: string
  title: string | React.ReactNode
  isShow?: boolean
  width?: number
  allowOutsideClick?: boolean
  destroyOnClose?: boolean
  footer?: React.ReactNode
  onCancel?: () => void
}

export const BasicModal = ({
  children,
  isShow = false,
  allowOutsideClick = true,
  destroyOnClose = true,
  title,
  width = 600,
  footer = null,
  onCancel,
  bodyStyle,
  className,
}: PropsWithChildren<Props>) => {
  const refContainer = useRef(null)
  const [container, setContainer] = useState(null)

  useEffect(() => {
    setContainer(refContainer.current)
  }, [refContainer])

  return (
    <Wrapper ref={refContainer}>
      <Modal
        title={title}
        visible={isShow}
        width={width}
        destroyOnClose={destroyOnClose}
        maskClosable={allowOutsideClick}
        onCancel={onCancel}
        footer={footer}
        centered
        getContainer={container as any}
        className={classNames('basic-modal', className)}
        bodyStyle={bodyStyle}
      >
        {children}
      </Modal>
    </Wrapper>
  )
}
