import {
  JOURNAL_EVENT_TYPE_NAMES,
  JournalData,
  JournalEventType,
  JournalEventTypeName,
  JournalEventTypesMap,
} from 'common/models'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'

export const convertJournalResponse = (journalRes: ReadonlyArray<any>, exciseList: any[]) => {
  const groupJournalsByType = groupBy(journalRes, 'journal_event_type') as any
  const groupJournalsByName = mapKeys(groupJournalsByType, (_, k: JournalEventType) => JournalEventTypesMap[k])

  const groupedJournals = JOURNAL_EVENT_TYPE_NAMES.reduce(
    (acc, k) => ({
      ...acc,
      [k]: groupJournalsByName[k] || [],
    }),
    {} as Record<JournalEventTypeName, ReadonlyArray<any>>
  )

  return [
    mapValues(groupedJournals, journals => convertJournalToTableFormat(journals, exciseList)),
    mapValues(groupedJournals, journals => journals.length),
  ] as const
}

const convertJournalToTableFormat = (journalRes: ReadonlyArray<any>, exciseList: any[]): JournalData[] => {
  return (journalRes || []).reduce((journals, data: any, iJournal: number) => {
    const branchesLength = data.branches.length

    data.branches.forEach((item: any) => {
      const leftSide = item.branch_sides?.find((side: any) => side.side === 1)
      const rightSide = item.branch_sides?.find((side: any) => side.side === 2)
      // Memo:
      // row_span is for the table to group JournalBranches within one journal
      // Currently DepartmentName, BusinessPartnerName and ExciseName are always null, that's why not to refer
      journals.push({
        id: data.id,
        slip_number: data.slip_number,
        key: `${iJournal}-${item.branch_number}`,
        row_span: item.branch_number === 1 ? branchesLength : 0,
        journal_num: iJournal,
        fixed_asset_biid: data.unique_fa_biid || null,
        is_reversed_journal: !!data.is_reversed_journal,
        recognized_at: data.recognized_at,
        debit_account_item_name: get(leftSide, 'account_item.name', ''),
        debit_account_sub_item_name: get(leftSide, 'account_sub_item.name', ''),
        debit_department_name: (leftSide && item?.department_name) || '',
        debit_business_partner_name: '',
        debit_value: leftSide?.value,
        debit_excise_value: leftSide?.excise_value,
        debit_calculated_excise_value: leftSide?.calculated_excise_value,
        debit_value_excluded_excise: leftSide?.value_excluded_excise,
        debit_value_included_excise: leftSide?.value_included_excise,
        debit_manual_excise_value: leftSide?.manual_excise_value,
        debit_excise_id: leftSide?.excise_id,
        debit_excise_name: exciseList?.find(item => item.id === leftSide?.excise_id)?.short_name || '',
        credit_account_item_name: get(rightSide, 'account_item.name', ''),
        credit_account_sub_item_name: get(rightSide, 'account_sub_item.name'),
        credit_department_name: (rightSide && item?.department_name) || '',
        credit_business_partner_name: '',
        credit_value: rightSide?.value,
        credit_excise_value: rightSide?.excise_value,
        credit_calculated_excise_value: rightSide?.calculated_excise_value,
        credit_value_excluded_excise: rightSide?.value_excluded_excise,
        credit_value_included_excise: rightSide?.value_included_excise,
        credit_manual_excise_value: rightSide?.manual_excise_value,
        credit_excise_id: rightSide?.excise_id,
        credit_excise_name: exciseList?.find(item => item.id === rightSide?.excise_id)?.short_name || '',
        remark: item.remark,
        isError: data.submit_result && data.submit_result !== 1,
        hasSubmitResult: !!data.submit_result,
        submit_response: data?.submit_response,
      })
    })

    return journals
  }, [])
}
