import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { Icon } from './styles'

type Props = {
  style?: React.CSSProperties
}

export const RightArrowIcon: React.FC<Props> = ({ style }) => {
  return (
    <Icon style={style}>
      <FontAwesomeIcon icon={faArrowRight} />
    </Icon>
  )
}
