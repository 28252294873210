import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 900px;
`

export const Table = styled.div`
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 1px #d4d8dd;
  background-color: #ffffff;
`

export const Row = styled.div`
  padding: 10px;
  border-bottom: 1px solid #d4d8dd;
  display: flex;

  .label {
    width: 200px;
    margin-top: 5px;
  }

  .content {
    display: flex;
  }

  &.last {
    border-bottom: none;
  }
`

export const SubmitWrapper = styled.div`
  text-align: right;
  margin-top: 10px;
`
