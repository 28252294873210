import React, { PropsWithChildren } from 'react'

import CoreLinkLabel from './core-link-label'
import { LinkLabelProps } from './model'
import RedirectLink from './redirect-link'

interface ILinkLabel extends React.FC<PropsWithChildren<LinkLabelProps>> {
  RedirectLink: typeof RedirectLink
}

export const LinkLabel = CoreLinkLabel as ILinkLabel
LinkLabel.RedirectLink = RedirectLink
