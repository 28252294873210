import styled from '@emotion/styled'

export const Wrapper = styled.div`
  input {
    border: 1px solid #d4d8dd;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 7px 8px;
    font-size: 13px;
    height: 30px;

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
    }
  }
`

export const LinkList = styled.div`
  color: #3b7de9;
`

export const LinkItem = styled.div`
  position: relative;
  word-wrap: break-word;

  &:hover {
    background-color: #f5f5f5;

    .edit-icon {
      opacity: 1;
    }

    .remove-icon {
      opacity: 1;
    }
  }

  .open-icon {
    color: #7c8291;
    margin-right: 10px;
    cursor: pointer;
  }

  .edit-icon {
    position: absolute;
    right: 24px;
    top: 3px;
    color: #7c8291;
    opacity: 0;
    cursor: pointer;
  }

  .remove-icon {
    position: absolute;
    right: 4px;
    top: 3px;
    color: #e31c1c;
    opacity: 0;
    cursor: pointer;
  }
`

export const EditForm = styled.div`
  input {
    border: 1px solid #d4d8dd;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 7px 8px;
    font-size: 13px;

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
    }
  }
`
