import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 900px;
`

export const Link = styled.label`
  color: #3b7de9;
  cursor: pointer;
`

export const DisplayTable = styled.div`
  border: solid 1px #d4d8dd;
  background-color: #ffffff;

  .row {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
  }

  .col-1 {
    width: 190px;
  }
`
