import { LinkLabel } from 'aa_common/front-end/components'
import { OfficeUser } from 'aa_common/front-end/models'
import classNames from 'classnames'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'

import { UserListWrapper } from './styles'

const t = loadNs(['common'])

interface UserListActionsProps {
  item: OfficeUser
  onEditClick: (item: OfficeUser) => void
  onDeleteClick: (item: OfficeUser) => void
  isYourSelf: boolean
}

const showPermissionMessage = (permission: boolean) => (!permission ? t('actions.no_permission') : undefined)

const UserListActions = ({ item, onEditClick, onDeleteClick, isYourSelf }: UserListActionsProps) => {
  const {
    permissions: { isEdit, isDelete },
  } = useUserPermission(FEATURES_ID.SETTING_USERS)

  return (
    <UserListWrapper>
      <LinkLabel
        showTooltipMessage={showPermissionMessage(isEdit)}
        onClick={() => onEditClick(item)}
        disabled={!isEdit}
      >
        {t('actions.edit')}
      </LinkLabel>
      <LinkLabel
        type="danger"
        className={classNames({ hidden: isYourSelf })}
        showTooltipMessage={showPermissionMessage(isDelete)}
        onClick={() => !isYourSelf && onDeleteClick(item)}
        disabled={!isDelete || isYourSelf}
      >
        {t('actions.cancel_1')}
      </LinkLabel>
    </UserListWrapper>
  )
}

export default UserListActions
