import styled from '@emotion/styled/macro'
import { Image } from 'antd'

export const CarouselButton = styled.button`
  visibility: hidden;
  opacity: 0;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border: none;
  color: white;
  padding-top: 2px;
  font-size: 18px;

  transition: visibility 0.2s linear, opacity 0.2s linear, 0.2s background ease-in;

  &:hover {
    cursor: pointer;
    background: #3b7de9;
  }

  &:active {
    background: blue;
  }

  &:focus {
    outline: none;
  }

  &.prev {
    position: absolute;
    left: 20px;
    z-index: 1;
    top: calc(50% - 18px);
  }

  &.next {
    position: absolute;
    right: 20px;
    z-index: 1;
    top: calc(50% - 18px);
  }
`

export const CarouselWrapper = styled.div`
  padding: 0px;
  position: relative;
  min-height: 180px;
  cursor: pointer;

  &:hover {
    ${CarouselButton} {
      visibility: visible;
      opacity: 1;
    }
  }

  .ant-image-mask {
    border-radius: 4px;
  }
`

export const CarouselImageDescription = styled.div`
  background: rgba(0, 0, 0, 0.5);
  max-height: 60px;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: calc(100% - 20px);
  z-index: 1;
  color: white;
  bottom: 0;

  transition: visibility 0.2s linear, opacity 0.2s linear, 0.2s background ease-in;

  div.img-carousel-desc-content {
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
`

export const CarouselImageSlide = styled.div`
  height: 180px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

export const CarouselImage = styled(Image)`
  max-height: 180px;
  max-width: 180px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`
