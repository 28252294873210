export const initFormKeys = [
  'acquisition_credit_payment_category_account_item_id',
  'acquisition_credit_payment_category_account_sub_item_id',
  'acquisition_debit_asset_category_account_item_id',
  'acquisition_debit_asset_category_account_sub_item_id',
  'acquisition_debit_excise_account_item_id',
  'acquisition_debit_excise_account_sub_item_id',
  'depreciation_credit_accumulated_account_item_id',
  'depreciation_credit_accumulated_account_sub_item_id',
  'depreciation_credit_asset_category_account_item_id',
  'depreciation_credit_asset_category_account_sub_item_id',
  'depreciation_debit_account_item_id',
  'depreciation_debit_account_sub_item_id',
  'impairment_debit_loss_account_item_id',
  'impairment_debit_loss_account_sub_item_id',
  'impairment_credit_accumulated_impairment_account_item_id',
  'impairment_credit_accumulated_impairment_account_sub_item_id',
  'impairment_credit_asset_category_account_item_id',
  'impairment_credit_asset_category_account_sub_item_id',
  'retirement_credit_asset_category_account_item_id',
  'retirement_credit_asset_category_account_sub_item_id',
  'retirement_credit_payment_category_account_item_id',
  'retirement_credit_payment_category_account_sub_item_id',
  'retirement_debit_accumulated_impairment_account_item_id',
  'retirement_debit_accumulated_impairment_account_sub_item_id',
  'retirement_debit_accumulated_depreciation_account_item_id',
  'retirement_debit_accumulated_depreciation_account_sub_item_id',
  'retirement_debit_depreciation_cost_account_item_id',
  'retirement_debit_depreciation_cost_account_sub_item_id',
  'retirement_debit_excise_account_item_id',
  'retirement_debit_excise_account_sub_item_id',
  'retirement_debit_loss_account_item_id',
  'retirement_debit_loss_account_sub_item_id',
  'sale_credit_asset_category_account_item_id',
  'sale_credit_asset_category_account_sub_item_id',
  'sale_credit_payment_category_account_item_id',
  'sale_credit_payment_category_account_sub_item_id',
  'sale_credit_excise_account_item_id',
  'sale_credit_excise_account_sub_item_id',
  'sale_credit_gain_account_item_id',
  'sale_credit_gain_account_sub_item_id',
  'sale_debit_accumulated_impairment_account_item_id',
  'sale_debit_accumulated_impairment_account_sub_item_id',
  'sale_debit_accumulated_depreciation_account_item_id',
  'sale_debit_accumulated_depreciation_account_sub_item_id',
  'sale_debit_depreciation_cost_account_item_id',
  'sale_debit_depreciation_cost_account_sub_item_id',
  'sale_debit_excise_account_item_id',
  'sale_debit_excise_account_sub_item_id',
  'sale_debit_loss_account_item_id',
  'sale_debit_loss_account_sub_item_id',
  'sale_debit_revenue_account_item_id',
  'sale_debit_revenue_account_sub_item_id',
  'sale_debit_asset_category_account_item_id',
  'sale_debit_asset_category_account_sub_item_id',
  'term_year_period',
  'month_id',
] as const

type JournalItemSettingFormValues = {
  [key in (typeof initFormKeys)[number]]: any
}

export const initialFormValues = initFormKeys.reduce((acc, key) => {
  acc[key] = null
  return acc
}, {} as JournalItemSettingFormValues)
