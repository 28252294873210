import { AutoCompleteSelect, Button, Spin } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { BreadcrumbNavigation } from 'components/molecules'
import DepreciationFixedAssetList from 'components/organisms/accounting/DepreciationFixedAssetList'
import { ListPageTemplate } from 'components/templates'
import i18n from 'i18n'
import { ActionWrapper, StyledTableTop } from 'pages/accounting/DepreciationFixedAssetPage/styles'
import React from 'react'

import { CollectiveCheckbox } from './components/collective-checkbox'
import { ExportDepreciationModal } from './components/export-depreciation-modal'
import { useDepreciationFixedAsset } from './hooks/use-depreciation-fixed-asset'
import { useExportDepreciationModal } from './hooks/use-export-depreciation-modal'
import { useSubmitExport } from './hooks/use-submit-export'
import { depreciationFixedAssetSlice } from './store'

const { t } = getNs(['common', 'pages/accounting/depreciation-fixed-asset-page'])

const breadcrumb = (
  <BreadcrumbNavigation sectionName={i18n.t('pages.accounting.DepreciationFixedAssetPage.titles.title_page')} />
)
export const DepreciationFixedAssetContainer = () => {
  const { taxDeclarationReportList, masterTaxDeclaration, taxYearInfo, handleChangeTaxYear, loading } =
    useDepreciationFixedAsset()
  const { visible, handleOpenModal } = useExportDepreciationModal()
  const { isEnable: isAllowedForMultiExportTaxDeclaration } = useCheckFlag(FeatureFlagNames.MultiExportTaxDeclaration)

  const reports = depreciationFixedAssetSlice.useSlice(state => state.data?.reports)
  const isHasSelectedItem = reports?.some(report => report.isSelected)

  const { onSubmitExport, loadingExportCsv } = useSubmitExport({ handleOpenModal, year: taxYearInfo })
  return (
    <ListPageTemplate breadcrumb={breadcrumb}>
      <Spin loading={loadingExportCsv}>
        <ActionWrapper>
          <AutoCompleteSelect
            style={{
              maxWidth: '200px',
              width: '100%',
            }}
            name="depreciation_assets_tax_declaration"
            valueField="code"
            textField="name_jp"
            value={taxYearInfo}
            onChange={handleChangeTaxYear}
            items={masterTaxDeclaration}
            allowClear={false}
          />
        </ActionWrapper>
        <If condition={isAllowedForMultiExportTaxDeclaration}>
          <StyledTableTop>
            <CollectiveCheckbox disabled={!reports?.length} />
            <Button color="grey" onClick={() => handleOpenModal(true)} disabled={!isHasSelectedItem}>
              {t('export_modal.title')}
            </Button>
          </StyledTableTop>
        </If>

        <Spin loading={loading}>
          <DepreciationFixedAssetList data={taxDeclarationReportList || []} taxYear={taxYearInfo} />
        </Spin>
        <ExportDepreciationModal visible={visible} onSubmitExport={onSubmitExport} handleOpenModal={handleOpenModal} />
      </Spin>
    </ListPageTemplate>
  )
}
