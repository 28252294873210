import styled from '@emotion/styled'

export const Wrapper = styled.div`
  text-align: left;

  .ant-select-selector {
    border-radius: 4px;
    font-size: 13px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-multiple {
    .ant-select-selection-search:first-of-type {
      .ant-select-selection-search-input {
        margin-left: 2px;
      }
    }
  }
`
