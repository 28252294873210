import { Tooltip } from 'aa_common/front-end/antd'
import { FILE_DOWNLOAD_PATH, MAXLENGTH_TEXT } from 'common/constants'
import { formatMaxText } from 'common/helpers/format-helper'
import FileIcon from 'components/atoms/icons/FileIcon'
import React from 'react'

import { Label, Wrapper } from './styles'

type File = {
  id: number | string
  filename: string
}

type Props = {
  file: File
  style?: React.CSSProperties
  maxLength?: number
  iconColor?: string
}

const FileLink: React.FC<Props> = ({ file, style, maxLength = MAXLENGTH_TEXT, iconColor }) => {
  const handleDownload = () => {
    window.open(`${FILE_DOWNLOAD_PATH}/${file.id}`, '_blank')
  }

  return (
    <Wrapper onClick={handleDownload} className="file-link" style={style}>
      <FileIcon color={iconColor} />
      {file.filename.length > maxLength ? (
        <Tooltip content={file.filename}>
          <Label>{formatMaxText(file.filename, maxLength)}</Label>
        </Tooltip>
      ) : (
        <Label>{file.filename}</Label>
      )}
    </Wrapper>
  )
}

export default FileLink
