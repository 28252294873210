import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AssetEditContainer } from 'containers/assets/asset-edit-container/asset-edit-container'
import React from 'react'

import AssetEditPageOld from './asset-edit-page'

const AssetEditPage: React.FC = () => {
  const { isEnable: isRefactorPagePhase1_4 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase1_4)

  return <>{isRefactorPagePhase1_4 ? <AssetEditContainer /> : <AssetEditPageOld />}</>
}

export default AssetEditPage
