/* eslint-disable react/display-name */

import { BaseRowMouseEvent, ComplexTable, messageFn, TableDropdownMenu } from 'aa_common/front-end'
import { ConfirmModal } from 'aa_common/front-end/components'
import { Permission } from 'common/constants'
import { getErrorMsg, isRetroactiveAsset } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FixedAssetListItem, LedgerSetting } from 'common/models'
import { FixedAssetsService } from 'common/open-api'
import { getAxiosSilent } from 'common/open-api/helper'
import { NoDataImage } from 'components/atoms'
import { isEmpty, isEqual } from 'lodash'
import { assetListPageStore, rowSelectSliceAssetList } from 'pages/assets/AssetListPage/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectAssetsCollectionView } from 'store/asset/selectors'
import { useDebounce } from 'use-debounce'

import { ExtAxiosError } from '../../../../types/custom-types'
import { useCorrection } from '../hooks/use-correction'
import { fixedColumnsTableSettings } from './config'
import { assetListHelper } from './helper'
import { EmptyImage, Wrapper } from './styles'
import { validateDeletable } from './tableSettings/helper'
import { fixedAssetTableColumns } from './tableSettings/pattern1'
import { fixedAssetTablePattern2Columns, parseDataForPattern2 } from './tableSettings/pattern2'

const t = loadNs(['components/organisms/assets/asset-list'])
const t1 = loadNs('common')
interface AssetListProps {
  assets: FixedAssetListItem[]
  filterAt: string
  searchWords: string[]
  sorter?: any
  isDetailMode: boolean
  ledgerSettings: LedgerSetting[]
  fixedAssetPermissions: Permission
  commentPermissions: Permission
  onShowItem: (item: FixedAssetListItem) => void
  onCopy: (item: any) => void
  onDelete: (item: any) => void
  onTableSort: (event: any) => void
}

const AssetList = ({
  assets,
  searchWords,
  ledgerSettings,
  sorter,
  filterAt,
  isDetailMode: detailMode,
  fixedAssetPermissions,
  commentPermissions,
  onShowItem,
  onCopy,
  onDelete,
  onTableSort,
}: AssetListProps) => {
  const history = useHistory()
  const { isCorrectable, checkEventsAllowedForCorrection, checkDateWithMonthlyClosing } = useCorrection()
  const [deletingItem, setDeletingItem] = useState(null)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)
  const { filters } = useSelector(selectAssetsCollectionView, isEqual)
  const rowSelectSliceAssetListStore = rowSelectSliceAssetList.useSlice(state => state.data)
  // Debounce the state in order to improve performance.
  const [isDetailMode] = useDebounce(detailMode, 10)
  const isEmptyAssets = isEmpty(assets)

  useEffect(() => {
    const focusRow = document.querySelector('.row-highlight') as HTMLElement
    if (!isEmptyAssets && focusRow) {
      setTimeout(() => {
        scrollIntoView(focusRow, {
          align: {
            top: 0,
            left: 0,
          },
        })
      }, 200)
    }
  }, [isEmptyAssets])

  const handleClickDelete = useCallback((item: any) => {
    setDeletingItem(item)
    setIsConfirmingDelete(true)
  }, [])

  const handleOnRowClick = useCallback(
    ({ record }: BaseRowMouseEvent) => {
      record && onShowItem(record)
    },
    [onShowItem]
  )

  const handleCancelDelete = () => {
    setDeletingItem(null)
    setIsConfirmingDelete(false)
  }

  const handleSubmitDelete = () => {
    onDelete && onDelete(deletingItem)
    setDeletingItem(null)
    setIsConfirmingDelete(false)
  }

  const handleOnRow = (record: any) => record.isHighlight && { className: 'row-highlight' }

  const columnsSettings = useMemo(() => {
    const fn = isDetailMode ? fixedAssetTablePattern2Columns : fixedAssetTableColumns
    return fn({
      sorter,
      searchWords,
      filterAt,
      ledgerSettings,
      permissions: commentPermissions,
    })
  }, [sorter, isDetailMode, filterAt, searchWords, ledgerSettings, commentPermissions])

  const memoizedData = useMemo(() => {
    // This logic is for display of "delete" button.
    // Now only "acquired_before_use" asset is deletable
    const convertedAssets = assets?.map((item: any) => {
      return {
        ...item,
        id: item.biid,
        isDeletable: validateDeletable(item, monthlyClosingCurrent),
      }
    })

    return isDetailMode ? parseDataForPattern2(convertedAssets) : convertedAssets
  }, [assets, isDetailMode, monthlyClosingCurrent])

  const expandableSettings = useMemo(() => {
    return isDetailMode
      ? {
          expandIconColumnIndex: -1,
          expandedRowKeys: assets?.map((item: any) => item.biid),
          defaultExpandAllRows: true,
          indentSize: 0,
        }
      : undefined
  }, [isDetailMode, assets])

  const rowSelectionRender = useCallback(
    (checked: boolean, record: any, index: number, originNode: any) => {
      return ((record.isParent && isDetailMode) || !isDetailMode) && <div className="selection-cell">{originNode}</div>
    },
    [isDetailMode]
  )

  const handleCorrection = async (biid: string) => {
    try {
      const response = await FixedAssetsService.isCorrectableDetail(biid, getAxiosSilent())
      if (response.data) {
        history.push(`/assets/${biid}/correction`)
      }
    } catch (err) {
      const errorMessage = getErrorMsg(err as ExtAxiosError)
      messageFn().error(errorMessage)
    }
  }

  const renderActions = useCallback(
    (record: any) => {
      const { change_situation, transition_at, acquired_at } = record
      const isEnableCorrectButton =
        checkEventsAllowedForCorrection(change_situation?.code) &&
        (checkDateWithMonthlyClosing(transition_at || acquired_at) || isRetroactiveAsset(record, monthlyClosingCurrent))

      return (
        ((record.isParent && isDetailMode) || !isDetailMode) && (
          <TableDropdownMenu
            data={record}
            permissions={fixedAssetPermissions}
            onCopy={onCopy}
            onDelete={handleClickDelete}
            onCorrection={handleCorrection}
            isEnableCorrectButton={isEnableCorrectButton}
            isHavePermissionToCorrect={isCorrectable}
          />
        )
      )
    },
    [isDetailMode, fixedAssetPermissions, onCopy, handleClickDelete]
  )
  const data = rowSelectSliceAssetList.useSlice(state => state.data)
  const rowBiids = memoizedData.map(item => item.biid)

  const selectedRows = React.useMemo(() => {
    return rowBiids.filter(biid => data?.selectedItem[biid])
  }, [data, rowBiids])
  return (
    <Wrapper>
      {isEmptyAssets ? (
        <EmptyImage>
          <NoDataImage text={t('msg_no_data')} />
        </EmptyImage>
      ) : (
        <ComplexTable
          enableSelectRows={!filters?.include_history}
          rowSelection={{ selectedRowKeys: selectedRows }}
          className={isDetailMode ? 'fixed-asset-detail-mode' : ''}
          tableLayout="fixed"
          fixedColumnsTableSettings={fixedColumnsTableSettings}
          columns={columnsSettings}
          data={memoizedData}
          fixedAction
          expandableSettings={expandableSettings}
          rowSelectionCellRender={rowSelectionRender}
          renderActions={renderActions}
          onRow={handleOnRow}
          onRowClick={handleOnRowClick}
          onRowMouseOver={assetListHelper.handleOnRowMouseOver}
          onRowMouseLeave={assetListHelper.handleOnRowMouseLeave}
          onColumnSortChanged={onTableSort}
          onDeleteClicked={handleClickDelete}
          onCopyClicked={onCopy}
          onSelectSingleRow={(record, selected) => {
            assetListPageStore.addSelectRows([{ id: record.biid, isSelected: selected }])
          }}
          rowClassName={record => {
            return rowSelectSliceAssetListStore?.errorBiids.includes(record?.biid) ? 'error-row' : ''
          }}
        />
      )}
      <ConfirmModal
        message={t1('messages.confirm_delete', { name: t('asset') })}
        visible={isConfirmingDelete}
        onOK={handleSubmitDelete}
        onCancel={handleCancelDelete}
      />
    </Wrapper>
  )
}

export default AssetList
