import { ReactComponent as CheckCircleIcon } from 'aa_common/front-end/assets/icons/check-circle.svg'
import { LinkLabel } from 'aa_common/front-end/components'
import { message as messageAnt } from 'antd'
import { loadNs } from 'common/i18n-config'
import { useEffect } from 'react'

import { SuccessIcon } from '../styles'

type Props = {
  isShow: boolean
  onClick: () => void
  onClose: () => void
}

const t = loadNs(['components/organisms/assets/asset-detail', 'common'])

const ConfirmMessage = ({ isShow, onClick, onClose }: Props) => {
  const message = (
    <>
      <label>{t('messages.created_success')}</label>
      <LinkLabel onClick={onClick} style={{ marginLeft: 5 }}>
        {t('check')}
      </LinkLabel>
    </>
  )

  useEffect(() => {
    if (isShow) {
      messageAnt.success({
        content: message,
        icon: <SuccessIcon component={CheckCircleIcon} />,
        onClose,
        duration: 8,
      })
    }
  }, [isShow]) // eslint-disable-line

  return null
}

export default ConfirmMessage
