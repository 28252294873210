import * as officeUserApi from 'api/app/officeUser'
import * as roleApi from 'api/app/role'
import { AxiosResponse } from 'axios'
import { parseError, parseValidationError } from 'common/helpers'
import { convertToOfficeUserItemResponse, convertToOfficeUserListResponse } from 'converters/settings/officeUser'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, showErrorsAppCall } from 'store/app/actions'

import {
  AssignRolesOfficeUserRequest,
  DeleteOfficeUserRequest,
  FetchOfficeUserItemRequest,
  InviteUserRequest,
} from './action.model'
import {
  fetchOfficeUserList as fetchOfficeUsers,
  handleDeleteOfficeUserFailure,
  handleDeleteOfficeUserSuccess,
  handleFetchCreatableUsersFailure,
  handleFetchCreatableUsersSuccess,
  handleFetchOfficeUserFailure,
  handleFetchOfficeUserListFailure,
  handleFetchOfficeUserListSuccess,
  handleFetchOfficeUserSuccess,
  handleFetchUserRoleOptionsFailure,
  handleFetchUserRoleOptionsSuccess,
  handleInviteUserFailure,
  handleInviteUserSuccess,
  handleSaveOfficeUserFailure,
  handleSaveOfficeUserSuccess,
} from './actions'
import {
  ASSIGN_ROLES_OFFICE_USER_REQUEST,
  DELETE_OFFICE_USER_REQUEST,
  FETCH_CREATABLE_OFFICE_USERS_REQUEST,
  FETCH_OFFICE_USER_ITEM_REQUEST,
  FETCH_OFFICE_USER_LIST_REQUEST,
  FETCH_USER_ROLE_OPTIONS_REQUEST,
  INVITE_USER_REQUEST,
} from './constant'

export function* fetchOfficeUserList() {
  try {
    const response: AxiosResponse = yield call(officeUserApi.list)
    const convertOfficeUserList = convertToOfficeUserListResponse(response?.data?.data)
    yield put(handleFetchOfficeUserListSuccess(convertOfficeUserList))
  } catch (error) {
    yield put(handleFetchOfficeUserListFailure())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchOfficeUserItem({ payload }: FetchOfficeUserItemRequest) {
  try {
    const response: AxiosResponse = yield call(officeUserApi.getUserById, payload.id)
    const convertOfficeUser = convertToOfficeUserItemResponse(response.data.data)
    yield put(handleFetchOfficeUserSuccess(convertOfficeUser))
  } catch (error) {
    yield put(handleFetchOfficeUserFailure())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchUserRoleOptions() {
  try {
    const response: AxiosResponse = yield call(roleApi.list)
    yield put(handleFetchUserRoleOptionsSuccess(response.data.data))
  } catch (error) {
    yield put(handleFetchUserRoleOptionsFailure())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* inviteUser({ payload }: InviteUserRequest) {
  try {
    yield call(officeUserApi.inviteUser, payload)
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.created_success') }))
    yield put(handleInviteUserSuccess())
    yield put(changeLocation('/setting/users'))
  } catch (error) {
    const errorPayload = parseValidationError(get(error, 'response.data.errors', undefined))
    yield put(handleInviteUserFailure(errorPayload))
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* assignRolesOfficeUser({ payload }: AssignRolesOfficeUserRequest) {
  try {
    yield call(officeUserApi.assignRolesForUser, payload)
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.updated_success') }))
    yield put(handleSaveOfficeUserSuccess())
    yield put(changeLocation('/setting/users'))
  } catch (error) {
    const errorPayload = parseValidationError(get(error, 'response.data.errors', undefined))
    yield put(handleSaveOfficeUserFailure(errorPayload))
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* deleteOfficeUser({ payload }: DeleteOfficeUserRequest) {
  try {
    yield call(officeUserApi.deleteOfficeUserById, payload.id)
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.deleted_success') }))
    yield put(handleDeleteOfficeUserSuccess())
    yield put(fetchOfficeUsers())
  } catch (error) {
    yield put(
      setAppMessage({ type: 'failure', content: i18n.t('common.messages.deleted_failure', { name: payload.name }) })
    )
    yield put(handleDeleteOfficeUserFailure())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchCreatableUsers() {
  try {
    const response: AxiosResponse = yield call(officeUserApi.getCreatableOfficeUsers)
    const convertOfficeUserList = convertToOfficeUserListResponse(response?.data?.data)
    yield put(handleFetchCreatableUsersSuccess(convertOfficeUserList))
  } catch (error) {
    yield put(handleFetchCreatableUsersFailure())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_OFFICE_USER_LIST_REQUEST, fetchOfficeUserList)
  yield takeLatest(FETCH_OFFICE_USER_ITEM_REQUEST, fetchOfficeUserItem)
  yield takeLatest(FETCH_USER_ROLE_OPTIONS_REQUEST, fetchUserRoleOptions)
  yield takeLatest(FETCH_CREATABLE_OFFICE_USERS_REQUEST, fetchCreatableUsers)
  yield takeLatest(ASSIGN_ROLES_OFFICE_USER_REQUEST, assignRolesOfficeUser)
  yield takeLatest(DELETE_OFFICE_USER_REQUEST, deleteOfficeUser)
  yield takeLatest(INVITE_USER_REQUEST, inviteUser)
}
