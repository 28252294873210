import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const CustomModalClass = css`
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
`

export const SubmitAreaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .select-month-wrapper {
    display: flex;
    margin-right: 15px;
    align-items: center;

    .label {
      color: #333333;
      margin-right: 10px;
    }
  }

  .select-wrapper {
    display: flex;
    .ant-select-item {
      text-align: left;
    }
  }
`

export const EditFormWrapper = styled.div`
  .top,
  .bottom,
  .middle {
    padding: 5px 10px;
  }
`

export const Section = styled.div`
  background: #f4f4f4;
  padding: 5px 10px;
  color: #7c8291;

  border-top: 1px solid #d4d8dd;
  border-bottom: 1px solid #d4d8dd;

  h4 {
    font-size: 13px;
    color: #7c8291;
    margin-bottom: 0;
  }
`

export const Field = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;

    .label-text {
      flex: 1 1 0%;
      min-width: 0px;

      h4 {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    .required-label-wrapper {
      flex: 0 1 auto;

      .required-label {
        display: block;
        color: white;
        background-color: #ec4949;
        padding: 1px 4px;
        border-radius: 4px;
      }
    }
  }

  .content {
    width: auto;
    padding-left: 10px;
  }

  .slip-date-form-wrapper,
  .accounting-plus-status-form-wrapper {
    display: flex;
    align-items: center;

    > h4 + div {
      margin-left: 5px;
      white-space: pre-wrap;
    }

    svg.fa-question-circle {
      font-size: 18px;
      margin-top: 3px;
      margin-right: 5px;
      cursor: pointer;
    }

    .ant-tooltip {
      .ant-tooltip-inner {
        padding: 10px;
        font-size: 13px;
        width: 500px;
      }
    }
  }

  .accounting-plus-status-form-wrapper {
    .ant-tooltip {
      .ant-tooltip-inner {
        width: 384px;
      }
    }

    .accounting-plus-status {
      white-space: break-spaces;
    }
  }
`
