import { convertComplexTableSortPayload, ifElse } from 'aa_common/front-end'
import { ASSET_STATUSES, DATE_FORMAT_RFC } from 'common/constants'
import { FixedAssetListItem } from 'common/models'
import { AssetCollectionFilterEvent } from 'components/organisms/assets/AssetFilterFacet/model'
import { List, Map } from 'immutable'
import find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { deleteAsset } from 'store/asset/actions'

import { DEFAULT_PAGE_SIZE, DETAIL_MODE_PAGE_SIZE } from '../constant'
import { getSingleViewParam, hookFnc } from '../helper'
import { useFetchAssetListCallback } from './use-fetch-asset-list-callback'
import { useFiltersSort } from './use-filters-sort'

export const useActionAssetList = () => {
  const dispatch = useDispatch()

  const { filters, sort, assetCollectionViewSelector, assetCollectionViewFilterValues } = useFiltersSort()
  const fetchAssetList = useFetchAssetListCallback()
  const isDetailMode = get(filters, 'include_history')
  const pageSize = ifElse(isDetailMode, DETAIL_MODE_PAGE_SIZE, DEFAULT_PAGE_SIZE)

  const handleListModeChanged = useCallback(
    (detailMode: boolean) => {
      const todayDate = moment().format(DATE_FORMAT_RFC)
      const newValue = { valid_at: todayDate, include_history: detailMode }
      const newFilters = { ...filters, ...newValue }
      const newFormFilterValues = { ...assetCollectionViewFilterValues, ...newValue }

      const pageSize = detailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
      dispatch(fetchAssetList({ filters: newFilters, sort, formValues: newFormFilterValues }, 1, pageSize))
    },
    [dispatch, fetchAssetList, filters, assetCollectionViewFilterValues, sort]
  )

  const handleSubmitFilter = useCallback(
    (event: AssetCollectionFilterEvent) => {
      hookFnc.submitFilter(event, filters, sort, dispatch, fetchAssetList)
    },
    [dispatch, fetchAssetList, filters, sort]
  )

  const handleFilterReset = useCallback(
    (event: AssetCollectionFilterEvent) => {
      // Keep the previous valid_at and also detail mode status;
      hookFnc.resetFilter(event, filters, sort, dispatch, fetchAssetList)
    },
    [dispatch, fetchAssetList, filters, sort]
  )

  const handleColumnSortChanged = useCallback(
    (sorter: any) => {
      const sort = convertComplexTableSortPayload(sorter)
      dispatch(fetchAssetList({ filters, sort, sorterValue: sorter }, assetCollectionViewSelector.page, pageSize))
    },
    [dispatch, fetchAssetList, filters, assetCollectionViewSelector.page, pageSize]
  )

  const handleDelete = useCallback(
    (data: any) => {
      const isDetailMode = get(filters, 'include_history')
      const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
      dispatch(deleteAsset(data, pageSize))
    },
    [dispatch, pageSize]
  )

  const handleCopy = useCallback(
    (data: any) => dispatch(changeLocation(`/assets/${get(data, 'biid')}/copy`)),
    [dispatch]
  )

  const handleValidAtChanged = useCallback(
    dateValue => {
      const assetStatus = List(assetCollectionViewFilterValues?.asset_status).filter(
        (selected: any) => ![ASSET_STATUSES.TO_BE_ACQUIRED, ASSET_STATUSES.RETIRED_OR_SOLD].includes(selected.value)
      )

      const newFilters = Map(filters)
        .setIn(['area_biid'], undefined)
        .setIn(['department_biid'], undefined)
        .setIn(['asset_status'], assetStatus.map((item: any) => item.value).toArray())
        .setIn(['valid_at'], dateValue)
        .toObject()

      const formValues = Map(assetCollectionViewFilterValues)
        .setIn(['department'], [])
        .setIn(['area'], [])
        .setIn(['asset_status'], assetStatus.toArray())
        .toObject()

      const isDetailMode = get(newFilters, 'include_history')
      const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE

      setTimeout(() => {
        dispatch(fetchAssetList({ filters: newFilters, sort, formValues }, 1, pageSize))
      }, 180)
    },
    [dispatch, fetchAssetList, assetCollectionViewFilterValues, filters, sort, pageSize]
  )
  const handlePageChanged = useCallback(
    currentPage => {
      dispatch(fetchAssetList({ filters, sort }, currentPage, pageSize))
    },
    [dispatch, fetchAssetList, filters, sort, pageSize]
  )
  const handleShowItem = useCallback(
    (record: FixedAssetListItem) => {
      const validAt = filters?.valid_at
      const isDetailMode = filters?.include_history && record?.change_situations

      let param = ''

      const validAtDate = moment(validAt)

      if (isDetailMode) {
        const currentEventItem = find(record.change_situations, event => {
          const validFromDate = moment(event.valid_from)
          const validToDate = moment(event.valid_to)
          return validAtDate.isBetween(validFromDate, validToDate, 'date', '[]')
        })

        param = getSingleViewParam(validAt, currentEventItem)
      } else {
        param = getSingleViewParam(validAt, record)
      }

      dispatch(changeLocation(`/assets/${record.biid}${param}`))
    },
    [dispatch, filters?.valid_at, filters?.include_history]
  )
  const generateSearchWords = useMemo(() => {
    if (filters) {
      const keywordValue = get(filters, 'keyword') as string
      const keyword = keywordValue ? keywordValue.trim() : ''
      return [...keyword.split(' '), keyword]
    }

    return []
  }, [filters])

  return {
    handleListModeChanged,
    handleSubmitFilter,
    handleFilterReset,
    handleColumnSortChanged,
    handleDelete,
    handleCopy,
    handleValidAtChanged,
    handlePageChanged,
    handleShowItem,
    generateSearchWords,
  }
}
