import { getNs } from 'common/i18n-config'

const { t } = getNs('common')

export enum ASSET_CHANGE_SITUATION_TYPES {
  ACQUISITION = 10,
  USAGE_STARTED = 20,
  RETIREMENT = 30,
  SELL = 40,
  MOVEMENT = 50,
  SWITCH = 90,
  OTHER_CHANGE = 99,
  ACCEPTANCE_OF_TRANSITION = 11,
  IMPAIRMENT = 60,
  DIVIDING = 53,
}

export enum CAUSE_TYPES {
  ACQUISITION = 1,
  USAGE_STARTED = 2,
  RETIREMENT = 10,
  SELL = 20,
  MOVEMENT = 30,
  DIVIDING = 31,
  IMPAIRMENT = 40,
  SWITCH_CATEGORY = 70,
}

export enum ACCOUNTING_PERIOD_TERM_MONTH_TYPES {
  DEFAULT = 0,
  BASE_ON_START_DATE = 1,
  BASE_ON_END_DATE = 2,
}

export enum ASSET_STATUSES {
  TO_BE_ACQUIRED = 1,
  ACQUIRED_BEFORE_USE = 10,
  IN_SREVICE_DEPRECIATING = 20,
  IN_IDLE = 25,
  IN_SREVICE_DEPRECIATED = 29,
  RETIRED_OR_SOLD = 30,
}

export enum LEDGER_PURPOSE {
  ACCOUNTING = 1,
  TAX = 2,
  TAX_AND_ACCOUNTING = 4,
  RFRS = 3,
  OTHER = 9,
}

export enum ASSET_CODE_AUTO_GENERATE_SETTING_METHOD {
  AUTO_NUMBER = 1,
  AUTO_NUMBER_BRANCH = 2,
  NO_AUTO_NUMBER = 3,
}

export enum ASSET_CODE_SETTING_RULE_TYPE_CODE {
  ASSET_CATEGORY = 1,
  ACQUISITION_DATE = 2,
  SYMBOL = 3,
  FIXED_VALUE = 4,
}

export enum ASSET_CODE_SETTING_RULE_SYMBOL_CODE {
  HYPHEN = 1,
  UNDERSCORE = 2,
  PERIOD = 3,
}

export enum ASSET_CODE_SETTING_RULE_LENGTH_CODE {
  FIVE_CHARACTERS = 1,
  THREE_CHARACTERS = 2,
}

export enum ASSET_CODE_SETTING_SCOPE_CODE {
  EACH_PATTERN = 1,
  WHOLE_OFFICE = 2,
}

export enum RETIREMENT_DEPRECIATION_ENDED_METHOD {
  END_OF_CURRENT_MONTH = 1,
  END_OF_LAST_MONTH = 2,
  END_OF_DEPRECIATION = 6,
  SELECT_WHEN_SELL_AND_RETIREMENT = 9,
  END_OF_LAST_YEAR = 5,
}

export enum INPUT_TAX_WHEN_REGISTER_FIXED_ASSET {
  NOT_EXECUTED = 1,
  EXECUTED = 2,
}

export enum AUTOMATIC_JOURNAL_ENTRY_ACQUISITION_TYPE {
  NOT_EXECUTED = 1,
  EXECUTED = 2,
}

export enum JOURNAL_EVENT_TYPE {
  ACQUISITION = 1,
  RETIREMENT = 2,
  SOLD = 3,
  DEPRECIATION = 4,
  IMPAIRMENT = 5,
}

export enum DEPRECIATION_METHOD_CODE {
  BULK_EQUAL_INSTALLMENT_METHOD = 30,
  DEFERRED_EQUAL_INSTALLMENT = 40,
  AVERAGE_USAGE_PERIOD = 50,
  LEASE_PERIOD_STRAIGHT_LINE = 60,
  OLD_STRAIGHT_LINE = 10,
  NEW_STRAIGHT_LINE = 11,
  OLD_DECLINING_BALANCE = 20,
  NEW_DECLINING_BALANCE_250 = 21,
  NEW_DECLINING_BALANCE_200 = 22,
  SMALL_AMOUNT_DEPRECIABLE_ASSET = 80,
  NON_DEPRECIABLE = 90,
  NON_DEPRECIATION = 99,
}

export enum DEPRECIATION_METHOD {
  CONTINUE_DEPRECIATION = 1,
  STOP_DEPRECIATION = 2,
}

export enum EXECUTE_VAT {
  NOT_EXECUTE = 1,
  EXECUTE = 2,
}

export enum EXCISE_TAX_METHOD {
  EXCLUDED_TAX = 1,
  INCLUDED_TAX = 2,
}

export enum EXCISE_TAX_INPUT_FORMAT {
  EXTERNAL_TAX = 1,
  INTERNAL_TAX = 2,
}

export enum EXCISE_ROUNDING_METHOD {
  ROUND_DOWN = 1,
  ROUND_UP = 2,
  ROUND_OFF = 3,
}

export enum EXCISE_CAN_EDIT_TAX {
  NO = 1,
  YES = 2,
}

export enum IMPAIRMENT_LOSS_APPLY_METHOD {
  END_OF_CURRENT_MONTH = 1,
  END_OF_LAST_MONTH = 2,
  SELECT_ON_IMPAIRMENT = 9,
}

export const MASTER_PAGES = {
  ASSET_CATEGORIES: t('master.asset_categories'),
  AREAS: t('master.areas'),
  DEPARTMENTS: t('master.departments'),
  UNITS: t('master.units'),
}

export enum ASSET_TYPE {
  TANGIBLE = 1, // 有形固定資産
  INTANGIBLE = 2, // 無形固定資産
}

export const CSV_CHARSET = {
  UTF8: 'utf-8',
  SHIFT_JIS: 'shift_jis',
}
