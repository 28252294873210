import styled from '@emotion/styled/macro'

export const ListWrapper = styled.ul``

export const ItemWrapper = styled.li`
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  margin-bottom: 24px;
  list-style: none;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Section = styled.div`
  background: #f4f4f4;
  padding: 5px 10px;
  color: #7c8291;

  h4 {
    font-size: 13px;
    color: #7c8291;
    margin-bottom: 0;
  }
`

export const Field = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  align-items: center;
  background: white;
  border-bottom: 1px solid #d4d8dd;

  + ${Section} {
    border-bottom: 1px solid #d4d8dd;
  }

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
    border-bottom: none;
  }

  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    width: 400px;

    .label-text {
      flex: 1 1 0%;
      min-width: 0px;
      display: flex;
      align-items: center;

      h4 {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 0;
      }

      .accounting-plus-status {
        white-space: break-spaces;
      }
    }

    .current-label-wrapper {
      flex: 0 1 auto;

      .current-label {
        display: block;
        color: white;
        background-color: #3b7de9;
        padding: 1px 4px;
        border-radius: 4px;
      }
    }
  }

  .ant-tooltip {
    .ant-tooltip-inner {
      padding: 10px;
      font-size: 13px;
    }
  }
  .slip-date-wrapper {
    .ant-tooltip-inner {
      width: 500px;
    }
  }
  .accounting-plus-status-tooltip-wrapper {
    .ant-tooltip-inner {
      width: 384px;
    }
  }

  .content {
    width: 100%;

    .content-text {
      flex: 1 1 0%;

      + .actions {
        margin-left: 10px;
      }
    }

    .actions {
      flex: 0 1 auto;
      align-self: flex-end;

      .aa-link {
        padding: 0;
        margin-right: 10px;
        height: auto;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .content-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
