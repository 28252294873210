import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { PresenterOneTagResponse, TagsService } from 'common/open-api'
import { parseErrorResponse } from 'common/utils/response-helper'

const initFilter = {}
const initState: { tags: PresenterOneTagResponse[] } = {
  tags: [],
}

const getTagList: FilterFunction<typeof initState, typeof initFilter> = async () => {
  try {
    const tags = (await TagsService.tagsList())?.data
    return { data: { tags } }
  } catch (err: any) {
    parseErrorResponse(err)
    return { data: { tags: [] } }
  }
}

export const tagListSlice = initAsyncSlice<typeof initState, typeof initFilter>(
  'master/tags',
  initState,
  initFilter,
  getTagList
)
