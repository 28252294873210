import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function useActiveTab() {
  const [activeTab, setActiveTab] = useState<string>('acquisition')

  const location = useLocation()
  useEffect(() => {
    const { hash } = location
    if (hash) {
      setActiveTab(hash.replace('#', ''))
    }
  }, [location])

  const history = useHistory()
  const handleOnTabChange = (activeKey: string) => {
    setActiveTab(activeKey)
    history.replace({
      hash: activeKey,
    })
  }

  return { activeTab, handleOnTabChange }
}
