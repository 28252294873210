import { convertSortValue, downloadRequestParams } from 'common/helpers'
import { CsvCharset } from 'common/models'
import { ExportService } from 'common/open-api'
import { importExportEventTypeSelectorSlice, importExportStore } from 'pages/assets/AssetListPage/store'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { exportAsset } from 'store/asset/actions'

import { IMPORT_EXPORT_EVENT_TYPES } from '../import-export-event-type-selector'
import { useFiltersSort } from './use-filters-sort'
import { useGetSelectedFa } from './use-get-selected-fa'

export const useExportAssets = () => {
  const dispatch = useDispatch()

  const [isShowExportModal, setIsShowExportModal] = useState(false)

  const handleExportClick = () => setIsShowExportModal(true)
  const { assetCollectionViewSelector } = useFiltersSort()
  const { totalBiidOfSelectedItems } = useGetSelectedFa()

  const numberOfExportItems = totalBiidOfSelectedItems.length
    ? totalBiidOfSelectedItems.length
    : assetCollectionViewSelector.totalRecords
  const hasIncludeHistory = !!assetCollectionViewSelector.filters?.include_history
  const { exportEventType } = importExportEventTypeSelectorSlice.useSlice()

  const handleExport = async (charset: CsvCharset) => {
    setIsShowExportModal(false)
    try {
      if (
        hasIncludeHistory ||
        (!hasIncludeHistory && exportEventType === IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER)
      ) {
        dispatch(
          exportAsset(
            {
              ...assetCollectionViewSelector.filters,
              ...(!!totalBiidOfSelectedItems.length && { biid: totalBiidOfSelectedItems }),
            },
            assetCollectionViewSelector.sort,
            charset
          )
        )
      } else {
        const filters = {
          ...assetCollectionViewSelector.filters,
          event_type_code: exportEventType,
          ...(!!totalBiidOfSelectedItems.length && { biid: totalBiidOfSelectedItems }),
        }
        const sortQuery = convertSortValue(assetCollectionViewSelector.sort) as any

        const response = await ExportService.fixedAssetsEventTypeList(
          {
            charset,
            sort: sortQuery,
            filter: filters,
          } as any,
          downloadRequestParams
        )

        response && importExportStore.setExportEventType(IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER)
      }
    } catch (err) {
      /* empty */
    }
  }

  const handleCancelModal = () => {
    setIsShowExportModal(false)
    importExportStore.setExportEventType(IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER)
  }

  return {
    handleExportClick,
    handleExport,
    handleCancelModal,
    isShowExportModal,
    numberOfExportItems,
  }
}
