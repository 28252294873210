import { Button } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import React from 'react'
import { useWatch } from 'react-hook-form'

import AutoCompleteSelect from './AutoCompleteSelect'
import { validateForm } from './helper'
import { FormFooterWrapper } from './styles'

const t = loadNs(['common', 'components/organisms/settings/excise-setting-form-modal'])

export type FormFooterProps = { allowSelectTerm: boolean; termList: any[] }

const FormFooter = ({ allowSelectTerm, termList }: FormFooterProps) => {
  const value = useWatch({
    name: [
      'tax_method',
      'default_excise_disposal_cost_id',
      'default_excise_sell_id',
      'tax_input_format',
      'rounding_method',
      'can_edit_tax',
    ],
  })

  return (
    <FormFooterWrapper>
      <label>{t('start_date')}</label>
      <AutoCompleteSelect
        name="from_term_id"
        items={termList}
        textField="text"
        valueField="value"
        disabled={!allowSelectTerm}
        style={{ width: 110, marginLeft: 15 }}
      />
      <Button type="submit" size="custom" style={{ width: 70, marginLeft: 15 }} disabled={!validateForm(value)}>
        {t('actions.save')}
      </Button>
    </FormFooterWrapper>
  )
}

export default FormFooter
