/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterOneMonthlyClosingSettingResponse,
  PresenterUpdateMonthlyClosingSettingRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MonthlyClosingSettings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Update monthly closing setting
   *
   * @tags monthly_closing_settings
   * @name MonthlyClosingSettingsUpdate
   * @summary Update the monthly closing setting
   * @request PUT:/monthly_closing_settings/{id}
   */
  monthlyClosingSettingsUpdate = (
    id: number | string,
    body: PresenterUpdateMonthlyClosingSettingRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneMonthlyClosingSettingResponse, PresenterError>({
      path: `/monthly_closing_settings/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get next setting of a monthly closing setting
   *
   * @tags monthly_closing_settings
   * @name NextDetail
   * @summary Get next setting of a monthly closing setting
   * @request GET:/monthly_closing_settings/{id}/next
   */
  nextDetail = (id: number | string, params: RequestParams = {}) =>
    this.http.request<PresenterOneMonthlyClosingSettingResponse, PresenterError>({
      path: `/monthly_closing_settings/${id}/next`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
