import { css } from '@emotion/css'

export const ModalAutoJournalSettingListClass = css`
  .aa-journal-item {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .journal-item-setting-table {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`
