import { BE_WS_URL } from 'common/constants/app'
import { useEffect, useRef, useState } from 'react'

export function useWebSocket<T>() {
  const [data, setData] = useState<T | null>(null)
  const webSocketRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    const createWebSocket = () => {
      const ws = new WebSocket(BE_WS_URL)
      ws.onmessage = e => {
        const data = e?.data && JSON.parse(e?.data)
        setData(data)
      }

      ws.onclose = () => {
        createWebSocket()
      }

      webSocketRef.current = ws
    }

    if (!webSocketRef.current) {
      createWebSocket()
    }

    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close()
      }
    }
  }, [])

  return { data, setData }
}
