import {
  DepartmentItemOnCloudSelector,
  DepartmentItemSelector,
  DepartmentListSelector,
  ImportDepartmentSelector,
} from './model'

export const selectDepartmentList = (state: any): DepartmentListSelector => ({
  departmentList: state.getIn(['settings', 'department', 'list', 'data'], []),
  departmentListLoading: state.getIn(['settings', 'department', 'list', 'loading']),
  departmentFilterConditions: state.getIn(['settings', 'department', 'list', 'filters'], {}),
  departmentSortConditions: state.getIn(['settings', 'department', 'list', 'sort'], []),
  pageNumber: state.getIn(['settings', 'department', 'list', 'pageNumber']),
  pageSize: state.getIn(['settings', 'department', 'list', 'pageSize']),
  totalRecords: state.getIn(['settings', 'department', 'list', 'totalRecords']),
})

export const selectImport = (state: any): ImportDepartmentSelector => ({
  importError: state.getIn(['settings', 'department', 'import', 'error'], {}),
  loading: state.getIn(['settings', 'department', 'import', 'loading']),
})

export const selectDepartmentItem = (state: any): DepartmentItemSelector => ({
  departmentData: state.getIn(['settings', 'department', 'item', 'data']),
  errors: state.getIn(['settings', 'department', 'item', 'errors']),
  loading: state.getIn(['settings', 'department', 'item', 'loading']),
})

export const selectDepartmentOnCloud = (state: any): DepartmentItemOnCloudSelector => ({
  list: state.getIn(['settings', 'department', 'departmentOnCloud', 'list']),
  loading: state.getIn(['settings', 'department', 'departmentOnCloud', 'loading']),
})
