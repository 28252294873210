import { Controller } from 'react-hook-form'

import { AutoCompleteSelect } from '../../../antd/auto-complete-select/auto-complete-select'
import { AutoCompleteSelectProps } from '../../../antd/auto-complete-select/schema'

const Select = (props: AutoCompleteSelectProps) => {
  const { control, name, required, defaultValue } = props
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ value, onChange: controllerOnChange }) => {
        return <AutoCompleteSelect {...props} value={value} onChange={controllerOnChange} />
      }}
    />
  )
}

export default Select
