import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const CoreSelectClass = css`
  text-align: left;

  &.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    border-radius: 4px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
    border-radius: 4px;
    font-size: 13px;

    input.ant-select-selection-search-input {
      height: 28px;
    }

    .ant-select-selection-placeholder {
      color: #999999;
      line-height: 28px;
      opacity: 1;
    }

    .ant-select-selection-item {
      line-height: 28px;
    }
  }

  &.ant-select {
    width: 100%;

    &.error {
      .ant-select-selector {
        border-color: #dc3545;
        background-color: #ffeeeb;
      }
    }
  }
`

export const AddItemWrapper = styled.div`
  display: flex;
  flex-wrap: 'nowrap';
  padding: 4px 8px;
`

// Styles for dropdown list
export const WrapperList = css`
  .ant-select-item {
    &.last {
      border-top: 1px solid rgba(0, 0, 0, 0.06);

      label {
        cursor: pointer;
        color: #3b7de9;
      }
    }
  }
`

export const AutoCompleteSelectTooltipOverlay = css`
  max-width: 400px;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: rgba(0, 0, 0, 0.75);
      font-weight: 500;
      white-space: pre-line;
      padding: 10px;
      border-radius: 4px;
      font-size: 13px;
    }
  }
`
