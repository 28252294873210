/* eslint-disable no-promise-executor-return */
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { DatePicker } from 'antd'
import classNames from 'classnames'
import { DATE_FORMAT, DATE_FORMAT_RFC, YEAR_FORMAT, YEAR_MONTH_FORMAT as RenderYearMonthFormat } from 'common/constants'
import i18n from 'i18n'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'

import { DatepickerProps } from './model'
import { Pending, Wrapper } from './styles'

const FULL_YEAR_MONTH_DATE_FORMAT = 'YYYYMMDD'
const YEAR_MONTH_DATE_FORMAT = 'YYMMDD'
const YEAR_MONTH_FORMAT = 'YYYYMM'
const YEAR_MONTH_FORMAT_SHORT = 'YYMM'
const YEAR_FORMAT_SHORT = 'YY'
const MONTH_DATE_FORMAT = 'MMDD'
const DAY_FORMAT = 'DD'

const Datepicker = ({
  name,
  className,
  picker,
  value,
  defaultValue,
  dateFormat,
  placeholder,
  unsettable = false,
  allowClear = true,
  disabled = false,
  showToday = true,
  error,
  control,
  isShowError = true,
  minDate,
  maxDate,
  style,
  renderExtraFooter,
  onDateChanged,
}: DatepickerProps) => {
  const [renderValue, setRenderValue] = useState<any>(value ? moment(value) : undefined)
  const [openState, setDatepickerOpen] = useState<boolean>(false)

  const refContainer = useRef(null)
  const renderValueRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      const newValue = moment(value)
      const currentValue = moment(renderValueRef.current)
      if (value) {
        if (!newValue.isSame(currentValue)) {
          setRenderValue(moment(value))
        }
      } else {
        setRenderValue(null)
      }
    }, 100)
  }, [value])

  useEffect(() => {
    renderValueRef.current = renderValue
  }, [renderValue])

  const handleEdit = useCallback(
    (controllerOnChange?: (event: any) => void) => {
      let currentDate = moment()

      if (minDate) {
        const minValue = moment(minDate)
        if (currentDate < minValue) currentDate = minValue
      }

      if (maxDate) {
        const maxValue = moment(maxDate)
        if (currentDate > maxValue) currentDate = maxValue
      }

      !control && setRenderValue(currentDate)
      controllerOnChange && controllerOnChange(currentDate)
    },
    [setRenderValue, minDate, maxDate, control]
  )

  const handleChanged = useCallback(
    async (newValue: Moment | null, controllerOnChange?: (event: any) => void) => {
      !control && setRenderValue(newValue)
      controllerOnChange && controllerOnChange(newValue)
      await delay(100)
      onDateChanged &&
        onDateChanged({
          name,
          value: newValue,
        })
    },
    [onDateChanged, name, control]
  )

  const disabledDate = useCallback(
    (current: Moment) => {
      if (minDate) {
        const minValue = moment(minDate)
        if (current && current.startOf('day') < minValue.startOf('day')) return true
      }

      if (maxDate) {
        const maxValue = moment(maxDate)
        return (
          current && current.startOf('day').format(DATE_FORMAT_RFC) > maxValue.startOf('day').format(DATE_FORMAT_RFC)
        )
      }

      return false
    },
    [minDate, maxDate]
  )

  const formatDate = useMemo(() => {
    switch (picker) {
      case 'month': {
        const actualDateFormat = dateFormat || RenderYearMonthFormat
        return [actualDateFormat, YEAR_MONTH_FORMAT, YEAR_MONTH_FORMAT_SHORT]
      }
      case 'year': {
        const actualDateFormat = dateFormat || YEAR_FORMAT
        return [actualDateFormat, YEAR_FORMAT_SHORT]
      }
      default: {
        const actualDateFormat = dateFormat || DATE_FORMAT
        return [
          actualDateFormat,
          FULL_YEAR_MONTH_DATE_FORMAT,
          YEAR_MONTH_DATE_FORMAT,
          YEAR_MONTH_FORMAT,
          MONTH_DATE_FORMAT,
          DAY_FORMAT,
        ]
      }
    }
  }, [picker, dateFormat])

  if (control) {
    const isInvalid = !!error

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ value, onChange: controllerOnChange }) => {
          return (
            <Wrapper className={classNames(className, { error: isInvalid })} ref={refContainer}>
              {(!unsettable || (unsettable && value)) && (
                <DatePicker
                  name={name}
                  format={formatDate}
                  value={value ? moment(value) : null}
                  picker={picker}
                  showToday={showToday}
                  onChange={newValue => {
                    handleChanged(newValue, controllerOnChange)
                  }}
                  open={openState}
                  onOpenChange={open => setDatepickerOpen(open)}
                  style={style}
                  allowClear={allowClear}
                  disabled={disabled}
                  disabledDate={minDate || maxDate ? disabledDate : undefined}
                  getPopupContainer={() => refContainer.current as any}
                  renderExtraFooter={
                    renderExtraFooter
                      ? () => {
                          return renderExtraFooter({ setDatepickerOpen })
                        }
                      : undefined
                  }
                />
              )}
              {unsettable && !value && (
                <Pending className="unset">
                  {i18n.t('common.form_values.unset')}
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="edit-icon"
                    onClick={() => handleEdit(controllerOnChange)}
                  />
                </Pending>
              )}
              {isInvalid && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
            </Wrapper>
          )
        }}
      />
    )
  }

  // *** Regular Datepicker ***
  return (
    <Wrapper className={className} ref={refContainer}>
      {(!unsettable || (unsettable && renderValue)) && (
        <DatePicker
          value={renderValue}
          picker={picker}
          showToday={showToday}
          placeholder={placeholder}
          name={name}
          format={formatDate}
          open={openState}
          onOpenChange={open => setDatepickerOpen(open)}
          onChange={value => handleChanged(value)}
          style={style}
          allowClear={allowClear}
          disabled={disabled}
          disabledDate={minDate || maxDate ? disabledDate : undefined}
          getPopupContainer={() => refContainer.current as any}
          renderExtraFooter={
            renderExtraFooter
              ? () => {
                  return renderExtraFooter({ setDatepickerOpen })
                }
              : undefined
          }
        />
      )}
      {unsettable && !renderValue && (
        <Pending className="unset">
          {i18n.t('common.form_values.unset')}
          <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={() => handleEdit()} />
        </Pending>
      )}
    </Wrapper>
  )
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default React.memo(Datepicker)
