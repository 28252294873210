/* eslint-disable react-hooks/exhaustive-deps */
import { Input as AntdInput, InputProps } from 'antd'
import classnames from 'classnames'
import { yen, yenStringToNumber } from 'common/helpers'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import { Controller, useForm, useFormContext, ValidationRule } from 'react-hook-form'

import { CommonErrorMsg } from '../../../antd'
import { Wrapper } from './styles'

// eslint-disable-next-line react/function-component-definition
const Input: React.FC<InputProps & Props> = ({
  name = '',
  isShowError = true,
  required,
  className,
  underline,
  allowPrefix,
  handlePressEnter,
  onlyNumber,
  controll,
  defaultValue,
  error,
  afterBlur,
  regexPatern,
  ...rest
}) => {
  // eslint-disable-next-line
  const propsUseFormProps = useForm()
  const propsUseFormContext = useFormContext()
  const { errors, getValues, setValue, control, register } = controll ? propsUseFormProps : propsUseFormContext
  const controller = controll || control // if have control -> useForm else useFormContext
  const errorMsg = !error ? get(errors, `${name}.message`) : error
  const classNames = classnames(className, { underline, error: errorMsg })
  const valueField = getValues(name)

  useEffect(() => {
    if (allowPrefix && !controll) {
      setValue(name, yenStringToNumber(valueField))
    }
  }, [valueField])

  const formatter = (value: string) => {
    if (allowPrefix) return yen(value)
    if (onlyNumber) {
      const regex = /\D/g
      return value.replace(regex, '')
    }
    return value
  }

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={controller}
      rules={{ required }}
      render={({ onChange: controllerOnChange, value, onBlur }) => {
        return (
          <Wrapper>
            <AntdInput
              value={formatter(value)}
              name={name}
              className={classNames}
              onChange={controllerOnChange}
              onBlur={({ target: { value } }) => {
                onBlur()
                afterBlur && afterBlur(value)
              }}
              onPressEnter={handlePressEnter}
              {...register({
                pattern: regexPatern || undefined,
              })}
              {...rest}
            />
            {errorMsg && isShowError && <CommonErrorMsg>{errorMsg}</CommonErrorMsg>}
          </Wrapper>
        )
      }}
    />
  )
}

type Props = InputProps & {
  isShowError?: boolean
  afterBlur?: (value: string) => void
  className?: string
  required?: boolean
  handlePressEnter?: () => void
  underline?: boolean
  allowPrefix?: boolean
  onlyNumber?: boolean
  controll?: any
  defaultValue?: any
  error?: any
  regexPatern?: ValidationRule<RegExp>
}

export default Input
