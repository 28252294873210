import { LoadingOutlined } from '@ant-design/icons'
import React, { PropsWithChildren } from 'react'

import { StyledSpin } from './styles'

export const Spin = ({ children, loading = false, size = 'large', indicatorSize, text }: PropsWithChildren<Props>) => {
  return (
    <StyledSpin spinning={loading} tip={text} indicator={spinIcon(indicatorSize)} size={size}>
      {children}
    </StyledSpin>
  )
}

const spinIcon = (indicatorSize?: number) => <LoadingOutlined style={{ fontSize: indicatorSize }} />

type Props = {
  text?: string
  loading?: boolean
  indicatorSize?: number
  size?: 'small' | 'large' | 'default'
}
