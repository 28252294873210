import { BasicModal } from 'aa_common/front-end/components'
import { useModalState } from 'aa_common/front-end/hooks/useModalState'
import { loadNs } from 'common/i18n-config'
import BasicForm, { RenderFormProps } from 'components/molecules/Form'
import React, { useEffect, useRef } from 'react'

import Form, { ExciseFormProp } from './Form'
import { Wrapper } from './styles'

const t = loadNs(['common'])

export type { ExciseFormProp } from './Form'

const ExciseSettingFormModal = React.forwardRef<any>(function ExciseSettingFormModal(_, ref) {
  const { title, isShow, isLoading, initialValues, errors, handleOnClose, customProps } =
    useModalState<ExciseFormProp>(ref)

  const formRef = useRef<RenderFormProps>(null)
  useEffect(() => {
    if (isShow) {
      // delay a bit to let React have time to set ref to `formRef.current`
      Promise.resolve().then(() => {
        if (initialValues && formRef.current) {
          formRef.current.reset(initialValues)
        }
      })
    }
  }, [isShow, initialValues])

  return (
    <Wrapper>
      <BasicModal title={title || t('actions.edit')} isShow={isShow} onCancel={() => handleOnClose()} width={760}>
        <BasicForm
          shouldUnregister
          ref={formRef}
          value={initialValues}
          errors={errors}
          loading={isLoading}
          onFormSubmit={handleOnClose}
        >
          <Form {...customProps} />
        </BasicForm>
      </BasicModal>
    </Wrapper>
  )
})

export default ExciseSettingFormModal
