import React from 'react'

import { Image } from './styles'

type Props = {
  alt?: string
  style?: React.CSSProperties
}

const NoImage: React.FC<Props> = ({ alt = '', style }) => {
  return <Image style={style} alt={alt} src={require('assets/images/image-placeholder.jpg').default} />
}

export default NoImage
