import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { LinkLabel } from '.'
import { RedirectLinkProps } from './model'

const RedirectLink: React.FC<PropsWithChildren<RedirectLinkProps>> = ({ redirectTo, children, ...componentRest }) => {
  const component: React.ComponentType<any> | undefined = routerProps => {
    const coreProps = {
      onClick: () => routerProps.navigate(),
      ...componentRest,
    }

    return <LinkLabel {...coreProps}>{children}</LinkLabel>
  }
  return <Link to={redirectTo} component={component} />
}
// Redirect Link is only used for redirect routes inside React Router Dom but using core link label styles.

export default RedirectLink
