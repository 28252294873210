import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 40px;

  border-bottom: 1px solid #d4d8dd;
  background-color: #ffffff;
`
