import { CSV_CHARSET } from 'common/constants'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { IMPORT_EXPORT_EVENT_TYPES } from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { useMemo } from 'react'

const TemplateImportFiles = new Map([
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER}`, 'fixed_assets_onboarding_sjis.csv'],
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.START_USAGE}`, 'fixed_assets_startusage_sjis.csv'],
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT}`, 'fixed_assets_impairment_sjis.csv'],
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION}`, 'fixed_assets_assettype_sjis.csv'],
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.RETIREMENT}`, 'fixed_assets_retirement_sjis.csv'],
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.SALE}`, 'fixed_assets_sale_sjis.csv'],
  [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.MOVEMENT}`, 'fixed_assets_movement_sjis.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER}`, 'fixed_assets_onboarding_utf8.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.START_USAGE}`, 'fixed_assets_startusage_utf8.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT}`, 'fixed_assets_impairment_utf8.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION}`, 'fixed_assets_assettype_utf8.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.RETIREMENT}`, 'fixed_assets_retirement_utf8.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.SALE}`, 'fixed_assets_sale_utf8.csv'],
  [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.MOVEMENT}`, 'fixed_assets_movement_utf8.csv'],
])

export const useDownloadFile = (downloadTemplateType: number | null, templateCharset: string | null) => {
  const { isEnable: isEnableFAReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)

  const downloadFile = TemplateImportFiles.get(`${templateCharset}-${downloadTemplateType}`)
  const baseLink = useMemo(() => {
    if (downloadTemplateType === IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER) {
      if (isEnableFAReduction) {
        return '/files/import_samples/fixed_asset_reduction_change_d_method/'
      }
    }
    return '/files/import_samples/'
  }, [downloadTemplateType, isEnableFAReduction])

  return { downloadFile, baseLink }
}
