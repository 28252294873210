import styled from '@emotion/styled'

export const Wrapper = styled.div`
  background: #fff;
  padding: 10px;
  width: 470px;
  height: 40px;

  border: 1px solid #d4d8dd;
  box-sizing: border-box;
  border-radius: 4px;
`

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
`
