/* eslint-disable react/display-name */
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BaseRowMouseEvent } from 'aa_common/front-end/shared/types'
import { List } from 'immutable'
import { ExpandedRowRender, RenderExpandIcon } from 'rc-table/lib/interface'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import MonthSimulationDetailUsage from '../MonthSimulationDetailUsage'
import { convertDepreciationResults } from './helper'
import { IDepreciationTable, IMonthSimulationDetail } from './model'
import { amortizationTableSettings } from './settings'
import { ExpandClass, Table } from './styles'

const MonthSimulationDetail = ({
  data,
  isAccountLedger,
  isShowComparison,
  showComparisonTable,
  isSourceAsset,
}: IMonthSimulationDetail) => {
  return (
    <MonthSimulationDetailUsage
      isSourceAsset={isSourceAsset}
      data={data?.months}
      fiscalYear={data?.fiscal_year}
      isAccountLedger={isAccountLedger}
      isShowComparison={isShowComparison}
      showComparisonTable={showComparisonTable}
    />
  )
}

const DepreciationTable = ({
  depreciationResults,
  acquiredAt,
  usageStartedAt,
  retiredAt,
  soldAt,
  currentMonthlyClosing,
  impairAt,
  isAccountLedger,
  isShowComparison,
  showComparisonTable,
  isDeclineBalanceMethods,
  dividedAts,
  isSourceAsset,
}: IDepreciationTable) => {
  const [expandedRowKeys, setExpandRowKeys] = useState<any[]>([])

  useEffect(() => {
    setExpandRowKeys([])
  }, [depreciationResults])

  const handleOnRowClick = useCallback(
    (event: BaseRowMouseEvent) => {
      const targetFindIndex = expandedRowKeys.findIndex(item => item === event.record.key)
      const isFoundInArray = targetFindIndex >= 0
      let updated = null
      if (isFoundInArray) {
        // collapse target item;
        updated = List(expandedRowKeys).remove(targetFindIndex).toArray()
      } else {
        // open target item;
        updated = List(expandedRowKeys).push(event.record.key).toArray()
      }
      setExpandRowKeys(updated)
    },
    [expandedRowKeys]
  )

  const columns = useMemo(() => {
    return amortizationTableSettings({
      currentMonthlyClosing,
      usageStartedAt,
      acquiredAt,
    })
  }, [currentMonthlyClosing, acquiredAt, usageStartedAt])

  const memoizedDepreciationResults = useMemo(
    () =>
      convertDepreciationResults({
        depreciationResults,
        currentMonthlyClosing,
        retiredAt,
        soldAt,
        usageStartedAt,
        acquiredAt,
        impairAt,
        isShowComparison,
        isDeclineBalanceMethods,
        dividedAts,
      }),
    [
      depreciationResults,
      currentMonthlyClosing,
      retiredAt,
      soldAt,
      usageStartedAt,
      acquiredAt,
      impairAt,
      isShowComparison,
      isDeclineBalanceMethods,
      dividedAts,
    ]
  )

  const renderExpandIcon: RenderExpandIcon<any> = useCallback(
    ({ expanded }) => <FontAwesomeIcon className={ExpandClass} icon={expanded ? faCaretDown : faCaretRight} />,
    []
  )

  const renderExpandRow: ExpandedRowRender<any> = useCallback(
    (record: any) => {
      return (
        <MonthSimulationDetail
          key={`simulation-month-${record.id}`}
          data={record}
          isSourceAsset={isSourceAsset}
          isAccountLedger={isAccountLedger}
          isShowComparison={isShowComparison}
          showComparisonTable={showComparisonTable}
        />
      )
    },
    [isSourceAsset, isAccountLedger, isShowComparison, showComparisonTable]
  )

  return (
    <Table
      enableSelectRows={false}
      tableLayout="fixed"
      columns={columns}
      data={memoizedDepreciationResults}
      actions={false}
      expandableSettings={{
        expandIconColumnIndex: 6,
        expandedRowKeys,
        expandedRowClassName: () => 'month-simulation-detail',
        expandIcon: renderExpandIcon,
        expandedRowRender: renderExpandRow,
      }}
      onRowClick={handleOnRowClick}
    />
  )
}

export default React.memo(DepreciationTable)
