import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button } from 'antd'
import i18n from 'i18n'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { BreadcrumbNavigationProps } from './model'
import { BackButtonClass, BackWrapper, BreadcrumbWrapper, DefaultBreadcrumbClass } from './styles'

// eslint-disable-next-line react/display-name
const BreadcrumbNavigation = ({ enableBackButton, customBackElement, sectionName }: BreadcrumbNavigationProps) => {
  const history = useHistory()
  const isKeyLocation = !!history?.location?.key

  const handleClickBack = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    history.goBack()
  }

  return (
    <BreadcrumbWrapper>
      {enableBackButton && isKeyLocation && (
        <BackWrapper>
          <Button type="link" className={BackButtonClass} onClick={handleClickBack}>
            <FontAwesomeIcon icon={faChevronLeft} /> {i18n.t('common.actions.back')}
          </Button>
        </BackWrapper>
      )}
      {customBackElement && <BackWrapper>{customBackElement}</BackWrapper>}
      {sectionName && (
        <Breadcrumb className={DefaultBreadcrumbClass}>
          <Breadcrumb.Item>{sectionName}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      {
        // TODO: Dynamic Generate if required
      }
    </BreadcrumbWrapper>
  )
}

export default BreadcrumbNavigation
