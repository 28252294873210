import styled from '@emotion/styled'
import { Button as AntdButton } from 'antd'

export const Button = styled(AntdButton)`
  padding: 0;
  text-align: left;
  height: unset;
  font-size: 1em;

  &.ant-btn {
    line-height: unset;
  }
`
