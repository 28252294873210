import { Map } from 'immutable'

import { AssetCategoryActionTypes } from './action.model'
import {
  CLEANUP_ASSET_CATEGORY,
  CREATE_ASSET_CATEGORY_REQUEST,
  DELETE_ASSET_CATEGORY_FAILURE,
  DELETE_ASSET_CATEGORY_REQUEST,
  DELETE_ASSET_CATEGORY_SUCCESS,
  EXPORT_ASSET_CATEGORY_FAILURE,
  EXPORT_ASSET_CATEGORY_REQUEST,
  EXPORT_ASSET_CATEGORY_SUCCESS,
  FETCH_ASSET_CATEGORIES_FAILURE,
  FETCH_ASSET_CATEGORIES_REQUEST,
  FETCH_ASSET_CATEGORIES_REQUEST_AUTH,
  FETCH_ASSET_CATEGORIES_SUCCESS,
  FETCH_ASSET_CATEGORY_REQUEST,
  FETCH_ASSET_CATEGORY_SUCCESS,
  IMPORT_ASSET_CATEGORY_FAILURE,
  IMPORT_ASSET_CATEGORY_REQUEST,
  IMPORT_ASSET_CATEGORY_SUCCESS,
  SUBMIT_ASSET_CATEGORY_FAILURE,
  SUBMIT_ASSET_CATEGORY_SUCCESS,
  UPDATE_ASSET_CATEGORY_REQUEST,
} from './constant'

export const initialState = Map({
  list: Map({
    data: [],
    loading: false,
    filters: null,
    sort: null,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  import: Map({
    loading: false,
    error: {
      message: '',
      defails: [],
    },
  }),
  export: {
    loading: false,
  },
})

export default (state = initialState, action: AssetCategoryActionTypes) => {
  switch (action.type) {
    case FETCH_ASSET_CATEGORIES_REQUEST:
    case FETCH_ASSET_CATEGORIES_REQUEST_AUTH:
      return state
        .setIn(['list', 'loading'], true)
        .setIn(['list', 'filters'], action.payload.filters)
        .setIn(['list', 'sort'], action.payload.sort)
    case FETCH_ASSET_CATEGORIES_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_ASSET_CATEGORIES_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], [])
    case FETCH_ASSET_CATEGORY_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_ASSET_CATEGORY_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case CREATE_ASSET_CATEGORY_REQUEST:
    case UPDATE_ASSET_CATEGORY_REQUEST:
      return state.setIn(['item', 'loading'], true).setIn(['item', 'errors'], null)
    case SUBMIT_ASSET_CATEGORY_SUCCESS:
      return state.setIn(['item', 'loading'], false)
    case SUBMIT_ASSET_CATEGORY_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.formErrors)
    case DELETE_ASSET_CATEGORY_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case DELETE_ASSET_CATEGORY_SUCCESS:
    case DELETE_ASSET_CATEGORY_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case IMPORT_ASSET_CATEGORY_REQUEST:
      return state.setIn(['import', 'loading'], true)
    case IMPORT_ASSET_CATEGORY_SUCCESS:
      return state
        .setIn(['import', 'loading'], false)
        .setIn(['import', 'error'], initialState.getIn(['import', 'error']))
    case IMPORT_ASSET_CATEGORY_FAILURE:
      return state.setIn(['import', 'loading'], false).setIn(['import', 'error'], action.payload)
    case EXPORT_ASSET_CATEGORY_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_ASSET_CATEGORY_SUCCESS:
    case EXPORT_ASSET_CATEGORY_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CLEANUP_ASSET_CATEGORY:
      return initialState
    default:
      return state
  }
}
