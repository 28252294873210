import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-picker {
    width: 100%;

    &.error {
      border-color: #dc3545;
      background-color: #ffeeeb;
    }
  }
`
