import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

type ModalRefParams = {
  title: React.ReactNode
  initialValues: any
  onValuesCb: (values: any) => any
}

export interface ModalRef<T> {
  open(params: ModalRefParams, customProps: T): void
}

export function useModalState<T>(ref: React.ForwardedRef<ModalRef<T>>) {
  const innerRef = useRef<any>({})
  const [title, setTitle] = useState<React.ReactNode>('')
  const [isShow, setIsShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const [errors, setErrors] = useState<any>(null)
  const [customProps, setCustomProps] = useState<T>({} as T)

  useImperativeHandle(ref, () => ({
    open(params, props) {
      setCustomProps(props)

      const { onValuesCb } = params || {}
      setTitle(params?.title || '')
      setInitialValues(params?.initialValues)
      setErrors(null)
      setIsLoading(false)
      setIsShow(true)
      innerRef.current = { onValuesCb }
    },
  }))

  const handleOnClose = useCallback(
    async (formValues?: any) => {
      // handling onClose, reject processing
      if (isLoading) return

      // No values are provided, close the modal
      if (!formValues) return setIsShow(false)

      setIsLoading(true)
      try {
        const { onValuesCb } = innerRef.current
        // invoke & wait for onValuesCb
        await (onValuesCb && onValuesCb(formValues))

        // close the modal & reset the states on success
        setIsShow(false)
        setTitle('')
        setInitialValues(null)
        setErrors(null)
      } catch (error) {
        // otherwise keep the modal open & show errors
        setErrors(error)
      }
      setIsLoading(false)
    },
    [isLoading]
  )

  return useMemo(() => {
    return {
      title,
      isShow,
      isLoading,
      initialValues,
      errors,
      customProps,
      handleOnClose,
    }
  }, [title, isShow, isLoading, initialValues, errors, customProps, handleOnClose])
}
