import React from 'react'

import { Cell, Row } from './styles'

type Props = {
  columns: any[]
  content: any
}

const EmptyRow: React.FC<Props> = ({ columns, content }) => {
  return (
    <Row className="last">
      <Cell className="first last" colSpan={columns.length + 1} style={{ textAlign: 'left' }}>
        {content}
      </Cell>
    </Row>
  )
}

export default EmptyRow
