import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { MonthlyClosing } from 'common/models/monthly-closing'
import moment from 'moment'

export const isImmutableEvent = (record: any, currentMonthlyClosing: MonthlyClosing) => {
  if (currentMonthlyClosing?.is_first_monthly_closing) return false

  const processingMonthStartDate = moment(currentMonthlyClosing?.from_term_month.start_date)
  const eventCode = record?.change_situation?.code
  const compareDate =
    eventCode === ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED
      ? moment(record?.recognized_at)
      : moment(record?.valid_from)

  return compareDate.isBefore(processingMonthStartDate, 'date')
}
