import { Button } from 'aa_common/front-end/antd'
import { RectangleForSession } from 'components/molecules'
import { SessionTemplate } from 'components/templates'
import parser from 'html-react-parser'
import i18n from 'i18n'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { submitTermOfUseRequest } from 'store/session/actions'
import { getCurrentUserSelector } from 'store/session/selectors'

const TermOfUsePage = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUserSelector)

  const handleOnClick = () => {
    dispatch(submitTermOfUseRequest())
  }

  useEffect(() => {
    if (currentUser && currentUser.is_confirmed) {
      dispatch(changeLocation('/'))
    }
  }, [dispatch, currentUser])

  return (
    <SessionTemplate>
      {currentUser && !currentUser.is_confirmed && (
        <RectangleForSession>
          <div style={{ marginTop: '30px', fontWeight: 700, fontSize: '16px', lineHeight: '16px' }}>
            {i18n.t('pages.user.TermOfUse.title')}
          </div>
          <div style={{ marginTop: '20px', fontSize: '13px', lineHeight: '13px' }}>
            {parser(i18n.t('pages.user.TermOfUse.explanation'))}
          </div>
          <Button
            size="custom"
            style={{ marginTop: '20px', marginBottom: '30px', width: '271px', height: '42px' }}
            onClick={handleOnClick}
          >
            {i18n.t('pages.user.TermOfUse.agree')}
          </Button>
        </RectangleForSession>
      )}
    </SessionTemplate>
  )
}

export default TermOfUsePage
