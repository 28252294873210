import React from 'react'

import LedgerSettingsEditTable from './edit'
import { ILedgerSettingsTable } from './model'
import { Wrapper } from './styles'
import LedgerSettingsViewTable from './view'

const LedgerSettingsTable: React.FC<ILedgerSettingsTable> = ({
  data,
  loading,
  readonly = false,
  onSubmitForm,
  onEdit,
}) => (
  <Wrapper className="ledger-settings-wrapper">
    {readonly ? (
      <LedgerSettingsViewTable loading={loading} data={data} onEdit={onEdit} />
    ) : (
      <LedgerSettingsEditTable loading={loading} data={data} onSubmitForm={onSubmitForm} onEdit={onEdit} />
    )}
  </Wrapper>
)

export default React.memo(LedgerSettingsTable)
