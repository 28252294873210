import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel, Tag } from 'antd'
import { NoDataImage } from 'components/atoms'
import { findIndex } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { CarouselImageItem, ImageCarouselProps } from './model'
import { CarouselButton, CarouselImage, CarouselImageDescription, CarouselImageSlide, CarouselWrapper } from './styles'

const getCurrentPosition = (photos: CarouselImageItem[], target: CarouselImageItem) => {
  return findIndex(photos, item => item === target) + 1
}

const ImageCarousel = ({
  photos,
  previewImage = true,
  emptyMessage = 'No Images',
  width = 180,
}: ImageCarouselProps) => {
  const carouselRef = useRef<any>(null)
  const [selectedItem, setSelectedItem] = useState<CarouselImageItem>()

  useEffect(() => {
    photos && setSelectedItem(photos[0])
  }, [photos])

  const handlePrevClick = useCallback(() => {
    carouselRef.current.prev()
  }, [carouselRef])

  const handleNextClick = useCallback(() => {
    carouselRef.current.next()
  }, [carouselRef])

  const handleOnChange = useCallback(
    event => {
      setSelectedItem(photos[event])
    },
    [setSelectedItem, photos]
  )

  const renderImageSlides = (photos: CarouselImageItem[]) => {
    return photos.map(item => (
      <CarouselImageSlide key={item.url}>
        <CarouselImage preview={previewImage} src={item.url ? item.url : ''} />
      </CarouselImageSlide>
    ))
  }

  const renderCarousel = () =>
    photos &&
    selectedItem && (
      <>
        <Tag
          color="white"
          style={{
            background: 'none',
            position: 'absolute',
            left: 5,
            top: 5,
            color: '#FFFFFF',
            fontSize: 13,
            lineHeight: '150%',
            zIndex: 2,
          }}
        >
          {`${getCurrentPosition(photos, selectedItem)}/${photos && photos.length}`}
        </Tag>
        {photos.length > 1 && (
          <CarouselButton className="prev" onClick={handlePrevClick}>
            <FontAwesomeIcon style={{ marginRight: 2 }} icon={faChevronLeft} />
          </CarouselButton>
        )}
        <Carousel ref={carouselRef} dots={false} afterChange={handleOnChange}>
          {renderImageSlides(photos)}
        </Carousel>
        {photos.length > 1 && (
          <CarouselButton className="next" onClick={handleNextClick}>
            <FontAwesomeIcon style={{ marginLeft: 2 }} icon={faChevronRight} />
          </CarouselButton>
        )}
        <CarouselImageDescription
          style={{
            visibility: selectedItem && selectedItem.description ? 'visible' : 'hidden',
            opacity: selectedItem && selectedItem.description ? 1 : 0,
          }}
        >
          <div className="img-carousel-desc-content">{selectedItem.description}</div>
        </CarouselImageDescription>
      </>
    )

  return (
    <CarouselWrapper style={{ width, textAlign: 'center' }}>
      {photos && photos.length > 0 ? renderCarousel() : <NoDataImage text={emptyMessage} imageHeight={140} />}
    </CarouselWrapper>
  )
}

export default ImageCarousel
