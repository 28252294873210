import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import DepartmentForm from 'components/organisms/settings/DepartmentForm'
import { MainTemplate } from 'components/templates'
import withRouterTrap from 'components/withRouterTrap'
import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectMasterData } from 'store/masterData/selectors'
import {
  cleanupDepartment,
  createDepartment,
  fetchDepartment,
  partialCleanupDepartment,
} from 'store/settings/department/actions'
import { selectDepartmentItem } from 'store/settings/department/selectors'

const t = loadNs(['pages/settings/departments'])

const DepartmentCopyPage = withRouterTrap(() => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('copy')} />
  const { id } = useParams<any>()

  const masterData = useSelector(selectMasterData, isEqual)
  const { departmentData: department, errors, loading } = useSelector(selectDepartmentItem, isEqual)
  const dispatch = useDispatch()

  const handleSubmit = (values: any) => {
    dispatch(createDepartment(values))
  }

  useEffect(() => {
    dispatch(fetchDepartment(id))
    return () => {
      dispatch(partialCleanupDepartment())
    }
  }, [dispatch, id])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <DepartmentForm
        values={department}
        errors={errors}
        onSubmit={handleSubmit}
        prepressCategories={masterData.prepress_category}
        type={FORM_MODES.COPY}
        loading={loading}
      />
    </MainTemplate>
  )
})

const DepartmentCopyPageWrapper = () => {
  const dispatch = useDispatch()
  return <DepartmentCopyPage path="/master/departments" onLeave={() => dispatch(cleanupDepartment())} />
}

export default DepartmentCopyPageWrapper
