import { GoFeatureFlagWebProvider } from '@openfeature/go-feature-flag-web-provider'
import { Client, EvaluationContext, EventDetails, OpenFeature, ProviderEvents } from '@openfeature/web-sdk'
import { checkAllFeatures, isOpenFeatureDevMode, openFeatureSlice } from 'common/open-features/store'
import { useEffect } from 'react'
import { sessionsSlice } from 'share-store/sessions.store'

export * from './store'
export const useOpenFeature = () => {
  const officeId = sessionsSlice.useSlice(s => s.currentOffice?.id)
  const remoteFlags = openFeatureSlice.useSlice()
  useEffect(() => {
    if (!officeId) return
    if (isOpenFeatureDevMode()) {
      checkAllFeatures()
    } else {
      connect(officeId)
    }
  }, [officeId])

  return [remoteFlags]
}

const endpoint = `${window.location.origin}/feature-flag`

const goFeatureFlagWebProvider = new GoFeatureFlagWebProvider(
  {
    endpoint,
    apiTimeout: 1000,
    retryInitialDelay: 1000,
    retryDelayMultiplier: 0,
    maxRetries: 0,
  },
  console
)

let client: Client | undefined

const relayContext: EvaluationContext = {
  targetingKey: 'user-key',
}

const onFeatureChanged = (_ctx: EventDetails<any>) => {
  checkAllFeatures(client)
}

export const connect = async (officeId: string | number) => {
  if (officeId && relayContext.office_id !== String(officeId)) {
    if (client) {
      client.removeHandler(ProviderEvents.Ready, onFeatureChanged)
      client.removeHandler(ProviderEvents.ConfigurationChanged, onFeatureChanged)
    }

    relayContext.office_id = `${officeId}`
    await OpenFeature.setContext(relayContext)
    OpenFeature.setProvider(goFeatureFlagWebProvider)

    client = OpenFeature.getClient()
    client.addHandler(ProviderEvents.Ready, onFeatureChanged)
    client.addHandler(ProviderEvents.ConfigurationChanged, onFeatureChanged)
  }
}
