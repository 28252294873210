import { isEmptyValue } from 'aa_common/front-end/helpers'
import { useEffect, useRef } from 'react'

export function useReferValue(
  fieldName: string,
  setFieldValue: (field: string, value: any) => void,
  value: any,
  referValue: any,
  canUpdate: boolean,
  forceResetToRefer?: boolean
) {
  const previousReferValue = useRef<number | null | undefined>(referValue)

  useEffect(() => {
    const currentPreviousReferValue = previousReferValue.current
    previousReferValue.current = referValue

    if (!canUpdate || isEmptyValue(referValue)) return

    if (currentPreviousReferValue !== referValue || isEmptyValue(value) || forceResetToRefer) {
      setFieldValue(fieldName, referValue)
    }
  }, [value, referValue, canUpdate, forceResetToRefer])
}
