import React, { CSSProperties } from 'react'

import { Wrapper } from './styles'

type Props = {
  style?: CSSProperties
}

const HorizontalLine: React.FC<Props> = ({ style }) => {
  return <Wrapper style={style} />
}

export default HorizontalLine
