import { AssetEventSearchParams, MonthlyClosingSetting } from 'common/models'

import {
  FetchMonthlyClosingPreviousAssetEventsRequest,
  FetchMonthlyClosingReselectRequest,
  SubmitMonthlyClosingReselectRequest,
} from './action.model'
import {
  CLEANUP_MONTHLY_CLOSING_ASSET_EVENTS,
  CLEANUP_MONTHLY_CLOSING_NEXT_SETTING,
  FETCH_MONTHLY_CLOSING_ASSET_EVENT_REQUEST,
  FETCH_MONTHLY_CLOSING_CLOSED_REQUEST,
  FETCH_MONTHLY_CLOSING_CURRENT_REQUEST,
  FETCH_MONTHLY_CLOSING_NEXT_SETTING_REQUEST,
  FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_REQUEST,
  FETCH_MONTHLY_CLOSING_RESELECT_REQUEST,
  FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_REQUEST,
  REOPEN_MONTHLY_CLOSING_REQUEST,
  SUBMIT_CLOSE_MONTHLY_CLOSING_REQUEST,
  SUBMIT_MONTHLY_CLOSING_RESELECT_REQUEST,
  UPDATE_MONTHLY_CLOSING_SETTING_REQUEST,
} from './constant'

export const fetchMonthlyClosingCurrent = () => ({
  type: FETCH_MONTHLY_CLOSING_CURRENT_REQUEST,
})

export const fetchMonthlyClosingSummaryAssetEvents = (monthlyClosingId: number) => ({
  type: FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_REQUEST,
  payload: monthlyClosingId,
})

export const fetchMonthlyClosingClosed = (settingId: number) => ({
  type: FETCH_MONTHLY_CLOSING_CLOSED_REQUEST,
  payload: settingId,
})

export const fetchMonthlyClosingNextSeting = () => ({
  type: FETCH_MONTHLY_CLOSING_NEXT_SETTING_REQUEST,
})

export const updateMonthlyClosingSeting = (setting: MonthlyClosingSetting, data: any) =>
  ({
    type: UPDATE_MONTHLY_CLOSING_SETTING_REQUEST,
    payload: {
      setting,
      data,
    },
  } as const)
export type UpdateMonthlyClosingSettingRequest = ReturnType<typeof updateMonthlyClosingSeting>

export const fetchMonthlyClosingAssetEvents = (params: AssetEventSearchParams) =>
  ({
    type: FETCH_MONTHLY_CLOSING_ASSET_EVENT_REQUEST,
    payload: params,
  } as const)
export type FetchMonthlyClosingAssetEventsRequest = ReturnType<typeof fetchMonthlyClosingAssetEvents>

export const cleanupMonthlyClosingNextSeting = () => ({
  type: CLEANUP_MONTHLY_CLOSING_NEXT_SETTING,
})

export const fetchMonthlyClosingPreviousAssetEvents = (
  assetIds: number[]
): FetchMonthlyClosingPreviousAssetEventsRequest => ({
  type: FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_REQUEST,
  payload: assetIds,
})

export const cleanupMonthlyClosingAssetEvents = () => ({
  type: CLEANUP_MONTHLY_CLOSING_ASSET_EVENTS,
})

export const submitCloseMonthlyClosing = () => ({
  type: SUBMIT_CLOSE_MONTHLY_CLOSING_REQUEST,
})

export const fetchMonthlyClosingReselect = (): FetchMonthlyClosingReselectRequest => ({
  type: FETCH_MONTHLY_CLOSING_RESELECT_REQUEST,
})

export const submitMonthlyClosingReselect = (term_month_id: number): SubmitMonthlyClosingReselectRequest => ({
  type: SUBMIT_MONTHLY_CLOSING_RESELECT_REQUEST,
  payload: term_month_id,
})

export const reopenMonthlyClosing = (dryRun?: boolean) =>
  ({
    type: REOPEN_MONTHLY_CLOSING_REQUEST,
    payload: { dryRun },
    meta: { thunk: true },
  } as const)

export type ReopenMonthlyClosingRequest = ReturnType<typeof reopenMonthlyClosing>
