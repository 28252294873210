import { parseDataForForm } from 'common/helpers'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetItemData } from 'store/asset/selectors'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'

export const useInitialData = (setInitialData: Function) => {
  const assetData = useSelector(selectAssetItemData, isEqual)
  const terms = useSelector(selectTermsData, isEqual)
  const FIRST_TERMS_INDEX = 0

  useEffect(() => {
    if (!assetData || !terms) return

    setInitialData({
      ...parseDataForForm(assetData),
      code: '',
      branch_code: '',
      min_acquired_at: terms[FIRST_TERMS_INDEX]?.start_date,
    })
  }, [assetData, terms])
}
