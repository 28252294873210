import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { DatePicker } from 'antd'
import { DATE_FORMAT } from 'common/constants'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'

import { Wrapper } from './styles'

const { RangePicker } = DatePicker

type Props = {
  fromField: string
  toField: string
  fromPlaceholder?: string
  toPlaceholder?: string
  dateFormat?: string
}

const RangeDatePicker = ({
  fromField,
  toField,
  fromPlaceholder = '',
  toPlaceholder = '',
  dateFormat = DATE_FORMAT,
}: Props) => {
  const { errors, values, setFieldValue } = useFormikContext()

  let isInValid = null

  if (get(errors, fromField) || get(errors, toField)) {
    isInValid = true
  }

  const handleChanged = (newValues: any): void => {
    if (newValues === null) {
      setFieldValue(fromField, null)
      setFieldValue(toField, null)
    } else {
      setFieldValue(fromField, newValues[0].startOf('day').format())
      setFieldValue(toField, newValues[1].endOf('day').format())
    }
  }

  const defaultValue = useMemo((): any => {
    let fromValue = getIn(values, fromField)
    let toValue = getIn(values, toField)

    if (fromValue) {
      fromValue = moment(fromValue)
    }

    if (toValue) {
      toValue = moment(toValue)
    }

    return [fromValue, toValue]
  }, [fromField, toField]) // eslint-disable-line

  return (
    <Wrapper>
      <RangePicker
        format={dateFormat}
        placeholder={[fromPlaceholder, toPlaceholder]}
        onChange={handleChanged}
        defaultValue={defaultValue}
        className={isInValid ? 'error' : undefined}
      />
      {isInValid && <CommonErrorMsg>{get(errors, fromField) || get(errors, toField)}</CommonErrorMsg>}
    </Wrapper>
  )
}

export default RangeDatePicker
