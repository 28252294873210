import { ifElse } from 'aa_common/front-end'
import { updateObjectValues } from 'common/helpers'
import { get } from 'lodash'
import moment from 'moment/moment'
import { DEPARTMENT_DETAIL_MODE, DepartmentItem, IDepartmentDataItem } from 'store/settings/department/model'

export const validateForm = (values: DepartmentItem) => {
  const { name, prepress_category_code } = values

  return !!(name?.trim() && prepress_category_code)
}
export const getFormData = (
  initialValues: any,
  value: any,
  beforeValue: any,
  afterValue: any,
  activeDepartmentId: any,
  currentTime: any
) => {
  const objectValues = updateObjectValues(initialValues, value)
  const isActiveItem = value.id === activeDepartmentId
  return {
    formData: {
      id: value.id,
      key: currentTime + value.id,
      status: ifElse(isActiveItem, DEPARTMENT_DETAIL_MODE.EDIT, DEPARTMENT_DETAIL_MODE.VIEW),
      data: {
        ...objectValues,
        prepress_category_code: get(value.prepress_category, 'code'),
        prepress_category: value.prepress_category,
        valid_from_min: beforeValue ? moment(beforeValue.valid_from) : null,
        valid_to_max: afterValue ? moment(afterValue.valid_to) : null,
        id_before: get(afterValue, 'id'),
        id_after: get(beforeValue, 'id'),
      },
    } as IDepartmentDataItem,
    isActiveItem,
  }
}
