export enum AREA_DETAIL_MODE {
  VIEW = 'view',
  EDIT = 'edit',
}
export interface IAreaItem {
  code: string
  name: string
  submit_destination: string
  search_key: string
}

interface IAreaFilter {
  code?: string
  biid?: string[]
  name?: string
  submit_destination?: string
  valid_at?: string
  search_key?: string
  show_past_areas?: boolean
  show_future_areas?: boolean
}

interface IAreaPostSearchFilter {
  biid?: string[]
  code?: string
  name?: string
  submit_destination_id?: string | number
  search_key?: string
  valid_at?: string
}

export interface IAreaDetail {
  data: AreaDataItem
  id: number
  key?: number
  status: AREA_DETAIL_MODE.VIEW | AREA_DETAIL_MODE.EDIT
  isAddFirst?: boolean
}

export interface AreaSearchPayload {
  filters?: IAreaFilter
  sort?: string[]
  pageNumber?: number
  pageSize?: number
}

export interface AreaPostSearchPayload {
  filters?: IAreaPostSearchFilter
  sort?: string[]
}

export interface DeleteAreaPayload {
  biid: string
  original_name: string
}

export interface AreaListType {
  biid: string
  code: string
  created_at: string
  id: number | null
  name: string
  submit_destination: {
    id: number
    code: string
    name: string
    search_key: string
  }
  valid_from: string | null
  valid_to: string | null
}

export interface AreaListSelector {
  areaList: AreaListType[]
  isLoading: boolean
  totalPages: number
  totalRecords: number
  pageNumber: number
  isResetFilter: boolean
  areaFilterConditions: any
  areaSortConditions: string[]
}

export interface UserInfo {
  id: number
  display_name: string
  email: string
  family_name: string
  given_name: string
  identification_code: string
  office_id: number
  office_identification_code: string
}
export interface SubmitDestinationData {
  code: string
  id: number
  name: string
  search_key: string
  user_info: UserInfo
}

export interface Photo {
  id: number
  filename: string
  thumbnail_url: string
  url: string
}

export interface AttachmentData {
  filename: string
  id: number
  mime_type: string
  url: string
}
export interface AreaDataItem {
  attachments: AttachmentData[]
  biid: string
  code: string
  id: number | null
  id_after: number | undefined
  id_before: number | undefined
  name: string
  photos: Photo[]
  search_key: string
  submit_destination: SubmitDestinationData
  submit_destination_id: number
  valid_from: string
  valid_from_min: string | null
  valid_to: string | null
  valid_to_max: string | null
  urls: string[]
}
