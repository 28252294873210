import { Asset } from 'common/models'
import { uniqBy } from 'lodash'

import { AssetDetailPageSelector, AssetItemByValidAtSelector } from './model'

export const selectAssetItemData = (state: any): Asset | null => {
  return state.getIn(['assets', 'item', 'data'])
}

export const selectAssetItemErrors = (state: any) => {
  return state.getIn(['assets', 'item', 'errors'])
}

export const selectAssetItemLoading = (state: any): boolean => {
  return state.getIn(['assets', 'item', 'loading'])
}

export const selectEventHistories = (state: any): any[] => {
  return state.getIn(['assets', 'eventHistory', 'list', 'data']) || []
}

export const selectEventHistoriesLoading = (state: any): boolean => {
  return state.getIn(['assets', 'eventHistory', 'list', 'loading'])
}

export const selectEventHistoryErrors = (state: any): any => {
  return state.getIn(['assets', 'eventHistory', 'item', 'errors'])
}

export const selectEventHistoryIsCreatedSuccess = (state: any): boolean => {
  return state.getIn(['assets', 'eventHistory', 'item', 'isCreatedSuccess'])
}

export const selectAssetsCollectionView = (state: any) => {
  return {
    filters: state?.getIn(['assets', 'list', 'filters']),
    sort: state?.getIn(['assets', 'list', 'sort']),
    sorterValue: state?.getIn(['assets', 'list', 'sorterValue']),
    page: state?.getIn(['assets', 'list', 'page']),
    totalPages: state?.getIn(['assets', 'list', 'totalPages']),
    totalRecords: state?.getIn(['assets', 'list', 'totalRecords']),
    assets: state?.getIn(['assets', 'list', 'data']) || [],
    listLoading: state?.getIn(['assets', 'list', 'loading']),
  }
}

export const selectAssetHighlightItem = (state: any) => {
  return {
    item: state.getIn(['assets', 'list', 'highlightItem']),
    type: state.getIn(['assets', 'list', 'highlightType']),
  }
}

export const selectAssetsCollectionFormValues = (state: any) => {
  return state?.getIn(['assets', 'list', 'formValues']) as Record<string, any>
}

export const selectSettingsData = (state: any) => {
  return {
    area: uniqBy(state.getIn(['settings', 'area', 'list', 'data'], []), 'biid'),
    assetCategory: state.getIn(['settings', 'assetCategory', 'list', 'data'], []),
    department: uniqBy(state.getIn(['settings', 'department', 'list', 'data'], []), 'biid'),
  }
}

export const selectAssetItemByValidAt = (state: any): AssetItemByValidAtSelector => {
  return {
    data: state.getIn(['assets', 'itemByValidAt', 'data']),
    loading: state.getIn(['assets', 'itemByValidAt', 'loading']),
    filterDate: state.getIn(['assets', 'list', 'filters', 'valid_at']),
  }
}

export const selectAssetDetailPage = (state: any): AssetDetailPageSelector => {
  return {
    asset: state.getIn(['assets', 'item', 'data']) || {},
    eventHistory: {
      list: state.getIn(['assets', 'eventHistory', 'list', 'data']),
      loading: state.getIn(['assets', 'eventHistory', 'list', 'loading']),
    },
    itemHistoryLoading: state.getIn(['assets', 'eventHistory', 'item', 'loading']) || false,
    itemHistoryErrors: state.getIn(['assets', 'eventHistory', 'item', 'errors']),
    causes: state.getIn(['settings', 'cause', 'list', 'data']) || [],
  }
}

export const selectUploadPhotoData = (state: any) => {
  return state.getIn(['assets', 'uploadingPhotos'])
}

export const selectShowMemorandumValueErrorMessage = (state: any) =>
  state.getIn(['assets', 'showMemorandumValueErrorMessage'])
