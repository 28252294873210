import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spin, Tooltip } from 'aa_common/front-end/antd'
import { ModalRef } from 'aa_common/front-end/hooks/useModalState'
import { CONFIRM_TYPES } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { ExciseSetting } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { ExciseFormProp, ExciseSettingFormModal, ExciseSettingView } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import useModal from 'hooks/useModalHook'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { createExciseSetting, deleteExciseSetting, fetchExciseSettingAuth } from 'store/settings/exciseSetting/actions'
import { selectExciseSettingListData, selectExciseSettingLoadingState } from 'store/settings/exciseSetting/selectors'

const t = loadNs(['common', 'pages/settings/excise-setting', 'components/organisms/settings/excise-setting-view'])

interface CallbackParams {
  title: string
  setting: ExciseSetting
  allowSelectTerm?: boolean
}

const breadcrumb = () => (
  <BreadcrumbNavigation
    enableBackButton={false}
    sectionName={
      <div style={{ display: 'flex' }}>
        <span>{t('excise_setting')}</span>
        <Tooltip content={t('tooltip.excise_setting')} tooltipStyles={{ marginLeft: 5 }}>
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
        </Tooltip>
      </div>
    }
  />
)

const ExciseSettingPage = () => {
  const dispatch = useDispatch()
  const modal = useModal()

  const terms = useSelector(selectTermsData, isEqual)
  const isLoading = useSelector(selectExciseSettingLoadingState)
  const exciseSettingListData = useSelector(selectExciseSettingListData, isEqual)

  useEffect(() => {
    dispatch(fetchTerms())
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchExciseSettingAuth())
  }, [dispatch])

  const modalRef = useRef<ModalRef<ExciseFormProp>>(null)
  const openModal = useCallback(
    (params: CallbackParams) => {
      const { setting: initialValues, allowSelectTerm = true } = params
      if (initialValues) {
        modalRef.current?.open(
          {
            ...params,
            initialValues,
            onValuesCb: values => dispatch(createExciseSetting(values)),
          },
          { allowSelectTerm }
        )
      }
    },
    [dispatch]
  )

  const handleClickDelete = useCallback(
    ({ from_term, to_term, id }: ExciseSetting) => {
      const fromYear = from_term?.year
      const toYear = to_term?.year
      const i18nKey = fromYear && toYear && fromYear !== toYear ? 'dual' : 'single'

      modal
        .confirm({
          title: t(`confirm_delete_modal.title.${i18nKey}`, { fromYear, toYear }),
          message: t(`confirm_delete_modal.content.${i18nKey}`, { fromYear, toYear }),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isConfirmed => {
          isConfirmed && dispatch(deleteExciseSetting(id))
        })
    },
    [modal, dispatch]
  )

  return (
    <MainTemplate breadcrumb={breadcrumb()}>
      <Spin loading={isLoading}>
        <ExciseSettingView
          terms={terms}
          exciseSettingListData={exciseSettingListData}
          onClickDelete={handleClickDelete}
          onClickTopEdit={setting =>
            openModal({
              setting,
              title: t('actions.edit'),
            })
          }
          onClickEdit={setting =>
            openModal({
              setting,
              allowSelectTerm: false,
              title: t('actions.modify'),
            })
          }
          onClickAdd={setting =>
            openModal({
              setting,
              title: t('actions.add'),
            })
          }
        />
      </Spin>
      <ExciseSettingFormModal ref={modalRef} />
    </MainTemplate>
  )
}

export default ExciseSettingPage
