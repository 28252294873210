import React from 'react'

import { Wrapper } from './styles'

type Props = {
  color?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const CopyIcon: React.FC<Props> = ({ color = '#3B7DE9', style, onClick }) => {
  return (
    <Wrapper style={style} onClick={onClick}>
      <svg width="16" height="16" viewBox="0 0 12 17" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M13.75 3.38281H10.5C10.1953 3.38281 9.80469 3.51562 9.5 3.69531V1.13281C9.5 0.71875 9.16406 0.382812 8.75 0.382812H5.5C5.08594 0.382812 4.50781 0.624999 4.21875 0.914062L1.03125 4.10156C0.742188 4.39062 0.5 4.96875 0.5 5.38281V10.6328C0.5 11.0469 0.835938 11.3828 1.25 11.3828H5.5V13.6328C5.5 14.0469 5.83594 14.3828 6.25 14.3828H13.75C14.1641 14.3828 14.5 14.0469 14.5 13.6328V4.13281C14.5 3.71875 14.1641 3.38281 13.75 3.38281ZM9.5 5.04687V7.38281H7.16406L9.5 5.04687ZM4.5 2.04687V4.38281H2.16406L4.5 2.04687ZM6.03125 7.10156C5.74219 7.39062 5.5 7.96875 5.5 8.38281V10.3828H1.5V5.38281H4.75C5.16406 5.38281 5.5 5.04687 5.5 4.63281V1.38281H8.5V4.63281L6.03125 7.10156ZM13.5 13.3828H6.5V8.38281H9.75C10.1641 8.38281 10.5 8.04687 10.5 7.63281V4.38281H13.5V13.3828Z" />
      </svg>
    </Wrapper>
  )
}

export default CopyIcon
