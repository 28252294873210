import styled from '@emotion/styled'

export const PageWrapper = styled.div`
  padding: 15px;

  .journal-list-wrapper {
    margin: 0;
  }
`

export const ErrorWrapper = styled.div`
  max-height: 188px;
  width: 900px;
  background: #ffeeeb;
  border: 2px solid #f57575;
  border-radius: 4px;
  margin: 10px 15px 12px;
  padding: 10px 15px;
  overflow-y: auto;

  color: #ec4949;
  font-size: 13px;
  font-weight: 400;

  > p:first-child {
    margin-bottom: 0;
  }

  > dl {
    margin: 0;
    margin-top: 17px;
    display: flex;
    flex-wrap: wrap;

    dt {
      margin-right: 10px;
    }

    dd {
      margin: 0;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          white-space: pre-line;
        }
      }
    }
  }

  > dl + dl {
    margin-top: 15px;
  }
`
