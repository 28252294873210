/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterError, PresenterExportHistoryResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class TaxDeclaration<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description GetExportHistoriesBySubmitDest list pdf export history with user and destination ID
   *
   * @tags tax_declaration
   * @name ExportHistoriesDetail
   * @summary GetExportHistoriesBySubmitDest list pdf export history with submit destination ID
   * @request GET:/tax_declaration/export_histories/{submit_destination_id}
   */
  exportHistoriesDetail = (
    submitDestinationId: number | string,
    query: {
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterExportHistoryResponse[], PresenterError>({
      path: `/tax_declaration/export_histories/${submitDestinationId}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
