import styled from '@emotion/styled/macro'
import { Wrapper as FileLinkWrapper } from 'components/atoms/FileLink/styles'
import { AntLink as ReferenceLinkWrapper } from 'components/atoms/ReferenceLink/styles'

export const LogListItemDetailWrapper = styled.div`
  .help {
    display: flex;
    flex-flow: row nowrap;
    padding: 8px;
    color: #3a3a3a;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #d4d8dd;
    background-color: #ffffff;
  }

  .content {
    position: relative;
    min-height: calc(100vh - 91px);
  }
`

export const BreadcrumbArea = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
`

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px;

  .section {
    background: white;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #d4d8dd;
  }
`

export const TopContent = styled.div`
  display: flex;
`

export const BodyContent = styled.div`
  margin-top: 15px;

  .underline-item-view {
    margin-bottom: 20px;
  }

  ${FileLinkWrapper} {
    margin-bottom: 5px;
  }

  ${ReferenceLinkWrapper} {
    margin-bottom: 5px;
  }
`

export const Carousel = styled.div`
  width: 180px;
`

export const BasicInfoWrapper = styled.div`
  width: 705px;
  margin-left: 15px;

  table {
    width: 100%;
    border-bottom: 0px;
    table-layout: fixed;
  }

  tr {
    height: 32px;
  }

  th {
    background-color: #e7f1fd;
    text-align: left;
    padding: 3px 10px;
    border: 1px solid #d4d8dd;
  }

  td {
    background-color: #ffffff;
    padding: 3px 10px;
    border: 1px solid #d4d8dd;
  }
`

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;

  tr {
    height: 30px;
  }

  th {
    background-color: #e7f1fd;
    text-align: left;
    padding: 3px 10px;
    border-right: none;
    width: 160px;
    border-top: 1px solid #d4d8dd;
    border-left: 1px solid #d4d8dd;
  }

  td {
    background-color: #ffffff;
    padding: 3px 10px;
    border-left: none;
    border-top: 1px solid #d4d8dd;
    border-right: 1px solid #d4d8dd;
  }

  tr:first-of-type {
    th:first-of-type {
      border-top-left-radius: 4px;
      border-left: 1px solid #d4d8dd;
    }

    td:last-of-type {
      border-top-right-radius: 4px;
    }
  }

  tr:last-of-type {
    th:first-of-type {
      border-bottom-left-radius: 4px;
      border-bottom: 1px solid #d4d8dd;
    }

    td:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid #d4d8dd;
    }
  }

  .acquisition-cost {
    padding-right: 50px;
    margin-right: 35px;
  }

  .excise-amount-label {
    background-color: #e7f1fd;
    border-left: 1px solid #d4d8dd;
    padding: 4px 10px;
    padding-right: 98px;
    margin-right: 10px;

    @-moz-document url-prefix() {
      padding: 3.5px 10px;
    }
  }
`
