import { parseError, parseResponse } from 'common/helpers'
import { buildPaginationParams, buildQueryFilterParams, convertSortValue } from 'common/helpers/request-helper'

import base from './base'
import { IExciseGetSearch, IExciseUpdateListItem, IExciseVatInfo } from './model/excise'

export const search: IExciseGetSearch = payload => {
  return base
    .get('/v1/excises', {
      params: {
        ...buildQueryFilterParams(payload.filters),
        ...buildPaginationParams(payload.pageNumber, payload.pageSize),
        sort: convertSortValue(payload.sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const searchAuth: IExciseGetSearch = payload => {
  return base
    .get('/v1/excises/get', {
      params: {
        ...buildQueryFilterParams(payload.filters),
        ...buildPaginationParams(payload.pageNumber, payload.pageSize),
        sort: convertSortValue(payload.sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const getExciseRates = () => {
  return base.get('/v1/excise_rates')
}

export const updateListItem: IExciseUpdateListItem = (id, updatedData) => {
  return base.put(`/v1/excises/${id}`, updatedData)
}

export const getExciseSettingsByDate: any = (date: string) => {
  return base.get(`/v1/excise_settings/by_date?date=${date}`)
}

export const getExciseVatInfo: IExciseVatInfo = (at, excise_id, input_value) => {
  return base.post('/v1/excises/calculate_value', {
    at,
    excise_id,
    input_value,
  })
}
