import { useEffect } from 'react'

import { useFormReady } from './use-form-ready'

export const useReductionAmount = (
  acquisitionCostReference: number | undefined,
  reductionAmount: number,
  manuallyCostUpdated: boolean,
  setFieldValue: (field: string, value: any) => void
) => {
  const isFormReady = useFormReady()

  useEffect(() => {
    if (
      isFormReady &&
      !Number.isNaN(reductionAmount) &&
      !Number.isNaN(acquisitionCostReference) &&
      acquisitionCostReference &&
      reductionAmount <= acquisitionCostReference
    ) {
      setFieldValue('net_acquisition_cost', acquisitionCostReference - reductionAmount)
    }
  }, [acquisitionCostReference, reductionAmount, isFormReady])

  useEffect(() => {
    if (manuallyCostUpdated && !acquisitionCostReference) {
      setFieldValue('reduction_amount', 0)
      setFieldValue('net_acquisition_cost', 0)
    }
  }, [acquisitionCostReference, manuallyCostUpdated])
}
