import * as assetCategoryApi from 'api/app/assetCategory'
import { AxiosResponse } from 'axios'
import { DATA_IN_USED_ERROR_CODE } from 'common/constants'
import { downloadFileByResponse } from 'common/helpers/file-helper'
import { parseError, parseImportErrors, parseValidationError } from 'common/utils/response-helper'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, SHOW_IN_USED_MESSAGE, showErrorsAppCall } from 'store/app/actions'

import {
  CreateAssetCategoryRequest,
  DeleteAssetCategoryRequest,
  FetchAssetCategoriesRequest,
  FetchAssetCategorySuccess,
  HandleSubmitAssetCategoryFailure,
  ImportAssetCategoryFailure,
  SubmitAssetCategoryFailure,
  UpdateAssetCategoryRequest,
} from './action.model'
import { fetchAssetCategoriesFailure, fetchAssetCategoriesSuccess } from './actions'
import {
  CREATE_ASSET_CATEGORY_REQUEST,
  DELETE_ASSET_CATEGORY_FAILURE,
  DELETE_ASSET_CATEGORY_REQUEST,
  DELETE_ASSET_CATEGORY_SUCCESS,
  EXPORT_ASSET_CATEGORY_FAILURE,
  EXPORT_ASSET_CATEGORY_REQUEST,
  EXPORT_ASSET_CATEGORY_SUCCESS,
  FETCH_ASSET_CATEGORIES_REQUEST,
  FETCH_ASSET_CATEGORIES_REQUEST_AUTH,
  FETCH_ASSET_CATEGORY_REQUEST,
  FETCH_ASSET_CATEGORY_SUCCESS,
  HANDLE_SUBMIT_ASSET_CATEGORY_FAILURE,
  IMPORT_ASSET_CATEGORY_FAILURE,
  IMPORT_ASSET_CATEGORY_REQUEST,
  IMPORT_ASSET_CATEGORY_SUCCESS,
  SUBMIT_ASSET_CATEGORY_FAILURE,
  SUBMIT_ASSET_CATEGORY_SUCCESS,
  UPDATE_ASSET_CATEGORY_REQUEST,
} from './constant'

export const selector = {
  getFilterConditions: (state: any) =>
    state.getIn(['settings', 'assetCategory', 'list', 'filters']) as Record<string, any>,
  getSort: (state: any) => state.getIn(['settings', 'assetCategory', 'list', 'sort']) as string[],
}

export function* handleSubmitAssetCategoryFailure({ error }: HandleSubmitAssetCategoryFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put<SubmitAssetCategoryFailure>({ type: SUBMIT_ASSET_CATEGORY_FAILURE, formErrors: payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put<SubmitAssetCategoryFailure>({ type: SUBMIT_ASSET_CATEGORY_FAILURE })
    }
  }
}

export function* fetchAssetCategories({ payload }: any) {
  const { data, errors } = yield call(assetCategoryApi.search, payload)
  if (data) {
    yield put(fetchAssetCategoriesSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchAssetCategoriesFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchAssetCategoriesAuth({ payload }: any) {
  const { data, errors } = yield call(assetCategoryApi.searchAuth, payload)
  if (data) {
    yield put(fetchAssetCategoriesSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchAssetCategoriesFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchAssetCategory({ id }: any) {
  try {
    const response: AxiosResponse = yield call(assetCategoryApi.getAssetCategory, id)
    yield put<FetchAssetCategorySuccess>({ type: FETCH_ASSET_CATEGORY_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createAssetCategory({ payload }: CreateAssetCategoryRequest) {
  try {
    yield call(assetCategoryApi.createAssetCategory, payload)
    yield put({ type: SUBMIT_ASSET_CATEGORY_SUCCESS })
    yield put(changeLocation('/master/asset-categories'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetCategoryFailure>({
      type: HANDLE_SUBMIT_ASSET_CATEGORY_FAILURE,
      error,
    })
  }
}

export function* updateAssetCategory({ id, payload }: UpdateAssetCategoryRequest) {
  try {
    yield call(assetCategoryApi.updateAssetCategory, id, payload)
    yield put({ type: SUBMIT_ASSET_CATEGORY_SUCCESS })
    yield put(changeLocation('/master/asset-categories'))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetCategoryFailure>({
      type: HANDLE_SUBMIT_ASSET_CATEGORY_FAILURE,
      error,
    })
  }
}

export function* deleteAssetCategory({ payload }: DeleteAssetCategoryRequest) {
  const { id, name } = payload

  try {
    const filters: ReturnType<typeof selector.getFilterConditions> = yield select(selector.getFilterConditions)
    const sort: ReturnType<typeof selector.getSort> = yield select(selector.getSort)

    const checkDeleteResponse: AxiosResponse = yield call(assetCategoryApi.destroyAssetCategory, id, true)
    const isAllowDelete = checkDeleteResponse.data.data && checkDeleteResponse.data.data.id
    if (isAllowDelete) {
      yield call(assetCategoryApi.destroyAssetCategory, id, false)
      yield put<FetchAssetCategoriesRequest>({ type: FETCH_ASSET_CATEGORIES_REQUEST, payload: { sort, filters } })
      yield put(
        setAppMessage({
          type: 'success',
          content: i18n.t('common.messages.deleted_success'),
        })
      )
      yield put({ type: DELETE_ASSET_CATEGORY_SUCCESS })
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && get(data, 'errors[0].code') === DATA_IN_USED_ERROR_CODE) {
        yield put({
          type: SHOW_IN_USED_MESSAGE,
          payload: i18n.t('common.messages.deleted_failure_by_in_used', { name }),
        })
      } else {
        yield put(
          setAppMessage({
            type: 'failure',
            content: i18n.t('common.messages.deleted_failure', { name }),
          })
        )
        yield put(showErrorsAppCall(parseError(error)))
      }
    }
    yield put({ type: DELETE_ASSET_CATEGORY_FAILURE })
  }
}

export function* importAssetCategories({ payload, file }: any) {
  try {
    yield call(assetCategoryApi.importAssetCategories, payload, file)
    yield put({ type: IMPORT_ASSET_CATEGORY_SUCCESS })
    yield put<FetchAssetCategoriesRequest>({ type: FETCH_ASSET_CATEGORIES_REQUEST, payload: {} })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      })
    )
  } catch (error: any) {
    const { status, data } = error.response

    if (status === 400 || status === 422) {
      const importedErrors = parseImportErrors(data)

      yield put<ImportAssetCategoryFailure>({ type: IMPORT_ASSET_CATEGORY_FAILURE, payload: importedErrors })
    } else {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* exportAssetCategories({ payload }: any) {
  try {
    const filters: ReturnType<typeof selector.getFilterConditions> = yield select(selector.getFilterConditions)
    const response: AxiosResponse = yield call(assetCategoryApi.exportAssetCategories, payload, filters)
    yield downloadFileByResponse(response)
    yield put({ type: EXPORT_ASSET_CATEGORY_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (error) {
    yield put({ type: EXPORT_ASSET_CATEGORY_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_ASSET_CATEGORIES_REQUEST, fetchAssetCategories)
  yield takeLatest(FETCH_ASSET_CATEGORIES_REQUEST_AUTH, fetchAssetCategoriesAuth)
  yield takeLatest(FETCH_ASSET_CATEGORY_REQUEST, fetchAssetCategory)
  yield takeLatest(CREATE_ASSET_CATEGORY_REQUEST, createAssetCategory)
  yield takeLatest(UPDATE_ASSET_CATEGORY_REQUEST, updateAssetCategory)
  yield takeLatest(DELETE_ASSET_CATEGORY_REQUEST, deleteAssetCategory)
  yield takeLatest(IMPORT_ASSET_CATEGORY_REQUEST, importAssetCategories)
  yield takeLatest(EXPORT_ASSET_CATEGORY_REQUEST, exportAssetCategories)
  yield takeLatest(HANDLE_SUBMIT_ASSET_CATEGORY_FAILURE, handleSubmitAssetCategoryFailure)
}
