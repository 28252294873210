import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin-top: 10px;
`

export const CurrentLabel = styled.div`
  min-width: 32px;
  height: 20px;
  background-color: #3b7de9;
  border-radius: 4px;
  margin-left: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
`

export const UserListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .aa-link {
    margin-right: 10px;
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`
