import { Button } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import RangeDatePickerInput from 'components/molecules/v2/RangeDatePickerInput'
import { RangeDatePickerChangeEvent } from 'components/molecules/v2/RangeDatePickerInput/model'
import React, { useCallback } from 'react'

import { BaseFormFilter } from '../../model'
import { ActionButtonWrapper, Field, Label, RangeDateFormWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

interface IRangeDatePickerForm extends BaseFormFilter {
  name: string
  label: string
}

const RangeDatePickerForm = ({ name, label, formModule, setPopupShow, onFormSubmit }: IRangeDatePickerForm) => {
  const { handleSubmit } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleDateChanged = useCallback((event: RangeDatePickerChangeEvent) => {
    if (!event.value) {
      formModule.setValue(name, undefined)
    }
  }, []) // eslint-disable-line

  const handleClickSubmit = () => {
    const { getValues } = formModule
    const submitFormValues = {
      [name]: getValues(name as any),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
    setPopupShow(false)
  }

  return (
    <RangeDateFormWrapper>
      <Field>
        <Label htmlFor={name}>{label}</Label>
        <RangeDatePickerInput
          placeholder={t('select_date_placeholder')}
          name={name}
          control={formModule.control}
          onDateChanged={handleDateChanged}
        />
      </Field>
      <ActionButtonWrapper>
        <Button type="button" color="blue" onClick={handleClickSubmit}>
          {t('actions.ok')}
        </Button>
      </ActionButtonWrapper>
    </RangeDateFormWrapper>
  )
}

export default RangeDatePickerForm
