import { List, Map } from 'immutable'

import { AssetActionTypes } from './action.model'
import {
  CLEANUP_ASSET,
  CLEANUP_ASSET_COLLECTION_ITEM,
  CLEANUP_ASSET_COLLECTION_LIST,
  CLEANUP_ASSET_ITEM_BY_VALID_AT,
  CLEAR_ASSET_EVENT_ERRORS,
  CREATE_ASSET_EVENT_MOVEMENT_REQUEST,
  CREATE_ASSET_EVENT_MOVEMENT_SUCCESS,
  CREATE_ASSET_EVENT_RETIREMENT_REQUEST,
  CREATE_ASSET_EVENT_RETIREMENT_SUCCESS,
  CREATE_ASSET_EVENT_SELL_REQUEST,
  CREATE_ASSET_EVENT_SELL_SUCCESS,
  CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
  CREATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS,
  CREATE_ASSET_EVENT_USAGE_REQUEST,
  CREATE_ASSET_EVENT_USAGE_SUCCESS,
  CREATE_ASSET_ITEM_REQUEST,
  CREATE_ASSET_ITEM_SUCCESS,
  DELETE_ASSET_ITEM_FAILURE,
  DELETE_ASSET_ITEM_REQUEST,
  DELETE_ASSET_ITEM_SUCCESS,
  EXPORT_ASSET_FAILURE,
  EXPORT_ASSET_REQUEST,
  EXPORT_ASSET_SUCCESS,
  FETCH_ASSET_EVENT_HISTORY_FAILURE,
  FETCH_ASSET_EVENT_HISTORY_REQUEST,
  FETCH_ASSET_EVENT_HISTORY_SUCCESS,
  FETCH_ASSET_ITEM_BY_VALID_AT_FAILURE,
  FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST,
  FETCH_ASSET_ITEM_BY_VALID_AT_SUCCESS,
  FETCH_ASSET_ITEM_REQUEST,
  FETCH_ASSET_ITEM_SUCCESS,
  FETCH_ASSET_LIST_FAILURE,
  FETCH_ASSET_LIST_REQUEST,
  FETCH_ASSET_LIST_SUCCESS,
  SET_IS_CREATED_SUCCESS_ASSET_EVENT,
  SET_SHOW_MEMORANDUM_VALUE_ERROR_MESSAGE,
  SUBMIT_ASSET_EVENT_FAILURE,
  SUBMIT_ASSET_ITEM_FAILURE,
  SUBMIT_IMPAIRMENT_FAILURE,
  SUBMIT_IMPAIRMENT_REQUEST,
  SUBMIT_IMPAIRMENT_SUCCESS,
  SUBMIT_MODIFIED_ASSET_TYPE_FAILURE,
  SUBMIT_MODIFIED_ASSET_TYPE_REQUEST,
  SUBMIT_MODIFIED_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_EVENT_MOVEMENT_REQUEST,
  UPDATE_ASSET_EVENT_MOVEMENT_SUCCESS,
  UPDATE_ASSET_EVENT_RETIREMENT_REQUEST,
  UPDATE_ASSET_EVENT_RETIREMENT_SUCCESS,
  UPDATE_ASSET_EVENT_SELL_REQUEST,
  UPDATE_ASSET_EVENT_SELL_SUCCESS,
  UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
  UPDATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS,
  UPDATE_ASSET_EVENT_USAGE_REQUEST,
  UPDATE_ASSET_EVENT_USAGE_SUCCESS,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_COMMENT_COUNT_SUCCESS,
  UPLOAD_PHOTO,
} from './constant'

const initAssetCollectionList = Map({
  data: null,
  filters: null,
  formValues: null,
  sort: null,
  sorterValue: null,
  page: 1,
  totalPages: null,
  totalRecords: null,
  loading: false,
  highlightItem: null,
  highlightType: null,
})

const initAssetItem = Map({
  data: null,
  loading: false,
  errors: null,
})

const initEventHistoryList = Map({
  data: [],
  filters: null,
  sort: null,
  page: 1,
  totalPages: null,
  totalRecords: null,
  loading: false,
})

const initEventHistoryItem = Map({
  loading: false,
  errors: null,
  isCreatedSuccess: false,
})

const initialState = Map({
  list: initAssetCollectionList,
  eventHistory: Map({
    list: initEventHistoryList,
    item: initEventHistoryItem,
  }),
  item: initAssetItem,
  itemByValidAt: initAssetItem,
  uploadingPhotos: false,
  showMemorandumValueErrorMessage: {},
})

export default (state = initialState, action: AssetActionTypes) => {
  switch (action.type) {
    case FETCH_ASSET_LIST_REQUEST:
      return state
        .setIn(['list', 'loading'], true)
        .setIn(['list', 'filters'], action.payload.filters)
        .setIn(
          ['list', 'formValues'],
          !action.payload.formValues ? state.getIn(['list', 'formValues']) : action.payload.formValues
        )
        .setIn(['list', 'sort'], action.payload.sort)
        .setIn(
          ['list', 'sorterValue'],
          !action.payload.sorterValue ? state.getIn(['list', 'sorterValue']) : action.payload.sorterValue
        )
        .setIn(['list', 'page'], action.pageNumber)
    case FETCH_ASSET_LIST_SUCCESS:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'data'], action.payload.list)
        .setIn(['list', 'highlightItem'], null)
        .setIn(['list', 'highlightType'], null)
        .setIn(['list', 'totalPages'], action.payload.totalPages)
        .setIn(['list', 'totalRecords'], action.payload.totalRecords)
    case FETCH_ASSET_LIST_FAILURE:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'highlightItem'], null)
        .setIn(['list', 'highlightType'], null)
    case FETCH_ASSET_EVENT_HISTORY_REQUEST:
      return state
        .setIn(['eventHistory', 'list', 'loading'], true)
        .setIn(['eventHistory', 'list', 'filters'], action.payload.filters)
        .setIn(['eventHistory', 'list', 'sort'], action.payload.sort)
    case FETCH_ASSET_EVENT_HISTORY_SUCCESS:
      return state
        .setIn(['eventHistory', 'list', 'loading'], false)
        .setIn(['eventHistory', 'list', 'data'], action.payload.list)
        .setIn(['eventHistory', 'list', 'totalRecords'], action.payload.totalRecords)
    case FETCH_ASSET_EVENT_HISTORY_FAILURE:
      return state
        .setIn(['eventHistory', 'list', 'loading'], false)
        .setIn(['eventHistory', 'list', 'errors'], action.payload)
    case FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST:
      return state.setIn(['itemByValidAt', 'loading'], true)
    case FETCH_ASSET_ITEM_BY_VALID_AT_SUCCESS: {
      let newState = state
        .setIn(['itemByValidAt', 'loading'], false)
        .setIn(['itemByValidAt', 'data'], action.payload.fixedAssetByValidAt)
        .setIn(['item', 'data'], action.payload.fixedAssetByLatest)

      if (action.payload.filterDate) {
        const prevFilters = state.getIn(['list', 'filters']) as any
        newState = newState.setIn(['list', 'filters'], {
          ...prevFilters,
          valid_at: action.payload.filterDate,
        })
      }

      return newState
    }
    case FETCH_ASSET_ITEM_BY_VALID_AT_FAILURE:
      return state.setIn(['itemByValidAt', 'loading'], false)
    case FETCH_ASSET_ITEM_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case FETCH_ASSET_ITEM_REQUEST:
    case CREATE_ASSET_ITEM_REQUEST:
    case UPDATE_ASSET_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case CREATE_ASSET_ITEM_SUCCESS:
    case UPDATE_ASSET_SUCCESS:
      return state
        .setIn(['list', 'highlightItem'], action.highlightItem)
        .setIn(['list', 'highlightType'], action.type === CREATE_ASSET_ITEM_SUCCESS ? 'create' : 'update')
        .setIn(['item', 'loading'], false)
    case SUBMIT_ASSET_ITEM_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.payload)
    case CREATE_ASSET_EVENT_USAGE_REQUEST:
    case CREATE_ASSET_EVENT_RETIREMENT_REQUEST:
    case CREATE_ASSET_EVENT_MOVEMENT_REQUEST:
    case CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST:
    case CREATE_ASSET_EVENT_SELL_REQUEST:
    case UPDATE_ASSET_EVENT_USAGE_REQUEST:
    case UPDATE_ASSET_EVENT_RETIREMENT_REQUEST:
    case UPDATE_ASSET_EVENT_MOVEMENT_REQUEST:
    case UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST:
    case UPDATE_ASSET_EVENT_SELL_REQUEST:
    case SUBMIT_IMPAIRMENT_REQUEST:
    case SUBMIT_MODIFIED_ASSET_TYPE_REQUEST:
      return state
        .setIn(['eventHistory', 'item', 'loading'], true)
        .setIn(['eventHistory', 'item', 'isCreatedSuccess'], false)
    case CREATE_ASSET_EVENT_USAGE_SUCCESS:
    case CREATE_ASSET_EVENT_RETIREMENT_SUCCESS:
    case CREATE_ASSET_EVENT_MOVEMENT_SUCCESS:
    case CREATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS:
    case CREATE_ASSET_EVENT_SELL_SUCCESS:
      return state
        .setIn(['eventHistory', 'item', 'loading'], false)
        .setIn(['eventHistory', 'item', 'errors'], null)
        .setIn(['eventHistory', 'item', 'isCreatedSuccess'], true)
    case UPDATE_ASSET_EVENT_USAGE_SUCCESS:
    case UPDATE_ASSET_EVENT_RETIREMENT_SUCCESS:
    case UPDATE_ASSET_EVENT_MOVEMENT_SUCCESS:
    case UPDATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS:
    case UPDATE_ASSET_EVENT_SELL_SUCCESS:
    case SUBMIT_IMPAIRMENT_SUCCESS:
    case SUBMIT_MODIFIED_ASSET_TYPE_SUCCESS:
      return state.setIn(['eventHistory', 'item', 'loading'], false).setIn(['eventHistory', 'item', 'errors'], null)
    case UPDATE_COMMENT_COUNT_SUCCESS: {
      const data = state.getIn(['list', 'data']) as any[]
      const list = List.of(...data)
      const updatedIndex = list.findIndex(item => item.biid === action.assetBiid)
      const updatedList = list.setIn([updatedIndex, 'commentCount'], action.payload)
      return state.setIn(['list', 'data'], updatedList.toArray())
    }
    case SET_IS_CREATED_SUCCESS_ASSET_EVENT:
      return state.setIn(['eventHistory', 'item', 'isCreatedSuccess'], action.payload)
    case SUBMIT_ASSET_EVENT_FAILURE:
    case SUBMIT_IMPAIRMENT_FAILURE:
    case DELETE_ASSET_ITEM_FAILURE:
    case SUBMIT_MODIFIED_ASSET_TYPE_FAILURE:
      return state
        .setIn(['eventHistory', 'item', 'loading'], false)
        .setIn(['eventHistory', 'item', 'errors'], action.payload)
    case CLEAR_ASSET_EVENT_ERRORS:
      return state.setIn(['eventHistory', 'item', 'errors'], null)
    case CLEANUP_ASSET:
      return initialState
    case CLEANUP_ASSET_COLLECTION_LIST:
      return state.setIn(['list'], initAssetCollectionList)
    case CLEANUP_ASSET_COLLECTION_ITEM:
      return state.setIn(['item'], initAssetItem)
    case EXPORT_ASSET_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_ASSET_SUCCESS:
    case EXPORT_ASSET_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CLEANUP_ASSET_ITEM_BY_VALID_AT:
      return state.setIn(['itemByValidAt'], initAssetItem)
    case DELETE_ASSET_ITEM_REQUEST:
      return state.setIn(['eventHistory', 'item', 'loading'], true)
    case DELETE_ASSET_ITEM_SUCCESS:
      return state.setIn(['eventHistory', 'item', 'loading'], false)
    case UPLOAD_PHOTO:
      return state.setIn(['uploadingPhotos'], action.payload)
    case SET_SHOW_MEMORANDUM_VALUE_ERROR_MESSAGE:
      return state.setIn(
        ['showMemorandumValueErrorMessage', action.payload.ledgerId],
        action.payload.isShowErrorMessage
      )
    default:
      return state
  }
}
