import { css } from '@emotion/css';

export const RightDrawerClass = css`
  &.ant-drawer-right {
    transition: width .2s ease, transform .3s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .ant-drawer-body {
    padding: 15px;
    margin-bottom: 15px;
    overflow-x: hidden;
  }

  .ant-drawer-header {
    padding: 0;
    border-bottom: 1px solid #D4D8DD;
  }

  .header-icon {
    color: grey;
  }

  .ant-drawer-title {
    @media not all and (min-resolution:.001dpcm) {
      @supports (-webkit-appearance:none) {
        display: inline-block;
      }
    }
  }

  .ant-drawer-close {
    position: absolute;
    top: 64px;
    left: 15px;
    padding: 0;
    @media not all and (min-resolution:.001dpcm) {
      @supports (-webkit-appearance:none) {
        display: inline-block;
        width: 0;
        text-align: left;
      }
    }
  }
}
`
