/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAutoGeneratingSerialNumberSettingFilter,
  PresenterError,
  PresenterIDResponse,
  PresenterInitialValueSerialNumberResponse,
  PresenterMeta,
  PresenterOneAutoGeneratingSerialNumberSettingResponse,
  PresenterSaveAutoGeneratingSerialNumberSettingRequest,
  PresenterSaveInitialValuesSerialNumberRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AutoGeneratingSerialNumberSettings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List AutoGeneratingSerialNumberSetting
   *
   * @name AutoGeneratingSerialNumberSettingsList
   * @summary List the AutoGeneratingSerialNumberSetting
   * @request GET:/auto_generating_serial_number_settings
   */
  autoGeneratingSerialNumberSettingsList = (
    body: PresenterAutoGeneratingSerialNumberSettingFilter,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneAutoGeneratingSerialNumberSettingResponse, any>({
      path: `/auto_generating_serial_number_settings`,
      method: 'GET',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Save autogeneratingserialnumbersetting
   *
   * @tags auto_generating_serial_number_settings
   * @name AutoGeneratingSerialNumberSettingsCreate
   * @summary Save the autogeneratingserialnumbersetting
   * @request POST:/auto_generating_serial_number_settings
   */
  autoGeneratingSerialNumberSettingsCreate = (
    body: PresenterSaveAutoGeneratingSerialNumberSettingRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneAutoGeneratingSerialNumberSettingResponse, PresenterError>({
      path: `/auto_generating_serial_number_settings`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Read more: https://mfv.esa.io/posts/1965#Getting%20current%20values
   *
   * @tags auto_generating_serial_number_settings
   * @name AutoGeneratingSerialNumberSettingsDetail
   * @summary List the initial values for AutoGeneratingSerialNumberSetting
   * @request GET:/auto_generating_serial_number_settings/{id}
   */
  autoGeneratingSerialNumberSettingsDetail = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterInitialValueSerialNumberResponse[], PresenterError>({
      path: `/auto_generating_serial_number_settings/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the values for AutoGeneratingSerialNumberSetting
   *
   * @tags auto_generating_serial_number_settings
   * @name AutoGeneratingSerialNumberSettingsDelete
   * @summary Delete the values for AutoGeneratingSerialNumberSetting
   * @request DELETE:/auto_generating_serial_number_settings/{id}
   */
  autoGeneratingSerialNumberSettingsDelete = (id: number | string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/auto_generating_serial_number_settings/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Read more: https://mfv.esa.io/posts/1965#Setting%20initial%20values
   *
   * @tags auto_generating_serial_number_settings
   * @name SaveCreate
   * @summary Save the initial values for AutoGeneratingSerialNumberSetting
   * @request POST:/auto_generating_serial_number_settings/{id}/save
   */
  saveCreate = (id: string, body: PresenterSaveInitialValuesSerialNumberRequest, params: RequestParams = {}) =>
    this.http.request<PresenterMeta, PresenterError>({
      path: `/auto_generating_serial_number_settings/${id}/save`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
