import { UnderlineInput } from 'aa_common/front-end/antd'
import { UnborderCollapse } from 'aa_common/front-end/components'
import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import React, { useMemo } from 'react'

import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { useReferValue } from '../hooks/useReferValue'
import { useResetInput } from '../hooks/useResetInput'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type ServiceLifeAfterChangeProps = {
  enabled?: boolean
  isShown: boolean
  fieldName: string
  referValue?: number | null
  isCopied: boolean
  isUpdateReferManually?: boolean
  forceResetToRefer?: boolean
}

export const ServiceLifeAfterChange = ({
  enabled,
  isShown,
  fieldName,
  referValue,
  isCopied,
  isUpdateReferManually,
  forceResetToRefer,
}: React.PropsWithChildren<ServiceLifeAfterChangeProps>) => {
  const { values, setFieldValue } = useFormikContext()
  const value = getIn(values, fieldName)

  // Prevent referring value when copying and initializing with data
  const canUpdate = useMemo(() => {
    return isShown && !isCopied && !!isUpdateReferManually
  }, [isShown, isCopied, isUpdateReferManually])

  useReferValue(fieldName, setFieldValue, value, referValue, canUpdate, forceResetToRefer)
  useClearValueOnHide(fieldName, setFieldValue, isShown)
  const { hasUpdate, handleReset } = useResetInput(fieldName, value, setFieldValue, referValue, isNumberHaveValue)

  if (!isShown) {
    return null
  }

  return (
    <Item
      label={
        <ItemLabelTooltip
          tooltipMaxWidth={560}
          label={parser(t('service_life_after_change'))}
          tooltip={parser(t('service_life_after_change_tooltip'))}
        />
      }
    >
      <UnderlineInput
        type="number"
        name={fieldName}
        editable={enabled}
        align="right"
        isResetManualValue={hasUpdate && enabled}
        onHandleClickOnReset={handleReset}
        min={2}
        defaultValue={referValue || 2}
      />
    </Item>
  )
}
