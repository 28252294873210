import Form from 'components/molecules/Form'
import { isArray, pick } from 'lodash'
import React from 'react'
import { Control, useWatch } from 'react-hook-form'

const { SubmitButton } = Form

interface Props {
  formControl: Control<Record<string, any>>
  loading?: boolean
  submitText?: any
}

const requiredFields = ['id', 'role_biids']

const SubmitArea = ({ formControl, loading, submitText }: Props) => {
  const formValues = useWatch({
    control: formControl,
  })

  const watchFormValues = pick(formValues, requiredFields)
  const isValid = Object.values(watchFormValues).every(value => value && (!isArray(value) ? !!value : value.length > 0))

  return <SubmitButton isDisableBtn={!isValid || loading} submitText={submitText} />
}

export default SubmitArea
