import Icon from '@ant-design/icons'
import styled from '@emotion/styled'
import { ComplexTable } from 'aa_common/front-end/antd'
import { Button } from 'antd'
import { BLACK_COLOR, BLUE_COLOR } from 'common/constants'

export const Table = styled(ComplexTable)`
  &.month-simulation-detail-table {
    tr > th {
      background: #fafafa;
      color: #666666;
      font-weight: 500;
      padding: 5px 10px;
    }

    tr > td {
      cursor: default;
      &.ant-table-cell {
        padding: 10px;
      }
    }

    .text {
      color: ${BLACK_COLOR};
      font-size: 13px;
    }
  }
`

export const ButtonLink = styled(Button)`
  &.ant-btn-link {
    padding: 0;
    margin: 0;
    font-size: 13px;
    height: auto;
  }
`

export const CheckIcon = styled(Icon)`
  font-size: 18px;
  vertical-align: middle;
  color: ${BLUE_COLOR};
`
