import { loadNs } from 'common/i18n-config'
import parser from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { validateRequiredField } from './helper'
import { ErrorMessage } from './styles'
import { getGeneralValidationErrors } from './validations/asset-schema'

const t = loadNs(['common'])

type Props = {
  errors: any
  values: any
  currentSetting?: any
}

const ErrorComponent: React.FC<Props> = ({ errors, values, currentSetting }) => {
  const [errorContent, setErrorContent] = useState('')
  const firstTimeRef = useRef(true)

  const displayErrors = useDebouncedCallback((errors: any, values: any, currentSetting?: any) => {
    const errorFields = Array.from(document.querySelectorAll('[data-js-label]')).filter(field => {
      return field.querySelector('.error')
    })
    const requiredErrorFields = validateRequiredField(values, currentSetting)
    const generalTopErrors = getGeneralValidationErrors(errors).join('<br>')
    const combineErrors = [requiredErrorFields, generalTopErrors].filter(error => !!error).join('<br>')

    if (errorFields.length === 1) {
      const target = errorFields[0]
      target.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' })
    } else if (errorFields.length > 1 || combineErrors) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const errorFieldLabels = errorFields.map(field => field.getAttribute('data-js-label'))
    setErrorContent(combineErrors || errorFieldLabels.join('<br>'))
  }, 250)

  useEffect(() => {
    if (firstTimeRef.current) {
      firstTimeRef.current = false
      return
    }
    displayErrors(errors, values, currentSetting)
  }, [errors, values, currentSetting])

  return !errorContent ? null : (
    <ErrorMessage>
      <div>{t('messages.please_validate')}</div>
      {parser(errorContent)}
    </ErrorMessage>
  )
}

export default ErrorComponent
