/* eslint-disable react/display-name */
import { ColumnsType } from 'antd/lib/table'
import { yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { MonthlyClosing } from 'common/models'
import React from 'react'

import MonthSimulation from '../MonthSimulation'
import { ConvertedDepreciationResultItem } from './model'

const t = loadNs(['components/organisms/assets/asset-detail', 'common'])

const PeriodTemplate = (data: any) => {
  return <span>{t('format.year_term', { year: data?.fiscal_year, term: data?.period })}</span>
}
const MoneyTemplate = (data: any) => <span>{yen(data)}</span>

interface AmortizationTableSettingsProps {
  currentMonthlyClosing: MonthlyClosing
  acquiredAt: string
  usageStartedAt: string | null
}

export const amortizationTableSettings = ({
  currentMonthlyClosing,
  acquiredAt,
  usageStartedAt,
}: AmortizationTableSettingsProps): ColumnsType<ConvertedDepreciationResultItem> => [
  {
    title: t('period'),
    width: 150,
    key: 'period',
    render: PeriodTemplate,
  },
  {
    title: t('depreciation_status'),
    dataIndex: 'renderMonths',
    key: 'depreciation_status',
    width: 'auto',
    className: 'depreciation-cell month-simulation',
    render: data => {
      return (
        <MonthSimulation
          months={data}
          currentMonthlyClosing={currentMonthlyClosing}
          acquiredAt={acquiredAt}
          usageStartedAt={usageStartedAt}
        />
      )
    },
  },
  {
    title: t('depreciationTable.beginning_book_value'),
    width: 130,
    dataIndex: 'beginning_book_value',
    key: 'beginning_book_value',
    showSorterTooltip: false,
    className: 'depreciation-cell right',
    render: MoneyTemplate,
  },
  {
    title: t('depreciationTable.depreciation_amount'),
    dataIndex: 'depreciation_amount',
    key: 'depreciation_amount',
    width: 130,
    className: 'depreciation-cell right',
    render: MoneyTemplate,
  },
  {
    title: t('depreciationTable.end_of_term_book_value'),
    key: 'end_of_term_book_value',
    dataIndex: 'end_of_term_book_value',
    width: 130,
    className: 'depreciation-cell right',
    render: MoneyTemplate,
  },
  {
    title: t('depreciationTable.accumulated_depreciation'),
    key: 'accumulated_depreciation_amount',
    dataIndex: 'accumulated_depreciation_amount',
    width: 130,
    className: 'depreciation-cell right',
    render: MoneyTemplate,
  },
]
