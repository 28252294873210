export const StatusJob = {
  JobStatusWaiting: 'WAITING',
  // JobStatusRunning represents running status that job is running
  JobStatusRunning: 'RUNNING',
  // JobStatusFailed represents running status that job was failed
  JobStatusFailed: 'FAILED',
  // JobStatusCompleted represents running status that job was completed
  JobStatusCompleted: 'COMPLETED',
  // JobStatusTimeOut represents running status that job was timeout
  JobStatusTimeOut: 'TIMEOUT',
}
