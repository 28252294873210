import { Map } from 'immutable'

import {
  CLEANUP_ASSET_IMPORT,
  IMPORT_ASSET_LIST_FAILURE,
  IMPORT_ASSET_LIST_REQUEST,
  IMPORT_ASSET_LIST_SUCCESS,
  SET_EDIT_ASSET_ITEM,
  SET_IMPORT_ASSET_LIST,
} from './actions'

const initialState = Map({
  list: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case IMPORT_ASSET_LIST_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case IMPORT_ASSET_LIST_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case IMPORT_ASSET_LIST_FAILURE:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'data'], action.payload.data)
        .setIn(['list', 'errors'], action.payload.errors)
    case SET_EDIT_ASSET_ITEM:
      return state.setIn(['item', 'data'], action.payload)
    case SET_IMPORT_ASSET_LIST:
      return state.setIn(['list', 'data'], action.payload)
    case CLEANUP_ASSET_IMPORT:
      return initialState
    default:
      return state
  }
}
