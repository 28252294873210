import styled from '@emotion/styled'

export const Wrapper = styled.div``

export const List = styled.div`
  color: #3b7de9;
`

export const Item = styled.div`
  display: flex;
  margin-top: 10px;
  position: relative;

  &:hover {
    background-color: #f5f5f5;
    .remove-icon {
      opacity: 1;
    }
  }

  .content {
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .open-icon {
    color: #7c8291;
    margin-right: 10px;
    cursor: pointer;
  }

  .remove-icon {
    position: absolute;
    right: 4px;
    top: 3px;
    color: #e31c1c;
    opacity: 0;
    cursor: pointer;
  }
`
