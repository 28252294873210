import { Map } from 'immutable'

import { FETCH_MASTER_DATA_REQUEST, FETCH_MASTER_DATA_SUCCESS } from './actions'

export const initialState = Map({
  data: {
    asset_status: [],
    asset_type_for_corporate_tax: [],
    asset_type_for_depreciable_property_tax: [],
    depreciation_method: [],
    cause_type: [],
    prepress_category: [],
    acquire_category: [],
    change_situation: [],
    monthly_residual_method: [],
    ledger_purpose: [],
    rounding_method: [],
    bulk_equal_installment_first_year_method: [],
    movement_depreciation_amount_method: [],
    maximum_depreciation_reached_year_calculation_method: [],
    lump_sum_depreciable_asset_rounding_on: [],
    bulk_equal_installment_depreciation_method: [],
    five_years_equal_installment_rounding_method: [],
    impairment_loss_apply_method: [],
    retirement_depreciation_ended_method: [],
    depreciable_assets_tax_declaration: [],
    asset_code_settings: {
      auto_generating_serial_number_setting_field: null,
      auto_generating_serial_number_setting_length: null,
      auto_generating_serial_number_setting_method: null,
      auto_generating_serial_number_setting_rules: [],
      auto_generating_serial_number_setting_scope: null,
    },
    excise_settings: {
      tax_method: null,
      tax_input_format: null,
      rounding_method: null,
      can_edit_tax: null,
      automatic_journal_entry_accquisition_type: null,
      input_tax_when_register_fixed_asset: null,
    },
    operation_type: [],
    operation_target: [],
    asset_type: [],
    submit_destination_city_district: {},
  },
  loading: false,
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_MASTER_DATA_REQUEST:
      return state.set('loading', true)
    case FETCH_MASTER_DATA_SUCCESS:
      return state.set('loading', false).set('data', action.payload)
    default:
      return state
  }
}
