import { Role } from 'aa_common/front-end/models'

import base from './base'

export const list = () => base.get('/v1/roles')

export const getRolesDefault = () => base.get('/v1/roles/default')

export const getRoleDetail = (biid: string) => base.get(`/v1/roles/${biid}`)

export const createNewRole = (postData: Role) => base.post('/v1/roles', postData)

export const updateRole = (biid: string, postData: Role) => base.put(`/v1/roles/${biid}`, postData)

export const deleteRole = (biid: string) => base.delete(`/v1/roles/${biid}`)
