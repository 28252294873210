/* eslint-disable no-unsafe-optional-chaining */
const JP_CHAR_AT_BEFORE_LAST_CHAR_PATTERN = /[んっ][a-z]$/
const JP_COMBINATION_PATTERN = /[きぎしじちにひびぴみり][ゃゅょ]/ // combination words patters
const MIDDLE_ALPHABET_OF_JP_CHARACTER = ['h', 's'] // h and s are the middle character of JP characters: shi, chi and tsu

const isHiragana = (character: string | number) => {
  const characterCode = typeof character === 'string' ? character.charCodeAt(0) : character

  return (characterCode >= 12353 && characterCode <= 12435) || characterCode === 12445 || characterCode === 12446
}

const checkJPOfLastCharacter = (newValue: string, previousValue: string) => {
  const lastCharacter = newValue?.slice(newValue?.length - 1)

  if (!isHiragana(lastCharacter)) return false

  if (newValue?.length === previousValue?.length + 1) {
    return true
  }

  if (newValue?.length === previousValue?.length && newValue !== previousValue) {
    return true
  }

  if (newValue?.length === previousValue?.length - 1) {
    const lastPreviousCharacter = previousValue?.slice(previousValue?.length - 1)

    if (MIDDLE_ALPHABET_OF_JP_CHARACTER.includes(lastPreviousCharacter)) return true
  }

  return false
}

const checkJPOfBeforeLastCharacter = (newValue: string, previousValue: string) => {
  const lastPreviousCharacter = previousValue?.slice(previousValue?.length - 1)
  const secondLastCharacter = newValue?.slice(newValue?.length - 2)[0]
  const matchSpecialCase = newValue?.match(JP_CHAR_AT_BEFORE_LAST_CHAR_PATTERN)

  return !!(secondLastCharacter !== lastPreviousCharacter && matchSpecialCase)
}

const checkJPOfTwoLastCharacters = (newValue: string, previousValue: string) => {
  const twoLastPreviousCharacter = previousValue?.slice(previousValue?.length - 2)
  const twoLastNewCharacter = newValue?.slice(newValue?.length - 2)
  const matchOfNewCharacters = twoLastNewCharacter?.match(JP_COMBINATION_PATTERN)

  return !!(twoLastPreviousCharacter !== twoLastNewCharacter && matchOfNewCharacters)
}

export const findNewJPCharacters = (newValue: string, previousValue: string) => {
  if (checkJPOfTwoLastCharacters(newValue, previousValue)) {
    return newValue?.slice(newValue?.length - 2)
  }
  if (checkJPOfLastCharacter(newValue, previousValue)) {
    return newValue?.slice(newValue?.length - 1)
  }
  if (checkJPOfBeforeLastCharacter(newValue, previousValue)) {
    return newValue?.slice(newValue?.length - 2)[0]
  }

  return null
}

export const isDeleteAction = (newValue: string, previousValue: string) => {
  if (newValue?.length === previousValue?.length - 1 && previousValue?.indexOf(newValue) === 0) {
    const lastPreviousCharacter: string = previousValue?.slice(previousValue?.length - 1)

    if (!isHiragana(lastPreviousCharacter)) return true
  }

  return false
}

export const toKatakana = (value: string) => {
  let kanaString = ''

  for (let i = 0; i < value?.length; i++) {
    const character = value?.charCodeAt(i)
    if (isHiragana(character)) {
      kanaString += String.fromCharCode(character + 96)
    } else {
      kanaString += value?.charAt(i)
    }
  }

  return kanaString
}
