import { UnborderCollapse } from 'aa_common/front-end/components'
import { MoneyInput } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import get from 'lodash/get'
import React from 'react'

import { Group } from '../../events/styles'

const { Item } = UnborderCollapse

type Props = {
  values: any

  t: (key: string) => string
  contentItemWidth: number
  itemWidth: string
}

export const DisposalCostAndExcise: React.FC<Props> = ({ values, t, contentItemWidth, itemWidth }) => {
  return (
    <>
      <Item label={`${t('disposal_cost_included_tax')}`} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
        <Group>
          <MoneyInput name="disposal_cost" style={{ width: 240 }} />
        </Group>
      </Item>
      <Item label={t('disposal_cost_excise')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
        <Group>
          <ExciseSelect name="disposal_excise_id" style={{ width: 240 }} isDisabled={!get(values, 'disposal_cost')} />
        </Group>
      </Item>
    </>
  )
}
