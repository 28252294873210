import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-picker-range {
    width: 100%;
    border-radius: 4px;
    height: 28px;
    padding: 4px 8px;

    .ant-picker-input {
      & > input {
        text-align: center;
      }
    }

    &.error {
      border-color: #dc3545;
      background-color: #ffeeeb;
    }
  }
`
