import { Button } from 'aa_common/front-end/antd'
import { Table } from 'antd'
import i18n from 'i18n'
import uniqueId from 'lodash/uniqueId'
import React, { useMemo } from 'react'

import { Error, ErrorWrapper, Wrapper } from './style'

// 'right' as 'right' ref: https://github.com/ant-design/ant-design/issues/22535#issuecomment-602993345
const columns = [
  { title: 'name', width: 400, dataIndex: 'name' },
  { title: 'identification_code', width: 100, align: 'right' as const, dataIndex: 'identification_code' },
  { title: 'switch', width: 100, align: 'center' as const, render: (record: any) => record.loginButton },
]

type Props = {
  offices: any[]
  loading: boolean
  errors: any[]
  handleSwitch: (office: any) => void
}

const OfficeSelectTable = ({ offices, loading, errors, handleSwitch }: Props) => {
  const memoizedOffices = useMemo(() => {
    if (!offices || offices?.length === 0) return []

    return offices.map(officeItem => {
      return {
        ...officeItem,
        key: officeItem.id,
        identification_code: officeItem.identification_code.replace(/\d{4}(?=.)/g, '$&-'),
        loginButton: (
          <Button onClick={() => handleSwitch(officeItem)} color="grey" size="custom" style={{ padding: '5px 10px' }}>
            {i18n.t('components.OfficeSelectTable.switch')}
          </Button>
        ),
      }
    })
  }, [offices, handleSwitch])

  return (
    <Wrapper>
      {errors && (
        <ErrorWrapper>
          {errors.map((err: string) => {
            return <Error key={uniqueId()}>{err}</Error>
          })}
        </ErrorWrapper>
      )}
      <Table
        columns={columns}
        dataSource={memoizedOffices}
        loading={loading}
        showHeader={false}
        pagination={false}
        title={() => i18n.t('components.OfficeSelectTable.select_office')}
      />
    </Wrapper>
  )
}

export default OfficeSelectTable
