import React, { useRef } from 'react'
import { components, ControlProps } from 'react-select'

const Control = (props: React.PropsWithChildren<ControlProps<any, false, any>>) => {
  const { innerProps, innerRef, ...rest } = props
  const {
    selectProps: { classNamePrefix },
  } = rest
  const divRef = useRef<HTMLDivElement | null>(null)

  const HOVER_CLASSNAME = `${classNamePrefix}__control-is-hovering`

  const customInnerProps = {
    onMouseOver: () => {
      divRef.current?.classList.add(HOVER_CLASSNAME)
    },
    onMouseLeave: () => {
      divRef.current?.classList.remove(HOVER_CLASSNAME)
    },
    ...innerProps,
  }
  return (
    <components.Control
      innerRef={ref => {
        divRef.current = ref
        return innerRef
      }}
      innerProps={customInnerProps}
      {...rest}
    />
  )
}

export default Control
