import { messageFn } from 'aa_common/front-end'
import { API_STATUS_CODE, StatusJob } from 'common/constants'
import { parseValidationError } from 'common/helpers/response-helper'
import { AsyncjobService, DtoJobSwagger } from 'common/open-api'
import { parseErrorResponse } from 'common/utils/response-helper'
import { useCallback, useEffect, useRef, useState } from 'react'

import { fiboInterval, FiboIntervalReturn } from './fibo-interval'
import { longPollingSlice, removePollingByKey } from './store'

const DEFAULT_INTERVAL_TIME = 5000
const DEFAULT_RETRY = (60 * 60 * 1000) / DEFAULT_INTERVAL_TIME

type LongPollingOptionsProps = {
  intervalTime?: number
  retry?: number
  onError: (errorBiids: string[], errors?: any) => void
  onSuccess: (data?: any) => void
  isShowToastErrorMessage?: boolean
}

export const useLongPolling = (keyPolling: string, options?: LongPollingOptionsProps) => {
  const { intervalTime = DEFAULT_INTERVAL_TIME, retry = DEFAULT_RETRY, isShowToastErrorMessage = true } = options || {}
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<DtoJobSwagger | null | undefined>(null)
  const ref = useRef<{ poller?: FiboIntervalReturn; retryCount: number; exist: boolean }>({
    poller: undefined,
    retryCount: 0,
    exist: false,
  })
  const pollingState = longPollingSlice.useSlice()
  const deps = [keyPolling, pollingState[keyPolling], intervalTime, retry]

  const setJobId = (jobId: string) => {
    longPollingSlice.setState({ [keyPolling]: jobId })
  }

  const stopPolling = (removeKey = false) => {
    // console.log('stop polling, removeKey=', removeKey)
    setLoading(false)
    ref.current.poller?.clear()
    removeKey && removePollingByKey(keyPolling)
  }

  const fetchData = useCallback(async () => {
    if (!pollingState[keyPolling] || !ref.current.exist) {
      stopPolling()
      return
    }
    try {
      const response = (await AsyncjobService.getAsyncJob(pollingState[keyPolling])) as LongPollingData
      setData(response?.data)
      if (isJobCancel(response)) {
        stopPolling(true)
        if (response.data.status === 'FAILED') {
          const { unprocessableEntity, errors } = getErrorDetail(response?.data)
          if (!unprocessableEntity && isShowToastErrorMessage) {
            parseErrorResponse(errors)
          }
          options?.onError?.(getErrorBiids(response?.data), errors)
        }
        if (response.data.status === 'COMPLETED') {
          options?.onSuccess(response.data)
        }
      }
    } catch (error: any) {
      setLoading(false)
      if (ref.current.retryCount >= retry) {
        stopPolling(true)
      } else {
        messageFn().error(error.toString())
        ref.current.retryCount++
      }
    }
  }, [...deps])

  useEffect(() => {
    if (pollingState[keyPolling]) {
      setLoading(true)
      ref.current.poller?.clear()
      ref.current.poller = fiboInterval(fetchData, { maxDuration: intervalTime })
      ref.current.poller?.run()
    }
    ref.current.exist = true
    return () => {
      stopPolling()
      ref.current.exist = false
    }
  }, [...deps])

  return {
    loading,
    data,
    setJobId,
  }
}

const isJobCancel = (response: LongPollingData) => {
  return (
    !response ||
    [StatusJob.JobStatusCompleted, StatusJob.JobStatusFailed, StatusJob.JobStatusTimeOut].includes(
      response?.data?.status ?? ''
    )
  )
}

const getErrorBiids = (e: LongPollingData['data']) => e?.data?.errors?.[0]?.biids || []
export const getErrorDetail = (e: LongPollingData['data']) => {
  const err = e?.data?.errors?.[0]
  const errorData = {
    unprocessableEntity: false,
    errors: '',
  }
  if (err) {
    if (err?.status?.toString() === API_STATUS_CODE.UNPROCESSABLE_ENTITY.toString()) {
      errorData.errors = parseValidationError([err])
      errorData.unprocessableEntity = true
    } else {
      errorData.errors = e?.data?.errors?.[0]?.detail || ''
      errorData.unprocessableEntity = false
    }
  } else {
    errorData.errors = e?.data?.error || ''
  }

  return errorData
}

export type LongPollingData = {
  data: {
    id: string
    status: 'WAITING' | 'RUNNING' | 'FAILED' | 'COMPLETED' | 'TIMEOUT'
    office_id: number
    data: {
      errors?: [
        {
          biids: string[]
          code: string
          detail: string
          source: {}
          status: string
        }
      ]
      error?: string
    }
  }
}
