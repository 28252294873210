import history from 'browserHistory'
import { CONFIRM_TYPES } from 'common/constants'
import useModal from 'hooks/useModalHook'
import i18n from 'i18n'
import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Router } from 'react-router-dom'
import { clearJournalResult } from 'store/accounting/journal/actions'
import { selectSendJournalResult } from 'store/accounting/journal/selectors'
import { changeLocation, initAppStart } from 'store/app/actions'
import { selectInitialSuccess } from 'store/app/selectors'
import { getUserInfoSelector } from 'store/session/selectors'

import { masterDataSlice } from '../../share-store/master-data.store'
import Routes from './Routes'
import ZustandWatcher from './ZustandWatcher'

const App = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(getUserInfoSelector)
  const initialSuccess = useSelector(selectInitialSuccess)
  const sendJournalResult = useSelector(selectSendJournalResult)
  const modal = useModal()

  // After mounted
  useEffect(() => {
    dispatch(initAppStart())
    masterDataSlice.setFilters({}, true)
  }, [dispatch])
  const dataLayer = useMemo(() => {
    if (!userInfo) return null

    return (
      <Helmet>
        {/* For google tag management, using react-helmet to make sure it data layer is on head tag */}
        <script>
          {`window.dataLayer = window.dataLayer || [];
           window.dataLayer.push({
            user_id: '${userInfo.identification_code}',
            office_identification_code: '${userInfo.office_identification_code}',
            office_type: '2'
          });`}
        </script>
      </Helmet>
    )
  }, [userInfo])

  useEffect(() => {
    if (sendJournalResult?.failTotal > 0 && sendJournalResult?.historyId) {
      dispatch(clearJournalResult())
      modal
        .confirm({
          closable: true,
          type: CONFIRM_TYPES.CONFIRM,
          title: i18n.t('components.ErrorModal.title'),
          message: i18n.t('components.ErrorModal.content', { number: sendJournalResult?.failTotal }),
          okText: i18n.t('components.ErrorModal.ok'),
          cancelText: i18n.t('common.actions.close'),
        })
        .then(isOKClick => {
          if (isOKClick) {
            // Handle go to history fail page
            dispatch(
              changeLocation(`/accounting/monthly-closing/journals/histories/${sendJournalResult?.historyId}/detail`)
            )
          }
        })
    }
  }, [sendJournalResult, dispatch, modal])

  return (
    <>
      {initialSuccess && (
        <Router history={history}>
          <Routes />
        </Router>
      )}
      {dataLayer}
      <ZustandWatcher />
    </>
  )
}

export default App
