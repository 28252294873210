import { LEDGER_PURPOSE } from 'common/constants'
import { LedgerSettingsEditItem, LedgerSettingsItem } from 'common/models'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssetList } from 'store/asset/actions'
import { selectAssetsCollectionView } from 'store/asset/selectors'
import { selectMasterData } from 'store/masterData/selectors'

import { DEFAULT_TEMPLATE_LEDGER } from '../const'
import { ILedgerSettingsEditTable } from '../model'
import { convertSubmitPayload } from './helper'
import LedgerSettingsEditTableForm from './LedgerSettingsEditTableForm'

const initValues = {
  settings: [],
  submitPayload: [],
}

const LedgerSettingsEditTable = ({ data, loading, onSubmitForm }: ILedgerSettingsEditTable) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState<any>(initValues)

  const masterData = useSelector(selectMasterData, isEqual)
  const { totalRecords: assetTotalRecords } = useSelector(selectAssetsCollectionView, isEqual)

  useEffect(() => {
    const defaultTemplate = [DEFAULT_TEMPLATE_LEDGER]
    setFormValues({
      settings:
        data && data.length > 0
          ? Array.from(data).filter((item: LedgerSettingsEditItem) => !item.deleted)
          : defaultTemplate,
      submitPayload: data && data.length > 0 ? convertSubmitPayload(data) : defaultTemplate,
    })
  }, [data]) // eslint-disable-line

  useEffect(() => {
    dispatch(fetchAssetList({}, 1, 1))
  }, []) // eslint-disable-line

  const handleFormSubmit = useCallback(
    values => {
      if (values) {
        const actualPayload = values.submitPayload.map((item: LedgerSettingsEditItem) => {
          const updated = values.settings.find((setting: LedgerSettingsItem) => setting.key_id === item.key_id)
          return { ...item, ...updated }
        })
        // Handle reset value of tax ledger depreciation method follow account ledger depreciation method.
        const accountLedgerIndex = actualPayload?.findIndex(
          (setting: LedgerSettingsItem) => setting.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
        )
        const taxLedgerIndex = actualPayload?.findIndex(
          (setting: LedgerSettingsItem) => setting.ledger_purpose === LEDGER_PURPOSE.TAX
        )
        if (accountLedgerIndex !== -1 && taxLedgerIndex !== -1) {
          set(
            actualPayload[taxLedgerIndex],
            'retirement_depreciation_ended_method',
            actualPayload[accountLedgerIndex]?.retirement_depreciation_ended_method
          )
        }
        onSubmitForm && onSubmitForm(actualPayload)
      }
    },
    [onSubmitForm]
  )

  return (
    <LedgerSettingsEditTableForm
      masterData={masterData}
      loading={loading}
      values={formValues}
      onSubmit={handleFormSubmit}
      isEmptyAssetList={assetTotalRecords === null ? false : !assetTotalRecords}
    />
  )
}

export default LedgerSettingsEditTable
