import styled from '@emotion/styled'
import { Modal } from 'antd'

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-close {
    left: 0;
  }

  .ant-modal-close-x {
    height: 47px;
    line-height: 47px;
    width: 47px;
    font-size: 14px;

    .anticon {
      position: absolute;
      top: 17px;
      left: 15px;
    }
  }

  .ant-modal-header {
    background-color: #fafafa;
    color: rgba(51, 51, 51, 1);
    padding: 15px;
    border-radius: 4px 4px 0 0;

    .ant-modal-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      padding-left: 30px;
    }
  }

  .ant-modal-body {
    font-size: 13px;
    padding: 15px;
  }

  .ant-modal-footer {
    text-align: right;
  }
`
