import styled from '@emotion/styled/macro'

export const Wrapper = styled.div`
  background-color: none;

  .ant-tabs {
    border: 1px solid #d4d8dd;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-nav-wrap {
      border-bottom: 1px solid #d4d8dd;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 0px;
        margin-right: 0px;
        min-width: 130px;
        justify-content: center;

        &:first-of-type {
          margin-right: 0px;
        }

        .ant-tabs-tab-btn {
          color: #d4d8dd;
        }
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #333333;
        font-weight: 700;
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 10px;
  }
`
