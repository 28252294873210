/* eslint-disable react/no-danger */
import { CaretDownOutlined } from '@ant-design/icons'
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Select } from 'antd'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { Wrapper, WrapperList } from './styles'

const { Option } = Select
interface AutoCompleteSelectProps {
  name: string
  items?: any[]
  valueField?: string
  textField?: string
  placeholder?: string
  wrapperStyle?: React.CSSProperties
  style?: React.CSSProperties
  disabled?: boolean
  hasError?: boolean
  allowClear?: boolean
  allowSearch?: boolean
  isShowError?: boolean
  afterChanged?: (value?: string) => void
  childrenNode?: ReactNode
  className?: string
}

const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({
  name,
  items = [],
  valueField = 'value',
  textField = 'text',
  placeholder,
  style,
  wrapperStyle,
  disabled = false,
  hasError = false,
  allowClear = true,
  allowSearch = true,
  isShowError = true,
  childrenNode,
  afterChanged,
  className,
}) => {
  const { errors, values, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name))
  const fieldValue = getIn(values, name)
  const refContainer = useRef(null)
  const refSelect = useRef<HTMLInputElement>(null)

  let isInValid = null

  if (get(errors, name)) {
    isInValid = true
  }

  const onChange = (value: any) => {
    if (value === undefined) {
      // when click clear icon
      setValue(null)
      setFieldValue(name, null)
      afterChanged && afterChanged(undefined)
      refSelect.current && refSelect.current.focus()
    } else {
      setValue(value)
      setFieldValue(name, value)
      afterChanged && afterChanged(value)
    }
  }

  const getValue = (value: any) => {
    if (value === 0 && items) {
      const zeroItem = items.find(item => item[valueField] === 0)
      if (zeroItem) {
        return zeroItem[textField]
      }
      return null
    }
    const item = items && items.find(item => item[valueField] === value)
    if (item) {
      return value
    }
    return ''
  }

  const dropdownRender = useCallback(
    (menu: React.ReactElement) => {
      return (
        <>
          {menu}
          {childrenNode}
        </>
      )
    },
    [childrenNode]
  )

  useEffect(() => {
    setValue(fieldValue)
  }, [fieldValue])
  return (
    <Wrapper ref={refContainer} style={wrapperStyle}>
      <Select
        ref={refSelect}
        showSearch={allowSearch}
        value={getValue(value)}
        dropdownClassName={WrapperList}
        placeholder={placeholder}
        style={style}
        allowClear={value && allowClear}
        dropdownMatchSelectWidth={false}
        filterOption={(input, option) =>
          `${option?.props?.children || ''}`.toLowerCase().includes(`${input}`.toLowerCase())
        }
        onChange={onChange}
        suffixIcon={<CaretDownOutlined className="ant-select-suffix" />}
        className={classNames(className, { error: isInValid || hasError })}
        disabled={disabled}
        getPopupContainer={() => refContainer.current as any}
        dropdownRender={dropdownRender}
      >
        {items &&
          items.map(item => (
            <Option key={item[valueField]} value={item[valueField]}>
              {getIn(item, textField)}
            </Option>
          ))}
      </Select>
      {isShowError && isInValid && (
        <CommonErrorMsg>
          <div data-testid={`error-${name}`} dangerouslySetInnerHTML={{ __html: get(errors, name) }} />
        </CommonErrorMsg>
      )}
    </Wrapper>
  )
}

export default AutoCompleteSelect
