import * as exciseApi from 'api/app/excise'
import { AxiosResponse } from 'axios'
import { parseError } from 'common/helpers'
import { call, put, takeLatest } from 'redux-saga/effects'
import { showErrorsAppCall } from 'store/app/actions'

import { FetchExciseListRequest, GetExciseVatInfoRequest, UpdateExciseListItemRequest } from './action.model'
import { fetchExciseListFailure, fetchExciseListSuccess } from './actions'
import {
  FETCH_EXCISE_LIST_REQUEST,
  FETCH_EXCISE_LIST_REQUEST_AUTH,
  FETCH_EXCISE_RATES_FAILURE,
  FETCH_EXCISE_RATES_REQUEST,
  FETCH_EXCISE_RATES_SUCCESS,
  FETCH_EXCISE_SETTINGS_BY_DATE_FAILURE,
  FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST,
  FETCH_EXCISE_SETTINGS_BY_DATE_SUCCESS,
  GET_EXCISE_VAT_INFO_FAILURE,
  GET_EXCISE_VAT_INFO_REQUEST,
  GET_EXCISE_VAT_INFO_SUCCESS,
  UPDATE_EXCISE_LIST_ITEM_FAILURE,
  UPDATE_EXCISE_LIST_ITEM_REQUEST,
  UPDATE_EXCISE_LIST_ITEM_SUCCESS,
} from './constant'

export function* fetchExciseList({ payload }: FetchExciseListRequest) {
  const { data, errors } = yield call(exciseApi.search, payload)
  if (data) {
    yield put(fetchExciseListSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchExciseListFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchExciseListAuth({ payload }: any) {
  const { data, errors } = yield call(exciseApi.searchAuth, payload)
  if (data) {
    yield put(fetchExciseListSuccess(data?.data || []))
  } else if (errors) {
    yield put(fetchExciseListFailure())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchExciseRates() {
  try {
    const response: AxiosResponse = yield call(exciseApi.getExciseRates)
    yield put({ type: FETCH_EXCISE_RATES_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put({ type: FETCH_EXCISE_RATES_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* updateExciseListItem({ id, updatedData }: UpdateExciseListItemRequest) {
  try {
    const response: AxiosResponse = yield call(exciseApi.updateListItem, id, updatedData)
    yield put({ type: UPDATE_EXCISE_LIST_ITEM_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put({ type: UPDATE_EXCISE_LIST_ITEM_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* getExciseVatInfoRequest({ at, excise_id, input_value }: GetExciseVatInfoRequest) {
  try {
    const response: AxiosResponse = yield call(exciseApi.getExciseVatInfo, at, excise_id, input_value)
    yield put({ type: GET_EXCISE_VAT_INFO_SUCCESS, payload: response.data.data })
  } catch (error) {
    yield put({ type: GET_EXCISE_VAT_INFO_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchExciseSettingsByDateRequest({ date, meta }: any) {
  try {
    const response: AxiosResponse = yield call(exciseApi.getExciseSettingsByDate, date)

    yield put({ type: FETCH_EXCISE_SETTINGS_BY_DATE_SUCCESS, payload: response.data.data, meta })
  } catch (error) {
    yield put({ type: FETCH_EXCISE_SETTINGS_BY_DATE_FAILURE, meta })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_EXCISE_LIST_REQUEST, fetchExciseList)
  yield takeLatest(FETCH_EXCISE_LIST_REQUEST_AUTH, fetchExciseListAuth)
  yield takeLatest(FETCH_EXCISE_RATES_REQUEST, fetchExciseRates)
  yield takeLatest(GET_EXCISE_VAT_INFO_REQUEST, getExciseVatInfoRequest)
  yield takeLatest(FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST, fetchExciseSettingsByDateRequest)
  yield takeLatest(UPDATE_EXCISE_LIST_ITEM_REQUEST, updateExciseListItem)
}
