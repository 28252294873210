import { AssetCodeSettingRule, AssetCodeSettings, ExciseSettings } from 'common/models'
import { get } from 'lodash'

import { initialState } from './reducer'

const parseDataForAssetCodeSettings = (data: any[]) => {
  const result: AssetCodeSettings = {
    auto_generating_serial_number_setting_method:
      data.find((dataItem: any) => dataItem.name === 'auto_generating_serial_number_setting_method') || null,
    auto_generating_serial_number_setting_length:
      data.find((dataItem: any) => dataItem.name === 'auto_generating_serial_number_setting_length') || null,
    auto_generating_serial_number_setting_scope:
      data.find((dataItem: any) => dataItem.name === 'auto_generating_serial_number_setting_scope') || null,
    auto_generating_serial_number_setting_field:
      data.find((dataItem: any) => dataItem.name === 'auto_generating_serial_number_setting_field') || null,
    auto_generating_serial_number_setting_rules: [],
  }

  if (result.auto_generating_serial_number_setting_field) {
    const rules: AssetCodeSettingRule[] = []

    result.auto_generating_serial_number_setting_field.values.forEach((settingField: any) => {
      const ruleItem = data.find((dataItem: any) => dataItem.name === settingField.key)
      rules.push({
        ...settingField,
        values: get(ruleItem, 'values'),
      })
    })

    result.auto_generating_serial_number_setting_rules = rules
  }

  return result
}

const parseDataForExciseSettings = (data: any[]) => {
  const result: ExciseSettings = {
    tax_method: data.find((dataItem: any) => dataItem.name === 'tax_method') || null,
    tax_input_format: data.find((dataItem: any) => dataItem.name === 'tax_input_format') || null,
    rounding_method: data.find((dataItem: any) => dataItem.name === 'rounding_method') || null,
    can_edit_tax: data.find((dataItem: any) => dataItem.name === 'can_edit_tax') || null,
    automatic_journal_entry_accquisition_type:
      data.find((dataItem: any) => dataItem.name === 'automatic_journal_entry_accquisition_type') || null,
    input_tax_when_register_fixed_asset:
      data.find((dataItem: any) => dataItem.name === 'input_tax_when_register_fixed_asset') || null,
  }

  return result
}

export const parseDataMaster = (data: any[] = []) => {
  const result = (initialState.toJS() as any).data

  data.forEach((item: any) => {
    if (item.name in result && Array.isArray(item.values)) {
      result[item.name] = item.values
    }
    if (item.name === 'submit_destination_city_district') {
      result[item.name] = item
    }
  })

  result.asset_code_settings = parseDataForAssetCodeSettings(data)
  result.excise_settings = parseDataForExciseSettings(data)

  return result
}
