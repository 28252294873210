import styled from '@emotion/styled'
import { css } from '@emotion/css'

import { ComplexTable } from 'aa_common/front-end/antd'

export const ExpandClass = css`
  color: #7c8291;
  cursor: pointer;
  font-size: 18px;
  vertical-align: middle;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`

export const Table = styled(ComplexTable)`
  .ant-table-row {
    &.ant-table-row-level-0 {
      cursor: pointer;
    }
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    font-weight: 800;
  }
`
