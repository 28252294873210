import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-input-number {
    width: 100%;
    border-radius: 4px;
    height: 30px;

    input {
      box-sizing: border-box;
      border-radius: 4px;
      padding: 7px 8px;
      font-size: 13px;
      height: 28px;
    }

    &.error {
      border-color: #dc3545;
      background-color: #ffeeeb !important;
    }
  }
`
