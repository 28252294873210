/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterMonthlyClosingHistoryResponse,
  PresenterOneFixedAssetHistoryResponse,
  PresenterOneJournalResponse,
  PresenterOneMonthlyClosingResponse,
  PresenterOneTermMonthResponse,
  PresenterReopenMonthlyClosingRequest,
  PresenterReopenMonthlyClosingResponse,
  PresenterReselectMonthlyClosingRequest,
  PresenterResponse,
  PresenterSummaryFixedAssetEvent,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MonthlyClosing<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List monthly closing
   *
   * @tags monthly_closing
   * @name MonthlyClosingList
   * @summary List the monthly closing
   * @request GET:/monthly_closing
   */
  monthlyClosingList = (
    query?: {
      'filter[from_term_month_id]'?: number | string
      'filter[monthly_closing_setting_id]'?: number | string
      'filter[status]'?: 1 | 2
      'filter[to_term_month_id]'?: number | string
      sort?: string[]
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterOneMonthlyClosingResponse[]
      },
      PresenterError
    >({
      path: `/monthly_closing`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCurrent monthly closing
   *
   * @tags monthly_closing
   * @name CurrentList
   * @summary GetCurrent the monthly closing
   * @request GET:/monthly_closing/current
   */
  currentList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneMonthlyClosingResponse, PresenterError>({
      path: `/monthly_closing/current`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Close the current monthly closing
   *
   * @tags monthly_closing
   * @name CurrentUpdate
   * @summary Close the current monthly closing
   * @request PUT:/monthly_closing/current
   */
  currentUpdate = (params: RequestParams = {}) =>
    this.http.request<PresenterOneMonthlyClosingResponse, PresenterError>({
      path: `/monthly_closing/current`,
      method: 'PUT',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetEventsByMonthlyClosing get histories of fixed asset by monthly closing
   *
   * @tags monthly_closing
   * @name FixedAssetEventsList
   * @summary GetEventsByMonthlyClosing get histories of fixed asset by monthly closing
   * @request GET:/monthly_closing/fixed_asset_events
   */
  fixedAssetEventsList = (
    query: {
      change_situation_code: 10 | 11 | 20 | 30 | 40 | 50 | 53 | 60 | 90 | 99
      is_notlinked?: boolean
      is_occurred_in_the_past?: boolean
      monthly_closing_id: number | string
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetHistoryResponse[], PresenterError>({
      path: `/monthly_closing/fixed_asset_events`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description get monthly closing history of current office
   *
   * @tags monthly_closing
   * @name HistoryList
   * @summary Get monthly closing history
   * @request GET:/monthly_closing/history
   */
  historyList = (params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterMonthlyClosingHistoryResponse[]
      },
      PresenterError
    >({
      path: `/monthly_closing/history`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get jounals in the current MonthlyClosing
   *
   * @tags monthly_closing
   * @name JournalsList
   * @summary Get jounals in the current MonthlyClosing
   * @request GET:/monthly_closing/journals
   */
  journalsList = (params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterOneJournalResponse[]
      },
      any
    >({
      path: `/monthly_closing/journals`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description reopen monthly closing
   *
   * @tags monthly_closing
   * @name ReopenCreate
   * @summary Reopen monthly closing
   * @request POST:/monthly_closing/reopen
   */
  reopenCreate = (body: PresenterReopenMonthlyClosingRequest, params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterReopenMonthlyClosingResponse
      },
      PresenterError
    >({
      path: `/monthly_closing/reopen`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description reselect monthly closing
   *
   * @tags monthly_closing
   * @name ReselectUpdate
   * @summary Reselect monthly closing
   * @request PUT:/monthly_closing/reselect
   */
  reselectUpdate = (body: PresenterReselectMonthlyClosingRequest, params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterOneMonthlyClosingResponse[]
      },
      PresenterError
    >({
      path: `/monthly_closing/reselect`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get term for reselect monthly closing
   *
   * @tags monthly_closing
   * @name ReselectGetTermMonthList
   * @summary Get term for reselect monthly closing
   * @request GET:/monthly_closing/reselect/get_term_month
   */
  reselectGetTermMonthList = (params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterOneTermMonthResponse[]
      },
      any
    >({
      path: `/monthly_closing/reselect/get_term_month`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get summary fixed asset event for monthly closing
   *
   * @tags monthly_closing
   * @name SummaryFixedAssetEventsDetail
   * @summary Get summary fixed asset event for monthly closing
   * @request GET:/monthly_closing/summary_fixed_asset_events/{id}
   */
  summaryFixedAssetEventsDetail = (id: number | string, params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterSummaryFixedAssetEvent[]
      },
      PresenterError
    >({
      path: `/monthly_closing/summary_fixed_asset_events/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
