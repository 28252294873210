export const FETCH_JOURNALS_REQUEST = 'FETCH_JOURNALS_REQUEST'
export const FETCH_JOURNALS_SUCCESS = 'FETCH_JOURNALS_SUCCESS'
export const FETCH_JOURNALS_FAILURE = 'FETCH_JOURNALS_FAILURE'

export const FETCH_JOURNALS_REPORT_REQUEST = 'FETCH_JOURNALS_REPORT_REQUEST'
export const SET_TOTAL_JOURNALS = 'SET_TOTAL_JOURNALS'
export const FETCH_COUNT_OF_JOURNAL_EVENTS = 'FETCH_COUNTS_OF_JOURNALS_EVENT'
export const HANDLE_FETCH_COUNT_OF_JOURNAL_EVENTS = 'HANDLE_FETCH_COUNTS_OF_JOURNALS_EVENT'

export const FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST = 'FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST'
export const FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_SUCCESS = 'FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_SUCCESS'
export const FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_FAILURE = 'FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_FAILURE'

export const FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST =
  'FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST'
export const FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_SUCCESS =
  'FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_SUCCESS'
export const FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_FAILURE =
  'FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_FAILURE'
export const CLEAR_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS = 'CLEAR_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS'

export const DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST = 'DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST'
export const DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_SUCCESS = 'DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_SUCCESS'
export const DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_FAILURE = 'DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_FAILURE'

export const SAVE_JOURNAL_ITEM_SETTINGS_REQUEST = 'SAVE_JOURNAL_ITEM_SETTINGS_REQUEST'
export const UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST = 'UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST'
export const SAVE_JOURNAL_ITEM_SETTINGS_SUCCESS = 'SAVE_JOURNAL_ITEM_SETTINGS_SUCCESS'
export const SAVE_JOURNAL_ITEM_SETTINGS_FAILURE = 'SAVE_JOURNAL_ITEM_SETTINGS_FAILURE'

export const FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST = 'FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST'
export const FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_SUCCESS = 'FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_SUCCESS'
export const FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_FAILURE = 'FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_FAILURE'

export const SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST = 'SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST'
export const UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST = 'UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST'
export const SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS = 'SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS'
export const SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE = 'SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE'

export const DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST = 'DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST'
export const DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS = 'DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS'
export const DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE = 'DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE'

export const CLEANUP_JOURNAL_SETTINGS = 'CLEANUP_JOURNAL_SETTINGS'

export const EXPORT_JOURNAL_REQUEST = 'EXPORT_JOURNAL_REQUEST'
export const EXPORT_JOURNAL_SUCCESS = 'EXPORT_JOURNAL_SUCCESS'
export const EXPORT_JOURNAL_FAILURE = 'EXPORT_JOURNAL_FAILURE'

export const FETCH_JOURNAL_LINKAGE_STATUS_REQUEST = 'FETCH_JOURNAL_LINKAGE_STATUS_REQUEST'
export const FETCH_JOURNAL_LINKAGE_STATUS_SUCCESS = 'FETCH_JOURNAL_LINKAGE_STATUS_SUCCESS'
export const FETCH_JOURNAL_LINKAGE_STATUS_FAIL = 'FETCH_JOURNAL_LINKAGE_STATUS_FAIL'

export const SEND_JOURNAL_REQUEST = 'SEND_JOURNAL_REQUEST'
export const SEND_JOURNAL_DONE = 'SEND_JOURNAL_DONE'
export const SEND_JOURNAL_FAIL_PARTIAL = 'SEND_JOURNAL_FAIL_PARTIAL'
export const CLEAR_SEND_JOURNAL_RESULT = 'CLEAR_SEND_JOURNAL_RESULT'

export const EXPORT_JOURNAL_LINKAGE_REQUEST = 'EXPORT_JOURNAL_LINKAGE_REQUEST'
export const EXPORT_JOURNAL_LINKAGE_SUCCESS = 'EXPORT_JOURNAL_LINKAGE_SUCCESS'
export const EXPORT_JOURNAL_LINKAGE_FAILURE = 'EXPORT_JOURNAL_LINKAGE_FAILURE'

export const FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST = 'FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST'
export const FETCH_JOURNAL_LINKAGE_DETAIL_SUCCESS = 'FETCH_JOURNAL_LINKAGE_DETAIL_SUCCESS'
export const FETCH_JOURNAL_LINKAGE_DETAIL_FAILURE = 'FETCH_JOURNAL_LINKAGE_DETAIL_FAILURE'
