/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterExciseSettingResponse,
  PresenterIDResponse,
  PresenterSaveExciseSettingRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class ExciseSettings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List excise setting
   *
   * @tags excise_settings
   * @name ExciseSettingsList
   * @summary List the excise setting
   * @request GET:/excise_settings
   */
  exciseSettingsList = (params: RequestParams = {}) =>
    this.http.request<PresenterExciseSettingResponse[], PresenterError>({
      path: `/excise_settings`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Save excise setting
   *
   * @tags excise_settings
   * @name ExciseSettingsCreate
   * @summary Save the excise setting
   * @request POST:/excise_settings
   */
  exciseSettingsCreate = (body: PresenterSaveExciseSettingRequest, params: RequestParams = {}) =>
    this.http.request<PresenterExciseSettingResponse, PresenterError>({
      path: `/excise_settings`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the excise setting by date
   *
   * @tags excise_settings
   * @name ByDateList
   * @summary Get the excise setting
   * @request GET:/excise_settings/by_date
   */
  byDateList = (
    query: {
      /** find the excise setting base on the date */
      date: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterExciseSettingResponse, PresenterError>({
      path: `/excise_settings/by_date`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete excise setting
   *
   * @tags excise_settings
   * @name ExciseSettingsDelete
   * @summary Delete the excise setting
   * @request DELETE:/excise_settings/{id}
   */
  exciseSettingsDelete = (
    id: number | string,
    query?: {
      /** dry_run enable to check whether you can delete ExciseSetting or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/excise_settings/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
