import { RequiredLabel } from 'aa_common/front-end/components'
import React from 'react'

import { ItemContent, ItemLabel, ItemWrapper } from './styles'

type Props = {
  label: string
  contentWidth?: number
  required?: boolean
}

const Item: React.FC<Props> = ({ children, label, contentWidth = 400, required = false }) => {
  return (
    <ItemWrapper className="item">
      <ItemLabel>{label}</ItemLabel>
      <ItemContent style={{ width: contentWidth }}>
        {children}
        {required && <RequiredLabel className="required" />}
      </ItemContent>
    </ItemWrapper>
  )
}

export default Item
