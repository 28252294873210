import { messageFn } from 'aa_common/front-end/antd'
import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { PresenterTaxDeclarationResponse, ReportsService } from 'common/open-api'
import produce from 'immer'

type State = {
  reports?: (PresenterTaxDeclarationResponse & { isSelected?: boolean })[]
}

type SelectedItem = { submitDestinationId: number; isSelected: boolean }

const initFilter: { year?: number | string } = {}
const initState: State = {
  reports: [],
}

// @ts-ignore
const fetchData: FilterFunction<typeof initState, typeof initFilter> = async filters => {
  try {
    const year = filters?.year
    if (!year) {
      return { data: { reports: [] } }
    }

    const reports = (await ReportsService.taxDeclarationList({ year }))?.data
    return { data: { reports } }
  } catch (e) {
    messageFn().error(e)
  }
}

export const depreciationFixedAssetSlice = initAsyncSlice('tax-declaration/export', initState, initFilter, fetchData)

export const markAll = (status: boolean) => {
  depreciationFixedAssetSlice.setData((data: State) => {
    const reports = data?.reports?.map(report => ({ ...report, isSelected: status }))
    return produce(data, (draft: State) => {
      draft.reports = reports
    })
  })
}

export const setSelectedItem = (selectedItem: SelectedItem) => {
  depreciationFixedAssetSlice.setData((data: State) => {
    const reports = data?.reports?.map(report => {
      if (report.submit_destination_id === selectedItem.submitDestinationId) {
        return { ...report, isSelected: selectedItem.isSelected }
      }
      return report
    })

    return produce(data, (draft: State) => {
      draft.reports = reports
    })
  })
}
