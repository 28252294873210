import * as exciseSettingApi from 'api/app/exciseSetting'
import { AxiosResponse } from 'axios'
import { API_STATUS_CODE } from 'common/constants'
import { parseError, parseValidationError } from 'common/helpers'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { setAppMessage, showErrorsAppCall } from 'store/app/actions'

import { fetchExciseSettingFailure, fetchExciseSettingSuccess } from './actions'
import {
  CREATE_EXCISE_SETTING_FAILURE,
  CREATE_EXCISE_SETTING_REQUEST,
  CREATE_EXCISE_SETTING_SUCCESS,
  DELETE_EXCISE_SETTING_FAILURE,
  DELETE_EXCISE_SETTING_REQUEST,
  DELETE_EXCISE_SETTING_SUCCESS,
  FETCH_EXCISE_SETTING_REQUEST,
  FETCH_EXCISE_SETTING_REQUEST_AUTH,
} from './constant'

function* fetchExciseSetting() {
  const { data, errors } = yield call(exciseSettingApi.getExciseSettings)
  if (data) {
    yield put(fetchExciseSettingSuccess(data?.data || null))
  } else if (errors) {
    yield put(fetchExciseSettingFailure())
    yield put(showErrorsAppCall(errors))
  }
}

function* fetchExciseSettingAuth() {
  const { data, errors } = yield call(exciseSettingApi.getExciseSettingsAuth)
  if (data) {
    yield put(fetchExciseSettingSuccess(data?.data || null))
  } else if (errors) {
    yield put(fetchExciseSettingFailure())
    yield put(showErrorsAppCall(errors))
  }
}

function* createExciseSetting({ payload, meta }: any) {
  try {
    const response: AxiosResponse = yield call(exciseSettingApi.createExciseSetting, payload)

    yield put({ type: CREATE_EXCISE_SETTING_SUCCESS, payload: response.data.data, meta })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
    yield put({ type: FETCH_EXCISE_SETTING_REQUEST })
  } catch (error) {
    const status = get(error, 'response.status')
    yield put({
      meta,
      error: true,
      type: CREATE_EXCISE_SETTING_FAILURE,
      payload:
        status !== API_STATUS_CODE.UNPROCESSABLE_ENTITY
          ? null
          : parseValidationError(get(error, 'response.data.errors', undefined)),
    })

    if (status !== API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

function* deleteExciseSetting({ payload }: any) {
  try {
    yield call(exciseSettingApi.deleteExciseSetting, payload)
    yield put({ type: DELETE_EXCISE_SETTING_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
    yield put({ type: FETCH_EXCISE_SETTING_REQUEST })
  } catch (error) {
    yield put({ type: DELETE_EXCISE_SETTING_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_EXCISE_SETTING_REQUEST, fetchExciseSetting)
  yield takeLatest(FETCH_EXCISE_SETTING_REQUEST_AUTH, fetchExciseSettingAuth)
  yield takeLatest(CREATE_EXCISE_SETTING_REQUEST, createExciseSetting)
  yield takeLatest(DELETE_EXCISE_SETTING_REQUEST, deleteExciseSetting)
}
