import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { Wrapper } from './styles'

type Props = {
  id: string
  containerId?: string
  additionWidth?: number
  style?: React.CSSProperties
}

export const BottomAction: React.FC<Props> = ({ children, id, containerId, additionWidth, style }) => {
  const resizeHandleDebounce = useDebouncedCallback((id: string, containerId?: string, additionWidth?: number) => {
    const containerWidth = document.getElementById(containerId || 'app-main-body')?.offsetWidth || 0
    const bottomSubmitEl = document.getElementById(id) as HTMLElement
    // TODO: Please keep the old code for backward compatibility if there occurs any issues;
    // bottomSubmitEl.style.width = containerWidth + 15 + (additionWidth || 0) + 'px';
    if (bottomSubmitEl) {
      bottomSubmitEl.style.width = `${containerWidth + (additionWidth || 0)}px`
    }
  }, 50)

  const observer = useRef<ResizeObserver>(
    new ResizeObserver(() => {
      resizeHandleDebounce(id, containerId, additionWidth)
    })
  ).current

  useLayoutEffect(() => {
    observer.observe(document.getElementById(containerId || 'app-main-body') as HTMLElement)
  }, [observer, containerId, id])

  useEffect(() => {
    return function cleanup() {
      observer.disconnect()
    }
  }, [observer])

  return (
    <Wrapper id={id} style={style}>
      {children}
    </Wrapper>
  )
}
