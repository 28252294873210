import { Select } from 'antd'
import { getIn, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'

const { Option } = Select

type Props = {
  name: string
  items?: any[]
  valueField?: string
  textField?: string
  placeholder?: string
  style?: React.CSSProperties
}

const MultipleSelectField: React.FC<Props> = ({
  name,
  items = [],
  valueField = 'value',
  textField = 'text',
  placeholder,
  style,
}) => {
  const { values, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name) || [])
  const fieldValue = getIn(values, name)

  const onChange = (value: any) => {
    setValue(value)
    setFieldValue(name, value)
  }

  useEffect(() => {
    setValue(fieldValue)
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper>
      <Select
        value={value || []}
        placeholder={placeholder}
        style={style}
        allowClear
        onChange={onChange}
        mode="multiple"
      >
        {items.map(item => (
          <Option key={item[valueField]} value={item[valueField]}>
            {item[textField]}
          </Option>
        ))}
      </Select>
    </Wrapper>
  )
}

export default MultipleSelectField
