import styled from '@emotion/styled'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent, CheckboxProps } from 'antd/es/checkbox'
import React from 'react'

type FormCheckBoxProps = CheckboxProps & {
  value?: boolean
  addOnBefore?: React.ReactNode
  addOnAfter?: React.ReactNode
}

export const FormCheckBox = ({ value, addOnBefore, addOnAfter, onChange, ...props }: FormCheckBoxProps) => {
  return (
    <FormCheckBoxStyle>
      {addOnBefore}
      <Checkbox checked={value} onChange={(e: CheckboxChangeEvent) => onChange?.(e.target.checked as any)} {...props} />
      {addOnAfter}
    </FormCheckBoxStyle>
  )
}

const FormCheckBoxStyle = styled.div`
  display: flex;
  align-items: center;
  .label {
    margin-left: 5px;
  }
`
