import { CSRF_TOKEN_KEY, FE_ERROR_403_PATH, FE_SIGNIN_PATH, FEATURES_ID, PERMISSIONS, Require } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers/permission-helper'
import getObject from 'common/helpers/recursive-helper'
import { navItems, SideNavItem } from 'components/organisms/MainMenu/settings'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { changeLocation } from 'store/app/actions'
import { fetchCurrentOffice, getUserInfo } from 'store/session/actions'
import { getUserInfoSelector, selectUserPermission } from 'store/session/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'

const checkPermission = (path: string, userPermissions: any, require?: Require) => {
  let selectedNavItem = null
  const selectedKey = path && path.replace('/', '').split('/').splice(0, 2).join('.')
  selectedNavItem = getObject(navItems, 'key', selectedKey) as SideNavItem
  if (!selectedNavItem) {
    selectedNavItem = getObject(navItems, 'key', selectedKey.split('.')[0]) as SideNavItem
  }
  const featureId = selectedNavItem && selectedNavItem.feature_id

  if (userPermissions && featureId) {
    if (featureId === FEATURES_ID.SETTING_ONBOARDING && userPermissions[featureId] > 0) return true
    if (
      featureId !== FEATURES_ID.SETTING_RECALCULATE &&
      (userPermissions[featureId] === 0 || userPermissions[featureId] === PERMISSIONS.EXECUTE)
    )
      return false

    const permission = handlePermissionByFeatureId(featureId as number, userPermissions)
    if (require) return permission[require]
    if (path.match(/new/g) || path.match(/copy/g)) return permission.isCreate
    if (path.match(/edit/g)) return permission.isEdit
  }

  return true
}

type PropsType = { require?: Require } & RouteProps
const PrivateRoute: React.FC<PropsType> = ({ require, ...props }) => {
  const csrfToken = localStorage.getItem(CSRF_TOKEN_KEY)

  const userInfo = useSelector(getUserInfoSelector)
  const { data: userPermissions } = useSelector(selectUserPermission)
  const dispatch = useDispatch()

  const { path }: any = props

  const isValidPermission = useMemo(() => {
    return checkPermission(path, userPermissions, require)
  }, [path, userPermissions, require])

  useEffect(() => {
    if (!csrfToken) return

    !userInfo && dispatch(getUserInfo())

    dispatch(fetchCurrentOffice())
    dispatch(fetchTerms())
  }, [dispatch, csrfToken, userInfo])

  const termsLength = useSelector(selectTermsList, isEqual)?.data?.length
  useEffect(() => {
    if (termsLength === 0) {
      dispatch(changeLocation('/accounting-periods/create'))
    }
  }, [termsLength, dispatch])

  if (csrfToken) {
    return isValidPermission ? <Route {...props} /> : <Redirect to={FE_ERROR_403_PATH} />
  }
  return <Redirect to={FE_SIGNIN_PATH} />
}

export default PrivateRoute
