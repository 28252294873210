import { ASSET_EVENT_FIELD } from 'common/constants'
import { formatDate, formatDateTime, objectToQueryParams, renderAssetFields, yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetReport } from 'common/models'
import { FileLink, PhotoView, ReferenceLink } from 'components/atoms'
import { get } from 'lodash'
import { assetEventListReportSlice } from 'pages/accounting/AssetEventListReportPage/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayDataRow } from '../styles'
import { Row } from './row'
import { SharedHeaderView } from './shared-event-view'

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const SaleEventView = ({ assets }: Props) => {
  const dispatch = useDispatch()

  const handleAssetClick = (asset: AssetReport) => {
    const paramObj = {
      valid_at: asset.sold_at,
      type: ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid || ''}${params}`))
  }

  if (!assets) return null
  return (
    <>
      {assets.map(asset => (
        <SaleEvent key={asset.biid} asset={asset} onAssetClick={handleAssetClick} />
      ))}
    </>
  )
}

const SaleEvent = ({ asset, onAssetClick }: SaleEventProps) => {
  const { isDetailMode, selectedItem } = assetEventListReportSlice.useSlice(state => ({
    isDetailMode: state?.data?.isDetailMode,
    selectedItem: state?.data?.selectedItem,
  }))

  return (
    <AssetDisplay onClick={() => onAssetClick(asset)}>
      <SharedHeaderView
        asset={asset}
        selectedItem={selectedItem}
        dataTestId="checkbox-sale"
        isDetailMode={isDetailMode}
      />
      {isDetailMode && (
        <div data-testid="sale-detailmode">
          <AssetDisplayDataRow>
            <div className="short-label">{asset.valid_from}</div>
            <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
            <div className="label text-color-666">{t('sale_at')}</div>
            <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
          </AssetDisplayDataRow>
          <Row label={t('sale_reason')} value={asset?.event_cause?.content} />
          <Row label={t('acquisition_date')} value={formatDate(asset.acquired_at) as string} />
          <Row label={t('service_start_date')} value={formatDate(asset.usage_started_at) as string} />
          <Row
            label={
              get(asset, 'acquisition_cost_excluded_excise') === get(asset, 'acquisition_cost')
                ? t('acquisition_cost_exclude')
                : t('acquisition_cost_include')
            }
            value={yen(asset.acquisition_cost)}
          />
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_depreciation')}</div>
            <div className="value">
              {renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION)}
            </div>
          </AssetDisplayDataRow>
          <Row label={t('accumulated_impairment')} value={yen(asset.accumulated_impaired_amount)} />
          <Row label={t('sale_price')} value={yen(asset.sold_amount as number)} />
          <Row label={t('disposal_cost')} value={yen(asset.disposal_cost)} />
          <AssetDisplayDataRow>
            <div className="label">{t('loss_and_gain')}</div>
            <div className="value">{renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.LOSS_AND_GAIN)}</div>
          </AssetDisplayDataRow>
          <Row label={t('department')} value={get(asset.department, 'name')} />
          <Row label={t('area')} value={get(asset.area, 'name')} />
          <AssetDisplayDataRow>
            <div className="label">{t('photo')}</div>
            <div className="value">
              {asset.event_photos?.map((photo: any) => (
                <div key={photo?.id}>
                  <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('attachment')}</div>
            <div className="value" style={{ display: 'block' }}>
              {asset.event_attachments?.map((attachment: any) => (
                <div key={attachment?.id}>
                  <FileLink file={attachment} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('reference_link')}</div>
            <div className="value" style={{ display: 'block' }}>
              {asset.event_urls?.map((url: string) => (
                <div key={url}>
                  <ReferenceLink url={url} />
                </div>
              ))}
            </div>
          </AssetDisplayDataRow>
          <Row className="last" label={t('sale_by')} value={get(asset.user_info, 'display_name')} />
        </div>
      )}
    </AssetDisplay>
  )
}

type Props = {
  assets: AssetReport[]
}

type SaleEventProps = {
  asset: AssetReport
  onAssetClick: (asset: AssetReport) => void
}
