/* eslint-disable import/no-named-default */
import { Button } from 'aa_common/front-end/antd'
import { BreadcrumbNavigation } from 'components/molecules'
import { default as BasicForm } from 'components/molecules/Form'
import { MonthlyClosingView } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingReselect, submitMonthlyClosingReselect } from 'store/accounting/monthlyClosing/actions'
import {
  selectMonthlyClosingCurrent,
  selectMonthlyClosingReselectTerms,
} from 'store/accounting/monthlyClosing/selectors'
import { CHANGE_LOCATION } from 'store/app/actions'

import { Form as FormContainer } from './Form'

const breadcrumb = (
  <BreadcrumbNavigation
    enableBackButton={false}
    sectionName={i18n.t('components.MainMenu.accounting.monthly_closing')}
  />
)

const MonthlyClosingPage: React.FC = () => {
  const dispatch = useDispatch()
  const [isReselectingTerm, setIsReselectingTerm] = useState<boolean>(false)
  const reselectTerms = useSelector(selectMonthlyClosingReselectTerms, isEqual)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)

  useEffect(() => {
    dispatch(fetchMonthlyClosingReselect())
  }, [dispatch])

  const handleClickSetting = () => {
    dispatch({ type: CHANGE_LOCATION, payload: '/accounting/monthly-closing/settings' })
  }

  const handleClickReselectingTerm = () => {
    setIsReselectingTerm(!isReselectingTerm)
  }

  const actions = useMemo(() => {
    return (
      <div style={{ display: 'none' }}>
        <Button name="edit-setting" color="grey" onClick={handleClickSetting}>
          {i18n.t('components.MonthlyClosingView.tightening_cycle_setting')}
        </Button>
      </div>
    )
  }, [])

  const onSubmit = (values: any) => {
    const currentTermId = values?.term_selection
    dispatch(submitMonthlyClosingReselect(currentTermId))
    setIsReselectingTerm(false)
  }

  return (
    <MainTemplate breadcrumb={breadcrumb} actions={actions}>
      {monthlyClosingCurrent?.is_first_monthly_closing && (
        <BasicForm value={{}} onFormSubmit={onSubmit}>
          <FormContainer
            isReselectingTerm={isReselectingTerm}
            handleClickReselectingTerm={handleClickReselectingTerm}
            reselectTerms={reselectTerms}
            monthlyClosingCurrent={monthlyClosingCurrent}
          />
        </BasicForm>
      )}
      <MonthlyClosingView isClickSelectTerm={isReselectingTerm} />
    </MainTemplate>
  )
}

export default MonthlyClosingPage
