import { DECLARATION_REPORT_EXPORT_MODE } from 'AppExport/model'
import { useHookForm } from 'common/form'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import { CsvFormat, ExportType } from '../constant'
import { exportSchema } from '../schema'

export const useFormExport = (visible: boolean) => {
  const { methods, handleSubmit, isValid, setValue, control, errors, reset, trigger } = useHookForm({
    schema: exportSchema,
  })
  const exportType = useWatch({ control, name: 'exportType' })
  const mode = useWatch({ control, name: 'mode' })

  const optionsFormatFile = useMemo(() => {
    return [
      { label: 'PDF', value: ExportType.PDF },
      { label: 'CSV', value: ExportType.CSV, disabled: mode === DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR },
    ]
  }, [mode])

  const optionsFormatCsvType = useMemo(() => {
    return [
      { label: 'UTF-8', value: CsvFormat.UTF8 },
      { label: 'Shift_JIS', value: CsvFormat.SHIFT_JIS },
    ]
  }, [])

  useEffect(() => {
    if (mode === DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR) {
      setValue('exportType', ExportType.PDF)
      trigger('exportType') // Trigger validation after setting the value
    }
  }, [mode])

  useEffect(() => {
    if (exportType === ExportType.CSV) {
      setValue('charset', CsvFormat.UTF8)
      trigger('charset') // Trigger validation after setting the value
    }
  }, [exportType])

  useEffect(() => {
    visible
      ? reset({
          submit_date: moment(),
          mode: DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR,
          exportType: ExportType.PDF,
        })
      : reset({})
  }, [visible])
  return {
    methods,
    handleSubmit,
    isValid,
    exportType,
    optionsFormatFile,
    setValue,
    errors,
    optionsFormatCsvType,
  }
}
