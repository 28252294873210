import { ComplexTable, Spin } from 'aa_common/front-end/antd'
import { BaseRowMouseEvent } from 'aa_common/front-end/shared/types'
import { IMasterData, OperationLog } from 'common/models'
import { Pagination } from 'components/molecules'
import React, { useCallback, useMemo } from 'react'

import SearchDateForm from './SearchDateForm'
import { logTableColumns } from './settings'
import { LogListWrapper } from './styles'

interface OperationLogListProps {
  masterData: IMasterData
  data: OperationLog[]
  loading: boolean
  currentPage: number
  totalRecords: number
  totalPages: number
  monthlyClosingCurrent: any
  handleSearchValuesChanged: (searchValues: any) => void
  onRowClick: (event: BaseRowMouseEvent) => void
  onPageChanged: (event: number) => void
}

const OperationLogList = ({
  data,
  loading,
  masterData,
  totalPages,
  totalRecords,
  monthlyClosingCurrent,
  currentPage,
  onRowClick,
  onPageChanged,
  handleSearchValuesChanged,
}: OperationLogListProps) => {
  const columns = useMemo(() => logTableColumns(masterData), [masterData])

  const handleOnRowClick = useCallback(
    (event: BaseRowMouseEvent) => {
      if (event) {
        onRowClick(event)
        const { rowIndex } = event
        const rows = document && document.querySelectorAll('.ant-table-row-level-0')
        if (rows && rows.length > 0 && rowIndex! >= 0) {
          Array.from(rows).forEach(row => {
            row.classList.remove('selected')
          })
          const selectedRow = rows[rowIndex!]
          selectedRow.classList.add('selected')
        }
      }
    },
    [onRowClick]
  )

  return (
    <LogListWrapper>
      <SearchDateForm
        monthlyClosingCurrent={monthlyClosingCurrent}
        handleSearchValuesChanged={handleSearchValuesChanged}
      />
      <Spin loading={loading}>
        <ComplexTable
          tableLayout="fixed"
          className="log-list-table"
          fixedColumnsTableSettings={useMemo(() => {
            return {
              scroll: {
                y: 'calc(100vh - 250px)',
              },
            }
          }, [])}
          data={data}
          topTemplate={
            totalRecords > 0 ? (
              <div className="top-template">
                <Pagination
                  name="log-pagination"
                  value={currentPage}
                  totalPages={totalPages}
                  totalRecords={totalRecords}
                  onPageChanged={onPageChanged}
                />
              </div>
            ) : undefined
          }
          columns={columns}
          enableSelectRows={false}
          rowKey="id"
          actions={false}
          onRowClick={handleOnRowClick}
        />
      </Spin>
    </LogListWrapper>
  )
}

export default React.memo(OperationLogList)
