import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Input as AntdInput } from 'antd'
import { InputProps } from 'antd/lib/input'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { Wrapper } from './styles'

interface Props extends InputProps {
  isShowError?: boolean
  afterBlur?: (value: string) => void
  className?: string
}

const Input: React.FC<Props> = ({ name = '', isShowError = true, afterBlur, className, ...rest }) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name))
  const [previousValue, setPreviousValue] = useState(getIn(values, name))
  const fieldValue = getIn(values, name)
  const error = get(errors, name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleBlur = () => {
    if (previousValue !== value) {
      setPreviousValue(value?.trim())
      setFieldValue(name, value?.trim())
    }
    afterBlur && afterBlur(value)
  }

  const handlePressEnter = () => {
    setFieldValue(name, value)
  }

  useEffect(() => {
    setValue(fieldValue)
  }, [fieldValue])

  return useMemo(() => {
    return (
      <Wrapper>
        <AntdInput
          value={value}
          name={name}
          className={classNames(className, { error })}
          onChange={handleChange}
          onBlur={handleBlur}
          onPressEnter={handlePressEnter}
          {...rest}
        />
        {error && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
      </Wrapper>
    )
  }, [value, error, rest]) // eslint-disable-line
}

export default Input
