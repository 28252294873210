export enum DataTypes {
  ASSET_TYPE = 1,
  ASSET_CATEGORY = 2,
  ASSET = 3,
}

export enum MoneyUnits {
  YEN = 1,
  THOUSAND_YEN = 2,
}

export interface EventAmount {
  change_situation_code: number
  value: number
}

export interface Amount {
  acquisition_cost: number
  accounting_previous_book_value: number
  accounting_current_book_value: number
  depreciation_amount: number
  accumulated_depreciation_amount: number
  accumulated_impaired_amount: number
  increases: EventAmount[]
  decreases: EventAmount[]
}

export type AssetTypeObject = {
  type: string
  amount: Amount
  items: AssetCategoryObject[]
}

export type AssetCategoryObject = {
  asset_category_code: string
  asset_category_name: string
  amount: Amount
  items: AssetObject[]
}

export type AssetObject = {
  biid: string
  code: string
  branch_code: string
  name: string
  usage_started_at: string | null
  service_life: number
  depreciation_method: any
  amount: Amount
  is_deleted: boolean
}
