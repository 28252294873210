import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJournalLinkageStatus } from 'store/accounting/journal/actions'
import { selectJournalLinkageStatus } from 'store/accounting/journal/selectors'

export default function useJournalStatus() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchJournalLinkageStatus())
  }, [dispatch])

  const journalLinkagePermissions = useSelector(selectJournalLinkageStatus, isEqual)

  return {
    isSendJournalPermission: (journalLinkagePermissions?.data?.permission_status || []).includes('journal'),
    ...journalLinkagePermissions,
  }
}
