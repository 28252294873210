export const sleep = (time: number) =>
  new Promise((res): any => {
    setTimeout(() => {
      res(true)
    }, time)
  })

/* eslint-disable no-async-promise-executor, no-promise-executor-return, no-await-in-loop */
export const waitFor = async (lib: any, interval = 200, timeout = 20000) =>
  new Promise(async res => {
    let count = timeout / interval
    while (count > 0) {
      if (window[lib]) return res(true)
      count--
      await sleep(interval)
    }
    res(false)
  })
