import { BaseRowMouseEvent } from 'aa_common/front-end/shared/types'
import { Drawer } from 'antd'
import { OperationLog } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { LogListItemDetail, OperationLogFilter, OperationLogList } from 'components/organisms'
import { ListPageTemplate } from 'components/templates'
import i18n from 'i18n'
import useFiltersSortsPaging from 'lib/hooks/useFiltersSortsPaging'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import {
  cleanupOperationLog,
  cleanupOperationLogItem,
  fetchOperationLogs,
} from 'store/internalControl/operationLog/actions'
import { selectOperationLogs } from 'store/internalControl/operationLog/selectors'
import { selectMasterData } from 'store/masterData/selectors'
import { useDebouncedCallback } from 'use-debounce'

import { LogListItemModalClass } from './styles'

const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.internalControl.operationLog.breadcrumb_title')} />

const OperationLogPage = () => {
  const [selectedLogItem, setSelectedLogItem] = useState<OperationLog | null>(null)

  const dispatch = useDispatch()
  const logSelector = useSelector(selectOperationLogs, isEqual)
  const masterData = useSelector(selectMasterData, isEqual)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)

  useEffect(() => {
    dispatch(fetchMonthlyClosingCurrent())
    return () => {
      dispatch(cleanupOperationLog())
    }
  }, [dispatch])

  const { filters, setFilters, pageNumber, setPageNumber, pageSize, totalPages } = useFiltersSortsPaging({
    totalRecords: logSelector.totalRecords,
    initPageSize: 100,
  })

  const fetchLogs = useDebouncedCallback((filters, pageNumber, pageSize) => {
    if (Object.keys(filters || {}).length) {
      dispatch(fetchOperationLogs(filters, pageNumber, pageSize))
    }
  }, 100)

  useEffect(() => {
    fetchLogs(filters, pageNumber, pageSize)
  }, [fetchLogs, filters, pageNumber, pageSize])

  const handleSearchValuesChanged = useCallback(
    (values: any) => {
      setFilters((currentValues: any) =>
        pickBy({
          ...currentValues,
          ...values,
        })
      )
    },
    [setFilters]
  )

  const handleRowClick = useCallback((event: BaseRowMouseEvent) => {
    setSelectedLogItem(event.record)
  }, [])

  const handleLogItemModalClose = useCallback(() => {
    setSelectedLogItem(null)
    dispatch(cleanupOperationLogItem())
  }, [dispatch])

  const filterPanel = <OperationLogFilter onSubmit={handleSearchValuesChanged} />

  return (
    <ListPageTemplate maxWidth={810} breadcrumb={breadcrumb} filterPanel={filterPanel}>
      <OperationLogList
        masterData={masterData}
        loading={logSelector.loading}
        currentPage={pageNumber}
        totalPages={totalPages}
        totalRecords={logSelector.totalRecords}
        data={logSelector.data}
        handleSearchValuesChanged={handleSearchValuesChanged}
        onRowClick={handleRowClick}
        onPageChanged={setPageNumber}
        monthlyClosingCurrent={monthlyClosingCurrent}
      />
      <Drawer
        width={930}
        className={LogListItemModalClass}
        visible={!!selectedLogItem}
        closable={false}
        mask={false}
        onClose={handleLogItemModalClose}
      >
        <LogListItemDetail masterData={masterData} logItem={selectedLogItem} onModalClose={handleLogItemModalClose} />
      </Drawer>
    </ListPageTemplate>
  )
}

export default OperationLogPage
