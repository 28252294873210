import { EXCISE_TAX_METHOD } from 'common/constants'

export const validateForm = (values: Record<string, number>) => {
  if (values.tax_method === EXCISE_TAX_METHOD.INCLUDED_TAX) {
    return Boolean(values.default_excise_disposal_cost_id && values.default_excise_sell_id)
  }
  if (values.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX) {
    if (
      values.tax_input_format === null ||
      values.rounding_method === null ||
      values.can_edit_tax === null ||
      values.default_excise_disposal_cost_id === null ||
      values.default_excise_sell_id === null
    ) {
      return false
    }
  }
  return true
}
