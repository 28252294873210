import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { MasterDataItem } from 'common/models'
import Input from 'components/atoms/v2/Input'
import Form, { RenderFormProps } from 'components/molecules/Form'
import { get } from 'lodash'
import React from 'react'
import { Control, useWatch } from 'react-hook-form'

import { validateForm, ValueFields } from './helpers'

const t = loadNs(['common', 'components/organisms/settings/cause-form'])

const { Group, Header, Item, SubmitButton } = Form

type Props = {
  item?: any
  formModule: RenderFormProps
  causeTypes: MasterDataItem[]
}

const SubmitButtonArea = ({
  formControl,
  loading,
}: {
  formControl: Control<Record<string, any>>
  loading?: boolean
}) => {
  const formValues = useWatch({
    control: formControl,
  })

  const isValid = validateForm(formValues as ValueFields)

  return <SubmitButton isDisableBtn={!isValid || loading} />
}

const CauseForm = ({ formModule, item, causeTypes }: Props) => {
  const { errors } = formModule

  return (
    <>
      <Group>
        <Header>{t('form_title')}</Header>
        <Item required label={t('code')}>
          <Input
            name="code"
            maxLength={10}
            disabled={formModule.loading}
            control={formModule.control}
            error={get(errors, 'code.message')}
            placeholder={t('placeholder.under_10')}
          />
        </Item>
        <Item required label={t('type')}>
          <AutoCompleteSelect
            name="type"
            items={causeTypes}
            valueField="code"
            textField="name_jp"
            control={formModule.control}
            placeholder={t('placeholder_type')}
            disabled={!!get(item, 'is_used') || formModule.loading}
            error={get(errors, 'type.message')}
            allowClear={false}
          />
        </Item>
        <Item required label={t('content')}>
          <Input
            name="content"
            maxLength={50}
            disabled={formModule.loading}
            control={formModule.control}
            error={get(errors, 'content.message')}
            placeholder={t('placeholder.under_50')}
          />
        </Item>
        <Item required={false} label={t('search_key')}>
          <Input
            name="search_key"
            maxLength={20}
            disabled={formModule.loading}
            control={formModule.control}
            placeholder={t('placeholder.under_20')}
          />
        </Item>
      </Group>
      <SubmitButtonArea formControl={formModule.control} loading={formModule.loading} />
    </>
  )
}

export default CauseForm
