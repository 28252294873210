import Form from 'components/molecules/Form'
import { pick } from 'lodash'
import React from 'react'

import FormFilterTemplate from './FormFilterTemplate'
import { FormFilterType } from './model'

const FormFilter = ({
  name,
  type,
  fields,
  initValues,
  label,
  onApplyFilter,
  renderForm = () => <span>Form Template</span>,
  renderContent = () => <span>Content Template</span>,
}: FormFilterType) => {
  const handleSubmit = (filter: any) => {
    onApplyFilter(pick(filter.submitFormValues, fields), name, filter)
  }

  return (
    <Form loading={false} value={initValues} onFormSubmit={handleSubmit}>
      {form => (
        <FormFilterTemplate
          form={form}
          type={type}
          handleSubmit={handleSubmit}
          name={name}
          label={label}
          renderContent={renderContent}
          renderForm={renderForm}
        />
      )}
    </Form>
  )
}

export default FormFilter
