import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { DataTypes } from 'common/models/depreciation'
import { get } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { handleDataTable } from './helper'
import { Link, Table } from './styles'

interface Props {
  dataTable: Array<any>
}

const t = loadNs(['components/organisms/accounting/under-over-depreciation-report'], 'depreciationReportTable')
export const ReportTable = ({ dataTable }: Props) => {
  const { pathname } = useLocation()

  const MoneyTemplate = useCallback((data: any) => <span>{yen(data)}</span>, [])

  const commonConfig = {
    align: 'right',
    width: 120,
    render: MoneyTemplate,
  }

  const columns = [
    {
      children: [
        {
          dataIndex: 'name',
          width: 282,
          className: 'no-border-right',
          render: (value: string, record: any) => {
            if (record.type === DataTypes.ASSET) {
              return (
                <Link
                  aria-disabled={!!record.is_deleted}
                  className={classNames({ disabled: record.is_deleted })}
                  to={{
                    pathname: `/assets/${record.biid}`,
                    state: {
                      fromPath: pathname,
                    },
                  }}
                >
                  {record?.code || ''} {record?.name}
                </Link>
              )
            }

            return value
          },
        },
      ],
    },
    {
      className: 'border-double-left',
      title: t('accounting_ledger'),
      children: [
        {
          ...commonConfig,
          title: t('depreciation_amount'),
          dataIndex: 'depreciation_amt_accountant_ledger',
          className: 'border-double-left',
        },
      ],
    },
    {
      title: t('tax_ledger'),
      children: [
        {
          ...commonConfig,
          title: t('depreciation_limit_amount'),
          dataIndex: 'depreciation_limit_amt_tax_ledger',
        },
      ],
    },
    {
      title: t('subtraction'),
      children: [
        {
          ...commonConfig,
          title: t('over_depreciation_amount'),
          dataIndex: 'over_depreciation_amt',
          className: 'no-border-right',
        },
        {
          ...commonConfig,
          title: t('under_depreciation_amount'),
          dataIndex: 'under_depreciation_amt',
        },
      ],
    },
    {
      title: t('depreciation_excess_amount'),
      children: [
        {
          ...commonConfig,
          title: t('accumulated_over_depreciation_amount'),
          dataIndex: 'over_depreciation_amt_carried_last_period',
          className: 'no-border-right',
        },
        {
          ...commonConfig,
          title: t('current_loss_allowance_amount'),
          dataIndex: 'loss_allowance_amt',
          className: 'no-border-right',
        },
        {
          ...commonConfig,
          title: t('accumulated_depreciation_amount_next_period'),
          dataIndex: 'over_depreciation_amt_carried_next_period',
        },
      ],
    },
  ]

  const dataHanled = useMemo(() => handleDataTable(dataTable), [dataTable])

  const customExpandIcon = ({ expanded, onExpand, record }: any) => {
    if (expanded) {
      return <CaretDownOutlined onClick={e => onExpand(record, e)} />
    }
    return get(record, 'children') && <CaretRightOutlined onClick={e => onExpand(record, e)} />
  }

  return (
    <Table
      dataSource={dataHanled}
      columns={columns}
      bordered
      pagination={false}
      expandable={{
        expandIcon: customExpandIcon,
      }}
    />
  )
}
