import { AxiosResponse } from 'axios'
import { VerifyAgentLoginPayload, VerifyAgentLoginResponse } from 'store/session/model'

import base from './base'

export const create = (data: any): Promise<AxiosResponse<any>> => {
  return base.post('/v1/sessions/mfid', data)
}

export const createSSO = (data: any): Promise<AxiosResponse<any>> => {
  return base.post('/v1/sessions/sso', data)
}

export const getUserInfo = () => {
  return base.get('/v1/me')
}

export const getCurrentUser = () => {
  return base.get('/v1/current_user')
}

export const logout = () => {
  return base.delete('/v1/sessions/mfid')
}

export const getCurrentOffice = () => {
  return base.get('/v1/current_office')
}

export const getUserPermission = () => {
  return base.get('/v1/office_users/permissions')
}

export const fetchNotifications = () => {
  return base.get('/v1/notifications?sort=-released_at,display_order')
}

export const verifyAgentLogin = (data: VerifyAgentLoginPayload): Promise<AxiosResponse<VerifyAgentLoginResponse>> => {
  return base.post('/v1/sessions/agentlogin', data)
}
