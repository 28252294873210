import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'aa_common/front-end/antd'
import { BasicModal } from 'aa_common/front-end/components'
import { Button as AntdButton } from 'antd'
import { loadNs } from 'common/i18n-config'
import { ASSET_CODE_FIELD, branchCodeField, codeField } from 'components/organisms/assets/AssetForm/const'
import { List } from 'immutable'
import { find, findIndex, get, pick } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { OTHER_CONDITION_FILTERS } from './const'
import FilterTemplate from './FilterTemplate'
import { AdvancedFilterModalFormProps, OtherConditionFilterOption } from './model'
import { ActionButtonWrapper, BasicModalStyle, ButtonCancelStyle, ButtonOKStyle } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

const AdvancedFilterModalForm = ({
  isShow,
  masterData,
  settingsData,
  formModule,
  showModal,
  onFormSubmit,
}: AdvancedFilterModalFormProps) => {
  const [fields, setFields] = useState<{ fieldKey: string | null }[]>([])
  const [fieldsToAdd, setFieldsToAdd] = useState(OTHER_CONDITION_FILTERS.length)
  const [remainingFilters, setRemainingFilters] = useState(OTHER_CONDITION_FILTERS)

  const { handleSubmit } = formModule

  useEffect(() => {
    const { getValues } = formModule
    const fields = getValues('submitFields') || []
    setFields(fields)

    if (!isShow && formModule) {
      const remainingFields = getValues('submitRemainingFields') || OTHER_CONDITION_FILTERS
      setRemainingFilters(remainingFields)
      setFieldsToAdd(OTHER_CONDITION_FILTERS.length - fields.length)
      resetFormValues()
    }
  }, [isShow]) // eslint-disable-line

  useEffect(() => {
    if (formModule) {
      const { getValues } = formModule
      const fields = getValues('submitFields') || []
      const remainingFields = getValues('submitRemainingFields') || OTHER_CONDITION_FILTERS
      setFieldsToAdd(OTHER_CONDITION_FILTERS.length - fields.length)
      setRemainingFilters(remainingFields)
    }
  }, [formModule])

  const resetFormValues = () => {
    const { getValues } = formModule
    const formFields = OTHER_CONDITION_FILTERS.map(item => item.value).concat([codeField.text, branchCodeField.text])

    if (getValues('submitFormValues')) {
      formModule.reset({
        ...getValues(['submitFields', 'submitRemainingFields', 'submitFormValues']),
        ...pick({ ...getValues('submitFormValues') }, formFields),
      })
    } else {
      const payload = new Map()
      for (const field of OTHER_CONDITION_FILTERS) {
        payload.set(field.value, field.emptyValue)
      }
      const convertedPayload = Object.fromEntries(payload.entries())
      Object.keys(convertedPayload).forEach((fieldKey: string) => {
        const value = get(convertedPayload, fieldKey)
        formModule.setValue(fieldKey, value)
      })
    }
  }

  const handleAddNewFilter = () => {
    if (fieldsToAdd > 0) {
      const updated = List(fields).push({ fieldKey: null }).toArray()
      setFields(updated)
      setFieldsToAdd(prev => prev - 1)
    }
  }

  const handleFormSubmit = () => {
    const { getValues } = formModule
    const formValue = getValues()

    const payload = new Map()
    for (const field of OTHER_CONDITION_FILTERS) {
      if (!get(formValue, field.value) || field.value === ASSET_CODE_FIELD) {
        payload.set(field.value, field.emptyValue)
        payload.set(codeField.text, get(formValue, codeField.text))
        payload.set(branchCodeField.text, get(formValue, branchCodeField.text))
      } else {
        payload.set(field.value, get(formValue, field.value))
      }
    }

    const convertedPayload = Object.fromEntries(payload.entries())
    Object.keys(convertedPayload).forEach((fieldKey: string) => {
      let value = get(convertedPayload, fieldKey)

      if (typeof value === 'string') {
        value = value.trim()
      }

      formModule.setValue(fieldKey, value)
    })

    showModal(false)
    formModule.setValue('submitFormValues', convertedPayload)
    formModule.setValue('submitFields', fields)
    formModule.setValue('submitRemainingFields', remainingFilters)
    handleSubmit(onFormSubmit, onFormSubmit)()
  }

  const onCancel = useCallback(() => showModal(false), [showModal])

  const handleFilterChanged = (index: number, filter?: string, lastFilter?: string) => {
    if (filter) {
      const updated = List(fields).set(index, { fieldKey: filter }).toArray()
      setFields(updated)
      setRemainingFilters(prev => {
        const deletedIndex = findIndex(prev, item => item.value === filter)
        const pushFilter = find(OTHER_CONDITION_FILTERS, item => item.value === lastFilter)
        let remainingFilters = List(prev).remove(deletedIndex).toArray()
        if (pushFilter) {
          setTimeout(() => formModule.setValue(lastFilter!, undefined), 100)
          remainingFilters = List(remainingFilters).push(pushFilter).toArray()
        }
        return remainingFilters
      })
    }
  }

  const handleFilterRemoved = (index: number, filter?: string) => {
    const updated = List(fields).remove(index).toArray()
    setFields(updated)
    setFieldsToAdd(prev => prev + 1)
    if (filter) {
      formModule.setValue(filter, undefined)
      setRemainingFilters(prev => {
        const pushFilter = find(OTHER_CONDITION_FILTERS, item => item.value === filter) as OtherConditionFilterOption
        const remainingFilters = List(prev).push(pushFilter).toArray()
        return remainingFilters
      })
    }
  }

  const getContent = () => {
    const content = []
    for (let i = 0; i < fields.length; i++) {
      content.push(
        <FilterTemplate
          key={i}
          index={i}
          masterData={masterData}
          settingsData={settingsData}
          formModule={formModule}
          remainingFilters={remainingFilters}
          field={fields.length > 0 ? fields[i] : null}
          onFilterChanged={handleFilterChanged}
          onFilterRemoved={handleFilterRemoved}
        />
      )
    }
    return content
  }

  return (
    <BasicModal
      footer={
        <ActionButtonWrapper>
          <Button className={ButtonCancelStyle} color="grey" onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
          <Button className={ButtonOKStyle} onClick={handleFormSubmit}>
            {t('actions.ok')}
          </Button>
        </ActionButtonWrapper>
      }
      className={BasicModalStyle}
      destroyOnClose={false}
      allowOutsideClick={false}
      title={t('filterSection.other_conditions')}
      isShow={isShow}
      onCancel={onCancel}
      bodyStyle={{ minHeight: '238px' }}
    >
      {fields.length > 0 && getContent()}
      <AntdButton disabled={fieldsToAdd === 0} style={{ padding: 5 }} type="link" onClick={handleAddNewFilter}>
        <PlusOutlined />
        <span>{t('add_new_filter')}</span>
      </AntdButton>
    </BasicModal>
  )
}

export default AdvancedFilterModalForm
