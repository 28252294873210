import { useCallback } from 'react'

import { ExportType } from '../constant'
import { useCountSelectedReports } from './use-count-selected-reports'
import { ExportCsvMultileDto, useExportCsv } from './use-export-csv'
import { ExportPdfMultileDto, useExportPdf } from './use-export-pdf'

export const useSubmitExport = (props: { handleOpenModal: (visible: boolean) => void; year: number }) => {
  const { submit_destination_ids } = useCountSelectedReports()

  const { handleExport } = useExportPdf({ handleOpenModal: props.handleOpenModal, year: props.year })

  const { handleExportCsv, exportCsvTaxDeclarationPolling } = useExportCsv({ handleOpenModal: props.handleOpenModal })
  const { loading: loadingExportCsv } = exportCsvTaxDeclarationPolling
  const onSubmitExport = useCallback(
    async (data: ExportCsvMultileDto | ExportPdfMultileDto) => {
      if (data.exportType === ExportType.CSV) {
        handleExportCsv?.({ ...data, submit_destination_ids, fiscal_year: props.year } as ExportCsvMultileDto)
      } else {
        await handleExport({ ...data, submit_destination_ids, year: props.year } as ExportPdfMultileDto)
      }
    },
    [submit_destination_ids, props.year]
  )
  return {
    onSubmitExport,
    loadingExportCsv,
  }
}
