import { useMemo } from 'react'

import { FeatureFlagNames, openFeatureSlice } from './index'

export const useCheckFlag = (flagKey: FeatureFlagNames) => {
  const remoteFlags = openFeatureSlice.useSlice()

  const { isEnable } = useMemo(() => {
    return {
      isEnable: remoteFlags?.[flagKey],
    }
  }, [flagKey, remoteFlags])
  return { isEnable }
}
