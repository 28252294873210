import styled from '@emotion/styled'

export const SvgFormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &.is-last-page {
    svg {
      image.svgTotalCellsBg,
      g[id='total'] {
        visibility: visible;
      }
    }
  }

  svg {
    width: 100%;

    image.svgTotalCellsBg,
    g[id='total'] {
      visibility: hidden;
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 13px;
      font-weight: 600;
      text-align: center;

      &[data-id='year'] {
        font-size: 14px;
        line-height: 1.1em;
      }

      &[data-id='page'],
      &[data-id='total_page'] {
        font-weight: 500;
        font-size: 7px;
        line-height: 1.9em;
        padding: 0 2px;
        text-align: right;
      }
    }

    g#fixed_assets {
      div {
        line-height: 1.7em;

        &[data-id$='.name'] {
          text-align: left;
          padding-left: 0.2em;
        }
      }
      g[id^='acquisitionCost'] {
        div {
          text-align: right;
        }
      }
    }

    g#sub_total,
    g#total {
      div {
        text-align: right;
        line-height: 1.4em;
      }
    }
  }
`
