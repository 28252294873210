import { initSlice } from 'aa_common/front-end/zustand'
import { produce } from 'immer'

const initState: {
  data: any
  importBulkChangeType: any
  errorBiids: string[]
  errors: any
} = {
  data: {},
  importBulkChangeType: null,
  errorBiids: [],
  errors: [],
}

export const importBulkChangeSlice = initSlice('AIF/import-form', initState)

export const addState = (state: any, type: any) => {
  importBulkChangeSlice.setState({ data: state, importBulkChangeType: type })
}

export const reset = () => {
  importBulkChangeSlice.setState(initState)
}

export const setErrorBiids = (errorBiids: any) => {
  importBulkChangeSlice.setState(state =>
    produce(state, draft => {
      draft.errorBiids = errorBiids
    })
  )
}

export const setErrors = (errors: any) => {
  importBulkChangeSlice.setState(state =>
    produce(state, draft => {
      draft.errors = errors
    })
  )
}

export const clearErrors = () => {
  importBulkChangeSlice.setState(state =>
    produce(state, draft => {
      draft.errors = []
    })
  )
}

export const importBulkChangeAction = {
  addState,
  reset,
  setErrorBiids,
  setErrors,
  clearErrors,
}
