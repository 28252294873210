import { Moment } from 'moment'

export enum DEPARTMENT_DETAIL_MODE {
  VIEW = 'view',
  EDIT = 'edit',
}
export interface IDepartmentItem {
  code: string
  name: string
  prepress_category: string
  search_key: string
}

export interface IDepartmentFilter {
  code?: string
  name?: string
  biid?: string[]
  prepress_category?: string
  valid_at?: string
  search_key?: string
  show_past_departments?: boolean
  show_future_departments?: boolean
}

export interface DepartmentSearchPayload {
  filters?: IDepartmentFilter
  sort?: string[]
  pageNumber?: number
  pageSize?: number
}

interface DepartmentPostSearchFilter {
  biid?: string[]
  code?: string
  name?: string
  prepress_category_code?: string | number
  search_key?: string
  valid_at?: string
}

export interface PrepressCategories {
  code: number
  name_en: string
  name_jp: string
}

type DateTypes = Moment | string | null
export interface DepartmentItem {
  biid: string | null
  code: string
  created_at?: string
  created_by?: number
  id: number | null
  id_before?: number | null
  id_after?: number | null
  name: string
  prepress_category_code: string | null
  office_id?: number
  prepress_category?: PrepressCategories | null
  search_key: string
  valid_from: DateTypes
  valid_to: DateTypes
  valid_from_min?: DateTypes
  valid_to_max?: DateTypes
  ref_id: number | null
  ref_name: string | null
  ref_code: number | null
}

export interface DepartmentItemOnCloud {
  ancestry: string
  code: string
  disp_order: number
  id: number
  memo: string
  name: string
}

export interface DepartmentPostSearchPayload {
  filters?: DepartmentPostSearchFilter
  sort?: string[]
  pageNumber?: number
  pageSize?: number
}

export interface DeleteDepartmentPayload {
  biid: string
  original_name: string
}

export interface DepartmentListSelector {
  departmentList: DepartmentItem[]
  departmentListLoading: boolean
  departmentFilterConditions: any
  pageNumber: number
  pageSize: number
  totalRecords: number
  departmentSortConditions: any
}

export interface ImportDepartmentSelector {
  importError: {
    details: any[]
    message: string
  }
  loading: boolean
}

export interface DepartmentItemSelector {
  departmentData: DepartmentItem[]
  errors: any
  loading: boolean
}

export interface IDepartmentDataItem {
  id: number
  key?: number
  data: DepartmentItem
  status: DEPARTMENT_DETAIL_MODE.VIEW | DEPARTMENT_DETAIL_MODE.EDIT
  isAddFirst?: boolean
}

export interface DepartmentItemOnCloudSelector {
  list: DepartmentItemOnCloud[]
  loading: boolean
}

export interface LatestRecord {
  validToLatestRecord: DateTypes
  recordItem: DepartmentItem | null
  validFromLatestRecord: DateTypes
  isValidToChanged?: boolean
  validToBeforeChanged: DateTypes
}

export enum TYPE_CONFIRMING_MODAL {
  UPDATING_VALID_TO = 'UPDATING_VALID_TO',
  REMOVING_VALID_TO = 'REMOVING_VALID_TO',
}
