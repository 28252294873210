import { messageFn } from 'aa_common/front-end/antd/message-function/message-function.component'
import { useRouterParams } from 'aa_common/front-end/hooks/useRouterParams'
import { getBookDividingInformation } from 'api/app/asset'
import { DATE_FORMAT_RFC } from 'common/constants/app'
import { LedgerSettingsItem } from 'common/models/ledger-setting'
import { useEffect, useState } from 'react'

interface BookValueItem {
  ledger_name: string
  ledger_setting_id: string | number
  book_value: number
  date: string
}

export const useGetBookDividingInfo = (
  dividing_at: any,
  depreciation_ended_method: any,
  ledgerSettings: LedgerSettingsItem[]
): [BookValueItem[], boolean] => {
  const [bookValues, setBookValues] = useState<BookValueItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const biid = useRouterParams<{ biid: string }>('biid')
  useEffect(() => {
    if (!biid || !depreciation_ended_method || ledgerSettings?.length === 0) {
      setBookValues([])
      return
    }

    setLoading(true)
    const isCancelled = false
    if (ledgerSettings && dividing_at == null) {
      const data = ledgerSettings.map(item => {
        return { date: '', ledger_name: item.name ?? '', ledger_setting_id: item.id as number, book_value: 0 }
      })
      setBookValues(data)
      setLoading(false)
    }
    if (dividing_at) {
      const postData = {
        biid,
        ledger_book_values: ledgerSettings?.map(item => {
          return { at: dividing_at.format(DATE_FORMAT_RFC), ledger_setting_id: item.id as number }
        }),
      }
      getBookDividingInformation(postData)
        .then(response => {
          if (isCancelled) return

          const bookvalues = response?.data?.data?.items || []

          const convertBookValueWithFormatTime = bookvalues.map((item: any) => {
            return { ...item, date: item.date.replace(/-/g, '/') }
          })
          setBookValues(convertBookValueWithFormatTime)
        })
        .catch((err: any) => {
          messageFn().error(err?.message)
        })
        .finally(() => setLoading(false))
    }
  }, [biid, dividing_at, JSON.stringify(ledgerSettings), depreciation_ended_method]) // eslint-disable-line
  return [bookValues, loading]
}
