import styled from '@emotion/styled'

export const StyledWrapper = styled.div`
  .ant-input {
    height: 30px;
    border-radius: 4px;
    color: #7c8291;
    font-size: 13px;

    &.ant-input-disabled {
      color: #7c8291;
    }

    &.underline {
      border-bottom: 1px solid #d4d8dd;
      border-radius: 0;
    }

    &.text-right {
      text-align: right;
    }

    &.error {
      color: #303030;
      border-color: #ec4949;
      background-color: #ffedea;
    }
  }
`
