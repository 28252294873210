/* eslint-disable react/display-name */
import { Role } from 'aa_common/front-end/models'
import { ColumnsType } from 'antd/es/table'
import { BLUE_COLOR } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import React from 'react'
import { Link } from 'react-router-dom'

const t = loadNs(['pages/settings/roles', 'components/organisms/settings/role-list'])

export const roleTableColumns = (): ColumnsType<Role> => [
  {
    title: t('role_no'),
    width: 80,
    dataIndex: 'biid',
    key: 'biid',
    render: (_, record, index) => <span>{index + 1}</span>,
  },
  {
    title: t('role'),
    width: 230,
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <Link to={`/setting/roles/${record.biid}`} style={{ color: BLUE_COLOR }}>
        {record.name}
      </Link>
    ),
  },
  {
    title: t('permission'),
    key: 'permissions',
    dataIndex: 'permissions',
    width: 'auto',
    render: (_, record) => {
      const arrayStringRendered: string[] = []
      record.categories.forEach(item => {
        !item.is_default && arrayStringRendered.push(item.category_name_jp)
      })
      return <span>{arrayStringRendered.join(', ')}</span>
    },
  },
]
