import styled from '@emotion/styled'

import { Wrapper as FileLinkWrapper } from 'components/atoms/FileLink/styles'
import { AntLink as ReferenceLinkWrapper } from 'components/atoms/ReferenceLink/styles'

export const Wrapper = styled.div`
  .ant-tabs {
    font-size: 13px;

    .ant-tabs-content-holder {
      padding: 15px;
    }
  }

  .name-table {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        width: calc(100% - 489px);
        height: 244px;
      }
    }
  }

  .quantity-table {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        width: 480px;
        height: 244px;
      }
    }
  }

  .attachment-table,
  .tag-table {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        width: 969px;
      }
    }
  }
`

export const TopContent = styled.div`
  display: flex;
`

export const BodyContent = styled.div`
  margin-top: 15px;

  .underline-item-view {
    margin-bottom: 20px;
  }

  ${FileLinkWrapper} {
    margin-bottom: 5px;
  }

  ${ReferenceLinkWrapper} {
    margin-bottom: 5px;
  }
`

export const Carousel = styled.div`
  width: 180px;
  height: 180px;
`

export const BasicInfoWrapper = styled.div`
  width: 705px;
  margin-left: 15px;

  table {
    width: 100%;
    border-bottom: 0px;
  }

  tr {
    height: 32px;
  }

  th {
    background-color: #e7f1fd;
    text-align: left;
    padding: 3px 10px;
    border: 1px solid #d4d8dd;
  }

  td {
    background-color: #ffffff;
    padding: 3px 10px;
    border: 1px solid #d4d8dd;
  }
`

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      max-width: 805px;
    }
  }

  tr {
    height: 30px;
  }

  th {
    background-color: #e7f1fd;
    text-align: left;
    padding: 3px 10px;
    border-right: none;
    width: 160px;
    border-top: 1px solid #d4d8dd;
    border-left: 1px solid #d4d8dd;
  }

  td {
    max-width: 545px;
    background-color: #ffffff;
    padding: 3px 10px;
    border-left: none;
    border-top: 1px solid #d4d8dd;
    border-right: 1px solid #d4d8dd;
  }

  tr:first-of-type {
    th:first-of-type {
      border-top-left-radius: 4px;
      border-left: 1px solid #d4d8dd;
    }

    td:last-of-type {
      border-top-right-radius: 4px;
    }
  }

  tr:last-of-type {
    th:first-of-type {
      border-bottom-left-radius: 4px;
      border-bottom: 1px solid #d4d8dd;
    }

    td:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid #d4d8dd;
    }
  }

  .acquisition-cost {
    padding-right: 50px;
    margin-right: 35px;
  }

  .excise-amount-label {
    background-color: #e7f1fd;
    border-left: 1px solid #d4d8dd;
    padding: 4px 10px;
    padding-right: 98px;
    margin-right: 10px;

    @-moz-document url-prefix() {
      padding: 3.5px 10px;
    }
  }
`

export const CommentWrapper = styled.div`
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid #d4d8dd;
  border-radius: 4px;

  .comment-box {
    border: none;
    border-top: 1px solid #d4d8dd;
  }

  .comment-empty-box {
    border: none;
  }
`

export const CommentHeaderWrapper = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  h3 {
    margin-bottom: 0;
    font-size: 13px;
  }
`

export const TableWrapperNew = styled.div`
  width: 100%;
  border-radius: 4px;

  .row-item {
    border-bottom: 1px solid #d4d8dd;

    &:first-of-type {
      border-top: 1px solid #d4d8dd;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .column-item {
        .header {
          border-top-left-radius: 0;
        }
        .content {
          border-top-right-radius: 0;
        }
        &:first-of-type {
          .header {
            border-top-left-radius: 4px;
          }
          .content {
            border-top-right-radius: 0;
          }
        }
      }

      .header {
        border-top-left-radius: 4px;
      }

      .content {
        border-top-right-radius: 4px;
      }
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .column-item {
        &:first-of-type {
          .header {
            border-bottom-left-radius: 4px;
          }
        }

        &:last-of-type {
          .content {
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }

  .column-item {
    .row-item {
      border: none;
      height: 100%;
      border-radius: 0;

      .header,
      .content {
        border-radius: 0;
      }
    }

    .content {
      border-right: none;
    }

    &:last-of-type {
      .content {
        border-right: 1px solid #d4d8dd;
      }
    }
  }

  .header {
    background-color: #e7f1fd;
    text-align: left;
    align-items: center;
    padding: 3px 10px;
    border-left: 1px solid #d4d8dd;
  }

  .content {
    align-items: center;
    word-break: break-word;
    background-color: #ffffff;
    padding: 3px 10px;
    border-right: 1px solid #d4d8dd;
  }
`
