export function ifElse(condition: any, trueValue: any, falseValue: any): any {
  return condition ? trueValue : falseValue
}

export function defaultTo<T>(...params: [...Array<T | null | undefined>, T]): T {
  for (const param of params) {
    if (param !== null && param !== undefined) {
      return param
    }
  }

  return params[params.length - 1] as T
}
