import React from 'react'
import { Controller } from 'react-hook-form'

import { IMonthTermSlider } from './model'
import CoreMonthTermSlider from './MonthTermSlider'

const MonthTermSlider = ({ control, name, ...rest }: IMonthTermSlider) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ onChange, value, name }) => {
          return (
            <CoreMonthTermSlider
              {...rest}
              value={value}
              name={name}
              onChange={value => {
                onChange(value)
                rest.onChange && rest.onChange(value)
              }}
            />
          )
        }}
      />
    )
  }

  return <CoreMonthTermSlider {...rest} name={name} />
}

export default MonthTermSlider
