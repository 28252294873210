import { List, Map } from 'immutable'

import {
  CLEANUP_ASSET_COMMENT,
  CREATE_ASSET_COMMENT_REQUEST,
  CREATE_ASSET_COMMENT_SUCCESS,
  DELETE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS,
  DELETE_ASSET_ITEM_COMMENT_SUCCESS,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_FAILURE,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_SUCCESS,
  FETCH_ASSET_ITEM_COMMENTS_FAILURE,
  FETCH_ASSET_ITEM_COMMENTS_REQUEST,
  FETCH_ASSET_ITEM_COMMENTS_SUCCESS,
  SUBMIT_ASSET_COMMENT_FAILURE,
  UPDATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS,
  UPDATE_ASSET_ITEM_COMMENT_SUCCESS,
} from './constant'

const initialState = Map({
  list: Map({
    data: null,
    loading: false,
  }),
  assetItemComments: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ASSET_ITEM_COMMENTS_REQUEST:
      return state.setIn(['assetItemComments', 'loading'], true)
    case FETCH_ASSET_ITEM_COMMENTS_SUCCESS:
      return state.setIn(['assetItemComments', 'loading'], false).setIn(['assetItemComments', 'data'], action.payload)
    case FETCH_ASSET_ITEM_COMMENTS_FAILURE:
      return state.setIn(['assetItemComments', 'loading'], false)
    case FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_SUCCESS: {
      const list = state.getIn(['list', 'data']) as any[]
      if (!list) {
        const comments = []
        comments.push({
          assetBiid: action.assetBiid,
          comments: action.payload || [],
        })
        return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], comments)
      }
      const assetItemCommentList = List.of(...list)
      let updatedCommentList
      const targetAssetIndex = assetItemCommentList.findIndex(item => item.assetBiid === action.assetBiid)
      const isAssetCommentsContain = targetAssetIndex >= 0
      if (isAssetCommentsContain) {
        updatedCommentList = assetItemCommentList
          .set(targetAssetIndex, {
            assetBiid: action.assetBiid,
            comments: action.payload || [],
          })
          .toArray()
      } else {
        updatedCommentList = assetItemCommentList
          .push({
            assetBiid: action.assetBiid,
            comments: action.payload || [],
          })
          .toArray()
      }
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], updatedCommentList)
    }
    case FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CREATE_ASSET_COMMENT_REQUEST:
      return state.setIn(['assetItemComments', 'loading'], true)
    case CREATE_ASSET_COMMENT_SUCCESS:
      return state.setIn(['assetItemComments', 'loading'], false)
    case UPDATE_ASSET_ITEM_COMMENT_SUCCESS: {
      const updatedCommentResponse = action.payload
      const previousAssetComments = state.getIn(['assetItemComments', 'data']) as any[]
      const list = List.of(...previousAssetComments)
      const updatedIndex = list.findIndex(item => item.id === updatedCommentResponse.id)
      const updatedComments = list.set(updatedIndex, updatedCommentResponse).toArray()
      return state.setIn(['assetItemComments', 'data'], updatedComments)
    }
    case UPDATE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS: {
      const immutableAssetItemCommentList = state.getIn(['list', 'data']) as any[]
      const assetItemCommentList = List.of(...immutableAssetItemCommentList)

      const targetAssetIndex = assetItemCommentList.findIndex(item => item.assetBiid === action.assetBiid)
      const immutableTargetAssetCommentList = assetItemCommentList.getIn([targetAssetIndex, 'comments']) as any[]
      const targetAssetCommentList = List.of(...immutableTargetAssetCommentList)

      const updatedCommentIndex = targetAssetCommentList.findIndex((item: any) => item.id === action.payload.id)
      const updatedComments = targetAssetCommentList.set(updatedCommentIndex, action.payload).toArray()
      const updatedItemCommentList = assetItemCommentList.setIn([targetAssetIndex, 'comments'], updatedComments)
      return state.setIn(['list', 'data'], updatedItemCommentList.toArray())
    }
    case DELETE_ASSET_ITEM_COMMENT_SUCCESS: {
      const previousComments = state.getIn(['assetItemComments', 'data']) as any[]
      const updatedComments = List.of(...previousComments)
        .filter(obj => obj.id !== action.payload.id)
        .toArray()
      return state.setIn(['assetItemComments', 'data'], updatedComments)
    }
    case DELETE_ASSET_COMMENT_COLLECTION_VIEW_SUCCESS: {
      const immutableAssetItemCommentList = state.getIn(['list', 'data']) as any[]
      const assetItemCommentList = List.of(...immutableAssetItemCommentList)

      const targetAssetIndex = assetItemCommentList.findIndex(item => item.assetBiid === action.assetBiid)
      const immutableTargetAssetCommentList = assetItemCommentList.getIn([targetAssetIndex, 'comments']) as any[]
      const targetAssetCommentList = List.of(...immutableTargetAssetCommentList)
      const updatedComments = targetAssetCommentList.filter(obj => obj.id !== action.payload.id).toArray()
      const updatedItemCommentList = assetItemCommentList.setIn([targetAssetIndex, 'comments'], updatedComments)
      return state.setIn(['list', 'data'], updatedItemCommentList.toArray())
    }
    case SUBMIT_ASSET_COMMENT_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.payload)
    case CLEANUP_ASSET_COMMENT:
      return initialState
    default:
      return state
  }
}
