import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { AccountItem } from 'common/models/'
import { RenderFormProps } from 'components/molecules/Form'
import { JournalItemSettingTable } from 'components/templates'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useFieldBinding, useSubAccOptions } from '../helper'

interface SaleSettingTableProps {
  accountItems: AccountItem[]
}

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const SaleSettingTable = ({ accountItems }: SaleSettingTableProps) => {
  const { loading, control, setValue } = useFormContext() as RenderFormProps
  const {
    permissions: { isCRU },
  } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)

  useFieldBinding('sale_credit_asset_category_account', 'acquisition_debit_asset_category_account')

  useFieldBinding('sale_debit_accumulated_depreciation_account', 'depreciation_credit_accumulated_account')

  useFieldBinding('sale_debit_accumulated_impairment_account', 'impairment_credit_accumulated_impairment_account')

  const onAccountItemChanged = (accountSubItemField: string) => setValue(accountSubItemField, null)

  const saleDebitLossAccountSubItems = useSubAccOptions('sale_debit_loss_account_item_id', accountItems)
  const saleDebitRevenueAccountSubItems = useSubAccOptions('sale_debit_revenue_account_item_id', accountItems)
  const saleDebitAccumulatedDepreciationAccountSubItems = useSubAccOptions(
    'depreciation_credit_accumulated_account_item_id',
    accountItems
  )
  const saleCreditACAccountSubItems = useSubAccOptions('acquisition_debit_asset_category_account_item_id', accountItems)
  const saleCreditPaymentCategoryAccountSubItems = useSubAccOptions(
    'sale_credit_payment_category_account_item_id',
    accountItems
  )
  const saleCreditGainAccountSubItems = useSubAccOptions('sale_credit_gain_account_item_id', accountItems)
  const saleDebitAccumulatedImpairmentAccountSubItems = useSubAccOptions(
    'impairment_credit_accumulated_impairment_account_item_id',
    accountItems
  )

  return (
    <JournalItemSettingTable title={t('sale_settings_table')}>
      <tbody>
        <tr>
          <th className="section-name">{t('accumulated')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_accumulated_depreciation_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_accumulated_depreciation_account_sub_item_id"
              items={saleDebitAccumulatedDepreciationAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>

          <th className="section-name">{t('asset_category')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_credit_asset_category_account_item_id"
              items={accountItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_credit_asset_category_account_sub_item_id"
              items={saleCreditACAccountSubItems}
              valueField="id"
              textField="name"
              control={control}
              disabled
            />
          </td>
        </tr>
        <tr>
          <th className="section-name">{t('accumulated_impairment_loss')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_accumulated_impairment_account_item_id"
              items={accountItems}
              disabled
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_accumulated_impairment_account_sub_item_id"
              items={saleDebitAccumulatedImpairmentAccountSubItems}
              disabled
              valueField="id"
              textField="name"
              control={control}
            />
          </td>

          <th className="section-name" />
          <td colSpan={2} />
        </tr>
        <tr>
          <th className="section-name">
            {t('income_subject')}
            <br />({t('income_subject_noted')})
          </th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_revenue_account_item_id"
              items={accountItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('sale_debit_revenue_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_revenue_account_sub_item_id"
              items={saleDebitRevenueAccountSubItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>

          <th className="section-name">
            {t('payment_category')}
            <br />({t('disposal_cost')})
          </th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_credit_payment_category_account_item_id"
              items={accountItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('sale_credit_payment_category_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_credit_payment_category_account_sub_item_id"
              items={saleCreditPaymentCategoryAccountSubItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
        </tr>
        <tr>
          <th className="section-name">{t('sale_loss')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_loss_account_item_id"
              items={accountItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('sale_debit_loss_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_debit_loss_account_sub_item_id"
              items={saleDebitLossAccountSubItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>

          <th className="section-name">{t('sale_gain')}</th>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_credit_gain_account_item_id"
              items={accountItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
              onChange={() => onAccountItemChanged('sale_credit_gain_account_sub_item_id')}
            />
          </td>
          <td className="input-field">
            <AutoCompleteSelect
              name="sale_credit_gain_account_sub_item_id"
              items={saleCreditGainAccountSubItems}
              disabled={loading || !isCRU}
              valueField="id"
              textField="name"
              control={control}
            />
          </td>
        </tr>
      </tbody>
    </JournalItemSettingTable>
  )
}

export default SaleSettingTable
