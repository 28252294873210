import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import { CloseIconClass } from './styles'

const CloseIconTemplate = ({ onCloseClick }: { onCloseClick: () => void }) => {
  return (
    <Button className={CloseIconClass} onClick={onCloseClick}>
      <CloseOutlined />
    </Button>
  )
}

export default CloseIconTemplate
