import { ExportIncreaseDecreaseReportRequest, FetchIncreaseDecreaseReportRequest } from './action.model'
import {
  ADD_INCREASE_DECREASE_REPORT_ROW_KEY,
  CLEANUP_INCREASE_DECREASE_REPORT,
  EXPORT_INCREASE_DECREASE_REPORT_REQUEST,
  FETCH_INCREASE_DECREASE_REPORT_REQUEST,
  REMOVE_INCREASE_DECREASE_REPORT_ROW_KEY,
} from './constant'
import { ExportIncreaseDecreaseReportPayload, IncreaseDecreaseReportPayload } from './model'

export const fetchIncreaseDecreaseReport = (
  payload: IncreaseDecreaseReportPayload,
  isInMonthlyClosing = false
): FetchIncreaseDecreaseReportRequest => ({
  type: FETCH_INCREASE_DECREASE_REPORT_REQUEST,
  payload,
  isInMonthlyClosing,
})

export const exportIncreaseDecreaseReport = (
  payload: ExportIncreaseDecreaseReportPayload,
  isInMonthlyClosing = false
): ExportIncreaseDecreaseReportRequest => ({
  type: EXPORT_INCREASE_DECREASE_REPORT_REQUEST,
  payload,
  isInMonthlyClosing,
})

export const CleanupIncreaseDecreaseReport = () => ({ type: CLEANUP_INCREASE_DECREASE_REPORT })

export const addIncreaseDecreaseReportRowKey = (key: string) => ({
  type: ADD_INCREASE_DECREASE_REPORT_ROW_KEY,
  payload: key,
})

export const removeIncreaseDecreaseReportRowKey = (key: string) => ({
  type: REMOVE_INCREASE_DECREASE_REPORT_ROW_KEY,
  payload: key,
})
