import styled from '@emotion/styled'

export const StyledWrapper = styled.div`
  span {
    cursor: pointer;

    &.disabled {
      color: #919191;
      pointer-events: none;
    }
  }

  .ant-btn-link {
    margin: unset !important;
  }

  .ant-breadcrumb-link {
    font-size: 18px;
  }
`
export const StyledWrapperTop = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-areas: 'pagination right-content';
  align-items: center;

  .ant-pagination {
    grid-area: pagination;
  }

  .right-content {
    grid-area: right-content;
    justify-self: end;

    span {
      font-size: 13px;
    }
  }
`

export const StyledWrapperContent = styled.div`
  background: #fff;
  padding: 15px;
`

export const StyledWrapperContentSVG = styled.div`
  min-height: 1000px;
  border: solid 1px #e2e2e2;
  .not-found-report {
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #7f7f7f;
  }
`
