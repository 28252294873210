import { buildPaginationParams, buildQueryFilterParams } from 'common/helpers/request-helper'

import base from './base'

export const get = (params: any, pageNumber: number, pageSize: number) => {
  return base.get('/v1/fixed_asset_operation_logs', {
    params: {
      sort: '-created_at',
      ...buildQueryFilterParams(params),
      ...buildPaginationParams(pageNumber, pageSize),
    },
  })
}
