import { RequiredLabel } from 'aa_common/front-end/components'
import React from 'react'

import { ItemContent, ItemLabel, ItemWrapper } from './styles'

type Props = {
  children: any
  className?: string
  label: string
  required?: boolean
  style?: React.CSSProperties
  contentWidth?: number
}

const Item = ({ children, className, label, required = false, style, contentWidth = 400 }: Props) => {
  return (
    <ItemWrapper className={className} style={style}>
      <ItemLabel>{label}</ItemLabel>
      <ItemContent style={{ width: contentWidth }}>
        {children}
        {required && <RequiredLabel className="required" />}
      </ItemContent>
    </ItemWrapper>
  )
}

export default Item
