import React from 'react'

import { Image, Label, Wrapper } from './styles'

type NoDataImageProps = {
  text?: string
  imageHeight?: number
}

const NoDataImage: React.FC<NoDataImageProps> = ({ text = '', imageHeight = 400 }) => {
  return (
    <Wrapper>
      <Image style={{ height: imageHeight }} alt="No Data" src={require('assets/images/no-data.png').default} />
      <Label>{text}</Label>
    </Wrapper>
  )
}

export default NoDataImage
