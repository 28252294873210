import {
  CheckIcon,
  DepreciationIcon,
  FixedAssetIcon,
  InternalControlIcon,
  MasterIcon,
  ParagraphEdit,
  SettingIcon,
} from 'aa_common/front-end'
import { FEATURES_ID } from 'common/constants'
import i18n from 'i18n'
import React from 'react'

import { NavItemIcon } from './styles'

export interface SideNavItem {
  icon?: React.ReactNode
  label: string | React.ReactNode
  path: string
  key: string
  disabled?: boolean
  children?: SideNavItem[]
  feature_id?: number | string
  feature_flag_key?: string
}

export const navItems: SideNavItem[] = [
  {
    icon: <NavItemIcon id="fixed-asset-icon" component={FixedAssetIcon} />,
    label: i18n.t('components.MainMenu.fixed_assets'),
    path: '/assets',
    key: 'assets',
    feature_id: FEATURES_ID.FIXED_ASSETS,
  },
  {
    icon: <NavItemIcon className="depreciation-icon" component={DepreciationIcon} />,
    label: i18n.t('components.MainMenu.depreciation.subnav_label'),
    path: '/depreciation',
    key: 'depreciation',
    children: [
      {
        label: i18n.t('components.MainMenu.depreciation.asset_events_report'),
        path: '/depreciation/asset-event-list-report',
        key: 'depreciation.asset-event-list-report',
        feature_id: FEATURES_ID.REPORT_ACQUISITION_TRANSFER,
      },
      {
        label: i18n.t('components.MainMenu.depreciation.d_result_report'),
        path: '/depreciation/amount-summary',
        key: 'depreciation.amount-summary',
        feature_id: FEATURES_ID.REPORT_DEPRECIATION_RESULT,
      },
      {
        label: i18n.t('components.MainMenu.depreciation.increase_decrease_report'),
        path: '/depreciation/increase-decrease-report',
        key: 'depreciation.increase-decrease-report',
        feature_id: FEATURES_ID.REPORT_INCREASE_DECREASE,
      },
      {
        label: i18n.t('components.MainMenu.depreciation.journals_report'),
        path: '/depreciation/journal-list-report',
        key: 'depreciation.journal-list-report',
        feature_id: FEATURES_ID.REPORT_JOURNAL_LIST,
      },
    ],
  },
  {
    icon: <NavItemIcon component={CheckIcon} />,
    label: i18n.t('components.MainMenu.summary.subnav_label'),
    path: '/accounting/monthly-closing',
    key: 'accounting.monthly-closing',
    feature_id: FEATURES_ID.MONTHLY_CLOSING,
  },
  {
    icon: <NavItemIcon component={ParagraphEdit} />,
    label: i18n.t('components.MainMenu.tax_declaration.subnav_label'),
    path: '/tax-declaration',
    key: 'tax-declaration',
    children: [
      {
        label: i18n.t('components.MainMenu.tax_declaration.depreciation_fixed_asset'),
        path: '/tax-declaration/depreciation-fixed-asset',
        key: 'tax-declaration.depreciation-fixed-asset',
        feature_id: FEATURES_ID.DEPRECIATION_FIXED_ASSET,
      },
      {
        label: i18n.t('components.MainMenu.tax_declaration.corporate_income_tax'),
        path: '/tax-declaration/corporate-income-tax',
        key: 'tax-declaration.corporate-income-tax',
        feature_id: FEATURES_ID.CORPORATE_INCOME_TAX,
      },
    ],
  },
  {
    icon: <NavItemIcon component={MasterIcon} />,
    label: i18n.t('components.MainMenu.master.subnav_label'),
    path: '/master',
    key: 'master',
    children: [
      {
        label: i18n.t('components.MainMenu.master.account_item'),
        path: '/master/account-items',
        key: 'master.account-items',
        feature_id: FEATURES_ID.MASTER_ACCOUNT_ITEMS,
      },
      {
        label: i18n.t('components.MainMenu.master.excise'),
        path: '/master/excise',
        key: 'master.excise',
        feature_id: FEATURES_ID.MASTER_EXICES,
      },
      {
        label: i18n.t('components.MainMenu.master.department'),
        path: '/master/departments',
        key: 'master.departments',
        feature_id: FEATURES_ID.MASTER_DEPARTMENT,
      },
      {
        label: i18n.t('components.MainMenu.master.asset_category'),
        path: '/master/asset-categories',
        key: 'master.asset-categories',
        feature_id: FEATURES_ID.MASTER_ASSET_CATEGORIES,
      },
      {
        label: i18n.t('components.MainMenu.master.area'),
        path: '/master/areas',
        key: 'master.areas',
        feature_id: FEATURES_ID.MASTER_AREAS,
      },
      {
        label: i18n.t('components.MainMenu.master.submit_destination'),
        path: '/master/submit-destinations',
        key: 'master.submit-destinations',
        feature_id: FEATURES_ID.MASTER_SUBMIT_DESTINATION,
      },
      {
        label: i18n.t('components.MainMenu.master.unit'),
        path: '/master/units',
        key: 'master.units',
        feature_id: FEATURES_ID.MASTER_UNITS,
      },
      {
        label: i18n.t('components.MainMenu.master.cause'),
        path: '/master/causes',
        key: 'master.causes',
        feature_id: FEATURES_ID.MASTER_CAUSES,
      },
      {
        label: i18n.t('components.MainMenu.master.tag'),
        path: '/master/tags',
        key: 'master.tags',
        feature_id: FEATURES_ID.MASTER_TAGS,
      },
    ],
  },
  {
    icon: <NavItemIcon component={SettingIcon} />,
    label: i18n.t('components.MainMenu.setting.subnav_label'),
    path: '/setting',
    key: 'setting',
    children: [
      {
        label: i18n.t('components.MainMenu.setting.office'),
        path: '/setting/office',
        key: 'setting.office',
        feature_id: FEATURES_ID.SETTING_OFFICES,
      },
      {
        label: i18n.t('components.MainMenu.setting.user'),
        path: '/setting/users',
        key: 'setting.users',
        feature_id: FEATURES_ID.SETTING_USERS,
      },
      {
        label: i18n.t('components.MainMenu.setting.role'),
        path: '/setting/roles',
        key: 'setting.roles',
        feature_id: FEATURES_ID.SETTING_ROLES,
      },
      {
        label: i18n.t('components.MainMenu.setting.accounting_period'),
        path: '/setting/accounting-periods',
        key: 'setting.accounting-periods',
        feature_id: FEATURES_ID.SETTING_ACCOUNTING_PERIOD,
      },
      {
        label: i18n.t('components.MainMenu.setting.journal'),
        path: '/setting/auto-journal-settings',
        key: 'setting.auto-journal-settings',
        feature_id: FEATURES_ID.SETTING_AUTO_JOURNAL,
      },
      {
        label: i18n.t('components.MainMenu.setting.auto_general_setting'),
        path: '/setting/asset-codes/auto-generate',
        key: 'setting.asset-codes',
        feature_id: FEATURES_ID.SETTING_AUTO_GEN_SERIAL_NUMBER,
      },
      {
        label: i18n.t('components.MainMenu.setting.ledger_calculate_setting'),
        path: '/setting/ledgers',
        key: 'setting.ledgers',
        feature_id: FEATURES_ID.SETTING_LEDGER,
      },
      {
        label: i18n.t('components.MainMenu.setting.excise'),
        path: '/setting/excises',
        key: 'setting.excises',
        feature_id: FEATURES_ID.SETTING_EXCISE,
      },
      // TODO: Temporarily comment to release asset dividing feature
      // {
      //   label: i18n.t('components.MainMenu.setting.recalculate_depreciation'),
      //   path: '/setting/recalculate',
      //   key: 'setting.recalculate',
      //   feature_id: FEATURES_ID.SETTING_RECALCULATE,
      // },
      {
        label: i18n.t('components.MainMenu.setting.import'),
        path: '/setting/import',
        key: 'setting.import',
        feature_id: FEATURES_ID.SETTING_ONBOARDING,
      },
    ],
  },
  {
    icon: <NavItemIcon component={InternalControlIcon} />,
    label: i18n.t('components.MainMenu.audit.subnav_label'),
    path: '/internal-control',
    key: 'internal-control',
    children: [
      {
        label: i18n.t('components.MainMenu.audit.operation_log'),
        path: '/internal-control/operation-log',
        key: 'internal-control.operation-log',
        feature_id: FEATURES_ID.AUDIT_LOGS,
      },
    ],
  },
]
