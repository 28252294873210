import { loadNs } from 'common/i18n-config'

import { OtherConditionFilterOption } from './model'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

export const BASE_OTHER_CONDITION_FILTERS = [
  'name_kana',
  'acquire_destination_name',
  'memo',
  'quantity',
  'submit_destination',
  'summary',
  'acquire_category',
  'asset_type_for_corporate_tax',
  'asset_type_for_depreciable_property_tax',

  'created_at',
  'created_by',
  'code',
  'branch_code',
  'asset_code',
  'name',
]

export const FORM_STATE_OTHER_CONDITION_FILTERS = BASE_OTHER_CONDITION_FILTERS.concat([
  'submitFields',
  'submitRemainingFields',
  'submitFormValues',
])

export const OTHER_CONDITION_FILTERS: OtherConditionFilterOption[] = [
  {
    value: 'name_kana',
    label: t('otherFilterConditions.name_kana'),
    emptyValue: null,
  },
  {
    value: 'summary',
    label: t('otherFilterConditions.summary'),
    emptyValue: null,
  },
  {
    value: 'memo',
    label: t('otherFilterConditions.memo'),
    emptyValue: null,
  },
  {
    value: 'quantity',
    label: t('otherFilterConditions.quantity'),
    emptyValue: null,
  },
  {
    value: 'acquire_destination_name',
    label: t('otherFilterConditions.acquire_destination_name'),
    emptyValue: null,
  },
  {
    value: 'acquire_category',
    label: t('otherFilterConditions.acquire_category'),
    emptyValue: [],
  },
  {
    value: 'asset_type_for_corporate_tax',
    label: t('otherFilterConditions.asset_type_for_corporate_tax'),
    emptyValue: [],
  },
  {
    value: 'asset_type_for_depreciable_property_tax',
    label: t('otherFilterConditions.asset_type_for_depreciable_property_tax'),
    emptyValue: [],
  },
  {
    value: 'created_at',
    label: t('otherFilterConditions.created_at'),
    emptyValue: null,
  },
  {
    value: 'asset_code',
    label: t('otherFilterConditions.asset_code'),
    emptyValue: null,
  },
  {
    value: 'name',
    label: t('otherFilterConditions.name'),
    emptyValue: null,
  },
]
