import styled from '@emotion/styled'
import { GREY_BORDER_COLOR } from 'common/constants'

export const Wrapper = styled.div`
  overflow-x: auto;
  margin-bottom: 45px;

  .ant-table-wrapper {
    .ant-table-thead {
      .ant-table-cell {
        white-space: pre-wrap;
      }

      & > tr > th.ant-table-cell {
        padding: 6px 15px;
        font-weight: 500;
        background: #ffffff;
        white-space: nowrap;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        background-color: #fff;

        &.error {
          background-color: #ffeeeb;

          &:hover > td {
            background-color: #fafafa;
          }

          .ant-table-cell-fix-left,
          .ant-table-cell-fix-right {
            background-color: #ffeeeb;
          }
        }

        &:hover {
          background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #ffffff;
        }
      }

      .ant-table-cell-ellipsis {
        overflow: unset;
      }
    }
  }
`

export const NoteContainer = styled.div`
  background: #ffffff;
  border: 1px solid ${GREY_BORDER_COLOR};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;
`

export const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
`
