import { LinkLabel } from 'aa_common/front-end/components'
import { BreadcrumbNavigation } from 'components/molecules'
import { MonthlyClosingSettingForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { CHANGE_LOCATION } from 'store/app/actions'

const MonthlyClosingSettingPage: React.FC = () => {
  const dispatch = useDispatch()

  const clickCancel = useCallback(() => {
    dispatch({ type: CHANGE_LOCATION, payload: '/accounting/monthly-closing' })
  }, [dispatch])

  const cancelLink = useMemo(
    () => (
      <LinkLabel id="cancel-monthly-closing-setting" style={{ fontSize: 13, marginRight: 15 }} onClick={clickCancel}>
        {i18n.t('common.actions.cancel')}
      </LinkLabel>
    ),
    [clickCancel]
  )

  const breadcrumb = (
    <BreadcrumbNavigation
      sectionName={i18n.t('components.MonthlyClosingView.tightening_cycle_setting')}
      enableBackButton={false}
      customBackElement={cancelLink}
    />
  )

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <MonthlyClosingSettingForm />
    </MainTemplate>
  )
}

export default MonthlyClosingSettingPage
