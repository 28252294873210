export const parseRegisterNumber = (registerNumber: string) => {
  return registerNumber.replace(/\d{4}(?=.)/g, '$&-')
}

export const copyTextToClipBoard = () => {
  const value = document.getElementById('register-number')?.innerText.trim()
  if (value) {
    navigator.clipboard.writeText(value)
  }
}
