import { checkIsAfterOrEqualDate, checkIsBeforeDate } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { getIn, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'

import { getAllLedgerFieldNames } from '../helper'
import { useFormReady } from './use-form-ready'

export const useTransitionBookValues = (
  usageStartedAt: string,
  ledgerIndex: number,
  isImportMode: boolean,
  isTransitionDayIsFirstDayOfTerm: boolean,
  beginBookValueMax?: number
) => {
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const { values, setFieldValue } = useFormikContext()
  const isFormReady = useFormReady()

  const {
    depreciationMethodCodeField,
    transitionAtField,
    transitionBeginningBookValueField,
    transitionBookValueField,
  } = getAllLedgerFieldNames(ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const transitionAt = getIn(values, transitionAtField)
  const transitionBeginningBookValue = getIn(values, transitionBeginningBookValueField)
  const transitionBookValue = getIn(values, transitionBookValueField)

  const isD90_99 = [DEPRECIATION_METHOD_CODE.NON_DEPRECIATION, DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE].includes(
    depreciationCode
  )
  const isD80 = depreciationCode === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET

  const isShowInputRef = useRef(true)

  useEffect(() => {
    if (!isFormReady) return

    const currentMonthlyClosingStartDate = moment(currentMonthlyClosing?.from_term_month.start_date)
    const isUsageStartAfterOrEqualTransitionAt = checkIsAfterOrEqualDate(usageStartedAt, transitionAt)
    const isUsageStartAtBeforeTransitionAt = checkIsBeforeDate(usageStartedAt, transitionAt)
    const isUsageStartedAtInPast = checkIsBeforeDate(usageStartedAt, currentMonthlyClosingStartDate)
    const isTransitionWhenAlreadyUsed = isUsageStartAtBeforeTransitionAt || isUsageStartedAtInPast
    const isTransitionWhenNotUsed = !usageStartedAt || isUsageStartAfterOrEqualTransitionAt

    // Set values when not showing inputs in UI
    if (!transitionAt) {
      isShowInputRef.current = false
      setFieldValue(transitionBeginningBookValueField, undefined)
      setFieldValue(transitionBookValueField, undefined)
    } else if (isD90_99) {
      isShowInputRef.current = false
      setFieldValue(transitionBeginningBookValueField, beginBookValueMax)
      if (!isImportMode || depreciationCode !== DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE) {
        setFieldValue(transitionBookValueField, beginBookValueMax)
      }
    } else if (isD80 && isTransitionWhenAlreadyUsed) {
      isShowInputRef.current = false
      setFieldValue(transitionBeginningBookValueField, 0)
      setFieldValue(transitionBookValueField, 0)
    } else if (isTransitionWhenNotUsed) {
      isShowInputRef.current = false
      setFieldValue(transitionBeginningBookValueField, beginBookValueMax)
      setFieldValue(transitionBookValueField, beginBookValueMax)
    } else {
      // Set values when showing inputs in UI
      if (!transitionBeginningBookValue || !isShowInputRef.current) {
        setFieldValue(transitionBeginningBookValueField, 0)
      }
      if (isTransitionDayIsFirstDayOfTerm) {
        setFieldValue(transitionBookValueField, transitionBeginningBookValue)
      } else if (!transitionBookValue || !isShowInputRef.current) {
        setFieldValue(transitionBookValueField, 0)
      }
      isShowInputRef.current = true
    }
  }, [
    currentMonthlyClosing?.from_term_month.start_date,
    isD90_99,
    isD80,
    usageStartedAt,
    transitionAt,
    beginBookValueMax,
    transitionBeginningBookValue,
    transitionBookValue,
    isImportMode,
    depreciationCode,
    isFormReady,
  ])
}
