/* eslint-disable @typescript-eslint/no-shadow */
import { faExternalLinkAlt, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { LinkLabel } from '../../../../../link-label'
import { LinkItemWrapper } from './styles'

interface LinkItemProps {
  url: string
  index: number
  onDeleteClick: (item: { index: number; url: string }) => void
  onEditClick: (item: { index: number; url: string }) => void
}

const LinkItem = ({ url, index, onDeleteClick, onEditClick }: LinkItemProps) => {
  const handleOpenLink = (url: string) => window.open(url, '_blank')

  return (
    <LinkItemWrapper>
      <div className="label">
        <LinkLabel enableEllipsis onClick={() => handleOpenLink(url)}>
          <span>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="link-icon" />
            {url}
          </span>
        </LinkLabel>
      </div>
      <div className="actions">
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="edit-icon action-icon"
          onClick={() => onEditClick({ index, url })}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="remove-icon action-icon"
          onClick={() => onDeleteClick({ index, url })}
        />
      </div>
    </LinkItemWrapper>
  )
}

export default LinkItem
