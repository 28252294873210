import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultTo } from 'aa_common/front-end'
import { tagListSlice } from 'containers/master/tags/store'
import { isEqual, pick } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionFormValues, selectSettingsData } from 'store/asset/selectors'
import { selectMasterData } from 'store/masterData/selectors'

import { TriggerTemplate } from './components/TriggerTemplate'
import FilterForm from './FilterForm'
import { getFixedAssetFilterPayload } from './helper'
import { AssetCollectionFilterEvent } from './model'
import { AssetFilterFacetCollapseWrapper, AssetFilterFacetSider, AssetFilterFacetWrapper } from './styles'

interface AssetFilterFacet {
  filterDate: string
  onSubmit: (event: AssetCollectionFilterEvent) => void
  onFilterReset: (event: AssetCollectionFilterEvent) => void
}

const pickFields = [
  'keyword',
  'depreciation_method',
  'asset_status',
  'acquire_category',
  'acquire_destination_name',
  'memo',
  'name',
  'name_kana',
  'pre_submit_number',
  'asset_type_for_corporate_tax',
  'asset_type_for_depreciable_property_tax',
  'quantity',
  'asset_category',
  'area',
  'tag',
  'department',
  'acquisition_cost',
  'acquired_at',
  'usage_started_at',
  'service_life',
  'code',
  'branch_code',
  'created_at',
]

const AssetFilterFacetNew = ({ filterDate, onSubmit, onFilterReset }: AssetFilterFacet) => {
  const [siderCollapse, setSiderCollapse] = useState(false)

  const masterData = useSelector(selectMasterData, isEqual)
  const settingsData = useSelector(selectSettingsData, isEqual)
  const tags = defaultTo(
    tagListSlice.useSlice(state => state?.data?.tags),
    []
  )
  const assetCollectionViewFormValues = useSelector(selectAssetsCollectionFormValues, isEqual)

  const filterValue = useMemo(() => assetCollectionViewFormValues, [assetCollectionViewFormValues])

  const handleCollapse = useCallback(() => setSiderCollapse(prevValue => !prevValue), [setSiderCollapse])

  const handleOnSubmit = (formValues: any, isReset?: boolean) => {
    const filterForm = pick(formValues, pickFields)
    const payload: AssetCollectionFilterEvent = {
      formValues,
      filtersPayload: getFixedAssetFilterPayload(filterForm),
    }
    setTimeout(() => {
      isReset ? onFilterReset(payload) : onSubmit(payload)
    }, 100)
  }

  return (
    <AssetFilterFacetSider
      trigger={<TriggerTemplate siderCollapse={siderCollapse} handleCollapse={handleCollapse} />}
      width={230}
      collapsedWidth={40}
      collapsed={siderCollapse}
      collapsible
    >
      <AssetFilterFacetWrapper width={230}>
        <FilterForm
          value={filterValue}
          filterDate={filterDate}
          masterData={masterData}
          settingsData={{ ...settingsData, tags }}
          onSubmit={handleOnSubmit}
        />
      </AssetFilterFacetWrapper>
      <AssetFilterFacetCollapseWrapper>
        <div className="collapse-item">
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faSearch} onClick={handleCollapse} />
        </div>
        <div className="collapse-item">
          <FontAwesomeIcon style={{ fontSize: 16 }} icon={faFilter} onClick={handleCollapse} />
        </div>
      </AssetFilterFacetCollapseWrapper>
    </AssetFilterFacetSider>
  )
}

export default AssetFilterFacetNew
