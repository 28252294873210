import * as underOverDepreciationApi from 'api/app/underOverDepreciation'
import { parseError } from 'common/helpers'
import i18n from 'i18n'
import { put, takeLatest } from 'redux-saga/effects'
import { setAppMessage, showErrorsAppCall } from 'store/app/actions'

import { ExportUnderOverDepreciationReportRequest } from './action.model'
import {
  EXPORT_UNDER_OVER_DEPRECIATION_REPORT_FAILURE,
  EXPORT_UNDER_OVER_DEPRECIATION_REPORT_REQUEST,
  EXPORT_UNDER_OVER_DEPRECIATION_REPORT_SUCCESS,
} from './constant'

export function* exportUnderOverDepreciationReport({ payload }: ExportUnderOverDepreciationReportRequest) {
  try {
    const linkExport = underOverDepreciationApi.exportUnderOverDepreciationURL(payload)
    yield window.open(linkExport, '_blank')
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
    yield put({ type: EXPORT_UNDER_OVER_DEPRECIATION_REPORT_SUCCESS })
  } catch (error) {
    yield put({ type: EXPORT_UNDER_OVER_DEPRECIATION_REPORT_FAILURE })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(EXPORT_UNDER_OVER_DEPRECIATION_REPORT_REQUEST, exportUnderOverDepreciationReport)
}
