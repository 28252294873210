import { FORM_MODES } from 'common/models'
import { Asset } from 'common/models/asset'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetForm, AssetTemporaryDataModal } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssetItemData, selectAssetItemErrors, selectAssetItemLoading } from 'store/asset/selectors'
import { clearTempData, createTempData } from 'store/tempData/actions'
import { selectTempDataData } from 'store/tempData/selectors'

import { useHandleSubmit } from './hooks/use-handle-submit'
import { useInitialData } from './hooks/use-initial-data'
import { useInitializeContainer } from './hooks/use-initialize-container'

const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={i18n.t('pages.assets.add')} />

export const AssetCopyContainer: React.FC = () => {
  const dispatch = useDispatch()

  const errors = useSelector(selectAssetItemErrors, isEqual)
  const loading = useSelector(selectAssetItemLoading, isEqual)
  const assetData = useSelector(selectAssetItemData, isEqual)
  const tempAssetData = useSelector(selectTempDataData, isEqual)

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [hasConfirmedTempData, setHasConfirmedTempData] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<Asset | null>(null)
  const [isAppliedTemporary, setIsAppliedTemporary] = useState<boolean>(false)

  const { handleSubmit, asset, showRequiredError, isValidateAssetLoading, validateErrors } = useHandleSubmit()

  useInitialData(setInitialData)
  useInitializeContainer(setConfirmVisible, setHasConfirmedTempData)

  const handleSaveTemp = (dataType: string, data: any) => {
    hasConfirmedTempData && dispatch(createTempData(dataType, data))
  }

  const handleOkConfirm = () => {
    tempAssetData && setInitialData({ ...tempAssetData })
    setConfirmVisible(false)
    setHasConfirmedTempData(true)
    setIsAppliedTemporary(true)
  }

  const handleCancelConfirm = () => {
    dispatch(clearTempData())
    setConfirmVisible(false)
    setHasConfirmedTempData(true)
    setIsAppliedTemporary(false)
  }

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      {assetData && (
        <AssetForm
          loading={loading || isValidateAssetLoading}
          errors={validateErrors || errors}
          onSubmit={handleSubmit}
          initialAssetData={initialData}
          formMode={FORM_MODES.COPY}
          onSaveTemp={handleSaveTemp}
          showRequiredError={showRequiredError}
          asset={asset}
          isTemporaryData={isAppliedTemporary}
        />
      )}
      {tempAssetData && (
        <AssetTemporaryDataModal
          isShow={confirmVisible}
          tempAssetData={tempAssetData}
          onCancel={handleCancelConfirm}
          onOk={handleOkConfirm}
        />
      )}
    </MainTemplate>
  )
}
