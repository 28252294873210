import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { Cell } from '../common-report/schema'

export const FormData = ({ reportData, reportPeriods, companyName, reportBackground }: Props) => {
  const valuesOfFirstRow: string[][] = []
  const coordinatesOfFirstRow = [
    { x: 320, y: 102, w: 74, h: 30 },
    { x: 320, y: 122, w: 74, h: 30 },
    { x: 392, y: 102, w: 73, h: 30 },
    { x: 392, y: 122, w: 73, h: 30 },
    { x: 464, y: 102, w: 73, h: 30 },
    { x: 464, y: 122, w: 73, h: 30 },
    { x: 535, y: 102, w: 73, h: 30 },
    { x: 535, y: 122, w: 73, h: 30 },
    { x: 607, y: 102, w: 73, h: 30 },
    { x: 607, y: 122, w: 73, h: 30 },
  ]

  return (
    <svg viewBox="0 0 793.62665 1122.6667" fill="none" xmlns="http://www.w3.org/2000/svg">
      <image x="0" y="0" width="100%" href={reportBackground} />
      <g id="coporate-tax-report">
        {/* Draw header with report period and company name */}
        <g id="report-header">
          <g id="period">
            {reportPeriods?.map((period: Cell[]) => (
              <g id={uniqueId('period-')} key={uniqueId('rp8-period-')}>
                {period?.map((cell, cellIndex) => (
                  <g id={`period-start-[${cellIndex}]`} key={uniqueId('rp8-period_start_')}>
                    <foreignObject x={cell.x} y={cell.y} width={cell.w} height={cell.h}>
                      <div className="cell-wrapper" data-id={`data-period-start-${cellIndex}`}>
                        <span>{cell.value}</span>
                      </div>
                    </foreignObject>
                  </g>
                ))}
              </g>
            ))}
          </g>
          <g id="company-name">
            <foreignObject x={companyName.x} y={companyName.y} width={companyName.w} height={companyName.h}>
              <div className="cell-wrapper" data-id="company-name">
                <span>{companyName.value}</span>
              </div>
            </foreignObject>
          </g>
        </g>
        {/* Report content */}
        {reportData.map((row, rowIndex) => (
          <g id={`row-${rowIndex}`} key={uniqueId('rp8-row_')}>
            {row.map((cell, cellIndex) => {
              if (rowIndex === 0) {
                cell?.value && valuesOfFirstRow.push(cell?.value?.split('.'))

                return (
                  <g key={uniqueId('rp8-period2')}>
                    {valuesOfFirstRow.flat().map((v: string, vIndex: number) => {
                      const periods = coordinatesOfFirstRow.map((c, cIndex) => ({
                        ...c,
                        ...(vIndex === cIndex && { value: v?.replaceAll(',', '・') }),
                      }))

                      return (
                        <g id={uniqueId('period-')} key={uniqueId('rp8-period-')}>
                          {periods?.map((p, pIndex) => (
                            <g id={`rp8-r1-period-start-[${pIndex}]`} key={uniqueId('rp8-r1-period_start_')}>
                              <foreignObject x={p?.x} y={p?.y} width={p?.w} height={p?.h}>
                                <div
                                  className="cell-wrapper"
                                  data-id={`data-period-start-${cellIndex}`}
                                  style={{ fontSize: '8.5px' }}
                                >
                                  {p?.value}
                                </div>
                              </foreignObject>
                            </g>
                          ))}
                        </g>
                      )
                    })}
                  </g>
                )
              }

              return (
                <g id={`cell-${rowIndex}-[${cellIndex}]`} key={uniqueId('rp8-cell_')}>
                  <foreignObject x={cell.x} y={cell.y} width={cell.w} height={cell.h}>
                    <div className="cell-wrapper" data-id={`data-${rowIndex}-${cellIndex}`}>
                      <span>{cell.value}</span>
                    </div>
                  </foreignObject>
                </g>
              )
            })}
          </g>
        ))}
      </g>
    </svg>
  )
}

interface Props {
  reportData: Cell[][]
  reportPeriods: Cell[][]
  companyName: Cell
  reportBackground: string
}
