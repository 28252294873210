/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterLedgerSettingFilter,
  PresenterOneLedgerSettingResponse,
  PresenterSaveLedgerSettingRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class LedgerSettings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List ledger setting
   *
   * @tags ledger_settings
   * @name LedgerSettingsList
   * @summary List the ledger setting
   * @request GET:/ledger_settings
   */
  ledgerSettingsList = (body: PresenterLedgerSettingFilter, params: RequestParams = {}) =>
    this.http.request<PresenterOneLedgerSettingResponse[], any>({
      path: `/ledger_settings`,
      method: 'GET',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Save ledger settings
   *
   * @tags ledger_settings
   * @name SaveCreate
   * @summary Save the ledger settings
   * @request POST:/ledger_settings/save
   */
  saveCreate = (body: PresenterSaveLedgerSettingRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneLedgerSettingResponse, PresenterError>({
      path: `/ledger_settings/save`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
