import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import CauseForm from 'components/organisms/settings/CauseForm'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupCause, fetchCause, updateCause } from 'store/settings/cause/actions'
import { selectCauseItem } from 'store/settings/cause/selectors'

const t = loadNs(['pages/settings/causes'])

const CauseEditPage = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />
  const { id } = useParams<any>()

  const dispatch = useDispatch()
  const causeItemSelector = useSelector(selectCauseItem, isEqual)

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(updateCause(id, values))
    },
    [dispatch, id]
  )

  useEffect(() => {
    dispatch(fetchCause(id))
    return () => {
      dispatch(cleanupCause())
    }
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <CauseForm onSubmit={handleSubmit} loading={causeItemSelector.loading} values={causeItemSelector.data} />
    </MainTemplate>
  )
}

export default CauseEditPage
