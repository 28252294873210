import styled from '@emotion/styled/macro'

export const Wrapper = styled.div`
  .depreciation-cell {
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }

  td.depreciation-cell {
    &.month-simulation {
      padding: 0;
    }
  }

  col.ant-table-expand-icon-col {
    width: 30px;
  }

  .ant-table-expanded-row.month-simulation-detail {
    > .ant-table-cell {
      padding: 0;
    }

    .ant-table {
      border: unset;
      margin: 0;
    }
  }
`

export const Section = styled.section``

export const SectionHeader = styled.h4`
  font-weight: 800;
  font-size: 13px;
  line-height: 150%;
`

export const WrapperLedger = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .ant-btn {
    border: none;
    border-radius: 15px;
    margin-right: 10px;
  }

  .disable {
    color: #333333;
    background-color: #d4d8dd;
  }

  .ant-btn:hover,
  .active {
    background-color: #3b7de9;
    color: #ffffff;
  }
`
