import { UnborderCollapse } from 'aa_common/front-end/components'
import { defaultTo } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { getIn, useFormikContext } from 'formik'
import React from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useReferValue } from '../hooks/useReferValue'
import LocalUnderlineInput from '../UnderlineInput'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type BeginningBookValueProps = {
  ledgerIndex: number
  hasTransition: boolean
  depreciationCode: any
  referValue?: number
}

const shouldRender = (hasTransition: boolean, depreciationCode: any) => {
  return (
    !hasTransition &&
    ![
      DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
      DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
      DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
      DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
    ].includes(depreciationCode)
  )
}

export const BeginningBookValue = ({
  ledgerIndex,
  hasTransition,
  depreciationCode,
  referValue,
}: BeginningBookValueProps) => {
  const beginningBookInitialValueField = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE,
    ledgerIndex
  )
  const { values, setFieldValue } = useFormikContext()
  const value = getIn(values, beginningBookInitialValueField)

  useReferValue(beginningBookInitialValueField, setFieldValue, value, referValue, true)

  if (!shouldRender(hasTransition, depreciationCode)) {
    return null
  }

  return (
    <Item label={t('beginning_book_value')}>
      <LocalUnderlineInput
        value={defaultTo(referValue, 0)}
        placeholder={t('beginning_book_value_placeholder')}
        hasManualValue={false}
        maxValue={Number.MAX_VALUE}
        editable={false}
      />
    </Item>
  )
}
