import { Tooltip, Typography } from 'antd'
import { useState } from 'react'

import { AutoCompleteOptionTooltipProps } from './schema'
import { AutoCompleteSelectTooltipOverlay } from './styles'

const { Text } = Typography

export const AutoCompleteOptionTooltip = ({ title }: AutoCompleteOptionTooltipProps) => {
  const [trucated, setTrucated] = useState<boolean>(false)

  return (
    <Tooltip
      overlayClassName={AutoCompleteSelectTooltipOverlay}
      placement="topLeft"
      overlayStyle={{ pointerEvents: 'none', maxWidth: '500px' }}
      title={trucated ? title : undefined}
    >
      <Text style={{ color: 'inherit' }} ellipsis={{ onEllipsis: setTrucated }}>
        {title}
      </Text>
    </Tooltip>
  )
}
