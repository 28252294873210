import { get } from 'lodash'
import { IncreaseDecreaseReportPayload } from 'store/accounting/increaseDecreaseReport/model'

export const convertIncreaseDecreaseReportPayload = (formValues: any): IncreaseDecreaseReportPayload => {
  return {
    from_month_serial_number: get(formValues, 'month_serial_number.0') || 1,
    to_month_serial_number: get(formValues, 'month_serial_number.1') || 1,
    term_id: get(formValues, 'term_year'),
    ledger_setting_id: get(formValues, 'ledger'),
  }
}
