import { AutoCompleteSelect as Select } from 'aa_common/front-end/antd'
import { ComponentProps } from 'react'
import { useFormContext } from 'react-hook-form'

const AutoCompleteSelect = (props: ComponentProps<typeof Select>) => {
  const { control } = useFormContext()
  return <Select control={control} allowClear={false} style={{ width: 240 }} {...props} />
}

export default AutoCompleteSelect
