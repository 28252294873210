import * as accountItemApi from 'api/app/accountItem'
import { AxiosResponse } from 'axios'
import { parseError, parseImportErrors } from 'common/helpers'
import { downloadFileByResponse } from 'common/helpers/file-helper'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { CHANGE_LOCATION, SET_APP_MESSAGE, showErrorsAppCall } from 'store/app/actions'

import {
  CHANGE_ACCOUNT_ITEMS_VALUES,
  EXPORT_ACCOUNT_ITEMS_FAILURE,
  EXPORT_ACCOUNT_ITEMS_REQUEST,
  EXPORT_ACCOUNT_ITEMS_SUCCESS,
  FETCH_ACCOUNT_ITEMS_REQUEST,
  FETCH_ACCOUNT_ITEMS_REQUEST_AUTH,
  fetchAccountItemsFailure,
  fetchAccountItemsSuccess,
  IMPORT_ACCOUNT_ITEMS_FAILURE,
  IMPORT_ACCOUNT_ITEMS_REQUEST,
  IMPORT_ACCOUNT_ITEMS_SUCCESS,
  SET_ERRORS,
  SET_IS_RESET_FORM,
  UPDATE_ACCOUNT_ITEMS_FAILURE,
  UPDATE_ACCOUNT_ITEMS_REQUEST,
  UPDATE_ACCOUNT_ITEMS_SUCCESS,
} from './actions'
import { cleanupData, parsedDataAccountItems, parseErrors } from './helper'
import { selectAccountItemList } from './selectors'

export function* fetchAccountItems() {
  const { data, errors } = yield call(accountItemApi.list)
  if (data) {
    yield put(fetchAccountItemsSuccess(parsedDataAccountItems(data?.data || [])))
  } else if (errors) {
    yield put(showErrorsAppCall(errors))
    yield put(fetchAccountItemsFailure())
  }
}

export function* fetchAccountItemsAuth() {
  const { data, errors } = yield call(accountItemApi.listAuth)
  if (data) {
    yield put(fetchAccountItemsSuccess(parsedDataAccountItems(data?.data || [])))
  } else if (errors) {
    yield put(showErrorsAppCall(errors))
    yield put(fetchAccountItemsFailure())
  }
}

export function* updateAccountItems() {
  const accountItemsData: ReturnType<typeof selectAccountItemList> = yield select(selectAccountItemList)
  const accountData = cleanupData(accountItemsData)

  try {
    const response: AxiosResponse = yield call(accountItemApi.update, { account_items: accountData })
    yield put({ type: UPDATE_ACCOUNT_ITEMS_SUCCESS, payload: response.data.data })
    yield put({ type: CHANGE_LOCATION, payload: '/master/account-items' })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('components.AccountItemList.msg_updated'),
      },
    })
  } catch (error: any) {
    const { status } = error.response

    if (status === 422) {
      const errors = parseErrors(get(error, 'response.data.errors', undefined))

      yield put({ type: CHANGE_ACCOUNT_ITEMS_VALUES, payload: [...accountData] })
      yield put({ type: SET_IS_RESET_FORM, payload: true })
      yield put({ type: SET_ERRORS, payload: errors })
      yield put({ type: UPDATE_ACCOUNT_ITEMS_FAILURE })
    } else {
      const { errors } = parseError(error)
      yield put(showErrorsAppCall(errors))
    }
  }
}

export function* importAccountItems({ payload, file }: any): Generator<any> {
  try {
    yield call(accountItemApi.importFile, payload, file)
    yield put({ type: IMPORT_ACCOUNT_ITEMS_SUCCESS })
    yield put({ type: FETCH_ACCOUNT_ITEMS_REQUEST })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('common.messages.imported_success'),
      },
    })
  } catch (error: any) {
    const { status, data } = error.response

    if (status === 400 || status === 422) {
      const importErrors = parseImportErrors(data)
      yield put({ type: IMPORT_ACCOUNT_ITEMS_FAILURE, payload: importErrors })
    } else {
      yield put({
        type: SET_APP_MESSAGE,
        payload: {
          type: 'failure',
          content: i18n.t('common.messages.imported_failure'),
        },
      })
      yield put(showErrorsAppCall(parseError(error)))
    }
  }
}

export function* exportAccountItems({ charset }: any): Generator<any> {
  try {
    const response = (yield call(accountItemApi.exportFile, charset)) as AxiosResponse
    yield call(downloadFileByResponse, response)
    yield put({
      type: EXPORT_ACCOUNT_ITEMS_SUCCESS,
    })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      },
    })
  } catch (error) {
    yield put({
      type: EXPORT_ACCOUNT_ITEMS_FAILURE,
    })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      },
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_ACCOUNT_ITEMS_REQUEST, fetchAccountItems)
  yield takeLatest(FETCH_ACCOUNT_ITEMS_REQUEST_AUTH, fetchAccountItemsAuth)
  yield takeLatest(UPDATE_ACCOUNT_ITEMS_REQUEST, updateAccountItems)
  yield takeLatest(IMPORT_ACCOUNT_ITEMS_REQUEST, importAccountItems)
  yield takeLatest(EXPORT_ACCOUNT_ITEMS_REQUEST, exportAccountItems)
}
