/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAWebAgentLoginAuthRequest,
  PresenterAWebAgentLoginCodeResponse,
  PresenterAwebUserResponse,
  PresenterTenantUserActivationStatusesResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AWebUsers<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get users list
   *
   * @tags AWeb-Users
   * @name UsersList
   * @summary Get users list
   * @request GET:/admin/users
   */
  usersList = (
    query?: {
      'filter[id]'?: number | string
      'filter[identification_code]'?: string
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterAwebUserResponse[], any>({
      path: `/admin/users`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get user by id
   *
   * @tags AWeb-Users
   * @name UsersDetail
   * @summary Get user by id
   * @request GET:/admin/users/{id}
   */
  usersDetail = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterAwebUserResponse, any>({
      path: `/admin/users/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Request verifying code, nonce and redirect url
   *
   * @tags AWeb-Users
   * @name UsersAgentLoginAuthorizeCreate
   * @summary Request verifying code, nonce and redirect url
   * @request POST:/admin/users/{id}/agent_login/authorize
   */
  usersAgentLoginAuthorizeCreate = (
    id: number | string,
    body: PresenterAWebAgentLoginAuthRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterAWebAgentLoginCodeResponse, any>({
      path: `/admin/users/${id}/agent_login/authorize`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Check user uids activation or not
   *
   * @tags AWeb-Users
   * @name TenantUserActivationStatusesCreate
   * @summary Check user uids activation or not
   * @request POST:/internal/tenant_user_activation_statuses
   */
  tenantUserActivationStatusesCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterTenantUserActivationStatusesResponse, any>({
      path: `/internal/tenant_user_activation_statuses`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
