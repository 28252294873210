import { ASSET_STATUSES } from 'common/constants'
import { isRetroactiveEvent, objectToQueryParams } from 'common/helpers'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams, FixedAssetListItem } from 'common/models'
import { FixedAssetsService } from 'common/open-api'
import get from 'lodash/get'
import moment from 'moment'
import { setErrorsBulkChange } from 'pages/assets/AssetListPage/store'

import { DEFAULT_PAGE_SIZE, DETAIL_MODE_PAGE_SIZE } from './constant'

export const getSingleViewParam = (validAt: string, fixedAssetItem?: FixedAssetListItem) => {
  const validAtDate = moment(validAt)

  let paramObj: AssetSingleViewQueryParams = {
    valid_at: validAt,
  }

  if (fixedAssetItem) {
    const acquiredAtDate = moment(fixedAssetItem.acquired_at)
    const assetStatus = fixedAssetItem.asset_status
    if (validAtDate.isBefore(acquiredAtDate) && assetStatus.code === ASSET_STATUSES.ACQUIRED_BEFORE_USE) {
      // To be acquired
      paramObj = {
        valid_at: fixedAssetItem.acquired_at,
        type: ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED,
      }
    } else if (assetStatus.code === ASSET_STATUSES.RETIRED_OR_SOLD) {
      // Retired or sold
      paramObj = {
        valid_at: (fixedAssetItem.retired_at || fixedAssetItem.sold_at) as string,
        type: ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
      }
    }
  }

  return `?${objectToQueryParams(paramObj)}`
}

export const verifyClosingMonthStartUsageEvent = (data: { currentMonthlyClosing: any; assetEvent: any }) => {
  return isRetroactiveEvent(data.assetEvent, data.currentMonthlyClosing)
}

function submitFilter(
  event: any,
  currentFilters: any,
  currentSort: any,
  dispatchFunction: any,
  fetchAssetListFunction: any
) {
  const newFilters = { ...currentFilters, ...event.filtersPayload }
  const isDetailMode = get(newFilters, 'include_history')
  const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
  dispatchFunction(
    fetchAssetListFunction({ filters: newFilters, sort: currentSort, formValues: event.formValues }, 1, pageSize)
  )
}

function resetFilter(
  event: any,
  currentFilters: any,
  currentSort: any,
  dispatchFunction: any,
  fetchAssetListFunction: any
) {
  const newFilters = {
    ...event.filtersPayload,
    include_history: !!currentFilters?.include_history,
    valid_at: currentFilters?.valid_at,
  }

  const isDetailMode = get(newFilters, 'include_history')
  const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
  dispatchFunction(
    fetchAssetListFunction({ filters: newFilters, sort: currentSort, formValues: event.formValues }, 1, pageSize)
  )
}

const bulkChangeStartedUsage = async (
  values: any,
  currentMonthlyClosing: any,
  setLoadingBulkChange: (value: boolean) => void,

  startedUsagePolling: any,
  confirmModal: any,
  biids: any
) => {
  setErrorsBulkChange({})
  setLoadingBulkChange(true)
  const checkClosingMonth = verifyClosingMonthStartUsageEvent({ assetEvent: values, currentMonthlyClosing })

  const callApi = async () => {
    try {
      const res = (await FixedAssetsService.bulkChangeStartUsageUpdate({ ...values, biids })) as any
      res?.data?.id && startedUsagePolling.setJobId(res.data.id)
    } catch (error) {
      // error
    } finally {
      setLoadingBulkChange(false)
    }
  }

  if (checkClosingMonth) {
    confirmModal.open({}).then(async (isOK: boolean) => {
      if (isOK) {
        await callApi()
      }
    })
  } else {
    await callApi()
  }
  setLoadingBulkChange(false)
}

const bulkChangeAssetTypeUpdate = async (
  values: any,
  setLoadingBulkChange: (params?: any) => void,
  biids: string[],
  assetTypeModificationPolling: any
) => {
  setErrorsBulkChange({})
  setLoadingBulkChange(true)
  try {
    const res = (await FixedAssetsService.bulkChangeAssetTypeUpdate({ ...values, biids })) as any
    res?.data?.id && assetTypeModificationPolling.setJobId(res.data.id)
  } catch (error) {
    // error
  } finally {
    setLoadingBulkChange(false)
  }
}
const bulkChangeMovement = async (
  values: any,
  setLoadingBulkChange: (params?: any) => void,
  biids: string[],
  movementPolling: any
) => {
  setLoadingBulkChange(true)
  setErrorsBulkChange({})
  try {
    const res = (await FixedAssetsService.bulkChangeMoveUpdate({ ...values, biids })) as any
    res?.data?.id && movementPolling.setJobId(res.data.id)
  } catch (error) {
    // error
  } finally {
    setLoadingBulkChange(false)
  }
}

const bulkChangesRetirement = async (
  values: any,
  setLoadingBulkChange: (params?: any) => void,
  biids: string[],
  retirementPolling: any
) => {
  setErrorsBulkChange({})
  setLoadingBulkChange(true)
  try {
    const res = (await FixedAssetsService.bulkChangeRetireUpdate({ ...values, biids })) as any
    res.data.id && retirementPolling.setJobId(res.data.id)
  } catch (error) {
    // error
  } finally {
    setLoadingBulkChange(false)
  }
}

export const hookFnc = {
  submitFilter,
  resetFilter,
  bulkChangeStartedUsage,
  bulkChangeAssetTypeUpdate,
  bulkChangeMovement,
  bulkChangesRetirement,
}
