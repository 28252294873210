import styled from '@emotion/styled'
import { Input } from 'antd'

export const PaginationWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

export const PaginationButton = styled.button`
  background: transparent;
  padding: 0 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: 0.2s color cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: opacity 0.2s;
  color: #7c8291;

  &.prev {
    margin-right: 7px;
  }

  &.next {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
    svg {
      color: #d4d8dd;
    }
  }

  &:hover {
    opacity: 0.5;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`

export const PaginationInput = styled(Input)`
  max-width: 40px;
  font-size: 13px;
  height: 30px;
  border-radius: 4px;
`
