import styled from '@emotion/styled'

export const DepreciationFixedAssetWrapper = styled.section`
  width: 100%;
  max-width: 1030px;
  padding: 15px;
  margin-top: 15px;
  background-color: white;
  border: 1px solid #d4d8dd;
  border-radius: 4px;
`

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid #d4d8dd;
  border-radius: 4px;
`

export const PaginationWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`
