import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: inline-block;
  text-align: center;
`

export const Image = styled.img``

export const Label = styled.div`
  background: 1px red solid;
  margin-top: 15px;
`
