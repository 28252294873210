/* eslint-disable react-hooks/exhaustive-deps */
import { InputNumber as AntdInputNumber, InputNumberProps } from 'antd'
import classnames from 'classnames'
import { get } from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { CommonErrorMsg } from '../../../antd'
import { Wrapper } from './styles'

// eslint-disable-next-line react/function-component-definition
const InputNumber: React.FC<InputNumberProps & Props> = ({
  name = '',
  isShowError = true,
  required,
  className,
  underline,
  handlePressEnter,
  ...rest
}) => {
  const { errors } = useFormContext()
  const error = get(errors, `${name}.message`)
  const errorMsg = !error ? get(errors, `${name}.message`) : error

  const classNames = classnames(className, { underline, error: errorMsg })

  return (
    <Controller
      name={name}
      defaultValue={null}
      rules={{ required }}
      render={({ onChange: controllerOnChange, value, onBlur }) => {
        return (
          <Wrapper>
            <AntdInputNumber
              value={value}
              name={name}
              className={classNames}
              onChange={controllerOnChange}
              onBlur={onBlur}
              onPressEnter={handlePressEnter}
              {...rest}
            />
            {error && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
          </Wrapper>
        )
      }}
    />
  )
}

type Props = InputNumberProps & {
  isShowError?: boolean
  afterBlur?: (value: string) => void
  className?: string
  required?: boolean
  handlePressEnter?: () => void
  underline?: boolean
  allowPrefix?: boolean
}

export default InputNumber
