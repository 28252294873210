import { EXCISE_TAX_INPUT_FORMAT } from 'common/constants'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

import { FORM_FIELDS } from '../const'
import { useFormReady } from './use-form-ready'

export const useAcquisitionCostReference = (
  isTaxMethodExcluded: boolean,
  acquisitionCostInput?: number,
  acquisitionExcludedInput?: number,
  taxInputFormat?: EXCISE_TAX_INPUT_FORMAT
) => {
  const { setFieldValue } = useFormikContext()
  const isFormReady = useFormReady()

  const acquisitionCostReference: number | undefined = useMemo(() => {
    if (isTaxMethodExcluded && taxInputFormat === EXCISE_TAX_INPUT_FORMAT.INTERNAL_TAX) {
      return acquisitionExcludedInput
    }
    return acquisitionCostInput
  }, [isTaxMethodExcluded, taxInputFormat, acquisitionCostInput, acquisitionExcludedInput])

  useEffect(() => {
    isFormReady && setFieldValue(FORM_FIELDS.ACQUISITION_COST_REFERENCE, acquisitionCostReference)
  }, [acquisitionCostReference, isFormReady])

  return acquisitionCostReference
}
