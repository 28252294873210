import { get, groupBy } from 'lodash'

export function convertArrayFieldsErrorPayload(errors: any) {
  if (!errors) {
    return null
  }

  const groupedBy = groupBy(errors, error => {
    return error.source.pointer.split('/')[2]
  })

  return Object.keys(groupedBy).map(itemIndex => {
    const errorFields = Array.from(get(groupedBy, itemIndex, [])).map(errorField => {
      const fieldKey = errorField.source.pointer.split(`/items/${itemIndex}/`)[1]
      return {
        field: fieldKey,
        message: errorField.detail,
      }
    })

    const fields = {}
    errorFields.forEach(item => {
      Object.assign(fields, {
        [item.field]: {
          message: item.message,
        },
      })
    })

    return {
      key: Number(itemIndex),
      fields,
    }
  })
}
