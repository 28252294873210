import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'aa_common/front-end/antd'
import { RequiredLabel } from 'aa_common/front-end/components'
import { defaultTo } from 'aa_common/front-end/helpers'
import { useFirstNotNullValue } from 'aa_common/front-end/hooks'
import {
  AUTOMATIC_JOURNAL_ENTRY_ACQUISITION_TYPE,
  EXCISE_TAX_METHOD,
  INPUT_TAX_WHEN_REGISTER_FIXED_ASSET,
} from 'common/constants'
import { generateTermsOptions } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { Excise } from 'common/models'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { fetchExciseList } from 'store/settings/excise/actions'
import { selectExciseList } from 'store/settings/excise/selectors'

import AutoCompleteSelect from './AutoCompleteSelect'
import FormFooter from './FormFooter'
import { RequiredLabelStyle, Row, titleLineStyle } from './styles'

const t = loadNs(['components/organisms/settings/excise-setting-form-modal', 'pages/settings/excise-setting'])

const checkDisableInputVAT = (autoJeAcType?: number, taxMethod?: number) =>
  autoJeAcType === AUTOMATIC_JOURNAL_ENTRY_ACQUISITION_TYPE.EXECUTED || taxMethod === EXCISE_TAX_METHOD.INCLUDED_TAX

export type ExciseFormProp = {
  allowSelectTerm: boolean
}

const Form = ({ allowSelectTerm }: ExciseFormProp) => {
  const dispatch = useDispatch()
  const { setValue } = useFormContext()
  const exciseSettings = useSelector(selectMasterData)?.excise_settings
  const terms = useSelector(selectTermsData, isEqual)
  const exciseList = defaultTo(useSelector<any, Excise[]>(selectExciseList), [])

  useEffect(() => {
    const filters = { is_active: true }
    dispatch(fetchExciseList({ filters }))
  }, [dispatch])

  const fromTermId = useFirstNotNullValue(useWatch<number>({ name: 'from_term_id' }))
  const termList = useMemo(() => {
    return generateTermsOptions(terms, fromTermId)
  }, [terms, fromTermId])

  const taxMethod = useWatch<number>({ name: 'tax_method' })
  const autoJeAcType = useWatch<number>({ name: 'automatic_journal_entry_accquisition_type' })
  useEffect(() => {
    if (checkDisableInputVAT(autoJeAcType, taxMethod)) {
      setValue('input_tax_when_register_fixed_asset', INPUT_TAX_WHEN_REGISTER_FIXED_ASSET.EXECUTED)
    }
  }, [setValue, autoJeAcType, taxMethod])

  return (
    <>
      <Row style={{ alignItems: 'center' }}>
        <div style={{ width: 57 }}>{t('setting_method')}</div>
        <Tooltip
          content={t('tooltip.tax_method')}
          tooltipStyles={{ display: 'flex', alignItems: 'center' }}
          placement="topRight"
        >
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 124 }}>
          <RequiredLabel className={RequiredLabelStyle} />
          <AutoCompleteSelect
            name="tax_method"
            items={exciseSettings.tax_method.values}
            valueField="code"
            textField="name_jp"
          />
        </div>
      </Row>
      {taxMethod === EXCISE_TAX_METHOD.EXCLUDED_TAX && (
        <>
          <Row style={{ alignItems: 'center' }}>
            <div style={{ width: 58 }}>{t('tax_input_format')}</div>
            <Tooltip
              content={t('tooltip.input_format')}
              tooltipStyles={{ display: 'flex', alignItems: 'center' }}
              placement="topRight"
            >
              <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
            </Tooltip>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 123 }}>
              <RequiredLabel className={RequiredLabelStyle} />
              <AutoCompleteSelect
                name="tax_input_format"
                items={exciseSettings.tax_input_format.values}
                valueField="code"
                textField="name_jp"
              />
            </div>
          </Row>
          <Row style={{ alignItems: 'center' }}>
            <div className="label">{t('rounding_method')}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RequiredLabel className={RequiredLabelStyle} />
              <AutoCompleteSelect
                name="rounding_method"
                items={exciseSettings.rounding_method.values}
                valueField="code"
                textField="name_jp"
              />
            </div>
          </Row>
          <Row style={{ alignItems: 'center' }}>
            <div style={{ width: 124 }}>{t('editable')}</div>
            <Tooltip content={t('tooltip.editable')}>
              <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
            </Tooltip>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 58 }}>
              <RequiredLabel className={RequiredLabelStyle} />
              <AutoCompleteSelect
                name="can_edit_tax"
                items={exciseSettings.can_edit_tax.values}
                valueField="code"
                textField="name_jp"
              />
            </div>
          </Row>
        </>
      )}
      <Row style={{ padding: 0 }}>
        <div className={titleLineStyle}>
          <span style={{ color: '#7C8291', marginLeft: 9 }}>{t('excise_account')}</span>
        </div>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <div style={{ width: 95 }}>{t('create_acquisition_journal')}</div>
        <Tooltip content={t('tooltip.create_acquisition_journal')}>
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 88 }}>
          <RequiredLabel className={RequiredLabelStyle} />
          <AutoCompleteSelect
            name="automatic_journal_entry_accquisition_type"
            items={exciseSettings.automatic_journal_entry_accquisition_type.values}
            valueField="code"
            textField="name_jp"
          />
        </div>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <div style={{ width: 107 }}>{t('input_vat')}</div>
        <Tooltip content={t('tooltip.input_vat')}>
          <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 76 }}>
          <RequiredLabel className={RequiredLabelStyle} />
          <AutoCompleteSelect
            name="input_tax_when_register_fixed_asset"
            items={exciseSettings.input_tax_when_register_fixed_asset.values}
            valueField="code"
            textField="name_jp"
            disabled={checkDisableInputVAT(autoJeAcType, taxMethod)}
          />
        </div>
      </Row>
      <Row style={{ padding: 0 }}>
        <div className={titleLineStyle}>
          <span style={{ color: '#7C8291', marginLeft: 9 }}>{t('excise_default_when_retire_or_sell')}</span>
        </div>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <div className="label">{t('excise_for_deposal_cost')}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RequiredLabel className={RequiredLabelStyle} />
          <AutoCompleteSelect
            name="default_excise_disposal_cost_id"
            items={exciseList}
            valueField="id"
            textField="short_name"
            placeholder={t('excise_placeholder')}
          />
        </div>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <div className="label">{t('excise_for_sell_cost')}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RequiredLabel className={RequiredLabelStyle} />
          <AutoCompleteSelect
            name="default_excise_sell_id"
            items={exciseList}
            valueField="id"
            textField="short_name"
            placeholder={t('excise_placeholder')}
          />
        </div>
      </Row>
      <FormFooter allowSelectTerm={allowSelectTerm} termList={termList} />
    </>
  )
}

export default Form
