import { UnborderCollapse } from 'aa_common/front-end/components'
import { LinkListField, UploadFileListField, UploadImageListField } from 'components/molecules'
import i18n from 'i18n'

const MEDIA_FIELD_NAME = {
  PHOTO: 'event_photos',
  ATTACHMENT: 'event_attachments',
  URL: 'event_urls',
}

export const MediaUploader = ({ photoField, attachmentField, urlField, ...props }: Props) => {
  const { Item } = UnborderCollapse

  return (
    <div {...props}>
      <Item label={photoField?.label || i18n.t('common.field.photo')}>
        <UploadImageListField name={photoField?.name || MEDIA_FIELD_NAME.PHOTO} />
      </Item>
      <Item label={attachmentField?.label || i18n.t('common.field.attachment')}>
        <UploadFileListField name={attachmentField?.name || MEDIA_FIELD_NAME.ATTACHMENT} />
      </Item>
      <Item label={urlField?.label || i18n.t('common.field.url.text')}>
        <LinkListField
          name={urlField?.name || MEDIA_FIELD_NAME.URL}
          placeholder={urlField?.placeholder || i18n.t('common.field.url.placeholder')}
        />
      </Item>
    </div>
  )
}

type CommonType = {
  name?: string
  label?: string
  placeholder?: string
}

type Props = {
  photoField?: CommonType
  attachmentField?: CommonType
  urlField?: CommonType
  style?: React.CSSProperties
}
