import styled from '@emotion/styled'

export const FormFields = styled.div`
  > .item {
    width: 100% !important;

    > .content {
      display: flex;
      align-items: center;
      width: 100% !important;

      > div:not(.required) {
        width: 240px;
      }

      .underline-input {
        display: flex;
        justify-content: flex-end;

        + div {
          // right-arrow
          width: 10px !important;
          margin: 0 25px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
    .error-field > div {
      border-color: red;
    }
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
`
