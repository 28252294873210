import AutoJournalSettingHalfModal from 'components/organisms/accounting/AutoJournalSettingHalfModal'
import AutoJournalSettingList from 'components/organisms/accounting/AutoJournalSettingList'
import i18n from 'i18n'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAssetCategoryJournalSettingsList,
  fetchAssetCategoryJournalSettingsTerms,
} from 'store/accounting/journal/actions'
import {
  selectorJournalSettingsForAssetCategoryList,
  selectorSelectedAssetCategoryJournalSettingTerms,
} from 'store/accounting/journal/selectors'

import { Wrapper, WrapperMessage } from './styles'

const AutoJournalSettingTab = React.forwardRef(function AutoJournalSettingTab(_, ref) {
  const [selectedAssetCategory, setSelectedAssetCategory] = useState<any>()
  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    closeModal: () => setSelectedAssetCategory(null),
  }))

  const journalSettingsAssetCategoryListSelector = useSelector(selectorJournalSettingsForAssetCategoryList, isEqual)
  const selectedAssetCategoryTermsSelector = useSelector(selectorSelectedAssetCategoryJournalSettingTerms, isEqual)

  const memoizedJournalSettingsAssetCategoryList = useMemo(
    () => journalSettingsAssetCategoryListSelector.data || [],
    [journalSettingsAssetCategoryListSelector.data]
  )

  useEffect(() => {
    dispatch(fetchAssetCategoryJournalSettingsList())
  }, []) // eslint-disable-line

  const handleAssetCategoryClick = useCallback(
    (item: any) => {
      setSelectedAssetCategory(item)
      const isIdDifferent = selectedAssetCategory?.id !== item.id
      isIdDifferent && dispatch(fetchAssetCategoryJournalSettingsTerms(item.id))
    },
    [dispatch, selectedAssetCategory]
  )

  const onDrawerClose = useCallback(() => setSelectedAssetCategory(null), [])

  return (
    <Wrapper>
      <WrapperMessage style={{ padding: 15, marginBottom: 15 }}>
        {i18n.t('pages.accounting.AutoJournalSettings.auto_journal_config_description')}
      </WrapperMessage>
      <AutoJournalSettingList
        data={memoizedJournalSettingsAssetCategoryList}
        loading={journalSettingsAssetCategoryListSelector.loading}
        onSelect={handleAssetCategoryClick}
      />
      <AutoJournalSettingHalfModal
        assetCategory={selectedAssetCategory}
        loading={selectedAssetCategoryTermsSelector.loading}
        handleDrawerClose={onDrawerClose}
      />
    </Wrapper>
  )
})

export default AutoJournalSettingTab
