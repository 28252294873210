import { Radio } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'

export const CustomRadioGroup = ({ value, onChange, ...props }: RadioGroupProps) => {
  // @ts-ignore
  return (
    <Radio.Group
      value={value}
      onChange={e => {
        onChange?.(e.target.value)
      }}
      {...props}
    />
  )
}
