import get from 'lodash/get'
import React from 'react'

import { detailReportSlice } from '../../corporate-income-tax-report-container/store'
import { xCoordinatesPeriod12, xCoordinatesReport12, yCoordinatesPeriod12 } from '../common-report/const'
import { FormData } from '../common-report/form-data'
import { getPeriod, getReportGrid } from '../common-report/helper'
import { SvgFormWrapper } from '../common-report/styles'
import reportBackground from './background.svg'

export const ReportForm = () => {
  const cellWidth = 91
  const yCoordinate = 88
  const heightPerRow: number[] = [
    17.5, 17, 17, 17, 17, 17, 17, 18, 18, 18.5, 17, 17, 18, 17, 17, 18, 18, 18, 21, 17.5, 18, 18, 18, 23, 17, 18, 17,
    19, 18, 18, 19, 19, 19, 20.5, 21, 21, 20.5, 21, 17, 19, 18.5, 17.5, 18.5, 19, 19, 21, 21, 20.5, 20.5, 21, 27,
  ]
  const reportData = detailReportSlice.useSlice(state => state?.data)

  const propsData = {
    reportData: getReportGrid(heightPerRow, cellWidth, xCoordinatesReport12, yCoordinate, reportData),
    reportPeriods: getPeriod(reportData, xCoordinatesPeriod12, yCoordinatesPeriod12),
    companyName: { x: 575, y: 40, w: 174, h: 49, value: get(reportData, 'office_name', '') },
    reportBackground,
  }

  return (
    <SvgFormWrapper>
      <FormData {...propsData} />
    </SvgFormWrapper>
  )
}
