import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectTempDataData } from 'store/tempData/selectors'

import { useFetchData } from './use-fetch-data'

export const useInitializeContainer = (setConfirmVisible: Function, setHasConfirmedTempData: Function) => {
  const tempAssetData = useSelector(selectTempDataData, isEqual)

  useFetchData()

  useEffect(() => {
    if (tempAssetData) {
      setConfirmVisible(true)
      setHasConfirmedTempData(false)
    } else {
      setHasConfirmedTempData(true)
    }
  }, [tempAssetData])
}
