import { useEffect } from 'react'

export function useClearValueOnHide(
  fieldName: string,
  setFieldValue: (field: string, value: any) => void,
  isShown: boolean
) {
  useEffect(() => {
    if (!isShown) {
      setFieldValue(fieldName, undefined)
    }
  }, [fieldName, setFieldValue, isShown])
}
