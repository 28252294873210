import styled from '@emotion/styled/macro'
import { css } from '@emotion/css'

export const ActionButtonWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const InputStyle = css`
  &.ant-input {
    padding: 5px 7px;
    height: 32px;
  }
`

export const AutoCompleteSelectStyle = css`
  .ant-select-selector {
    border-radius: 4px !important;
  }
`

export const RemoveFilterStyle = css`
  margin-right: 6px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
`

export const SelectStyle = css`
  width: 100%;

  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
  }

  & .ant-select-arrow {
    top: 46%;
  }
`

export const RowStyle = css`
  margin-bottom: 15px;
`

export const RangeDatePickerInputStyle = css`
  &.ant-picker {
    height: 32px !important;
  }
`

export const ButtonOKStyle = css`
  width: 144px;
  height: 30px;
`

export const ButtonCancelStyle = css`
  width: 144px;
  height: 30px;
  margin-right: 30px !important;
`

export const BasicModalStyle = css`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      border-radius: 4px 4px 0 0;
    }
  }

  .ant-select-selector {
    border-radius: 4px !important;
  }
`

export const AssetCodeSectionWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-input {
    height: 32px;
  }

  .fa-minus {
    color: #303030;
    margin: 0 5px;
  }
`
