import { AssetCategory, Excise, MasterDataItem } from 'common/models'
import { PresenterOneTagResponse, PresenterOneUnitResponse } from 'common/open-api'

export enum VALUE_TYPES {
  EXCISE = 'EXCISE',
  UNIT = 'UNIT',
  ASSET_CATEGORY = 'ASSET_CATEGORY',
  TAG = 'TAG',
  DEPRECIATION_METHOD = 'DEPRECIATION_METHOD',
}

const compareId = (list: any[], id: number | string) => list.find(item => item.id || item.code === id)

export const getNameValue = (
  listName: Excise[] | PresenterOneTagResponse[] | PresenterOneUnitResponse[] | MasterDataItem[],
  type: VALUE_TYPES,
  id: number | null,
  ids?: string[]
) => {
  if (!listName || listName.length === 0) return ''

  let selectedItem = id && compareId(listName, id)
  const result: string[] = []

  switch (type) {
    case VALUE_TYPES.TAG:
      if (!ids) return ''

      ids.forEach(tagId => {
        const selectedTag = compareId(listName, tagId)
        result.push(selectedTag?.name as string)
      })

      selectedItem = result.join(',')
      break

    case VALUE_TYPES.EXCISE:
      selectedItem = selectedItem ? selectedItem.short_name : ''
      break

    case VALUE_TYPES.DEPRECIATION_METHOD:
      selectedItem = selectedItem ? selectedItem.name_jp : ''
      break

    default:
      selectedItem = selectedItem ? selectedItem.name : ''
      break
  }

  return selectedItem
}

export const getAssetCategoryName = (assetCategories: AssetCategory[], assetCategoryId: number) => {
  if (!assetCategories || assetCategories.length === 0) return ''

  const selectedItem = assetCategories.find(assetCategory => assetCategory.id === assetCategoryId)

  return selectedItem ? selectedItem.name : ''
}
