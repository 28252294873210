import styled from '@emotion/styled'

export const LinkItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  position: relative;

  &:hover {
    background-color: #f5f5f5;
    .edit-icon,
    .remove-icon {
      opacity: 1;
    }
  }

  .label {
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    position: relative;
    min-width: 0px;

    .link-icon {
      margin-right: 5px;
      color: #7c8291;
    }
  }

  .actions {
    flex: 0 1 auto;
    padding: 0 5px;
    align-items: center;

    .action-icon {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .action-icon {
    opacity: 0;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  .edit-icon {
    color: #7c8291;
  }
  .remove-icon {
    color: #e31c1c;
  }

  transition: 0.1s all ease-in-out;
`
