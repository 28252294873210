/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterImportFixedAssetEventRequest,
  PresenterJob,
  PresenterResponse,
  PresenterValidateImportFixedAssetEventResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class FixedAssetEvents<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description ImportFAEventCSV from csv file
   *
   * @tags fixed_asset_events
   * @name EventsCreate
   * @summary ImportFAEventCSV for importing fixed asset events
   * @request POST:/import/events
   */
  eventsCreate = (body: PresenterImportFixedAssetEventRequest, params: RequestParams = {}) =>
    this.http.request<PresenterJob, PresenterError>({
      path: `/import/events`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description ValidateImportFixedAssetEventCSV from csv file
   *
   * @tags fixed_asset_events
   * @name EventsValidateCsvCreate
   * @summary ValidateImportFixedAssetEventCSV for importing fixed asset events
   * @request POST:/import/events/validate_csv
   */
  eventsValidateCsvCreate = (
    data: {
      /** fixed assets csv file to import */
      file: File
      /** charset in file content */
      charset?: string
      /** event type code */
      event_type: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterValidateImportFixedAssetEventResponse
      },
      any
    >({
      path: `/import/events/validate_csv`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
