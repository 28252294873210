export const FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST = 'FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST'
export const FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH = 'FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH'
export const FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS = 'FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS'
export const FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE = 'FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE'

export const CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST = 'CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST'
export const CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS = 'CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS'
export const CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE = 'CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE'

export const DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST = 'DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST'
export const DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS = 'DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS'
export const DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE = 'DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE'

export const FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST = 'FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST'
export const FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS = 'FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS'
export const CLEANUP_ASSET_CODE_INITIAL_NUMBER_SETTING = 'CLEANUP_ASSET_CODE_INITIAL_NUMBER_SETTING'

export const SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST = 'SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST'
export const SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS = 'SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS'
export const SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_FAILURE = 'SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_FAILURE'

export const CLEANUP_ASSET_CODE_AUTO_GENERATE_SETTING = 'CLEANUP_ASSET_CODE_AUTO_GENERATE_SETTING'

export const SET_TERM_YEAR_SELECTED = 'SET_TERM_YEAR_SELECTED'

export const fetchAutoGenerateSettings = () => ({
  type: FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
})

export const fetchAutoGenerateSettingsAuth = () => ({
  type: FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH,
})

export const fetchAutoGenerateSettingsSuccess = (payload: any) => ({
  type: FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS,
  payload,
})

export const fetchAutoGenerateSettingsFailure = () => ({
  type: FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE,
})

export const createAutoGenerateSetting = (payload: any) => ({
  type: CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  payload,
  meta: { thunk: true },
})

export const deleteAutoGenerateSetting = (assetCodeSettingId: number) => ({
  type: DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  payload: assetCodeSettingId,
})

export const fetchInitialNumberSetting = (assetCodeSettingId: number) => ({
  type: FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST,
  payload: assetCodeSettingId,
})

export const saveInitialNumberSetting = (payload: any) => ({
  type: SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_REQUEST,
  payload,
})

export const cleanupInitialNumberSetting = () => ({
  type: CLEANUP_ASSET_CODE_INITIAL_NUMBER_SETTING,
})

export const setTermYearSelected = (termYear: number) => ({
  type: SET_TERM_YEAR_SELECTED,
  payload: termYear,
})
