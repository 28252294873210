import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionFormValues, selectAssetsCollectionView } from 'store/asset/selectors'

export const useFiltersSort = () => {
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const assetCollectionViewFilterValues = useSelector(selectAssetsCollectionFormValues, isEqual)

  const sort = useMemo(() => assetCollectionViewSelector.sort, [assetCollectionViewSelector.sort])
  const filters = useMemo(() => assetCollectionViewSelector.filters, [assetCollectionViewSelector.filters])

  return {
    filters,
    sort,
    assetCollectionViewSelector,
    assetCollectionViewFilterValues,
  }
}
