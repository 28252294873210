import classNames from 'classnames'
import React, { useMemo } from 'react'

import { formatFormData } from '../helpers'
import { FormProps } from '../models'
import FormData from './FormData'
import { SvgFormWrapper } from './styles'

const Form = (props: FormProps) => {
  const data = useMemo(() => formatFormData(props), [props])
  const className = classNames({
    'is-last-page': data.page && data.page === data.total_page,
  })

  return (
    <SvgFormWrapper className={className}>
      <FormData {...data} />
    </SvgFormWrapper>
  )
}

export default Form
