/* eslint-disable import/no-mutable-exports */
import i18next, { DefaultNamespace, i18n, KeyPrefix, Namespace, TFunction } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next, useTranslation } from 'react-i18next'

import { Backend } from './backend'

const resources: Record<string, any> = {
  ja: require('../locales/ja').default,
}
// Don't load English translation for `production`
if (process.env.REACT_APP_ENV !== 'production') {
  resources.en = require('../locales/en').default
}

let instance: i18n

const singletonInit = () => {
  if (instance) return instance
  instance = i18next.createInstance()
  instance
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      detection: {
        order: ['querystring', 'navigator', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
      },
      nonExplicitSupportedLngs: true,
      fallbackLng: ['ja'],
      supportedLngs: ['ja', 'en'],
      ns: ['common'],
      interpolation: {
        escapeValue: false,
      },
      resources, // TODO: remove
    })
    // TODO: remove
    .then(() => {
      ;(window as any).i18n = instance
    })
  return instance
}

/* deprecated */
export const loadNs = <
  Ns extends Namespace,
  Kp extends KeyPrefix<ActualNs>,
  ActualNs extends Namespace = Ns extends null ? DefaultNamespace : Ns
>(
  ns: Ns,
  kp?: Kp
): TFunction<ActualNs, Kp> => {
  instance.loadNamespaces(ns)
  const getT = (k?: Kp): TFunction<ActualNs, Kp> => instance.getFixedT(null, ns, k)
  return getT(kp)
}

export const getNs = <
  Ns extends Namespace,
  Kp extends KeyPrefix<ActualNs>,
  ActualNs extends Namespace = Ns extends null ? DefaultNamespace : Ns
>(
  ns: Ns,
  kp?: Kp
): {
  t: TFunction<ActualNs, Kp>
  useNsReady: typeof useTranslation<ActualNs, Kp>
} => {
  instance.loadNamespaces(ns)
  const getT = (k?: Kp): TFunction<ActualNs, Kp> => instance.getFixedT(null, ns, k)
  const t = getT(kp)

  const useNsReady = () => {
    return useTranslation(ns, { i18n: instance })
  }

  return {
    t,
    useNsReady,
  }
}

instance = singletonInit()
export default instance
