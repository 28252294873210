import { assetListPageStore } from 'pages/assets/AssetListPage/store'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchAssetList } from 'store/asset/actions'

export const useFetchAssetListCallback = () => {
  const { search } = useLocation()
  const store = assetListPageStore.rowSelectSliceAssetList.useSlice(data => data).data
  return useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const is_retroactive = searchParams.get('is_retroactive') === 'true'
    const journal_id = Number(searchParams.get('journal_id'))

    return (...args: Parameters<typeof fetchAssetList>) => {
      const [payload, ...rest] = args
      let filters = { ...payload?.filters }
      if (store.idMarkAlls.length > 0) {
        filters.biid = store.idMarkAlls
      } else {
        delete filters.biid
      }
      if (is_retroactive) {
        filters = { ...filters, is_retroactive }
      } else if (journal_id) {
        filters = { ...filters, journal_id }
      } else {
        delete filters.is_retroactive
        delete filters.journal_id
      }
      return fetchAssetList({ ...payload, filters }, ...rest)
    }
  }, [search, store.idMarkAlls])
}
