import { BaseRowMouseEvent } from 'aa_common/front-end/shared/types'

const handleOnRowMouseOver = ({ record }: BaseRowMouseEvent) => {
  if (record.parentId) {
    const parentRow = document.querySelector(`tr[data-row-key*="${record.parentId}"]`)
    parentRow?.classList.add('row-hovered')
    const childRows = document.querySelectorAll(`tr[data-row-key*="${record.parentId}-"]`)

    childRows.length > 0 &&
      childRows.forEach(row => {
        row.classList.add('row-hovered')
      })
  } else if (record.children) {
    const parentRow = document.querySelector(`tr[data-row-key*="${record.id}"]`)
    parentRow?.classList.add('row-hovered')
    const childRows = document.querySelectorAll(`tr[data-row-key*="${record.id}-"]`)
    childRows.length > 0 &&
      childRows.forEach(row => {
        row.classList.add('row-hovered')
      })
  }
}

const handleOnRowMouseLeave = ({ record }: BaseRowMouseEvent) => {
  if (record.parentId) {
    const parentRow = document.querySelector(`tr[data-row-key*="${record.parentId}"]`)
    parentRow?.classList.remove('row-hovered')
    const childRows = document.querySelectorAll(`tr[data-row-key*="${record.parentId}-"]`)
    childRows.length > 0 &&
      childRows.forEach(row => {
        row.classList.remove('row-hovered')
      })
  } else if (record.children) {
    const parentRow = document.querySelector(`tr[data-row-key*="${record.id}"]`)
    parentRow?.classList.remove('row-hovered')
    const childRows = document.querySelectorAll(`tr[data-row-key*="${record.id}-"]`)
    childRows.length > 0 &&
      childRows.forEach(row => {
        row.classList.remove('row-hovered')
      })
  }
}

export const assetListHelper = {
  handleOnRowMouseOver,
  handleOnRowMouseLeave,
}
