import React from 'react'

import { CoreMultiSelectPickerItem } from '../../components/MultiSelectPicker/model'
import { BaseFormFilter } from '../../model'
import MultiSelectForm from '../MultiSelectForm'

interface AssetStatusFormProps extends BaseFormFilter {
  items: CoreMultiSelectPickerItem[]
  name: string
}

const AssetStatusForm = ({ items, name, formModule, setPopupShow, onFormSubmit }: AssetStatusFormProps) => {
  const hasDisableOptions = items.filter(item => item.disabled).length > 0

  return (
    <MultiSelectForm
      items={items}
      name={name}
      formModule={formModule}
      setPopupShow={setPopupShow}
      onFormSubmit={onFormSubmit}
      hasDisableOptions={hasDisableOptions}
    />
  )
}

export default AssetStatusForm
