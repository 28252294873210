import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Button } from 'aa_common/front-end/antd'

export const WrapperClass = css`
  padding: 10px;
  border: 1px solid #d4d8dd;
  .comment-suffix {
    color: #7c8291;
    display: flex;
    margin-top: auto;
    align-self: flex-end;
    padding: 2px 10px 0px 7px;
  }

  .comment-box-wrapper {
    border: 1px solid #d4d8dd;
    border-radius: 4px;
    display: flex;
    background: white;
    &.disabled {
      background: #ddd;
      cursor: not-allowed;
    }
  }
`

export const StyledButton = styled(Button)`
  margin-top: 5px;
  display: flex;
  margin-left: auto;
  font-size: 13px;

  &.edit {
    margin-left: unset;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  &:focus {
    background: linear-gradient(180deg, #eff1f4 0%, #ffffff 100%);
  }
`
