import {
  CREATE_EXCISE_SETTING_REQUEST,
  DELETE_EXCISE_SETTING_REQUEST,
  FETCH_EXCISE_SETTING_FAILURE,
  FETCH_EXCISE_SETTING_REQUEST,
  FETCH_EXCISE_SETTING_REQUEST_AUTH,
  FETCH_EXCISE_SETTING_SUCCESS,
} from './constant'

export const fetchExciseSetting = () => ({
  type: FETCH_EXCISE_SETTING_REQUEST,
})

export const fetchExciseSettingAuth = () => ({
  type: FETCH_EXCISE_SETTING_REQUEST_AUTH,
})

export const fetchExciseSettingSuccess = (payload: any) => ({
  type: FETCH_EXCISE_SETTING_SUCCESS,
  payload,
})

export const fetchExciseSettingFailure = () => ({
  type: FETCH_EXCISE_SETTING_FAILURE,
})

export const createExciseSetting = (payload: any) => ({
  type: CREATE_EXCISE_SETTING_REQUEST,
  payload,
  meta: { thunk: true },
})

export const deleteExciseSetting = (id: number) => ({
  type: DELETE_EXCISE_SETTING_REQUEST,
  payload: id,
})
