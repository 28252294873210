import styled from '@emotion/styled/macro'
import Icon from '@ant-design/icons'
import { StyledComplexTable } from 'aa_common/front-end/shared/styles'
import { AntLink as ReferenceLink } from 'components/atoms/ReferenceLink/styles'

export const Wrapper = styled.div`
  ${StyledComplexTable} {
    .ant-table {
      font-size: 13px;
    }

    .ant-table-tbody > tr {
      &.ant-table-row-selected,
      &:hover {
        > td {
          background: #eff1f4;

          &.ant-table-column-sort {
            background: #eff1f4;
          }
        }
      }

      td {
        vertical-align: top;
        padding: 5px;
        padding-left: 10px;

        &.content {
          padding: 0px;
          padding-left: 10px;
          ul {
            margin-bottom: 0px;

            li:last-of-type {
              border-bottom: none;
            }
          }
        }
      }

      &.ant-table-row {
        &.highlight {
          .ant-table-cell {
            background-color: rgba(59, 125, 233, 0.1);
          }

          background-color: rgba(59, 125, 233, 0.1);
        }
      }
    }

    .ant-table-thead > tr > th {
      background: #fafafa;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 500;
      border-bottom: 1px solid #dce0e6;
      color: #333333;
      .ant-table-column-sorters {
        span {
          font-weight: 500;
        }
      }
    }
  }

  .change_situation,
  .valid-from {
    font-weight: 500;
    color: #000000;
  }
`

export const DetailList = styled.ul`
  list-style-type: none;
`

export const DetailListField = styled.li`
  border-bottom: 1px solid #dce0e6;
  &:first-of-type {
    margin-top: 0;
  }

  .detail-field-wrapper {
    display: table;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;

    .aa-link {
      font-weight: 500;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }

    .title {
      display: table-cell;
      text-align: left;
      vertical-align: middle;
      color: #333333;
      width: 200px;
    }

    .value {
      display: table-cell;
      text-align: left;
      color: #333333;

      ${ReferenceLink} {
        max-width: 447px;
      }
    }

    .action {
      text-align: right;
      padding-right: 10px;
    }

    .created_at {
      font-size: 12px;
      color: #666666;
      line-height: 20px;
    }
  }
`

export const ExtraLine = styled.div`
  position: absolute;
  width: 300px;
  top: 32px;
  left: -281px;
  border-bottom: 1px solid #dce0e6;
`

export const SuccessIcon = styled(Icon)`
  &.anticon {
    color: #3b7de9;
  }
`
