import { clearNullDataDeep } from 'aa_common/front-end/helpers'
import { parseError } from 'common/helpers'
import { get } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorsAppCall } from 'store/app/actions'

export type Request = (...otherArgs: any) => any
export type RequestState = {
  data?: any
  meta?: any
  status?: number
  error?: any
  isLoading: boolean
}
type UseResourceReturnType = [RequestState, (...args: any) => Promise<any>]

const useResource = (apiRequest: Request, isShowMessage = true): UseResourceReturnType => {
  const dispatch = useDispatch()

  const [state, setState] = useState<RequestState>({ isLoading: false })
  const request = useCallback(
    (...otherArgs: any) => {
      setState({ isLoading: true })
      return Promise.resolve(apiRequest(...otherArgs))
        .then((response: any) => {
          const { status, data } = response || {}
          const result = {
            status,
            data: clearNullDataDeep(data?.data),
            meta: data?.meta,
            isLoading: false,
          }

          setState(result)
          return result
        })
        .catch((error: any) => {
          const errResult = {
            status: get(error, ['response', 'status']),
            error: get(error, ['response', 'data', 'errors']),
            isLoading: false,
          }
          setState(errResult)
          isShowMessage && dispatch(showErrorsAppCall(parseError(error)))
          return errResult
        })
    },
    [apiRequest, dispatch, isShowMessage]
  )

  return useMemo(() => {
    const result = [{ ...state }, request]
    return result as UseResourceReturnType
  }, [state, request])
}

export default useResource
