export const FETCH_OPERATION_LOGS_REQUEST = 'FETCH_OPERATION_LOGS_REQUEST'
export const FETCH_OPERATION_LOGS_SUCCESS = 'FETCH_OPERATION_LOGS_SUCCESS'
export const FETCH_OPERATION_LOGS_FAILURE = 'FETCH_OPERATION_LOGS_FAILURE'

export const FETCH_OPERATION_LOG_ITEM_REQUEST = 'FETCH_OPERATION_LOG_ITEM_REQUEST'
export const FETCH_OPERATION_LOG_ITEM_SUCCESS = 'FETCH_OPERATION_LOG_ITEM_SUCCESS'
export const FETCH_OPERATION_LOG_ITEM_FAILURE = 'FETCH_OPERATION_LOG_ITEM_FAILURE'

export const CLEANUP_OPERATION_LOG = 'CLEANUP_OPERATION_LOG'
export const CLEANUP_OPERATION_LOG_LIST = 'CLEANUP_OPERATION_LOG_LIST'
export const CLEANUP_OPERATION_LOG_ITEM = 'CLEANUP_OPERATION_LOG_ITEM'
