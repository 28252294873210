import React from 'react'
import get from 'lodash/get'

import svgBg from './bg.svg'

export default function Form(props) {
  return (
    <svg viewBox="0 0 842 598" fill="none" xmlns="http://www.w3.org/2000/svg">
      <image x="0" y="0" width="100%" href={svgBg} />
      <g id="$shinkokusho_05_a.pdf">
        <g id="total">
          <g id="[3]">
            <foreignObject x="548.73" y="573.5" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="total[3]">
                {get(props, 'total[3]')}
              </div>
            </foreignObject>
          </g>
          <g id="[2]">
            <foreignObject x="525.06" y="573.5" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="total[2]">
                {get(props, 'total[2]')}
              </div>
            </foreignObject>
          </g>
          <g id="[1]">
            <foreignObject x="501.031" y="573.5" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="total[1]">
                {get(props, 'total[1]')}
              </div>
            </foreignObject>
          </g>
          <g id="[0]">
            <foreignObject x="477" y="573.5" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="total[0]">
                {get(props, 'total[0]')}
              </div>
            </foreignObject>
          </g>
        </g>
        <g id="sub_total">
          <g id="[3]_2">
            <foreignObject x="548.73" y="555" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="sub_total[3]">
                {get(props, 'sub_total[3]')}
              </div>
            </foreignObject>
          </g>
          <g id="[2]_2">
            <foreignObject x="525.06" y="555" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="sub_total[2]">
                {get(props, 'sub_total[2]')}
              </div>
            </foreignObject>
          </g>
          <g id="[1]_2">
            <foreignObject x="501.031" y="555" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="sub_total[1]">
                {get(props, 'sub_total[1]')}
              </div>
            </foreignObject>
          </g>
          <g id="[0]_2">
            <foreignObject x="477" y="555" width="22.8547" height="17">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="sub_total[0]">
                {get(props, 'sub_total[0]')}
              </div>
            </foreignObject>
          </g>
        </g>
        <g id="fixed_assets">
          <g id="[19].">
            <g id="cause">
              {get(props, 'fixed_assets[19].cause') == '4' && (
                <circle cx="697" cy="547.64" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[19].cause') == '3' && (
                <circle cx="666.5" cy="547.64" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[19].cause') == '2' && (
                <circle cx="697" cy="537.8" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[19].cause') == '1' && (
                <circle cx="666.5" cy="537.8" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life">
              <foreignObject x="573.5" y="532.3" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].service_life">
                  {get(props, 'fixed_assets[19].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost">
              <g id="[3]_3">
                <foreignObject x="549.5" y="532.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquisitionCost[3]">
                    {get(props, 'fixed_assets[19].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_3">
                <foreignObject x="525.5" y="532.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquisitionCost[2]">
                    {get(props, 'fixed_assets[19].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_3">
                <foreignObject x="501.5" y="532.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquisitionCost[1]">
                    {get(props, 'fixed_assets[19].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_3">
                <foreignObject x="477.92" y="532.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquisitionCost[0]">
                    {get(props, 'fixed_assets[19].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt.">
              <g id="month">
                <foreignObject x="458.5" y="532.3" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquiredAt.month">
                    {get(props, 'fixed_assets[19].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year">
                <foreignObject x="438.5" y="532.3" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquiredAt.year">
                    {get(props, 'fixed_assets[19].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era">
                <foreignObject x="423.5" y="532.3" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].acquiredAt.era">
                    {get(props, 'fixed_assets[19].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity">
              <foreignObject x="401.5" y="532.3" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].quantity">
                  {get(props, 'fixed_assets[19].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name">
              <foreignObject x="165.5" y="532.3" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[19].name">
                  {get(props, 'fixed_assets[19].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code">
              <foreignObject x="146.5" y="532.3" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[19].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[19].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type">
              {get(props, 'fixed_assets[19].type') == '3' && (
                <circle cx="83.5" cy="543.6" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[19].type') == '1' && (
                <circle cx="65.5" cy="543.6" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[18].">
            <g id="cause_2">
              {get(props, 'fixed_assets[18].cause') == '4' && (
                <circle cx="697" cy="525.335" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[18].cause') == '3' && (
                <circle cx="666.5" cy="525.335" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[18].cause') == '2' && (
                <circle cx="697" cy="515.495" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[18].cause') == '1' && (
                <circle cx="666.5" cy="515.495" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_2">
              <foreignObject x="573.5" y="509.195" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].service_life">
                  {get(props, 'fixed_assets[18].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_2">
              <g id="[3]_4">
                <foreignObject x="549.5" y="509.195" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquisitionCost[3]">
                    {get(props, 'fixed_assets[18].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_4">
                <foreignObject x="525.5" y="509.195" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquisitionCost[2]">
                    {get(props, 'fixed_assets[18].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_4">
                <foreignObject x="501.5" y="509.195" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquisitionCost[1]">
                    {get(props, 'fixed_assets[18].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_4">
                <foreignObject x="477.92" y="509.195" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquisitionCost[0]">
                    {get(props, 'fixed_assets[18].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._2">
              <g id="month_2">
                <foreignObject x="458.5" y="509.195" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquiredAt.month">
                    {get(props, 'fixed_assets[18].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_2">
                <foreignObject x="438.5" y="509.195" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquiredAt.year">
                    {get(props, 'fixed_assets[18].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_2">
                <foreignObject x="423.5" y="509.195" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].acquiredAt.era">
                    {get(props, 'fixed_assets[18].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_2">
              <foreignObject x="401.5" y="509.195" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].quantity">
                  {get(props, 'fixed_assets[18].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_2">
              <foreignObject x="165.5" y="509.195" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[18].name">
                  {get(props, 'fixed_assets[18].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_2">
              <foreignObject x="146.5" y="509.195" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[18].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[18].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_2">
              {get(props, 'fixed_assets[18].type') == '3' && (
                <circle cx="83.5" cy="520.495" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[18].type') == '1' && (
                <circle cx="65.5" cy="520.495" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[17].">
            <g id="cause_3">
              {get(props, 'fixed_assets[17].cause') == '4' && (
                <circle cx="697" cy="502.229" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[17].cause') == '3' && (
                <circle cx="666.5" cy="502.229" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[17].cause') == '2' && (
                <circle cx="697" cy="492.389" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[17].cause') == '1' && (
                <circle cx="666.5" cy="492.389" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_3">
              <foreignObject x="573.5" y="486.089" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].service_life">
                  {get(props, 'fixed_assets[17].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_3">
              <g id="[3]_5">
                <foreignObject x="549.5" y="486.089" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquisitionCost[3]">
                    {get(props, 'fixed_assets[17].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_5">
                <foreignObject x="525.5" y="486.089" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquisitionCost[2]">
                    {get(props, 'fixed_assets[17].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_5">
                <foreignObject x="501.5" y="486.089" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquisitionCost[1]">
                    {get(props, 'fixed_assets[17].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_5">
                <foreignObject x="477.92" y="486.089" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquisitionCost[0]">
                    {get(props, 'fixed_assets[17].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._3">
              <g id="month_3">
                <foreignObject x="458.5" y="486.089" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquiredAt.month">
                    {get(props, 'fixed_assets[17].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_3">
                <foreignObject x="438.5" y="486.089" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquiredAt.year">
                    {get(props, 'fixed_assets[17].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_3">
                <foreignObject x="423.5" y="486.089" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].acquiredAt.era">
                    {get(props, 'fixed_assets[17].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_3">
              <foreignObject x="401.5" y="486.089" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].quantity">
                  {get(props, 'fixed_assets[17].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_3">
              <foreignObject x="165.5" y="486.089" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[17].name">
                  {get(props, 'fixed_assets[17].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_3">
              <foreignObject x="146.5" y="486.089" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[17].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[17].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_3">
              {get(props, 'fixed_assets[17].type') == '3' && (
                <circle cx="83.5" cy="497.389" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[17].type') == '1' && (
                <circle cx="65.5" cy="497.389" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[16].">
            <g id="cause_4">
              {get(props, 'fixed_assets[16].cause') == '4' && (
                <circle cx="697" cy="479.124" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[16].cause') == '3' && (
                <circle cx="666.5" cy="479.124" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[16].cause') == '2' && (
                <circle cx="697" cy="469.284" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[16].cause') == '1' && (
                <circle cx="666.5" cy="469.284" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_4">
              <foreignObject x="573.5" y="462.984" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].service_life">
                  {get(props, 'fixed_assets[16].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_4">
              <g id="[3]_6">
                <foreignObject x="549.5" y="462.984" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquisitionCost[3]">
                    {get(props, 'fixed_assets[16].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_6">
                <foreignObject x="525.5" y="462.984" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquisitionCost[2]">
                    {get(props, 'fixed_assets[16].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_6">
                <foreignObject x="501.5" y="462.984" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquisitionCost[1]">
                    {get(props, 'fixed_assets[16].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_6">
                <foreignObject x="477.92" y="462.984" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquisitionCost[0]">
                    {get(props, 'fixed_assets[16].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._4">
              <g id="month_4">
                <foreignObject x="458.5" y="462.984" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquiredAt.month">
                    {get(props, 'fixed_assets[16].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_4">
                <foreignObject x="438.5" y="462.984" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquiredAt.year">
                    {get(props, 'fixed_assets[16].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_4">
                <foreignObject x="423.5" y="462.984" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].acquiredAt.era">
                    {get(props, 'fixed_assets[16].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_4">
              <foreignObject x="401.5" y="462.984" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].quantity">
                  {get(props, 'fixed_assets[16].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_4">
              <foreignObject x="165.5" y="462.984" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[16].name">
                  {get(props, 'fixed_assets[16].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_4">
              <foreignObject x="146.5" y="462.984" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[16].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[16].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_4">
              {get(props, 'fixed_assets[16].type') == '3' && (
                <circle cx="83.5" cy="474.284" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[16].type') == '1' && (
                <circle cx="65.5" cy="474.284" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[15].">
            <g id="cause_5">
              {get(props, 'fixed_assets[15].cause') == '4' && (
                <circle cx="697" cy="456.019" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[15].cause') == '3' && (
                <circle cx="666.5" cy="456.019" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[15].cause') == '2' && (
                <circle cx="697" cy="446.179" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[15].cause') == '1' && (
                <circle cx="666.5" cy="446.179" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_5">
              <foreignObject x="573.5" y="439.879" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].service_life">
                  {get(props, 'fixed_assets[15].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_5">
              <g id="[3]_7">
                <foreignObject x="549.5" y="439.879" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquisitionCost[3]">
                    {get(props, 'fixed_assets[15].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_7">
                <foreignObject x="525.5" y="439.879" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquisitionCost[2]">
                    {get(props, 'fixed_assets[15].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_7">
                <foreignObject x="501.5" y="439.879" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquisitionCost[1]">
                    {get(props, 'fixed_assets[15].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_7">
                <foreignObject x="477.92" y="439.879" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquisitionCost[0]">
                    {get(props, 'fixed_assets[15].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._5">
              <g id="month_5">
                <foreignObject x="458.5" y="439.879" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquiredAt.month">
                    {get(props, 'fixed_assets[15].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_5">
                <foreignObject x="438.5" y="439.879" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquiredAt.year">
                    {get(props, 'fixed_assets[15].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_5">
                <foreignObject x="423.5" y="439.879" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].acquiredAt.era">
                    {get(props, 'fixed_assets[15].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_5">
              <foreignObject x="401.5" y="439.879" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].quantity">
                  {get(props, 'fixed_assets[15].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_5">
              <foreignObject x="165.5" y="439.879" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[15].name">
                  {get(props, 'fixed_assets[15].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_5">
              <foreignObject x="146.5" y="439.879" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[15].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[15].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_5">
              {get(props, 'fixed_assets[15].type') == '3' && (
                <circle cx="83.5" cy="451.179" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[15].type') == '1' && (
                <circle cx="65.5" cy="451.179" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[14].">
            <g id="cause_6">
              {get(props, 'fixed_assets[14].cause') == '4' && (
                <circle cx="697" cy="432.914" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[14].cause') == '3' && (
                <circle cx="666.5" cy="432.914" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[14].cause') == '2' && (
                <circle cx="697" cy="423.074" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[14].cause') == '1' && (
                <circle cx="666.5" cy="423.074" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_6">
              <foreignObject x="573.5" y="416.774" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].service_life">
                  {get(props, 'fixed_assets[14].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_6">
              <g id="[3]_8">
                <foreignObject x="549.5" y="416.774" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquisitionCost[3]">
                    {get(props, 'fixed_assets[14].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_8">
                <foreignObject x="525.5" y="416.774" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquisitionCost[2]">
                    {get(props, 'fixed_assets[14].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_8">
                <foreignObject x="501.5" y="416.774" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquisitionCost[1]">
                    {get(props, 'fixed_assets[14].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_8">
                <foreignObject x="477.92" y="416.774" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquisitionCost[0]">
                    {get(props, 'fixed_assets[14].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._6">
              <g id="month_6">
                <foreignObject x="458.5" y="416.774" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquiredAt.month">
                    {get(props, 'fixed_assets[14].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_6">
                <foreignObject x="438.5" y="416.774" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquiredAt.year">
                    {get(props, 'fixed_assets[14].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_6">
                <foreignObject x="423.5" y="416.774" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].acquiredAt.era">
                    {get(props, 'fixed_assets[14].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_6">
              <foreignObject x="401.5" y="416.774" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].quantity">
                  {get(props, 'fixed_assets[14].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_6">
              <foreignObject x="165.5" y="416.774" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[14].name">
                  {get(props, 'fixed_assets[14].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_6">
              <foreignObject x="146.5" y="416.774" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[14].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[14].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_6">
              {get(props, 'fixed_assets[14].type') == '3' && (
                <circle cx="83.5" cy="428.074" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[14].type') == '1' && (
                <circle cx="65.5" cy="428.074" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[13].">
            <g id="cause_7">
              {get(props, 'fixed_assets[13].cause') == '4' && (
                <circle cx="697" cy="409.808" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[13].cause') == '3' && (
                <circle cx="666.5" cy="409.808" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[13].cause') == '2' && (
                <circle cx="697" cy="399.968" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[13].cause') == '1' && (
                <circle cx="666.5" cy="399.968" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_7">
              <foreignObject x="573.5" y="393.668" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].service_life">
                  {get(props, 'fixed_assets[13].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_7">
              <g id="[3]_9">
                <foreignObject x="549.5" y="393.668" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquisitionCost[3]">
                    {get(props, 'fixed_assets[13].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_9">
                <foreignObject x="525.5" y="393.668" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquisitionCost[2]">
                    {get(props, 'fixed_assets[13].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_9">
                <foreignObject x="501.5" y="393.668" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquisitionCost[1]">
                    {get(props, 'fixed_assets[13].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_9">
                <foreignObject x="477.92" y="393.668" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquisitionCost[0]">
                    {get(props, 'fixed_assets[13].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._7">
              <g id="month_7">
                <foreignObject x="458.5" y="393.668" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquiredAt.month">
                    {get(props, 'fixed_assets[13].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_7">
                <foreignObject x="438.5" y="393.668" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquiredAt.year">
                    {get(props, 'fixed_assets[13].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_7">
                <foreignObject x="423.5" y="393.668" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].acquiredAt.era">
                    {get(props, 'fixed_assets[13].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_7">
              <foreignObject x="401.5" y="393.668" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].quantity">
                  {get(props, 'fixed_assets[13].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_7">
              <foreignObject x="165.5" y="393.668" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[13].name">
                  {get(props, 'fixed_assets[13].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_7">
              <foreignObject x="146.5" y="393.668" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[13].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[13].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_7">
              {get(props, 'fixed_assets[13].type') == '3' && (
                <circle cx="83.5" cy="404.968" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[13].type') == '1' && (
                <circle cx="65.5" cy="404.968" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[12].">
            <g id="cause_8">
              {get(props, 'fixed_assets[12].cause') == '4' && (
                <circle cx="697" cy="386.703" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[12].cause') == '3' && (
                <circle cx="666.5" cy="386.703" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[12].cause') == '2' && (
                <circle cx="697" cy="376.863" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[12].cause') == '1' && (
                <circle cx="666.5" cy="376.863" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_8">
              <foreignObject x="573.5" y="370.563" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].service_life">
                  {get(props, 'fixed_assets[12].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_8">
              <g id="[3]_10">
                <foreignObject x="549.5" y="370.563" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquisitionCost[3]">
                    {get(props, 'fixed_assets[12].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_10">
                <foreignObject x="525.5" y="370.563" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquisitionCost[2]">
                    {get(props, 'fixed_assets[12].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_10">
                <foreignObject x="501.5" y="370.563" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquisitionCost[1]">
                    {get(props, 'fixed_assets[12].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_10">
                <foreignObject x="477.92" y="370.563" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquisitionCost[0]">
                    {get(props, 'fixed_assets[12].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._8">
              <g id="month_8">
                <foreignObject x="458.5" y="370.563" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquiredAt.month">
                    {get(props, 'fixed_assets[12].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_8">
                <foreignObject x="438.5" y="370.563" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquiredAt.year">
                    {get(props, 'fixed_assets[12].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_8">
                <foreignObject x="423.5" y="370.563" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].acquiredAt.era">
                    {get(props, 'fixed_assets[12].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_8">
              <foreignObject x="401.5" y="370.563" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].quantity">
                  {get(props, 'fixed_assets[12].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_8">
              <foreignObject x="165.5" y="370.563" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[12].name">
                  {get(props, 'fixed_assets[12].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_8">
              <foreignObject x="146.5" y="370.563" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[12].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[12].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_8">
              {get(props, 'fixed_assets[12].type') == '3' && (
                <circle cx="83.5" cy="381.863" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[12].type') == '1' && (
                <circle cx="65.5" cy="381.863" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[11].">
            <g id="cause_9">
              {get(props, 'fixed_assets[11].cause') == '4' && (
                <circle cx="697" cy="363.598" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[11].cause') == '3' && (
                <circle cx="666.5" cy="363.598" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[11].cause') == '2' && (
                <circle cx="697" cy="353.758" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[11].cause') == '1' && (
                <circle cx="666.5" cy="353.758" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_9">
              <foreignObject x="573.5" y="347.458" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].service_life">
                  {get(props, 'fixed_assets[11].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_9">
              <g id="[3]_11">
                <foreignObject x="549.5" y="347.458" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquisitionCost[3]">
                    {get(props, 'fixed_assets[11].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_11">
                <foreignObject x="525.5" y="347.458" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquisitionCost[2]">
                    {get(props, 'fixed_assets[11].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_11">
                <foreignObject x="501.5" y="347.458" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquisitionCost[1]">
                    {get(props, 'fixed_assets[11].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_11">
                <foreignObject x="477.92" y="347.458" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquisitionCost[0]">
                    {get(props, 'fixed_assets[11].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._9">
              <g id="month_9">
                <foreignObject x="458.5" y="347.458" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquiredAt.month">
                    {get(props, 'fixed_assets[11].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_9">
                <foreignObject x="438.5" y="347.458" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquiredAt.year">
                    {get(props, 'fixed_assets[11].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_9">
                <foreignObject x="423.5" y="347.458" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].acquiredAt.era">
                    {get(props, 'fixed_assets[11].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_9">
              <foreignObject x="401.5" y="347.458" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].quantity">
                  {get(props, 'fixed_assets[11].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_9">
              <foreignObject x="165.5" y="347.458" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[11].name">
                  {get(props, 'fixed_assets[11].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_9">
              <foreignObject x="146.5" y="347.458" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[11].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[11].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_9">
              {get(props, 'fixed_assets[11].type') == '3' && (
                <circle cx="83.5" cy="358.758" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[11].type') == '1' && (
                <circle cx="65.5" cy="358.758" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[10].">
            <g id="cause_10">
              {get(props, 'fixed_assets[10].cause') == '4' && (
                <circle cx="697" cy="340.493" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[10].cause') == '3' && (
                <circle cx="666.5" cy="340.493" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[10].cause') == '2' && (
                <circle cx="697" cy="330.653" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[10].cause') == '1' && (
                <circle cx="666.5" cy="330.653" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_10">
              <foreignObject x="573.5" y="324.353" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].service_life">
                  {get(props, 'fixed_assets[10].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_10">
              <g id="[3]_12">
                <foreignObject x="549.5" y="324.353" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquisitionCost[3]">
                    {get(props, 'fixed_assets[10].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_12">
                <foreignObject x="525.5" y="324.353" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquisitionCost[2]">
                    {get(props, 'fixed_assets[10].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_12">
                <foreignObject x="501.5" y="324.353" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquisitionCost[1]">
                    {get(props, 'fixed_assets[10].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_12">
                <foreignObject x="477.92" y="324.353" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquisitionCost[0]">
                    {get(props, 'fixed_assets[10].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._10">
              <g id="month_10">
                <foreignObject x="458.5" y="324.353" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquiredAt.month">
                    {get(props, 'fixed_assets[10].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_10">
                <foreignObject x="438.5" y="324.353" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquiredAt.year">
                    {get(props, 'fixed_assets[10].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_10">
                <foreignObject x="423.5" y="324.353" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].acquiredAt.era">
                    {get(props, 'fixed_assets[10].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_10">
              <foreignObject x="401.5" y="324.353" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].quantity">
                  {get(props, 'fixed_assets[10].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_10">
              <foreignObject x="165.5" y="324.353" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[10].name">
                  {get(props, 'fixed_assets[10].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_10">
              <foreignObject x="146.5" y="324.353" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[10].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[10].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_10">
              {get(props, 'fixed_assets[10].type') == '3' && (
                <circle cx="83.5" cy="335.653" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[10].type') == '1' && (
                <circle cx="65.5" cy="335.653" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[9].">
            <g id="cause_11">
              {get(props, 'fixed_assets[9].cause') == '4' && (
                <circle cx="697" cy="317.387" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[9].cause') == '3' && (
                <circle cx="666.5" cy="317.387" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[9].cause') == '2' && (
                <circle cx="697" cy="307.547" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[9].cause') == '1' && (
                <circle cx="666.5" cy="307.547" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_11">
              <foreignObject x="573.5" y="301.247" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].service_life">
                  {get(props, 'fixed_assets[9].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_11">
              <g id="[3]_13">
                <foreignObject x="549.5" y="301.247" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquisitionCost[3]">
                    {get(props, 'fixed_assets[9].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_13">
                <foreignObject x="525.5" y="301.247" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquisitionCost[2]">
                    {get(props, 'fixed_assets[9].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_13">
                <foreignObject x="501.5" y="301.247" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquisitionCost[1]">
                    {get(props, 'fixed_assets[9].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_13">
                <foreignObject x="477.92" y="301.247" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquisitionCost[0]">
                    {get(props, 'fixed_assets[9].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._11">
              <g id="month_11">
                <foreignObject x="458.5" y="301.247" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquiredAt.month">
                    {get(props, 'fixed_assets[9].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_11">
                <foreignObject x="438.5" y="301.247" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquiredAt.year">
                    {get(props, 'fixed_assets[9].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_11">
                <foreignObject x="423.5" y="301.247" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].acquiredAt.era">
                    {get(props, 'fixed_assets[9].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_11">
              <foreignObject x="401.5" y="301.247" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].quantity">
                  {get(props, 'fixed_assets[9].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_11">
              <foreignObject x="165.5" y="301.247" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[9].name">
                  {get(props, 'fixed_assets[9].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_11">
              <foreignObject x="146.5" y="301.247" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[9].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[9].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_11">
              {get(props, 'fixed_assets[9].type') == '3' && (
                <circle cx="83.5" cy="312.547" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[9].type') == '1' && (
                <circle cx="65.5" cy="312.547" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[8].">
            <g id="cause_12">
              {get(props, 'fixed_assets[8].cause') == '4' && (
                <circle cx="697" cy="294.282" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[8].cause') == '3' && (
                <circle cx="666.5" cy="294.282" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[8].cause') == '2' && (
                <circle cx="697" cy="284.442" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[8].cause') == '1' && (
                <circle cx="666.5" cy="284.442" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_12">
              <foreignObject x="573.5" y="278.142" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].service_life">
                  {get(props, 'fixed_assets[8].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_12">
              <g id="[3]_14">
                <foreignObject x="549.5" y="278.142" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquisitionCost[3]">
                    {get(props, 'fixed_assets[8].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_14">
                <foreignObject x="525.5" y="278.142" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquisitionCost[2]">
                    {get(props, 'fixed_assets[8].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_14">
                <foreignObject x="501.5" y="278.142" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquisitionCost[1]">
                    {get(props, 'fixed_assets[8].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_14">
                <foreignObject x="477.92" y="278.142" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquisitionCost[0]">
                    {get(props, 'fixed_assets[8].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._12">
              <g id="month_12">
                <foreignObject x="458.5" y="278.142" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquiredAt.month">
                    {get(props, 'fixed_assets[8].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_12">
                <foreignObject x="438.5" y="278.142" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquiredAt.year">
                    {get(props, 'fixed_assets[8].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_12">
                <foreignObject x="423.5" y="278.142" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].acquiredAt.era">
                    {get(props, 'fixed_assets[8].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_12">
              <foreignObject x="401.5" y="278.142" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].quantity">
                  {get(props, 'fixed_assets[8].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_12">
              <foreignObject x="165.5" y="278.142" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[8].name">
                  {get(props, 'fixed_assets[8].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_12">
              <foreignObject x="146.5" y="278.142" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[8].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[8].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_12">
              {get(props, 'fixed_assets[8].type') == '3' && (
                <circle cx="83.5" cy="289.442" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[8].type') == '1' && (
                <circle cx="65.5" cy="289.442" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[7].">
            <g id="cause_13">
              {get(props, 'fixed_assets[7].cause') == '4' && (
                <circle cx="697" cy="271.177" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[7].cause') == '3' && (
                <circle cx="666.5" cy="271.177" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[7].cause') == '2' && (
                <circle cx="697" cy="261.337" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[7].cause') == '1' && (
                <circle cx="666.5" cy="261.337" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_13">
              <foreignObject x="573.5" y="255.037" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].service_life">
                  {get(props, 'fixed_assets[7].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_13">
              <g id="[3]_15">
                <foreignObject x="549.5" y="255.037" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquisitionCost[3]">
                    {get(props, 'fixed_assets[7].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_15">
                <foreignObject x="525.5" y="255.037" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquisitionCost[2]">
                    {get(props, 'fixed_assets[7].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_15">
                <foreignObject x="501.5" y="255.037" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquisitionCost[1]">
                    {get(props, 'fixed_assets[7].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_15">
                <foreignObject x="477.92" y="255.037" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquisitionCost[0]">
                    {get(props, 'fixed_assets[7].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._13">
              <g id="month_13">
                <foreignObject x="458.5" y="255.037" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquiredAt.month">
                    {get(props, 'fixed_assets[7].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_13">
                <foreignObject x="438.5" y="255.037" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquiredAt.year">
                    {get(props, 'fixed_assets[7].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_13">
                <foreignObject x="423.5" y="255.037" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].acquiredAt.era">
                    {get(props, 'fixed_assets[7].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_13">
              <foreignObject x="401.5" y="255.037" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].quantity">
                  {get(props, 'fixed_assets[7].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_13">
              <foreignObject x="165.5" y="255.037" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[7].name">
                  {get(props, 'fixed_assets[7].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_13">
              <foreignObject x="146.5" y="255.037" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[7].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[7].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_13">
              {get(props, 'fixed_assets[7].type') == '3' && (
                <circle cx="83.5" cy="266.337" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[7].type') == '1' && (
                <circle cx="65.5" cy="266.337" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[6].">
            <g id="cause_14">
              {get(props, 'fixed_assets[6].cause') == '4' && (
                <circle cx="697" cy="248.072" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[6].cause') == '3' && (
                <circle cx="666.5" cy="248.072" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[6].cause') == '2' && (
                <circle cx="697" cy="238.232" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[6].cause') == '1' && (
                <circle cx="666.5" cy="238.232" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_14">
              <foreignObject x="573.5" y="231.932" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].service_life">
                  {get(props, 'fixed_assets[6].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_14">
              <g id="[3]_16">
                <foreignObject x="549.5" y="231.932" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquisitionCost[3]">
                    {get(props, 'fixed_assets[6].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_16">
                <foreignObject x="525.5" y="231.932" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquisitionCost[2]">
                    {get(props, 'fixed_assets[6].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_16">
                <foreignObject x="501.5" y="231.932" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquisitionCost[1]">
                    {get(props, 'fixed_assets[6].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_16">
                <foreignObject x="477.92" y="231.932" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquisitionCost[0]">
                    {get(props, 'fixed_assets[6].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._14">
              <g id="month_14">
                <foreignObject x="458.5" y="231.932" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquiredAt.month">
                    {get(props, 'fixed_assets[6].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_14">
                <foreignObject x="438.5" y="231.932" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquiredAt.year">
                    {get(props, 'fixed_assets[6].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_14">
                <foreignObject x="423.5" y="231.932" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].acquiredAt.era">
                    {get(props, 'fixed_assets[6].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_14">
              <foreignObject x="401.5" y="231.932" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].quantity">
                  {get(props, 'fixed_assets[6].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_14">
              <foreignObject x="165.5" y="231.932" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[6].name">
                  {get(props, 'fixed_assets[6].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_14">
              <foreignObject x="146.5" y="231.932" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[6].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[6].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_14">
              {get(props, 'fixed_assets[6].type') == '3' && (
                <circle cx="83.5" cy="243.232" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[6].type') == '1' && (
                <circle cx="65.5" cy="243.232" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[5].">
            <g id="cause_15">
              {get(props, 'fixed_assets[5].cause') == '4' && (
                <circle cx="697" cy="224.966" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[5].cause') == '3' && (
                <circle cx="666.5" cy="224.966" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[5].cause') == '2' && (
                <circle cx="697" cy="215.126" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[5].cause') == '1' && (
                <circle cx="666.5" cy="215.126" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_15">
              <foreignObject x="573.5" y="208.826" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].service_life">
                  {get(props, 'fixed_assets[5].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_15">
              <g id="[3]_17">
                <foreignObject x="549.5" y="208.826" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquisitionCost[3]">
                    {get(props, 'fixed_assets[5].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_17">
                <foreignObject x="525.5" y="208.826" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquisitionCost[2]">
                    {get(props, 'fixed_assets[5].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_17">
                <foreignObject x="501.5" y="208.826" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquisitionCost[1]">
                    {get(props, 'fixed_assets[5].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_17">
                <foreignObject x="477.92" y="208.826" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquisitionCost[0]">
                    {get(props, 'fixed_assets[5].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._15">
              <g id="month_15">
                <foreignObject x="458.5" y="208.826" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquiredAt.month">
                    {get(props, 'fixed_assets[5].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_15">
                <foreignObject x="438.5" y="208.826" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquiredAt.year">
                    {get(props, 'fixed_assets[5].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_15">
                <foreignObject x="423.5" y="208.826" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].acquiredAt.era">
                    {get(props, 'fixed_assets[5].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_15">
              <foreignObject x="401.5" y="208.826" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].quantity">
                  {get(props, 'fixed_assets[5].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_15">
              <foreignObject x="165.5" y="208.826" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[5].name">
                  {get(props, 'fixed_assets[5].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_15">
              <foreignObject x="146.5" y="208.826" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[5].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[5].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_15">
              {get(props, 'fixed_assets[5].type') == '3' && (
                <circle cx="83.5" cy="220.126" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[5].type') == '1' && (
                <circle cx="65.5" cy="220.126" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[4].">
            <g id="cause_16">
              {get(props, 'fixed_assets[4].cause') == '4' && (
                <circle cx="697" cy="201.861" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[4].cause') == '3' && (
                <circle cx="666.5" cy="201.861" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[4].cause') == '2' && (
                <circle cx="697" cy="192.021" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[4].cause') == '1' && (
                <circle cx="666.5" cy="192.021" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_16">
              <foreignObject x="573.5" y="185.721" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].service_life">
                  {get(props, 'fixed_assets[4].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_16">
              <g id="[3]_18">
                <foreignObject x="549.5" y="185.721" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquisitionCost[3]">
                    {get(props, 'fixed_assets[4].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_18">
                <foreignObject x="525.5" y="185.721" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquisitionCost[2]">
                    {get(props, 'fixed_assets[4].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_18">
                <foreignObject x="501.5" y="185.721" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquisitionCost[1]">
                    {get(props, 'fixed_assets[4].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_18">
                <foreignObject x="477.92" y="185.721" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquisitionCost[0]">
                    {get(props, 'fixed_assets[4].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._16">
              <g id="month_16">
                <foreignObject x="458.5" y="185.721" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquiredAt.month">
                    {get(props, 'fixed_assets[4].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_16">
                <foreignObject x="438.5" y="185.721" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquiredAt.year">
                    {get(props, 'fixed_assets[4].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_16">
                <foreignObject x="423.5" y="185.721" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].acquiredAt.era">
                    {get(props, 'fixed_assets[4].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_16">
              <foreignObject x="401.5" y="185.721" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].quantity">
                  {get(props, 'fixed_assets[4].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_16">
              <foreignObject x="165.5" y="185.721" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[4].name">
                  {get(props, 'fixed_assets[4].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_16">
              <foreignObject x="146.5" y="185.721" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[4].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[4].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_16">
              {get(props, 'fixed_assets[4].type') == '3' && (
                <circle cx="83.5" cy="197.021" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[4].type') == '1' && (
                <circle cx="65.5" cy="197.021" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[3].">
            <g id="cause_17">
              {get(props, 'fixed_assets[3].cause') == '4' && (
                <circle cx="697" cy="178.756" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[3].cause') == '3' && (
                <circle cx="666.5" cy="178.756" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[3].cause') == '2' && (
                <circle cx="697" cy="168.916" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[3].cause') == '1' && (
                <circle cx="666.5" cy="168.916" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_17">
              <foreignObject x="573.5" y="162.616" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].service_life">
                  {get(props, 'fixed_assets[3].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_17">
              <g id="[3]_19">
                <foreignObject x="549.5" y="162.616" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquisitionCost[3]">
                    {get(props, 'fixed_assets[3].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_19">
                <foreignObject x="525.5" y="162.616" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquisitionCost[2]">
                    {get(props, 'fixed_assets[3].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_19">
                <foreignObject x="501.5" y="162.616" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquisitionCost[1]">
                    {get(props, 'fixed_assets[3].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_19">
                <foreignObject x="477.92" y="162.616" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquisitionCost[0]">
                    {get(props, 'fixed_assets[3].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._17">
              <g id="month_17">
                <foreignObject x="458.5" y="162.616" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquiredAt.month">
                    {get(props, 'fixed_assets[3].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_17">
                <foreignObject x="438.5" y="162.616" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquiredAt.year">
                    {get(props, 'fixed_assets[3].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_17">
                <foreignObject x="423.5" y="162.616" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].acquiredAt.era">
                    {get(props, 'fixed_assets[3].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_17">
              <foreignObject x="401.5" y="162.616" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].quantity">
                  {get(props, 'fixed_assets[3].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_17">
              <foreignObject x="165.5" y="162.616" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[3].name">
                  {get(props, 'fixed_assets[3].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_17">
              <foreignObject x="146.5" y="162.616" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[3].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[3].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_17">
              {get(props, 'fixed_assets[3].type') == '3' && (
                <circle cx="83.5" cy="173.916" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[3].type') == '1' && (
                <circle cx="65.5" cy="173.916" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[2].">
            <g id="cause_18">
              {get(props, 'fixed_assets[2].cause') == '4' && (
                <circle cx="697" cy="155.651" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[2].cause') == '3' && (
                <circle cx="666.5" cy="155.651" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[2].cause') == '2' && (
                <circle cx="697" cy="145.811" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[2].cause') == '1' && (
                <circle cx="666.5" cy="145.811" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_18">
              <foreignObject x="573.5" y="139.511" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].service_life">
                  {get(props, 'fixed_assets[2].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_18">
              <g id="[3]_20">
                <foreignObject x="549.5" y="139.511" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquisitionCost[3]">
                    {get(props, 'fixed_assets[2].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_20">
                <foreignObject x="525.5" y="139.511" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquisitionCost[2]">
                    {get(props, 'fixed_assets[2].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_20">
                <foreignObject x="501.5" y="139.511" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquisitionCost[1]">
                    {get(props, 'fixed_assets[2].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_20">
                <foreignObject x="477.92" y="139.511" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquisitionCost[0]">
                    {get(props, 'fixed_assets[2].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._18">
              <g id="month_18">
                <foreignObject x="458.5" y="139.511" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquiredAt.month">
                    {get(props, 'fixed_assets[2].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_18">
                <foreignObject x="438.5" y="139.511" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquiredAt.year">
                    {get(props, 'fixed_assets[2].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_18">
                <foreignObject x="423.5" y="139.511" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].acquiredAt.era">
                    {get(props, 'fixed_assets[2].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_18">
              <foreignObject x="401.5" y="139.511" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].quantity">
                  {get(props, 'fixed_assets[2].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_18">
              <foreignObject x="165.5" y="139.511" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[2].name">
                  {get(props, 'fixed_assets[2].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_18">
              <foreignObject x="146.5" y="139.511" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[2].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[2].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_18">
              {get(props, 'fixed_assets[2].type') == '3' && (
                <circle cx="83.5" cy="150.811" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[2].type') == '1' && (
                <circle cx="65.5" cy="150.811" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[1].">
            <g id="cause_19">
              {get(props, 'fixed_assets[1].cause') == '4' && (
                <circle cx="697" cy="132.545" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[1].cause') == '3' && (
                <circle cx="666.5" cy="132.545" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[1].cause') == '2' && (
                <circle cx="697" cy="122.705" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[1].cause') == '1' && (
                <circle cx="666.5" cy="122.705" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_19">
              <foreignObject x="573.5" y="116.405" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].service_life">
                  {get(props, 'fixed_assets[1].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_19">
              <g id="[3]_21">
                <foreignObject x="549.5" y="116.405" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquisitionCost[3]">
                    {get(props, 'fixed_assets[1].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_21">
                <foreignObject x="525.5" y="116.405" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquisitionCost[2]">
                    {get(props, 'fixed_assets[1].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_21">
                <foreignObject x="501.5" y="116.405" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquisitionCost[1]">
                    {get(props, 'fixed_assets[1].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_21">
                <foreignObject x="477.92" y="116.405" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquisitionCost[0]">
                    {get(props, 'fixed_assets[1].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._19">
              <g id="month_19">
                <foreignObject x="458.5" y="116.405" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquiredAt.month">
                    {get(props, 'fixed_assets[1].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_19">
                <foreignObject x="438.5" y="116.405" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquiredAt.year">
                    {get(props, 'fixed_assets[1].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_19">
                <foreignObject x="423.5" y="116.405" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].acquiredAt.era">
                    {get(props, 'fixed_assets[1].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_19">
              <foreignObject x="401.5" y="116.405" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].quantity">
                  {get(props, 'fixed_assets[1].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_19">
              <foreignObject x="165.5" y="116.405" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[1].name">
                  {get(props, 'fixed_assets[1].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_19">
              <foreignObject x="146.5" y="116.405" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[1].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[1].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_19">
              {get(props, 'fixed_assets[1].type') == '3' && (
                <circle cx="83.5" cy="127.705" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[1].type') == '1' && (
                <circle cx="65.5" cy="127.705" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
          <g id="[0].">
            <g id="cause_20">
              {get(props, 'fixed_assets[0].cause') == '4' && (
                <circle cx="697" cy="109.44" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[0].cause') == '3' && (
                <circle cx="666.5" cy="109.44" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[0].cause') == '2' && (
                <circle cx="697" cy="99.6" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[0].cause') == '1' && (
                <circle cx="666.5" cy="99.6" r="4.5" stroke="black"></circle>
              )}
            </g>
            <g id="service_life_20">
              <foreignObject x="573.5" y="93.3" width="19" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].service_life">
                  {get(props, 'fixed_assets[0].service_life')}
                </div>
              </foreignObject>
            </g>
            <g id="acquisitionCost_20">
              <g id="[3]_22">
                <foreignObject x="549.5" y="93.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquisitionCost[3]">
                    {get(props, 'fixed_assets[0].acquisitionCost[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_22">
                <foreignObject x="525.5" y="93.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquisitionCost[2]">
                    {get(props, 'fixed_assets[0].acquisitionCost[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_22">
                <foreignObject x="501.5" y="93.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquisitionCost[1]">
                    {get(props, 'fixed_assets[0].acquisitionCost[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_22">
                <foreignObject x="477.92" y="93.3" width="22.5" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquisitionCost[0]">
                    {get(props, 'fixed_assets[0].acquisitionCost[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="acquiredAt._20">
              <g id="month_20">
                <foreignObject x="458.5" y="93.3" width="19" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquiredAt.month">
                    {get(props, 'fixed_assets[0].acquiredAt.month')}
                  </div>
                </foreignObject>
              </g>
              <g id="year_20">
                <foreignObject x="438.5" y="93.3" width="20" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquiredAt.year">
                    {get(props, 'fixed_assets[0].acquiredAt.year')}
                  </div>
                </foreignObject>
              </g>
              <g id="era_20">
                <foreignObject x="423.5" y="93.3" width="15" height="22">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].acquiredAt.era">
                    {get(props, 'fixed_assets[0].acquiredAt.era')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="quantity_20">
              <foreignObject x="401.5" y="93.3" width="22" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].quantity">
                  {get(props, 'fixed_assets[0].quantity')}
                </div>
              </foreignObject>
            </g>
            <g id="name_20">
              <foreignObject x="165.5" y="93.3" width="235" height="22">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="fixed_assets[0].name">
                  {get(props, 'fixed_assets[0].name')}
                </div>
              </foreignObject>
            </g>
            <g id="asset_type_for_depreciable_property_tax_code_20">
              <foreignObject x="146.5" y="93.3" width="19" height="22">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="fixed_assets[0].asset_type_for_depreciable_property_tax_code"
                >
                  {get(props, 'fixed_assets[0].asset_type_for_depreciable_property_tax_code')}
                </div>
              </foreignObject>
            </g>
            <g id="type_20">
              {get(props, 'fixed_assets[0].type') == '3' && (
                <circle cx="83.5" cy="104.6" r="4.5" stroke="black"></circle>
              )}
              {get(props, 'fixed_assets[0].type') == '1' && (
                <circle cx="65.5" cy="104.6" r="4.5" stroke="black"></circle>
              )}
            </g>
          </g>
        </g>
        <g id="page">
          <foreignObject x="760" y="22.3" width="30" height="13">
            <div xmlns="http://www.w3.org/1999/xhtml" data-id="page">
              {get(props, 'page')}
            </div>
          </foreignObject>
        </g>
        <g id="total_page">
          <foreignObject x="687" y="22.3" width="30" height="13">
            <div xmlns="http://www.w3.org/1999/xhtml" data-id="total_page">
              {get(props, 'total_page')}
            </div>
          </foreignObject>
        </g>
        <g id="year_21">
          <foreignObject x="399" y="20.8" width="61" height="14.9472">
            <div xmlns="http://www.w3.org/1999/xhtml" data-id="year">
              {get(props, 'year')}
            </div>
          </foreignObject>
        </g>
        <g id="submit_destination.">
          <g id="company_">
            <g id="name_21">
              <foreignObject x="45" y="37" width="210" height="24">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.company_name">
                  {get(props, 'submit_destination.company_name')}
                </div>
              </foreignObject>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
