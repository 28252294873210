import base from './base'

export enum TempDataTypes {
  CREATE_ASSET = 'create-fixed-asset',
  EDIT_ASSET = 'edit-fixed-asset',
}

export const create = (type: string, data: any, extraKey?: string) => {
  return base.post('/v1/temp_data', { type, data, extra_key: extraKey })
}

export const get = (type: string, extraKey?: string) => {
  const params = { extra_key: extraKey }

  return base.get(`/v1/temp_data/${type}`, { params })
}
