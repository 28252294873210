import { css } from '@emotion/css'

export const AutoJournalSettingListClass = css`
  .ant-table {
    border: 1px solid #d4d8dd;
    font-size: 13px;

    .ant-table-thead {
      tr > th {
        background: white;
        border-right: 1px solid #d4d8dd;
        padding: 5px 15px;

        &:last-of-type {
          border-right: none;
        }

        &.parent-header {
          text-align: left;
          color: #7c8291;
          padding: 0 15px;
        }
      }

      tr {
        &:nth-of-type(2) {
          > th {
            border-top: 1px solid #d4d8dd;
          }
        }

        &:nth-of-type(3) {
          > th {
            cursor: default;
            background: #f7f7f7;
            padding: 5px 15px;
          }
        }
      }
    }

    .ant-table-tbody {
      > tr {
        cursor: pointer;
        &.selected {
          td {
            background: #eaf2fd;
          }
        }
        > td {
          border-right: 1px solid #d4d8dd;
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }

    .header-template {
      h4 {
        font-size: 13px;
        margin-bottom: 2px;
        color: #7c8291;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`
