/* eslint-disable react/display-name */
import { ReactComponent as CheckCircleIcon } from 'aa_common/front-end/assets/icons/check-circle.svg'
import { ColumnsType } from 'antd/lib/table'
import { DATE_FORMAT } from 'common/constants'
import { formatMonth, yen } from 'common/helpers/format-helper'
import { loadNs } from 'common/i18n-config'
import moment from 'moment'
import React from 'react'

import { ButtonLink, CheckIcon } from './styles'

const t = loadNs(['components/organisms/assets/asset-detail', 'common'])

const MonthYearTemplate = (data: any, fiscalYear: number) => (
  <span>{t('format.term_year_month', { year: fiscalYear, month: formatMonth(data.month) })}</span>
)

const MoneyTemplate = (amount: string | number) => <span>{amount === undefined ? '-' : yen(amount)}</span>

export const monthSimulationUsageTableSettings = (
  isAccountLedger: boolean,
  isShowComparison: boolean,
  fiscalYear: number,
  isSourceAsset: boolean,
  showComparisonTable: () => void
): ColumnsType<any> => [
  {
    width: 120,
    key: 'term_month',
    className: 'depreciation-cell',
    render: data => ({
      children: data?.id ? (
        MonthYearTemplate(data, fiscalYear)
      ) : (
        <>
          <span className="text">{t('depreciationTable.adjust_depreciation')}</span>{' '}
          <ButtonLink type="link" onClick={showComparisonTable}>
            {t('depreciationTable.adjust_depreciation_link')}
          </ButtonLink>
        </>
      ),
      props: data?.id ? { colSpan: 1 } : { colSpan: 2 },
    }),
  },
  {
    key: 'depreciation_status',
    width: 'auto',
    className: 'depreciation-cell',
    render: data => {
      const isRetiredOrSold = data.is_retired_or_sold

      if (!data || !data?.id) {
        return {
          props: { colSpan: 0 },
        }
      }

      if (isRetiredOrSold && data.retiredAt) {
        return (
          <span className="text">
            {t('depreciationTable.asset_retired_at', {
              retiredAt: moment(data.retiredAt).format(DATE_FORMAT),
            })}
          </span>
        )
      }

      if (isRetiredOrSold && data.soldAt) {
        return (
          <span className="text">
            {t('depreciationTable.asset_sold_at', {
              soldAt: moment(data.soldAt).format(DATE_FORMAT),
            })}
          </span>
        )
      }

      if (isAccountLedger && data?.is_impaired && data?.impairAt) {
        return (
          <span className="text">
            {t('depreciationTable.asset_impair_at', {
              impairAt: moment(data.impairAt).format(DATE_FORMAT),
            })}
          </span>
        )
      }

      if (isSourceAsset && data?.isDivided && data?.dividedAt) {
        return (
          <span className="text">
            {t('depreciationTable.asset_divided_at', {
              dividedAt: moment(data?.dividedAt).format(DATE_FORMAT),
            })}
          </span>
        )
      }

      if (data.monthHasBeenAppliedDepreciation) {
        return <CheckIcon component={CheckCircleIcon} />
      }

      if (data.isActive) {
        return <span className="text">{t('depreciation_calculation_processing')}</span>
      }
    },
  },
  {
    title: t('depreciationTable.beginning_book_value'),
    width: 130,
    dataIndex: 'beginning_book_value',
    key: 'beginning_book_value',
    showSorterTooltip: false,
    className: 'depreciation-cell detail-usage right',
    render: MoneyTemplate,
  },
  {
    title: t('depreciationTable.depreciation_amount'),
    width: 130,
    dataIndex: 'depreciation_amount',
    key: 'depreciation_amount',
    showSorterTooltip: false,
    className: 'depreciation-cell detail-usage right',
    render: MoneyTemplate,
  },
  {
    title: t('depreciationTable.end_of_term_book_value'),
    dataIndex: 'end_of_term_book_value',
    key: 'end_of_term_book_value',
    width: 130,
    className: 'depreciation-cell detail-usage right',
    render: MoneyTemplate,
  },
  {
    title: t('depreciationTable.accumulated_depreciation'),
    width: 130,
    className: 'depreciation-cell detail-usage right',
    dataIndex: isShowComparison ? 'adjusted_accumulated_depreciation_amount' : 'accumulated_depreciation_amount',
    key: isShowComparison ? 'adjusted_accumulated_depreciation_amount' : 'accumulated_depreciation_amount',
    render: MoneyTemplate,
  },
  {
    width: 30,
    render: () => null,
  },
]
