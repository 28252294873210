import styled from '@emotion/styled'

export const StyledTable = styled.table`
  font-size: 13px;
  background: white;
  border-collapse: separate;
  border-radius: 4px;
  border-spacing: 0px;
  min-width: 821px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #d4d8dd;

  &:last-child {
    margin-bottom: 0;
  }

  thead {
    th {
      border-right: 1px solid #d4d8dd;

      &:last-of-type {
        border-right: none;
      }
    }

    tr:first-of-type {
      th {
        border-bottom: 1px solid #d4d8dd;
      }

      th:first-of-type {
        border-radius: 4px 0 0 0;
        -moz-border-radius: 4px 0 0 0;
        -webkit-border-radius: 4px 0 0 0;
      }

      th:last-of-type {
        border-radius: 0 4px 0 0;
        -moz-border-radius: 0 4px 0 0;
        -webkit-border-radius: 0 4px 0 0;
      }
    }

    tr:last-of-type {
      th {
        border-bottom: 1px solid #d4d8dd;
        border-right: 1px solid #d4d8dd;
      }
    }

    th {
      &.header-level-0 {
        padding: 5px;
        text-align: center;
        background-color: #e9f1fc;
      }
    }
  }

  tbody tr {
    th {
      border-right: 1px solid #d4d8dd;
    }

    &:last-child {
      th:first-of-type {
        border-radius: 0 0 0 4px;
        -moz-border-radius: 0 0 0 4px;
        -webkit-border-radius: 0 0 0 4px;
      }
      td:last-child {
        border-radius: 0 0 4px 0;
        -moz-border-radius: 0 0 4px 0;
        -webkit-border-radius: 0 0 4px 0;
      }
    }
  }

  .section-name,
  .category-header {
    text-align: center;
    background: #f7f7f7;
  }

  .category-header {
    padding: 5px;
    border-bottom: 1px solid #d4d8dd;
  }

  .section-name {
    padding: 5px 0px;
    width: 110px;
  }

  .view-table-cell,
  .input-field {
    min-width: 130px;
    max-width: 130px;
    padding: 3px 10px;
    border-right: none;
  }

  .section-name {
    + .input-field + .input-field,
    + .view-table-cell + .view-table-cell,
    + td[colspan] {
      border-right: 1px solid #d4d8dd;
    }

    + .category-header + .category-header {
      border-right: 1px solid #d4d8dd;
    }

    &:last-of-type {
      + .input-field + .input-field,
      + .view-table-cell + .view-table-cell,
      + td[colspan],
      + .category-header + .category-header {
        border-right: none;
      }
    }
  }

  .input-field {
    .ant-select {
      min-width: 130px;
      max-width: 130px;
    }
    + .input-field {
      border-left: initial;
    }
  }
`

export const TableTitle = styled.h4`
  font-size: 13px;
  margin-bottom: 0;
  color: #7c8291;
`
