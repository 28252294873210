import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .upload-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .anticon-plus {
    background: #7c8291;
    color: #ffffff;
  }

  .ant-upload {
    &.ant-upload-select-picture-card {
      height: 54px;
      width: 54px;
      box-sizing: border-box;
      margin-right: 0px;

      & > .ant-upload {
        font-size: 10px;
        padding: 7px;
      }
    }
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
  }
`

export const Thumbnail = styled.div`
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  box-sizing: border-box;
  height: 54px;
  margin-right: 6px;
  padding: 1px;
  position: relative;
  width: 54px;
  margin-bottom: 8px;

  .image-item {
    img {
      width: 49px;
      height: 49px;
    }

    &:before {
      position: absolute;
      z-index: 1;
      width: 49px;
      height: 49px;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      content: ' ';
    }
  }

  &:hover {
    .image-item {
      &:before {
        opacity: 1;
      }
    }
  }
  z-index: 1;

  &:hover {
    .edit-icon {
      opacity: 1;
      transition: all 0.8s;
    }

    .remove-icon {
      opacity: 1;
      transition: all 0.8s;
    }
  }
`

export const Image = styled.div`
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  padding: 8px;

  img {
    width: 100%;
  }
`

export const EditIcon = styled.div`
  position: absolute;
  top: 14px;
  left: 8px;
  color: #ffffff;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
`

export const RemoveIcon = styled.div`
  position: absolute;
  top: 14px;
  left: 32px;
  color: #ffffff;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
`

export const Memo = styled.div`
  margin-top: 8px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 3px;
  }

  input {
    border: 1px solid #d4d8dd;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 7px 8px;
    font-size: 13px;

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
    }
  }
`
