import { OfficeSelectTable } from 'components/organisms'
import { SessionTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { fetchOfficesRequest, selectOfficeRequest } from 'store/office/actions'
import { selectOfficeList, selectOfficeListErrors } from 'store/office/selectors'

const OfficeSelectPage = () => {
  const dispatch = useDispatch()
  const officeList = useSelector(selectOfficeList, isEqual)
  const officeListErrors = useSelector(selectOfficeListErrors, isEqual)

  useEffect(() => {
    dispatch(fetchOfficesRequest())
  }, []) // eslint-disable-line

  useEffect(() => {
    document.title = i18n.t('common.page_title')
  }, [])

  useEffect(() => {
    if (officeList.data && officeList.data.length === 0) {
      dispatch(changeLocation('/login'))
    }
  }, [dispatch, officeList.data])

  const handleSwitch = useCallback(
    (office: any) => {
      dispatch(selectOfficeRequest(office.id))
    },
    [dispatch]
  )

  return (
    <SessionTemplate>
      <OfficeSelectTable
        offices={officeList.data}
        loading={officeList.loading}
        errors={officeListErrors}
        handleSwitch={handleSwitch}
      />
    </SessionTemplate>
  )
}

export default OfficeSelectPage
