import FormField from 'aa_common/front-end/components/form/form-field'
import { UnderlineText } from 'aa_common/front-end/components/underline-text'
import { yen } from 'common/helpers'
import i18n from 'i18n'

import { BookDateItem, BookValueItem, DividingBookingDataProps } from './schema'
import { CollapSeDiViDing } from './styles'

export const LedgerInformationField = (props: {
  collapse: boolean
  dividingBookingData: DividingBookingDataProps
  collapseAction: () => void
  loading?: boolean
}) => {
  const Width_UnderlineText = '240px'
  const { collapse, dividingBookingData, collapseAction, loading } = props
  return (
    <div style={{ position: 'relative', display: 'inline-block' }} className={CollapSeDiViDing}>
      <div style={{ position: 'relative' }}>
        <FormField className="multilField" title={i18n.t('components.AssetDividing.standard_date_to_show_book_value')}>
          <div style={{ display: 'flex' }}>
            {(dividingBookingData.bookDate ?? []).map((item: BookDateItem, index: number) => (
              <div
                className="date"
                style={{ marginLeft: index > 0 ? 34 : 0 }}
                key={`bookDate${item.ledger_setting_id}`}
              >
                <UnderlineText loading={loading} contentAlign="right" width={Width_UnderlineText}>
                  <div className="content" style={{ textAlign: 'left' }}>
                    {item.content}
                  </div>
                </UnderlineText>
              </div>
            ))}
          </div>
        </FormField>
        <FormField className="multilField" title={i18n.t('components.AssetDividing.book_value_when_dividing')}>
          <div style={{ display: 'flex' }}>
            {(dividingBookingData.bookValue ?? []).map((item: BookValueItem, index: number) => (
              <div
                className="bookValue"
                style={{ marginLeft: index > 0 ? 34 : 0 }}
                key={`bookValue${item.ledger_setting_id}`}
              >
                <UnderlineText loading={loading} contentAlign="right" width={Width_UnderlineText}>
                  <div className="name">{item?.name}</div>
                  <div className="value">{yen(String(item.value))}</div>
                </UnderlineText>
              </div>
            ))}
          </div>
        </FormField>
        {dividingBookingData.bookValue.length > 0 && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className="collapseBtn"
            style={{ right: dividingBookingData?.bookDate?.length === 2 ? -30 : -48 }}
            onClick={() => collapseAction()}
            data-testid="collapseBtn"
          >
            {collapse
              ? i18n.t('components.AssetDividing.collapse_in')
              : i18n.t('components.AssetDividing.collapse_out')}
          </div>
        )}
      </div>
    </div>
  )
}
