import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonProps, Dropdown, Menu, Tooltip } from 'antd'
import { JOURNAL_EVENT_TYPE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { JournalData } from 'common/models'
import AccountingPlusLink from 'components/atoms/AccountingPlusLink'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectJournalList } from 'store/accounting/journal/selectors'
import { changeLocation } from 'store/app/actions'

import { Button, MenuIcon, MenuStyle, TooltipWrapper } from './styles'

type ButtonWithTooltipProps = ButtonProps & {
  tooltipContent?: React.ReactNode
}

const t = loadNs(['components/organisms/accounting/journal-list'], 'menu')
const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = ({ tooltipContent, children, ...buttonProps }) => {
  return (
    <Tooltip
      overlayStyle={{ maxWidth: 'unset', pointerEvents: 'none' }}
      overlayInnerStyle={{ borderRadius: 4, padding: 10 }}
      title={tooltipContent}
      placement="top"
      arrowPointAtCenter
      getPopupContainer={triggerNode => triggerNode}
    >
      <TooltipWrapper>
        <Button {...buttonProps}>{children}</Button>
      </TooltipWrapper>
    </Tooltip>
  )
}

const JournalActions = ({ journalRecord }: { journalRecord: JournalData }) => {
  const dispatch = useDispatch()
  const { data: journalList } = useSelector(selectJournalList)

  const goToDetail = (event: any) => {
    event.stopPropagation()
    if (journalRecord.fixed_asset_biid) {
      dispatch(changeLocation(`/assets/${journalRecord.fixed_asset_biid}?valid_at=${journalRecord.recognized_at}`))
    } else {
      dispatch(changeLocation(`/assets?journal_id=${journalRecord.id}`))
    }
  }

  const redirectUrl = useMemo(() => {
    return `/${journalRecord?.submit_response?.split('/').slice(3).join('/')}`
  }, [journalRecord])

  const isSaleOrRetireEvent = journalList?.some(
    (journal: any) =>
      journal.id === journalRecord.id &&
      [JOURNAL_EVENT_TYPE.SOLD, JOURNAL_EVENT_TYPE.RETIREMENT].includes(journal.journal_event_type - 1)
  ) // Minus 1 because the definition on the BE side is adding 1
  const isAssetInfoDisabled = journalRecord.is_reversed_journal && !isSaleOrRetireEvent

  const Actions = (
    <Menu className={MenuStyle} onClick={event => event.domEvent.stopPropagation()}>
      <Menu.Item disabled={isAssetInfoDisabled} onClick={event => goToDetail(event.domEvent)} key="asset-info">
        <ButtonWithTooltip
          tooltipContent={isAssetInfoDisabled ? t('cannot_view_asset') : undefined}
          type="text"
          disabled={isAssetInfoDisabled}
          className="item-button"
        >
          {t('asset_info')}
        </ButtonWithTooltip>
      </Menu.Item>
      {journalRecord.hasSubmitResult && !journalRecord.isError && (
        <Menu.Item onClick={event => event.domEvent.stopPropagation()} key="acc-plus-detail">
          <AccountingPlusLink className="acc-plus-url" path={redirectUrl}>
            <span>
              {t('acc_plus_detail')}
              <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: 5 }} />
            </span>
          </AccountingPlusLink>
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <Dropdown
      placement="bottomRight"
      overlay={Actions}
      trigger={['click']}
      getPopupContainer={triggerNode => triggerNode}
    >
      <Button className="menu-dot" type="text" onClick={event => event.stopPropagation()}>
        <MenuIcon />
      </Button>
    </Dropdown>
  )
}

export default JournalActions
