import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AssetCopyContainer } from 'containers/assets/asset-copy-container'
import React from 'react'

import AssetCopyPageOld from './asset-copy-page'

const AssetCopyPage: React.FC = () => {
  const { isEnable: isRefactorPagePhase1_4 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase1_4)

  return <>{isRefactorPagePhase1_4 ? <AssetCopyContainer /> : <AssetCopyPageOld />}</>
}

export default AssetCopyPage
