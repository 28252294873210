import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Layout, Menu } from 'antd'
import Icon from '@ant-design/icons'

const MAIN_MENU_BACKGROUND_COLOR = '#32373F'

export const Wrapper = styled.div`
  background: ${MAIN_MENU_BACKGROUND_COLOR};
  padding: 0px;
  font-size: 14px;
  z-index: 100;

  .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 22px;
  }

  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    margin-left: 0;
  }

  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    &::before,
    &::after {
      background-image: linear-gradient(to right, #c2d1fc, #c2d1fc);
    }
  }

  .ant-menu-submenu-popup {
    .ant-menu-item-selected {
      border-bottom: 1px solid #3b7de9;
    }
  }

  .depreciation-icon {
    font-size: 19px !important;
    margin-left: 1px !important;
  }

  .ant-menu-item {
    #fixed-asset-icon {
      svg rect:nth-of-type(1) {
        fill: #32373f;
        transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  .selected-subnav,
  .ant-menu-item-selected {
    #fixed-asset-icon {
      svg rect:nth-of-type(1) {
        stroke: white;
        fill: #3b7de9;
      }
    }
  }

  .ant-menu-item-active:not(.ant-menu-item-selected) {
    #fixed-asset-icon {
      svg rect:nth-of-type(1) {
        stroke: white;
        fill: #424954;
      }
    }
  }
`

export const List = styled.ul`
  list-style-type: none;
`

export const SidebarMenu = styled(Layout.Sider)`
  background-color: ${MAIN_MENU_BACKGROUND_COLOR};
  position: sticky;
  top: 40px;
  height: calc(100vh - 40px);
  .ant-layout-sider-trigger {
    background: ${MAIN_MENU_BACKGROUND_COLOR};
  }
`

export const SidebarMenuWrapper = styled(Menu)`
  background-color: inherit;
  ul {
    background: inherit;
  }
  &.ant-menu-inline-collapsed {
    width: unset;
  }
`

export const NavItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  background-color: ${MAIN_MENU_BACKGROUND_COLOR};
  font-size: 14px;
  line-height: 16px;
  color: #c2d1fc;
  padding-left: 10px !important;
  border-bottom: 1px solid #424954;

  &.ant-menu-item {
    a {
      color: #c2d1fc;
    }
    max-height: 40px;
    height: 100%;
    a {
      color: inherit;
    }

    padding-right: 0px;
    letter-spacing: -1px;
    margin: 0 !important;

    &.ant-menu-item-active {
      background: #424954;
      color: white;
    }

    &.ant-menu-item-disabled {
      opacity: 0.5;
      color: #d4d8dd !important;
      a {
        color: inherit !important;
      }
    }
  }

  &.ant-menu-item-selected {
    background: #3b7de9 !important;
    color: white;
    letter-spacing: -1px;

    a {
      color: white;
    }

    &::after {
      right: unset;
      left: 0;
      border-right: 4px solid #3b7de9;
      border-bottom: 1px solid #3b7de9;
    }
  }

  .ant-menu-inline-collapsed & {
    padding-left: 15px !important;
  }

  .ant-menu-inline & {
    .ant-menu-submenu & {
      padding-left: 16px !important;
      font-size: 13px;
    }

    &.ant-menu-item {
      width: unset;
    }
  }
`

export const NavItemIcon = styled(Icon)`
  &.anticon {
    font-size: 22px;
    margin-right: 10px;
    vertical-align: -0.28em;

    .ant-menu-inline-collapsed & {
      font-size: 22px;
    }
  }
  .selected-subnav &,
  .ant-menu-item-selected &,
  .ant-menu-item-active &,
  .ant-menu-submenu-active & {
    svg path {
      fill: white;
      transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    svg rect {
      fill: white;
      transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    + span {
      color: white;
    }
  }
`

export const NavTextLabel = styled.span`
  color: inherit;
`

export const NavSubMenu = styled(Menu.SubMenu)`
  font-size: 14px;
  line-height: 16px;
  color: #c2d1fc;
  border-bottom: none;

  &.ant-menu-submenu {
    border-bottom: 1px solid #424954;
    > .ant-menu {
      background: ${MAIN_MENU_BACKGROUND_COLOR};
    }
    &.ant-menu-item {
      height: 200px;
    }
    &.ant-menu-submenu-title {
      &:active {
        background: #3b7de9;
      }
      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background-image: linear-gradient(to right, #c2d1fc, #c2d1fc);
        }
      }
      &:hover,
      &:active {
        .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background-image: linear-gradient(to right, white, white);
          }
        }
      }
    }

    &.ant-menu-submenu-vertical {
      .ant-menu-submenu-arrow {
        display: none;
      }
    }

    &.ant-menu-submenu-inline,
    &.ant-menu-submenu-vertical {
      &.selected-subnav,
      &.ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: white;
        }
      }
      &.ant-menu-submenu-active {
        &:active {
          background: #3b7de9;
        }
      }
      &.selected-subnav {
        background: #3b7de9;
      }
    }
  }

  .ant-menu-inline-collapsed & {
    .ant-menu-submenu-title {
      padding-left: 15px !important;
    }
  }

  .ant-menu-submenu-title {
    color: #c2d1fc;
    padding-left: 10px !important;
    padding-right: 0px;
    letter-spacing: -1px;
    margin: 0;

    & span:nth-of-type(2) {
      width: 80px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.ant-menu-submenu-disabled {
    .ant-menu-submenu-title {
      color: #d4d8dd !important;
      opacity: 0.5;
    }
  }
`

export const styleSidebarMenuWrapperClass = css`
  height: 100%;
  border-right: 0;
`

export const subnavMenuPopupClass = css`
  > .ant-menu {
    background: #424954;
    border-radius: 0;
    box-shadow: none;
    max-height: 480px;
    .ant-menu-item {
      background: #424954;
      padding: 0 12px !important;
      max-width: 600px;
      margin: 0;
      border-bottom: 1px solid #424954;
      height: 41px;
    }
  }
`
