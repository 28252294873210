import { LinkLabel } from 'aa_common/front-end/components'
import * as ejhAPI from 'api/app/exportJournalHistory'
import { formatDateTimeNoDot } from 'common/helpers/format-helper'
import React from 'react'

import { HistoryRecordType } from './model'
import { HistoryRecord, WrapperHistory } from './styles'

export interface Props {
  historyRecords: HistoryRecordType[]
  firstCellRenderer?: (h: HistoryRecordType) => React.ReactNode
}

const HistoryList = ({ historyRecords, firstCellRenderer }: Props) => (
  <WrapperHistory>
    {historyRecords?.map(history => {
      const {
        id,
        file_name,
        created_at,
        user_info: { display_name },
      } = history

      return (
        <HistoryRecord key={id}>
          {firstCellRenderer?.(history) ?? (
            <LinkLabel href={ejhAPI.downloadFileURL(id)} target="_blank" className="history-name">
              {file_name}
            </LinkLabel>
          )}
          <div>
            <span className="history-info">{formatDateTimeNoDot(created_at)}</span>
            <span className="history-info">{display_name}</span>
          </div>
        </HistoryRecord>
      )
    })}
  </WrapperHistory>
)

export default HistoryList
