import styled from '@emotion/styled'
import { LINK_COLOR } from 'common/constants/styles'
import { Typography } from 'antd'

const { Link } = Typography

export const StyledLink = styled(Link)`
  font-size: 13px;
  max-width: 100%;

  &.ant-typography,
  &.ant-typography a {
    color: ${LINK_COLOR};
  }

  &.ant-typography-danger {
    color: #ec4949;
  }
`
