import { CsvCharset } from 'common/models'

import {
  CLEANUP_CAUSE,
  CREATE_CAUSE_REQUEST,
  DELETE_CAUSE_REQUEST,
  EXPORT_CAUSE_REQUEST,
  FETCH_CAUSE_REQUEST,
  FETCH_CAUSES_FAILURE,
  FETCH_CAUSES_REQUEST,
  FETCH_CAUSES_REQUEST_AUTH,
  FETCH_CAUSES_SUCCESS,
  IMPORT_CAUSE_REQUEST,
  UPDATE_CAUSE_REQUEST,
} from './constant'
import { DeleteCausePayload } from './model'

export const fetchCauseList = () => {
  return {
    type: FETCH_CAUSES_REQUEST,
  }
}

export const fetchCauseListAuth = () => ({
  type: FETCH_CAUSES_REQUEST_AUTH,
})

export const fetchCauseListSuccess = (payload: any) => ({
  type: FETCH_CAUSES_SUCCESS,
  payload,
})

export const fetchCauseListFailure = (payload: any) => ({
  type: FETCH_CAUSES_FAILURE,
  payload,
})

export const createCause = (payload: any) => {
  return {
    type: CREATE_CAUSE_REQUEST,
    payload,
  }
}

export const deleteCause = (payload: DeleteCausePayload) => {
  return {
    type: DELETE_CAUSE_REQUEST,
    payload,
  }
}

export const fetchCause = (id: number) => {
  return {
    type: FETCH_CAUSE_REQUEST,
    id,
  }
}

export const updateCause = (id: number, payload: any) => {
  return {
    type: UPDATE_CAUSE_REQUEST,
    id,
    payload,
  }
}

export const cleanupCause = (): any => {
  return {
    type: CLEANUP_CAUSE,
  }
}

export const exportCauses = (charset: CsvCharset) => {
  return {
    type: EXPORT_CAUSE_REQUEST,
    payload: charset,
  }
}

export const importCauses = (charset: CsvCharset, file: any) => {
  return {
    type: IMPORT_CAUSE_REQUEST,
    payload: charset,
    file,
  }
}
