import { RequiredLabel } from 'aa_common/front-end/components'
import React, { memo } from 'react'

import { ItemForm } from './styles'

interface FormFieldProps {
  label: string
  children: React.ReactChild // applying this - children will be required.
  required?: boolean
}

const FormField = ({ label, children, required }: FormFieldProps) => {
  return (
    <ItemForm className="item-form">
      <div className="label">
        <span>{label}</span>
      </div>
      <div className="content">
        {required && <RequiredLabel className="required" />}
        {children}
      </div>
    </ItemForm>
  )
}

export default memo(FormField)
