import * as reportApi from 'api/app/report'
import { parseError } from 'common/helpers'
import { call, put, takeLatest } from 'redux-saga/effects'
import { setLoadingApp, showErrorsAppCall } from 'store/app/actions'

import { handleFetchAssetEventsReport, handleFetchSummaryFixedAssetEvents } from './actions'
import { ACTION } from './constant'

export function* fetchMonthlyClosingAssetEvents({ payload }: any) {
  yield put(setLoadingApp(true))
  try {
    const { termMonthId, changeSituationCode, pageNumber, pageSize } = payload
    const { data } = yield call(reportApi.getFixedAssetEvents, termMonthId, changeSituationCode, pageNumber, pageSize)
    const payloadData = {
      data: data?.data || [],
      total: data?.meta?.total || 0,
    }

    yield put(handleFetchAssetEventsReport(payloadData))
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
  yield put(setLoadingApp(false))
}

export function* fetchSummaryFixedAssetEvents({ payload }: any) {
  try {
    const { data } = yield call(reportApi.getSummaryFixedAsset, payload)

    yield put(handleFetchSummaryFixedAssetEvents(data?.data || []))
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
    yield put(handleFetchSummaryFixedAssetEvents([]))
  }
}

export default function* actionWatcher(): Generator<any> {
  yield takeLatest(ACTION.FETCH_ASSET_EVENTS_REPORT, fetchMonthlyClosingAssetEvents)
  yield takeLatest(ACTION.FETCH_SUMMARY_FIXED_ASSET_EVENTS, fetchSummaryFixedAssetEvents)
}
