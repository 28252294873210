/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DtoJobSwagger, PresenterError } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AsyncJob<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description GetJob return status of async job
   *
   * @tags async_job
   * @name GetAsyncJob
   * @summary GetJob return status of async job
   * @request GET:/asyncjob/:id
   */
  getAsyncJob = (id: string, params: RequestParams = {}) =>
    this.http.request<DtoJobSwagger, PresenterError>({
      path: `/asyncjob/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
