import { message as messageAnt } from 'antd'
import React, { createContext, ReactNode, useContext } from 'react'

const VISIBLE_DURATION = 3 // seconds
messageAnt.config({
  top: 60,
  duration: VISIBLE_DURATION,
})

type MessageInstance = ReturnType<typeof messageAnt.useMessage>[0]

const AntMessageInstanceProvider = ({ children }: { children: ReactNode }) => {
  const [api, antContextHolder] = messageAnt.useMessage()

  return (
    <AntMessageContext.Provider value={api}>
      {antContextHolder}
      {children}
    </AntMessageContext.Provider>
  )
}

const AntMessageContext = createContext<any>({})

export default function useAntMessageInstance() {
  return useContext<MessageInstance>(AntMessageContext)
}

export { AntMessageContext, AntMessageInstanceProvider }
