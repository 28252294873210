import Icon from '@ant-design/icons'
import styled from '@emotion/styled'

import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check-circle.svg'
import { messageFn } from './message-function.component'

export const SuccessIcon = styled(Icon)`
  &.anticon {
    color: #3b7de9;
  }
`
export const messageSuccess = (content: string) => {
  return messageFn().success({
    content,
    icon: <SuccessIcon component={CheckCircleIcon} />,
  })
}
