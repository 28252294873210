import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkLabel } from 'aa_common/front-end/components'
import { OfficeUser, Role, UserFormPayload } from 'aa_common/front-end/models'
import { updateObjectValues } from 'common/helpers/object-helper'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import Form from 'components/molecules/Form'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserInfoSelector } from 'store/session/selectors'

import CoreUserForm from './Form'
import { TextWrapper, Wrapper } from './styles'

const t = loadNs(['components/organisms/settings/user-form'])

interface UserFormProps {
  roles: Role[]
  creatableUsers?: OfficeUser[]
  values?: UserFormPayload
  loading: boolean
  errors: any
  type: FORM_MODES.NEW | FORM_MODES.EDIT
  onSubmit: (formValues: any) => void
}

const initialValues = {
  id: null,
  user_id: null,
  name: null,
  email: null,
  role_biids: [],
}

const REACT_APP_NAVIS_SERVICE_URL = process.env.REACT_APP_NAVIS_SERVICE_URL as string

const UserForm = ({ values, creatableUsers, roles, errors, loading, type, onSubmit }: UserFormProps) => {
  const [formValues, setFormValues] = useState(initialValues)
  const userInfo = useSelector(getUserInfoSelector, isEqual)
  const encodeParameterLink = encodeURIComponent(
    `${REACT_APP_NAVIS_SERVICE_URL}/sso?tenant_uid=${userInfo?.office_id}&redirect_path=${REACT_APP_NAVIS_SERVICE_URL}/app&login_hint=${userInfo?.email}`
  )

  useEffect(() => {
    if (values && type === FORM_MODES.EDIT) {
      const objectValues = updateObjectValues(initialValues, values)
      setFormValues({ ...objectValues })
    }
  }, [values, type])

  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 10 }}>
        <TextWrapper>
          {t('user_does_not_exist')}
          <a href={decodeURIComponent(encodeParameterLink)} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              style={{ marginLeft: '8px', color: '#3B7DE9' }}
              icon={faExternalLinkAlt}
              className="open-icon"
            />
            <span
              style={{
                margin: '0 8px',
                color: '#3B7DE9',
              }}
            >
              {t('add_new_user_in_navis')}
            </span>
          </a>
          {t('here')}
          <LinkLabel
            style={{
              margin: '0 8px',
              color: '#3B7DE9',
            }}
            onClick={() => window.location.reload()}
          >
            {t('reloading_page')}
          </LinkLabel>
          {t('please')}
        </TextWrapper>
      </div>
      <Form value={formValues} errors={errors} onFormSubmit={onSubmit} loading={loading}>
        {form => <CoreUserForm creatableUsers={creatableUsers} type={type} formModule={form} roles={roles} />}
      </Form>
    </Wrapper>
  )
}

export default UserForm
