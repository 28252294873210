import { JournalLinkageDetailData } from 'common/models'
import { Map } from 'immutable'

import { JournalActions } from './action.model'
import {
  CLEANUP_JOURNAL_SETTINGS,
  CLEAR_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS,
  CLEAR_SEND_JOURNAL_RESULT,
  DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_FAILURE,
  DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST,
  DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_SUCCESS,
  DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE,
  DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
  DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS,
  EXPORT_JOURNAL_FAILURE,
  EXPORT_JOURNAL_LINKAGE_FAILURE,
  EXPORT_JOURNAL_LINKAGE_REQUEST,
  EXPORT_JOURNAL_LINKAGE_SUCCESS,
  EXPORT_JOURNAL_REQUEST,
  EXPORT_JOURNAL_SUCCESS,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_FAILURE,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_SUCCESS,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_FAILURE,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST,
  FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_SUCCESS,
  FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_FAILURE,
  FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST,
  FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_SUCCESS,
  FETCH_JOURNAL_LINKAGE_DETAIL_FAILURE,
  FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST,
  FETCH_JOURNAL_LINKAGE_DETAIL_SUCCESS,
  FETCH_JOURNAL_LINKAGE_STATUS_FAIL,
  FETCH_JOURNAL_LINKAGE_STATUS_REQUEST,
  FETCH_JOURNAL_LINKAGE_STATUS_SUCCESS,
  FETCH_JOURNALS_FAILURE,
  FETCH_JOURNALS_REQUEST,
  FETCH_JOURNALS_SUCCESS,
  HANDLE_FETCH_COUNT_OF_JOURNAL_EVENTS,
  SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE,
  SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
  SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS,
  SAVE_JOURNAL_ITEM_SETTINGS_FAILURE,
  SAVE_JOURNAL_ITEM_SETTINGS_REQUEST,
  SAVE_JOURNAL_ITEM_SETTINGS_SUCCESS,
  SEND_JOURNAL_DONE,
  SEND_JOURNAL_FAIL_PARTIAL,
  SEND_JOURNAL_REQUEST,
  SET_TOTAL_JOURNALS,
  UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST,
  UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST,
} from './constant'

export const initialState = Map({
  list: Map({
    data: [],
    total: 0,
    errors: [],
    loading: false,
  }),
  assetCategory: Map({
    list: Map({
      data: [],
      loading: false,
      onTabChanged: null,
    }),
    selectedItem: Map({
      data: [],
      loading: false,
    }),
  }),
  generalSettings: Map({
    terms: Map({
      data: [],
      loading: false,
    }),
  }),
  countOfJournalEvents: {},
  journalLinkageStatus: {
    data: {},
    loading: false,
    errors: [],
  },
  sendJournalLoading: {
    loading: false,
  },
  journalLinkageSendResult: {
    historyId: null,
    failTotal: 0,
  },
  journalLinkageDetail: {
    data: [],
    exportNumber: 0,
    termMonth: null,
    totalFail: 0,
    summaryErrorType: '',
    journalLinkageStatus: null,
    loading: false,
  } as JournalLinkageDetailData,
})

export default (state = initialState, action: JournalActions) => {
  switch (action.type) {
    case FETCH_JOURNALS_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_JOURNALS_SUCCESS:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'errors'], [])
        .setIn(['list', 'data'], action.payload)
    case FETCH_JOURNALS_FAILURE:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'errors'], action.payload)
        .setIn(['list', 'data'], [])
    case FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST:
      return state.setIn(['journalLinkageDetail', 'loading'], true)
    case FETCH_JOURNAL_LINKAGE_DETAIL_SUCCESS:
      return state.set('journalLinkageDetail', { ...action.payload, loading: false })
    case FETCH_JOURNAL_LINKAGE_DETAIL_FAILURE:
      return state.setIn(['journalLinkageDetail', 'loading'], false)
    case SET_TOTAL_JOURNALS:
      return state.setIn(['list', 'total'], action.payload)
    case HANDLE_FETCH_COUNT_OF_JOURNAL_EVENTS:
      return state.set('countOfJournalEvents', action.payload)
    case FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST:
      return state.setIn(['assetCategory', 'list', 'loading'], true)
    case FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_SUCCESS:
      return state
        .setIn(['assetCategory', 'list', 'loading'], false)
        .setIn(['assetCategory', 'list', 'data'], action.payload)
    case FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_FAILURE:
      return state.setIn(['assetCategory', 'list', 'loading'], false)
    case FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST:
      return state.setIn(['assetCategory', 'selectedItem', 'loading'], true)
    case FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_SUCCESS:
      return state
        .setIn(['assetCategory', 'selectedItem', 'loading'], false)
        .setIn(['assetCategory', 'selectedItem', 'data'], action.payload || [])
    case FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_FAILURE:
      return state
        .setIn(['assetCategory', 'selectedItem', 'loading'], false)
        .setIn(['assetCategory', 'list', 'loading'], false)
    case DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST:
    case UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST:
    case SAVE_JOURNAL_ITEM_SETTINGS_REQUEST:
      return state
        .setIn(['assetCategory', 'selectedItem', 'loading'], true)
        .setIn(['assetCategory', 'list', 'loading'], true)
    case DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_SUCCESS:
      return state
        .setIn(['assetCategory', 'selectedItem', 'data'], action.payload)
        .setIn(['assetCategory', 'selectedItem', 'loading'], false)
    case SAVE_JOURNAL_ITEM_SETTINGS_SUCCESS:
      return state
        .setIn(['assetCategory', 'selectedItem', 'data'], action.list)
        .setIn(['assetCategory', 'selectedItem', 'loading'], false)
    case DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_FAILURE:
    case SAVE_JOURNAL_ITEM_SETTINGS_FAILURE:
      return state
        .setIn(['assetCategory', 'selectedItem', 'loading'], false)
        .setIn(['assetCategory', 'list', 'loading'], false)
    case DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST:
    case UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST:
    case SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST: {
      return state.setIn(['generalSettings', 'terms', 'loading'], true)
    }
    case DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS:
    case SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS: {
      return state
        .setIn(['generalSettings', 'terms', 'loading'], false)
        .setIn(['generalSettings', 'terms', 'data'], action.list)
    }
    case DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE:
    case SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE: {
      return state.setIn(['generalSettings', 'terms', 'loading'], false)
    }
    case FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST:
      return state.setIn(['generalSettings', 'terms', 'loading'], true)
    case FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_SUCCESS:
      return state
        .setIn(['generalSettings', 'terms', 'loading'], false)
        .setIn(['generalSettings', 'terms', 'data'], action.payload)
    case FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_FAILURE:
      return state.setIn(['generalSettings', 'terms', 'loading'], false)
    case CLEAR_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS:
      return state.setIn(['assetCategory', 'selectedItem', 'data'], null)
    case CLEANUP_JOURNAL_SETTINGS:
      return initialState
    case EXPORT_JOURNAL_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_JOURNAL_SUCCESS:
    case EXPORT_JOURNAL_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case FETCH_JOURNAL_LINKAGE_STATUS_REQUEST:
      return state.setIn(['journalLinkageStatus', 'loading'], true)
    case FETCH_JOURNAL_LINKAGE_STATUS_SUCCESS:
      return state
        .setIn(['journalLinkageStatus', 'loading'], false)
        .setIn(['journalLinkageStatus', 'data'], action.payload)
    case FETCH_JOURNAL_LINKAGE_STATUS_FAIL:
      return state
        .setIn(['journalLinkageStatus', 'loading'], false)
        .setIn(['journalLinkageStatus', 'errors'], action.payload)
        .setIn(['journalLinkageStatus', 'data'], {})
    case SEND_JOURNAL_REQUEST:
      return state.setIn(['sendJournalLoading', 'loading'], true)
    case SEND_JOURNAL_DONE:
      return state.setIn(['sendJournalLoading', 'loading'], false)
    case SEND_JOURNAL_FAIL_PARTIAL:
      return state
        .setIn(['journalLinkageSendResult', 'historyId'], action.payload?.export_journal_history_id)
        .setIn(['journalLinkageSendResult', 'failTotal'], action.payload?.total_fail)
    case CLEAR_SEND_JOURNAL_RESULT:
      return state.set('journalLinkageSendResult', {})
    case EXPORT_JOURNAL_LINKAGE_REQUEST:
      return state.setIn(['journalLinkageDetail', 'loading'], true)
    case EXPORT_JOURNAL_LINKAGE_SUCCESS:
    case EXPORT_JOURNAL_LINKAGE_FAILURE:
      return state.setIn(['journalLinkageDetail', 'loading'], false)
    default:
      return state
  }
}
