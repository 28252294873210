import styled from '@emotion/styled/macro'

export const Wrapper = styled.div`
  position: relative;
  height: 20px;
  cursor: pointer;

  &:hover {
    transition: 0.2s all ease-in-out;
    opacity: 0.6;
  }

  .line {
    position: absolute;
    z-index: 1;
    top: 9px;
  }

  .content {
    position: absolute;
    top: 10px;
    left: 50%;
    display: flex;
    font-size: 12px;
    color: #3b7de9;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #eff1f4;
    width: 26px;

    .plus-icon {
      margin: auto;
    }
  }
`
