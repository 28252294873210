import { LinkLabel } from 'aa_common/front-end/components'
import i18n from 'i18n'
import React, { useMemo } from 'react'

type Props = {
  type?: 'copy' | 'edit' | 'delete'
  onClick?: () => void
  disabled?: boolean
  showTooltipContent?: string
}

export const TableActionButton = ({ disabled, type = 'edit', onClick, showTooltipContent }: Props) => {
  const renderLabel = useMemo(() => {
    switch (type) {
      case 'delete':
        return i18n.t('common.actions.cancel_1')
      default:
        return i18n.t(`common.actions.${type}`)
    }
  }, [type])

  const renderType = useMemo(() => {
    switch (type) {
      case 'delete':
        return 'danger'
      default:
        return undefined
    }
  }, [type])

  return (
    <LinkLabel
      disabled={disabled}
      className="table-action"
      showTooltipMessage={showTooltipContent}
      type={renderType}
      onClick={onClick}
    >
      {renderLabel}
    </LinkLabel>
  )
}
