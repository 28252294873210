import { UserRoleListSelector, UserRoleSelector } from './model'

export const selectUserRoleList = (state: any): UserRoleListSelector => ({
  data: state.getIn(['settings', 'role', 'list', 'data']),
  loading: state.getIn(['settings', 'role', 'list', 'loading']),
})

export const selectRoleDetail = (state: any): UserRoleSelector => ({
  data: state.getIn(['settings', 'role', 'item', 'data']),
  loading: state.getIn(['settings', 'role', 'item', 'loading']),
})
