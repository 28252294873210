import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-collapse-item {
    .ant-collapse-header {
      background-color: #e7f1fd;
      padding: 4px 8px;

      .ant-collapse-arrow {
        .collapse-icon {
          transition: 0.3s all;
          color: #7c8291;

          &.active {
            transform: rotate(90deg);
          }
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0px;
      display: flex;

      .content {
        width: 100%;
        .item:last-of-type {
          border-bottom: 0px;
        }
      }

      .action {
        display: flex;
        width: 44px;
        border-left: 1px solid #d4d8dd;
        color: #7c8291;

        .edit-icon {
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
`

export const ItemWrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
  box-sizing: border-box;
  color: #333333;
  display: flex;
  min-height: 48px;
  padding: 10px;

  &.last {
    border-radius: 0px 0px 4px 4px;
  }
`

export const ItemLabel = styled.div`
  width: 200px;
  font-weight: bold;
`

export const ItemContent = styled.div`
  position: relative;

  .required {
    left: -40px;
    position: absolute;
    top: 3px;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;

  .ant-btn:last-of-type {
    margin-left: 10px;
  }
`
