import styled from '@emotion/styled'

import { Input as AntdInput } from 'antd'

export const SearchInput = styled(AntdInput.Search)`
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  font-size: 13px;

  .ant-input-wrapper {
    .ant-input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 30px;
    }

    .ant-input-group-addon .ant-input-search-button {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 30px;
    }
  }
`
