import styled from '@emotion/styled'

export const FormFields = styled.div`
  max-width: 1000px;

  &:after {
    display: table;
    content: '';
    clear: both;
  }
`
