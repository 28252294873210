import rollbar from 'lib/rollbar'
import React from 'react'
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary'

const ErrorBoundaryWithRollbar: React.FC<ErrorBoundaryProps> = props => {
  const handleError = (error: Error, info: { componentStack: string }) => {
    if (props.onError) {
      rollbar.error(error)
      props.onError(error, info)
    } else {
      rollbar.error(error)
    }
  }

  return (
    <ErrorBoundary {...props} onError={handleError}>
      {props.children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWithRollbar
