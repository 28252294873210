import * as assetApi from 'api/app/asset'
import * as monthlyClosingApi from 'api/app/monthlyClosing'
import { AxiosResponse } from 'axios'
import { ASSET_CHANGE_SITUATION_TYPES, SAGA_TIME_DELAY } from 'common/constants'
import { parseError } from 'common/helpers'
import { parseSummaryAssetEvents } from 'common/helpers/event-helper'
import { formatMonth } from 'common/helpers/format-helper'
import { MONTHLY_CLOSING_CYCLES, MonthlyClosing, MonthlyClosingSetting } from 'common/models'
import i18n from 'i18n'
import { get } from 'lodash'
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { setAppMessage, setLoadingApp, showErrorsAppCall } from 'store/app/actions'

import {
  FetchMonthlyClosingAssetEventsRequest,
  ReopenMonthlyClosingRequest,
  UpdateMonthlyClosingSettingRequest,
} from './actions'
import {
  FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_CURRENT_SUCCESS,
  FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_PAST_SUCCESS,
  FETCH_MONTHLY_CLOSING_ASSET_EVENT_REQUEST,
  FETCH_MONTHLY_CLOSING_CLOSED_REQUEST,
  FETCH_MONTHLY_CLOSING_CLOSED_SUCCESS,
  FETCH_MONTHLY_CLOSING_CURRENT_FAILURE,
  FETCH_MONTHLY_CLOSING_CURRENT_REQUEST,
  FETCH_MONTHLY_CLOSING_CURRENT_SUCCESS,
  FETCH_MONTHLY_CLOSING_NEXT_SETTING_REQUEST,
  FETCH_MONTHLY_CLOSING_NEXT_SETTING_SUCCESS,
  FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_REQUEST,
  FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_SUCCESS,
  FETCH_MONTHLY_CLOSING_RESELECT_FAILURE,
  FETCH_MONTHLY_CLOSING_RESELECT_REQUEST,
  FETCH_MONTHLY_CLOSING_RESELECT_SUCCESS,
  FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_REQUEST,
  FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_SUCCESS,
  REOPEN_MONTHLY_CLOSING_REQUEST,
  REOPEN_MONTHLY_CLOSING_SUCCESS,
  SUBMIT_CLOSE_MONTHLY_CLOSING_REQUEST,
  SUBMIT_CLOSE_MONTHLY_CLOSING_SUCCESS,
  SUBMIT_MONTHLY_CLOSING_RESELECT_REQUEST,
  SUBMIT_MONTHLY_CLOSING_RESELECT_SUCCESS,
  UPDATE_MONTHLY_CLOSING_SETTING_REQUEST,
  UPDATE_MONTHLY_CLOSING_SETTING_SUCCESS,
} from './constant'
import { selectMonthlyClosingCurrent } from './selectors'

export function* fetchMonthlyClosingCurrent() {
  try {
    const response: AxiosResponse = yield call(monthlyClosingApi.getCurrent)
    const responseData = get(response, 'data.data')
    yield put({ type: FETCH_MONTHLY_CLOSING_CURRENT_SUCCESS, payload: responseData })
    yield delay(SAGA_TIME_DELAY)
  } catch (error) {
    yield put({ type: FETCH_MONTHLY_CLOSING_CURRENT_FAILURE })
  }
}

export function* fetchMonthlyClosingSummaryAssetEvents({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(monthlyClosingApi.getSummaryAssetEvents, payload)

    yield put({
      type: FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_SUCCESS,
      payload: parseSummaryAssetEvents(response.data.data),
    })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchMonthlyClosingNextSetting() {
  try {
    const monthlyClosing: MonthlyClosing = yield select(selectMonthlyClosingCurrent)
    const response: AxiosResponse = yield call(
      monthlyClosingApi.getNextSetting,
      monthlyClosing.monthly_closing_setting.id
    )
    const nextYearSetting: MonthlyClosingSetting = response.data.data
    nextYearSetting.is_next_year = true

    yield put({ type: FETCH_MONTHLY_CLOSING_NEXT_SETTING_SUCCESS, payload: nextYearSetting })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchMonthlyClosingClosed() {
  try {
    const monthlyClosing: MonthlyClosing = yield select(selectMonthlyClosingCurrent)
    const response: AxiosResponse = yield call(
      monthlyClosingApi.getClosedMonlyClosingOfSetting,
      monthlyClosing.monthly_closing_setting.id
    )

    yield put({ type: FETCH_MONTHLY_CLOSING_CLOSED_SUCCESS, payload: response.data.data || [] })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchMonthlyClosingAssetEvents({ payload }: FetchMonthlyClosingAssetEventsRequest) {
  try {
    const isAcquisitionEvent = payload.change_situation_code === ASSET_CHANGE_SITUATION_TYPES.ACQUISITION
    const responses: AxiosResponse[] = yield all([
      call(monthlyClosingApi.getAssetEvents, payload, false),
      // conditionally Fetch data in past
      isAcquisitionEvent && call(monthlyClosingApi.getAssetEvents, payload, true),
    ])
    const payloads = responses.map(response => ({
      data: response?.data?.data || [],
      total: response?.data?.meta?.total || 0,
    }))

    yield put({ type: FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_CURRENT_SUCCESS, payload: payloads[0] })
    if (isAcquisitionEvent) {
      yield put({ type: FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_PAST_SUCCESS, payload: payloads[1] })
    }
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* updateSetting({ payload }: UpdateMonthlyClosingSettingRequest) {
  try {
    const { setting } = payload
    const { data } = payload

    yield call(monthlyClosingApi.updateSetting, setting.id, payload.data)
    yield put({ type: UPDATE_MONTHLY_CLOSING_SETTING_SUCCESS })

    const year_string = setting.is_next_year
      ? i18n.t('components.MonthlyClosingView.next_year')
      : i18n.t('components.MonthlyClosingView.current_year')
    const closing_cycle_string =
      data.closing_cycle === MONTHLY_CLOSING_CYCLES.MONTHLY
        ? i18n.t('common.units.month')
        : i18n.t('common.units.quarter')

    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('components.MonthlyClosingView.update_closing_cycle_success_msg', {
          year: setting.term.year,
          year_string,
          closing_cycle_string,
        }),
      })
    )
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchMonthlyClosingPreviousAssetEvents({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.searchPreviousByIds, payload)

    yield put({ type: FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_SUCCESS, payload: response.data.data || [] })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* submitCloseMonthlyClosing() {
  try {
    const monthlyClosing: MonthlyClosing = yield select(selectMonthlyClosingCurrent)

    yield call(monthlyClosingApi.submitCloseMonthlyClosing)
    yield put({ type: SUBMIT_CLOSE_MONTHLY_CLOSING_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('components.MonthlyClosingView.submit_current_closing_cycle_success_msg', {
          year: monthlyClosing.from_term_month?.term?.year,
          month: formatMonth(monthlyClosing.from_term_month.month),
        }),
      })
    )
    yield put({ type: FETCH_MONTHLY_CLOSING_CURRENT_REQUEST })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchMonthlyClosingReselectTerms() {
  try {
    const response: AxiosResponse = yield call(monthlyClosingApi.getMonthlyClosingReselectTerms)
    const responseData = response?.data?.data
    yield put({ type: FETCH_MONTHLY_CLOSING_RESELECT_SUCCESS, payload: responseData })
  } catch (error) {
    yield put({ type: FETCH_MONTHLY_CLOSING_RESELECT_FAILURE })
  }
}

export function* submitMonthlyClosingReselectTerm(actions: any) {
  try {
    yield call(monthlyClosingApi.submitMonthlyClosingReselectTerm, actions.payload)
    yield put({ type: SUBMIT_MONTHLY_CLOSING_RESELECT_SUCCESS })
    yield put(setAppMessage({ type: 'success', content: i18n.t('components.MonthlyClosingView.reselect_success') }))
    window.location.reload()
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* reopenMonthlyClosing({ payload, meta }: ReopenMonthlyClosingRequest) {
  try {
    yield put(setLoadingApp(true))
    const response: AxiosResponse = yield call(monthlyClosingApi.reopenMonthlyClosing, payload.dryRun)
    const assetCount = response?.data?.data?.retroactive_fixedasset_count || 0
    yield put({ type: REOPEN_MONTHLY_CLOSING_SUCCESS, payload: { assetCount }, meta })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
  yield put(setLoadingApp(false))
}

export default function* actionWatcher(): Generator<any> {
  yield takeLatest(FETCH_MONTHLY_CLOSING_CURRENT_REQUEST, fetchMonthlyClosingCurrent)
  yield takeLatest(FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_REQUEST, fetchMonthlyClosingSummaryAssetEvents)
  yield takeLatest(FETCH_MONTHLY_CLOSING_NEXT_SETTING_REQUEST, fetchMonthlyClosingNextSetting)
  yield takeLatest(FETCH_MONTHLY_CLOSING_CLOSED_REQUEST, fetchMonthlyClosingClosed)
  yield takeLatest(FETCH_MONTHLY_CLOSING_ASSET_EVENT_REQUEST, fetchMonthlyClosingAssetEvents)
  yield takeLatest(UPDATE_MONTHLY_CLOSING_SETTING_REQUEST, updateSetting)
  yield takeLatest(FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_REQUEST, fetchMonthlyClosingPreviousAssetEvents)
  yield takeLatest(SUBMIT_CLOSE_MONTHLY_CLOSING_REQUEST, submitCloseMonthlyClosing)
  yield takeLatest(FETCH_MONTHLY_CLOSING_RESELECT_REQUEST, fetchMonthlyClosingReselectTerms)
  yield takeLatest(SUBMIT_MONTHLY_CLOSING_RESELECT_REQUEST, submitMonthlyClosingReselectTerm)
  yield takeLatest(REOPEN_MONTHLY_CLOSING_REQUEST, reopenMonthlyClosing)
}
