import {
  CreatableOfficeUsersSelector,
  OfficeUserItemSelector,
  OfficeUserListSelector,
  OfficeUserRoleOptionsSelector,
} from './model'

export const selectorOfficeUserList = (state: any): OfficeUserListSelector => {
  return {
    loading: state.getIn(['settings', 'officeUser', 'list', 'loading']),
    data: state.getIn(['settings', 'officeUser', 'list', 'data']) || [],
  }
}

export const selectorOfficeUserItem = (state: any): OfficeUserItemSelector => {
  return {
    loading: state.getIn(['settings', 'officeUser', 'item', 'loading']),
    data: state.getIn(['settings', 'officeUser', 'item', 'data']) || [],
    errors: state.getIn(['settings', 'officeUser', 'item', 'errors']),
  }
}

export const selectorOfficeUserRoleOptions = (state: any): OfficeUserRoleOptionsSelector => {
  return {
    loading: state.getIn(['settings', 'officeUser', 'roles', 'loading']),
    data: state.getIn(['settings', 'officeUser', 'roles', 'data']) || [],
  }
}

export const selectorCreatableOfficeUsers = (state: any): CreatableOfficeUsersSelector => {
  return {
    loading: state.getIn(['settings', 'officeUser', 'creatableUsers', 'loading']),
    data: state.getIn(['settings', 'officeUser', 'creatableUsers', 'data']) || [],
  }
}
