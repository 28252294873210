import React from 'react'

import { DividerWrapper } from './styles'

type Props = {
  children: any
}

const Divider = ({ children }: Props) => {
  return <DividerWrapper>{children}</DividerWrapper>
}

export default Divider
