import { IndeterminateLoading } from 'aa_common/front-end/components'
import { Progress } from 'antd'
import React from 'react'

interface Props {
  isProgressInfo?: boolean
  titleName: string
  icon?: any
  statusProgress?: 'active' | 'exception'
}

const Modal = ({ titleName, statusProgress = 'active', isProgressInfo = false, icon }: Props) => {
  const renderLoading = () => {
    switch (statusProgress) {
      case 'active':
        return <IndeterminateLoading />
      case 'exception':
        return <Progress percent={100} status="exception" showInfo={isProgressInfo} />
      default:
        break
    }
  }

  return (
    <div className="modal-title">
      <div className="title-message">{titleName}</div>
      <div className="content">
        {renderLoading()}
        {icon}
      </div>
    </div>
  )
}

export default Modal
