import { defaultTo } from 'aa_common/front-end/helpers'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

import { FORM_FIELDS } from '../const'
import { useFormReady } from './use-form-ready'

export const useBookValueReference = (acquisitionCostReference?: number, netAcquisitionCost?: number) => {
  const { isEnable: isEnableForFixedAssetReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  const { setFieldValue } = useFormikContext()
  const isFormReady = useFormReady()

  const bookValueReference = useMemo(() => {
    if (isEnableForFixedAssetReduction) {
      return defaultTo(netAcquisitionCost, acquisitionCostReference)
    }
    return acquisitionCostReference
  }, [acquisitionCostReference, netAcquisitionCost, isEnableForFixedAssetReduction])

  useEffect(() => {
    isFormReady && setFieldValue(FORM_FIELDS.BOOK_VALUE_REFERENCE, bookValueReference)
  }, [bookValueReference, isFormReady])

  return bookValueReference
}
