import styled from '@emotion/styled/macro'
import { Typography } from 'antd'

const { Text } = Typography

export const Wrapper = styled.div`
  font-size: 13px;
  width: 100%;
  position: relative;
`

export const Header = styled.div`
  background-color: #e7f1fd;
  box-sizing: border-box;
  border-bottom: 1px solid #d4d8dd;
  color: #333333;
  padding: 6px 8px;
  border-radius: 4px 4px 0px 0px;
`

export const ItemWrapper = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
  box-sizing: border-box;
  color: #333333;
  display: flex;
  min-height: 48px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ItemLabel = styled.div`
  padding-left: 10px;
  width: 200px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .content {
    font-weight: bold;
  }
`

export const ItemContent = styled.div`
  width: 400px;
  padding: 0 5px;
`

export const ItemContentView = styled.div`
  width: 690px;
  white-space: pre-line;
`

export const Footer = styled.div`
  margin-top: 15px;
  text-align: center;
`

export const DividerWrapper = styled.div`
  background-color: #eff1f4;
  border-bottom: 1px solid #d4d8dd;
  border-left: 1px solid #d4d8dd;
  border-right: 1px solid #d4d8dd;
  color: #7c8291;
  padding: 4px 9px;
`

export const Group = styled.div<{ borderLess?: boolean }>`
  border-radius: ${props => (props.borderLess ? 'unset' : '4px')};
  border: ${props => (props.borderLess ? 'none' : '1px solid #D4D8DD')};

  &:not(${Header}) {
    ${ItemWrapper} {
      &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }

  ${ItemWrapper} {
    &:first-of-type {
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .form-field {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
export const LabelRequired = styled(Text)`
  position: absolute;
  background-color: red;
  color: #fff;
  left: -65px;
  border-radius: 5px;
  height: 20px;
  font-size: 13px;
  padding: 0 3px;
  top: 6px;
`

export const ErrorTextStyles = styled.div``
