import { List } from 'immutable'
import React, { useCallback, useMemo } from 'react'

import { convertSelectOptionItems } from './helper'
import { CoreMultiSelectPickerItem } from './model'

export const useHandleOptionChanged = (
  selectedValues: any[],
  setSelectedValues: React.Dispatch<React.SetStateAction<any[]>>,
  onChange?: (value: any[]) => void
) => {
  return useCallback(
    (item: CoreMultiSelectPickerItem) => {
      if (item.disabled) {
        return
      }

      let values = [...selectedValues]
      const currentValue = item
      const found = selectedValues.findIndex(selectedValue => selectedValue.value === currentValue.value) !== -1
      if (found) {
        values = values.filter(itemValue => itemValue.value !== currentValue.value)
      } else {
        values.push(currentValue)
      }
      setSelectedValues(values)
      onChange?.(values)
    },
    [setSelectedValues, onChange, selectedValues]
  )
}

export const useDisableSelectAll = (
  renderItems: ReturnType<typeof convertSelectOptionItems>,
  selectedValues: any[]
) => {
  return useMemo(() => {
    return (
      renderItems.length === 0 ||
      List(renderItems)
        .filter(item => !item.disabled)
        .every(item => {
          return selectedValues.find(selectedValueItem => item.value === selectedValueItem.value)
        })
    )
  }, [renderItems, selectedValues])
}

export const useDisableRemoveAll = (
  renderItems: ReturnType<typeof convertSelectOptionItems>,
  selectedValues: any[]
) => {
  return useMemo(() => {
    return (
      renderItems.length === 0 ||
      !renderItems.some(item => selectedValues.find(selectedValue => selectedValue.value === item.value))
    )
  }, [renderItems, selectedValues])
}
