import {
  CLEANUP_ASSET_COMMENT,
  CREATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  CREATE_ASSET_COMMENT_REQUEST,
  DELETE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  DELETE_ASSET_ITEM_COMMENT_REQUEST,
  FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST,
  FETCH_ASSET_ITEM_COMMENTS_REQUEST,
  UPDATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  UPDATE_ASSET_ITEM_COMMENT_REQUEST,
} from './constant'

export const fetchAssetItemComments = (assetBiid: string) => ({
  type: FETCH_ASSET_ITEM_COMMENTS_REQUEST,
  assetBiid,
})

export const fetchAssetItemCommentsCollectionView = (assetBiid: string) => ({
  type: FETCH_ASSET_ITEM_COMMENTS_COLLECTION_VIEW_REQUEST,
  assetBiid,
})

export const createAssetComment = (assetBiid: string, content: string) => ({
  type: CREATE_ASSET_COMMENT_REQUEST,
  assetBiid,
  content,
})

export const createAssetCommentCollectionView = (assetBiid: string, content: string) => ({
  type: CREATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  assetBiid,
  content,
})

export const deleteAssetComment = (id: string | number) => ({
  type: DELETE_ASSET_ITEM_COMMENT_REQUEST,
  id,
})

export const deleteAssetCommentCollectionView = (id: string | number, assetBiid: string) => ({
  type: DELETE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  id,
  assetBiid,
})

export const updateAssetComment = (id: string | number, content: string) => ({
  type: UPDATE_ASSET_ITEM_COMMENT_REQUEST,
  id,
  content,
})

export const updateAssetCommentCollectionView = (id: string | number, assetBiid: string, content: string) => ({
  type: UPDATE_ASSET_COMMENT_COLLECTION_VIEW_REQUEST,
  id,
  assetBiid,
  content,
})

export const cleanupAssetComment = (): any => ({
  type: CLEANUP_ASSET_COMMENT,
})
