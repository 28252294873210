import { AssetCategoryItemSelector, AssetCategorySelector } from './model'

export const selectAssetCategoryList = (state: any): AssetCategorySelector => ({
  assetCategories: state.getIn(['settings', 'assetCategory', 'list', 'data'], []),
  listLoading: state.getIn(['settings', 'assetCategory', 'list', 'loading'], false),
  assetCategoriesFilters: state.getIn(['settings', 'assetCategory', 'list', 'filters'], []),
  assetCategoriesSort: state.getIn(['settings', 'assetCategory', 'list', 'sort'], []),
})

export const selectAssetCategoryItem = (state: any) => {
  return state.getIn(['settings', 'assetCategory', 'item', 'data'])
}

export const selectorAssetCategoryItem = (state: any): AssetCategoryItemSelector => {
  return {
    loading: state.getIn(['settings', 'assetCategory', 'item', 'loading']),
    data: state.getIn(['settings', 'assetCategory', 'item', 'data']),
    errors: state.getIn(['settings', 'assetCategory', 'item', 'errors']),
  }
}

export const selectAssetCategoryImport = (state: any) => ({
  importError: state.getIn(['settings', 'assetCategory', 'import', 'error'], {}),
  importLoading: state.getIn(['settings', 'assetCategory', 'import', 'loading']),
})
