import styled from '@emotion/styled'
import React from 'react'

export const preLineStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
}

export const Wrapper = styled.div`
  width: 950px;
`

export const Error = styled.div`
  color: #dc3545;
`

export const Heading = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 15px;
  align-items: center;
`

export const HeadingTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
`

export const BottomSubmit = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  z-index: 10;
  border-top: 1px #d4d8dd solid;
`

export const SavedAtNotice = styled.label`
  color: #333333;
  margin-left: 10px;
  font-size: 13px;
`

export const FormGroup = styled.div`
  &:after {
    display: table;
    content: '';
    clear: both;
  }
`

export const UnderlineInputWrapper = styled.div`
  display: contents;
  height: 32px;
  display: flex;
  position: relative;

  input,
  label.value-view {
    border: none;
    border-bottom: 1px solid #d4d8dd;
    box-sizing: border-box;
    width: 100%;
    padding: 7px;
    padding-right: 18px;
    font-size: 13px;
    color: #7c8291;
    background: none;

    &:focus {
      outline: 0;
    }
  }

  .edit-icon {
    position: absolute;
    top: 10px;
    right: 0px;
    color: #7c8291;
    cursor: pointer;
  }

  .reset-link {
    position: absolute;
    top: 6px;
    right: 0px;
    color: #3b7de9;
    cursor: pointer;
  }
`

export const TopMessage = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #d4d8dd;
  white-space: pre-wrap;
`

export const BottomMessage = styled.div`
  margin: 15px 10px;
  color: #7c8291;
  font-size: 12px;

  .delete {
    font-size: 13px;
    color: #ec4949;
    cursor: pointer;
  }

  .disabled {
    font-size: 13px;
    color: #7c8291;
    opacity: 0.3;
  }
`

export const AssetCodeWrapper = styled.div`
  display: flex;
`

export const LedgerHeader = styled.div`
  display: inline-flex;
  margin-left: 10px;
  margin-bottom: 15px;
`

export const TitleName = styled.span`
  color: rgb(51, 51, 51);
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
`

export const ErrorMessage = styled.div`
  box-sizing: border-box;
  color: #ec4949;
  border-radius: 4px;
  border: 2px solid #f57575;
  background: #ffeeeb;
  padding: 15px;
  font-size: 14px;
`

export const prelineStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
}
