export const selectAssetCommentList = (state: any): any[] => {
  return state.getIn(['assetComment', 'list', 'data'])
}

export const selectAssetCommentListLoading = (state: any): boolean => {
  return state.getIn(['assetComment', 'list', 'loading'])
}

export const getAssetItemCommentSelector = (state: any) => {
  return {
    data: state.getIn(['assetComment', 'assetItemComments', 'data']),
    isLoading: state.getIn(['assetComment', 'assetItemComments', 'loading']),
  }
}

export const getListAssetItemCommentSelector = (state: any) => {
  return {
    data: state.getIn(['assetComment', 'list', 'data']),
    isLoading: state.getIn(['assetComment', 'list', 'loading']),
  }
}
