import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Layout, Tabs as AntTabs } from 'antd'

export const Wrapper = styled.div`
  position: relative;
  min-width: 1200px;
  color: #333333;

  .tabpage-template-body {
    padding: 0;
  }
  .error-list {
    margin: 10px 15px;
    padding: 10px 15px;
    max-width: 900px;

    div:first-child {
      margin: 0;
    }
  }
`

export const Tabs = styled(AntTabs)`
  .ant-tabs-nav {
    padding: 0 15px;
    margin-bottom: 0;
    &::before {
      border-bottom: 1px solid #d4d8dd;
    }
  }

  .ant-tabs-tab {
    min-width: 110px;
    display: flex;
    justify-content: center;
  }
`

export const TabPane = styled(AntTabs.TabPane)`
  position: relative;
  display: flex;
  height: 100%;
  background: #f0f2f5;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100 - (var(--vh, 1vh) * 14.3));
`

export const Header = styled(Layout.Header)`
  padding: 0;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 9;
`

export const Body = styled(Layout)`
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 40px);
`

export const BreadcrumbArea = styled.div`
  background-color: #ffffff;
  &.has-search-panel {
    border-bottom: none;
  }
`

export const ActionWrapper = styled.div`
  padding: 10px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  button {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 5px;
    }
  }
`

export const WrapContent = styled.div`
  background-color: #eff1f4;
  height: 100%;
  display: table-row;
`

export const TopMessage = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 130px);
  z-index: 99;
`

export const FilterPanel = styled.div`
  background: #ffffff;
`

export const TabsClass = css`
  &.isCard {
    .ant-tabs-nav {
      background: #f0f2f5;
      &::before {
        border-bottom: none;
      }
    }
    .ant-tabs-tab {
      border-radius: 25px !important;
      background: #d4d8dd;
      height: 32px;
      margin-right: 10px;
      &:hover {
        background: #3b7de9;

        .ant-tabs-tab-btn {
          color: #fff;
        }
      }

      &.ant-tabs-tab-active {
        background: #3b7de9;

        .ant-tabs-tab-btn {
          color: #fff;
          text-shadow: none;
        }
      }
      &-btn {
        color: #666666;
      }
    }
  }
`

export const ErrorWrapper = styled.div`
  margin-left: 15px;
`
