export enum TYPE_CONFIRMING_MODAL {
  UPDATING_VALID_TO = 'UPDATING_VALID_TO',
  REMOVING_VALID_TO = 'REMOVING_VALID_TO',
}

export const INITIAL_VALUES: any = {
  id: null,
  biid: null,
  code: '',
  name: '',
  submit_destination_id: null,
  search_key: '',
  valid_from: null,
  valid_to: null,
  photos: [],
  attachments: [],
  urls: [],
}

export const INITIAL_LATEST_RECORD_VALUE = {
  validToLatestRecord: null,
  validFromLatestRecord: null,
  recordItem: null,
  isValidToChanged: false,
  validToBeforeChanged: null,
}
