/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterChangeTagPositionRequest,
  PresenterCreateTagRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterOneTagResponse,
  PresenterResponse,
  PresenterUpdateTagRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Tags<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get tag list
   *
   * @tags tags
   * @name TagsList
   * @summary Get the tag list
   * @request GET:/tags
   */
  tagsList = (
    query?: {
      'filter[name]'?: string
      sort?: string[]
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneTagResponse[], any>({
      path: `/tags`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create tag with name
   *
   * @tags tags
   * @name TagsCreate
   * @summary Create the tag
   * @request POST:/tags
   */
  tagsCreate = (body: PresenterCreateTagRequest, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/tags`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description change position of tag
   *
   * @tags tags
   * @name ChangePositionPartialUpdate
   * @summary change position of tag
   * @request PATCH:/tags/change_position
   */
  changePositionPartialUpdate = (body: PresenterChangeTagPositionRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, PresenterError>({
      path: `/tags/change_position`,
      method: 'PATCH',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get tag with id
   *
   * @tags tags
   * @name TagsDetail
   * @summary Get the tag by ID
   * @request GET:/tags/{id}
   */
  tagsDetail = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterOneTagResponse, PresenterError>({
      path: `/tags/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update tag
   *
   * @tags tags
   * @name TagsUpdate
   * @summary Update the tag
   * @request PUT:/tags/{id}
   */
  tagsUpdate = (id: string, body: PresenterUpdateTagRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/tags/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete tag with id
   *
   * @tags tags
   * @name TagsDelete
   * @summary Delete the tag
   * @request DELETE:/tags/{id}
   */
  tagsDelete = (
    id: string,
    query?: {
      /** dry_run enable to check whether you can delete tag or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/tags/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
