import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const Wrapper = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  position: relative;

  .journal-linkage-history {
    display: flex;
    align-items: center;

    .status {
      font-size: 16px;
      margin-right: 5px;
      margin-top: -3px;

      .status-icon {
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
      .error {
        color: red;
      }
      .success {
        color: #3b7de9;
      }
    }
  }

  .history-pagination {
    display: flex;
    justify-content: end;
  }

  .current-monthly-closing {
    font-size: 13px;
  }

  .history-content-wrapper {
    max-width: 539px;
  }

  .acc-plus-message {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    font-size: 13px;
  }
`

export const EmptyImage = styled.div`
  padding-top: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
`

export const WrapperActions = styled.div`
  position: absolute;
  top: 5px;
  z-index: 1;

  .history-tab {
    background: #d4d8dd;
    max-width: 121px;
    border-radius: 15px;
    padding: 5px 15px;
    color: #666666;

    &:hover {
      background-color: #3b7de9;
      color: #ffffff;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
`

export const WrapperHistory = styled.div`
  background: #ffffff;
  margin-top: 15px;
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  padding: 10px 15px;
`

export const HistoryRecord = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  .history-info {
    margin-right: 15px;
  }
`

export const TooltipClass = css`
  max-width: unset;
  pointer-events: none;
  font-size: 13px;

  .ant-tooltip-inner {
    border-radius: 4px;
    padding: 10px;
  }
`
