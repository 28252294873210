import { parseError, parseResponse } from 'common/helpers'
import {
  buildPaginationParams,
  buildQueryFilterParams,
  convertSortValue,
  objectToQueryParams,
  RequestParam,
} from 'common/helpers/request-helper'

import base from './base'
import { IAreaGetSearch, IAreaPostSearch } from './model/area'

export const search: IAreaGetSearch = ({ filters, sort, pageNumber, pageSize }) => {
  const filterQueryString = objectToQueryParams(buildQueryFilterParams(filters))
  return base
    .get(`/v1/areas?${filterQueryString}`, {
      params: {
        ...buildPaginationParams(pageNumber, pageSize),
        sort: convertSortValue(sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const searchAuth: IAreaGetSearch = ({ filters, sort, pageNumber, pageSize }) => {
  const filterQueryString = objectToQueryParams(buildQueryFilterParams(filters))
  return base
    .get(`/v1/areas/get?${filterQueryString}`, {
      params: {
        ...buildPaginationParams(pageNumber, pageSize),
        sort: convertSortValue(sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const postSearch: IAreaPostSearch = payload => {
  const data = {
    ...buildQueryFilterParams(payload.filters),
    sort: convertSortValue(payload.sort || []),
  }
  return base.post('/v1/areas/search', data)
}

export const createArea = (data: any) => {
  return base.post('/v1/areas', data)
}

export const insertArea = (data: any) => {
  return base.post('/v1/areas/insert_record', data)
}

export const getById = (id: number) => {
  return base.get(`/v1/areas/${id}`)
}

export const getByBiid = (biid: string) => {
  const params: any = {}
  params['filter[biid]'] = biid
  params.sort = '-valid_from'

  return base.get('/v1/areas', { params })
}

export const update = (id: number, data: any) => {
  return base.put(`/v1/areas/update_by_id/${id}`, data)
}

export const updateLatest = (id: number, data: any) => {
  return base.put(`/v1/areas/update_latest/${id}`, data)
}

export const destroy = (biid: string, dryRun: boolean) => {
  return base.delete(`/v1/areas/delete_by_biid/${biid}`, { params: { dry_run: dryRun } })
}

export const deleteById = (id: number) => {
  return base.delete(`/v1/areas/delete_by_id/${id}`)
}

export const importArea = (charset: string, file: any) => {
  const formData = new FormData()
  formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/areas/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const exportArea = (charset: string, params: RequestParam) => {
  const filter = objectToQueryParams(buildQueryFilterParams(params))
  return base.get(`/v1/export/areas?charset=${charset}&${filter}`, {
    responseType: 'blob',
  })
}
