import base from './base'

export const getTerms = () => {
  return base.get('/v1/accounting_periods/terms')
}

export const getFullTerms = () => {
  return base.get('/v1/accounting_periods/full_terms')
}

export const getTermById = (id: number) => {
  return base.get(`/v1/accounting_periods/terms/${id}`)
}

export const generateTerms = (data: any) => {
  return base.post('/v1/accounting_periods/generate_terms', data)
}

export const createTerms = (data: any) => {
  return base.post('/v1/accounting_periods/terms', data)
}

export const getChangedTermPeriod = (data: any) => {
  return base.put('/v1/accounting_periods/terms', {
    ...data,
    dry_run: true,
  })
}

export const updateTermPeriod = (data: any) => {
  return base.put('/v1/accounting_periods/terms', {
    ...data,
    dry_run: false,
  })
}

export const getTermMonthByDate = (data: any) => {
  return base.post('v1/accounting_periods/term_month_by_date', data)
}
