import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { LEDGER_PURPOSE, RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'common/constants'
import { IMasterData } from 'common/models'
import React from 'react'
import { Control, useWatch } from 'react-hook-form'

const RetirementField = ({
  index,
  defaultValue,
  error,
  control,
  masterData,
  ledgerNumber,
}: {
  index: number
  error: string
  defaultValue: any
  control: Control<any>
  masterData: IMasterData
  ledgerNumber: number
}) => {
  const fieldName = `settings[${index}].retirement_depreciation_ended_method`
  // Get ledger purpose value of current ledger setting
  const currentLedgerPurposeName = `settings[${index}].ledger_purpose`
  const ledgerPurpose = useWatch({
    name: currentLedgerPurposeName,
    control,
  }) as LEDGER_PURPOSE
  const isTaxPurpose = LEDGER_PURPOSE.TAX === ledgerPurpose
  const isAccountantPurpose = LEDGER_PURPOSE.ACCOUNTING === ledgerPurpose
  // Get all of ledger settings
  const watchFields: Array<string> = []
  for (let i = 0; i < ledgerNumber; i++) {
    watchFields.push(`settings[${i}].ledger_purpose`)
  }
  const ledgerSettingsWatcher = useWatch({
    name: watchFields,
    control,
  }) as [LEDGER_PURPOSE]

  const hasAllTaxAccountant =
    Object.values(ledgerSettingsWatcher)?.some(element => element === LEDGER_PURPOSE.TAX) &&
    Object.values(ledgerSettingsWatcher)?.some(element => element === LEDGER_PURPOSE.ACCOUNTING)
  // Handle option list
  const items = masterData.retirement_depreciation_ended_method.filter((item: any) =>
    isAccountantPurpose && hasAllTaxAccountant
      ? RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION !== item?.code
      : ![
          RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION,
          RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_YEAR,
        ].includes(item.code)
  )

  return (
    <If condition={!isTaxPurpose}>
      <AutoCompleteSelect
        name={fieldName}
        items={items}
        defaultValue={defaultValue}
        textField="name_jp"
        valueField="code"
        control={control}
        isShowError
        error={error}
        allowClear={false}
      />
    </If>
  )
}

export default RetirementField
