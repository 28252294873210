import styled from '@emotion/styled/macro'
import { css } from '@emotion/css'

import { ItemWrapper } from 'components/molecules/Form/styles'

export const Wrapper = styled.div`
  width: 900px;

  div > a > .open-icon {
    color: #1890ff;
    margin-left: 5px;
    cursor: pointer;
  }
`

export const FormWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #d4d8dd;
  background: white;
`

export const Group = styled.div`
  ${ItemWrapper} {
    &:first-of-type {
      border-radius: 4px 4px 0 0;
      border-top: 1px solid #d4d8dd;
    }
  }
`

export const Field = styled.div`
  font-size: 13px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d4d8dd;

  &.roles {
    padding: 5px 10px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;

    .label-text {
      flex: 1 1 0%;
      min-width: 0px;

      h4 {
        font-weight: 800;
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    .required-label-wrapper {
      flex: 0 1 auto;

      .required-label {
        display: block;
        color: white;
        background-color: #ec4949;
        padding: 1px 4px;
        border-radius: 4px;
      }
    }
  }

  .content {
    width: auto;
    padding-left: 20px;

    .ant-checkbox-group {
      display: flex;
    }
  }
`

export const CheckboxClass = css`
  display: flex;
  flex-direction: column;

  .ant-checkbox-group-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const TextWrapper = styled.div`
  color: #333333;
`
