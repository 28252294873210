import { Button } from 'aa_common/front-end/antd'
import { RedirectToMasterPageLink, UnborderCollapse } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import * as assetApi from 'api/app/asset'
import * as exciseApi from 'api/app/excise'
import {
  DEPRECIATION_METHOD_CODE,
  ENDPOINTS,
  EXCISE_TAX_METHOD,
  FEATURES_ID,
  LEDGER_PURPOSE,
  RETIREMENT_DEPRECIATION_ENDED_METHOD,
} from 'common/constants'
import { syncupDepreciationMethodTaxLedger } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES, LedgerSetting, RetirementFixedAssetLedger } from 'common/models'
import { HiddenField, Input } from 'components/atoms'
import { AutoCompleteSelect, DatePicker, MediaUploader } from 'components/molecules'
import { DEPRECIATION_METHOD } from 'components/organisms/depreciation/LedgerSettingsTable/model'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import useUserPermission from 'lib/hooks/useUserPermission'
import { find, get, isEqual } from 'lodash'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetItemData } from 'store/asset/selectors'
import { selectMasterData } from 'store/masterData/selectors'

import { renderDepreciationConditionField } from '../AssetEventSellForm/helper'
import { ExpandableBox, FormFields, Group, UnderlineText } from '../events/styles'
import { checkAssetLedger, convertDataLedgerSettingHelper } from '../helper/convert-data-ledger-setting.helper'
import BookValueFields from './components/book-value-fields'
import DisposalCostWithExcise from './DisposalCostWithExcise'
import { checkHasDepreciationEndedMethod, nonDepreciationMethodLength, validateForm, ValueFields } from './helper'

const t = loadNs('components/organisms/assets/asset-retirement-form')
const t2 = loadNs('components/organisms/assets/asset-event-sell-form')
const t3 = loadNs('common')

const { Item, Actions } = UnborderCollapse

type Props = {
  causes: any[]
  ledgerSettings: LedgerSetting[]
  formMode: FORM_MODES
  onClose?: (e: any) => void
  onHandleReloadCauses?: () => void
  renderDrawerExpandToggle: (ledgerCount: number) => ReactNode
  isBulkChangeEvent?: boolean
}

type BoodValueConditions = {
  retired_at: string
  retirement_fixed_asset_ledger_requests: RetirementFixedAssetLedger[]
}

const contentItemWidth = 240
const itemWidth = '100%'
const isShowMessage = false

const Form: React.FC<Props> = ({
  causes,
  formMode,
  ledgerSettings,
  onClose,
  onHandleReloadCauses,
  renderDrawerExpandToggle,
  isBulkChangeEvent,
}) => {
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const { permissions } = useUserPermission(FEATURES_ID.MASTER_CAUSES)

  const isShowRetirementDepreciationEndedMethodField = ledgerSettings.some(
    ledger =>
      ledger.retirement_depreciation_ended_method ===
      RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
  )

  const masterData = useSelector(selectMasterData, isEqual)
  const assetItem = useSelector(selectAssetItemData, isEqual)

  const [bookInformationResponse, getBookInformation] = useResource(assetApi.getBookInformation, isShowMessage)
  const [bookValueConditions, setBookValueConditions] = useState<BoodValueConditions | null>(null)
  const [exciseSettingResponse, getExciseSettingByDate] = useResource(exciseApi.getExciseSettingsByDate)
  const retirementFixedAssetLedgerRequests: RetirementFixedAssetLedger[] | null = get(
    values,
    'retirement_fixed_asset_ledger_requests'
  )
  const isTaxMethodExcluded = get(exciseSettingResponse.data, 'tax_method') === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const retiredAt: string | null = get(values, 'retired_at')
  const isEditMode = formMode === FORM_MODES.EDIT

  useEffect(() => {
    if (retiredAt && !isBulkChangeEvent) {
      getExciseSettingByDate(retiredAt)
    }
  }, [retiredAt]) // eslint-disable-line

  const isRetirementDepreciationEndedMethod =
    ledgerSettings?.filter(
      item =>
        item.retirement_depreciation_ended_method ===
        RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
    ).length > 0

  const isSmallAmountDepreciableAsset =
    assetItem &&
    assetItem?.fixed_asset_ledgers?.filter(
      item => item.depreciation_method_code === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
    ).length > 0

  const bookInformationItems = useMemo(() => {
    return ifElse(bookInformationResponse.data, get(bookInformationResponse.data, 'items'), [])
  }, [bookInformationResponse.data])

  const retirementDepreciationEndedMethods = useMemo(() => {
    if (!masterData) return []

    return masterData.retirement_depreciation_ended_method.filter(
      item => item.code !== RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
    )
  }, [masterData])

  useEffect(() => {
    let endedMethodIndex = -1
    if (formMode === FORM_MODES.NEW) {
      // Set retirement depreciation method to "end of depreciation" if asset ledger is bulk equal install.
      // Otherwise, set retirement depreciation method to "end of current month"
      assetItem?.fixed_asset_ledgers?.forEach(assetLedger => {
        const retireDepreciationMethod = get(assetLedger, 'ledger_setting.retirement_depreciation_ended_method')
        if (retireDepreciationMethod === RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT) {
          const isDepreciationMethodContinue =
            get(assetLedger, 'ledgerSetting.bulk_equal_installment_depreciation_method') ===
            DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
          const isBulkEqualInstallment =
            assetLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
          const valueRetirementDepreciationMethod = ifElse(
            isBulkEqualInstallment && isDepreciationMethodContinue,
            RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION,
            RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH
          )
          endedMethodIndex++
          setFieldValue(
            `retirement_fixed_asset_ledger_requests[${endedMethodIndex}].retirement_depreciation_ended_method`,
            valueRetirementDepreciationMethod
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetItem?.fixed_asset_ledgers, formMode])

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    ledgerSettings.map((ledgerSetting, index) => {
      const assetLedger = find(assetItem?.fixed_asset_ledgers, item => item.ledger_setting_id === ledgerSetting.id)

      const { isBulkEqualInstallment, isSmallAmountDepreciableAsset } = checkAssetLedger(assetLedger)

      const oldRetirementDepreciation = assetLedger?.retirement_depreciation_ended_method
      const isUndefinedValue =
        get(values, `retirement_fixed_asset_ledger_requests[${index}].retirement_depreciation_ended_method`) ===
        undefined
      const retirementDepreciationCurrentValue = assetLedger?.ledger_setting?.retirement_depreciation_ended_method
      const isRetirementDepreciationWhenSell =
        retirementDepreciationCurrentValue === RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
      const retirementDepreciationEndedValue = ifElse(
        isRetirementDepreciationWhenSell,
        RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH,
        retirementDepreciationCurrentValue
      )
      const isDepreciationMethodContinue =
        ledgerSetting.bulk_equal_installment_depreciation_method === DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
      const isValidDepreciationMethod = isDepreciationMethodContinue && isBulkEqualInstallment
      if (isEditMode && isBulkEqualInstallment && oldRetirementDepreciation) {
        return setFieldValue(
          `retirement_fixed_asset_ledger_requests[${index}].retirement_depreciation_ended_method`,
          oldRetirementDepreciation
        )
      }

      if (isValidDepreciationMethod)
        return setFieldValue(
          `retirement_fixed_asset_ledger_requests[${index}].retirement_depreciation_ended_method`,
          RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION
        )

      if (isSmallAmountDepreciableAsset)
        return setFieldValue(
          `retirement_fixed_asset_ledger_requests[${index}].retirement_depreciation_ended_method`,
          null
        )

      if (isUndefinedValue && !isSmallAmountDepreciableAsset && !isValidDepreciationMethod)
        return setFieldValue(
          `retirement_fixed_asset_ledger_requests[${index}].retirement_depreciation_ended_method`,
          retirementDepreciationEndedValue
        )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetItem?.fixed_asset_ledgers, ledgerSettings])

  useEffect(() => {
    if (!checkHasDepreciationEndedMethod(ledgerSettings)) return

    if (!retirementFixedAssetLedgerRequests || !retiredAt) return

    const newCondition = {
      retired_at: retiredAt,
      retirement_fixed_asset_ledger_requests: retirementFixedAssetLedgerRequests,
    }

    if (!isEqual(newCondition, bookValueConditions)) {
      setBookValueConditions({
        retired_at: retiredAt,
        retirement_fixed_asset_ledger_requests: syncupDepreciationMethodTaxLedger(
          retirementFixedAssetLedgerRequests,
          ledgerSettings,
          assetItem,
          isEditMode,
          values
        ),
      })
    }
  }, [values]) // eslint-disable-line

  useEffect(() => {
    if (!bookValueConditions || isBulkChangeEvent) return

    const biid = get(values, 'biid')
    let hasFullCondition = true

    bookValueConditions.retirement_fixed_asset_ledger_requests.forEach(item => {
      if (isSmallAmountDepreciableAsset) {
        return getBookInformation(
          biid,
          bookValueConditions.retired_at,
          bookValueConditions.retirement_fixed_asset_ledger_requests,
          ledgerSettings
        )
      }
      if (!item.retirement_depreciation_ended_method) {
        hasFullCondition = false
      }
    })

    if (!hasFullCondition) return
    getBookInformation(
      biid,
      bookValueConditions.retired_at,
      bookValueConditions.retirement_fixed_asset_ledger_requests,
      ledgerSettings
    )
  }, [bookValueConditions]) // eslint-disable-line

  const renderHiddenDepreciationEndedMethods = () => {
    let endedMethodIndex = -1

    return (
      <Item
        required={isRetirementDepreciationEndedMethod}
        label={t2('select_depreciation_method')}
        contentWidth={contentItemWidth}
        style={{
          width: itemWidth,
          whiteSpace: 'pre-line',
          marginTop: 10,
          paddingBottom: 0,
          display: 'none',
        }}
      >
        <Group>
          {ledgerSettings.map(ledgerSetting => {
            endedMethodIndex++

            return (
              <div key={ledgerSetting?.id}>
                <HiddenField
                  name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].ledger_setting_id`}
                  value={ledgerSetting?.id}
                />
                <HiddenField
                  name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].retirement_depreciation_ended_method`}
                  value={ledgerSetting?.retirement_depreciation_ended_method}
                />
              </div>
            )
          })}
        </Group>
      </Item>
    )
  }

  const renderDepreciationEndedMethods = () => {
    let endedMethodIndex = -1
    const assetLedgers = assetItem?.fixed_asset_ledgers
    const assetLedgerLength = assetLedgers?.length
    const isAllLedgerIsNonDepreciationMethod =
      assetLedgers && nonDepreciationMethodLength(assetLedgers) === assetLedgerLength
    const hasAllTaxAccountant =
      ledgerSettings?.some(element => element?.ledger_purpose === LEDGER_PURPOSE.TAX) &&
      ledgerSettings?.some(element => element?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING)

    const retirementDepreciationEndedMethodItems = retirementDepreciationEndedMethods.filter(
      item => item.code !== RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_DEPRECIATION
    )

    return (
      <Item
        required={
          isBulkChangeEvent ||
          (isRetirementDepreciationEndedMethod &&
            !(isSmallAmountDepreciableAsset || isAllLedgerIsNonDepreciationMethod))
        }
        label={t('select_depreciation_method')}
        contentWidth={contentItemWidth}
        style={
          !isBulkChangeEvent
            ? {
                width: itemWidth,
                whiteSpace: 'pre-line',
                alignItems: 'center',
                paddingBottom: 0,
              }
            : {}
        }
        requiredClassName={!isBulkChangeEvent ? 'retire-required-label' : ''}
      >
        <Group>
          {isBulkChangeEvent && isShowRetirementDepreciationEndedMethodField && (
            <AutoCompleteSelect
              name="retirement_fixed_asset_ledger_requests[0].retirement_depreciation_ended_method"
              wrapperStyle={{ width: 240 }}
              items={retirementDepreciationEndedMethodItems}
              valueField="code"
              textField="name_jp"
              allowClear={false}
            />
          )}

          {!isBulkChangeEvent &&
            ledgerSettings?.map((ledgerSetting, index) => {
              const {
                isSelectWhenSellorRetire,
                isEndOfCurrentMonth,
                isEndOfLastMonth,
                datasourceItems,
                isNonDepreciationOrNonTargetMethod,
                isSmallAmountDepreciableAsset,
                isBulkEqualInstallment,
                currentDepreciationMethodContinue,
              } = convertDataLedgerSettingHelper({
                ledgerSetting,
                assetItem,
                isEditMode,
                retirementDepreciationEndedMethods,
                hasAllTaxAccountant,
              })

              if (
                (isEndOfCurrentMonth || isEndOfLastMonth) &&
                (isSmallAmountDepreciableAsset || isNonDepreciationOrNonTargetMethod)
              ) {
                endedMethodIndex++

                return (
                  <div key={ledgerSettings[index].id}>
                    <UnderlineText style={{ paddingTop: 32 }} />
                    <HiddenField
                      name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].ledger_setting_id`}
                      value={ledgerSettings[index].id}
                    />
                    <HiddenField
                      name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].retirement_depreciation_ended_method`}
                      value={ledgerSettings[index].retirement_depreciation_ended_method}
                    />
                  </div>
                )
              }

              if (isSelectWhenSellorRetire) {
                endedMethodIndex++

                if (isSmallAmountDepreciableAsset || isNonDepreciationOrNonTargetMethod) {
                  return (
                    <div key={ledgerSettings[index].id}>
                      <UnderlineText style={{ paddingTop: 32 }} />
                      <HiddenField
                        name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].ledger_setting_id`}
                        value={ledgerSettings[index].id}
                      />
                      <HiddenField
                        name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].retirement_depreciation_ended_method`}
                        value={ledgerSettings[index].retirement_depreciation_ended_method}
                      />
                    </div>
                  )
                }

                return (
                  <div key={ledgerSettings[index].id}>
                    {/* Hanlde show/hide depreciation method input for tax ledger */}
                    {ledgerSetting?.ledger_purpose !== LEDGER_PURPOSE.TAX ? (
                      <AutoCompleteSelect
                        name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].retirement_depreciation_ended_method`}
                        disabled={currentDepreciationMethodContinue && isBulkEqualInstallment}
                        wrapperStyle={{ marginRight: 10, width: 240, marginTop: 8 }}
                        items={datasourceItems}
                        valueField="code"
                        textField="name_jp"
                        allowClear={false}
                      />
                    ) : null}
                    <HiddenField
                      width="240px"
                      name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].ledger_setting_id`}
                      value={ledgerSettings[index].id}
                    />
                  </div>
                )
              }
              endedMethodIndex++

              return (
                <div key={ledgerSettings[index].id}>
                  <UnderlineText style={{ marginRight: 10, height: 36 }}>&nbsp;</UnderlineText>
                  <HiddenField
                    name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].ledger_setting_id`}
                    value={ledgerSettings[index].id}
                  />
                  <HiddenField
                    name={`retirement_fixed_asset_ledger_requests[${endedMethodIndex}].retirement_depreciation_ended_method`}
                    value={ledgerSettings[index].retirement_depreciation_ended_method}
                  />
                </div>
              )
            })}
        </Group>
      </Item>
    )
  }

  useEffect(() => {
    const biid = get(values, 'biid')
    const isUnvalidConditions = !ledgerSettings || !retirementFixedAssetLedgerRequests || !retiredAt

    if (isUnvalidConditions) return

    if (!checkHasDepreciationEndedMethod(ledgerSettings) && !isBulkChangeEvent) {
      getBookInformation(biid, retiredAt, retirementFixedAssetLedgerRequests, ledgerSettings)
    }
  }, [ledgerSettings, retiredAt, retirementFixedAssetLedgerRequests]) // eslint-disable-line

  const isValidFields = validateForm(values, isRetirementDepreciationEndedMethod, !!isSmallAmountDepreciableAsset)

  return (
    <>
      <FormFields>
        <Item label={t('retirement_at')} required>
          <DatePicker name="retired_at" />
        </Item>
        <Item label={t('retirement_reason')} required>
          <AutoCompleteSelect
            name="event_cause_id"
            items={causes}
            valueField="id"
            textField="content"
            childrenNode={
              <RedirectToMasterPageLink
                permissions={permissions}
                urlName={ENDPOINTS.CAUSES}
                pageName={t3('master.causes')}
                onHandleReload={onHandleReloadCauses}
              />
            }
          />
        </Item>
        {isBulkChangeEvent && isShowRetirementDepreciationEndedMethodField && renderDepreciationEndedMethods()}
        {!isBulkChangeEvent && (
          <ExpandableBox>
            <div className="expandable-content">
              <BookValueFields
                ledgerSettings={ledgerSettings}
                assetItem={assetItem}
                values={values}
                bookInformationItems={bookInformationItems}
                isTaxMethodExcluded={isTaxMethodExcluded}
                t={t}
                contentItemWidth={contentItemWidth}
                itemWidth={itemWidth}
              />
              <DisposalCostWithExcise isBulkChangesEventForRetirement={isBulkChangeEvent} />
              <Item label={t('disposal_destination')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
                <Group>
                  <Input
                    name="disposal_destination_name"
                    style={{ width: 240 }}
                    maxLength={100}
                    placeholder={t('please_input')}
                  />
                </Group>
              </Item>
              <Item
                label={t('retirement_loss')}
                contentWidth={contentItemWidth}
                style={{ width: itemWidth, paddingBottom: 0, minHeight: 30 }}
              >
                <Group>
                  {renderDepreciationConditionField(
                    ledgerSettings,
                    'retirement_loss',
                    assetItem,
                    values,
                    bookInformationItems,
                    isTaxMethodExcluded,
                    isEditMode
                  )}
                </Group>
              </Item>
              {checkHasDepreciationEndedMethod(ledgerSettings)
                ? renderDepreciationEndedMethods()
                : renderHiddenDepreciationEndedMethods()}
            </div>
            {renderDrawerExpandToggle?.(ledgerSettings?.length)}
          </ExpandableBox>
        )}
        {!isBulkChangeEvent && <MediaUploader style={{ marginTop: 20 }} />}
      </FormFields>
      <Actions>
        <Button color="grey" onClick={onClose}>
          {t3('actions.cancel')}
        </Button>
        <Button type="submit" disabled={!isValidFields}>
          {t3('actions.register')}
        </Button>
      </Actions>
    </>
  )
}

export default Form
