import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 600px;
  text-align: center;
  margin: 30px auto 0;
  background: #ffffff;
  border: 1px solid #d4d8dd;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d4d8dd;
  padding: 29px;
`

export const Title = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
`

export const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ErrorWrapper = styled.div`
  width: 600px;
  padding: 10px;
  border: 2px solid transparent;
  line-height: 1.42;
  font-size: 13px;
  border-radius: 4px;

  margin: 60px auto 0 auto;
  background-color: #faeff0;
  color: #ec4949;
  border-color: #e7a9aa;
  white-space: break-spaces;
`
