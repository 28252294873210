import { ASSET_CHANGE_SITUATION_TYPES, FEATURES_ID } from 'common/constants'
import useUserPermission from 'lib/hooks/useUserPermission'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectEventHistories } from 'store/asset/selectors'

export const useCorrection = () => {
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)
  const { permissions: fixedAssetPermissions } = useUserPermission(FEATURES_ID.FIXED_ASSETS)
  const isCorrectable = fixedAssetPermissions?.isCRU || fixedAssetPermissions?.isCRUD

  const checkEventsAllowedForCorrection = (eventCode: number) => {
    return [
      ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED,
      ASSET_CHANGE_SITUATION_TYPES.ACQUISITION,
      ASSET_CHANGE_SITUATION_TYPES.ACCEPTANCE_OF_TRANSITION,
    ].includes(eventCode)
  }

  const eventsWithCorrectionLinkHidden = [
    ASSET_CHANGE_SITUATION_TYPES.SELL,
    ASSET_CHANGE_SITUATION_TYPES.MOVEMENT,
    ASSET_CHANGE_SITUATION_TYPES.RETIREMENT,
    ASSET_CHANGE_SITUATION_TYPES.OTHER_CHANGE,
    ASSET_CHANGE_SITUATION_TYPES.DIVIDING,
    ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT,
  ]

  const isCorrectionLinkHidden = eventHistories.some(event =>
    eventsWithCorrectionLinkHidden.includes(event.change_situation.code)
  )

  const checkDateWithMonthlyClosing = (date: string) => {
    const monthlyClosingBeginningDate = monthlyClosingCurrent?.from_term_month?.start_date
    return moment(date).isSameOrAfter(moment(monthlyClosingBeginningDate))
  }

  return { isCorrectable, isCorrectionLinkHidden, checkEventsAllowedForCorrection, checkDateWithMonthlyClosing }
}
