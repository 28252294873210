import { CoreMultiSelectPickerItem } from './model'

const getNodeText = (node: JSX.Element): any => {
  if (['string', 'number'].includes(typeof node)) return node
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (typeof node === 'object' && node) return getNodeText(node.props.children)
}

interface ConvertOptionSettings {
  items: CoreMultiSelectPickerItem[]
  selectedValues: any[] | null
  renderOptions: (item: CoreMultiSelectPickerItem) => JSX.Element
}

export const convertSelectOptionItems = ({ items, selectedValues, renderOptions }: ConvertOptionSettings) => {
  if (!items) return []
  return items.map(item => {
    return {
      ...item,
      selected: selectedValues?.findIndex(selectedValue => selectedValue.value === item.value) !== -1,
      value: item.value,
      optionSearchKey: getNodeText(renderOptions(item)),
    }
  })
}
