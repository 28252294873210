import { getBookInformation } from 'api/app/asset'
import { ASSET_CHANGE_SITUATION_TYPES, DATE_FORMAT_RFC, DEPRECIATION_METHOD_CODE } from 'common/constants'
import {
  findAccountingLedger,
  getDepreciationCodeOfAccountingLedger,
  isAccountingJournalTarget,
} from 'common/helpers/ledger-helper'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectAssetItemData } from 'store/asset/selectors'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

const defaultBookInfoValue = { book_value: 0, date: '' } as const

// Ref: https://miro.com/app/board/uXjVOz6KRwc=/?moveToWidget=3458764526445045122&cot=14
export const useImpairmentBookInfo = (impairAtFieldName: string, impairMethodFieldName: string) => {
  const [bookValue, setBookValue] = useState(defaultBookInfoValue)
  const [loading, setLoading] = useState(false)

  const { biid } = useParams<{ biid: string }>()
  const { data: ledgers } = useSelector(selectorLedgerSettingsList, isEqual)

  const watchValues = useWatch({ name: [impairAtFieldName, impairMethodFieldName] })
  const impairedAt = watchValues[impairAtFieldName]
  const depreciation_ended_method = watchValues[impairMethodFieldName]

  useEffect(() => {
    if (!biid || !impairedAt || !depreciation_ended_method) {
      setBookValue(defaultBookInfoValue)
      return
    }

    const ledgersPayload = [
      {
        ledger_setting_id: findAccountingLedger(ledgers)?.id as number,
        depreciation_ended_method,
      },
    ]

    setLoading(true)
    let isCancelled = false
    getBookInformation(
      biid,
      moment(impairedAt).format(DATE_FORMAT_RFC),
      ledgersPayload,
      [],
      ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT
    )
      .then(response => {
        if (isCancelled) return
        const bookInfo = response?.data?.data?.items?.[0]
        setBookValue(bookInfo || defaultBookInfoValue)
      })
      .finally(() => setLoading(false))

    return () => {
      isCancelled = true
    }
  }, [biid, impairedAt, ledgers, depreciation_ended_method])

  return useMemo(() => {
    return [bookValue, loading] as const
  }, [bookValue, loading])
}

// Ref: https://miro.com/app/board/uXjVOz6KRwc=/?moveToWidget=3458764526445045453&cot=14
export const useImpairmentLossAmount = (bookValue: number) => {
  const asset = useSelector(selectAssetItemData, isEqual)

  return useMemo(() => {
    const assetType = asset?.asset_category?.asset_type
    const dMethodCode = getDepreciationCodeOfAccountingLedger(asset?.fixed_asset_ledgers)

    if (bookValue && assetType && dMethodCode) {
      const isSpecialMethod = [
        DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
        DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
      ].includes(dMethodCode)

      const ledgers = asset?.fixed_asset_ledgers || []
      const ledger = (ledgers || []).find(({ ledger_setting }) => isAccountingJournalTarget(ledger_setting))

      return bookValue - (isSpecialMethod ? ledger?.residual_amount || 0 : ledger?.memorandum_value || 0)
    }

    return 0
  }, [bookValue, asset])
}
