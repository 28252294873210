import { EXCISE_TAX_METHOD } from 'common/constants'
import { JournalData } from 'common/models'
import React, { useMemo } from 'react'

import { genColumns } from './settings'
import { Table, Wrapper } from './styles'

interface Props {
  journals: JournalData[]
  exciseSettingByDate: any
}

const JournalList = ({ journals, exciseSettingByDate }: Props) => {
  const columns = useMemo(() => {
    return genColumns({
      isIncluded: exciseSettingByDate?.data?.tax_method === EXCISE_TAX_METHOD.INCLUDED_TAX,
      lastKey: 'journal-actions',
      className: 'even-num-journal',
    })
  }, [exciseSettingByDate?.data])

  return (
    <Wrapper className="journal-list-wrapper">
      <Table
        tableLayout="fixed"
        columns={columns}
        dataSource={journals}
        pagination={false}
        rowClassName={(record: any) => record?.isError && 'row-error'}
      />
    </Wrapper>
  )
}

export default JournalList
