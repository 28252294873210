import * as assetCategoryApi from 'api/app/assetCategory'
import * as autoJournalApi from 'api/app/autoJournalSetting'
import * as journalApi from 'api/app/journal'
import * as monthlyClosingApi from 'api/app/monthlyClosing'
import { AxiosResponse } from 'axios'
import { API_ERROR_META_CODE, API_STATUS_CODE, SAGA_TIME_DELAY } from 'common/constants'
import { parseError } from 'common/helpers'
import { downloadFileByResponse } from 'common/helpers/file-helper'
import { formatMonth } from 'common/helpers/format-helper'
import { getConstant } from 'components/organisms/accounting/GeneralJournalSettingList/const'
import i18n from 'i18n'
import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { SetAppMessage } from 'store/app/action.model'
import { changeLocation, SET_APP_MESSAGE, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { ON_WEBSOCKET_MESSAGE } from 'store/session/actions'

import { selectMonthlyClosingCurrent } from '../monthlyClosing/selectors'
import {
  DeleteAssetCategoryJournalSettingsItemFailure,
  DeleteAssetCategoryJournalSettingsItemRequest,
  DeleteAssetCategoryJournalSettingsItemSuccess,
  DeleteGeneralJournalSettingsTermFailure,
  DeleteGeneralJournalSettingsTermRequest,
  DeleteGeneralJournalSettingsTermSuccess,
  ExportJournalLinkageRequest,
  FetchAssetCategoryTermsJournalSettingsFailure,
  FetchAssetCategoryTermsJournalSettingsRequest,
  FetchAssetCategoryTermsJournalSettingsSuccess,
  FetchJournalGeneralSettingTermsFailure,
  FetchJournalGeneralSettingTermsSuccess,
  FetchJournalLinkageDetailRequest,
  FetchJournalSettingsForAssetCategoryListFailure,
  FetchJournalSettingsForAssetCategoryListSuccess,
  SaveGeneralJournalSettingsItemFailure,
  SaveGeneralJournalSettingsItemRequest,
  SaveGeneralJournalSettingsItemSuccess,
  SaveJournalItemSettingsFailure,
  SaveJournalItemSettingsRequest,
  SaveJournalItemSettingsSuccess,
  UpdateGeneralJournalSettingsItemRequest,
  UpdateJournalItemSettingsRequest,
} from './action.model'
import {
  fetchJournalLinkageDetailFails,
  fetchJournalLinkageDetailSuccess,
  fetchJournalsFails,
  fetchJournalsSuccess,
  handleFetchCountOfJournalEvents,
  sendJournalFailPartial,
  setTotalJournals,
} from './actions'
import * as Constant from './constant'
import { selectJournalLinkageStatus } from './selectors'

export function* fetchJournalsReport({ payload }: any) {
  const { params, pageNumber, pageSize } = payload
  const { data, errors } = yield call(journalApi.getJournals, params, pageNumber, pageSize)
  if (data) {
    yield put(fetchJournalsSuccess(data?.data || []))
    yield put(setTotalJournals(data?.meta?.total || 0))
    yield delay(SAGA_TIME_DELAY)
  } else if (errors) {
    yield put(fetchJournalsFails(errors))
    yield put(setTotalJournals(0))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchJournalLinkageDetail({ linkageHistoryId }: FetchJournalLinkageDetailRequest) {
  const { data, errors } = yield call(journalApi.getJournalLinkageDetail, linkageHistoryId)
  if (data) {
    const journalData = map(data?.data?.items, ({ journal, ...rest }) => ({ ...journal, ...rest }))
    const exportNumber = get(data, 'data.serial_number', 0)
    const termMonth = get(data, 'data.monthly_closing')
    const totalFail = get(data, 'data.total_fail')
    const summaryErrorType = get(data, 'data.summary_error_type')
    const journalLinkageStatus = get(data, 'data.journal_linkage_status')

    yield put(
      fetchJournalLinkageDetailSuccess({
        data: journalData,
        exportNumber,
        termMonth,
        totalFail,
        summaryErrorType,
        journalLinkageStatus,
      })
    )
  } else if (errors) {
    yield put(fetchJournalLinkageDetailFails())
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchCountOfJournalEvents({ payload }: any) {
  const { data, errors } = yield call(journalApi.getCountOfJournalEvents, payload)
  if (data) {
    yield put(handleFetchCountOfJournalEvents(data?.data || {}))
    yield delay(SAGA_TIME_DELAY)
  } else if (errors) {
    yield put(handleFetchCountOfJournalEvents({}))
    yield put(showErrorsAppCall(errors))
  }
}

export function* fetchJournals() {
  const { data, errors, meta } = yield call(monthlyClosingApi.getJournals)
  if (data) {
    yield put(fetchJournalsSuccess(data?.data || []))
  } else if (errors) {
    if (meta?.code === API_ERROR_META_CODE.JOURNAL_SENDING_IN_PROCESS) {
      yield put(changeLocation('/accounting/monthly-closing'))
    }
    yield put(fetchJournalsFails(errors))
  }
}

export function* fetchJournalSettingsForAssetCategoryList() {
  try {
    const assetCategoryListResponse: AxiosResponse = yield call(assetCategoryApi.searchAuth, { sort: ['name'] })
    const latestJournalSettingListResponse: AxiosResponse = yield call(
      autoJournalApi.getJournalSettingsForAssetCategoryList
    )
    const convertedList = convertJournalSettingForAssetCategoryList(
      assetCategoryListResponse.data.data,
      latestJournalSettingListResponse.data.data
    )
    yield delay(2000)
    yield put<FetchJournalSettingsForAssetCategoryListSuccess>({
      type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_SUCCESS,
      payload: convertedList,
    })
  } catch (e) {
    yield put<FetchJournalSettingsForAssetCategoryListFailure>({
      type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_FAILURE,
    })
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* fetchTermsJournalSettingsForAssetCategory({
  assetCategoryId,
}: FetchAssetCategoryTermsJournalSettingsRequest) {
  try {
    const response: AxiosResponse = yield call(autoJournalApi.getAssetCategoryTermsJournalSettings, {
      asset_category_id: assetCategoryId,
    })
    yield put<FetchAssetCategoryTermsJournalSettingsSuccess>({
      type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_SUCCESS,
      payload: response.data.data,
    })
  } catch (e) {
    yield put<FetchAssetCategoryTermsJournalSettingsFailure>({
      type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_FAILURE,
    })
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* fetchJournalGeneralSettingsTerms() {
  try {
    const response: AxiosResponse = yield call(autoJournalApi.getGeneralJournalSettingTerms)
    yield put<FetchJournalGeneralSettingTermsSuccess>({
      type: Constant.FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_SUCCESS,
      payload: response.data.data,
    })
  } catch (e) {
    yield put<FetchJournalGeneralSettingTermsFailure>({
      type: Constant.FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_FAILURE,
    })
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* updateJournalItem({ id, payload, meta }: UpdateJournalItemSettingsRequest) {
  try {
    yield call(autoJournalApi.updateJournalItemSettings, id, payload)
    const response: AxiosResponse = yield call(autoJournalApi.getAssetCategoryTermsJournalSettings, {
      asset_category_id: payload.asset_category_id,
    })
    yield put({ type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST })
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.updated_success') }))
    yield put<SaveJournalItemSettingsSuccess>({
      type: Constant.SAVE_JOURNAL_ITEM_SETTINGS_SUCCESS,
      list: get(response, 'data.data') || [],
      meta,
    })
  } catch (e) {
    yield put<SaveJournalItemSettingsFailure>({
      type: Constant.SAVE_JOURNAL_ITEM_SETTINGS_FAILURE,
      payload: e,
      meta,
      error: true,
    })
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* saveAssetCategoryJournalSettingTerm({ payload, meta }: SaveJournalItemSettingsRequest) {
  try {
    yield call(autoJournalApi.saveAssetCategoryJournalSettingsTerm, payload)
    const response: AxiosResponse = yield call(autoJournalApi.getAssetCategoryTermsJournalSettings, {
      asset_category_id: payload.asset_category_id,
    })
    yield put({ type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST })
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.updated_success') }))
    yield put<SaveJournalItemSettingsSuccess>({
      type: Constant.SAVE_JOURNAL_ITEM_SETTINGS_SUCCESS,
      list: get(response, 'data.data') || [],
      meta,
    })
  } catch (e) {
    yield put<SaveJournalItemSettingsFailure>({
      type: Constant.SAVE_JOURNAL_ITEM_SETTINGS_FAILURE,
      payload: e,
      meta,
      error: true,
    })
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* saveGeneralJournalSettingItem({ payload, meta }: SaveGeneralJournalSettingsItemRequest) {
  try {
    yield call(autoJournalApi.createGeneralJournalSettingsItem, payload)
    const response: AxiosResponse = yield call(autoJournalApi.getGeneralJournalSettingTerms)
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.updated_success') }))
    yield put<SaveGeneralJournalSettingsItemSuccess>({
      type: Constant.SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS,
      list: response?.data?.data,
      meta,
    })
  } catch (e) {
    const status = get(e, 'response.status')
    yield put<SaveGeneralJournalSettingsItemFailure>({
      type: Constant.SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE,
      meta,
      payload: e,
      error: true,
    })
    if (status !== API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      yield put(showErrorsAppCall(parseError(e)))
    }
  }
}

export function* updateGeneralJournalSettingItem({ id, payload, meta }: UpdateGeneralJournalSettingsItemRequest) {
  try {
    yield call(autoJournalApi.updateGeneralJournalSettingsItem, id, payload)
    const response: AxiosResponse = yield call(autoJournalApi.getGeneralJournalSettingTerms)
    yield put(setAppMessage({ type: 'success', content: i18n.t('common.messages.updated_success') }))
    yield put<SaveGeneralJournalSettingsItemSuccess>({
      type: Constant.SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS,
      list: response.data.data,
      meta,
    })
  } catch (e) {
    const status = get(e, 'response.status')
    yield put<SaveGeneralJournalSettingsItemFailure>({
      type: Constant.SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE,
      meta,
      payload: e,
      error: true,
    })
    if (status !== API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      yield put(showErrorsAppCall(parseError(e)))
    }
  }
}

export function* deleteAssetCategoryJournalSettingTerm({
  deletedId,
  assetCategoryId,
}: DeleteAssetCategoryJournalSettingsItemRequest) {
  try {
    yield call(autoJournalApi.deleteAssetCategoryJournalSettingsTerm, deletedId)
    const response: AxiosResponse = yield call(autoJournalApi.getAssetCategoryTermsJournalSettings, {
      asset_category_id: assetCategoryId,
    })
    yield put({ type: Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST })
    yield put<SetAppMessage>({
      type: SET_APP_MESSAGE,
      payload: { type: 'success', content: i18n.t('common.messages.deleted_success') },
    })
    yield put<DeleteAssetCategoryJournalSettingsItemSuccess>({
      type: Constant.DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_SUCCESS,
      payload: get(response, 'data.data') || [],
    })
  } catch (e) {
    yield put<DeleteAssetCategoryJournalSettingsItemFailure>({
      type: Constant.DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_FAILURE,
    })
    yield put(showErrorsAppCall(parseError(e)))
  }
}

export function* deleteGeneralJournalSettingTerm({ deletedId }: DeleteGeneralJournalSettingsTermRequest) {
  try {
    yield call(autoJournalApi.deleteGeneralJournalSettingsTerm, deletedId)
    const response: AxiosResponse = yield call(autoJournalApi.getGeneralJournalSettingTerms)
    yield put<SetAppMessage>({
      type: SET_APP_MESSAGE,
      payload: { type: 'success', content: i18n.t('common.messages.deleted_success') },
    })
    yield put<DeleteGeneralJournalSettingsTermSuccess>({
      type: Constant.DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_SUCCESS,
      list: response.data.data,
    })
  } catch (e) {
    yield put(showErrorsAppCall(parseError(e)))
    yield put<DeleteGeneralJournalSettingsTermFailure>({
      type: Constant.DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_FAILURE,
    })
  }
}

export function* exportJournals({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(journalApi.exportFile, payload.format, payload.charset)
    yield downloadFileByResponse(response)
    yield put({ type: Constant.EXPORT_JOURNAL_SUCCESS })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      },
    })
  } catch (error) {
    yield put({ type: Constant.EXPORT_JOURNAL_FAILURE })
    yield put({
      type: SET_APP_MESSAGE,
      payload: {
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      },
    })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* exportJournalLinkageHistory({ payload }: ExportJournalLinkageRequest) {
  try {
    const response: AxiosResponse = yield call(
      journalApi.exportJournalLinkageDetail,
      payload.linkage_history_id,
      payload.charset
    )
    yield downloadFileByResponse(response)
    yield put({ type: Constant.EXPORT_JOURNAL_LINKAGE_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (error) {
    yield put({ type: Constant.EXPORT_JOURNAL_LINKAGE_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchJournalLinkageStatus() {
  const response: AxiosResponse = yield call(journalApi.getJournalStatus)
  if (response.data) {
    yield put({ type: Constant.FETCH_JOURNAL_LINKAGE_STATUS_SUCCESS, payload: response?.data?.data ?? {} })
  } else {
    yield put({ type: Constant.FETCH_JOURNAL_LINKAGE_STATUS_FAIL, payload: response?.status })
  }
}

export function* sendJournal() {
  try {
    yield call(journalApi.sendJournal)
    yield put({ type: Constant.SEND_JOURNAL_DONE })
  } catch (error: any) {
    if (error?.response?.data?.meta?.code === API_ERROR_META_CODE.JOURNAL_SENDING_IN_PROCESS) {
      yield put(showErrorsAppCall(parseError(error)))
    } else {
      yield put(
        setAppMessage({
          type: 'failure',
          content: i18n.t('pages.accounting.JournalList.send_journal_fail'),
        })
      )
    }
    yield put({ type: Constant.SEND_JOURNAL_DONE })
  }
}

export function* handleResultSubmitJournal(action: any) {
  const { ACCOUNTING_PLUS_STATUS_VALUES } = getConstant()
  const isSendJournalResultMsg = Object.prototype.hasOwnProperty.call(
    action?.payload?.message,
    'export_journal_history_id'
  )

  if (!isSendJournalResultMsg) return

  // eslint-disable-next-line no-unsafe-optional-chaining
  const { export_journal_history_id, total, total_fail, error, summary_error_type } = action?.payload?.message
  if (error || total_fail === total || summary_error_type) {
    const monthlyClosing: ReturnType<typeof selectMonthlyClosingCurrent> = yield select(selectMonthlyClosingCurrent)
    const closingYear = monthlyClosing?.from_term_month.year
    const closingMonth = formatMonth(monthlyClosing.from_term_month.month)
    const errorTypes = ['out_of_term', 'closed_month', 'permission_denied', 'not_found']
    yield put(
      setAppMessage({
        type: 'failure',
        content: errorTypes.includes(summary_error_type)
          ? i18n.t(`pages.accounting.JournalList.${summary_error_type}`, { year: closingYear, month: closingMonth })
          : i18n.t('pages.accounting.JournalList.send_journal_fail'),
      })
    )
  } else if (total_fail === 0) {
    const data: ReturnType<typeof selectJournalLinkageStatus> = yield select(selectJournalLinkageStatus)
    const isApproved = data.data?.journal_linkage_status === ACCOUNTING_PLUS_STATUS_VALUES?.approve?.id
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('pages.accounting.JournalList.send_journal_done'),
        path: isApproved ? '/books' : '/applicant/cooperation_journals',
        label: i18n.t('pages.accounting.JournalList.confirm_link'),
      })
    )
  } else {
    yield put(sendJournalFailPartial({ export_journal_history_id, total_fail }))
  }

  yield put({ type: Constant.SEND_JOURNAL_DONE })
}

export default function* actionWatcher() {
  yield takeLatest(Constant.FETCH_JOURNALS_REQUEST, fetchJournals)
  yield takeLatest(Constant.FETCH_JOURNAL_LINKAGE_DETAIL_REQUEST, fetchJournalLinkageDetail)
  yield takeLatest(Constant.FETCH_JOURNALS_REPORT_REQUEST, fetchJournalsReport)
  yield takeLatest(Constant.FETCH_COUNT_OF_JOURNAL_EVENTS, fetchCountOfJournalEvents)
  yield takeLatest(
    Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_LIST_REQUEST,
    fetchJournalSettingsForAssetCategoryList
  )
  yield takeLatest(
    Constant.FETCH_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_TERMS_REQUEST,
    fetchTermsJournalSettingsForAssetCategory
  )
  yield takeLatest(Constant.DELETE_ASSET_CATEGORY_JOURNAL_SETTINGS_ITEM_REQUEST, deleteAssetCategoryJournalSettingTerm)
  yield takeLatest(Constant.SAVE_JOURNAL_ITEM_SETTINGS_REQUEST, saveAssetCategoryJournalSettingTerm)
  yield takeLatest(Constant.UPDATE_JOURNAL_ITEM_SETTINGS_REQUEST, updateJournalItem)
  yield takeLatest(Constant.FETCH_JOURNAL_GENERAL_SETTINGS_TERMS_REQUEST, fetchJournalGeneralSettingsTerms)
  yield takeLatest(Constant.SAVE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST, saveGeneralJournalSettingItem)
  yield takeLatest(Constant.UPDATE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST, updateGeneralJournalSettingItem)
  yield takeLatest(Constant.DELETE_GENERAL_JOURNAL_SETTINGS_ITEM_REQUEST, deleteGeneralJournalSettingTerm)
  yield takeLatest(Constant.EXPORT_JOURNAL_REQUEST, exportJournals)
  yield takeLatest(Constant.EXPORT_JOURNAL_LINKAGE_REQUEST, exportJournalLinkageHistory)
  yield takeLatest(Constant.FETCH_JOURNAL_LINKAGE_STATUS_REQUEST, fetchJournalLinkageStatus)
  yield takeLatest(Constant.SEND_JOURNAL_REQUEST, sendJournal)
  yield takeEvery(ON_WEBSOCKET_MESSAGE, handleResultSubmitJournal)
}

const convertJournalSettingForAssetCategoryList = (assetCategoryList: any[], latestJournalSettingList: any[]) => {
  if (!assetCategoryList) {
    return []
  }

  return assetCategoryList.map(item => {
    const latestSettings = find(
      latestJournalSettingList,
      latestJournalItem => get(latestJournalItem, 'asset_category_id') === item.id
    )
    return {
      ...item,
      latestSettings: latestSettings || null,
    }
  })
}
