import { Button } from 'aa_common/front-end/antd'
import * as assetApi from 'api/app/asset'
import { loadNs } from 'common/i18n-config'
import { RangeInput } from 'components/molecules'
import useResource from 'lib/hooks/useResource'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect } from 'react'

import { BaseFormFilter } from '../../model'
import { ActionButtonWrapper, Field, Label, MoneyAmountWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

const MoneyAmountForm = ({ formModule, setPopupShow, onFormSubmit }: BaseFormFilter) => {
  const [range, getRange] = useResource(assetApi.getRange)

  useEffect(() => {
    getRange()
  }, [getRange])

  useEffect(() => {
    const acquisitionCostValue = formModule.watch('acquisition_cost')
    const isEqualToDefaultValue = isEqual(acquisitionCostValue, [0, 1000000])

    if (range.data && isEqualToDefaultValue) {
      const {
        acquisition_cost: { min, max },
      } = range.data
      formModule && formModule.setValue('acquisition_cost', [min, max])
    } else if (range.data && !isEqualToDefaultValue) {
      formModule && formModule.setValue('acquisition_cost', acquisitionCostValue)
    }
  }, [range]) // eslint-disable-line

  const { handleSubmit } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleFormReset = () => {
    formModule.reset({
      acquisition_cost: null,
    })
  }

  const handleClickSubmit = () => {
    const { getValues } = formModule
    const submitFormValues = {
      acquisition_cost: getValues('acquisition_cost'),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
    setPopupShow(false)
  }

  return (
    <MoneyAmountWrapper>
      <Field>
        <Label htmlFor="acquisition_cost">{t('form.acquisition_cost')}</Label>
        {range.data && (
          <RangeInput
            type="currency"
            disabled={range.isLoading}
            name="acquisition_cost"
            control={formModule.control}
            range={range.data.acquisition_cost}
          />
        )}
      </Field>
      <ActionButtonWrapper>
        <Button
          disabled={range.isLoading}
          type="button"
          style={{ marginTop: 10 }}
          color="grey"
          onClick={handleFormReset}
        >
          {t('actions.clear')}
        </Button>
        <Button disabled={range.isLoading} type="button" style={{ marginTop: 10 }} onClick={handleClickSubmit}>
          {t('actions.ok')}
        </Button>
      </ActionButtonWrapper>
    </MoneyAmountWrapper>
  )
}

export default MoneyAmountForm
