import { Map } from 'immutable'

import { UnderOverDepreciationReportActionTypes } from './action.model'
import {
  EXPORT_UNDER_OVER_DEPRECIATION_REPORT_FAILURE,
  EXPORT_UNDER_OVER_DEPRECIATION_REPORT_REQUEST,
  EXPORT_UNDER_OVER_DEPRECIATION_REPORT_SUCCESS,
} from './constant'

export const initialState = Map({
  export: {
    loading: false,
  },
})

export default (state = initialState, action: UnderOverDepreciationReportActionTypes) => {
  switch (action.type) {
    case EXPORT_UNDER_OVER_DEPRECIATION_REPORT_REQUEST:
      return state.setIn(['export', 'loading'], true)
    case EXPORT_UNDER_OVER_DEPRECIATION_REPORT_SUCCESS:
    case EXPORT_UNDER_OVER_DEPRECIATION_REPORT_FAILURE:
      return state.setIn(['export', 'loading'], false)
    default:
      return state
  }
}
