import { getIn } from 'formik'
import { useEffect, useMemo } from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'

const enableCopyValue = (fieldName: string, formValues: any, valueFirstItem: any) => {
  return getIn(formValues, fieldName) !== valueFirstItem
}

const getValueOfFirstItem = (name: string, formValues: any) => {
  return getIn(formValues, genLedgerFieldName(name, 0))
}

export function useLedgerCopy(
  formValues: any,
  setFieldValue: (name: string, value: any) => void,
  index: number,
  isCopied: boolean
) {
  const copyFields = useMemo(() => {
    return [
      DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE,
      DEPRECIATION_FIELDS.SERVICE_LIFE,
      DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS,
      DEPRECIATION_FIELDS.RESIDUAL_AMOUNT,
      DEPRECIATION_FIELDS.DEPRECIATION_RATE,
      DEPRECIATION_FIELDS.GUARANTEE_RATE,
      DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE,
      DEPRECIATION_FIELDS.ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE,
      DEPRECIATION_FIELDS.TRANSITION_BEGINNING_BOOK_VALUE,
      DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE,
      DEPRECIATION_FIELDS.REVISED_ACQUISITION_AMOUNT,
      DEPRECIATION_FIELDS.DEPRECIATED_MONTHS,
      DEPRECIATION_FIELDS.TRANSITION_AT,
      DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE,
      DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE,
      DEPRECIATION_FIELDS.ACCUMULATED_DEPRECIATION_AMOUNT,
      DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT,
      DEPRECIATION_FIELDS.MEMORANDUM_VALUE,
    ]
  }, [])

  const firstItemValues = useMemo(
    () => copyFields.map(name => String(getValueOfFirstItem(name, formValues))).join('-'),
    [copyFields, formValues]
  )
  useEffect(() => {
    if (isCopied) {
      copyFields.forEach(name => {
        const valueFirstItem = getValueOfFirstItem(name, formValues)
        const fieldName = genLedgerFieldName(name, index)
        enableCopyValue(fieldName, formValues, valueFirstItem) && setFieldValue(fieldName, valueFirstItem)
      })
    }
  }, [firstItemValues, setFieldValue, index, copyFields, isCopied])
}
