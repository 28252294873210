import React from 'react'

import { StyledWrapper } from './styles'

export const IndeterminateLoading = () => {
  return (
    <StyledWrapper>
      <div className="progress-bar">
        <div className="progress-bar-value" />
      </div>
    </StyledWrapper>
  )
}
