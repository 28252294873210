import styled from '@emotion/styled'
import { Button, If } from 'aa_common/front-end'
import { BasicModal } from 'aa_common/front-end/components/modals/basic-modal'
import { Col, DatePicker, Row, Typography } from 'antd'
import { DECLARATION_REPORT_EXPORT_MODE } from 'AppExport/model'
import { DATE_FORMAT } from 'common/constants'
import { FormCheckBox, FormProviderWrapper, RowFormItem } from 'common/form'
import { CustomRadioGroup } from 'common/form/radio-group'
import { getNs } from 'common/i18n-config'
import i18n from 'i18n'
import React from 'react'

import { ExportType } from '../constant'
import { useCountSelectedReports } from '../hooks/use-count-selected-reports'
import { ExportCsvMultileDto } from '../hooks/use-export-csv'
import { ExportPdfMultileDto } from '../hooks/use-export-pdf'
import { useFormExport } from '../hooks/use-form-export'

const { t } = getNs(['common', 'pages/accounting/depreciation-fixed-asset-page'])
export const DescriptionRow = ({ children }: { children: React.ReactNode }) => (
  <Row>
    <Col span={6} />
    <Col span={18}>{children}</Col>
  </Row>
)
export const ExportDepreciationModal = (props: {
  visible: boolean
  handleOpenModal: (visible: boolean) => void
  onSubmitExport: (data: ExportCsvMultileDto | ExportPdfMultileDto) => void
}) => {
  const { isValid, handleSubmit, methods, exportType, optionsFormatFile, optionsFormatCsvType } = useFormExport(
    props.visible
  )
  const { countSelectedReports } = useCountSelectedReports()

  return (
    <StyleModal>
      <BasicModal
        title={t('export_modal.title')}
        width={570}
        isShow={props.visible}
        onCancel={() => props.handleOpenModal(false)}
        footer={
          <>
            <Button className="btn-cancel" onClick={() => props.handleOpenModal(false)} color="grey">
              {i18n.t('common.actions.cancel')}
            </Button>
            <Button data-testid="tag-submit-btn" disabled={!isValid} onClick={handleSubmit(props.onSubmitExport)}>
              {i18n.t('common.actions.ok')}
            </Button>
          </>
        }
      >
        <Typography>
          {countSelectedReports} {t('export_modal.export_destination')}
        </Typography>

        <FormProviderWrapper methods={methods}>
          <RowFormItem
            name="mode"
            label={t('export_modal.export_types')}
            labelStyle={{ fontWeight: 500, fontSize: 13 }}
            style={{ padding: '10px 0 0 0 ' }}
            element={
              <CustomRadioGroup
                options={[
                  { label: t('radios.increase_decrease'), value: DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR },
                  { label: t('radios.all_fixed_assets'), value: DECLARATION_REPORT_EXPORT_MODE.ALL },
                ]}
              />
            }
          />
          <RowFormItem
            name="exportType"
            label={t('export_modal.file_format')}
            style={{ padding: '10px 0 0 0 ' }}
            labelStyle={{ fontWeight: 500, fontSize: 13 }}
            element={<CustomRadioGroup options={optionsFormatFile} />}
          />
          <DescriptionRow>
            <If condition={exportType === ExportType.PDF}>
              <StyledDescription>{t('export_modal.note_file_format_pdf')}</StyledDescription>
            </If>
            <If condition={exportType === ExportType.CSV}>
              <StyledDescription>{t('export_modal.note_file_format_csv')}</StyledDescription>
            </If>
          </DescriptionRow>
          <If condition={exportType === ExportType.PDF}>
            <RowFormItem
              name="submit_date"
              labelStyle={{ fontWeight: 500, fontSize: 13 }}
              label={t('export_modal.submit_date')}
              element={<DatePickerCenterStyle style={{ width: 240 }} allowClear={false} format={DATE_FORMAT} />}
            />
          </If>

          <If condition={exportType === ExportType.CSV}>
            <RowFormItem
              name="charset"
              label={t('export_modal.character_code')}
              style={{ padding: '10px 0 0 0 ' }}
              labelStyle={{ fontWeight: 500, fontSize: 13 }}
              element={<CustomRadioGroup options={optionsFormatCsvType} />}
            />
          </If>

          <If condition={exportType === ExportType.PDF}>
            <RowFormItem
              name="with_asset_code"
              label={t('export_modal.asset_code')}
              labelStyle={{ fontWeight: 500, fontSize: 13 }}
              element={
                <FormCheckBox
                  addOnAfter={<Typography style={{ marginLeft: 8 }}>{t('export_modal.output')}</Typography>}
                />
              }
            />
            <DescriptionRow>
              <StyledDescription>{t('export_modal.output_asset_code_description')} </StyledDescription>
            </DescriptionRow>
          </If>
        </FormProviderWrapper>
      </BasicModal>
    </StyleModal>
  )
}

const StyleModal = styled.div`
  span {
    line-height: 150%;
  }
  article {
    line-height: 150%;
  }
`

const StyledDescription = styled(Typography)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #919191;
`

const DatePickerCenterStyle = styled(DatePicker)`
  input {
    text-align: center;
  }
`
