import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-radio-group {
    label:nth-of-type(2) {
      margin-left: 46px;
    }

    span.ant-radio + * {
      padding-right: 0px;
      padding-left: 10px;
    }
  }
`
