export const FETCH_LEDGER_SETTINGS_LIST_REQUEST = 'FETCH_LEDGER_SETTINGS_LIST_REQUEST'
export const FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH = 'FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH'
export const FETCH_LEDGER_SETTINGS_LIST_SUCCESS = 'FETCH_LEDGER_SETTINGS_LIST_SUCCESS'
export const FETCH_LEDGER_SETTINGS_LIST_FAILURE = 'FETCH_LEDGER_SETTINGS_LIST_FAILURE'

export const SAVE_LEDGER_SETTINGS_LIST_REQUEST = 'SAVE_LEDGER_SETTINGS_LIST_REQUEST'
export const SAVE_LEDGER_SETTINGS_LIST_SUCCESS = 'SAVE_LEDGER_SETTINGS_LIST_SUCCESS'
export const SAVE_LEDGER_SETTINGS_LIST_FAILURE = 'SAVE_LEDGER_SETTINGS_LIST_FAILURE'

export const SAVE_HAS_ACCOUNTANT_AND_TAX_LEDGER = 'SAVE_HAS_ACCOUNTANT_AND_TAX_LEDGER'

export const CLEANUP_LEDGER_SETTINGS = 'CLEANUP_LEDGER_SETTINGS'
