import { Spin } from 'aa_common/front-end/antd'
import { BasicTable } from 'aa_common/front-end/components'
import { CONFIRM_TYPES, FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { NoDataImage } from 'components/atoms'
import { ListPageActions } from 'components/molecules'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import { get } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import { CAUSE_LIST_COLUMNS_WIDTH, columns, convertTypeToJPName } from './helper'
import { ActionWrapper, EmptyImage, Wrapper } from './styles'

const t = loadNs(['common', 'components/organisms/settings/cause-list'])

type CauseListProps = {
  masterData: any[]
  causes: any[]
  loading?: boolean
  onCopy: (item: any) => void
  onDelete: (item: any) => void
  onEdit: (item: any) => void
  handleExportClick: () => void
  handleImportClick: () => void
  handleAddNew: () => void
}

const CauseList = ({
  masterData,
  causes,
  loading,
  onCopy,
  onEdit,
  onDelete,
  handleAddNew,
  handleExportClick,
  handleImportClick,
}: CauseListProps) => {
  const modal = useModal()

  const { permissions: causePermission } = useUserPermission(FEATURES_ID.MASTER_CAUSES)

  const handleCopy = useCallback((item: any) => onCopy(item), [onCopy])

  const memoizedCauses = useMemo(() => convertTypeToJPName(masterData, causes), [masterData, causes])

  const handleClickDelete = useCallback(
    (item: any) => {
      modal
        .confirm({
          title: '',
          message: t('messages.confirm_delete', { name: get(item, 'content') }),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isOKClick => {
          if (isOKClick) {
            onDelete && onDelete(item)
          }
        })
    },
    [onDelete]
  ) //eslint-disable-line

  const disableActions = useMemo(
    () => ({
      copy: () => !causePermission.isCreate,
      edit: (record: any) => !!get(record, 'is_default') || !causePermission.isEdit,
      delete: (record: any) => !!get(record, 'is_default') || !causePermission.isDelete,
    }),
    [causePermission]
  )

  const showActionsTooltip = useMemo(
    () => ({
      copy: () => {
        return !causePermission.isCreate ? t('actions.no_permission') : undefined
      },
      edit: (record: any) => {
        return !causePermission.isEdit && !get(record, 'is_default') ? t('actions.no_permission') : undefined
      },
      delete: (record: any) => {
        return !causePermission.isDelete && !get(record, 'is_default') ? t('actions.no_permission') : undefined
      },
    }),
    [causePermission]
  )

  return (
    <Wrapper>
      <Spin loading={loading}>
        <ActionWrapper>
          <ListPageActions
            featureId={FEATURES_ID.MASTER_CAUSES}
            add={{ onClick: handleAddNew, disabled: loading }}
            onImport={{ onClick: handleImportClick, disabled: loading }}
            onExport={{ onClick: handleExportClick, disabled: loading || causes.length === 0 }}
          />
        </ActionWrapper>
        {causes && causes.length === 0 ? (
          <EmptyImage>
            <NoDataImage text={t('msg_no_data')} />
          </EmptyImage>
        ) : (
          <BasicTable
            columns={columns()}
            columnsWidth={CAUSE_LIST_COLUMNS_WIDTH}
            data={memoizedCauses}
            onCopy={handleCopy}
            onEdit={onEdit}
            onDelete={handleClickDelete}
            disableActions={disableActions}
            showActionsTooltip={showActionsTooltip}
          />
        )}
      </Spin>
    </Wrapper>
  )
}

export default CauseList
