import { List, Map } from 'immutable'

import {
  ADD_DEPRECIATION_RESULT_ROW_KEY,
  CLEANUP_DEPRECIATION_RESULT,
  FETCH_DEPRECIATION_RESULT_FAILURE,
  FETCH_DEPRECIATION_RESULT_REQUEST,
  FETCH_DEPRECIATION_RESULT_SUCCESS,
  REMOVE_DEPRECIATION_RESULT_ROW_KEY,
} from './actions'

export const initialState = Map({
  list: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  rowKeys: [],
})

export default (state = initialState, action: any): any => {
  switch (action.type) {
    case FETCH_DEPRECIATION_RESULT_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_DEPRECIATION_RESULT_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_DEPRECIATION_RESULT_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], [])
    case ADD_DEPRECIATION_RESULT_ROW_KEY: {
      const rowKeys = state.getIn(['rowKeys']) as string[]
      const updatedKeys = [...rowKeys, action.payload]

      return state.setIn(['rowKeys'], updatedKeys)
    }
    case REMOVE_DEPRECIATION_RESULT_ROW_KEY: {
      const prevRowKeys = state.getIn(['rowKeys']) as string[]
      const deletedIndex = List(prevRowKeys).findIndex(item => item === action.payload)
      const updatedKeys = List(prevRowKeys).remove(deletedIndex).toArray()
      return state.setIn(['rowKeys'], updatedKeys)
    }
    case CLEANUP_DEPRECIATION_RESULT:
      return initialState
    default:
      return state
  }
}
