import { CsvCharset } from 'common/models'

import {
  CleanUpAssetAction,
  CreateAssetItemRequest,
  DeleteAssetItemRequest,
  DeleteAssetRequest,
  FetchAssetEventHistoryRequest,
  FetchAssetItemByValidAtRequest,
  FetchAssetItemByValidAtSuccess,
  FetchAssetItemRequest,
  FetchAssetListFailure,
  FetchAssetListRequest,
  FetchAssetListSuccess,
  ISetShowMemorandumValueErrorMessage,
  SubmitImpairmentEventRequest,
  SubmitModifiedAssetTypeEventRequest,
} from './action.model'
import {
  CLEANUP_ASSET,
  CLEANUP_ASSET_COLLECTION_ITEM,
  CLEANUP_ASSET_COLLECTION_LIST,
  CLEANUP_ASSET_ITEM_BY_VALID_AT,
  CLEAR_ASSET_EVENT_ERRORS,
  CREATE_ASSET_EVENT_MOVEMENT_REQUEST,
  CREATE_ASSET_EVENT_RETIREMENT_REQUEST,
  CREATE_ASSET_EVENT_SELL_REQUEST,
  CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
  CREATE_ASSET_EVENT_USAGE_REQUEST,
  CREATE_ASSET_ITEM_REQUEST,
  DELETE_ASSET_ITEM_REQUEST,
  DELETE_ASSET_REQUEST,
  DELETE_UNUSED_ASSET_REQUEST,
  EXPORT_ASSET_REQUEST,
  FETCH_ASSET_EVENT_HISTORY_REQUEST,
  FETCH_ASSET_ITEM_BY_VALID_AT_FAILURE,
  FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST,
  FETCH_ASSET_ITEM_BY_VALID_AT_SUCCESS,
  FETCH_ASSET_ITEM_REQUEST,
  FETCH_ASSET_LIST_FAILURE,
  FETCH_ASSET_LIST_REQUEST,
  FETCH_ASSET_LIST_SUCCESS,
  SET_IS_CREATED_SUCCESS_ASSET_EVENT,
  SET_SHOW_MEMORANDUM_VALUE_ERROR_MESSAGE,
  SUBMIT_IMPAIRMENT_REQUEST,
  SUBMIT_MODIFIED_ASSET_TYPE_REQUEST,
  UPDATE_ASSET_EVENT_MOVEMENT_REQUEST,
  UPDATE_ASSET_EVENT_RETIREMENT_REQUEST,
  UPDATE_ASSET_EVENT_SELL_REQUEST,
  UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
  UPDATE_ASSET_EVENT_USAGE_REQUEST,
  UPDATE_ASSET_REQUEST,
  UPLOAD_PHOTO,
} from './constant'
import { FixedAssetEventHistoryPayload, FixedAssetSearchPayload } from './model'

interface IFetchAssetList {
  (payload?: FixedAssetSearchPayload, pageNumber?: number, pageSize?: number): FetchAssetListRequest
}
export const fetchAssetList: IFetchAssetList = (
  payload = { filters: {}, sort: [] },
  pageNumber = 1,
  pageSize = 100
) => {
  return {
    type: FETCH_ASSET_LIST_REQUEST,
    payload,
    pageNumber,
    pageSize,
  }
}

export const handleFetchAssetListSuccess = (
  list: any[],
  totalPages: number,
  totalRecords: number
): FetchAssetListSuccess => {
  return {
    type: FETCH_ASSET_LIST_SUCCESS,
    payload: {
      list,
      totalPages,
      totalRecords,
    },
  }
}

export const handleFetchAssetListFailure = (): FetchAssetListFailure => ({
  type: FETCH_ASSET_LIST_FAILURE,
})

interface IFetchEventHistory {
  (payload: FixedAssetEventHistoryPayload, isGetDetailOneFixedAsset: boolean): FetchAssetEventHistoryRequest
}
export const fetchEventHistory: IFetchEventHistory = (payload, isGetDetailOneFixedAsset) => {
  return {
    type: FETCH_ASSET_EVENT_HISTORY_REQUEST,
    payload,
    isGetDetailOneFixedAsset,
  }
}

export const createAsset = (payload: any): CreateAssetItemRequest => {
  return {
    type: CREATE_ASSET_ITEM_REQUEST,
    payload,
  }
}

export const fetchAsset = (biid: string, isGetDetailOneFixedAsset: boolean): FetchAssetItemRequest => {
  return {
    type: FETCH_ASSET_ITEM_REQUEST,
    biid,
    isGetDetailOneFixedAsset,
  }
}

export const fetchAssetByValidAt = (
  biid: string,
  valid_at: string,
  updateValidAtValue = false,
  isGetDetailOneFixedAsset = false
): FetchAssetItemByValidAtRequest => {
  return {
    type: FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST,
    biid,
    valid_at,
    updateValidAtValue,
    isGetDetailOneFixedAsset,
  }
}

export const deleteAsset = (asset: any, pageSize: number): DeleteAssetRequest => {
  return {
    type: DELETE_ASSET_REQUEST,
    payload: {
      item: asset,
      pageSize,
    },
  }
}

export const deleteUnusedAsset = (biid: string) => {
  return {
    type: DELETE_UNUSED_ASSET_REQUEST,
    biid,
  }
}

export const deleteAssetItem = (payload: any, isGetDetailOneFixedAsset: boolean = false): DeleteAssetItemRequest => {
  return {
    type: DELETE_ASSET_ITEM_REQUEST,
    payload,
    isGetDetailOneFixedAsset,
  }
}

export const cleanupAsset = (): CleanUpAssetAction => {
  return {
    type: CLEANUP_ASSET,
  }
}

export const cleanupAssetCollectionList = () => ({ type: CLEANUP_ASSET_COLLECTION_LIST })

export const cleanupAssetItem = () => ({ type: CLEANUP_ASSET_COLLECTION_ITEM })

export const cleanupAssetItemByValidAt = () => ({ type: CLEANUP_ASSET_ITEM_BY_VALID_AT })

export const createAssetEventUsage = (biid: string, data: any, isGetDetailOneFixedAsset: boolean = false) => {
  return {
    type: CREATE_ASSET_EVENT_USAGE_REQUEST,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const createAssetEventRetirement = (biid: string, data: any, isGetDetailOneFixedAsset: boolean = false) => {
  return {
    type: CREATE_ASSET_EVENT_RETIREMENT_REQUEST,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const createAssetEventMovement = (biid: string, data: any, isGetDetailOneFixedAsset: boolean = false) => {
  return {
    type: CREATE_ASSET_EVENT_MOVEMENT_REQUEST,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const createAssetEventSwitchCategory = (biid: string, data: any, isGetDetailOneFixedAsset: boolean = false) => {
  return {
    type: CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const createAssetEventSell = (biid: string, data: any, isGetDetailOneFixedAsset: boolean = false) => {
  return {
    type: CREATE_ASSET_EVENT_SELL_REQUEST,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const updateAssetEventUsage = (
  biid: string,
  id: number,
  data: any,
  isGetDetailOneFixedAsset: boolean = false
) => {
  return {
    type: UPDATE_ASSET_EVENT_USAGE_REQUEST,
    id,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const updateAssetEventRetirement = (
  biid: string,
  id: number,
  data: any,
  isGetDetailOneFixedAsset: boolean = false
) => {
  return {
    type: UPDATE_ASSET_EVENT_RETIREMENT_REQUEST,
    id,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const updateAssetEventMovement = (
  biid: string,
  id: number,
  data: any,
  isGetDetailOneFixedAsset: boolean = false
) => {
  return {
    type: UPDATE_ASSET_EVENT_MOVEMENT_REQUEST,
    id,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const updateAssetEventSwitchCategory = (
  biid: string,
  id: number,
  data: any,
  isGetDetailOneFixedAsset: boolean = false
) => {
  return {
    type: UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
    id,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const updateAssetEventSell = (
  biid: string,
  id: number,
  data: any,
  isGetDetailOneFixedAsset: boolean = false
) => {
  return {
    type: UPDATE_ASSET_EVENT_SELL_REQUEST,
    id,
    biid,
    data,
    isGetDetailOneFixedAsset,
  }
}

export const updateAsset = (biid: string, data: any) => {
  return {
    type: UPDATE_ASSET_REQUEST,
    biid,
    data,
  }
}

export const exportAsset = (filters: any, sort: string[], charset: CsvCharset) => {
  return {
    type: EXPORT_ASSET_REQUEST,
    payload: { filters, sort },
    charset,
  }
}

export const setIsCreatedAssetEvent = (value: boolean) => {
  return {
    type: SET_IS_CREATED_SUCCESS_ASSET_EVENT,
    payload: value,
  }
}

export const clearAssetEventErrors = () => {
  return {
    type: CLEAR_ASSET_EVENT_ERRORS,
  }
}

export const handleFetchAssetItemByValidAtSuccess = (
  payload: FetchAssetItemByValidAtSuccess['payload']
): FetchAssetItemByValidAtSuccess => ({
  type: FETCH_ASSET_ITEM_BY_VALID_AT_SUCCESS,
  payload,
})

export const submitImpairment = (
  assetBiid: string,
  impairmentPayload: any,
  isGetDetailOneFixedAsset: boolean = false
): SubmitImpairmentEventRequest => ({
  type: SUBMIT_IMPAIRMENT_REQUEST,
  payload: {
    impairmentPayload,
    assetBiid,
    isGetDetailOneFixedAsset,
  },
  meta: { thunk: true },
})

export const submitModifiedAssetType = (
  assetBiid: string,
  modifiedPayload: any
): SubmitModifiedAssetTypeEventRequest => ({
  type: SUBMIT_MODIFIED_ASSET_TYPE_REQUEST,
  payload: {
    modifiedPayload,
    assetBiid,
  },
  meta: { thunk: true },
})

export const handleFetchAssetItemByValidAtFailure = () => ({ type: FETCH_ASSET_ITEM_BY_VALID_AT_FAILURE })

export const updateUploadPhotoStatus = (uploading: boolean) => ({
  type: UPLOAD_PHOTO,
  payload: uploading,
})

export const setShowMemorandumValueErrorMessage = ({
  ledgerId,
  isShowErrorMessage,
}: {
  ledgerId: number
  isShowErrorMessage: boolean
}): ISetShowMemorandumValueErrorMessage => ({
  type: SET_SHOW_MEMORANDUM_VALUE_ERROR_MESSAGE,
  payload: {
    ledgerId,
    isShowErrorMessage,
  },
})
