import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import DepartmentDetailTable from 'components/organisms/settings/DepartmentDetailTable'
import { MainTemplate } from 'components/templates'
import withRouterTrap from 'components/withRouterTrap'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { fetchMasterData } from 'store/masterData/actions'
import { selectMasterData } from 'store/masterData/selectors'
import {
  cleanupDepartment,
  clearDepartmentItemErrors,
  fetchByBiid,
  fetchDepartmentsOnCloudRequest,
  partialCleanupDepartment,
} from 'store/settings/department/actions'
import { selectDepartmentItem, selectDepartmentOnCloud } from 'store/settings/department/selectors'

const { t, useNsReady } = getNs(['components/organisms/settings/department-form'])

const DepartmentDetailPage = withRouterTrap(() => {
  useNsReady()
  const dispatch = useDispatch()
  const { departmentData, loading } = useSelector(selectDepartmentItem, isEqual)
  const masterData = useSelector(selectMasterData, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const { list: listDepartmentOnCloud } = useSelector(selectDepartmentOnCloud, isEqual)

  const { biid, id } = useParams<any>()

  const currentMonthlyClosingDate = currentMonthlyClosing?.from_term_month?.end_date

  useEffect(() => {
    dispatch(fetchByBiid(biid))
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchMasterData())
    dispatch(fetchDepartmentsOnCloudRequest())

    return function cleanup() {
      dispatch(partialCleanupDepartment())
    }
  }, []) // eslint-disable-line

  const code = useLocation<any>()?.state?.department?.code
  const areaCode = departmentData?.[0]?.code || code || ''
  const breadcrumb = useMemo(() => {
    const sectionName = `${t('department_code')}: ${areaCode}`
    return <BreadcrumbNavigation enableBackButton sectionName={sectionName} />
  }, [areaCode])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <DepartmentDetailTable
        values={departmentData}
        activeId={id && parseInt(id, 10)}
        departmentBiid={biid}
        loading={loading}
        prepressCategories={masterData.prepress_category}
        onClearErrors={clearDepartmentItemErrors}
        currentMonthlyClosingDate={currentMonthlyClosingDate}
        listDepartmentOnCloud={listDepartmentOnCloud}
      />
    </MainTemplate>
  )
})

const DepartmentDetailPageWrapper = () => {
  const dispatch = useDispatch()
  return <DepartmentDetailPage path="/master/departments" onLeave={() => dispatch(cleanupDepartment())} />
}

export default DepartmentDetailPageWrapper
