export const setCookieWithTime = (cookieName: string, cookieValue: string, time: number): void => {
  const currentDay = new Date()
  const currentTime = currentDay.getTime()
  const expireTime = currentTime + 1000 * 3600 * time
  currentDay.setTime(expireTime)
  const expires = `expires=${currentDay.toUTCString()}`
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`
}

export const removeCookieByName = (cookieName: string): void => {
  document.cookie = `${cookieName}=;PATH=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
