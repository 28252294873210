import { css } from '@emotion/css'

export const WrapperClass = css`
  && {
    padding: 30px 0;
  }

  .content {
    .text {
      margin-bottom: 30px;
    }
  }
`
