import styled from '@emotion/styled'

export const StyledWrapper = styled.div`
  span {
    cursor: pointer;

    &.disabled {
      color: #919191;
      pointer-events: none;
    }
  }

  .ant-breadcrumb-link {
    font-size: 18px;
  }
`
