import get from 'lodash/get'
import React from 'react'

import { detailReportSlice } from '../../corporate-income-tax-report-container/store'
import { getPeriod, getReportGrid } from '../common-report/helper'
import { SvgFormWrapper } from '../common-report/styles'
import reportBackground from './background.svg'
import { FormData } from './form-data'

export const ReportForm = () => {
  const cellWidth = 72
  const yCoordinate = 89
  const heightPerRow: number[] = [60, 60, 60, 60, 60, 62, 60, 60, 62, 60]

  const xCoordinates: number[] = [318, 390, 460, 532, 604, 676]

  const xCoordinatesPeriod: number[] = [426, 477, 500]
  const yCoordinatesPeriod: number[] = [42, 65]

  const reportData = detailReportSlice.useSlice(state => state?.data)

  const propsData = {
    reportData: getReportGrid(heightPerRow, cellWidth, xCoordinates, yCoordinate, reportData),
    reportPeriods: getPeriod(reportData, xCoordinatesPeriod, yCoordinatesPeriod),
    companyName: { x: 575, y: 40, w: 173, h: 50, value: get(reportData, 'office_name', '') },
    reportBackground,
  }

  return (
    <SvgFormWrapper>
      <FormData {...propsData} />
    </SvgFormWrapper>
  )
}
