export default {
  // molecules
  AutoCompleteSelect: require('common/locales/ja/components/molecules/auto-complete-select'),
  AutoCompleteText: require('common/locales/ja/components/molecules/auto-complete-text'),
  Pagination: require('common/locales/ja/components/molecules/pagination'),
  BasicForm: require('common/locales/ja/components/molecules/basic-form'),
  ConfirmModal: require('common/locales/ja/components/molecules/modals/confirm-modal'),
  ImportModal: require('common/locales/ja/components/molecules/modals/import-modal'),
  ExportModal: require('common/locales/ja/components/molecules/modals/export-modal'),
  FilterPanel: require('common/locales/ja/components/molecules/filter-panel'),
  ActionsList: require('common/locales/ja/components/molecules/actions-list'),
  UploadImageListField: require('common/locales/ja/components/molecules/upload-image-list-field'),
  UploadFileListField: require('common/locales/ja/components/molecules/upload-file-list-field'),
  DropdownToggle: require('common/locales/ja/components/molecules/dropdown-toggle'),
  RetrospectiveAssetModal: require('common/locales/ja/components/molecules/retrospective-asset-modal'),
  ButtonSendJournal: require('common/locales/ja/components/molecules/button-send-journal'),
  ConfirmSendJournalModal: require('common/locales/ja/components/molecules/modals/confirm-send-journal-modal'),
  ErrorModal: require('common/locales/ja/components/molecules/modals/error-modal'),

  // organisms
  AutoJournalSettings: require('common/locales/ja/components/organisms/accounting/auto-journal-setting-tab'),
  GeneralAutoJournalSettings: require('common/locales/ja/components/organisms/accounting/general-journal-setting-tab'),
  MonthlyClosingView: require('common/locales/ja/components/organisms/accounting/monthly-closing-view'),
  JournalList: require('common/locales/ja/components/organisms/accounting/journal-list'),
  MonthlyClosingAssetEventList: require('common/locales/ja/components/organisms/accounting/monthly-closing-asset-event-list'),
  UnderOverDepreciationReport: require('common/locales/ja/components/organisms/accounting/under-over-depreciation-report'),
  AssetEventsReport: require('common/locales/ja/components/organisms/accounting/asset-events-report'),
  LedgerSettings: require('common/locales/ja/components/organisms/depreciation/ledger-settings'),
  AssetComment: require('common/locales/ja/components/organisms/assets/asset-comment'),
  AssetList: require('common/locales/ja/components/organisms/assets/asset-list'),
  AssetForm: require('common/locales/ja/components/organisms/assets/asset-form'),
  AssetFilterFacet: require('common/locales/ja/components/organisms/assets/asset-filter-facet'),
  AssetDetail: require('./organisms/assets/AssetDetail'),
  AssetUsageStartedForm: require('common/locales/ja/components/organisms/assets/asset-usage-started-form'),
  AssetRetirementForm: require('common/locales/ja/components/organisms/assets/asset-retirement-form'),
  AssetMovementForm: require('common/locales/ja/components/organisms/assets/asset-movement-form'),
  SwitchCategoryForm: require('common/locales/ja/components/organisms/assets/switch-category-form'),
  AssetEventSellForm: require('common/locales/ja/components/organisms/assets/asset-event-sell-form'),
  AssetTypeModifyForm: require('common/locales/ja/components/organisms/assets/asset-type-modify-form'),
  AssetImpairment: require('common/locales/ja/components/organisms/assets/asset-impairment'),
  AssetImportForm: require('common/locales/ja/components/organisms/assets/asset-import-form'),
  AssetImportResultList: require('common/locales/ja/components/organisms/assets/asset-import-result-list'),
  AssetCategoryForm: require('common/locales/ja/components/organisms/settings/asset-category-form'),
  AssetCategoryList: require('common/locales/ja/components/organisms/settings/asset-category-list'),
  AssetCategoryFilter: require('common/locales/ja/components/organisms/settings/asset-category-filter'),
  AreaForm: require('common/locales/ja/components/organisms/settings/area-form'),
  AreaFilter: require('common/locales/ja/components/organisms/settings/area-filter'),
  AreaList: require('common/locales/ja/components/organisms/settings/area-list'),
  ExciseListFilter: require('common/locales/ja/components/organisms/settings/excise-list-filter'),
  ExciseList: require('common/locales/ja/components/organisms/settings/excise-list'),
  SubmitDestinationList: require('common/locales/ja/components/organisms/settings/submit-destination-list'),
  SubmitDestinationForm: require('common/locales/ja/components/organisms/settings/submit-destination-form'),
  UnitList: require('common/locales/ja/components/organisms/settings/unit-list'),
  UnitForm: require('common/locales/ja/components/organisms/settings/unit-form'),
  UserForm: require('common/locales/ja/components/organisms/settings/user-form'),
  TagList: require('common/locales/ja/components/organisms/settings/tag-list'),
  TagForm: require('common/locales/ja/components/organisms/settings/tag-form'),
  CauseList: require('common/locales/ja/components/organisms/settings/cause-list'),
  CauseForm: require('common/locales/ja/components/organisms/settings/cause-form'),
  DepartmentFilter: require('common/locales/ja/components/organisms/settings/department-filter'),
  DepartmentList: require('common/locales/ja/components/organisms/settings/department-list'),
  DepartmentForm: require('common/locales/ja/components/organisms/settings/department-form'),
  AccountingPeriodForm: require('common/locales/ja/components/organisms/settings/accounting-period-form'),
  MainMenu: require('common/locales/ja/components/organisms/main-menu'),
  MainHeader: require('common/locales/ja/components/organisms/main-header'),
  AgentLoginHeader: require('common/locales/ja/components/organisms/agent-login-header'),
  DepreciationResultList: require('common/locales/ja/components/organisms/depreciation/depreciation-result-list'),
  OfficeSelectTable: require('common/locales/ja/components/organisms/office/select-table'),
  AccountItemList: require('common/locales/ja/components/organisms/settings/account-item-list'),
  AccountItemForm: require('common/locales/ja/components/organisms/settings/account-item-form'),
  AssetCodeAutoGenerationFormModal: require('common/locales/ja/components/organisms/settings/asset-code-auto-generation-form-modal'),
  AssetCodeAutoGenerationView: require('common/locales/ja/components/organisms/settings/asset-code-auto-generation-view'),
  AssetCodeStartNumberPage: require('common/locales/ja/components/organisms/settings/asset-code-start-number-page'),
  LogListItemDetail: require('common/locales/ja/components/organisms/internal-control/log-list-item-detail'),
  OperationLogFilter: require('common/locales/ja/components/organisms/internal-control/operation-log-filter'),
  ExciseSettingView: require('common/locales/ja/components/organisms/settings/excise-setting-view'),
  ExciseSettingFormModal: require('common/locales/ja/components/organisms/settings/excise-setting-form-modal'),
  RoleList: require('common/locales/ja/components/organisms/settings/role-list'),
  SettingImportResultList: require('common/locales/ja/components/organisms/settings/setting-import-result-list'),
  AssetDividing: require('common/locales/ja/components/organisms/assets/asset-dividing'),
}
