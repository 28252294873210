/* eslint-disable no-case-declarations */
import { loadNs } from 'common/i18n-config'
import { ColumnState, TableColumn, TableSortType } from 'common/models'
import { get } from 'lodash'

const t = loadNs(['components/organisms/settings/asset-category-list'])

const buildAssetTypeForCorporateTax = (data: any): string => {
  const asset_type_for_corporate_tax = get(data, ['asset_type_for_corporate_tax', 'name_jp'])

  let result = ''
  if (asset_type_for_corporate_tax) result = `${asset_type_for_corporate_tax}\n`
  if (data.asset_structure_for_corporate_tax) result += `${data.asset_structure_for_corporate_tax}\n`
  if (data.asset_detail_for_corporate_tax) result += data.asset_detail_for_corporate_tax

  return result
}

export const getColumns = (): TableColumn[] => [
  { field: 'code', text: t('code') },
  { field: 'name', text: t('name') },
  { field: 'asset_type', text: t('type') },
  { field: 'excise_id', text: t('excise') },
  { field: 'depreciation_method', text: t('depreciation_method') },
  { field: 'service_life', text: t('service_life') },
  { field: 'asset_type_for_corporate_tax', text: t('asset_type_for_corporate_tax') },
  {
    field: 'asset_type_for_depreciable_property_tax',
    text: t('asset_type_for_depreciable_property_tax'),
  },
  { field: 'search_key', text: t('search_key') },
]
export const ASSET_CATEGORY_LIST_COLUMNS_WIDTH = [130, 'auto', 120, 120, 120, 120, 120, 120, 112, 120]

export const getDefaultColumnsState = (): ColumnState[] =>
  getColumns()?.map(item => {
    let columnSortType: TableSortType = ''
    if (item.field === 'code') {
      columnSortType = 'desc'
    }

    return {
      ...item,
      sortType: columnSortType,
    }
  })

export const convertDataToDisplay = (data: any, columns: any, memoizedAssetType: any): any => {
  if (!data) return []

  return data.map((dataItem: any) => {
    const displayItem: any = { id: dataItem.id }

    const rateText = (exciseRate: any) => {
      return ` ${exciseRate.reduced_flg !== 0 ? '(軽)' : ''}${exciseRate.rate * 100}%`
    }

    const simpleName = (simpleExcise: any) => {
      return ` ${simpleExcise.name ? simpleExcise.name.replace('第', '') : ''}`
    }

    columns.forEach(({ field }: any) => {
      switch (field) {
        case 'excise_id':
          const exciseRate = dataItem.excise?.excise_rate.rate ? rateText(dataItem.excise?.excise_rate) : ''
          const simpleExcise = dataItem.excise?.simple_excise ? simpleName(dataItem.excise?.simple_excise) : ''
          displayItem[field] = dataItem.excise
            ? `${dataItem.excise?.excise_label.abbreviation}${exciseRate}${simpleExcise}`
            : ' '
          break
        case 'asset_type_for_corporate_tax':
          displayItem[field] = buildAssetTypeForCorporateTax(dataItem)
          break
        case 'asset_structure_for_corporate_tax':
        case 'asset_detail_for_corporate_tax':
          break
        case 'asset_type_for_depreciable_property_tax':
          displayItem[field] = get(dataItem, [field, 'name_jp'])
          break
        case 'depreciation_method':
          displayItem[field] = get(dataItem, [field, 'name_jp'])
          break
        case 'asset_type':
          displayItem[field] = dataItem?.asset_type ? memoizedAssetType[dataItem?.asset_type] : ''
          break
        default:
          displayItem[field] = dataItem[field]
          break
      }
    })

    return displayItem
  })
}
