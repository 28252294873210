import { ClearTempData, CreateTempDataRequest, FetchTempDataRequest } from './action.model'

export const FETCH_TEMP_DATA_REQUEST = 'FETCH_TEMP_DATA_REQUEST'
export const FETCH_TEMP_DATA_SUCCESS = 'FETCH_TEMP_DATA_SUCCESS'
export const FETCH_TEMP_DATA_FAILURE = 'FETCH_TEMP_DATA_FAILURE'
export const CREATE_TEMP_DATA_REQUEST = 'CREATE_TEMP_DATA_REQUEST'
export const CREATE_TEMP_DATA_SUCCESS = 'CREATE_TEMP_DATA_SUCCESS'
export const CREATE_TEMP_DATA_FAILURE = 'CREATE_TEMP_DATA_FAILURE'
export const CLEAR_TEMP_DATA = 'CLEAR_TEMP_DATA'

export const fetchTempData = (dataType: string, extraKey?: string): FetchTempDataRequest => ({
  type: FETCH_TEMP_DATA_REQUEST,
  dataType,
  extraKey,
})

export const createTempData = (dataType: string, data: any, extraKey?: string): CreateTempDataRequest => ({
  type: CREATE_TEMP_DATA_REQUEST,
  dataType,
  data,
  extraKey,
})

export const clearTempData = (): ClearTempData => ({
  type: CLEAR_TEMP_DATA,
})
