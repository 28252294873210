import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePickerProps } from 'antd'
import classNames from 'classnames'
import { DATE_FORMAT } from 'common/constants'
import i18n from 'i18n'
import { Moment } from 'moment'
import React from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'

import { CommonErrorMsg } from '../../../antd'
import { Pending, StyledDatePicker } from './styles'

type DatePickerFormProps = DatePickerProps & {
  name: string
  required?: boolean
  disabledDate?: (date: Moment) => boolean
  onChange?: (e: any) => void
  format?: any
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year'
  allowClear?: boolean
  options?: {
    unsetTime?: boolean
    onActionEnableEditTime?: () => void
  }
}

const DatePicker = ({
  name,
  required,
  disabledDate,
  onChange,
  format,
  picker,
  allowClear,
  options,
  ...props
}: DatePickerFormProps) => {
  const { errors, control } = useFormContext()
  const error = get(errors, `${name}.message`)

  return (
    <Controller
      name={name}
      defaultValue={null}
      rules={{ required }}
      control={control}
      render={({ onChange: controllerOnChange, value }) => {
        return (
          <div className="form_control">
            {options?.unsetTime ? (
              <Pending>
                {i18n.t('common.form_values.unset')}
                <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={options?.onActionEnableEditTime} />
              </Pending>
            ) : (
              <StyledDatePicker
                {...props}
                name={name}
                picker={picker ?? 'date'}
                className={classNames({ error })}
                value={value}
                format={format ?? DATE_FORMAT}
                allowClear={allowClear}
                disabledDate={disabledDate}
                getPopupContainer={triggerNode => triggerNode.parentElement as HTMLElement}
                onChange={e => {
                  onChange && onChange(e)
                  controllerOnChange(e)
                }}
              />
            )}
            {error && <CommonErrorMsg>{error}</CommonErrorMsg>}
          </div>
        )
      }}
    />
  )
}

export default DatePicker
