/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateJournalSettingRequest,
  PresenterEditJournalSettingRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterJournalSettingResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class JournalSettings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get list journal setting
   *
   * @tags journal_settings
   * @name JournalSettingsList
   * @summary Get list journal setting
   * @request GET:/journal_settings
   */
  journalSettingsList = (params: RequestParams = {}) =>
    this.http.request<PresenterJournalSettingResponse[], PresenterError>({
      path: `/journal_settings`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create use to create JournalSetting
   *
   * @tags journal_settings
   * @name JournalSettingsCreate
   * @summary Create create JournalSetting
   * @request POST:/journal_settings
   */
  journalSettingsCreate = (body: PresenterCreateJournalSettingRequest, params: RequestParams = {}) =>
    this.http.request<PresenterJournalSettingResponse, PresenterError>({
      path: `/journal_settings`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Edit the JournalSetting
   *
   * @tags journal_settings
   * @name JournalSettingsUpdate
   * @summary Edit the JournalSetting
   * @request PUT:/journal_settings/{id}
   */
  journalSettingsUpdate = (id: number | string, body: PresenterEditJournalSettingRequest, params: RequestParams = {}) =>
    this.http.request<PresenterJournalSettingResponse, PresenterError>({
      path: `/journal_settings/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete journal setting by id
   *
   * @tags journal_settings
   * @name JournalSettingsDelete
   * @summary Delete the journal setting
   * @request DELETE:/journal_settings/{id}
   */
  journalSettingsDelete = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/journal_settings/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
