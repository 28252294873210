import { ICON_DEFAULT_COLOR } from 'common/constants'
import React from 'react'

type Props = {
  width?: number
  height?: number
  color?: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const CommentIcon: React.FC<Props> = ({
  width = 15,
  height = 15,
  color = ICON_DEFAULT_COLOR,
  className,
  style,
  onClick,
}) => (
  <svg
    className={className}
    style={{ color, width, height, ...style }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5Z"
    />
  </svg>
)

export default CommentIcon
