import { validateRequiredField } from 'components/organisms/assets/AssetForm/helper'
import { getGeneralValidationErrors } from 'components/organisms/assets/AssetForm/validations/asset-schema'
import { get, isEqual } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { deleteUnusedAsset, updateAsset } from 'store/asset/actions'
import { selectAssetItemErrors, selectAssetItemLoading } from 'store/asset/selectors'

export const useActionsEditFa = () => {
  const dispatch = useDispatch()
  const { biid } = useParams<any>()

  const [showRequiredError, setShowRequiredError] = useState<boolean>(false)
  const errors = useSelector(selectAssetItemErrors, isEqual)
  const loading = useSelector(selectAssetItemLoading, isEqual)
  const [formValues, setFormValues] = useState<any>(null)
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false)
  const [submitedAsset, setSubmitedAsset] = useState<any>(null)

  const handleSubmit = (values: any) => {
    setSubmitedAsset(values)
    if (validateRequiredField(values) || !!getGeneralValidationErrors().join(',')) {
      setShowRequiredError(true)
      return
    }
    if (get(values, 'usage_started_at')) {
      dispatch(updateAsset(biid, values))
    } else {
      setIsShowConfirm(true)
      setFormValues(values)
    }
  }

  const handleDelete = (biid: string) => {
    dispatch(deleteUnusedAsset(biid))
  }

  const handleCancelSubmit = () => {
    setIsShowConfirm(false)
    setFormValues(null)
  }

  const handleOkSubmit = () => {
    dispatch(updateAsset(biid, formValues))
    setIsShowConfirm(false)
  }

  return {
    handleSubmit,
    handleDelete,
    handleCancelSubmit,
    handleOkSubmit,
    showRequiredError,
    errors,
    loading,
    isShowConfirm,
    submitedAsset,
  }
}
