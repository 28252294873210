import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetFormDrawer, SettingImportResultList as AssetImportResultList } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoadingApp } from 'store/app/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-import-form'])

const AssetImportResultsPage: React.FC = () => {
  useNsReady()
  const dispatch = useDispatch()
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('bulk_change_import_title')} />

  const isLoadingApp = useSelector(selectLoadingApp)

  useEffect(() => {
    dispatch(fetchLedgerSettingsList())
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={breadcrumb} isLoadingApp={isLoadingApp}>
      <AssetImportResultList />
      <AssetFormDrawer />
    </MainTemplate>
  )
}

export default AssetImportResultsPage
