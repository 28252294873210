/* eslint-disable react/function-component-definition */
import { Col, Row, Tooltip, TooltipProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { RequiredLabel } from '../../required-label'
import { RowFieldClass } from './styles'

interface FormFieldProps {
  children: React.ReactNode
  className?: string
  required?: boolean
  title: string
  tooltip?: TooltipProps
}

const FormField: React.FC<FormFieldProps> = ({ children, required, className, title, tooltip }) => {
  const [showTooltip, setShowTooltip] = React.useState(false)
  return (
    <Row className={classNames(RowFieldClass, className, 'form-field')} align="middle" wrap={false}>
      <Col className="label" flex="200px">
        <Row className="label-wrapper" align="middle" wrap={false}>
          <Col className="label-text" flex="auto">
            <h4>{title}</h4>
          </Col>
          {required && (
            <Col className="requiredLabel" flex="none">
              <RequiredLabel />
            </Col>
          )}
        </Row>
      </Col>
      <Col className="field-content" flex="auto">
        {tooltip ? (
          <Tooltip visible={showTooltip} {...tooltip}>
            {React.Children.toArray(children).map((e: any) =>
              React.cloneElement(e, {
                onMouseEnter: () => setShowTooltip(true),
                onMouseLeave: () => setShowTooltip(false),
              })
            )}
          </Tooltip>
        ) : (
          children
        )}
      </Col>
    </Row>
  )
}

export default FormField
