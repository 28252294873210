import { Collapse as CollapseAnt } from 'antd'
import React, { ReactNode, useState } from 'react'

import Item from './Item'
import { Actions, LeftGroup, RightGroup, RightTitle, Wrapper } from './styles'

const { Panel } = CollapseAnt

type Props = {
  title?: string
  rightTitle?: string
  collapsable?: boolean
  isOpen?: boolean
  children?: ReactNode
}

interface CollapseProps extends React.FC<Props> {
  Item: typeof Item
  Actions: typeof Actions
  LeftGroup: typeof LeftGroup
  RightGroup: typeof RightGroup
}

export const UnborderCollapse: CollapseProps = ({
  children,
  title,
  rightTitle,
  collapsable = true,
  isOpen = true,
}: Props) => {
  const [collapse, setCollapse] = useState(isOpen)

  const handleChange = () => {
    collapsable && setCollapse(!collapse)
  }

  return (
    <Wrapper>
      <CollapseAnt activeKey={collapse ? ['1'] : []} expandIconPosition="right" onChange={handleChange}>
        <Panel header={title} key="1" collapsible={collapsable ? 'header' : 'disabled'} showArrow={collapsable}>
          <div className="content">{children}</div>
        </Panel>
      </CollapseAnt>
      <RightTitle onClick={handleChange} collapsable={collapsable}>
        {rightTitle}
      </RightTitle>
    </Wrapper>
  )
}

UnborderCollapse.Item = Item
UnborderCollapse.Actions = Actions
UnborderCollapse.LeftGroup = LeftGroup
UnborderCollapse.RightGroup = RightGroup
