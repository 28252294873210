import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: relative;
  height: 30px;
  cursor: pointer;

  .content {
    position: absolute;
    top: 15px;
    left: 50%;
    display: flex;
    font-size: 18px;
    color: #3b7de9;
    transform: translate(-50%, -50%);
  }

  .text {
    font-size: 13px;
    font-weight: 500;
    margin-left: 10px;
  }

  &:hover {
    .content {
      color: #40a9ff;
    }
  }
`
