import { loadNs } from 'common/i18n-config'
import { DataTypes } from 'common/models/depreciation'

const t = loadNs(['components/organisms/accounting/under-over-depreciation-report'], 'depreciationReportTable')
const getFisedAssetAmount = (fixedAssetAmounts: any) => {
  const fixedAssets: any[] = []
  let fixedAssetitem
  fixedAssetAmounts.forEach((fixedAsset: any) => {
    const name = `${fixedAsset?.code}${fixedAsset?.branch_code ? '-' : ''}`
    fixedAssetitem = {
      ...fixedAsset?.asset_depreciation_amount,
      name: `${fixedAsset?.code ? name : ''}${fixedAsset?.branch_code || ''} ${fixedAsset?.name}`,
      key: fixedAsset?.biid,
      branch_code: fixedAsset?.branch_code,
      biid: fixedAsset?.biid,
      is_deleted: fixedAsset?.is_deleted,
      type: DataTypes.ASSET,
    }
    fixedAssets.push(fixedAssetitem)
  })
  return fixedAssets
}

const getAssetCategory = (categories: any) => {
  const categoryAmounts: any[] = []
  let categoryItem
  categories.forEach((category: any) => {
    categoryItem = {
      ...category?.category_depreciation_amount,
      name: category?.asset_category_name,
      key: category?.asset_category_code,
      type: DataTypes.ASSET_CATEGORY,
    }
    if (
      category?.fixed_asset_amounts &&
      Array.isArray(category.fixed_asset_amounts) &&
      category.fixed_asset_amounts.length > 0
    ) {
      categoryItem.children = getFisedAssetAmount(category.fixed_asset_amounts)
    }
    categoryAmounts.push(categoryItem)
  })
  return categoryAmounts
}

export const handleDataTable = (rawData: any) => {
  const dataTable: any[] = []
  if (rawData && Array.isArray(rawData) && rawData.length > 0) {
    let totalAmount
    rawData.forEach((data: any, index: number) => {
      totalAmount = {
        ...data?.total_depreciation_amount,
        name: t('total_amount_title'),
        key: `total-amount-${index}`,
        type: DataTypes.ASSET_TYPE,
      }
      if (
        data?.asset_category_amounts &&
        Array.isArray(data.asset_category_amounts) &&
        data.asset_category_amounts.length > 0
      ) {
        totalAmount.children = getAssetCategory(data.asset_category_amounts)
      }
      dataTable.push(totalAmount)
    })
  }
  return dataTable
}
