import { AutoCompleteSelect, Button, TextField } from 'aa_common/front-end/antd'
import { RedirectToMasterPageLink } from 'aa_common/front-end/components'
import { EventLinkPhoto } from 'aa_common/front-end/components/assets-form/common-form/event-link-photo'
import Form from 'aa_common/front-end/components/form'
import { RangePickerProps } from 'antd/lib/date-picker'
import { CAUSE_TYPES, DATE_FORMAT, ENDPOINTS, EXCISE_TAX_METHOD, IMPAIRMENT_LOSS_APPLY_METHOD } from 'common/constants'
import i18n from 'i18n'
import get from 'lodash/get'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import { StoreContext } from './asset-dividing-form'
import { DividingDetailTable } from './dividing-detail-table'
import { validateDividingDate, validateRangeDisableDividingTime } from './helper'
import { useGetBookDividingInfo } from './hooks'
import { LedgerInformationField } from './ledger-informations-field'
import { FIELDS } from './schema'
import { StyledFormContentWrapper } from './styles'

const WIDTH_DEFAULT = 764
const WIDTH_COLLAPSE = 1056
const { END_OF_LAST_MONTH } = IMPAIRMENT_LOSS_APPLY_METHOD

export const FormContent = (props: FormContentProps) => {
  const { control, errors, formState, getValues } = useFormContext()
  const [collapse, setCollapse] = useState(false)
  const { causes, onReloadCauses, setShowForm, ledgerSettings } = props
  const dividingCauses = useMemo(() => causes?.filter(cause => get(cause, 'type') === CAUSE_TYPES.DIVIDING), [causes])
  const asset = StoreContext.useSelector(state => state.dividingData?.asset)
  const terms = StoreContext.useSelector(state => state.dividingData?.terms)
  const exciseTaxMethod = StoreContext.useSelector(state => state.dividingData?.exciseTaxMethod)
  const permissions = StoreContext.useSelector(state => state.dividingData?.permissions)
  const dividingAt = StoreContext.useSelector(state => state.dividingData?.dividingAt)

  const setDividingAt = StoreContext.useSelector(state => state.dividingData?.setDividingAt)

  const watchValues = useWatch({
    name: [FIELDS.divided_at],
    control,
    defaultValue: getValues([FIELDS.divided_at]),
  })
  const selectedDate = watchValues[FIELDS.divided_at]

  const [dataBookValue, loading] = useGetBookDividingInfo(dividingAt, END_OF_LAST_MONTH, ledgerSettings)
  const dividingBookingData = useMemo(() => {
    const data = {
      bookDate: dataBookValue.map(item => ({
        ledger_setting_id: item.ledger_setting_id,
        name: item?.ledger_name,
        content: item.date,
      })),
      bookValue: dataBookValue.map(item => ({
        name: item.ledger_name,
        ledger_setting_id: item.ledger_setting_id,
        value: item.book_value,
      })),
    }

    return collapse
      ? data
      : {
          bookDate: data.bookDate[0] ? [data.bookDate[0]] : [],
          bookValue: data.bookValue[0] ? [data.bookValue[0]] : [],
        }
  }, [collapse, dataBookValue])
  const collapseAction = useCallback(() => {
    setCollapse(!collapse)
  }, [collapse])
  const acquired_at = asset?.acquired_at
  const transitionAt = get(asset, 'transition_at')
  const disabledDate: RangePickerProps['disabledDate'] = useCallback(
    current => !!(terms && acquired_at && validateRangeDisableDividingTime(current, terms, acquired_at)),
    [acquired_at, terms]
  )
  // useDebouncedCallback
  const onChangeDividingField = useCallback(
    (e: any) => {
      acquired_at && validateDividingDate(acquired_at, e, setDividingAt, terms, transitionAt)
    },
    [acquired_at, terms, setDividingAt, transitionAt]
  )

  const onDebounceChangeDividingField = useDebouncedCallback(e => onChangeDividingField(e), 150)
  const originalAcquisitionCost = get(
    asset,
    exciseTaxMethod === EXCISE_TAX_METHOD.INCLUDED_TAX ? 'acquisition_cost' : 'acquisition_cost_excluded_excise'
  )

  return (
    <StyledFormContentWrapper>
      <div
        data-testid="dividing_at"
        className="dividing-form-content"
        style={{ width: collapse && dataBookValue.length > 2 ? WIDTH_COLLAPSE : WIDTH_DEFAULT }}
      >
        <Form.Group className="form-wrapper" borderLess>
          <Form.FormField
            title={i18n.t('components.AssetDividing.dividing_at')}
            required
            tooltip={{
              title: i18n.t('components.AssetDividing.tooltipDividingAt'),
              color: '#000',
              overlayStyle: { maxWidth: 293, fontSize: 13 },
              overlayInnerStyle: { padding: 10, borderRadius: 5 },
            }}
          >
            <div style={{ position: 'relative' }} className={FIELDS.divided_at}>
              <Form.DatePicker
                className={FIELDS.divided_at}
                name={FIELDS.divided_at}
                data-testid={FIELDS.divided_at}
                format={() => {
                  return selectedDate ? selectedDate?.format(DATE_FORMAT) : ''
                }}
                picker="year"
                required
                disabledDate={disabledDate}
                onSelect={onDebounceChangeDividingField}
                // AA-8656 in comment must use onSelect
                style={{ textAlign: 'center' }}
              />
              {selectedDate && <div className="dividing_date">{dividingAt && dividingAt?.format(DATE_FORMAT)}</div>}
            </div>
          </Form.FormField>
          <Form.FormField title={i18n.t('components.AssetDividing.reason')} required>
            <AutoCompleteSelect
              name={FIELDS.event_cause_id}
              items={dividingCauses}
              valueField="id"
              textField="content"
              placeholder={i18n.t('common.placeholders.default_select_placeholder')}
              control={control}
              error={get(errors, 'event_cause_id.message')}
              required
              customDropdownTemplate={
                <RedirectToMasterPageLink
                  urlName={ENDPOINTS.CAUSES}
                  pageName={i18n.t('common.master.causes')}
                  onHandleReload={onReloadCauses}
                  permissions={permissions}
                />
              }
            />
          </Form.FormField>
          <Form.FormField title={i18n.t('components.AssetDividing.acquisition_cost')}>
            <TextField defaultValue={originalAcquisitionCost} bordered={false} underline allowPrefix />
          </Form.FormField>
          <LedgerInformationField
            collapse={collapse}
            dividingBookingData={dividingBookingData}
            loading={loading}
            collapseAction={collapseAction}
          />
          {asset && <DividingDetailTable />}
          <EventLinkPhoto
            photo={{ name: FIELDS.event_photos, title: i18n.t('components.AssetDividing.photos') }}
            attachment={{ name: FIELDS.event_attachments, title: i18n.t('components.AssetDividing.attachments') }}
            links={{
              name: FIELDS.event_urls,
              placeholder: i18n.t('components.AssetDividing.placeholder.links'),
              title: i18n.t('components.AssetDividing.links'),
            }}
          />
        </Form.Group>
        <Form.Footer>
          <div className="Form.footer-form">
            <Button data-testid="cancel-btn" className="btn-cancel" color="grey" onClick={() => setShowForm(false)}>
              {i18n.t('common.actions.cancel')}
            </Button>
            <Button
              data-testid="register-btn"
              className="btn-submit"
              type="submit"
              disabled={!formState.isValid || !formState.isDirty}
            >
              {i18n.t('common.actions.register')}
            </Button>
          </div>
        </Form.Footer>
      </div>
    </StyledFormContentWrapper>
  )
}

interface FormContentProps {
  causes: any[]
  onReloadCauses?: () => void
  setShowForm: (isShow: boolean) => void
  ledgerSettings: any
  // eslint-disable-next-line react/no-unused-prop-types
  isShow?: boolean
}
