import styled from '@emotion/styled'
import { Button } from 'aa_common/front-end/antd'
import classNames from 'classnames'
import i18n from 'i18n'
import React, { useCallback, useEffect, useState } from 'react'
import { Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions'

import { CommentTextBoxProps } from './model'
import { WrapperClass } from './styles'

const CommentTextbox = ({
  className,
  value,
  type = 'regular',
  permissions,
  onChange,
  onCommentSubmit,
  onCancel,
}: CommentTextBoxProps) => {
  const [textValue, setTextValue] = useState<string>('')

  const handleOnChange = useCallback<OnChangeHandlerFunc>(
    event => {
      const textValue = event.target.value || ''
      setTextValue(textValue)
      onChange && onChange(textValue)
    },
    [onChange]
  )

  useEffect(() => {
    setTextValue(value || '')
  }, [value])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    const isCommandWithEnterHotKey = event.metaKey && event.keyCode === 13
    if (isCommandWithEnterHotKey) {
      handleCommentSubmit()
    }
  }

  const handleCommentSubmit = useCallback(() => {
    if (textValue.trim() !== '') {
      onCommentSubmit && onCommentSubmit(textValue.trim())
      setTimeout(() => {
        type === 'regular' && setTextValue('')
      }, 200)
    }
  }, [textValue, onCommentSubmit, type])

  const isBlankText = textValue.trim() === ''
  return (
    <CommentTextboxStyle className={classNames(WrapperClass, className)}>
      <div className={`${permissions.isCreate ? 'comment-box-wrapper' : 'comment-box-wrapper disabled'}`}>
        <MentionsInput
          value={textValue}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          className="mentions"
          placeholder={i18n.t('components.AssetComment.comment_input_placeholder')}
          allowSuggestionsAboveCursor
          disabled={!permissions.isCreate}
        >
          <Mention
            markup="@[__display__](data:__id__)"
            trigger="@"
            data={[]} // parse empty array in order to not show mentions
            displayTransform={(id, display) => {
              return ` @${display} `
            }}
            appendSpaceOnAdd
            className="mentions__mention"
          />
        </MentionsInput>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {type === 'regular' && (
          <Button
            style={{ marginTop: 5 }}
            color="grey"
            disabled={isBlankText || !permissions.isEdit}
            showTooltipMessage={!permissions.isEdit ? i18n.t('common.actions.no_permission') : undefined}
            onClick={handleCommentSubmit}
          >
            {i18n.t('components.AssetComment.comment')}
          </Button>
        )}
      </div>
      {type === 'edit' && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className="cmt-textbox-cancel"
            style={{ marginTop: 5, marginRight: 5 }}
            color="grey"
            onClick={onCancel}
          >
            {i18n.t('common.actions.cancel')}
          </Button>
          <Button
            className="cmt-textbox-submit"
            style={{ marginTop: 5 }}
            disabled={isBlankText || !permissions.isEdit}
            showTooltipMessage={!permissions.isEdit ? i18n.t('common.actions.no_permission') : undefined}
            onClick={handleCommentSubmit}
          >
            {i18n.t('common.actions.save')}
          </Button>
        </div>
      )}
    </CommentTextboxStyle>
  )
}

export default CommentTextbox

const CommentTextboxStyle = styled.div`
  .mentions {
    width: calc(100% - 30px);
  }

  .mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
  }
  .mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
    overflow: hidden;
  }
  .mentions--singleLine .mentions__input {
    padding: 1px;
    border: 2px inset;
  }

  .mentions--multiLine .mentions__control {
    font-family: inherit;
    font-size: 13px;
  }
  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 6px 8px;
    padding-top: 7px;
    min-height: 30px;
    overflow: auto !important;
    max-height: 120px;
  }
  .mentions--multiLine .mentions__input {
    border: none;
    padding: 6px 8px;
    padding-top: 7px;
    outline: 0;
    overflow: auto !important;
    max-height: 120px;
  }

  .mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 13px;
    border-radius: 4px;
    max-height: 240px;
    overflow-y: auto;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .mentions__suggestions__item--focused {
    background-color: #e7f1fd;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    background: #eaf2fd;
    color: #3b7de9;
    border-radius: 4px;
    pointer-events: none;
  }
`
