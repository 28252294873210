import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const CommentIconClass = css`
  color: #7c8291 !important;
  margin-right: 5px;
  &.empty {
    color: #d4d8dd !important;
  }
  &.selected {
    color: #3b7de9 !important;
  }
`

export const ListCommentClass = css`
  max-height: 250px;
  overflow-y: auto;
`

export const PopupOverlayClass = css`
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-title {
    padding: 10px;
    border-bottom: 1px solid #d4d8dd;
    .comment-popup-title {
      font-weight: 500;
    }
  }
  .ant-popover-inner {
    border-radius: 4px;
    border: 1px solid #d4d8dd;
    width: 600px;
    &-content {
      padding: 0;
    }
  }

  .comment-empty-box {
    border: none;
  }
`

export const CommentBoxClass = css`
  && {
    border: none;
    border-top: 1px solid #d4d8dd;
  }
`

export const IconCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
