import { Map } from 'immutable'
import { get } from 'lodash'

import { AssetActionTypes } from './action.model'
import {
  CLEAR_TEMP_DATA,
  CREATE_TEMP_DATA_REQUEST,
  CREATE_TEMP_DATA_SUCCESS,
  FETCH_TEMP_DATA_FAILURE,
  FETCH_TEMP_DATA_REQUEST,
  FETCH_TEMP_DATA_SUCCESS,
} from './actions'

const initialState = Map({
  data: null,
  loading: false,
  errors: null,
  savedAt: null,
})

export default (state = initialState, action: AssetActionTypes) => {
  switch (action.type) {
    case FETCH_TEMP_DATA_REQUEST:
      return state.setIn(['loading'], true)
    case FETCH_TEMP_DATA_SUCCESS:
      return state
        .setIn(['loading'], false)
        .setIn(['data'], get(action.payload, 'data'))
        .setIn(['savedAt'], get(action.payload, 'last_updated_at'))
    case FETCH_TEMP_DATA_FAILURE:
      return state.setIn(['loading'], false)
    case CREATE_TEMP_DATA_REQUEST:
      return state.setIn(['loading'], true)
    case CREATE_TEMP_DATA_SUCCESS:
      return state.setIn(['loading'], false).setIn(['savedAt'], action.savedAt)
    case CLEAR_TEMP_DATA:
      return initialState
    default:
      return state
  }
}
