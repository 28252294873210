import { AutoCompleteSelect, Button } from 'aa_common/front-end/antd'
import { BottomAction, LinkLabel } from 'aa_common/front-end/components'
import { FEATURES_ID } from 'common/constants'
import { formatMonth } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES, TermMonth } from 'common/models'
import { RenderFormProps } from 'components/molecules/Form'
import useUserPermission from 'lib/hooks/useUserPermission'
import { find, first, isEqual } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectorSelectedAssetCategoryJournalSettingTerms } from 'store/accounting/journal/selectors'
import { selectFullTermsList } from 'store/settings/accountingPeriod/selectors'

import { SubmitAreaWrapper } from './styles'

interface SubmitAreaProps {
  formMode: FORM_MODES
  isDetailMode: boolean
  item: any
  nextSettings: any
  onCancelClick: () => void
  onCopyClick: () => void
  setRenderTitle: (value: string) => void
}

const t = loadNs(['common', 'components/organisms/accounting/auto-journal-setting-tab'])
const showNoPermissionMessage = (permission: boolean) => (!permission ? t('actions.no_permission') : undefined)

const SubmitArea = ({
  item,
  nextSettings,
  formMode,
  isDetailMode,
  setRenderTitle,
  onCancelClick,
  onCopyClick,
}: SubmitAreaProps) => {
  const { data: accountPeriods, loading: isAccountPeriodLoading } = useSelector(selectFullTermsList, isEqual)
  const { data: journalItems } = useSelector(selectorSelectedAssetCategoryJournalSettingTerms, isEqual)
  const {
    permissions: { isCRU },
  } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)

  const isAddMode = formMode === FORM_MODES.NEW
  const isEditMode = formMode === FORM_MODES.EDIT && !isDetailMode
  const isAllowEdit = (isAddMode || isEditMode) && isCRU

  const { setValue, control, loading, watch } = useFormContext() as RenderFormProps

  const isFirstTime = journalItems.length === 0

  const latestSettings: any = useMemo(() => {
    return first(journalItems)
  }, [journalItems])

  const termValues = watch(['term_year_period', 'month_id'])

  const memoizedTermYearPeriods = useMemo(() => {
    let compareDate: Moment = moment()
    if ((isAddMode && isFirstTime) || !isAddMode) {
      const settingStartDate = moment(item?.from_term_month?.start_date || nextSettings.start_date)
      compareDate = settingStartDate
    } else if (isAddMode && !isFirstTime) {
      const settingEndDate = moment(item?.from_term_month?.end_date || latestSettings?.from_term_month?.end_date)
      compareDate = settingEndDate
    }

    return (accountPeriods || []).map(termYear => {
      const startDate = moment(termYear.start_date)
      const endDate = moment(termYear.end_date)

      return {
        value: termYear.id,
        disabled: !compareDate.isBetween(startDate, endDate, 'date') && compareDate.isAfter(startDate, 'date'),
        label: t('format.term_year', { year: termYear.year }),
      }
    })
  }, [accountPeriods, item, nextSettings, isAddMode, latestSettings, isFirstTime])

  const memoizedTermMonths = useMemo(() => {
    let compareDate: moment.Moment = moment()
    if ((isAddMode && isFirstTime) || !isAddMode) {
      const settingStartDate = moment(item?.from_term_month?.start_date || nextSettings.start_date)
      compareDate = settingStartDate
    } else if (isAddMode && !isFirstTime) {
      const settingEndDate = moment(item?.from_term_month?.end_date || latestSettings?.from_term_month?.end_date)
      compareDate = settingEndDate
    }

    const termYear = find(accountPeriods, item => item.id === termValues.term_year_period)
    return (termYear?.term_months || []).map((termMonth: TermMonth) => {
      const startDate = moment(termMonth.start_date)
      const endDate = moment(termMonth.end_date)

      return {
        value: termMonth.id,
        disabled: !compareDate.isBetween(startDate, endDate, 'date') && compareDate.isAfter(startDate, 'date'),
        label: t('format.term_month', { month: formatMonth(termMonth.month) }),
      }
    })
  }, [accountPeriods, termValues.term_year_period, item, nextSettings, latestSettings, isAddMode, isFirstTime])

  useEffect(() => {
    const { term_year_period, month_id } = termValues
    const fiscalYear = find(memoizedTermYearPeriods, item => item.value === term_year_period)?.label
    const fiscalMonth = find(memoizedTermMonths, item => item.value === month_id)?.label

    if (fiscalMonth && fiscalYear) {
      setRenderTitle(`${fiscalYear}${fiscalMonth}`)
    }
  }, [termValues, memoizedTermMonths, memoizedTermYearPeriods]) // eslint-disable-line

  const handleOnTermYearChanged = useCallback(
    termYearPeriod => {
      const settingStartDate = moment(item?.from_term_month?.start_date || nextSettings.start_date)
      const settingEndDate = moment(item?.from_term_month?.end_date || nextSettings.end_date)
      const compareDate = isAddMode ? settingEndDate : settingStartDate

      const termYear = find(accountPeriods, item => item.id === termYearPeriod)

      const firstTermMonth = find(termYear?.term_months, item => {
        const startDate = moment(item.start_date)
        const endDate = moment(item.end_date)

        return compareDate.isBetween(startDate, endDate, 'date', '[]')
      })

      // Validate & choose the correct minimum first term month option when user choose the first enabled term year option
      // other enabled term years options, it will get the first term month of the term year.
      setValue(
        'month_id',
        (isAddMode || isEditMode) && firstTermMonth ? firstTermMonth.id : first(termYear?.term_months)?.id
      )
    },
    [item, accountPeriods, isAddMode, isEditMode, nextSettings]
  ) // eslint-disable-line

  return (
    <BottomAction id="asset-category-auto-journal-submit-area" containerId="auto-journal-setting-modal-form-wrapper">
      <SubmitAreaWrapper>
        <div className="action-button">
          <Button type="button" color="grey" onClick={onCancelClick}>
            {t('actions.cancel')}
          </Button>
        </div>
        <LinkLabel
          style={{ marginRight: 15 }}
          showTooltipMessage={showNoPermissionMessage(isCRU)}
          disabled={!isCRU}
          onClick={onCopyClick}
        >
          {t('copy_auto_journal_settings')}
        </LinkLabel>
        <span>{t(`${isAddMode ? 'add' : 'edit'}_valid_from_label`)}</span>
        <div className="select-wrapper">
          <AutoCompleteSelect
            name="term_year_period"
            items={memoizedTermYearPeriods}
            textField="label"
            valueField="value"
            control={control}
            disabled={!isAllowEdit || loading || isAccountPeriodLoading}
            onChange={handleOnTermYearChanged}
            allowClear={false}
            style={{ marginRight: 5, width: 120 }}
          />
          <AutoCompleteSelect
            name="month_id"
            items={memoizedTermMonths}
            textField="label"
            valueField="value"
            control={control}
            disabled={!isAllowEdit || loading || isAccountPeriodLoading}
            allowClear={false}
            style={{ marginRight: 5, width: 120 }}
          />
        </div>
      </SubmitAreaWrapper>
      <Button
        type="submit"
        size="custom"
        showTooltipMessage={showNoPermissionMessage(isCRU)}
        disabled={!isCRU}
        tooltipSettings={{
          placement: 'topRight',
        }}
        style={{ padding: '4px 13px' }}
      >
        {t('actions.save')}
      </Button>
    </BottomAction>
  )
}

export default SubmitArea
