import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AssetListContainer } from 'containers/assets/asset-list-container/asset-list-container'

import { AssetsListPageOld } from './asset-list-page'

const AssetsListPage = () => {
  const { isEnable: isRefactorPagePhase1_4 } = useCheckFlag(FeatureFlagNames.RefactorPagePhase1_4)
  return <>{isRefactorPagePhase1_4 ? <AssetListContainer /> : <AssetsListPageOld />}</>
}

export default AssetsListPage
