import { Checkbox, Spin } from 'aa_common/front-end/antd'
import { BasicTable } from 'aa_common/front-end/components'
import { checkIsToday } from 'aa_common/front-end/helpers'
import { DATE_FORMAT_RFC, FEATURES_ID } from 'common/constants'
import { calcInitColumnsState, convertTableSortPayload } from 'common/helpers/sort-helper'
import { getNs } from 'common/i18n-config'
import { ColumnState, IColumnSortEvent } from 'common/models'
import { NoDataImage } from 'components/atoms'
import Datepicker from 'components/atoms/v2/Datepicker'
import { ListPageActions, Pagination } from 'components/molecules'
import Form, { RenderFormProps } from 'components/molecules/Form'
import { Filters, FilterSortPagingEvents } from 'lib/hooks/useFiltersSortsPaging'
import useUserPermission from 'lib/hooks/useUserPermission'
import { isEqual } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { convertDataToDisplay, DEPARTMENT_LIST_COLUMNS_WIDTH, getColumns } from './helper'
import { ActionWrapper, EmptyImage, PaginationWrapper, SearchFilterDatePickerClass, Wrapper } from './styles'

const { t, useNsReady } = getNs([
  'common',
  'components/organisms/settings/department-list',
  'components/organisms/settings/area-list',
])
export const getInitFilters = (seedValues?: Filters) => {
  const { valid_at, is_show_future, is_show_past } = seedValues || {}
  const date = moment(valid_at)

  return {
    valid_at: (date.isValid() ? date : moment()).format(DATE_FORMAT_RFC),
    is_show_future: !!is_show_future,
    is_show_past: !!is_show_past,
  }
}

type BaseProps = {
  departments: any[]
  loading?: boolean
  totalPages: number
  totalRecords: number
  currentPage: number
  initFormValues: Filters
  onAddNew: () => void
  onImportClick: () => void
  onExportClick: () => void
  onCopy?: (item: any) => void
} & FilterSortPagingEvents
type Props = {
  initColumnsState: ColumnState[]
} & BaseProps

const DepartmentNewList = ({
  departments,
  loading = false,
  totalPages,
  totalRecords,
  currentPage,
  initFormValues,
  initColumnsState,
  onExportClick,
  onImportClick,
  onAddNew,
  onCopy,
  onFiltersChanged,
  onSortsChanged,
  onPageChanged,
}: Props) => {
  useNsReady()
  const [departmentColumnsState, setColumnsState] = useState<ColumnState[] | undefined>(initColumnsState)
  const { loading: permissionLoading, permissions: departmentPermission } = useUserPermission(
    FEATURES_ID.MASTER_DEPARTMENT
  )

  const { control, setValue } = useFormContext()
  const formValues = useWatch({})

  useEffect(() => {
    onFiltersChanged(formValues)
  }, [onFiltersChanged, formValues])

  const valid_at: moment.Moment = formValues?.valid_at
  const validAt = moment(valid_at).format(DATE_FORMAT_RFC)
  const memoizedDepartments = useMemo(
    () => convertDataToDisplay(departments, getColumns(), validAt),
    [departments, validAt]
  )
  const isToday = useMemo(() => checkIsToday(valid_at), [valid_at])

  useEffect(() => {
    if (valid_at && !checkIsToday(valid_at)) {
      setValue('is_show_future', false)
      setValue('is_show_past', false)
    }
  }, [setValue, valid_at])

  const handleOnTableSort = useCallback<IColumnSortEvent>(
    ({ tableColumns }) => {
      setColumnsState(tableColumns)
      onSortsChanged(convertTableSortPayload(tableColumns))
    },
    [onSortsChanged]
  )

  const disableActions = useMemo(
    () => ({
      copy: () => !departmentPermission.isCreate,
    }),
    [departmentPermission]
  )

  const showActionsTooltip = useMemo(
    () => ({
      copy: () => (!departmentPermission.isCreate ? t('actions.no_permission') : undefined),
    }),
    [departmentPermission]
  )

  return (
    <Wrapper>
      <ListPageActions
        featureId={FEATURES_ID.MASTER_DEPARTMENT}
        add={{ onClick: onAddNew, disabled: loading }}
        onImport={{ onClick: onImportClick, disabled: loading }}
        onExport={{ onClick: onExportClick, disabled: departments.length === 0 || loading }}
      />
      <ActionWrapper>
        <div className="left">
          <Datepicker
            name="valid_at"
            control={control}
            defaultValue={moment(initFormValues.valid_at)}
            disabled={loading}
            className={SearchFilterDatePickerClass}
            allowClear={false}
          />
          <span style={{ marginLeft: 5 }}>{t('list_display_status')}</span>
          <Checkbox
            className="display-all-checkbox"
            name="is_show_future"
            control={control}
            disabled={loading || !isToday}
            defaultChecked={!!initFormValues.is_show_future}
          >
            {t('checkbox_labels.area_future')}
          </Checkbox>
          <Checkbox
            className="display-all-checkbox"
            name="is_show_past"
            control={control}
            disabled={loading || !isToday}
            defaultChecked={!!initFormValues.is_show_past}
          >
            {t('checkbox_labels.area_past')}
          </Checkbox>
        </div>
        <PaginationWrapper>
          <span>{`${totalRecords}${t('pagination_status')}`}</span>
          <Pagination
            value={currentPage}
            disabled={loading}
            onPageChanged={onPageChanged}
            name="departments"
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </PaginationWrapper>
      </ActionWrapper>
      {departments?.length ? (
        <BasicTable
          loading={permissionLoading}
          columns={getColumns()}
          columnsState={departmentColumnsState}
          columnsWidth={DEPARTMENT_LIST_COLUMNS_WIDTH}
          data={memoizedDepartments}
          onHeaderCellSortClick={handleOnTableSort}
          disableActions={disableActions}
          showActionsTooltip={showActionsTooltip}
          onCopy={onCopy}
        />
      ) : (
        <EmptyImage>
          <NoDataImage text={t('msg_no_data')} />
        </EmptyImage>
      )}
    </Wrapper>
  )
}

type DepartmentListWrapperProps = {
  initSortState?: string[]
} & BaseProps

const DepartmentListWrapper = React.forwardRef<RenderFormProps, DepartmentListWrapperProps>(
  function DepartmentListWrapper({ initSortState, initFormValues, onFiltersChanged, ...props }, ref) {
    const initColumnsState = useMemo(() => calcInitColumnsState(getColumns(), initSortState), [initSortState])
    const filtersRef = useRef<any>({})

    return (
      <Form value={initFormValues} ref={ref} shouldUnregister>
        <Spin loading={props.loading}>
          <DepartmentNewList
            {...props}
            initFormValues={initFormValues}
            initColumnsState={initColumnsState}
            onFiltersChanged={filters => {
              const newFilters = !filters?.valid_at
                ? filters
                : {
                    ...filters,
                    valid_at: moment(filters.valid_at).format(DATE_FORMAT_RFC) || '',
                  }
              if (!isEqual(filtersRef.current, newFilters)) {
                filtersRef.current = newFilters
                onFiltersChanged(newFilters)
              }
            }}
          />
        </Spin>
      </Form>
    )
  }
)

export default DepartmentListWrapper
