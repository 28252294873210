import { CommonErrorMsg } from 'aa_common/front-end/antd'
import React from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import { CurrencyInputProps } from './model'
import { CoreInput } from './styles'

const CoreCurrencyInput = ({ currencyPrefix, ...rest }: any) => <CoreInput {...rest} prefix={currencyPrefix} />

const CurrencyInput = ({
  name,
  value,
  disabled,
  currencyPrefix = '¥',
  placeholder = '0',
  className,
  onChange,
  onBlur,
  onKeyUp,
  control,
  isShowError = true,
  error,
}: CurrencyInputProps) => {
  const handleChange = (event: NumberFormatValues) => {
    onChange?.(event)
  }

  // *** Form Input ***
  if (control) {
    const isInvalid = !!error

    return (
      <>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ value, onChange: controllerOnChange }) => (
            <NumberFormat
              name={name}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              value={value}
              onValueChange={event => {
                controllerOnChange(event.floatValue)
                handleChange(event)
              }}
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              customInput={CoreCurrencyInput}
              allowNegative={false}
              allowLeadingZeros={false}
              allowEmptyFormatting={false}
              thousandSeparator
              currencyPrefix={currencyPrefix}
              defaultValue={0}
            />
          )}
        />
        {isInvalid && isShowError && <CommonErrorMsg fontSize="13px">{error}</CommonErrorMsg>}
      </>
    )
  }

  // *** Regular Input ***
  return (
    <NumberFormat
      name={name}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      value={value}
      onValueChange={handleChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      customInput={CoreCurrencyInput}
      allowNegative={false}
      allowLeadingZeros={false}
      allowEmptyFormatting={false}
      thousandSeparator
      currencyPrefix={currencyPrefix}
      defaultValue={0}
    />
  )
}

export default CurrencyInput
