import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 600px;
  margin: 60px auto;

  .ant-table {
    border-radius: 4px;
    border: solid 1px #d4d8dd;
    padding-bottom: 15px;

    .ant-table-tbody {
      tr:last-of-type {
        td:first-of-type {
          border-bottom-left-radius: unset;
        }
        td:last-of-type {
          border-bottom-right-radius: unset;
        }
      }
    }

    .ant-table-title {
      font-weight: 500;
    }
  }

  .ant-table-title {
    border-bottom: solid 1px #d4d8dd;
    height: 50px;
    text-align: center;
  }

  .ant-table-cell {
    border-bottom: solid 1px #d4d8dd;
    padding: 0 20px;
    height: 50px;
    font-weight: 400;
  }
`

export const ErrorWrapper = styled.div`
  background-color: #ffeeeb;
  border: 2px solid #f57575;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ec4949;
  padding: 10px 10px;
  margin-bottom: 15px;
`

export const Error = styled.div`
  color: #ec4949;
`
