/* eslint-disable react/function-component-definition */
import React from 'react'

import { ItemContentView, ItemLabel, ItemWrapper } from './styles'

type Props = {
  label: string
  style?: React.CSSProperties
}

const ItemView: React.FC<Props> = ({ children, label, style }) => {
  return (
    <ItemWrapper style={style}>
      <ItemLabel>{label}</ItemLabel>
      <ItemContentView>{children}</ItemContentView>
    </ItemWrapper>
  )
}

export default ItemView
