import styled from '@emotion/styled'
import { Button } from 'aa_common/front-end/antd'
import i18n from 'i18n'
import React from 'react'

export const AgentLoginHeader = ({ onSubmit }: { onSubmit: () => void }) => {
  return (
    <StyledWrapper>
      <StyledAcction>
        <div className="processing-title" color="grey">
          {i18n.t('components.AgentLoginHeader.agent_login_processing')}
        </div>
        <Button type="button" size="short" color="grey" onClick={onSubmit}>
          {i18n.t('components.AgentLoginHeader.finish_agent_login')}
        </Button>
      </StyledAcction>
    </StyledWrapper>
  )
}

export const StyledWrapper = styled.section`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(236, 73, 73, 0.7);
  z-index: 9;
`
export const StyledAcction = styled.div`
  margin-left: 15px;
  display: flex;
  height: 100%;
  align-items: center;
  button:first-of-type {
    margin-right: 10px;
  }

  .processing-title {
    color: black !important;
    background-color: #ffffff !important;
    padding: 0 10px;
    line-height: 29px;
    margin: 0 10px 0 0;
  }
`
