import styled from '@emotion/styled'
import { Table as AntTable } from 'antd'

export const AmortizationTable = styled.table`
  width: 100%;
  border-bottom: 0px;
  margin-bottom: 15px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  tr {
    min-height: 32px;
    &:first-of-type {
      border-top-left-radius: 4px;
      th {
        &:first-of-type {
          border-top-left-radius: 4px;
        }
      }

      td {
        &:last-of-type {
          border-top-right-radius: 4px;
        }
      }
    }

    &:last-of-type {
      td {
        border-bottom: 1px solid #d4d8dd;
      }
    }

    th {
      border-left: none;
      border-bottom: none;
      &:first-of-type {
        border-left: 1px solid #d4d8dd;
      }
    }

    td {
      border-left: none;
      border-bottom: none;
    }

    &:last-child {
      th {
        &:first-of-type {
          border-bottom-left-radius: 4px;
          border-bottom: 1px solid #d4d8dd;
        }
        &:last-of-type {
          border-bottom: 1px solid #d4d8dd;
        }
      }

      td {
        &:last-of-type {
          border-bottom-right-radius: 4px;
          border-bottom: 1px solid #d4d8dd;
        }
      }
    }
  }

  th {
    background-color: #e7f1fd;
    text-align: left;
    width: 180px;
    padding: 5px 10px;
    border: 1px solid #d4d8dd;
  }

  td {
    background-color: #ffffff;
    width: auto;
    padding: 5px 10px;
    border: 1px solid #d4d8dd;
  }
`

export const Table = styled(AntTable)`
  .ant-table {
    border: none;

    &.ant-table-bordered {
      & > .ant-table-container {
        border-color: #d4d8dd;
        border-radius: 4px;

        & > .ant-table-content {
          & > table {
            border-top-color: #d4d8dd;
            border-radius: 4px;
          }
        }

        .ant-table-cell {
          border-color: #d4d8dd;

          &.no-border-right {
            border-right: none;
          }

          &:first-child {
            width: 160px;
          }
        }
      }
    }

    .ant-table-thead {
      tr:first-of-type {
        th {
          background-color: #ffffff;
        }

        .ant-table-cell {
          text-align: left;
          font-weight: 500;
        }
      }
    }

    .ant-table-cell {
      padding: 10px;
      font-weight: 400;
      border-color: #d4d8dd !important;

      &.border-double-left {
        border-left: 3px double #d4d8dd;
      }
    }
  }
`
