import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import i18n from 'i18n'

export const TriggerTemplate = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return (
    <div style={{ borderTop: '1px solid #424954' }}>
      <div style={{ color: 'white', marginLeft: isCollapsed ? '9.175px' : 11.5, float: 'left' }}>
        <div
          style={{
            background: 'transparent',
            display: 'inline-block',
            marginRight: 8,
            marginBottom: 4,
            width: 24,
            height: 24,
            verticalAlign: 'middle',
          }}
        >
          {isCollapsed ? (
            <RightOutlined style={{ verticalAlign: '0.78em', color: 'white' }} />
          ) : (
            <LeftOutlined style={{ verticalAlign: '0.78em', color: 'white' }} />
          )}
        </div>
        <span>{i18n.t('common.actions.close')}</span>
      </div>
    </div>
  )
}
