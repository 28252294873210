import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 900px;

  .ant-upload-drag {
    height: 122px;

    p.ant-upload-text {
      font-size: 13px;
    }
  }

  .ant-upload-list-text {
    display: none;
  }
`

export const Header = styled.div`
  font-weight: 500;
`

export const Body = styled.div`
  background: #ffffff;
  border: 1px solid #d4d8dd;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;

  div + div:not(.ant-select-item) {
    margin-top: 10px;
  }

  .bold {
    font-weight: 500;
  }

  .action {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
`
