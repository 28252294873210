import { loadNs } from 'common/i18n-config'

const t = loadNs(['pages/accounting/journal-list'])

export const KEY_ACTIVE_PARAMS = {
  ACQUISITION: 'acquisition',
  RETIREMENT: 'retirement',
  SOLD: 'sold',
  DEPRECIATION: 'depreciation',
  IMPAIRMENT: 'impairment',
}

export const MAP_JOURNAL_ID = {
  [KEY_ACTIVE_PARAMS.ACQUISITION]: 1,
  [KEY_ACTIVE_PARAMS.RETIREMENT]: 2,
  [KEY_ACTIVE_PARAMS.SOLD]: 3,
  [KEY_ACTIVE_PARAMS.DEPRECIATION]: 4,
  [KEY_ACTIVE_PARAMS.IMPAIRMENT]: 5,
}

export const INITIAL_VALUES: any = {
  term_month_id: null,
  journal_event_type: MAP_JOURNAL_ID[KEY_ACTIVE_PARAMS.ACQUISITION],
}

export const ACTIVE_PARAMS = {
  [KEY_ACTIVE_PARAMS.ACQUISITION]: false,
  [KEY_ACTIVE_PARAMS.RETIREMENT]: false,
  [KEY_ACTIVE_PARAMS.SOLD]: false,
  [KEY_ACTIVE_PARAMS.DEPRECIATION]: false,
  [KEY_ACTIVE_PARAMS.IMPAIRMENT]: false,
}

export const getActiveParamLabel = () => ({
  [KEY_ACTIVE_PARAMS.ACQUISITION]: (num: number) => t('acquisition', { num }),
  [KEY_ACTIVE_PARAMS.RETIREMENT]: (num: number) => t('retirement', { num }),
  [KEY_ACTIVE_PARAMS.SOLD]: (num: number) => t('sold', { num }),
  [KEY_ACTIVE_PARAMS.DEPRECIATION]: (num: number) => t('depreciation', { num }),
  [KEY_ACTIVE_PARAMS.IMPAIRMENT]: (num: number) => t('impairment', { num }),
})
