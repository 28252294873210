import { createContext } from 'react'

import { IInfiniteScrollContext } from './model'

const InfiniteScrollContext = createContext<IInfiniteScrollContext>({
  renderFooter: null,
  totalPages: 0,
  pageSize: 0,
  totalRecords: 0,
  data: null,
  hasMoreRows: false,
  isFetching: false,
  isLoading: false,
  isRefetching: false,
})

export default InfiniteScrollContext
