import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UploadFile } from 'antd/lib/upload/interface'
import get from 'lodash/get'

import { Spin } from '../../../antd'
import { EditIcon, RemoveIcon, Thumbnail, ThumbnailImage } from './styles'

export const UploadItem = ({
  imageItem,
  isEditMode,
  loading,
  remove,
  preview,
}: {
  imageItem: UploadFile
  loading?: boolean
  isEditMode: boolean
  remove: () => void
  preview: () => void
}) => {
  const imageName = imageItem.name || ''
  const imageSrc = get(imageItem.response, 'data.thumbnail_url') || imageItem.url || imageItem.thumbUrl

  return (
    <Thumbnail key={imageItem.uid}>
      <Spin loading={loading} size="default">
        {isEditMode && !loading && (
          <EditIcon className="edit-icon" onClick={preview}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </EditIcon>
        )}
        {isEditMode && !loading && (
          <RemoveIcon className="remove-icon" onClick={remove}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </RemoveIcon>
        )}
        <ThumbnailImage url={imageSrc} className="image-item" onClick={() => !isEditMode && preview()}>
          <img alt={imageName} src={imageSrc} />
        </ThumbnailImage>
      </Spin>
    </Thumbnail>
  )
}
