import { Typography } from 'antd'
import { URL_ZENDECK, ZENDECK_SERVICE_NAME, ZENDECK_TICKET_FORM_ID } from 'common/constants'
import { objectToQueryParams } from 'common/helpers/request-helper'
import { ErrorTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEmpty, isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Translation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCurrentOffice, getUserInfo } from 'store/session/actions'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'

import { WrapperClass } from './styles'

const { Link } = Typography

const headerContent = {
  header: i18n.t('pages.errorPages.no_authorization.header'),
  title: 'No Authorization',
}

const NoAuthorizationPage = () => {
  const dispatch = useDispatch()
  const userInfoSelector = useSelector(selectUserInfo, isEqual)
  const currentOfficeSelector = useSelector(selectCurrentOffice, isEqual)

  const userInfo = useMemo(() => userInfoSelector.data, [userInfoSelector.data])
  const currentOffice = useMemo(() => currentOfficeSelector.data, [currentOfficeSelector.data])

  useEffect(() => {
    document.title = i18n.t('common.page_title')
    if (isEmpty(userInfo)) {
      dispatch(getUserInfo())
    }

    if (isEmpty(currentOffice)) {
      dispatch(fetchCurrentOffice())
    }
  }, []) // eslint-disable-line

  const ContactToMFCloudCenter = useMemo(() => {
    const zendeckQueryParams = {
      identification_code: userInfo?.office_identification_code,
      mail: userInfo?.email,
      office_type_id: `${currentOffice?.id}`,
      service_name: ZENDECK_SERVICE_NAME,
      ticket_form_id: ZENDECK_TICKET_FORM_ID,
      user_identification_code: userInfo?.identification_code,
    }

    return (
      <Translation i18n={i18n}>
        {t => {
          return (
            <div>
              <span>{t('pages.errorPages.problem_exists.text_4')}</span>
              <Link
                disabled={!userInfo || !currentOffice}
                href={`${URL_ZENDECK}?${objectToQueryParams(zendeckQueryParams)}`}
              >
                {t('pages.errorPages.problem_exists.link')}
              </Link>
              <span>{t('pages.errorPages.problem_exists.text_2')}</span>
            </div>
          )
        }}
      </Translation>
    )
  }, [userInfo, currentOffice])

  return (
    <ErrorTemplate contentClassName={WrapperClass} headerContent={headerContent}>
      <div className="text">
        <span>{i18n.t('pages.errorPages.no_authorization.text_1')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.no_authorization.text_2')}</span>
        <br />
      </div>
      <div className="link">{ContactToMFCloudCenter}</div>
    </ErrorTemplate>
  )
}

export default NoAuthorizationPage
