import { FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import { selectUserPermission } from 'store/session/selectors'

export const useAssetPermissions = () => {
  const { data: userPermissions } = useSelector(selectUserPermission, isEqual)

  const fixedAssetPermissions = handlePermissionByFeatureId(FEATURES_ID.FIXED_ASSETS, userPermissions)
  const exportPermissions = handlePermissionByFeatureId(FEATURES_ID.EXPORT, userPermissions)
  const commentPermissions = handlePermissionByFeatureId(FEATURES_ID.COMMENT, userPermissions)
  return {
    fixedAssetPermissions,
    exportPermissions,
    commentPermissions,
  }
}
