import { Radio } from 'antd'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import React, { useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  name: string
  trueValue?: boolean | number | string
  falseValue?: boolean | number | string
}

const BoolRadios = ({ name = '', falseValue = false, trueValue = true }: Props) => {
  const { values, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name))

  const handleChange = (e: any) => {
    setValue(e.target.value)
    setFieldValue(name, e.target.value)
  }

  return (
    <Wrapper>
      <Radio.Group onChange={handleChange} value={value}>
        <Radio value={trueValue}>{i18n.t('common.form_values.have')}</Radio>
        <Radio value={falseValue}>{i18n.t('common.form_values.not_have')}</Radio>
      </Radio.Group>
    </Wrapper>
  )
}

export default BoolRadios
