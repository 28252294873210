import { InUsedMessage } from 'components/organisms'
import MainTemplate, { MainTemplateProps } from 'components/templates/MainTemplate'
import React from 'react'

import { Errors, TableList, Wrapper } from './styles'

interface ListPageTemplateProps extends MainTemplateProps {
  errors?: any
  maxWidth?: number | string
  actions?: any
  filterPanel?: any
}

const ListPageTemplate: React.FC<ListPageTemplateProps> = ({
  actions,
  breadcrumb,
  children,
  filterPanel,
  errors,
  maxWidth = 1000,
}) => {
  return (
    <MainTemplate breadcrumb={breadcrumb} actions={actions} filterPanel={filterPanel}>
      <Wrapper style={{ maxWidth, marginTop: filterPanel ? 36 : undefined }}>
        {errors && <Errors>{errors}</Errors>}
        <TableList>{children}</TableList>
      </Wrapper>
      <InUsedMessage />
    </MainTemplate>
  )
}

export default ListPageTemplate
