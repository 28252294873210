import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DATE_FORMAT } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { RenderFormProps } from 'components/molecules/Form'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'

import { Empty, List, ListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

interface RangeDateContentProps {
  formModule: RenderFormProps
  fieldKey: string
  formState: any
  onFormSubmit: (event: any) => void
}

const RangeDateContent = ({ formModule, fieldKey, formState, onFormSubmit }: RangeDateContentProps) => {
  const { handleSubmit, setValue } = formModule

  const handleFormSubmit = () => handleSubmit(onFormSubmit, onFormSubmit)()

  const value = get(formState, fieldKey)
  const fromValue = value && value[0] ? moment(value[0]).format(DATE_FORMAT) : t('unset')
  const toValue = value && value[1] ? moment(value[1]).format(DATE_FORMAT) : t('unset')

  const handleClearFilter = () => {
    setValue(fieldKey, undefined)
    const submitFormValues = {
      [fieldKey]: null,
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
  }

  return !value ? (
    <Empty>{t('empty')}</Empty>
  ) : (
    <List>
      <ListItem>
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ marginRight: 6, cursor: 'pointer' }}
          onClick={handleClearFilter}
        />
        <span>{`${fromValue} ~ ${toValue}`}</span>
      </ListItem>
    </List>
  )
}

export default RangeDateContent
