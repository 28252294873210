import { Tooltip } from 'antd'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import { MonthlyClosing } from 'common/models'
import React, { useRef } from 'react'

import { calculateMonthStatus } from '../DepreciationTable/helper'
import { ConvertedDepreciationResultTermMonthItem } from '../DepreciationTable/model'
import { MonthList, MonthListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-detail'])

interface IMonthSimulation {
  months: ConvertedDepreciationResultTermMonthItem[]
  acquiredAt: string
  usageStartedAt: string | null
  currentMonthlyClosing: MonthlyClosing
}

const MonthSimulation = ({ months, usageStartedAt, acquiredAt, currentMonthlyClosing }: IMonthSimulation) => {
  const containerRef = useRef(null)

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <MonthList ref={containerRef}>
        {currentMonthlyClosing &&
          months?.map(month => {
            const { isActive, isNotDepreciationYet, hasStartDepreciation, monthHasBeenAppliedDepreciation } =
              calculateMonthStatus(currentMonthlyClosing, usageStartedAt, acquiredAt, month)
            const toBeAppliedDepreciation = hasStartDepreciation && !monthHasBeenAppliedDepreciation

            let component
            if (isActive) {
              component = (
                <Tooltip
                  key={`${month.id}-${month.year}`}
                  placement="top"
                  title={t('depreciation_calculation_processing')}
                  arrowPointAtCenter
                  overlayClassName="processing-tooltip"
                  getPopupContainer={() => containerRef.current as any}
                >
                  <MonthListItem className="current">
                    <div className="text-label">{month.month}</div>
                  </MonthListItem>
                </Tooltip>
              )
            } else {
              component = (
                <MonthListItem
                  key={`${month.id}-${month.year}`}
                  className={classNames({
                    'before-depreciation': isNotDepreciationYet,
                    unselect: toBeAppliedDepreciation,
                    select: monthHasBeenAppliedDepreciation,
                  })}
                >
                  <div className="text-label">{month.month}</div>
                </MonthListItem>
              )
            }

            return component
          })}
      </MonthList>
    </div>
  )
}

export default MonthSimulation
