import { Map } from 'immutable'

import { MonthlyClosingActionTypes } from './action.model'
import {
  CLEANUP_MONTHLY_CLOSING_ASSET_EVENTS,
  CLEANUP_MONTHLY_CLOSING_NEXT_SETTING,
  FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_CURRENT_SUCCESS,
  FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_PAST_SUCCESS,
  FETCH_MONTHLY_CLOSING_CLOSED_SUCCESS,
  FETCH_MONTHLY_CLOSING_CURRENT_REQUEST,
  FETCH_MONTHLY_CLOSING_CURRENT_SUCCESS,
  FETCH_MONTHLY_CLOSING_NEXT_SETTING_SUCCESS,
  FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_SUCCESS,
  FETCH_MONTHLY_CLOSING_RESELECT_SUCCESS,
  FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_SUCCESS,
} from './constant'

export const initialState = Map({
  // Keep this for backward compability - will change the code
  current: null,
  currentMonthlyClosing: Map({
    loading: false,
    data: null,
  }),
  isCurrentLoading: false,
  nextSetting: null,
  summaryAssetEvents: null,
  closedMonthlyClosing: null,
  assetEvents: {
    dataInCurrent: {
      data: null,
      total: null,
    },
    dataInPast: {
      data: null,
      total: null,
    },
  },
  previousAssetEvents: null,
  monthlyClosingReselectTerms: null,
})

export default (state = initialState, action: MonthlyClosingActionTypes) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSING_CURRENT_REQUEST:
      return state.setIn(['currentMonthlyClosing', 'loading'], true)
    case FETCH_MONTHLY_CLOSING_CURRENT_SUCCESS:
      // Since we have to check the loading state,
      // We keep the 'current' key for backward compability - will change the code later;
      return state
        .setIn(['current'], action.payload)
        .setIn(['currentMonthlyClosing', 'data'], action.payload)
        .setIn(['currentMonthlyClosing', 'loading'], false)
    case FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_SUCCESS:
      return state.setIn(['summaryAssetEvents'], action.payload)
    case FETCH_MONTHLY_CLOSING_NEXT_SETTING_SUCCESS:
      return state.setIn(['nextSetting'], action.payload)
    case FETCH_MONTHLY_CLOSING_CLOSED_SUCCESS:
      return state.setIn(['closedMonthlyClosings'], action.payload)
    case FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_CURRENT_SUCCESS:
      return state
        .setIn(['assetEvents', 'dataInCurrent', 'data'], action.payload.data)
        .setIn(['assetEvents', 'dataInCurrent', 'total'], action.payload.total)
    case FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_PAST_SUCCESS:
      return state
        .setIn(['assetEvents', 'dataInPast', 'data'], action.payload.data)
        .setIn(['assetEvents', 'dataInPast', 'total'], action.payload.total)
    case FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_SUCCESS:
      return state.setIn(['previousAssetEvents'], action.payload)
    case CLEANUP_MONTHLY_CLOSING_ASSET_EVENTS:
      return state.setIn(['assetEvents', 'data'], null)
    case CLEANUP_MONTHLY_CLOSING_NEXT_SETTING:
      return state.setIn(['nextSetting'], null)
    case FETCH_MONTHLY_CLOSING_RESELECT_SUCCESS:
      return state.setIn(['monthlyClosingReselectTerms'], action.payload)
    default:
      return state
  }
}
