import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import DepartmentForm from 'components/organisms/settings/DepartmentForm'
import { MainTemplate } from 'components/templates'
import withRouterTrap from 'components/withRouterTrap'
import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { cleanupDepartment, createDepartment, partialCleanupDepartment } from 'store/settings/department/actions'
import { selectDepartmentItem } from 'store/settings/department/selectors'

const t = loadNs(['pages/settings/departments'])

const DepartmentPage = withRouterTrap(() => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('add')} />
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData, isEqual)
  const { errors, loading } = useSelector(selectDepartmentItem, isEqual)

  const handleSubmit = (values: any): void => {
    dispatch(createDepartment(values))
  }

  useEffect(() => {
    return function cleanup(): void {
      dispatch(partialCleanupDepartment())
    }
  }, [dispatch])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <DepartmentForm
        errors={errors}
        onSubmit={handleSubmit}
        prepressCategories={masterData.prepress_category}
        loading={loading}
      />
    </MainTemplate>
  )
})

const DepartmentPageWrapper = () => {
  const dispatch = useDispatch()
  return <DepartmentPage path="/master/departments" onLeave={() => dispatch(cleanupDepartment())} />
}

export default DepartmentPageWrapper
