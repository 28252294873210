import { ConfirmModal } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import AssetForm from 'components/organisms/assets/AssetForm'
import { MainTemplate } from 'components/templates'
import React from 'react'

import { useActionsEditFa } from './hooks/use-actions-edit-fa'
import { useFetchAssetDetail } from './hooks/use-fetch-asset-detail'

const { t, useNsReady } = getNs(['pages/assets', 'components/organisms/assets/asset-form'])

export const AssetEditContainer: React.FC = () => {
  useNsReady()

  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />

  const { parsedAssetDataForm, assetData } = useFetchAssetDetail()

  const {
    handleOkSubmit,
    handleCancelSubmit,
    submitedAsset,
    handleDelete,
    handleSubmit,
    errors,
    showRequiredError,
    isShowConfirm,
    loading,
  } = useActionsEditFa()

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      {assetData && (
        <AssetForm
          loading={loading}
          errors={errors}
          onSubmit={handleSubmit}
          initialAssetData={parsedAssetDataForm}
          formMode={FORM_MODES.EDIT}
          onDelete={handleDelete}
          showRequiredError={showRequiredError}
          asset={submitedAsset}
        />
      )}
      <ConfirmModal
        type={ConfirmModal.CONFIRM_TYPES.CONFIRM}
        title={t('update_confirm_title')}
        message={t('update_confirm_message')}
        visible={isShowConfirm}
        onCancel={handleCancelSubmit}
        onOK={handleOkSubmit}
      />
    </MainTemplate>
  )
}
