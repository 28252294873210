import { Button, Tooltip } from 'aa_common/front-end/antd'
import { ColorLabel } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import { NumberInput } from 'components/atoms'
import { getIn, useFormikContext } from 'formik'
import { useAssetCodePreview } from 'hooks/use-asset-code-preview'
import React from 'react'

import { Row, SubmitWrapper, Table } from './styles'

const { t, useNsReady } = getNs(['common', 'components/organisms/settings/asset-code-start-number-page'])

type Props = {
  lengthRuleNumber: number
}

const Form: React.FC<Props> = ({ lengthRuleNumber }) => {
  useNsReady()
  const { values } = useFormikContext()
  const assetCategoryInitialValues = getIn(values, 'asset_category_initial_values')
  const { getAssetCodePreview, isIncludeAcquisitionDate } = useAssetCodePreview()

  return (
    <>
      {assetCategoryInitialValues && (
        <Table>
          {assetCategoryInitialValues.map((assetCategory: any, index: number) => {
            const assetCodePreview = getAssetCodePreview(assetCategory, lengthRuleNumber)

            return (
              <Row key={assetCategory.id} className={index === assetCategoryInitialValues.length - 1 ? 'last' : ''}>
                <div className="label">{assetCategory.asset_category_name}</div>
                <div className="content">
                  {assetCategory.is_editable ? (
                    <NumberInput
                      name={`asset_category_initial_values[${index}].initial_value`}
                      style={{ width: 100 }}
                      maxLength={lengthRuleNumber}
                    />
                  ) : (
                    <Tooltip content={t('disabled_tooltip_msg')} placement="top">
                      <NumberInput
                        name={`asset_category_initial_values[${index}].initial_value`}
                        style={{ width: 100 }}
                        disabled
                      />
                    </Tooltip>
                  )}

                  <ColorLabel color="disabled" style={{ marginLeft: 10, marginTop: 5 }}>
                    {t('code_sample', { code: assetCodePreview })}
                    {isIncludeAcquisitionDate && t('incase_registering_today')}
                  </ColorLabel>
                </div>
              </Row>
            )
          })}
        </Table>
      )}
      <SubmitWrapper>
        <Button type="submit" color="blue" size="custom" style={{ width: 70 }}>
          {t('actions.save')}
        </Button>
      </SubmitWrapper>
    </>
  )
}

export default Form
