import { css } from '@emotion/css'

export const LayoutClass = css`
  .top-header-template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .left {
      display: flex;
      align-items: center;
    }

    .display-status-text {
      margin-left: 5px;
    }

    .filter-date {
      min-width: 150px;
      .ant-picker-input > input {
        text-align: left;
      }
    }

    span {
      font-size: 13px;
    }
  }

  .term-list-sider {
    background: white;
    border-right: 1px solid #d4d8dd;
    padding: 10px 15px;
    z-index: 1;

    button.add-button {
      &:hover {
        color: rgba(59, 125, 233, 0.9);
      }
      .add-button-icon {
        margin: 0 10px;
      }
      .dotted {
        width: 100%;
        border-bottom: 1px dashed;
      }

      display: flex;
      padding: 0;
      flex-direction: 'column';
      width: 100%;
      align-items: center;
      outline: none;
      border: none;
      box-shadow: none;

      &:disabled {
        background: none;
      }
    }

    span.add-button {
      display: initial !important;

      .add-button-icon {
        margin: 0 10px;
      }
      .dotted {
        width: 100%;
        border-bottom: 1px dashed;
      }

      button {
        display: flex;
        padding: 0;
        flex-direction: 'column';
        width: 100%;
        align-items: center;
        outline: none;
        border: none;
        box-shadow: none;

        &:disabled {
          background: none;
        }
      }
    }
  }
`

export const ContentClass = css`
  background: #dce0e6;
`
