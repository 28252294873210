/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { Namespace, ReadCallback } from 'i18next'
import I18nextHttpBackend from 'i18next-http-backend'

export const isBrowser = typeof window !== 'undefined'

export class Backend extends I18nextHttpBackend {
  static type: 'backend'

  read(lng: string, ns: string, callback: ReadCallback) {
    getNsResource(lng, ns).then(resources => {
      callback(null, resources)
    })
  }
}
Backend.type = 'backend'

const getNsUrl = async (lng: string, ns: string): Promise<object> => {
  let promise: Promise<object>
  if (isBrowser) {
    promise = await fetch(`/locales/${lng}/${ns}.json`, { credentials: 'same-origin' }).then(res => {
      return res.json()
    })
  } else {
    promise = await import(`../locales/${lng}/${ns}.json`).then(res => {
      return res.default
    })
  }
  const result = await promise
  // console.log(result)
  return result
}

const getNsResource = async (lng: string, ns: string | Namespace) => {
  ns = Array.isArray(ns) ? ns : [ns]
  const promises = ns.map(async (ens: string) => getNsUrl(lng, ens))
  const result = await Promise.allSettled<object>(promises)
  const resources = result.reduce((a, e) => {
    if (e.status === 'fulfilled') {
      return {
        ...a,
        ...e.value,
      }
    }
    return a
  }, {})
  return resources
}
