import reduxWebsocket from '@giantmachines/redux-websocket'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'

import persistStoreMiddleware from './persistStore'
import reducers from './rootReducer'
import rootSaga from './rootSaga'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const websocketMiddleware = reduxWebsocket({ deserializer: JSON.parse })
const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(websocketMiddleware, thunkMiddleware, sagaMiddleware), persistStoreMiddleware)
)

sagaMiddleware.run(rootSaga)

export default store
