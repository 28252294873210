import { Radio } from 'antd'
import { useFormikContext } from 'formik'
import { get, isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'

type CheckboxItem = {
  label: string
  value: boolean | string | number
}

type Props = {
  fields: Array<CheckboxItem>
  name: string
  disabled?: boolean
  afterChanged?: (value: string | number) => void
  defaultValue?: number
}

const RadioList = ({ fields = [], name, disabled = false, afterChanged, defaultValue }: Props) => {
  const { values, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(get(values, name))

  const handleChange = (e: any) => {
    setValue(e.target.value)
    setFieldValue(name, e.target.value)
    afterChanged && afterChanged(e.target.value)
  }

  useEffect(() => {
    const newValue = get(values, name)
    if (!isEqual(value, newValue)) {
      setValue(newValue)
    }
  }, [values]) // eslint-disable-line

  return (
    <Wrapper>
      <Radio.Group onChange={handleChange} value={value || defaultValue} disabled={disabled}>
        {fields.map(field => (
          <Radio value={field.value} key={typeof field?.value === 'boolean' ? field?.value.toString() : field?.value}>
            {field.label}
          </Radio>
        ))}
      </Radio.Group>
    </Wrapper>
  )
}

export default RadioList
