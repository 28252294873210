import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import i18n from 'i18n'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { fetchTermPeriods, fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermData, selectTermsData } from 'store/settings/accountingPeriod/selectors'

import {
  DividerWrapper,
  HorizontalBorder,
  PaginationButton,
  PaginationWrapper,
  Wrapper,
  WrapperContent,
} from './styles'

interface TermsSelectionProps {
  keyValue?: string
  keyName?: string
  onChange?: (termMonthId: number) => void
}

const TermsSelection = ({ keyValue = 'id', keyName = 'month', onChange = value => value }: TermsSelectionProps) => {
  const dispatch = useDispatch()

  const { data: currMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)

  const [termValue, setTermValue] = useState<number | null>(null)
  const [index, setIndex] = useState(0)
  const [monthValue, setMonthValue] = useState<number | null>(currMonthlyClosing?.from_term_month?.month || null)

  const terms = useSelector(selectTermsData, isEqual)
  const { data: termPeriods } = useSelector(selectTermData, isEqual)

  const termMonths = termPeriods?.term_months || []

  useEffect(() => {
    !currMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
    !terms && dispatch(fetchTerms())
  }, [terms, currMonthlyClosing, dispatch])

  useEffect(() => {
    if (currMonthlyClosing) {
      setTermValue(currMonthlyClosing?.from_term_month?.term_id)
      setMonthValue(currMonthlyClosing?.from_term_month?.month)
    }
  }, [currMonthlyClosing])

  useEffect(() => {
    termValue && dispatch(fetchTermPeriods(termValue))
  }, [termValue]) // eslint-disable-line

  useEffect(() => {
    if (termMonths.length) {
      let termMonthId = null
      let index = 0
      if (monthValue) {
        termMonths.forEach((monthItem: any, indexMonth: number) => {
          if (monthItem.month === monthValue) {
            termMonthId = monthItem.id
            index = indexMonth
          }
        })
      } else {
        termMonthId = termMonths[0]?.[keyValue]
      }
      setIndex(index)
      onChange(termMonthId)
    }
  }, [termMonths]) // eslint-disable-line

  const parseTerms = useMemo(() => {
    return terms?.map(term => ({
      value: term.id,
      text: `${i18n.t('common.format.year_term', { year: term.year, term: term.period })}`,
    }))
  }, [terms])

  const handleOnChangeSelect = (value: number) => {
    setTermValue(value)
    setMonthValue(null)
  }

  const handleOnChangePagination = (index: number) => {
    setMonthValue(null)
    setIndex(index)
    onChange(termMonths[index]?.[keyValue])
  }

  return (
    <Wrapper>
      {termMonths && (
        <WrapperContent>
          <AutoCompleteSelect
            value={termValue}
            name="term_id"
            items={parseTerms}
            textField="text"
            valueField="value"
            style={{ width: 150 }}
            allowClear={false}
            onChange={handleOnChangeSelect}
          />
          <PaginationWrapper>
            <PaginationButton
              className="prev"
              disabled={index === 0}
              onClick={() => handleOnChangePagination(index - 1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </PaginationButton>
            <span>
              {i18n.t('common.format.term_month', {
                month: monthValue || termMonths[index]?.[keyName],
              })}
            </span>
            <PaginationButton
              className="next"
              disabled={index === termMonths.length - 1}
              onClick={() => handleOnChangePagination(index + 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </PaginationButton>
          </PaginationWrapper>
        </WrapperContent>
      )}
      <DividerWrapper>
        <HorizontalBorder />
      </DividerWrapper>
    </Wrapper>
  )
}

export default TermsSelection
