import { Checkbox as AntdCheckbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React, { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

import { CommonErrorMsg } from '../error-message'
import { Wrapper } from './styles'

interface CheckboxProps {
  name: string
  className?: string
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  isShowError?: boolean
  control?: Control<Record<any, any>>
  error?: string
  style?: React.CSSProperties
  onChange?: (event: any) => void
  indeterminateValue?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  className,
  checked,
  defaultChecked,
  isShowError = true,
  error,
  disabled,
  indeterminateValue,
  children,
  control,
  style,
  onChange,
}) => {
  const [isChecked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    setChecked(!!checked)
  }, [checked, name])

  const handleOnChange = (event: CheckboxChangeEvent) => {
    const { checked, value } = event.target
    setChecked(checked)
    onChange &&
      onChange({
        name,
        checked,
        value,
      })
  }

  if (control) {
    const isInvalid = !!error

    return (
      <Wrapper>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultChecked}
          render={({ value, onChange: controllerOnChange, ...controllerRest }) => {
            return (
              <AntdCheckbox
                className={className}
                checked={value}
                disabled={disabled}
                onChange={event => {
                  controllerOnChange(event.target.checked)
                  handleOnChange(event)
                }}
                data-testid={name}
                style={style}
                indeterminate={indeterminateValue}
                {...controllerRest}
              >
                {children}
              </AntdCheckbox>
            )
          }}
        />
        {isInvalid && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
      </Wrapper>
    )
  }

  return (
    <AntdCheckbox
      name={name}
      className={className}
      checked={isChecked}
      disabled={disabled}
      indeterminate={indeterminateValue}
      onChange={handleOnChange}
      style={style}
      data-testid={name}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {children}
    </AntdCheckbox>
  )
}
