/* eslint-disable  no-empty */
import { messageSuccess, Spin } from 'aa_common/front-end/antd'
import { ExportModal, If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { convertComplexTableSortPayload } from 'aa_common/front-end/shared'
import { Layout } from 'antd'
import { ASSET_STATUSES, DATE_FORMAT_RFC, FEATURES_ID } from 'common/constants'
import {
  buildQueryFilterParams,
  convertSortValue,
  downloadRequestParams,
  handlePermissionByFeatureId,
} from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { Asset, CsvCharset, FixedAssetListItem } from 'common/models'
import { ExportService, FixedAssetsService, SelectAllService } from 'common/open-api'
import { BreadcrumbNavigation } from 'components/molecules'
import {
  AssetModificationForm,
  AssetMovementForm,
  AssetRetirementForm,
  AssetUsageStartedForm,
} from 'components/organisms'
import AssetFilterFacet from 'components/organisms/assets/AssetFilterFacet'
import { AssetCollectionFilterEvent } from 'components/organisms/assets/AssetFilterFacet/model'
import AssetList from 'components/organisms/assets/AssetList'
import AssetListTopTemplate from 'components/organisms/assets/AssetList/components/AssetListTopTemplate'
import { TableHeaderFunctions } from 'components/organisms/assets/AssetList/components/AssetListTopTemplate/components/table-header-functions'
import { FilterFacetTemplate } from 'components/templates'
import useRetrospectiveAssetModal from 'components/templates/modal/RetroactiveAssetModal'
import { tagListSlice } from 'containers/master/tags/store'
import { useLongPolling } from 'hooks/useLongPolling'
import i18n from 'i18n'
import { List, Map } from 'immutable'
import find from 'lodash/find'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import { deleteAsset, exportAsset } from 'store/asset/actions'
import {
  selectAssetDetailPage,
  selectAssetsCollectionFormValues,
  selectAssetsCollectionView,
} from 'store/asset/selectors'
import { selectUserPermission } from 'store/session/selectors'
import { fetchCauseList } from 'store/settings/cause/actions'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { getSingleViewParam, useFetchAssetListCallback, verifyClosingMonthStartUsageEvent } from './helper'
import { useGetSelectedFa } from './hooks/useGetSelectedFa'
import { IMPORT_EXPORT_EVENT_TYPES, ImportExportEventTypeSelector } from './import-export-event-type-selector'
import {
  assetListPageStore,
  BulkChangeEvent,
  importExportEventTypeSelectorSlice,
  importExportStore,
  rowSelectSliceAssetList,
  setErrorBiids,
  setErrorsBulkChange,
} from './store'
import { ContentWrapper } from './styles'

const { t, useNsReady } = getNs(['pages/assets', 'common'])
const DETAIL_MODE_PAGE_SIZE = 20
const DEFAULT_PAGE_SIZE = 50

const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.assets.list')} />

export const AssetsListPageOld = () => {
  useNsReady()
  const rowSelectSliceAssetListStore = rowSelectSliceAssetList.useSlice(state => state.data)
  const { bulkChangeEvent, errorsBulkChange } = rowSelectSliceAssetListStore
  const { selectedRow, biids, totalBiidOfSelectedItems } = useGetSelectedFa()

  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const dispatch = useDispatch()
  const fetchAssetList = useFetchAssetListCallback()
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const assetCollectionViewFilterValues = useSelector(selectAssetsCollectionFormValues, isEqual)
  const ledgerSettings = useSelector(selectLedgerSettingList, isEqual)
  const { data: userPermissions } = useSelector(selectUserPermission, isEqual)

  const fixedAssetPermissions = handlePermissionByFeatureId(FEATURES_ID.FIXED_ASSETS, userPermissions)
  const exportPermissions = handlePermissionByFeatureId(FEATURES_ID.EXPORT, userPermissions)
  const commentPermissions = handlePermissionByFeatureId(FEATURES_ID.COMMENT, userPermissions)

  const { causes } = useSelector(selectAssetDetailPage, isEqual)

  const isDetailMode = get(assetCollectionViewSelector.filters, 'include_history')
  const pageSize = ifElse(isDetailMode, DETAIL_MODE_PAGE_SIZE, DEFAULT_PAGE_SIZE)
  const sort = useMemo(() => assetCollectionViewSelector.sort, [assetCollectionViewSelector.sort])
  const filters = useMemo(() => assetCollectionViewSelector.filters, [assetCollectionViewSelector.filters])
  const confirmModal = useRetrospectiveAssetModal()
  const [loadingBulkChange, setLoadingBulkChange] = useState(false)
  useEffect(() => {
    const filterValidAt = get(assetCollectionViewSelector.filters, 'valid_at', null)
    const todayDate = moment().format(DATE_FORMAT_RFC)
    const validAtDate = filterValidAt || todayDate

    const filterPayload = { valid_at: validAtDate, ...assetCollectionViewSelector.filters }
    const sortPayload =
      !assetCollectionViewSelector.sort || assetCollectionViewSelector.sort.length === 0
        ? ['-code', '-branch_code']
        : assetCollectionViewSelector.sort

    dispatch(
      fetchAssetList(
        { filters: filterPayload, sort: sortPayload, formValues: assetCollectionViewFilterValues },
        assetCollectionViewSelector.page,
        pageSize
      )
    )

    assetListPageStore.resetSelectRows()

    // Get tag data
    tagListSlice.setFilters({}, true)

    dispatch(fetchCauseList())
    dispatch(fetchLedgerSettingsList())
  }, []) // eslint-disable-line

  const handleListModeChanged = useCallback(
    (detailMode: boolean) => {
      const todayDate = moment().format(DATE_FORMAT_RFC)
      const newValue = { valid_at: todayDate, include_history: detailMode }
      const newFilters = { ...filters, ...newValue }
      const newFormFilterValues = { ...assetCollectionViewFilterValues, ...newValue }

      const pageSize = detailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
      dispatch(fetchAssetList({ filters: newFilters, sort, formValues: newFormFilterValues }, 1, pageSize))
    },
    [dispatch, fetchAssetList, filters, assetCollectionViewFilterValues, sort]
  )

  const handleSubmitFilter = useCallback(
    (event: AssetCollectionFilterEvent) => {
      hookFnc.submitFilter(event, filters, sort, dispatch, fetchAssetList)
    },
    [dispatch, fetchAssetList, filters, sort]
  )

  const handleFilterReset = useCallback(
    (event: AssetCollectionFilterEvent) => {
      // Keep the previous valid_at and also detail mode status;
      resetFilter(event, filters, sort, dispatch, fetchAssetList)
    },
    [dispatch, fetchAssetList, filters, sort]
  )

  const handleColumnSortChanged = useCallback(
    (sorter: any) => {
      const sort = convertComplexTableSortPayload(sorter)

      dispatch(fetchAssetList({ filters, sort, sorterValue: sorter }, assetCollectionViewSelector.page, pageSize))
    },
    [dispatch, fetchAssetList, filters, assetCollectionViewSelector.page, pageSize]
  )

  const handleDelete = useCallback(
    (data: any) => {
      dispatch(deleteAsset(data, pageSize))
    },
    [dispatch, pageSize]
  )

  const handleCopy = useCallback(
    (data: any) => dispatch(changeLocation(`/assets/${get(data, 'biid')}/copy`)),
    [dispatch]
  )
  const filtersWithoutBiid = useMemo(() => {
    const newFilters = omit(filters, ['biid'])
    return newFilters
  }, [filters])

  const store = assetListPageStore.rowSelectSliceAssetList.useSlice(data => data).data
  const markAllFa = useCallback(async () => {
    const queryFilterParams = buildQueryFilterParams({ ...filters, calculate_cost: true } ?? {})
    const res = await SelectAllService.fixedAssetsSelectAllCreate({
      ...queryFilterParams,
      sort,
    })
    assetListPageStore.setIdMarkAll(res.data)
  }, [JSON.stringify(filtersWithoutBiid), JSON.stringify(sort)])

  useEffect(() => {
    if (store.markAll) {
      markAllFa()
    }
  }, [markAllFa, store.markAll])

  useEffect(() => {
    if (!store.markAll) {
      assetListPageStore.resetSelectRows()
    }
  }, [JSON.stringify(filtersWithoutBiid), JSON.stringify(sort)])

  const handleShowItem = useCallback(
    (record: FixedAssetListItem) => {
      const validAt = filters?.valid_at
      const isDetailMode = filters?.include_history && record?.change_situations

      let param = ''

      const validAtDate = moment(validAt)

      if (isDetailMode) {
        const currentEventItem = find(record.change_situations, event => {
          const validFromDate = moment(event.valid_from)
          const validToDate = moment(event.valid_to)
          return validAtDate.isBetween(validFromDate, validToDate, 'date', '[]')
        })

        param = getSingleViewParam(validAt, currentEventItem)
      } else {
        param = getSingleViewParam(validAt, record)
      }

      dispatch(changeLocation(`/assets/${record.biid}${param}`))
    },
    [dispatch, filters?.valid_at, filters?.include_history]
  )

  const handleExportClick = useCallback(() => setIsShowExportModal(true), [])

  const handleValidAtChanged = useCallback(
    dateValue => {
      const assetStatus = List(assetCollectionViewFilterValues?.asset_status).filter(
        (selected: any) => ![ASSET_STATUSES.TO_BE_ACQUIRED, ASSET_STATUSES.RETIRED_OR_SOLD].includes(selected.value)
      )

      const newFilters = Map(filters)
        .setIn(['area_biid'], undefined)
        .setIn(['department_biid'], undefined)
        .setIn(['asset_status'], assetStatus.map((item: any) => item.value).toArray())
        .setIn(['valid_at'], dateValue)
        .toObject()

      const formValues = Map(assetCollectionViewFilterValues)
        .setIn(['department'], [])
        .setIn(['area'], [])
        .setIn(['asset_status'], assetStatus.toArray())
        .toObject()

      setTimeout(() => {
        dispatch(fetchAssetList({ filters: newFilters, sort, formValues }, 1, pageSize))
      }, 180)
    },
    [dispatch, fetchAssetList, assetCollectionViewFilterValues, filters, sort, pageSize]
  )

  const handlePageChanged = useCallback(
    currentPage => {
      dispatch(fetchAssetList({ filters, sort }, currentPage, pageSize))
    },
    [dispatch, fetchAssetList, filters, sort, pageSize]
  )

  const { exportEventType } = importExportEventTypeSelectorSlice.useSlice()
  const handleExport = async (charset: CsvCharset) => {
    setIsShowExportModal(false)
    try {
      if (
        hasIncludeHistory ||
        (!hasIncludeHistory && exportEventType === IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER)
      ) {
        dispatch(
          exportAsset(
            {
              ...assetCollectionViewSelector.filters,
              ...(!!totalBiidOfSelectedItems.length && { biid: totalBiidOfSelectedItems }),
            },
            assetCollectionViewSelector.sort,
            charset
          )
        )
      } else {
        const filters = {
          ...assetCollectionViewSelector.filters,
          event_type_code: exportEventType,
          ...(!!totalBiidOfSelectedItems.length && { biid: totalBiidOfSelectedItems }),
        }
        const sortQuery = convertSortValue(assetCollectionViewSelector.sort) as any

        const response = await ExportService.fixedAssetsEventTypeList(
          {
            charset,
            sort: sortQuery,
            filter: filters,
          } as any,
          downloadRequestParams
        )

        response && importExportStore.setExportEventType(IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER)
      }
    } catch (err) {
      /* empty */
    }
  }

  const generateSearchWords = useMemo(() => {
    if (filters) {
      const keywordValue = get(filters, 'keyword') as string
      const keyword = keywordValue ? keywordValue.trim() : ''
      return [...keyword.split(' '), keyword]
    }

    return []
  }, [filters])

  const hasIncludeHistory = !!assetCollectionViewSelector.filters?.include_history

  const lastEvent = useMemo(() => {
    return selectedRow.find((item: Asset) => item.usage_started_at)
  }, [selectedRow])

  const onError = (errorBiids: string[], errors?: any) => {
    errorBiids && setErrorBiids(errorBiids)
    errors && setErrorsBulkChange(errors)
  }

  const onSuccess = () => {
    messageSuccess(i18n.t('common.messages.created_success'))
    assetListPageStore.setBulkChangeEvent()
    assetListPageStore.unSelectAllRowOnPage(biids)

    dispatch(fetchAssetList({ filters, sort, formValues: assetCollectionViewFilterValues }, 1, pageSize))
  }

  const movementPolling = useLongPolling('movement', { onError, onSuccess })
  const retirementPolling = useLongPolling('retirement', { onError, onSuccess })
  const startedUsagePolling = useLongPolling('startedUsagePolling', { onError, onSuccess })
  const assetTypeModificationPolling = useLongPolling('assetTypeModificationPolling', { onError, onSuccess })

  const handleCancelModal = () => {
    setIsShowExportModal(false)
    importExportStore.setExportEventType(IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER)
  }
  const numberOfExportItems = totalBiidOfSelectedItems.length
    ? totalBiidOfSelectedItems.length
    : assetCollectionViewSelector.totalRecords
  return (
    <FilterFacetTemplate breadcrumb={breadcrumb}>
      <Spin
        indicatorSize={40}
        loading={
          assetCollectionViewSelector.listLoading ||
          movementPolling.loading ||
          retirementPolling.loading ||
          startedUsagePolling.loading ||
          assetTypeModificationPolling.loading ||
          loadingBulkChange
        }
      >
        <Layout>
          <AssetFilterFacet
            filterDate={assetCollectionViewSelector.filters?.valid_at}
            onSubmit={handleSubmitFilter}
            onFilterReset={handleFilterReset}
          />
          <ContentWrapper>
            <AssetListTopTemplate
              valid_at={assetCollectionViewSelector.filters?.valid_at}
              totalRecords={assetCollectionViewSelector.totalRecords}
              onExportClick={handleExportClick}
              onValidAtChanged={handleValidAtChanged}
              fixedAssetPermissions={fixedAssetPermissions}
              exportPermissions={exportPermissions}
            />
            <TableHeaderFunctions onPageChanged={handlePageChanged} onListModeChanged={handleListModeChanged} />
            <AssetList
              filterAt={assetCollectionViewSelector.filters?.valid_at}
              assets={assetCollectionViewSelector.assets || []}
              sorter={assetCollectionViewSelector.sorterValue}
              searchWords={generateSearchWords}
              isDetailMode={hasIncludeHistory}
              ledgerSettings={ledgerSettings}
              fixedAssetPermissions={fixedAssetPermissions}
              commentPermissions={commentPermissions}
              onShowItem={handleShowItem}
              onCopy={handleCopy}
              onDelete={handleDelete}
              onTableSort={handleColumnSortChanged}
            />

            <AssetRetirementForm
              isShow={bulkChangeEvent === BulkChangeEvent.RETIREMENT}
              causes={causes || []}
              onClose={() => assetListPageStore.setBulkChangeEvent(undefined)}
              onSubmit={values => hookFnc.bulkChangesRetirement(values, setLoadingBulkChange, biids, retirementPolling)}
              errors={errorsBulkChange}
              isBulkChangeEvent
            />

            <AssetMovementForm
              isShow={bulkChangeEvent === BulkChangeEvent.MOVEMENT}
              causes={causes || []}
              latestEvent={lastEvent}
              onSubmit={values => {
                hookFnc.bulkChangeMovement(values, setLoadingBulkChange, biids, movementPolling)
              }}
              onClose={() => assetListPageStore.setBulkChangeEvent(undefined)}
              errors={errorsBulkChange}
            />

            <AssetUsageStartedForm
              onClose={() => assetListPageStore.setBulkChangeEvent()}
              causes={causes || []}
              isShow={bulkChangeEvent === BulkChangeEvent.START_USAGE}
              isBulkChangeEvent
              errors={errorsBulkChange}
              onSubmit={values =>
                hookFnc.bulkChangeStartedUsage(
                  values,
                  currentMonthlyClosing,
                  setLoadingBulkChange,
                  startedUsagePolling,
                  confirmModal,
                  biids
                )
              }
            />

            <AssetModificationForm
              isShow={bulkChangeEvent === BulkChangeEvent.CHANGE_ASSET_TYPE}
              setShowForm={() => assetListPageStore.setBulkChangeEvent()}
              onSubmit={values => {
                hookFnc.bulkChangeAssetTypeUpdate(values, setLoadingBulkChange, biids, assetTypeModificationPolling)
              }}
              isBulkChangeEvent
            />

            <ExportModal
              title={t('export_modal_title', { quantity: numberOfExportItems })}
              isShow={isShowExportModal}
              onCancel={handleCancelModal}
              onExport={handleExport}
              isShortCharsetLabel
            >
              <If condition={!isDetailMode}>
                <ImportExportEventTypeSelector
                  name="exportEventType"
                  onChange={(exportEventType: number) => importExportStore.setExportEventType(exportEventType)}
                />
              </If>
              <div style={{ marginBottom: '5px', fontWeight: 500 }}>{t('character_code')}</div>
            </ExportModal>
          </ContentWrapper>
        </Layout>
      </Spin>
    </FilterFacetTemplate>
  )
}

function submitFilter(
  event: any,
  currentFilters: any,
  currentSort: any,
  dispatchFunction: any,
  fetchAssetListFunction: any
) {
  const newFilters = { ...currentFilters, ...event.filtersPayload }
  const isDetailMode = get(newFilters, 'include_history')
  const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
  dispatchFunction(
    fetchAssetListFunction({ filters: newFilters, sort: currentSort, formValues: event.formValues }, 1, pageSize)
  )
}
function resetFilter(
  event: any,
  currentFilters: any,
  currentSort: any,
  dispatchFunction: any,
  fetchAssetListFunction: any
) {
  const newFilters = {
    ...event.filtersPayload,
    include_history: !!currentFilters?.include_history,
    valid_at: currentFilters?.valid_at,
  }

  const isDetailMode = get(newFilters, 'include_history')
  const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
  dispatchFunction(
    fetchAssetListFunction({ filters: newFilters, sort: currentSort, formValues: event.formValues }, 1, pageSize)
  )
}

const bulkChangeStartedUsage = async (
  values: any,
  currentMonthlyClosing: any,
  setLoadingBulkChange: (value: boolean) => void,

  startedUsagePolling: any,
  confirmModal: any,
  biids: any
) => {
  setErrorsBulkChange({})
  setLoadingBulkChange(true)
  const checkClosingMonth = verifyClosingMonthStartUsageEvent({ assetEvent: values, currentMonthlyClosing })

  const callApi = async () => {
    try {
      const res = (await FixedAssetsService.bulkChangeStartUsageUpdate({ ...values, biids })) as any
      res?.data?.id && startedUsagePolling.setJobId(res.data.id)
    } catch (error) {
    } finally {
      setLoadingBulkChange(false)
    }
  }

  if (checkClosingMonth) {
    confirmModal.open({}).then(async (isOK: boolean) => {
      if (isOK) {
        await callApi()
      }
    })
  } else {
    await callApi()
  }
  setLoadingBulkChange(false)
}

const bulkChangeAssetTypeUpdate = async (
  values: any,
  setLoadingBulkChange: (params?: any) => void,
  biids: string[],
  assetTypeModificationPolling: any
) => {
  setErrorsBulkChange({})
  setLoadingBulkChange(true)
  try {
    const res = (await FixedAssetsService.bulkChangeAssetTypeUpdate({ ...values, biids })) as any
    res?.data?.id && assetTypeModificationPolling.setJobId(res.data.id)
  } catch (error) {
  } finally {
    setLoadingBulkChange(false)
  }
}
const bulkChangeMovement = async (
  values: any,
  setLoadingBulkChange: (params?: any) => void,
  biids: string[],
  movementPolling: any
) => {
  setLoadingBulkChange(true)
  setErrorsBulkChange({})
  try {
    const res = (await FixedAssetsService.bulkChangeMoveUpdate({ ...values, biids })) as any
    res?.data?.id && movementPolling.setJobId(res.data.id)
  } catch (error) {
  } finally {
    setLoadingBulkChange(false)
  }
}

const bulkChangesRetirement = async (
  values: any,
  setLoadingBulkChange: (params?: any) => void,
  biids: string[],
  retirementPolling: any
) => {
  setErrorsBulkChange({})
  setLoadingBulkChange(true)
  try {
    const res = (await FixedAssetsService.bulkChangeRetireUpdate({ ...values, biids })) as any
    res.data.id && retirementPolling.setJobId(res.data.id)
  } catch (error) {
  } finally {
    setLoadingBulkChange(false)
  }
}

export const hookFnc = {
  submitFilter,
  resetFilter,
  bulkChangeStartedUsage,
  bulkChangeAssetTypeUpdate,
  bulkChangeMovement,
  bulkChangesRetirement,
}
