import { faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { RenderFormProps } from 'components/molecules/Form'
import { List as ImmutableList } from 'immutable'
import React, { useRef } from 'react'

import { Empty, EmptyWrapperClass, List, ListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

interface AssetStatusContentProps {
  name: string
  selectedItems: any[]
  formModule: RenderFormProps
  onFormSubmit: (event: any) => void
}

const AssetStatusContent = ({ name, selectedItems, formModule, onFormSubmit }: AssetStatusContentProps) => {
  const containerRef = useRef(null)
  const { handleSubmit } = formModule

  const handleFormSubmit = () => handleSubmit(onFormSubmit, onFormSubmit)()

  const handleClick = (value: any) => {
    const immutableSelectedItems = ImmutableList(selectedItems)
    const deletedIndex = immutableSelectedItems.findIndex((selectedValue: any) => selectedValue.value === value)
    if (deletedIndex >= 0) {
      formModule.setValue(name, immutableSelectedItems.splice(deletedIndex, 1).toArray())
      const submitFormValues = {
        [name]: immutableSelectedItems.splice(deletedIndex, 1).toArray(),
      }
      formModule.setValue('submitFormValues', submitFormValues)
      handleFormSubmit()
    }
  }

  return !selectedItems || selectedItems.length === 0 ? (
    <EmptyWrapperClass ref={containerRef}>
      <div className="label">
        <Empty>{t('empty')}</Empty>
      </div>
      <Tooltip
        overlayStyle={{ width: 300 }}
        placement="top"
        arrowPointAtCenter
        content={t('asset_status.default_message')}
      >
        <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 16, color: '7d8290', cursor: 'pointer' }} />
      </Tooltip>
    </EmptyWrapperClass>
  ) : (
    <List>
      {selectedItems.map((item: any) => {
        return (
          <ListItem key={item.key}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ marginRight: 6, cursor: 'pointer' }}
              onClick={() => handleClick(item.value)}
            />
            {item.label}
          </ListItem>
        )
      })}
    </List>
  )
}

export default AssetStatusContent
