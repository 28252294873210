import { messageSuccess } from 'aa_common/front-end'
import { useLongPolling } from 'hooks/useLongPolling'
import i18n from 'i18n'
import get from 'lodash/get'
import {
  assetListPageStore,
  rowSelectSliceAssetList,
  setErrorBiids,
  setErrorsBulkChange,
} from 'pages/assets/AssetListPage/store'
import { useDispatch } from 'react-redux'

import { DEFAULT_PAGE_SIZE, DETAIL_MODE_PAGE_SIZE } from '../constant'
import { useFetchAssetListCallback } from './use-fetch-asset-list-callback'
import { useFiltersSort } from './use-filters-sort'
import { useGetSelectedFa } from './use-get-selected-fa'

export const useBulkChangeLongPooling = () => {
  const { biids } = useGetSelectedFa()
  const dispatch = useDispatch()
  const fetchAssetList = useFetchAssetListCallback()
  const { filters, sort, assetCollectionViewSelector, assetCollectionViewFilterValues } = useFiltersSort()

  const rowSelectSliceAssetListStore = rowSelectSliceAssetList.useSlice(state => state.data)
  const { bulkChangeEvent, errorsBulkChange } = rowSelectSliceAssetListStore

  const pageSize = get(assetCollectionViewSelector.filters, 'include_history')
    ? DETAIL_MODE_PAGE_SIZE
    : DEFAULT_PAGE_SIZE

  const onError = (errorBiids: string[], errors?: any) => {
    errorBiids && setErrorBiids(errorBiids)
    errors && setErrorsBulkChange(errors)
  }

  const onSuccess = () => {
    messageSuccess(i18n.t('common.messages.created_success'))
    assetListPageStore.setBulkChangeEvent()
    assetListPageStore.unSelectAllRowOnPage(biids)

    dispatch(fetchAssetList({ filters, sort, formValues: assetCollectionViewFilterValues }, 1, pageSize))
  }

  const movementPolling = useLongPolling('movement', { onError, onSuccess })
  const retirementPolling = useLongPolling('retirement', { onError, onSuccess })
  const startedUsagePolling = useLongPolling('startedUsagePolling', { onError, onSuccess })
  const assetTypeModificationPolling = useLongPolling('assetTypeModificationPolling', { onError, onSuccess })

  return {
    movementPolling,
    retirementPolling,
    startedUsagePolling,
    assetTypeModificationPolling,
    bulkChangeEvent,
    errorsBulkChange,
    biids,
    onSuccess,
    onError,
  }
}
