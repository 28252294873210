import { UnborderCollapse } from 'aa_common/front-end/components'
import React from 'react'

import { renderDepreciationConditionField } from '../../AssetEventSellForm/helper'
import { Group } from '../../events/styles'

const { Item } = UnborderCollapse
type Props = {
  ledgerSettings: any[]
  assetItem: any
  values: any
  bookInformationItems: any[]
  isTaxMethodExcluded: boolean
  t: (key: string) => string
  contentItemWidth: number
  itemWidth: string
}

const BookValueFields: React.FC<Props> = ({
  ledgerSettings,
  assetItem,
  values,
  bookInformationItems,
  isTaxMethodExcluded,
  t,
  contentItemWidth,
  itemWidth,
}) => (
  <>
    <Item label={t('book_value_date')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
      <Group>
        {renderDepreciationConditionField(
          ledgerSettings,
          'date',
          assetItem,
          values,
          bookInformationItems,
          isTaxMethodExcluded
        )}
      </Group>
    </Item>
    <Item label={t('book_value_sale')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
      <Group>
        {renderDepreciationConditionField(
          ledgerSettings,
          'book_value',
          assetItem,
          values,
          bookInformationItems,
          isTaxMethodExcluded
        )}
      </Group>
    </Item>
  </>
)

export default BookValueFields
