import { Spin } from 'aa_common/front-end/antd'
import * as depreciationComparisonApi from 'api/app/depreciationComparison'
import { yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import useResource from 'lib/hooks/useResource'
import React, { useEffect, useState } from 'react'

import { Table } from '../styles'

const t = loadNs(['components/organisms/assets/asset-detail', 'common'])

const MoneyTemplate = (data: any) => {
  return <span>{yen(data)}</span>
}

const settings = {
  align: 'right',
  width: 120,
  render: MoneyTemplate,
}

const columns = [
  {
    className: 'no-border-right',
    children: [
      {
        dataIndex: 'term',
        width: 130,
        className: 'no-border-right',
        render: (term: any) => {
          return (
            <span>{`${t('format.year_term', { year: term.year, term: term.period })} ${
              !term.end_date ? '〜' : ''
            }`}</span>
          )
        },
      },
    ],
  },
  {
    className: 'border-double-left',
    title: t('depreciationComparisonTable.accounting_ledger'),
    children: [
      {
        ...settings,
        title: t('depreciationComparisonTable.depreciation_amount'),
        dataIndex: 'depreciation_amt',
        className: 'border-double-left',
      },
    ],
  },
  {
    title: t('depreciationComparisonTable.tax_ledger'),
    children: [
      {
        ...settings,
        title: t('depreciationComparisonTable.depreciation_limit_amount'),
        dataIndex: 'depreciation_limit_amt',
      },
    ],
  },
  {
    title: t('depreciationComparisonTable.subtraction'),
    children: [
      {
        ...settings,
        title: t('depreciationComparisonTable.over_depreciation_amount'),
        dataIndex: 'over_depreciation_amt',
        className: 'no-border-right',
      },
      {
        ...settings,
        title: t('depreciationComparisonTable.under_depreciation_amount'),
        dataIndex: 'under_depreciation_amt',
      },
    ],
  },
  {
    title: t('depreciationComparisonTable.depreciation_excess_amount'),
    children: [
      {
        ...settings,
        title: t('depreciationComparisonTable.accumulated_over_depreciation_amount'),
        dataIndex: 'accumulated_over_depreciation_amt',
        className: 'no-border-right',
      },
      {
        ...settings,
        title: t('depreciationComparisonTable.current_loss_allowance_amount'),
        dataIndex: 'loss_allowance',
        className: 'no-border-right',
      },
      {
        ...settings,
        title: t('depreciationComparisonTable.accumulated_depreciation_amount_next_period'),
        dataIndex: 'accumulated_depreciation_amt_next_period',
      },
    ],
  },
]

interface IDepreciationComparisonTable {
  biid: string | undefined
}

const DepreciationComparisonTable = ({ biid }: IDepreciationComparisonTable) => {
  const [depreciationComparison, getDepreciationComparisonData] = useResource(depreciationComparisonApi.get)
  const [depreciationComparisonData, setDepreciationComparisonData] = useState<any>()

  useEffect(() => {
    if (biid) {
      getDepreciationComparisonData(biid)
    }
  }, [biid]) //eslint-disable-line

  useEffect(() => {
    if (depreciationComparison?.data?.length > 0) {
      const dataHandled = depreciationComparison.data.map((item: any, index: number) => ({ ...item, key: index }))
      setDepreciationComparisonData(dataHandled)
    }
  }, [depreciationComparison])

  return (
    <Spin loading={depreciationComparison.isLoading}>
      <Table dataSource={depreciationComparisonData} columns={columns} bordered pagination={false} />
    </Spin>
  )
}

export default DepreciationComparisonTable
