import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { NumberInput } from 'components/atoms'
import { BasicForm } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import React from 'react'

import { getServiceLifeMinValue } from './helpers'
import { Error } from './styles'

const { Item } = BasicForm

const t = loadNs([
  'common',
  'components/organisms/settings/asset-category-list',
  'components/organisms/assets/asset-form',
  'components/organisms/settings/asset-category-form',
])

type ServiceLifeProps = {
  depreciationCode?: any
}

const shouldRender = (depreciationCode: any) => {
  return ![
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
  ].includes(depreciationCode)
}

export const ServiceLife = ({ depreciationCode }: ServiceLifeProps) => {
  const { errors } = useFormikContext()
  const isDisabledInput = depreciationCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
  const minValue = getServiceLifeMinValue(depreciationCode)

  if (!shouldRender(depreciationCode)) return null

  return (
    <Item label={t('service_life')}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NumberInput
          name="service_life"
          min={minValue}
          max={100}
          maxLength={3}
          disabled={isDisabledInput}
          style={{ width: 60, marginRight: 5 }}
          isShowError={false}
        />
        <label>{t('units.year')}</label>
      </div>
      <Error>
        <label>{getIn(errors, 'service_life')}</label>
      </Error>
    </Item>
  )
}
