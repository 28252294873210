import React from 'react'

import { Wrapper } from './styles'

type Props = {
  color?: string
}

const LinkIcon: React.FC<Props> = ({ color = '#3B7DE9' }) => {
  return (
    <Wrapper>
      <svg width="16" height="9" viewBox="0 0 16 9" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M1.52 4.28418C1.52 2.91618 2.632 1.80418 4 1.80418H7.2V0.28418H4C1.792 0.28418 0 2.07618 0 4.28418C0 6.49218 1.792 8.28418 4 8.28418H7.2V6.76418H4C2.632 6.76418 1.52 5.65218 1.52 4.28418ZM4.8 5.08418H11.2V3.48418H4.8V5.08418ZM12 0.28418H8.8V1.80418H12C13.368 1.80418 14.48 2.91618 14.48 4.28418C14.48 5.65218 13.368 6.76418 12 6.76418H8.8V8.28418H12C14.208 8.28418 16 6.49218 16 4.28418C16 2.07618 14.208 0.28418 12 0.28418Z" />
      </svg>
    </Wrapper>
  )
}

export default LinkIcon
