import { DATE_FORMAT_RFC } from 'common/constants'
import { tagListSlice } from 'containers/master/tags/store'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { assetListPageStore } from 'pages/assets/AssetListPage/store'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import {
  selectAssetDetailPage,
  selectAssetsCollectionFormValues,
  selectAssetsCollectionView,
} from 'store/asset/selectors'
import { fetchCauseList } from 'store/settings/cause/actions'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'

import { DEFAULT_PAGE_SIZE, DETAIL_MODE_PAGE_SIZE } from '../constant'
import { useFetchAssetListCallback } from './use-fetch-asset-list-callback'

export const useFetchAssetData = () => {
  const fetchAssetList = useFetchAssetListCallback()
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const assetCollectionViewFilterValues = useSelector(selectAssetsCollectionFormValues, isEqual)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const { causes } = useSelector(selectAssetDetailPage, isEqual)

  const dispatch = useDispatch()

  const isDetailMode = get(assetCollectionViewSelector.filters, 'include_history')
  const pageSize = isDetailMode ? DETAIL_MODE_PAGE_SIZE : DEFAULT_PAGE_SIZE
  useEffect(() => {
    const filterValidAt = get(assetCollectionViewSelector.filters, 'valid_at', null)
    const todayDate = moment().format(DATE_FORMAT_RFC)
    const validAtDate = filterValidAt || todayDate

    const filterPayload = { valid_at: validAtDate, ...assetCollectionViewSelector.filters }
    const sortPayload =
      !assetCollectionViewSelector.sort || assetCollectionViewSelector.sort.length === 0
        ? ['-code', '-branch_code']
        : assetCollectionViewSelector.sort

    dispatch(
      fetchAssetList(
        { filters: filterPayload, sort: sortPayload, formValues: assetCollectionViewFilterValues },
        assetCollectionViewSelector.page,
        pageSize
      )
    )

    assetListPageStore.resetSelectRows()

    tagListSlice.setFilters({}, true)

    dispatch(fetchCauseList())
    dispatch(fetchLedgerSettingsList())
  }, [dispatch])

  const hasIncludeHistory = !!assetCollectionViewSelector.filters?.include_history

  return {
    assetCollectionViewSelector,
    assetCollectionViewFilterValues,
    currentMonthlyClosing,
    causes,
    hasIncludeHistory,
    isDetailMode,
  }
}
