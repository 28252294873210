import { Checkbox as AntdCheckbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { getIn, useFormikContext } from 'formik'
import React, { CSSProperties } from 'react'

import { Wrapper } from './styles'

type Props = {
  name: string
  style?: CSSProperties
  disabled?: boolean
  afterChanged?: (value: boolean) => void
}

const Checkbox: React.FC<Props> = ({ name = '', children, style, disabled = false, afterChanged }) => {
  const { values, handleChange } = useFormikContext()

  const handleCheckChange = (e: CheckboxChangeEvent) => {
    handleChange(e)
    afterChanged && afterChanged(e.target.checked)
  }

  return (
    <Wrapper>
      <AntdCheckbox
        checked={getIn(values, name)}
        name={name}
        onChange={handleCheckChange}
        style={style}
        disabled={disabled}
      >
        {children}
      </AntdCheckbox>
    </Wrapper>
  )
}

export default Checkbox
