import classNames from 'classnames'
import React from 'react'
import { components, OptionProps } from 'react-select'

import Text from '../Text'
import { OptionWrapperClass } from './styles'

const Option = (optionProps: React.PropsWithChildren<OptionProps<any, false, any>>) => {
  const { children, className, ...rest } = optionProps
  const { isDisabled, isFocused, isSelected } = rest

  return (
    <components.Option
      className={classNames(className, OptionWrapperClass, {
        disabled: isDisabled,
        selected: isSelected,
        focused: isFocused,
      })}
      {...rest}
    >
      <Text text={children as string} />
    </components.Option>
  )
}

export default Option
