import { Map } from 'immutable'

import { SessionActionTypes } from './action.model'
import {
  CREATE_SESSION_FAILURE,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  FETCH_CURRENT_OFFICE_FAILURE,
  FETCH_CURRENT_OFFICE_REQUEST,
  FETCH_CURRENT_OFFICE_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_PERMISSION_REQUEST,
  HANDLE_FETCH_USER_PERMISSION,
  ON_WEBSOCKET_CLOSED,
  ON_WEBSOCKET_CONNECT,
  ON_WEBSOCKET_DISCONNECT,
  ON_WEBSOCKET_OPEN,
  RETURN_FLAG_MESSAGE_NO_VALID_OFFICE,
  SET_SEEN_NOTIFICATIONS,
  STATUS_CLOSED,
  STATUS_CONNECT,
  STATUS_DISCONNECT,
  STATUS_OPEN,
} from './actions'

export const initialState = Map({
  errors: [],
  loading: false,
  currentUser: Map({
    data: null,
    errors: [],
    loading: false,
  }),
  currentOffice: Map({
    data: null,
    loading: false,
  }),
  user: Map({
    data: null,
    loading: false,
  }),
  userPermission: Map({
    data: null,
    loading: false,
  }),
  notifications: Map({
    data: [],
    loading: false,
    seenIds: [],
  }),
  flag_invalid_office: false,
  wsConnectionStatus: STATUS_CLOSED,
})

export default (state = initialState, action: SessionActionTypes) => {
  switch (action.type) {
    case CREATE_SESSION_REQUEST:
      return state.set('loading', true)
    case CREATE_SESSION_SUCCESS:
      return state.set('loading', false)
    case CREATE_SESSION_FAILURE:
      return state.set('loading', false).set('errors', action.payload)
    case FETCH_USER_INFO_REQUEST:
      return state.setIn(['user', 'loading'], true)
    case FETCH_USER_INFO_SUCCESS:
      return state.setIn(['user', 'loading'], false).setIn(['user', 'data'], action.userInfo)
    case FETCH_CURRENT_USER_REQUEST:
      return state.setIn(['currentUser', 'loading'], true)
    case FETCH_CURRENT_USER_SUCCESS:
      return state.setIn(['currentUser', 'loading'], false).setIn(['currentUser', 'data'], action.payload)
    case FETCH_CURRENT_USER_FAILURE:
      return state.setIn(['currentUser', 'loading'], false).setIn(['currentUser', 'errors'], action.payload)
    case FETCH_CURRENT_OFFICE_REQUEST:
      return state.setIn(['currentOffice', 'loading'], true)
    case FETCH_CURRENT_OFFICE_SUCCESS:
      return state.setIn(['currentOffice', 'loading'], false).setIn(['currentOffice', 'data'], action.payload)
    case FETCH_CURRENT_OFFICE_FAILURE:
      return state.setIn(['currentOffice', 'loading'], false)
    case RETURN_FLAG_MESSAGE_NO_VALID_OFFICE:
      return state.set('flag_invalid_office', true)
    case FETCH_USER_PERMISSION_REQUEST:
      return state.setIn(['userPermissions', 'loading'], true)
    case HANDLE_FETCH_USER_PERMISSION:
      return state.setIn(['userPermissions', 'data'], action.payload).setIn(['userPermissions', 'loading'], false)
    case FETCH_NOTIFICATIONS_REQUEST:
      return state.setIn(['notifications', 'loading'], true)
    case FETCH_NOTIFICATIONS_FAILURE:
      return state.setIn(['notifications', 'loading'], false).setIn(['notifications', 'errors'], action.payload)
    case FETCH_NOTIFICATIONS_SUCCESS:
      return state.setIn(['notifications', 'loading'], false).setIn(['notifications', 'data'], action.payload)
    case SET_SEEN_NOTIFICATIONS:
      return state.setIn(
        ['notifications', 'seenIds'],
        action.payload.map(n => n.id)
      )
    case ON_WEBSOCKET_CONNECT:
      return state.set('wsConnectionStatus', STATUS_CONNECT)
    case ON_WEBSOCKET_OPEN:
      return state.set('wsConnectionStatus', STATUS_OPEN)
    case ON_WEBSOCKET_DISCONNECT:
      return state.set('wsConnectionStatus', STATUS_DISCONNECT)
    case ON_WEBSOCKET_CLOSED:
      return state.set('wsConnectionStatus', STATUS_CLOSED)
    default:
      return state
  }
}
