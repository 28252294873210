import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComplexTable } from 'aa_common/front-end/antd'
import { ConfirmModal, LinkLabel } from 'aa_common/front-end/components'
import { ASSET_CHANGE_SITUATION_TYPES, CONFIRM_TYPES, URL_REOPEN_MONTHLY_GUIDE } from 'common/constants'
import { formatDate, hasEventOccurred } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { isImmutableEvent } from 'components/organisms/assets/helper/isImmutableEvent'
import useModal from 'hooks/useModalHook'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { deleteAssetItem, setIsCreatedAssetEvent } from 'store/asset/actions'
import {
  selectAssetDetailPage,
  selectAssetItemLoading,
  selectEventHistories,
  selectEventHistoriesLoading,
  selectEventHistoryErrors,
  selectEventHistoryIsCreatedSuccess,
} from 'store/asset/selectors'

import ConfirmMessage from './components/confirm-message'
import { buildEventHistoryColumns, EventHistoryActionType } from './settings'
import { Wrapper } from './styles'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-detail', 'common'])

interface Props {
  onUpdateEvent: (eventData: any) => void
  isHighlightLastestEvent?: boolean
  setIsHighlightLastestEvent?: (value: boolean) => void
}

const handleOnRow = (record: any, isHighlightLastestEvent: boolean, lastRecord: any) => {
  if (!isHighlightLastestEvent) return null

  if (get(lastRecord, 'id') === get(record, 'id')) {
    return { className: 'highlight' }
  }
}

const EventHistory = ({ onUpdateEvent, isHighlightLastestEvent, setIsHighlightLastestEvent }: Props) => {
  useNsReady()
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useModal()

  const [deletingEvent, setDeletingEvent] = useState(null)
  const [isShowConfirmMessage, setIsShowConfirmMessage] = useState<boolean>(false)

  const eventHistories = useSelector(selectEventHistories, isEqual)
  const loading = useSelector(selectEventHistoriesLoading, isEqual)
  const eventHistoryErrors = useSelector(selectEventHistoryErrors, isEqual)
  const isCreatedSuccess = useSelector(selectEventHistoryIsCreatedSuccess, isEqual)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const { itemHistoryLoading } = useSelector(selectAssetDetailPage, isEqual)
  const assetItemLoading = useSelector(selectAssetItemLoading, isEqual)
  const isAcquisitionEvent =
    get(deletingEvent, ['change_situation', 'code']) === ASSET_CHANGE_SITUATION_TYPES.ACQUISITION
  const lastRecord = useMemo(() => last(eventHistories), [eventHistories])

  const handleEventHistoryActionClick = useCallback(
    (record: any, actionType: EventHistoryActionType) => {
      const key = (
        {
          [ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED]: 'usage_start',
          [ASSET_CHANGE_SITUATION_TYPES.MOVEMENT]: 'movement',
          [ASSET_CHANGE_SITUATION_TYPES.RETIREMENT]: 'retirement',
          [ASSET_CHANGE_SITUATION_TYPES.SELL]: 'sell',
          [ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT]: 'impairment',
          [ASSET_CHANGE_SITUATION_TYPES.DIVIDING]: 'dividing',
        } as Record<any, string>
      )[record?.change_situation?.code]

      if (key && isImmutableEvent(record, currentMonthlyClosing)) {
        modal.confirm({
          type: CONFIRM_TYPES.CONFIRM,
          title: t('asset_event_history.title', { context: actionType }),
          className: 'prevent-action-modal',
          message: (
            <div>
              {t(`asset_event_history.${key}_message`, { context: actionType })}
              <LinkLabel target="_blank" href={URL_REOPEN_MONTHLY_GUIDE}>
                {t('actions.click_here_for_details')}
                <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faExternalLinkAlt} />
              </LinkLabel>
            </div>
          ),
          showCancelButton: false,
          closable: true,
          maskClosable: false,
        })
      } else {
        const eventHandler = {
          correct: onUpdateEvent,
          delete: setDeletingEvent,
        }

        eventHandler[actionType]?.(record)
      }
    },
    [currentMonthlyClosing, setDeletingEvent, onUpdateEvent]
  ) // eslint-disable-line

  const submitDeleteEvent = () => {
    const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)

    deletingEvent &&
      dispatch(
        deleteAssetItem(Object.assign({}, deletingEvent, { number_of_events: eventHistories.length }), hasDividingEvent) // eslint-disable-line
      )
    isAcquisitionEvent && history.push('/assets')
    setIsHighlightLastestEvent && setIsHighlightLastestEvent(false)
  }

  const cancelDelete = () => {
    setDeletingEvent(null)
  }

  useEffect(() => {
    setDeletingEvent(null)
  }, [eventHistories, eventHistoryErrors]) // eslint-disable-line

  useEffect(() => {
    setIsHighlightLastestEvent && setIsHighlightLastestEvent(false)
    isCreatedSuccess && setIsShowConfirmMessage(true)
  }, [isCreatedSuccess])

  const handleAfterCloseConfirmMessage = () => {
    setIsShowConfirmMessage(false)
    dispatch(setIsCreatedAssetEvent(false))
  }

  const eventHistoryTableColumnSettings = useMemo(() => {
    return buildEventHistoryColumns(lastRecord, handleEventHistoryActionClick)
  }, [lastRecord, handleEventHistoryActionClick])

  const modalMessage = isAcquisitionEvent
    ? t('messages.confirm_delete', { name: get(deletingEvent, 'name') })
    : t('msg_confirm_remove_event', {
        date: formatDate(get(deletingEvent, 'valid_from')),
        eventType: get(deletingEvent, ['change_situation', 'name_jp']),
      })

  return (
    <Wrapper>
      {eventHistories && eventHistories.length > 0 && (
        <ComplexTable
          loading={loading}
          columns={eventHistoryTableColumnSettings}
          actions={false}
          enableSelectRows={false}
          data={eventHistories}
          onRow={record => handleOnRow(record, isHighlightLastestEvent ?? false, lastRecord)}
        />
      )}

      <ConfirmModal
        isLoading={itemHistoryLoading || loading || assetItemLoading}
        className="delete-event-modal"
        message={modalMessage}
        visible={!!deletingEvent}
        onOK={submitDeleteEvent}
        onCancel={cancelDelete}
        okText={t(isAcquisitionEvent ? 'actions.remove' : 'actions.delete')}
      />
      <ConfirmMessage
        isShow={isShowConfirmMessage}
        onClick={() => {
          setIsHighlightLastestEvent && setIsHighlightLastestEvent(true)
        }}
        onClose={handleAfterCloseConfirmMessage}
      />
    </Wrapper>
  )
}

export default EventHistory
