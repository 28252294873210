import styled from '@emotion/styled/macro'
import { StyledComplexTable } from 'aa_common/front-end/shared/styles'
import { Image, Radio as AntdRadio } from 'antd'

export const Wrapper = styled.div`
  td.asset-table-money {
    text-align: right;
  }

  ${StyledComplexTable} {
    .ant-table {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .ant-table-thead > tr > th {
      font-size: 13px;

      &.ant-table-selection-column {
        padding: 0;
        .ant-table-selection {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          > .ant-checkbox-wrapper {
            display: none;
          }
        }
      }
    }

    &.fixed-asset-detail-mode {
      .ant-table-tbody > tr:not(.ant-table-row-selected) {
        background: #fafafa;
      }
      .ant-table-row {
        &.ant-table-row-level-0 {
          &:not(.ant-table-row-selected) {
            > .ant-table-cell {
              background-color: #fafafa;
            }
          }
          &.row-hovered {
            &.ant-table-row-selected {
              > .ant-table-cell {
                background-color: #eaf2fd;
              }
            }
            > .ant-table-cell {
              background-color: #f5f5f5;
            }
          }
          &.row-highlight {
            > .ant-table-cell {
              background-color: #eaf2fd;
            }
          }
        }
      }
    }

    .ant-table-tbody > tr > td {
      cursor: pointer;
      &.fixed-asset-photo {
        padding: 0 0 0 5px;
      }

      &.ant-table-selection-column {
        padding: 0;
        .selection-cell {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          > .ant-checkbox-wrapper {
            padding: 0;
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .ant-table-row {
      &.row-hovered {
        &.ant-table-row-selected > .ant-table-cell {
          background-color: #eaf2fd;
        }
        > .ant-table-cell {
          background-color: #f5f5f5;
        }
      }

      &.row-highlight {
        > .ant-table-cell {
          background-color: #eaf2fd;
        }
      }
    }

    .ant-table-row-expand-icon {
      display: none;
    }

    .render-event-info {
      td {
        padding: 10px 5px 10px 0;
      }
    }
  }

  .col-fixed-right {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const FixedAssetTableImage = styled(Image)`
  max-width: 50px;
`

export const Radio = {
  Group: styled(AntdRadio.Group)`
    margin-right: 10px;
  `,
  Button: styled(AntdRadio.Button)`
    font-size: 16px;
    padding: 0 10px;

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #7c8291;
      color: white;
      border-color: #7c8291;
    }

    &.ant-radio-button-wrapper-checked:not(
        [class*=' ant-radio-button-wrapper-disabled']
      ).ant-radio-button-wrapper:first-of-type {
      border-right-color: transparent;
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: transparent;
    }

    &:first-of-type {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
    &:last-of-type {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
  `,
}

export const TitleStyles = styled.span`
  white-space: pre-line;
`
