import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'aa_common/front-end/antd'
import { RequiredLabel } from 'aa_common/front-end/components'
import { Col, Row } from 'antd'
import React from 'react'

interface LabelFieldProps {
  title: React.ReactNode
  tooltip?: React.ReactNode
  required?: boolean
}

const LabelField = ({ title, tooltip, required }: LabelFieldProps) => {
  return (
    <Row wrap={false} align="middle" justify="space-between">
      <Col className="label-text" flex="auto">
        <Row wrap={false} align="middle">
          <Col className="label-content">{typeof title === 'string' ? <h4>{title}</h4> : title}</Col>
          <Col>
            {tooltip && (
              <Tooltip align={{ offset: [-10, 0] }} content={tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Col>
        </Row>
      </Col>
      {required && (
        <Col className="required_label_wrapper" flex="none" style={{ marginLeft: 5 }}>
          <RequiredLabel />
        </Col>
      )}
    </Row>
  )
}

export default LabelField
