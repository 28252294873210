import { AssetReport } from 'common/models'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'

import { SharedEventView } from './shared-event-view'

type Props = {
  assets: AssetReport[]
}

export const RetirementEventView: React.FC<Props> = ({ assets }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, []) // eslint-disable-line

  return (
    <>
      {assets?.map(asset => (
        <SharedEventView key={asset?.biid} asset={asset} />
      ))}
    </>
  )
}
