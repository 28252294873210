import { Permission, PERMISSIONS } from 'common/constants'

export const handlePermissionByFeatureId = (featureId: number, userPermissions: any): Permission => {
  const permissionValue = userPermissions?.[featureId]
  const permissions = {
    isRead: permissionValue >= PERMISSIONS.READ,
    isCreate: permissionValue < PERMISSIONS.EXECUTE && permissionValue >= PERMISSIONS.CREATE,
    isEdit: permissionValue < PERMISSIONS.EXECUTE && permissionValue >= PERMISSIONS.EDIT,
    isDelete: permissionValue < PERMISSIONS.EXECUTE && permissionValue >= PERMISSIONS.DELETE,
    isExecute: permissionValue >= PERMISSIONS.EXECUTE,
  }

  const isCRU = permissions.isCreate && permissions.isRead && permissions.isEdit

  return {
    ...permissions,
    isCRU,
    isCRUD: isCRU && permissions.isDelete,
  }
}
