import { formatDate, formatDateTime, identifyJournalStatusType, objectToQueryParams, yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { AssetReport } from 'common/models'
import { NoImage } from 'components/atoms'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'
import { Media } from './media'
import { SharedRow } from './shared-event-view'

type Props = {
  assets: AssetReport[]
}

const t = loadNs(['common', 'components/organisms/accounting/monthly-closing-asset-event-list'])
const renderAssetPhoto = (asset: AssetReport) => {
  const { thumbnail_url } = asset?.photos?.[0] || {}
  return !thumbnail_url ? <NoImage /> : <img src={thumbnail_url} alt="" />
}

export const ImpairmentEventView = ({ assets }: Props) => {
  const dispatch = useDispatch()

  const handleAssetClick = useCallback(
    (asset: AssetReport) => {
      const params = objectToQueryParams({ valid_at: asset.impaired_at })
      dispatch(changeLocation(`/assets/${asset.biid}?${params}`))
    },
    [dispatch]
  )

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, [dispatch])

  return (
    <>
      {assets?.map(asset => (
        <AssetDisplay key={asset.id} onClick={() => handleAssetClick(asset)}>
          <AssetDisplayHeader>
            <div className="status">
              <JournalStatus type={identifyJournalStatusType(asset)} />
            </div>
            <div className="photo">{renderAssetPhoto(asset)}</div>
            <div className="asset-code">
              {asset.code}-{asset?.branch_code}
            </div>
            <div className="asset-name">{asset.name}</div>
          </AssetDisplayHeader>
          <AssetDisplayDataRow>
            <div className="short-label">{asset.impaired_at}</div>
            <div className="short-label">{t('impairment')}</div>
            <div className="label">{t('impaired_register_date')}</div>
            <div className="value">{formatDateTime(asset.created_at || '')}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('impairment_cause')}</div>
            <div className="value">{asset.event_cause?.content}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('acquisition_date')}</div>
            <div className="value">{formatDate(asset.acquired_at)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('service_start_date')}</div>
            <div className="value">{formatDate(asset.usage_started_at)}</div>
          </AssetDisplayDataRow>
          <SharedRow asset={asset} isImpairmentEvent />
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_impairment_amount')}</div>
            <div className="value">{yen(asset.accumulated_impaired_amount)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('impairment_remain_book_value')}</div>
            <div className="value">{yen(asset.remaining_book_value)}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('department')}</div>
            <div className="value">{asset.department?.name}</div>
          </AssetDisplayDataRow>
          <AssetDisplayDataRow>
            <div className="label">{t('area')}</div>
            <div className="value">{asset.area?.name}</div>
          </AssetDisplayDataRow>
          <Media asset={asset} />
          <AssetDisplayDataRow className="last">
            <div className="label">{t('impair_by')}</div>
            <div className="value">{asset.user_info?.display_name}</div>
          </AssetDisplayDataRow>
        </AssetDisplay>
      ))}
    </>
  )
}
