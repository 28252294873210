import { Button, messageFn, messageSuccess, Spin } from 'aa_common/front-end/antd'
import { CSVCharsetRadios, ErrorsList } from 'aa_common/front-end/components'
import { message, Upload } from 'antd'
import * as recalculateApi from 'api/app/recalculate'
import history from 'browserHistory'
import { CSV_CHARSET, FEATURES_ID, MAX_IMPORT_FILE_SIZE } from 'common/constants'
import { getRecalculateError, isSupportedCharset, parseImportErrors, validateFileSize } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { CsvCharset } from 'common/models'
import { FixedAssetEventsService } from 'common/open-api'
import { getAxiosSilent } from 'common/open-api/helper'
import useResource from 'lib/hooks/useResource'
import useUserPermission from 'lib/hooks/useUserPermission'
import get from 'lodash/get'
import {
  IMPORT_EXPORT_EVENT_TYPES,
  ImportExportEventTypeSelector,
} from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { importExportEventTypeSelectorSlice, importExportStore } from 'pages/assets/AssetListPage/store'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorsAppCall } from 'store/app/actions'
import { validateImportCsv } from 'store/assetImport/actions'

import { useDownloadFile } from './hooks/useDownloadFile'
import { importBulkChangeAction } from './store'
import { Body, Header, Wrapper } from './styles'

const { t, useNsReady } = getNs([
  'components/molecules/auto-complete-select',
  'common',
  'components/organisms/assets/asset-import-form',
  'components/molecules/modals/import-modal',
  'pages/assets',
])

const { Dragger } = Upload

const AssetImportForm: React.FC = () => {
  useNsReady()
  const dispatch = useDispatch()
  const { importEventType, templateCharset, downloadTemplateType } = importExportEventTypeSelectorSlice.useSlice()
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [{ data: recalculateHistory }, getRecalculateHistory] = useResource(recalculateApi.getHistory)
  const [isUploading, setIsUploading] = useState(false)

  const beforeUpload = (file: File): boolean => {
    if (!validateFileSize(file, MAX_IMPORT_FILE_SIZE)) {
      message.error(t('msg_file_size_limit'), 2)
    } else {
      setUploadedFile(file)
    }

    return false
  }

  const permissionEventTypeMap = new Map([
    [IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER, useUserPermission(FEATURES_ID.FIXED_ASSETS)],
    [IMPORT_EXPORT_EVENT_TYPES.START_USAGE, useUserPermission(FEATURES_ID.START_USAGE)],
    [IMPORT_EXPORT_EVENT_TYPES.MOVEMENT, useUserPermission(FEATURES_ID.MOVEMENT)],
    [IMPORT_EXPORT_EVENT_TYPES.RETIREMENT, useUserPermission(FEATURES_ID.RETIRE)],
    [IMPORT_EXPORT_EVENT_TYPES.SALE, useUserPermission(FEATURES_ID.SALE)],
    [IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT, useUserPermission(FEATURES_ID.IMPAIRMENT)],
    [IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION, useUserPermission(FEATURES_ID.TYPE_MODIFICATION)],
  ])

  const isNotPermissionbyEvent = (importEventType: number | null): boolean => {
    if (importEventType !== null && permissionEventTypeMap.has(importEventType)) {
      const permissions = permissionEventTypeMap.get(importEventType)?.permissions
      return !!(permissions && !permissions.isCRUD && !permissions.isCRU)
    }
    return false
  }

  const [preImportErrs, setPreImportErrs] = useState<string | string[] | null>()
  const handleSubmit = async () => {
    setPreImportErrs(null)
    if (importEventType && isNotPermissionbyEvent(importEventType)) {
      const eventTypes = new Map([
        [IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER, t('fixed_asset_ledger')],
        [IMPORT_EXPORT_EVENT_TYPES.START_USAGE, t('start_usage')],
        [IMPORT_EXPORT_EVENT_TYPES.MOVEMENT, t('movement')],
        [IMPORT_EXPORT_EVENT_TYPES.RETIREMENT, t('retirement')],
        [IMPORT_EXPORT_EVENT_TYPES.SALE, t('sale')],
        [IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT, t('impairment')],
        [IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION, t('asset_type_modification')],
      ])
      setPreImportErrs(t('msg_not_permission', { event_name: eventTypes.get(importEventType) }))
      return
    }
    if (uploadedFile) {
      await hookFnc.handleSubmit(setPreImportErrs, uploadedFile, setIsUploading, importEventType, dispatch)
    }
  }
  useEffect(() => {
    getRecalculateHistory()
    const recalculateError = getRecalculateError(recalculateHistory)
    recalculateError && dispatch(showErrorsAppCall(recalculateError))
  }, []) //eslint-disable-line

  useEffect(() => {
    importExportStore.setTemplateCharset(CSV_CHARSET.UTF8)
  }, [])

  const { downloadFile, baseLink } = useDownloadFile(downloadTemplateType, templateCharset)

  return (
    <Wrapper>
      {preImportErrs && <ErrorsList errors={preImportErrs} />}
      <Header>{t('header_1')}</Header>
      <Body>
        <div style={{ marginTop: 0 }} className="bold">
          {t('precaution')}
        </div>
        <div style={{ whiteSpace: 'pre-wrap', marginTop: 5 }}>{t('precaution_content')}</div>
        <>
          <ImportExportEventTypeSelector
            name="downloadTemplateType"
            onChange={(downloadTemplateType: number) => importExportStore.setDownloadTemplateType(downloadTemplateType)}
            tooltipOverlayInnerStyle={{ width: 355 }}
          />
          <div style={{ marginBottom: -5, fontWeight: 500 }}>{t('character_code')}</div>
          <CSVCharsetRadios
            isShortCharsetLabel
            handleChange={(charset: string) => importExportStore.setTemplateCharset(charset)}
            tooltipOverlayInnerStyle={{ width: 270 }}
          />
          <div className="action">
            <a target="_blank" href={`${baseLink}${downloadFile}`} rel="noreferrer">
              <Button>{t('actions.download')}</Button>
            </a>
          </div>
        </>
      </Body>
      <Header>{t('header_2')}</Header>
      <Body>
        <div className="bold">{t('precaution_import')}</div>
        <div>{t('precaution_import_desc')}</div>
        <ImportExportEventTypeSelector
          name="importEventType"
          tooltip={t('import_tooltip_content')}
          onChange={(importEventType: number) => importExportStore.setImportEventType(importEventType)}
        />
        <div>
          <Spin indicatorSize={40} loading={isUploading}>
            <Upload accept=".csv" fileList={[]} beforeUpload={beforeUpload} multiple={false}>
              <Button color="grey">{t('select_attachment')}</Button>
              <label style={{ marginLeft: 5 }}>{uploadedFile ? uploadedFile.name : t('not_selected')}</label>
            </Upload>
            <Dragger multiple={false} style={{ marginTop: 10, height: 122 }} beforeUpload={beforeUpload} accept=".csv">
              <p className="ant-upload-text">{t('drag_and_drop_here')}</p>
            </Dragger>
            <div className="action">
              <Button onClick={handleSubmit} disabled={!uploadedFile}>
                {t('actions.import')}
              </Button>
            </div>
          </Spin>
        </div>
      </Body>
    </Wrapper>
  )
}

export default AssetImportForm

export const hookFnc = {
  handleSubmitError: (error: any, setPreImportErrs: any, importEventType: any) => {
    const errorResponseData = error?.response?.data
    if (errorResponseData?.data?.events?.length) {
      const unexpectedError = errorResponseData?.data?.unexpected_preview_error?.detail
      unexpectedError && messageFn().error(unexpectedError)

      importBulkChangeAction.addState(errorResponseData.data, importEventType)
      importBulkChangeAction.setErrors(errorResponseData.errors)
      history.push('/assets/import/results')
    } else {
      setPreImportErrs(parseImportErrors(get(error, 'response.data'))?.details)
    }
  },
  checkIsImportFixedAssetLedger: (importEventType: any) => {
    return importEventType !== null && importEventType === IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER
  },
  handleSubmit: async (
    setPreImportErrs: any,
    uploadedFile: File | null,
    setIsUploading: any,
    importEventType: any,
    dispatch: any
  ) => {
    const { handleSubmitError } = hookFnc
    setPreImportErrs(null)

    isSupportedCharset(uploadedFile!).then(async ({ charset, isSupported, notSupportedMsg }) => {
      // @ts-ignore
      notSupportedMsg && setPreImportErrs(notSupportedMsg)
      if (isSupported) {
        setIsUploading(true)
        try {
          if (hookFnc.checkIsImportFixedAssetLedger(importEventType)) {
            dispatch(validateImportCsv(charset as CsvCharset, uploadedFile!))
            setIsUploading(false)
          } else {
            const res = await FixedAssetEventsService.eventsValidateCsvCreate(
              {
                file: uploadedFile!,
                charset: charset as CsvCharset,
                event_type: importEventType as number,
              },
              getAxiosSilent()
            )
            messageSuccess(t('messages.imported_success'))
            importBulkChangeAction.addState(res.data, importEventType)
            history.push('/assets/import/results')
          }
        } catch (error: any) {
          handleSubmitError(error, setPreImportErrs, importEventType)
        } finally {
          setIsUploading(false)
        }
      }
    })
  },
}
