import styled from '@emotion/styled'

export const StyledContentWrapper = styled.div``

export const StyledError = styled.div``

export const TableList = styled.div``

export const StyledWrapper = styled.div`
  .filter-facet-body-layout,
  .filter-facet-template {
    padding: 0;
  }
`
