/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateInternalUserRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterInternalUser,
  PresenterInternalUserListResponse,
  PresenterInternalUserLoginRequest,
  PresenterInternalUserLoginResponse,
  PresenterInternalUserUpdateRequest,
  PresenterInternalUserUpdateResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AWebInternalUser<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get internal-user list
   *
   * @tags AWeb-Internal_User
   * @name InternalUsersList
   * @summary Get the internal-user list
   * @request GET:/admin/internal_users
   */
  internalUsersList = (
    query?: {
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterInternalUserListResponse[], any>({
      path: `/admin/internal_users`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create internal user
   *
   * @tags AWeb-Internal_User
   * @name InternalUsersCreate
   * @summary Create the internal user
   * @request POST:/admin/internal_users
   */
  internalUsersCreate = (body: PresenterCreateInternalUserRequest, params: RequestParams = {}) =>
    this.http.request<PresenterInternalUser, any>({
      path: `/admin/internal_users`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description return internal user info
   *
   * @tags AWeb-Internal_User
   * @name InternalUsersMeList
   * @summary Internal User Info
   * @request GET:/admin/internal_users/me
   */
  internalUsersMeList = (params: RequestParams = {}) =>
    this.http.request<PresenterInternalUser, any>({
      path: `/admin/internal_users/me`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description Update internal user
   *
   * @tags AWeb-Internal_User
   * @name InternalUsersUpdate
   * @summary Update the internal user
   * @request PUT:/admin/internal_users/{id}
   */
  internalUsersUpdate = (id: number | string, body: PresenterInternalUserUpdateRequest, params: RequestParams = {}) =>
    this.http.request<PresenterInternalUserUpdateResponse, any>({
      path: `/admin/internal_users/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete Internal User with id
   *
   * @tags AWeb-Internal_User
   * @name InternalUsersDelete
   * @summary Delete the Internal User
   * @request DELETE:/admin/internal_users/{id}
   */
  internalUsersDelete = (id: number | string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/admin/internal_users/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create session from OAuth Code, nonce for Replay Attacks
   *
   * @tags AWeb-Internal_User
   * @name LoginCreate
   * @summary Login with Google
   * @request POST:/admin/login
   */
  loginCreate = (body: PresenterInternalUserLoginRequest, params: RequestParams = {}) =>
    this.http.request<PresenterInternalUserLoginResponse, any>({
      path: `/admin/login`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
