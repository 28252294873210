import { Col, Row, Slider } from 'antd'
import CurrencyInput from 'components/atoms/v2/CurrencyInput'
import RegexInput from 'components/atoms/v2/RegexInput'
import React, { useEffect, useState } from 'react'

import { CoreRangeInput, RangeProps } from './model'
import { RangeInputStyle, RangeInputWrapper } from './styles'

const RangeInput = ({
  name,
  value,
  type,
  disabled,
  range: { min: minRange, max: maxRange },
  onChange,
  suffix,
}: CoreRangeInput) => {
  const [fromValue, setFromValue] = useState<number>(value ? value[0] : minRange)
  const [toValue, setToValue] = useState<number>(value ? value[1] : maxRange)
  const [fromInputValue, setFromInputValue] = useState<number>(value ? value[0] : minRange)
  const [toInputValue, setToInputValue] = useState<number>(value ? value[1] : maxRange)

  useEffect(() => {
    // run first validation;
    handleValidate(false)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (value) {
      const [from, to] = value
      setFromValue(from)
      setToValue(to)
      setFromInputValue(from)
      setToInputValue(to)
    } else {
      setFromValue(minRange)
      setToValue(maxRange)
      setFromInputValue(minRange)
      setToInputValue(maxRange)
    }
  }, [value, minRange, maxRange])

  const handleValueChange = (event: any) => {
    const [from, to] = event
    setFromValue(from)
    setToValue(to)
    setFromInputValue(from)
    setToInputValue(to)
  }

  const handleAfterChange = (event: any) => {
    const [from, to] = event
    handleValueChange([from, to])
    handleValidate()
  }

  const handleValidate = (triggerOnChange = true) => {
    const fromValue = getValidateValue(Number(fromInputValue), { min: minRange, max: maxRange })
    const toValue = getValidateValue(Number(toInputValue), { min: minRange, max: maxRange })

    const isFromMoreThanTo = fromValue > toValue

    if (isFromMoreThanTo) {
      setFromValue(toValue)
      setToValue(fromValue)
      setTimeout(() => {
        setFromInputValue(toValue)
        setToInputValue(fromValue)
      }, 1)
      onChange && onChange({ from: toValue, to: fromValue, trigger: triggerOnChange })
      return
    }

    setFromValue(fromValue)
    setToValue(toValue)
    setFromInputValue(fromValue)
    setToInputValue(toValue)
    onChange && onChange({ from: fromValue, to: toValue, trigger: triggerOnChange })
  }

  const handleKeyUp = (event: any) => {
    const pressedKey = event.key || String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (pressedKey === 'Enter') {
      handleValidate()
    }
  }

  const handleFromChange = (event: any) => {
    switch (type) {
      case 'currency':
        setFromInputValue(event.floatValue || 0)
        break
      default:
        setFromInputValue(event.target.value)
    }
  }

  const handleToChange = (event: any) => {
    switch (type) {
      case 'currency':
        setToInputValue(event.floatValue || 0)
        break
      default:
        setToInputValue(event.target.value)
    }
  }

  const renderFromInput = () => {
    switch (type) {
      case 'currency': {
        return (
          <CurrencyInput
            disabled={disabled}
            className={RangeInputStyle}
            onKeyUp={handleKeyUp}
            onBlur={() => handleValidate()}
            name={`${name}-range-from-input`}
            value={fromInputValue}
            onChange={handleFromChange}
          />
        )
      }
      default: {
        return (
          <RegexInput
            disabled={disabled}
            className={RangeInputStyle}
            type="number"
            onKeyUp={handleKeyUp}
            onBlur={() => handleValidate()}
            name={`${name}-range-from-input`}
            value={fromInputValue}
            onChange={handleFromChange}
            suffix={suffix || null}
          />
        )
      }
    }
  }

  const renderToInput = () => {
    switch (type) {
      case 'currency': {
        return (
          <CurrencyInput
            disabled={disabled}
            className={RangeInputStyle}
            onKeyUp={handleKeyUp}
            onBlur={() => handleValidate()}
            name={`${name}-range-to-input`}
            value={toInputValue}
            onChange={handleToChange}
          />
        )
      }
      default: {
        return (
          <RegexInput
            disabled={disabled}
            className={RangeInputStyle}
            type="number"
            onKeyUp={handleKeyUp}
            onBlur={() => handleValidate()}
            name={`${name}-range-to-input`}
            value={toInputValue}
            onChange={handleToChange}
            suffix={suffix || null}
          />
        )
      }
    }
  }

  return (
    <RangeInputWrapper>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Slider
            tooltipVisible={false}
            range
            disabled={disabled}
            value={[fromValue, toValue]}
            min={minRange}
            max={maxRange}
            onChange={handleValueChange}
            onAfterChange={handleAfterChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11}>{renderFromInput()}</Col>
        <Col span={2} style={{ textAlign: 'center' }}>
          <span style={{ fontWeight: 'bold', fontSize: 18 }}>~</span>
        </Col>
        <Col span={11}>{renderToInput()}</Col>
      </Row>
    </RangeInputWrapper>
  )
}

function getValidateValue(value: number, range: RangeProps) {
  if (value > range.max) {
    return range.max
  }

  if (value < range.min) {
    return range.min
  }

  return value
}

export default RangeInput
