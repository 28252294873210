import { FormProps } from './models'

export function numberThousandSplit(number?: number) {
  const maxPart = 4
  return [...new Array(maxPart).fill(''), ...`${number || ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').split(',')].slice(
    -maxPart
  )
}

const jpEraYearFormatter = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { year: '2-digit' })
/*
Sample result: formatterJpEraYear(2022) => '令和4'
*/
export function formatterJpEraYear(year?: number | string, month = '01', day = '01') {
  return jpEraYearFormatter.format(new Date(`${year || 2022}-${month}-${day}`)).slice(0, -1)
}

const shortPpEraYearFormatter = new Intl.DateTimeFormat('ja-JP-u-ca-japanese')
export function calcEraYear(year?: number | string, month = '01', day = '01') {
  const formattedStr = shortPpEraYearFormatter.format(new Date(`${year || 2022}-${month}-${day}`))
  return formattedStr.match(/\d+/)?.[0]
}

function calcEraNumber(year: number | string, month = '01', day = '01') {
  /*
  | 番号 |  年号 |
  | ---------  |
  |  1  | 明治  |
  |  2  | 大正  |
  |  3  | 昭和  |
  |  4  | 平成  |
  |  5  | 令和  |
  */
  const eraTable = ['明治', '大正', '昭和', '平成', '令和']
  const jpEraYear = formatterJpEraYear(year, month, day)
  return eraTable.findIndex(era => jpEraYear.startsWith(era)) + 1
}

export function buildAcquiredAtData(acquiredAt: string) {
  let [year, month, day] = Array.from((acquiredAt || '').matchAll(/(\d+)/g)).map(match => match[0])

  if (month === '01' && day === '01') {
    ;[year, month, day] = [`${parseInt(year, 10) - 1}`, '12', '31']
  }

  return {
    era: calcEraNumber(year, month, day),
    year: calcEraYear(year, month, day),
    month: parseInt(month, 10),
  }
}

export const formatFormData = (data: FormProps) => {
  return {
    ...data,
    year: calcEraYear(data.year),
    fixed_assets: (data.fixed_assets || []).map((fixedAsset: any) => ({
      ...fixedAsset,
      acquiredAt: buildAcquiredAtData(fixedAsset.acquired_at),
      acquisitionCost: numberThousandSplit(fixedAsset.acquisition_cost),
    })),
    sub_total: numberThousandSplit(data.sub_total),
    total: numberThousandSplit(data.total),
  }
}

type CellConfig = { x: number; y?: number; w?: number; className?: string }
type MapCellsWithData = CellConfig & { value: string }
export const mapCoordinatesWithData = (values: string[][], yCoordinates: number[], cellConfig: CellConfig[]) => {
  const result: MapCellsWithData[] = []
  if (values?.length) {
    yCoordinates.forEach((y, idx) => {
      cellConfig.forEach(({ x, w, className }, index) => {
        const value = values?.[idx]?.[index]
        result.push({ x, y, w, value, className })
      })
    })
  }
  return result
}
