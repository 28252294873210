import React, { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'

import { CoreMultiSelectPickerItem } from './model'
import CoreMultiSelectPicker from './MultiSelectPicker'

interface MultiSelectPickerProps {
  name: string
  items: CoreMultiSelectPickerItem[]
  control: Control<Record<string, any>>
  listHeight: number
  onChange?: (value: any[]) => void
}

const MultiSelectPicker = ({ name, items, control, listHeight, onChange }: MultiSelectPickerProps) => {
  const memoizedItems = useMemo(() => items, [items])

  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange: controllerOnChange }) => (
        <CoreMultiSelectPicker
          name={name}
          items={memoizedItems}
          value={value}
          listHeight={listHeight}
          onChange={event => {
            controllerOnChange(event)
            onChange?.(event)
          }}
          onDataSourceChanged={event => controllerOnChange(event)}
        />
      )}
    />
  )
}

export default MultiSelectPicker
