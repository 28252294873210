import styled from '@emotion/styled'
import { Table as AntTable } from 'antd'

export const Wrapper = styled.div`
  margin: 15px;
  width: 100%;
`

export const Table = styled(AntTable)`
  .ant-table {
    border: 1px solid #d4d8dd;
    box-sizing: border-box;
    border-radius: 4px;

    .ant-table-cell {
      padding: 0 15px;
      height: 55px;
      background: #ffffff;

      &.border-double-left {
        border-left: 3px double #d4d8dd;
      }

      &.border-left {
        border-left: 1px solid #d4d8dd;
      }

      &.journal-actions {
        padding: 0;
        vertical-align: middle;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        font-weight: 500;

        &.padding-5 {
          padding: 5px;
        }

        &.align-center {
          text-align: center;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        &.odd-num-journal {
          background: #f7f7f7;
        }

        &.even-num-journal {
          background: #fff;
        }
      }

      .ant-table-row {
        &.row-error {
          td {
            background-color: #ffeeeb;
          }
        }
      }
    }
  }
`
