import { getIn, useFormikContext } from 'formik'
import React, { CSSProperties, useEffect, useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  maxLength?: number
  name: string
  rows?: number
  style?: CSSProperties
  placeholder?: string
}

const CTextArea = ({ maxLength = 1000, name, rows = 4, style, placeholder = '' }: Props) => {
  const { values, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name))
  const [previousValue, setPreviousValue] = useState(getIn(values, name))
  const fieldValue = getIn(values, name)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  const handleBlur = () => {
    if (previousValue !== value) {
      setPreviousValue(value)
      setFieldValue(name, value)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    setValue(fieldValue)
  }, [fieldValue])

  return (
    <Wrapper
      value={value}
      name={name}
      rows={rows}
      maxLength={maxLength}
      style={style}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
    />
  )
}

export default CTextArea
