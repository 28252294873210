import { LinkLabel } from 'aa_common/front-end/components'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { Wrapper } from './styles'

const t = loadNs(['common', 'components/organisms/accounting/journal-list'])
const MessageNoJournals = () => {
  const dispatch = useDispatch()
  const {
    permissions: { isRead },
  } = useUserPermission(FEATURES_ID.SETTING_EXCISE)

  return (
    <Wrapper style={{ fontSize: '13px' }}>
      <span style={{ marginRight: 5 }}>{t('message_no_journals')}</span>
      <LinkLabel
        showTooltipMessage={!isRead ? t('actions.no_permission') : undefined}
        disabled={!isRead}
        onClick={() => dispatch(changeLocation('/setting/excises'))}
      >
        {t('config_journals_type')}
      </LinkLabel>
    </Wrapper>
  )
}

export default MessageNoJournals
