import React from 'react'
import { Controller } from 'react-hook-form'

import { RangeInputProps, RangeOnChangeEvent } from './model'
import CoreRangeInput from './RangeInput'

const RangeInput = ({
  name,
  value,
  className = '',
  type = 'number',
  disabled = false,
  range,
  control,
  onChange,
  suffix,
}: RangeInputProps) => {
  const handleOnChange = (event: RangeOnChangeEvent) => {
    event.trigger && onChange && onChange([event.from, event.to])
  }

  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ value, onChange: controllerOnChange }) => {
          return (
            <CoreRangeInput
              className={className}
              name={name}
              type={type}
              disabled={disabled}
              value={value}
              range={range}
              onChange={event => {
                controllerOnChange([event.from, event.to])
                handleOnChange(event)
              }}
              suffix={suffix || null}
            />
          )
        }}
      />
    )
  }

  return (
    <CoreRangeInput
      className={className}
      type={type}
      disabled={disabled}
      name={name}
      range={range}
      value={value}
      onChange={handleOnChange}
      suffix={suffix || null}
    />
  )
}

export default RangeInput
