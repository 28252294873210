import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Button } from 'aa_common/front-end/antd'
import { Collapse } from 'antd'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import i18n from 'i18n'
import React, { useCallback, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { Anchor, Body, Field, Footer, Label, Row, Symbol, Wrapper } from './styles'

const { Panel } = Collapse

type BaseFilterPanelProps = {
  children: any
  initialValues: any
  loading?: boolean
  formRef?: any
  onSubmit?: (values: any) => void
}

interface FilterPanelProps extends React.FC<BaseFilterPanelProps> {
  Row: typeof Row
  Field: typeof Field
  Label: typeof Label
  Symbol: typeof Symbol
}

const FilterPanel: FilterPanelProps = ({
  children,
  initialValues,
  loading,
  formRef,
  onSubmit,
}: BaseFilterPanelProps) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  const handleSubmitFormik = (values: any) => onSubmit?.(values)

  const toggleCollapse = useCallback(() => {
    setIsShow(prev => !prev)
  }, [setIsShow])

  return (
    <Wrapper>
      <Collapse
        className={classNames('search-panel-collapse', { show: isShow, hidden: !isShow })}
        accordion
        activeKey={isShow ? 'search-panel' : undefined}
      >
        <Panel header="Form" key="search-panel">
          <Formik
            initialValues={initialValues}
            validationSchema={null}
            validateOnChange={false}
            validateOnBlur={false}
            errors={null}
            onSubmit={handleSubmitFormik}
          >
            <CSSTransition in={isShow} timeout={300} unmountOnExit>
              <Form ref={formRef}>
                <Body>{children}</Body>
                <Footer>
                  <Button className="filter-search" type="submit" loading={loading}>
                    {i18n.t('components.FilterPanel.search')}
                  </Button>
                </Footer>
              </Form>
            </CSSTransition>
          </Formik>
        </Panel>
      </Collapse>
      <Anchor className="collapse-anchor" onClick={toggleCollapse}>
        <label>{i18n.t('components.FilterPanel.conditions')}</label>
        {isShow ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </Anchor>
    </Wrapper>
  )
}

FilterPanel.Row = Row
FilterPanel.Field = Field
FilterPanel.Label = Label
FilterPanel.Symbol = Symbol

export default FilterPanel
