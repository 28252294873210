export const FETCH_DEPRECIATION_RESULT_REQUEST = 'FETCH_DEPRECIATION_RESULT_REQUEST'
export const FETCH_DEPRECIATION_RESULT_SUCCESS = 'FETCH_DEPRECIATION_RESULT_SUCCESS'
export const FETCH_DEPRECIATION_RESULT_FAILURE = 'FETCH_DEPRECIATION_RESULT_FAILURE'
export const EXPORT_DEPRECIATION_RESULT_REQUEST = 'EXPORT_DEPRECIATION_RESULT_REQUEST'
export const ADD_DEPRECIATION_RESULT_ROW_KEY = 'ADD_DEPRECIATION_RESULT_ROW_KEY'
export const REMOVE_DEPRECIATION_RESULT_ROW_KEY = 'REMOVE_DEPRECIATION_RESULT_ROW_KEY'

export const CLEANUP_DEPRECIATION_RESULT = 'CLEANUP_DEPRECIATION_RESULT'

export const fetchResults = (payload: any, isInMonthlyClosing = false) => {
  return {
    type: FETCH_DEPRECIATION_RESULT_REQUEST,
    payload,
    isInMonthlyClosing,
  }
}

export const exportDepreciationResult = (payload: any, isInMonthlyClosing = false) => {
  return {
    type: EXPORT_DEPRECIATION_RESULT_REQUEST,
    payload,
    isInMonthlyClosing,
  }
}

export const addDepreciationResultRowKey = (key: string) => ({
  type: ADD_DEPRECIATION_RESULT_ROW_KEY,
  payload: key,
})

export const removeDepreciationResultRowKey = (key: string) => ({
  type: REMOVE_DEPRECIATION_RESULT_ROW_KEY,
  payload: key,
})
