import { Asset } from 'common/models'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'

import { assetListPageStore } from '../store'

export const useGetSelectedFa = () => {
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const store = assetListPageStore.rowSelectSliceAssetList.useSlice(data => data).data
  const { selectedRow, biids, totalBiidOfSelectedItems } = React.useMemo(() => {
    const selectedRow = assetCollectionViewSelector.assets.filter((asset: Asset) =>
      asset && asset.biid ? store.selectedItem[asset.biid] : undefined
    )

    const totalBiidOfSelectedItems = Object.keys(store.selectedItem).filter(key => store.selectedItem[key])

    const biids = selectedRow.map((item: Asset) => item.biid)
    return { selectedRow, biids, totalBiidOfSelectedItems }
  }, [assetCollectionViewSelector.assets, store.selectedItem])
  return {
    selectedRow,
    biids,
    totalBiidOfSelectedItems,
  }
}
