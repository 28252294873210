import styled from '@emotion/styled'

export const Wrapper = styled.div`
  border: 1px solid #d4d8dd;
  width: 600px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 15px 15px;

  .size-short {
    width: 150px;
    margin: 5px 0;

    span {
      font-size: 13px;
    }
  }

  .auto-complete-select-wrapper {
    width: 150px;
    margin-bottom: 10px;
  }

  .cancel_terms {
    width: 85px;
    font-size: 13px;
  }

  .submit_terms {
    width: 70px;
    font-size: 13px;
  }
`
