import { Notification } from 'common/models'

import { CurrentUser, FlagMessageInvalidOffice, ICurrentOfficeSelector, IUserInfoSelector, UserInfo } from './model'

export const getUserInfoSelector = (state: any): UserInfo => {
  return state.getIn(['session', 'user', 'data'])
}

export const getCurrentUserSelector = (state: any): CurrentUser => {
  return state.getIn(['session', 'currentUser', 'data'])
}

export const selectUserInfo = (state: any): IUserInfoSelector => {
  return {
    loading: state.getIn(['session', 'user', 'loading']),
    data: state.getIn(['session', 'user', 'data']),
  }
}

export const selectCurrentOffice = (state: any): ICurrentOfficeSelector => {
  return {
    loading: state.getIn(['session', 'currentOffice', 'loading']),
    data: state.getIn(['session', 'currentOffice', 'data']),
  }
}

export const selectFlagInvalidOffice = (state: any): FlagMessageInvalidOffice => {
  return state.getIn(['session', 'flag_invalid_office'])
}

export const selectUserPermission = (state: any) => {
  const data = state.getIn(['session', 'userPermissions', 'data'])
  return {
    data:
      data &&
      Object.keys(data).reduce((acc: Record<string, any>, key) => {
        acc[key] = data[key]?.operation_combined
        return acc
      }, {}),
    loading: state.getIn(['session', 'userPermissions', 'loading']),
  }
}

export const selectWsConnectionStatus = (state: any) => {
  return state.getIn(['session', 'wsConnectionStatus']) as number
}

export const selectNotifications = (state: any) => {
  const notifications: Notification[] = state.getIn(['session', 'notifications', 'data']) || []
  const seenIds = state.getIn(['session', 'notifications', 'seenIds']) as number[]
  const unreadCount = notifications.filter(({ id }) => !seenIds.includes(id)).length
  return {
    unreadCount,
    notifications,
    loading: state.getIn(['session', 'notifications', 'loading']) as boolean,
  }
}
