import { immutable } from '@redux-devtools/serialize'
import Immutable, { Map } from 'immutable'
import persistState from 'redux-localstorage'

const { stringify, parse } = immutable(Immutable)
const paths = ['session', 'notifications']

const persistStoreMiddleware = persistState(null, {
  serialize: (state: any) => stringify(state.getIn(paths)),
  deserialize: (serializedData: string) => parse(serializedData),
  merge(initialState: any, persistedState: any) {
    if (!persistedState) return initialState
    return Map({}).setIn(paths, persistedState)
  },
})

export default persistStoreMiddleware
