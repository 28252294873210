import { BasicTable } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import useModal from 'hooks/useModalHook'
import React, { useCallback, useMemo } from 'react'

import { convertDataToDisplay, getDepreciationFixedAssetColumns } from './helper'
import HistoryList from './HistoryList'
import { StyledWrapper } from './styles'

interface Props {
  data: any[]
  taxYear: number
}

const { t, useNsReady } = getNs(['common', 'pages/accounting/depreciation-fixed-asset-page'])
const DepreciationFixedAssetList = ({ data, taxYear }: Props) => {
  useNsReady()
  const modal = useModal()
  const { isEnable: isAllowedForMultiExportTaxDeclaration } = useCheckFlag(FeatureFlagNames.MultiExportTaxDeclaration)

  const openHistoryModal = useCallback(
    (id: number, name: string) => {
      const title = t('columns.output_history')
      const cancelText = t('actions.close')
      modal.confirm({
        title,
        cancelText,
        closable: true,
        message: <HistoryList key={id} id={id} name={name} taxYear={taxYear} />,
      })
    },
    [modal, taxYear]
  )

  const memoizedAreas = useMemo(
    () => convertDataToDisplay(data, taxYear, openHistoryModal, isAllowedForMultiExportTaxDeclaration as boolean),
    [data, openHistoryModal, taxYear, isAllowedForMultiExportTaxDeclaration]
  )

  const getTableConfig = () => {
    const columnsWidth = [150, 200, 260, 180]
    const columns = getDepreciationFixedAssetColumns(isAllowedForMultiExportTaxDeclaration as boolean)

    if (isAllowedForMultiExportTaxDeclaration) {
      columnsWidth.unshift(40)
    } else {
      columnsWidth.unshift(0)
    }

    return { columnsWidth, columns }
  }

  const { columns, columnsWidth } = getTableConfig()
  return (
    <StyledWrapper isExportMultiple={isAllowedForMultiExportTaxDeclaration}>
      <BasicTable
        data={memoizedAreas}
        columns={columns}
        columnsWidth={columnsWidth}
        style={{
          margin: '15px 0',
        }}
      />
    </StyledWrapper>
  )
}

export default DepreciationFixedAssetList
