const parseValueForm = (keys: string[], values: any, prefix?: string) => {
  const prefixStr = prefix ?? ''
  const initialValue = {}

  return keys.reduce((obj: any, key: string) => {
    return Object.assign(obj, {
      [key]: values[prefixStr + key],
    })
  }, initialValue)
}

export { parseValueForm }
