import { Spin } from 'aa_common/front-end/antd'
import { SessionTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { handleCheckMfidCallback } from 'store/session/actions'

import { Wrapper } from './styles'

const CallbackPage: React.FC = () => {
  const dispatch = useDispatch()
  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const code = params.get('code')
    const state = params.get('state')

    dispatch(handleCheckMfidCallback(state, code))
  }, []) // eslint-disable-line

  return (
    <SessionTemplate>
      <Spin loading size="large" text="Loading ...">
        <Wrapper />
      </Spin>
    </SessionTemplate>
  )
}

export default CallbackPage
