import { IMPAIRMENT_LOSS_APPLY_METHOD } from 'common/constants'
import { v1 as uuidv1 } from 'uuid'

import { DEPRECIATION_METHOD } from './model'

export const DEFAULT_TEMPLATE_LEDGER = {
  five_years_equal_installment_rounding_method: 1,
  id: null,
  key_id: uuidv1(),
  ledger_purpose: null,
  lump_sum_depreciable_asset_rounding_on: 1,
  maximum_depreciation_reached_year_calculation_method: 1,
  monthly_residual_method: 1,
  movement_depreciation_amount_method: 1,
  name: null,
  retirement_depreciation_ended_method: 1,
  rounding_method: 2,
  bulk_equal_installment_first_year_method: 2,
  impairment_loss_apply_method: IMPAIRMENT_LOSS_APPLY_METHOD.END_OF_CURRENT_MONTH,
  bulk_equal_installment_depreciation_method: DEPRECIATION_METHOD.CONTINUE_DEPRECIATION,
}
