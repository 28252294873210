import styled from '@emotion/styled'

export const UploadItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  position: relative;

  &:hover {
    background-color: #f5f5f5;
    .remove-icon {
      opacity: 1;
    }
  }

  .label {
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    min-width: 0px;

    .attachment-icon {
      margin-right: 5px;
      color: rgb(124, 130, 145);
    }
  }

  .actions {
    flex: 0 1 auto;
    padding: 0 5px;
  }

  .remove-icon {
    color: #e31c1c;
    opacity: 0;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    &:hover {
      opacity: 0.5;
    }

    &.hidden {
      display: none;
    }
  }

  transition: 0.1s all ease-in-out;
`
