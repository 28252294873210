import styled from '@emotion/styled'

export const EmptyWrapper = styled.div`
  margin: 5px 0;
  padding: 0 10px;
`

export const MultiselectPickerWrapper = styled.div`
  .multipicker-search {
    padding-top: 3px;
    padding-bottom: 3px;
    height: 24px;

    input {
      font-size: 13px;
    }
  }
`

export const MultiselectInputWrapper = styled.div`
  padding: 0 5px;
`

export const MultiselectPickerActionWrapper = styled.div`
  margin: 8px 0 0;
  padding: 5px;
  border-top: 1px #dce0e6 solid;
  border-bottom: 1px #dce0e6 solid;
  button {
    &:first-of-type {
      padding-left: 0;
    }
    padding: 5px;
    margin-right: 5px;
  }
`

export const ListItem = styled.div`
  padding: 5px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.3s ease;
  border-bottom: 1px #dce0e6 solid;

  .option-content {
    overflow-wrap: anywhere;
  }

  &.selected {
    background-color: rgba(59, 125, 233, 0.1);
  }

  &.disabled {
    color: #d4d8dd;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #f5f5f5;

    &.selected {
      background-color: rgba(59, 125, 233, 0.1);
    }
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  bottom: 120px;
  width: 100%;
  text-align: center;
`
