import { Map } from 'immutable'

import {
  CREATE_EXCISE_SETTING_SUCCESS,
  DELETE_EXCISE_SETTING_FAILURE,
  DELETE_EXCISE_SETTING_REQUEST,
  DELETE_EXCISE_SETTING_SUCCESS,
  FETCH_EXCISE_SETTING_FAILURE,
  FETCH_EXCISE_SETTING_REQUEST,
  FETCH_EXCISE_SETTING_REQUEST_AUTH,
  FETCH_EXCISE_SETTING_SUCCESS,
} from './constant'

export const initialState = Map({
  list: Map({
    data: null,
  }),
  item: Map({
    data: null,
    loading: false,
  }),
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_EXCISE_SETTING_REQUEST_AUTH:
    case FETCH_EXCISE_SETTING_REQUEST:
    case DELETE_EXCISE_SETTING_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_EXCISE_SETTING_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_EXCISE_SETTING_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['list', 'data'], null)
    case CREATE_EXCISE_SETTING_SUCCESS:
      return state.setIn(['item', 'data'], action.payload)
    case DELETE_EXCISE_SETTING_SUCCESS:
    case DELETE_EXCISE_SETTING_FAILURE:
      return state.setIn(['item', 'loading'], false)
    default:
      return state
  }
}
