import {
  buildPaginationParams,
  buildQueryFilterParams,
  convertSortValue,
  objectToQueryParams,
  parseError,
  parseResponse,
  RequestParam,
} from 'common/helpers'
import { LedgerSetting, RetirementFixedAssetLedger } from 'common/models'
import { FixedAssetSearchPayload, ICalculateMemorandumValuePayload } from 'store/asset/model'

import base from './base'

export const search = (
  payload: FixedAssetSearchPayload,
  pageNumber: number,
  pageSize: number,
  isGetDetailOneFixedAsset: boolean = false
) => {
  const filters = {
    ...payload.filters,
    calculate_cost: true,
    ...(isGetDetailOneFixedAsset && { is_get_detail_one_fixed_asset: isGetDetailOneFixedAsset }),
  }

  return base.post('/v1/search/fixed_assets', {
    ...buildQueryFilterParams(filters),
    ...buildPaginationParams(pageNumber, pageSize),
    sort: payload.sort,
  })
}

export const create = (data: any) => {
  return base.post('/v1/fixed_assets', data)
}

export const createBulk = (data: any) => {
  return base.post('/v1/fixed_asset_import', data)
}

export const get = (biid: string, valid_at?: string) => {
  return base.get(`/v1/fixed_assets/${biid}`, {
    params: {
      valid_at,
    },
  })
}

export const getById = (biid: string, id: number, includeDelete?: boolean) => {
  return base.get(`/v1/fixed_assets/${biid}/records/${id}`, {
    params: {
      includes_deleted: includeDelete,
    },
  })
}

export const getPreviousOfId = (biid: string, id: number) => {
  return base.get(`/v1/fixed_assets/${biid}/records/${id}/previous`)
}

export const createUsage = (biid: string, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/start_usage`, data)
}

export const createRetire = (biid: string, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/retire`, data)
}

export const createMovement = (biid: string, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/move`, data)
}

export const createSwitchCategory = (biid: string, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/switch_category`, data)
}

export const createSell = (biid: string, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/sell`, data)
}

export const updateUsage = (biid: string, id: number, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/records/${id}/start_usage`, data)
}

export const updateRetirement = (biid: string, id: number, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/records/${id}/retire`, data)
}

export const updateMovement = (biid: string, id: number, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/records/${id}/move`, data)
}

export const updateSwitchCategory = (biid: string, id: number, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/records/${id}/switch_category`, data)
}

export const updateSell = (biid: string, id: number, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}/records/${id}/sell`, data)
}

export const deleteByBiid = (biid: string) => {
  return base.delete(`/v1/fixed_assets/${biid}`)
}

export const deleteById = (biid: string, id: number) => {
  return base.delete(`/v1/fixed_assets/${biid}/records/${id}`)
}

export const updateByBiid = (biid: string, data: any) => {
  return base.put(`/v1/fixed_assets/${biid}`, data)
}

export const getRange = () => {
  return base.get('/v1/fixed_asset_ranges')
}

export const getDepreciationResults = (biid: string, ledger_setting_id: string | number) => {
  const params = { ledger_setting_id }
  return base.get(`/v1/fixed_assets/${biid}/depreciation_results`, { params })
}

export const postValidateCsv = (charset: string | null, file: File) => {
  const formData = new FormData()
  charset && formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/fixed_asset_import/validate_csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const initializeFixedAssets = () => {
  return base
    .delete('/v1/fixed_assets')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const exportFile = (charset: string, filter: RequestParam, sort: string[]) => {
  const filterQuery = objectToQueryParams(buildQueryFilterParams(filter))
  const sortQuery = convertSortValue(sort)

  return base.get(`/v1/export/fixed_assets?charset=${charset}&${filterQuery}&sort=${sortQuery || ''}`, {
    responseType: 'blob',
  })
}

export const searchPreviousByIds = (ids: number[]) => {
  return base.post('/v1/search/previous_fixed_assets', {
    ids,
  })
}

export const getBookInformation = (
  biid: string,
  date: string,
  ledgers: RetirementFixedAssetLedger[] | null,
  ledgerSettings: LedgerSetting[],
  changeSituationCode?: number
) => {
  const params = {
    at: date,
    fixed_asset_ledgers: ledgers?.map
      ? ledgers.map((item, idx) => ({
          depreciation_ended_method: item.depreciation_ended_method || item.retirement_depreciation_ended_method,
          ledger_setting_id: item?.ledger_setting_id || ledgerSettings[idx]?.id,
          change_situation_code: changeSituationCode,
        }))
      : undefined,
  }

  return base.post(`/v1/fixed_assets/${biid}/book_informations`, params)
}

export const getStandardDateToShowBookValue = (data: { biid: string; divided_at: string }) => {
  return base.post(`/v1/fixed_assets/divide/standard_date`, data)
}

export const submitImpairmentEvent = (assetBiid: string, payload: any) => {
  return base.put(`/v1/fixed_assets/${assetBiid}/impair`, payload)
}

export const submitDividingAsset = (assetBiid: string, payload: any) => {
  return base.put(`/v1/fixed_assets/${assetBiid}/divide`, payload)
}

export const submitModifiedAssetTypeEvent = (assetBiid: string, payload: any) => {
  return base.put(`/v1/fixed_assets/${assetBiid}/asset_type`, payload)
}

export const calculateMemorandumValue = (payload: ICalculateMemorandumValuePayload) => {
  return base.post('v1/fixed_assets/calculate_memorandum', payload)
}

export const getBookDividingInformation = (data: {
  biid: string
  ledger_book_values: { at: string; ledger_setting_id: number }[]
}) => {
  return base.post(`/v1/fixed_assets/divide/book_value`, data)
}

export const deleteDividingEvent = (biid: string, id: number) => {
  return base.delete(`/v1/fixed_assets/${biid}/records/${id}/divide`)
}
