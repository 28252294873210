import { DepreciationResultReportSelector } from './select.model'

export const selectDepreciationResults = (state: any): DepreciationResultReportSelector => ({
  loading: state.getIn(['depreciation', 'result', 'list', 'loading']),
  data: state.getIn(['depreciation', 'result', 'list', 'data']) || null,
})

export const selectDepreciationReportRowKeys = (state: any) => {
  return state.getIn(['depreciation', 'result', 'rowKeys'])
}
