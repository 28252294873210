import {
  DATE_FORMAT,
  DATE_FORMAT_RFC,
  DATETIME_FORMAT,
  DATETIME_NO_DOT_FORMAT,
  DAY_FORMAT,
  MONTH_DAY_FORMAT,
  MONTH_FORMAT,
  TIME_FORMAT,
  YEAR_FORMAT,
} from 'common/constants'
import currency from 'currency.js'
import moment, { Moment } from 'moment'
import urlRegex from 'url-regex-safe'

export const yenStringToNumber = (value: string) => currency(value).value
const JA_LANGUAGE = 'ja-JP'
type DateType = string | Moment | null

export const yen = (value: number | string) => {
  return currency(value, { symbol: '¥', precision: 0 }).format()
}

export const yenWithoutSymbol = (value: number | string) => {
  return currency(value, { symbol: '', precision: 0 }).format()
}

export const yenCheckNull = (value: number | string | undefined | null) => {
  if (value === null || value === undefined) return ''
  return yen(value)
}

export const yenWithoutSymbolCheckNull = (value: number | string | undefined | null) => {
  if (value === null || value === undefined) return ''
  return yenWithoutSymbol(value)
}

export const isValidUrl = (url: string) => urlRegex({ exact: true, strict: true }).test(url)

export const formatDate = (date: DateType | undefined, format?: string) => {
  const formatType = format || DATE_FORMAT

  if (date) {
    return moment(date).format(formatType)
  }
  return date
}

export const formatTime = (date: DateType) => {
  const format = TIME_FORMAT

  if (date) {
    return moment(date).format(format)
  }
  return date
}

export const formatDateTime = (date: DateType) => {
  return formatDateTimeBase(date, DATETIME_FORMAT)
}

export const formatDateTimeNoDot = (date: DateType) => {
  return formatDateTimeBase(date, DATETIME_NO_DOT_FORMAT)
}

export const formatDateTimeBase = (
  date: DateType,
  format: typeof DATETIME_FORMAT | typeof DATETIME_NO_DOT_FORMAT | typeof DATE_FORMAT_RFC
) => {
  if (date) {
    return moment(date).format(format)
  }

  return date
}

export const formatYear = (value: DateType | number) => {
  if (value) {
    if (typeof value === 'number') {
      return moment(value.toString()).format(YEAR_FORMAT)
    }
    return moment(value).format(YEAR_FORMAT)
  }
  return value
}

export const formatMonth = (value: DateType | number) => {
  if (value) {
    if (typeof value === 'number') return value >= 10 ? value : `0${value}`
    return moment(value).format(MONTH_FORMAT)
  }

  return value
}

export const formatMonthDay = (date: DateType | number) => {
  if (date) {
    return moment(date).format(MONTH_DAY_FORMAT)
  }

  return date
}

export const formatNumber = (value: number) => {
  return Intl.NumberFormat(JA_LANGUAGE).format(value)
}

export const formatMaxText = (value: string, maxLength: number) => {
  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}...`
  }

  return value
}

export const formatDay = (time: DateType | number) => (time ? moment(time).format(DAY_FORMAT) : time)
