import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const Wrapper = styled.div`
  width: 900px;
  font-size: 13px;

  .auto-complete-select-wrapper {
    display: inline-block;
    width: 170px;
    padding-right: 5px;

    & + label {
      padding-right: 20px;
    }
  }

  .ant-checkbox-wrapper {
    font-size: 13px;
    color: #333333;
  }
`

export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Table = styled.div`
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 1px #d4d8dd;
  background-color: #ffffff;
`

export const Row = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid #d4d8dd;
  display: flex;
  align-items: center;

  .label {
    width: 200px;
    white-space: pre-wrap;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const LastRow = styled.div`
  border-bottom: 1px solid #d4d8dd;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .aa-link {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const CurrentLabel = styled.div`
  width: 32px;
  height: 20px;
  background-color: #3b7de9;
  border-radius: 4px;
  margin-right: 20px;
  color: #ffffff;
  text-align: center;
  font-size: 12px;

  position: absolute;
  left: 304px;
`

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  padding-right: 10px;
  border-top: solid 1px #d4d8dd;
`

export const TitleLineStyle = css`
  height: 30px;
  width: 100%;
  background: #f4f4f4;
  padding: 0;

  display: flex;
  align-items: center;
`
