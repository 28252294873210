/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCalculateExciseValueRequest,
  PresenterCalculatedExciseValueResponse,
  PresenterError,
  PresenterOneExciseRateResponse,
  PresenterOneExciseResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Excises<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List excise rates
   *
   * @tags excises
   * @name ExciseRatesList
   * @summary List the excise rates
   * @request GET:/excise_rates
   */
  exciseRatesList = (
    query?: {
      'filter[end_date]'?: string[]
      'filter[reduced_flg]'?: number | string
      'filter[start_date]'?: string[]
      'filter[total_percentage]'?: number | string
      sort?: string[]
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneExciseRateResponse, PresenterError>({
      path: `/excise_rates`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description List excise
   *
   * @tags excises
   * @name ExcisesList
   * @summary List the excise
   * @request GET:/excises
   */
  excisesList = (
    query?: {
      'filter[code]'?: string
      'filter[disp_order]'?: number | string
      'filter[excise_label_id]'?: number | string
      'filter[excise_master_id]'?: number | string
      'filter[excise_rate_id]'?: number | string
      'filter[external_code]'?: string
      'filter[is_active]'?: boolean
      'filter[simple_excise_id]'?: number | string
      'filter[tmp_visible_flg]'?: boolean
      sort?: string[]
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneExciseResponse, PresenterError>({
      path: `/excises`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description CalculateExciseValue the excise
   *
   * @tags excises
   * @name CalculateValueCreate
   * @summary CalculateExciseValue the excise
   * @request POST:/excises/calculate_value
   */
  calculateValueCreate = (body: PresenterCalculateExciseValueRequest, params: RequestParams = {}) =>
    this.http.request<PresenterCalculatedExciseValueResponse, any>({
      path: `/excises/calculate_value`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
