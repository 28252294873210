import styled from '@emotion/styled'
import { GREY_BORDER_COLOR, BLACK_COLOR } from 'common/constants'

export const Wrapper = styled.div`
  position: relative;
  min-width: 1000px;
`

export const Header = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 99;
`

export const Body = styled.div`
  padding-top: 100px;
  position: relative;
  z-index: 1;
  height: 100vh;
  background: #f0f1f4;
`

export const Content = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`

export const ErrorContent = styled.div`
  border: 1px ${GREY_BORDER_COLOR} solid;
  border-radius: 4px;
  padding: 30px;
  width: 600px;
  text-align: center;
  background: #ffffff;

  .header {
    margin-bottom: 30px;
    .status-header {
      color: ${BLACK_COLOR};
      margin-bottom: 15px;
      font-size: 24px;
    }

    .status-title {
      color: #666666;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .content {
    color: ${BLACK_COLOR};
    font-size: 13px;
  }
`
