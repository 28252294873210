import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { RedirectToMasterPageLink, UnderlineText, UploadAttachment, UploadImage } from 'aa_common/front-end/components'
import FormLinkListField from 'aa_common/front-end/components/form/form-link-list-field'
import { Col, Row, Typography } from 'antd'
import { CAUSE_TYPES, ENDPOINTS, FEATURES_ID, IMPAIRMENT_LOSS_APPLY_METHOD } from 'common/constants'
import { handlePermissionByFeatureId, yen } from 'common/helpers'
import { findAccountingLedger } from 'common/helpers/ledger-helper'
import { loadNs } from 'common/i18n-config'
import Form from 'components/molecules/Form'
import { get, isEqual } from 'lodash'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { selectUserPermission } from 'store/session/selectors'
import { fetchCauseList } from 'store/settings/cause/actions'
import { selectCauseList } from 'store/settings/cause/selectors'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

import { useImpairmentBookInfo, useImpairmentLossAmount } from './hooks'
import ImpairmentDate from './ImpairmentDate'

const t = loadNs(['components/organisms/assets/asset-impairment', 'common'])

const { FormField, Group } = Form
const { SELECT_ON_IMPAIRMENT } = IMPAIRMENT_LOSS_APPLY_METHOD
const FIELD_NAMES = {
  impaired_at: 'impaired_at',
  impairment_method: 'impairment_method',
} as const

const FormContent = () => {
  const { control, errors, setValue } = useFormContext()
  const dispatch = useDispatch()
  const { data: causes } = useSelector(selectCauseList, isEqual)
  const { data: ledgerSettings } = useSelector(selectorLedgerSettingsList, isEqual)
  const { data: permissions } = useSelector(selectUserPermission, isEqual)

  const accountingLedger = useMemo(() => findAccountingLedger(ledgerSettings), [ledgerSettings])

  const isSelectMethodOnImpair = useMemo(() => {
    const method = accountingLedger?.impairment_loss_apply_method
    const isSelectOnImpairment = method === SELECT_ON_IMPAIRMENT
    if (method && !isSelectOnImpairment) {
      setValue(FIELD_NAMES.impairment_method, method)
    }
    return isSelectOnImpairment
  }, [setValue, accountingLedger])

  const { impairment_loss_apply_method } = useSelector(selectMasterData, isEqual)
  const impairmentMethods = impairment_loss_apply_method.filter(item => item.code !== SELECT_ON_IMPAIRMENT)

  const impairmentReasons = useMemo(
    () => (causes || []).filter(cause => cause.type === CAUSE_TYPES.IMPAIRMENT),
    [causes]
  )

  const [bookInformation, loading] = useImpairmentBookInfo(FIELD_NAMES.impaired_at, FIELD_NAMES.impairment_method)
  const { book_value: impairmentBookValue, date: impairReferenceDate } = bookInformation || {}
  const impairmentLossAmount = useImpairmentLossAmount(impairmentBookValue)

  return (
    <Group className="form-wrapper" borderLess>
      <FormField title={t('impairment_date')} required>
        <ImpairmentDate required name={FIELD_NAMES.impaired_at} error={get(errors, 'impaired_at.message')} />
      </FormField>
      <FormField title={t('impairment_reason')} required>
        <AutoCompleteSelect
          required
          name="event_cause_id"
          valueField="id"
          textField="content"
          placeholder={t('placeholder.please_select')}
          items={impairmentReasons}
          control={control}
          error={get(errors, 'event_cause_id.message')}
          customDropdownTemplate={
            <RedirectToMasterPageLink
              permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_CAUSES, permissions)}
              urlName={ENDPOINTS.CAUSES}
              pageName={t('master.causes')}
              onHandleReload={() => dispatch(fetchCauseList())}
            />
          }
        />
      </FormField>
      <FormField title={t('book_value_date')}>
        <UnderlineText loading={loading} contentAlign="right">
          <div style={{ textAlign: 'left' }}>{impairReferenceDate}&nbsp;</div>
        </UnderlineText>
      </FormField>
      <FormField title={t('impairment_book_value')}>
        <UnderlineText loading={loading} contentAlign="right">
          <Row className="impairment-book-value" wrap={false}>
            <Col className="ledger-name" flex="auto">
              <Typography.Text ellipsis>{accountingLedger?.name}</Typography.Text>
            </Col>
            <Col flex="none">
              <span className="value">{yen(impairmentBookValue)}</span>
            </Col>
          </Row>
        </UnderlineText>
      </FormField>
      <FormField title={t('impairment_loss_amount')}>
        <UnderlineText loading={loading} contentAlign="right">
          {yen(impairmentLossAmount)}
        </UnderlineText>
      </FormField>
      <FormField title={t('impairment_total_amount')}>
        <UnderlineText loading={loading} contentAlign="right">
          {yen(impairmentLossAmount)}
        </UnderlineText>
      </FormField>
      {isSelectMethodOnImpair && (
        <FormField title={t('impairment_depreciation_method')} required>
          <AutoCompleteSelect
            required
            name={FIELD_NAMES.impairment_method}
            valueField="code"
            textField="name_jp"
            placeholder={t('placeholder.please_select')}
            items={impairmentMethods}
            error={get(errors, 'impairment_method.message')}
            control={control}
          />
        </FormField>
      )}
      <FormField className="align-start" title={t('photos')}>
        <UploadImage name="event_photos" control={control} />
      </FormField>
      <FormField className="align-start" title={t('attachments')}>
        <UploadAttachment name="event_attachments" />
      </FormField>
      <FormField className="align-start" title={t('links')}>
        <FormLinkListField name="event_urls" control={control} placeholder={t('placeholder.links')} />
      </FormField>
    </Group>
  )
}

export default FormContent
