import { AccountingPlusLink, NoDataImage } from 'components/atoms'
import { Pagination } from 'components/molecules'
import i18n from 'i18n'
import React, { Dispatch, SetStateAction } from 'react'

import HistoryList, { Props as HistoryListProp } from './HistoryList'
import { EmptyImage } from './styles'

interface Props extends HistoryListProp {
  paginationName: string
  totalRecords: number
  totalPages: number
  pageNumber: number
  showAccPlusLink?: boolean
  onPageChange: Dispatch<SetStateAction<number>>
}

const HistoriesContent = ({
  historyRecords,
  paginationName,
  totalRecords,
  totalPages,
  onPageChange,
  pageNumber,
  showAccPlusLink,
  ...rest
}: Props) => (
  <>
    {!(historyRecords && historyRecords?.length) ? (
      <EmptyImage>
        <NoDataImage text={i18n.t('pages.accounting.HistoryList.messages.no_data')} />
      </EmptyImage>
    ) : (
      <div className="history-content-wrapper">
        <Pagination
          totalPages={totalPages}
          totalRecords={totalRecords}
          name={paginationName}
          nameClass="history-pagination"
          onPageChanged={onPageChange}
          value={pageNumber}
        />
        {showAccPlusLink && (
          <div className="acc-plus-message">
            <span>
              {i18n.t('pages.accounting.HistoryList.acc_plus_link.text1')}
              <AccountingPlusLink style={{ marginLeft: 5, marginRight: 5 }} path="/applicant/cooperation_journals" />
              {i18n.t('pages.accounting.HistoryList.acc_plus_link.text2')}
            </span>
          </div>
        )}
        <HistoryList historyRecords={historyRecords} {...rest} />
      </div>
    )}
  </>
)

export default HistoriesContent
