import { IOfficeSelector } from './model'

export const selectOfficeList = (state: any): IOfficeSelector => {
  return {
    loading: state.getIn(['office', 'list', 'loading']),
    data: state.getIn(['office', 'list', 'data']),
  }
}

export const selectOfficeListErrors = (state: any) => {
  return state.getIn(['office', 'list', 'errors'])
}
