import styled from '@emotion/styled'
import { List } from 'antd'
import { css } from '@emotion/css'

export const CommentItem = styled(List.Item)`
  justify-content: unset;
  transition: all 0.2s ease-in;
  padding-left: 10px;

  &.item-hovered,
  &.is-editing {
    background-color: #f5f5f5;
  }

  .comment-action {
    padding: 0;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }

    &.edit {
      color: #3b7de9;
    }

    &.delete {
      color: #ec4949;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      cursor: not-allowed;
    }

    &:hover {
      opacity: 0.7;
    }
  }
  .avatar-wrapper {
    margin-right: 10px;
    align-self: flex-start;
  }
  .comment-content-wrapper {
    width: 100%;
    font-size: 13px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .actions {
        transition: opacity 0.2s ease-in-out;
        &.show {
          opacity: 1;
        }
        &.hide {
          opacity: 0;
        }
        margin-right: 10px;
        margin-top: -5px;
      }

      > .comment-info {
        display: inline-flex;
        .user {
          margin-right: 15px;
        }
        .comment-date {
          color: #7c8291;
        }
      }
    }
    .content {
      padding-right: 10px;
      overflow-wrap: anywhere;

      p {
        margin-bottom: 0;
      }
      .edit-mode {
        display: none;
        &.show {
          display: block;
        }
        border: none;
        padding: 0;
      }
    }
  }
`

export const DefaultListClass = css`
  .ant-list-item {
    border-bottom: 1px solid #d4d8dd;
  }
`
