export type FiboInterValOptions = {
  maxDuration: number
}
export type FiboIntervalReturn = {
  run: () => void
  clear: () => void
}

export const fiboInterval = (callback: () => any, { maxDuration = 5000 }: FiboInterValOptions): FiboIntervalReturn => {
  let timeoutId: any
  let f1 = 0
  let f2 = 1000
  let timer = f1 + f2

  const run = () => {
    timeoutId = setTimeout(() => {
      callback()
      // console.log(f1, f2, timer)
      if (timer < maxDuration) {
        f1 = f2
        f2 = timer
        timer = f1 + f2
      }
      run()
    }, timer)
  }

  const clear = () => {
    clearTimeout(timeoutId)
  }

  return {
    run,
    clear,
  }
}
