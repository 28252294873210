import { AutoComplete } from 'antd'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import React, { useState } from 'react'

import { Wrapper, WrapperList } from './styles'

const { Option } = AutoComplete

interface AutoCompleteTextProps {
  name: string
  items?: any[]
  disabled?: boolean
  maxLength?: number
  placeholder?: string
  style?: React.CSSProperties
  onClickNew?: () => void
  className?: string
}

const AutoCompleteText: React.FC<AutoCompleteTextProps> = ({
  name,
  disabled,
  items = [],
  maxLength = 0,
  placeholder,
  style,
  onClickNew,
  className,
}) => {
  const { values, setFieldValue } = useFormikContext()
  const [options, setOptions] = useState(items)
  const [value, setValue] = useState(getIn(values, name))

  const onSearch = (searchText: string) => {
    if (maxLength > 0 && searchText.length > maxLength) {
      return
    }

    setOptions(items.filter(item => item.includes(searchText)))
  }

  const onChange = (value: string) => {
    if (value === null) {
      onClickNew && onClickNew()
    } else {
      if (maxLength > 0 && value.length > maxLength) {
        value = value.substring(0, maxLength)
      }
      setValue(value)
      setFieldValue(name, value)
    }
  }

  return (
    <Wrapper>
      <AutoComplete
        value={value}
        style={style}
        dropdownClassName={WrapperList}
        placeholder={placeholder}
        onSearch={onSearch}
        disabled={disabled}
        onChange={onChange}
        className={className}
      >
        {options.map(value => (
          <Option key={value} value={value}>
            {value}
          </Option>
        ))}
        {onClickNew && (
          <Option className="last" value="">
            <label>{i18n.t('components.AutoCompleteText.add_new')}</label>
          </Option>
        )}
      </AutoComplete>
    </Wrapper>
  )
}

export default AutoCompleteText
