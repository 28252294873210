import { ErrorTemplate } from 'components/templates'
import i18n from 'i18n'
import React, { useEffect } from 'react'

const headerContent = {
  header: i18n.t('pages.errorPages.maintenance.header'),
  title: 'Under Maintenance',
}

const UnderMaintenancePage = () => {
  useEffect(() => {
    document.title = i18n.t('common.page_title')
  }, [])

  return (
    <ErrorTemplate headerContent={headerContent}>
      <div className="text">
        <span>{i18n.t('pages.errorPages.maintenance.text_1')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.maintenance.text_2')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.maintenance.text_3')}</span>
      </div>
    </ErrorTemplate>
  )
}

export default UnderMaintenancePage
