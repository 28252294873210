import { AxiosResponse } from 'axios'
import { DependencyList, useEffect, useRef, useState } from 'react'

import { messageFn } from '../antd'

export const useIntervalApiCall = <T>(
  apiCall: () => Promise<AxiosResponse<T>> | undefined,
  intervalTime: number,
  stopCondition: (params?: T) => boolean,
  executeFncWhenStopCondition?: (params?: T) => void,
  maxTimeCall?: number,
  deps?: DependencyList
) => {
  const [data, setData] = useState<T | null | undefined>(null)
  const ref = useRef<{ intervalId?: NodeJS.Timer; maxCount: number }>({ intervalId: undefined, maxCount: 0 })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCall()
        setData(response?.data)
        ref.current.maxCount = 0
        if (!response) {
          ref.current.intervalId && clearInterval(ref.current.intervalId)
        }
        if (stopCondition(response?.data)) {
          executeFncWhenStopCondition && executeFncWhenStopCondition(response?.data)
          setData(undefined)
          ref.current.intervalId && clearInterval(ref.current.intervalId)
        }
        if (maxTimeCall) {
          if (ref.current.maxCount > maxTimeCall / intervalTime) {
            ref.current.intervalId && clearInterval(ref.current.intervalId)
          }
        }
      } catch (error: any) {
        ref.current.maxCount++
        messageFn().error(error.toString())
      }
    }

    fetchData()
    const id = setInterval(fetchData, intervalTime)
    ref.current.intervalId = id

    return () => {
      clearInterval(id)
    }
  }, [intervalTime, ...(deps || [])])

  return { data }
}
