export enum RegisterType {
  SALE = 'sold',
  RETIRED = 'retired',
  IMPAIRED = 'impaired',
  DIVIDED = 'divided',
}

export enum AssetDetailTabType {
  INFORMATION = '1',
  AMORTIZATION = '2',
  EVENT_HISTORY = '3',
}
