import { CleanupOffice, FetchOfficesRequest, SelectOfficeRequest } from './action.model'

export const FETCH_OFFICES_REQUEST = 'FETCH_OFFICES_REQUEST'
export const FETCH_OFFICES_SUCCESS = 'FETCH_OFFICES_SUCCESS'
export const FETCH_OFFICES_FAILURE = 'FETCH_OFFICES_FAILURE'
export const SELECT_OFFICE_REQUEST = 'SELECT_OFFICE_REQUEST'
export const SELECT_OFFICE_SUCCESS = 'SELECT_OFFICE_SUCCESS'
export const SELECT_OFFICE_FAILURE = 'SELECT_OFFICE_FAILURE'
export const CLEANUP_OFFICE = 'CLEANUP_OFFICE'

export const fetchOfficesRequest = (): FetchOfficesRequest => {
  return { type: FETCH_OFFICES_REQUEST }
}

export const selectOfficeRequest = (officeId: number): SelectOfficeRequest => {
  return { type: SELECT_OFFICE_REQUEST, payload: officeId }
}

export const cleanupOffice = (): CleanupOffice => {
  return { type: CLEANUP_OFFICE }
}
