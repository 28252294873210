import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const LogListWrapper = styled.div`
  .log-list-table {
    div.ant-table-body {
      overflow-y: auto !important;
      min-height: 200px;
    }

    .ant-table-tbody {
      > tr {
        cursor: pointer;
        &.selected {
          td {
            background: #eaf2fd;
          }
        }
      }
    }
  }

  .top-template {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
`

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const Label = styled.label`
  margin-right: 10px;
`

export const DateRangePickerClass = css`
  max-width: 293px;
`
