import { Button, Spin } from 'aa_common/front-end/antd'
import { BasicModal, ColorLabel, ConfirmModal, If, LinkLabel } from 'aa_common/front-end/components'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { Checkbox } from 'antd'
import * as departmentApi from 'api/app/department'
import { DATE_FORMAT, DATE_FORMAT_RFC, FEATURES_ID } from 'common/constants'
import { tryEditForm } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { LineAddNew, NoDataImage } from 'components/atoms'
import { BasicForm } from 'components/molecules'
import useResource from 'lib/hooks/useResource'
import useUserPermission from 'lib/hooks/useUserPermission'
import { first, get } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { deleteDepartment, insertDepartment, updateDepartment } from 'store/settings/department/actions'
import {
  DEPARTMENT_DETAIL_MODE,
  DepartmentItem,
  DepartmentItemOnCloud,
  IDepartmentDataItem,
  LatestRecord,
  PrepressCategories,
  TYPE_CONFIRMING_MODAL,
} from 'store/settings/department/model'

import AddValidToModal from './AddValidToModal'
import DetailTable from './DetailTable'
import { getFormData } from './helpers'
import {
  ActionListWrapper,
  ButtonActionGroup,
  DetailInfoGroup,
  EmptyImage,
  ModalContent,
  SearchFilterDatePicker,
  Wrapper,
} from './styles'

const t = loadNs(['common', 'components/organisms/settings/department-form', 'components/organisms/settings/area-form'])

const initialValues: DepartmentItem = {
  id: null,
  biid: null,
  code: '',
  name: '',
  prepress_category_code: null,
  search_key: '',
  valid_from: null,
  valid_to: null,
  ref_id: null,
  ref_name: null,
  ref_code: null,
}

type Props = {
  values?: any
  prepressCategories: PrepressCategories[]
  activeId?: number | null
  departmentBiid: string
  currentMonthlyClosingDate: string
  listDepartmentOnCloud: DepartmentItemOnCloud[]
  loading?: boolean
  onClearErrors: () => void
}

export const initialLatestRecordValue = {
  validToLatestRecord: null,
  validFromLatestRecord: null,
  recordItem: null,
  isValidToChanged: false,
  validToBeforeChanged: null,
}

const currentDate = moment().format(DATE_FORMAT)

const DepartmentDetailTable = ({
  values,
  prepressCategories,
  activeId = null,
  loading = false,
  departmentBiid,
  currentMonthlyClosingDate,
  listDepartmentOnCloud,
  onClearErrors,
}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation<any>()

  const dateValuefield = location?.state?.dateValueField
  const { permissions: departmentPermission } = useUserPermission(FEATURES_ID.MASTER_DEPARTMENT)

  const [formDataList, setFormDataList] = useState<IDepartmentDataItem[]>([])
  const [editingFormItem, setEditingFormItem] = useState<IDepartmentDataItem | null>(null)
  const [activeDepartmentId, setActiveDepartmentId] = useState<number | null>(activeId)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)
  const [isInUsed, setIsInUsed] = useState(false)
  const [isDisplayAllDepartments, setIsDisplayAllDepartments] = useState(false)
  const [valueDateInput, setValueDateInput] = useState<string>(dateValuefield)
  const [latestRecord, setLatestRecord] = useState<LatestRecord>(initialLatestRecordValue)
  const [currentLatestRecord, setCurrentLatestRecord] = useState<DepartmentItem | null>(null)
  const [countItems, setCountItems] = useState({
    passItems: 0,
    futureItems: 0,
  })
  const [isEdit, setIsEdit] = useState(false)
  const [isAddNewDepartment, setIsAddNewDepartment] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [isClickOnAddValidTo, setIsClickOnAddValidTo] = useState(false)
  const [isCanNotAddValidToModal, setIsCanNotAddValidToModal] = useState(false)
  const [isEditLatestRecord, setIsEditLatestRecord] = useState(false)
  const [typeConfirmingModal, setTypeConfirmingModal] = useState<TYPE_CONFIRMING_MODAL | null>(null)

  const [destroyDryRunRes, destroyDryRun] = useResource(departmentApi.destroy, false)

  const isRemovingConfirmModal = typeConfirmingModal === TYPE_CONFIRMING_MODAL.REMOVING_VALID_TO

  const msgCannotEdit = t('messages.can_not_action_because_of_unsave')

  const handleEdit = (departmentId: number) => {
    if (!tryEditForm(editingFormItem, msgCannotEdit)) {
      const formDataItem = formDataList.find(item => item.id === departmentId)

      if (formDataItem) {
        formDataItem.status = DEPARTMENT_DETAIL_MODE.EDIT
      }

      formDataItem && setEditingFormItem(formDataItem)
      setFormDataList([...formDataList])
    }
  }

  const handleCancelEdit = (departmentId: number | null) => {
    if (departmentId) {
      const formDataItem = formDataList.find(item => item.id === departmentId)
      if (formDataItem) {
        formDataItem.status = DEPARTMENT_DETAIL_MODE.VIEW
      }
      setFormDataList([...formDataList])
    } else {
      setFormDataList(formDataList.filter((item: IDepartmentDataItem) => item.data.id))
    }

    setEditingFormItem(null)
    onClearErrors()
    activeDepartmentId && setActiveDepartmentId(null)
    setIsAddNewDepartment(false)
    setIsEdit(false)
    setIsCopy(false)
    setIsEditLatestRecord(false)
    isAddNewDepartment &&
      setLatestRecord({
        ...initialLatestRecordValue,
        recordItem: currentLatestRecord,
      })
  }

  const handleSubmitForm = (formData: DepartmentItem) => {
    const isInsertNewLatestRecordByEdit =
      currentLatestRecord?.id === formData?.id &&
      isEditLatestRecord &&
      !currentLatestRecord?.valid_to &&
      currentLatestRecord?.valid_from !== formData?.valid_from

    if (latestRecord?.isValidToChanged) {
      const id = latestRecord.recordItem?.id

      if (latestRecord?.recordItem?.valid_to === null) {
        setTypeConfirmingModal(TYPE_CONFIRMING_MODAL.REMOVING_VALID_TO)
      } else if (latestRecord?.recordItem?.valid_to) {
        setTypeConfirmingModal(TYPE_CONFIRMING_MODAL.UPDATING_VALID_TO)
      }
      id && handleCancelEdit(id)
    } else {
      const callback = {
        setIsAddNewDepartment,
        setIsCopy,
      }
      if (isInsertNewLatestRecordByEdit) {
        const currentValue = { ...formData, id_after: formData?.id, id: null }

        dispatch(insertDepartment(currentValue, callback))
      } else {
        if (formData.id) {
          dispatch(updateDepartment(formData?.id, formData, callback))
        } else {
          dispatch(insertDepartment(formData, callback))
        }

        activeDepartmentId && setActiveDepartmentId(null)
        setIsEdit(false)
      }
    }

    setIsEditLatestRecord(false)
  }

  const havingNewFormItem = () => {
    const firstFormDataItem = first(formDataList)

    if (firstFormDataItem) {
      return !firstFormDataItem.data.id
    }

    return false
  }

  const handleClickOnEditLatestRecord = (departmentId: number) => {
    handleEdit(departmentId)
    setIsEditLatestRecord(true)
  }

  /**
   * DELETING - STARTING
   */
  const handleClickDelete = useCallback(() => {
    setIsConfirmingDelete(true)
  }, [])

  const handleCancelDelete = useCallback(() => {
    setIsConfirmingDelete(false)
  }, [])

  const submitDelete = () => {
    const payload = {
      biid: departmentBiid,
      original_name: get(values[0], 'name'),
    }

    dispatch(deleteDepartment(payload))
  }
  /**
   * DELETING - ENDING
   */

  /**
   * ADDING ABOLITION DATE FOR LATEST RECORD - STARTING
   */
  const handleClickOnAddValidToBtn = useCallback(() => {
    setIsClickOnAddValidTo(true)
  }, [])

  const handleCloseAddValidToModal = useCallback(() => {
    setIsClickOnAddValidTo(false)
    setLatestRecord({
      ...initialLatestRecordValue,
      recordItem: currentLatestRecord,
    })
  }, [isClickOnAddValidTo]) // eslint-disable-line

  const handleAddValidToModal = () => {
    setIsClickOnAddValidTo(false)
    const { recordItem, validToLatestRecord, validFromLatestRecord } = latestRecord
    const id = recordItem && get(recordItem, 'id')

    if (isInUsed) {
      setIsCanNotAddValidToModal(true)
    } else if (validToLatestRecord) {
      id && dispatch(updateDepartment(id, recordItem))
    } else {
      const currentLatestRecord = { ...recordItem, valid_to: validFromLatestRecord }
      id && dispatch(updateDepartment(id, currentLatestRecord))
    }
  }

  const closeCanNotAddValidToModal = () => {
    setIsCanNotAddValidToModal(false)
  }
  /**
   * ADDING ABOLITION DATE FOR LATEST RECORD - ENDING
   */

  // DISPLAY ALL CHECKBOX
  const handleDisplayAllStatus = (e: any) => {
    const { checked } = e.target

    setIsDisplayAllDepartments(checked)
  }

  // DATE PICKER
  const handleChangeDate = (event: any) => {
    const dateValue = event && event.format(DATE_FORMAT)

    setValueDateInput(dateValue)
  }

  // ADD NEW
  const handleAddNew = () => {
    if (!tryEditForm(editingFormItem, msgCannotEdit)) {
      const firstFormDataItem: any = first(formDataList)
      const validFromValue = firstFormDataItem.data.valid_to
        ? moment(firstFormDataItem.data.valid_to).add(1, 'days')
        : moment(firstFormDataItem.data.valid_from).add(1, 'days')

      const formData: IDepartmentDataItem = {
        isAddFirst: true,
        id: moment().valueOf(),
        status: DEPARTMENT_DETAIL_MODE.EDIT,
        data: {
          ...initialValues,
          code: firstFormDataItem.data.code,
          valid_from: validFromValue,
          valid_from_min: moment(validFromValue),
          biid: firstFormDataItem.data.biid,
          id_after: firstFormDataItem.id,
        },
      }

      setFormDataList([formData, ...formDataList])
      setEditingFormItem(formData)
      setIsAddNewDepartment(true)
    }
  }

  const handleAppendNewFormItem = (afterFormItem: IDepartmentDataItem) => {
    if (!tryEditForm(editingFormItem, msgCannotEdit)) {
      const index = formDataList.findIndex((item: any) => item.id === afterFormItem.id)
      const beforeFormItem = formDataList[index + 1]
      const validFromValue = beforeFormItem
        ? moment(beforeFormItem.data.valid_from).add(1, 'days')
        : moment(afterFormItem.data.valid_from).subtract(1, 'days')
      let validToValue = beforeFormItem ? moment(beforeFormItem.data.valid_to).subtract(1, 'days') : validFromValue

      if (validToValue < validFromValue || !afterFormItem.data.valid_to) validToValue = validFromValue

      const formData: IDepartmentDataItem = {
        id: moment().valueOf(),
        status: DEPARTMENT_DETAIL_MODE.EDIT,
        data: {
          ...initialValues,
          code: afterFormItem.data.code,
          valid_from: validFromValue,
          valid_from_min: beforeFormItem ? moment(validFromValue) : null,
          valid_to: validToValue,
          valid_to_max: moment(afterFormItem.data.valid_to).subtract(1, 'day'),
          biid: afterFormItem.data.biid,
          id_before: afterFormItem.id,
          id_after: get(beforeFormItem, 'id'),
        },
      }

      formDataList.splice(index + 1, 0, formData)
      setEditingFormItem(formData)
      setFormDataList([...formDataList])
      setIsAddNewDepartment(true)
    }
  }

  /**
   * CONFIRMING CHANGE VALID_TO FOR LATEST RECORD - STARTING
   */
  const changeValidToLatestRecord = () => {
    const { recordItem } = latestRecord
    const id = get(recordItem, 'id')

    id && dispatch(updateDepartment(id, recordItem))
    setTypeConfirmingModal(null)
  }

  const closeConfirmingChangeValidToModal = () => {
    setTypeConfirmingModal(null)
  }
  /**
   * CONFIRMING CHANGE VALID_TO FOR LATEST RECORD - ENDING
   */
  useEffect(() => {
    if (values) {
      const newestValidFrom = values?.map(({ valid_from }: any) => moment(valid_from))
      const maxValidFromDate = moment.max(newestValidFrom).format(DATE_FORMAT_RFC)

      const newestRecord = defaultTo(
        values.filter((item: any) => item?.valid_from === maxValidFromDate),
        []
      )
      const record = newestRecord[0]

      setLatestRecord({
        validFromLatestRecord: maxValidFromDate,
        validToLatestRecord: record?.valid_to,
        recordItem: record,
        validToBeforeChanged: record?.valid_to,
      })

      setCurrentLatestRecord(record)
    }
  }, [values])

  useEffect(() => {
    setEditingFormItem(null)

    if (values) {
      const currentTime = moment().valueOf()
      const newDataList = []

      for (let i = 0; i < values.length; i++) {
        const { formData, isActiveItem } = getFormData(
          initialValues,
          values[i],
          values[i + 1],
          values[i - 1],
          activeDepartmentId,
          currentTime
        )
        isActiveItem && setEditingFormItem(formData)
        newDataList.push(formData)
      }
      const filterDataList = newDataList.filter((item: IDepartmentDataItem) => {
        const { valid_from, valid_to } = item.data

        if (valueDateInput && valid_to && (valid_from as string) <= valueDateInput && valueDateInput <= valid_to) {
          return item
        }
        if (valueDateInput && !valid_to && (valid_from as string) <= valueDateInput) {
          return item
        }
        return false
      })

      const passItems = newDataList.filter(({ data }: IDepartmentDataItem) => {
        const { valid_from } = data

        return moment(valid_from).isBefore(currentDate)
      }).length

      const futureItems = newDataList.filter(({ data }: IDepartmentDataItem) => {
        const { valid_from } = data

        return moment(valid_from).isAfter(currentDate)
      }).length

      setCountItems({
        passItems,
        futureItems,
      })

      isDisplayAllDepartments ? setFormDataList(newDataList) : setFormDataList(filterDataList)
    }
  }, [values, valueDateInput, isDisplayAllDepartments]) // eslint-disable-line

  useEffect(() => {
    departmentBiid && destroyDryRun(departmentBiid, true)
  }, [departmentBiid, destroyDryRun])

  useEffect(() => {
    destroyDryRunRes.data && setIsInUsed(false)
    destroyDryRunRes.error && setIsInUsed(true)
  }, [destroyDryRunRes])

  const idCurrentHistory = get(formDataList[0]?.data, 'id')
  const isEmptyHistory = formDataList.length === 0

  const idLatestRecord = get(currentLatestRecord, 'id')
  const latestValidTo = get(currentLatestRecord, 'valid_to')
  const isCurrentLatestRecordValidTo = !!latestValidTo
  const isLatestRecordItem = idCurrentHistory === idLatestRecord && !!idLatestRecord

  const isDisableAbolitionButton = (!isDisplayAllDepartments && !isLatestRecordItem) || isCurrentLatestRecordValidTo
  const isDisableEditLatestRecordBtn = isDisplayAllDepartments || isEmptyHistory
  const isEnableTopInsertButton = !havingNewFormItem() && isDisplayAllDepartments && !isCurrentLatestRecordValidTo

  return (
    <Spin loading={loading}>
      <Wrapper>
        {isCurrentLatestRecordValidTo && (
          <div
            style={{
              marginBottom: '15px',
            }}
          >
            {t('status.abolition_date', {
              time: latestValidTo,
            })}
          </div>
        )}
        <ActionListWrapper
          style={{
            marginBottom: `${ifElse(!isDisplayAllDepartments || isCurrentLatestRecordValidTo, '10px', '5px')}`,
          }}
        >
          <DetailInfoGroup>
            <SearchFilterDatePicker
              disabled={loading || isDisplayAllDepartments}
              format={DATE_FORMAT}
              onChange={handleChangeDate}
              defaultValue={moment(valueDateInput)}
              allowClear={false}
            />
            <span className="display-status" style={{ marginLeft: 5 }}>
              {t('list_display_status')}
            </span>
            <Checkbox
              style={{
                marginLeft: '20px',
              }}
              name="display-all"
              checked={isDisplayAllDepartments}
              onChange={handleDisplayAllStatus}
              className="display-all-check-box"
            >
              <span style={{ fontSize: 13 }}>
                {t('format.display_all_with_count', {
                  past_count: countItems.passItems,
                  future_count: countItems.futureItems,
                })}
              </span>
            </Checkbox>
          </DetailInfoGroup>
          <ButtonActionGroup>
            <Button
              color="grey"
              className={ifElse(isDisableAbolitionButton, 'disabled', 'btn-abolition')}
              size="custom"
              disabled={isDisableAbolitionButton || !departmentPermission.isDelete}
              onClick={handleClickOnAddValidToBtn}
              showTooltipMessage={ifElse(!departmentPermission.isDelete, t('actions.no_permission'), undefined)}
            >
              {t('actions.abolition')}
            </Button>
            <Button
              color="grey"
              className={ifElse(isDisableEditLatestRecordBtn, 'disabled', 'btn-edit')}
              size="custom"
              disabled={isDisableEditLatestRecordBtn || !departmentPermission.isEdit}
              onClick={() => handleClickOnEditLatestRecord(formDataList[0] && formDataList[0].id)}
              showTooltipMessage={ifElse(!departmentPermission.isEdit, t('actions.no_permission'), undefined)}
            >
              {t('actions.edit')}
            </Button>
          </ButtonActionGroup>
        </ActionListWrapper>
        <If condition={isEnableTopInsertButton}>
          <LineAddNew
            onClick={handleAddNew}
            showTooltipMessage={ifElse(!departmentPermission.isCreate, t('actions.no_permission'), undefined)}
            disabled={!departmentPermission.isCreate}
          />
        </If>
        {formDataList.map((formDataItem: IDepartmentDataItem) => {
          return (
            <div key={formDataItem.key || formDataItem.id}>
              <Wrapper>
                <BasicForm initialValues={formDataItem.data} onSubmit={handleSubmitForm}>
                  <DetailTable
                    mode={formDataItem.status}
                    detailData={formDataItem.data}
                    loading={loading}
                    prepressCategories={prepressCategories}
                    currentMonthlyClosingDate={currentMonthlyClosingDate}
                    isDisplayAllDepartments={isDisplayAllDepartments}
                    departmentId={formDataItem.id}
                    isEdit={isEdit}
                    isCopy={isCopy}
                    isAddNewDepartment={isAddNewDepartment}
                    formDataList={formDataList}
                    isEditLatestRecord={isEditLatestRecord}
                    latestRecord={latestRecord}
                    onHandleSubmit={handleSubmitForm}
                    setIsEdit={setIsEdit}
                    setIsCopy={setIsCopy}
                    onEdit={handleEdit}
                    onCancel={handleCancelEdit}
                    setIsEditLatestRecord={setIsEditLatestRecord}
                    setLatestRecord={setLatestRecord}
                    formDataItem={formDataItem}
                    listDepartmentOnCloud={listDepartmentOnCloud}
                    hasEditPermission={departmentPermission.isEdit}
                    hasDeletePermission={departmentPermission.isDelete}
                  />

                  <If condition={formDataItem.data.id && isDisplayAllDepartments}>
                    <LineAddNew
                      onClick={() => handleAppendNewFormItem(formDataItem)}
                      showTooltipMessage={ifElse(!departmentPermission.isCreate, t('actions.no_permission'), undefined)}
                      disabled={!departmentPermission.isCreate}
                    />
                  </If>
                </BasicForm>
              </Wrapper>
            </div>
          )
        })}
        {!formDataList?.length && (
          <EmptyImage>
            <NoDataImage text={t('msg_no_data')} />
          </EmptyImage>
        )}
        {/* DELETE - STARTING */}
        {!loading && formDataList?.length !== 0 && (
          <>
            <LinkLabel
              showTooltipMessage={ifElse(!departmentPermission.isDelete, t('actions.no_permission'), undefined)}
              type="danger"
              disabled={isInUsed || !departmentPermission.isDelete}
              onClick={handleClickDelete}
            >
              {t('status.delete_all_histories')}
            </LinkLabel>
            <If condition={isInUsed}>
              <ColorLabel color="notice" style={{ marginLeft: 5 }}>
                {t('status.cannot_delete')}
              </ColorLabel>
            </If>
          </>
        )}
        {/* DELETE - ENDING */}
        <ConfirmModal
          title={ifElse(isCanNotAddValidToModal, t('modal_titles.abolition_could_not_be_registered'), '')}
          message={ifElse(
            isCanNotAddValidToModal,
            t('modal_messages.abolition_could_not_be_registered'),
            t('modal_messages.confirm_delete')
          )}
          visible={isConfirmingDelete || isCanNotAddValidToModal}
          type={ifElse(isCanNotAddValidToModal, ConfirmModal.CONFIRM_TYPES.CONFIRM, ConfirmModal.CONFIRM_TYPES.DELETE)}
          onOK={ifElse(isCanNotAddValidToModal, closeCanNotAddValidToModal, submitDelete)}
          onCancel={ifElse(isCanNotAddValidToModal, closeCanNotAddValidToModal, handleCancelDelete)}
          isLoading={loading}
        />
        {/* CONFIRMING MODAL - ENDING */}

        {/* UPDATING VALID TO CONFIRMING MODAL - STARTING */}
        <ConfirmModal
          title={ifElse(
            isRemovingConfirmModal,
            t('removing_valid_to_confirming_title'),
            t('updating_valid_to_confirming_title')
          )}
          message={ifElse(
            isRemovingConfirmModal,
            t('removing_valid_to_confirming_message'),
            t('updating_valid_to_confirming_message')
          )}
          visible={typeConfirmingModal !== null}
          type={ConfirmModal.CONFIRM_TYPES.CONFIRM}
          onOK={changeValidToLatestRecord}
          onCancel={closeConfirmingChangeValidToModal}
        />
        {/* UPDATING VALID TO CONFIRMING MODAL - ENDING */}

        {/* ADD VALID TO MODAL - STARTING */}
        <BasicModal
          title={t('modal_titles.abolition_add_valid_to_title')}
          isShow={isClickOnAddValidTo}
          allowOutsideClick
          className="add-valid-to"
          onCancel={handleCloseAddValidToModal}
          width={540}
          bodyStyle={{
            padding: '15px 0',
          }}
        >
          <ModalContent>
            <BasicForm initialValues={formDataList[0]} onSubmit={handleAddValidToModal}>
              <AddValidToModal
                onHandleCloseAddValidToModal={handleCloseAddValidToModal}
                setLatestRecord={setLatestRecord}
                latestRecord={latestRecord}
              />
            </BasicForm>
          </ModalContent>
        </BasicModal>
        {/* ADD VALID TO MODAL - ENDING */}
      </Wrapper>
    </Spin>
  )
}

export default DepartmentDetailTable
