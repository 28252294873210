import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { RequiredLabel } from '../required-label'
import { ItemContent, ItemLabel, ItemWrapper } from './styles'

type Props = {
  label: string | ReactNode
  contentWidth?: number
  required?: boolean
  style?: React.CSSProperties
  jsLabel?: string
  requiredClassName?: string
}

const Item: React.FC<Props> = ({
  children,
  label,
  contentWidth = 240,
  required = false,
  style,
  jsLabel,
  requiredClassName,
}) => {
  return (
    <ItemWrapper className="item" style={style} data-js-label={jsLabel || label}>
      <ItemLabel className="label">{label}</ItemLabel>
      <ItemContent className="content" style={{ width: contentWidth }}>
        {children}
        {required && <RequiredLabel className={classNames('required', requiredClassName)} />}
      </ItemContent>
    </ItemWrapper>
  )
}

export default Item
