import styled from '@emotion/styled'

interface StyledWrapperProps {
  widthContent?: string
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  border-bottom: 1px solid #d4d8dd;

  .underline-text-content {
    ${props => props.widthContent && `width: ${props.widthContent};`}
    font-size: 13px;
    color: #7c8291;
    padding-bottom: 4px;
    position: relative;

    .underline-loading {
      position: absolute;
      bottom: -1px;
      width: 100%;
      > div {
        margin: 0;
        .progress-bar {
          height: 1px;
        }
      }
    }
  }
  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }
`
