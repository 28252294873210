import { css } from '@emotion/css'

export const DefaultTooltipOverlay = css`
  max-width: 400px;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: rgba(0, 0, 0, 0.75);
      font-weight: 500;
      white-space: pre-line;
      padding: 10px;
      border-radius: 4px;
      font-size: 13px;
    }
  }
`
