import { useCallback, useEffect, useState } from 'react'

export function useResetInput(
  fieldName: string,
  value: any,
  setFieldValue: (field: string, value: any) => void,
  initialValue?: any,
  hasInitialValue?: (value: any) => boolean
) {
  const [hasUpdate, changeHaveUpdate] = useState(false)

  const handleReset = useCallback(() => {
    setFieldValue(fieldName, initialValue)
    changeHaveUpdate(false)
  }, [fieldName, initialValue, changeHaveUpdate, setFieldValue])

  useEffect(() => {
    changeHaveUpdate(Boolean(hasInitialValue?.(initialValue) && initialValue !== value))
  }, [initialValue, value])

  return {
    hasUpdate,
    handleReset,
  }
}
