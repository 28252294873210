import React from 'react'
import { useFormContext } from 'react-hook-form'

import FormField from '../../../form/form-field'
import LinkListField from '../../../form/form-link-list-field'
import { UploadAttachment } from '../../../form/form-upload-attachment'
import { UploadImage } from '../../../form/form-upload-image'
import { EventLinkPhotoProps } from './schema'

export const EventLinkPhoto = (props: EventLinkPhotoProps) => {
  const { photo, attachment, links } = props
  const { control } = useFormContext()

  return (
    <div {...props}>
      <FormField className="align-start" title={photo.title}>
        <UploadImage name={photo.name} control={control} />
      </FormField>
      <FormField className="align-start" title={attachment.title}>
        <UploadAttachment name={attachment.name} />
      </FormField>
      <FormField className="align-start" title={links.title}>
        <LinkListField name={links.name} control={control} placeholder={links.placeholder ?? ''} />
      </FormField>
    </div>
  )
}
