import base from './base'

export const list = (assetBiid: string) => {
  return base.get(`v1/fixed_assets/${assetBiid}/comments`)
}

export const getCommentCountByFixedAssets = (fixedAssetBiids: string[]) => {
  return base.post('v1/comments/count_by_fixed_assets', {
    fixed_asset_biids: fixedAssetBiids,
  })
}

export const create = (assetBiid: string, content: any) => {
  return base.post(`v1/fixed_assets/${assetBiid}/comments`, {
    content,
  })
}

export const update = (id: number, content: any) => {
  return base.put(`v1/comments/${id}`, {
    content,
  })
}

export const destroy = (id: number) => {
  return base.delete(`v1/comments/${id}`)
}
