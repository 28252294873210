import { Message } from './model'

export const selectAppMessage = (state: any): Message => {
  return state.getIn(['app', 'message'])
}

export const selectInUsedMessage = (state: any) => {
  return state.getIn(['app', 'inUsedMessage'])
}

export const selectInitialSuccess = (state: any): boolean => {
  return state.getIn(['app', 'initialSuccess'])
}

export const selectNavigationSelectedNavItem = (state: any) => {
  return state.getIn(['app', 'navigation', 'selectedNavItem'])
}

export const selectLoadingApp = (state: any): boolean => {
  return state.getIn(['app', 'loadingApp'])
}

export const selectFlagDisableNavigation = (state: any) => {
  return state.getIn(['app', 'navigation', 'isDisabled'])
}
