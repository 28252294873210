import { loadNs } from 'common/i18n-config'
import { Input } from 'components/atoms'
import { AutoCompleteSelect, AutoCompleteText, FilterPanel } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import React from 'react'

const t = loadNs(['components/organisms/settings/asset-category-filter', 'common'])

const { Row, Field, Label, Symbol } = FilterPanel

type Props = {
  masterData: any
  loading: boolean
  onSubmit?: (values: any) => void
}

const AssetCategoryFilter = ({ masterData, loading, onSubmit }: Props) => {
  const initialValues = {
    code: '',
    name: '',
    excise_id: null,
    depreciation_method: null,
    asset_type_for_depreciable_property_tax: null,
    asset_type_for_corporate_tax: null,
    asset_structure_for_corporate_tax: '',
    asset_detail_for_corporate_tax: '',
    search_key: '',
  }

  return (
    <FilterPanel initialValues={initialValues} loading={loading} onSubmit={onSubmit}>
      <Row>
        <Field>
          <Label>{t('code')}</Label>
          <Input name="code" disabled={loading} maxLength={10} />
        </Field>

        <Field>
          <Label>{t('name')}</Label>
          <Input name="name" disabled={loading} maxLength={50} />
        </Field>

        <Field>
          <Label>{t('consumption_tax_classification')}</Label>
          <ExciseSelect
            style={{
              width: '140px',
            }}
            name="excise_id"
            allowClear
          />
        </Field>

        <Field>
          <Label>{t('depreciation_method')}</Label>
          <AutoCompleteSelect
            name="depreciation_method"
            disabled={loading}
            items={masterData?.depreciation_method}
            valueField="code"
            textField="name_jp"
          />
        </Field>
      </Row>
      <Row>
        <Field>
          <Label>{t('asset_type_for_depreciable_property_tax')}</Label>
          <AutoCompleteSelect
            name="asset_type_for_depreciable_property_tax"
            disabled={loading}
            items={masterData?.asset_type_for_depreciable_property_tax}
            valueField="code"
            textField="name_jp"
          />
        </Field>

        <Field>
          <Label>{t('asset_type_for_corporate_tax')}</Label>
          <AutoCompleteSelect
            name="asset_type_for_corporate_tax"
            disabled={loading}
            items={masterData?.asset_type_for_corporate_tax}
            valueField="code"
            textField="name_jp"
          />
        </Field>
        <Field>
          <Label>&nbsp;</Label>
          <AutoCompleteText name="asset_structure_for_corporate_tax" disabled={loading} maxLength={50} />
          <Symbol>-</Symbol>
        </Field>
        <Field>
          <Label>&nbsp;</Label>
          <AutoCompleteText name="asset_detail_for_corporate_tax" disabled={loading} maxLength={50} />
          <Symbol>-</Symbol>
        </Field>
        <Field>
          <Label>{t('search_key')}</Label>
          <Input name="search_key" disabled={loading} maxLength={20} />
        </Field>
      </Row>
    </FilterPanel>
  )
}

export default AssetCategoryFilter
