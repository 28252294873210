import styled from '@emotion/styled'
import { BLUE_COLOR } from 'common/constants'

export const Wrapper = styled.div`
  display: inline-flex;
  color: ${BLUE_COLOR};
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`

export const Label = styled.label`
  margin-left: 4px;
  cursor: pointer;
`
