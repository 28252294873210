import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface RouterTrapProps {
  path: string
  onEnter?(): void
  onLeave?(): void
}

export default function withRouterTrap<T>(Component: React.ComponentType<T>) {
  const WithRouterTrap = (props: T & RouterTrapProps) => {
    const history = useHistory()
    const { path, onEnter, onLeave } = props

    useEffect(() => {
      onEnter && onEnter()
      return history.listen(location => {
        if (!location?.pathname?.startsWith(path)) {
          onLeave && onLeave()
        }
      })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <Component {...props} />
  }
  return WithRouterTrap
}
