import { Aggregation, MoneyUnits } from 'common/models/depreciation'
import i18n from 'i18n'

export const UNITS = [
  {
    label: i18n.t('common.units.yen'),
    value: MoneyUnits.YEN,
  },
  {
    label: i18n.t('common.units.thousand_yen'),
    value: MoneyUnits.THOUSAND_YEN,
  },
]

export const AGGREGATIONS = [
  {
    label: i18n.t('components.DepreciationResultList.depreciation_calculation'),
    value: Aggregation.DEPRECIATION_CALCULATION,
    disabled: false,
  },
  {
    label: i18n.t('components.DepreciationResultList.journal_result'),
    value: Aggregation.JOURNAL_RESULT,
    disabled: false,
  },
]
