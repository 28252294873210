import { Spin } from 'aa_common/front-end/antd'
import { getExportFixedAssetHistories } from 'api/app/depreciationFixedAsset'
import useResource from 'lib/hooks/useResource'
import React, { useEffect } from 'react'

import { Modal } from './styles'

type Props = {
  key: any
  id: number
  name: string
  taxYear: number
}

const HistoryList = ({ key, id, name, taxYear }: Props) => {
  const [{ isLoading, data: histories }, getExportHistories] = useResource(getExportFixedAssetHistories)

  useEffect(() => {
    getExportHistories(id, taxYear)
  }, [getExportHistories, id, taxYear, key])

  return (
    <Modal style={isLoading ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : undefined}>
      <Spin loading={isLoading}>
        {histories &&
          histories.map((hist: any) => {
            return (
              <div key={hist.id}>
                <span style={{ marginRight: '10px' }}>{name}</span>
                <span style={{ marginRight: '10px' }}>{hist.created_by}</span>
                <a href={hist.url} target="_blank" rel="noreferrer" style={{ cursor: 'pointer', color: '#3B7CE9' }}>
                  {hist.file_name}
                </a>
              </div>
            )
          })}
      </Spin>
    </Modal>
  )
}

export default HistoryList
