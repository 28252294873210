import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { Input, RegexInput } from 'components/atoms'
import { AutoCompleteSelect, AutoCompleteText, BasicForm } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import { getIn, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchExciseList } from 'store/settings/excise/actions'

import { validateForm, ValueFields } from './helpers'
import { useDepreciationChanged } from './hooks'
import { ServiceLife } from './ServiceLife'
import { InlineWrapper } from './styles'

const t = loadNs([
  'common',
  'components/organisms/settings/asset-category-list',
  'components/organisms/assets/asset-form',
  'components/organisms/settings/asset-category-form',
])

const { Group, Header, Item, Divider, SubmitButton } = BasicForm

type Props = {
  masterData: any
  type: FORM_MODES
}

const Form: React.FC<Props> = ({ masterData, type }) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext<ValueFields>()
  const isValidFields = validateForm(values)
  const depreciationCode = getIn(values, 'depreciation_method')

  useEffect(() => {
    const filters = {
      is_active: true,
    }
    dispatch(fetchExciseList({ filters }))
  }, [dispatch])

  const onDepreciationChanged = useDepreciationChanged(type, getIn(values, 'service_life'))

  return (
    <>
      <Group>
        <Header>{t('form_title')}</Header>
        <Item required label={t('code')}>
          <RegexInput name="code" placeholder={t('placeholder.under_10')} maxLength={10} />
        </Item>
        <Item required label={t('name')}>
          <Input name="name" placeholder={t('placeholder.under_50')} maxLength={50} />
        </Item>
        <Item required label={t('type')}>
          <AutoCompleteSelect
            name="asset_type"
            items={masterData && masterData.asset_type}
            valueField="code"
            textField="name_jp"
          />
        </Item>
        <Divider>{t('divider_content')}</Divider>
        <Item label={t('excise')}>
          <ExciseSelect style={{ width: 400 }} name="excise_id" placeholder={t('excise_placeholder')} />
        </Item>
        <Item label={t('depreciation_method')}>
          <AutoCompleteSelect
            name="depreciation_method"
            items={masterData && masterData.depreciation_method}
            valueField="code"
            textField="name_jp"
            placeholder={t('depreciation_method_placeholder')}
            afterChanged={onDepreciationChanged}
          />
        </Item>
        <ServiceLife depreciationCode={depreciationCode} />
        <Item label={t('asset_type_for_depreciable_property_tax')}>
          <AutoCompleteSelect
            name="asset_type_for_depreciable_property_tax"
            items={masterData && masterData.asset_type_for_depreciable_property_tax}
            valueField="code"
            textField="name_jp"
            placeholder={t('asset_type_for_depreciable_property_tax_placeholder')}
          />
        </Item>
        <Item label={t('asset_type_for_corporate_tax')}>
          <InlineWrapper>
            <AutoCompleteSelect
              name="asset_type_for_corporate_tax"
              items={masterData && masterData.asset_type_for_corporate_tax}
              valueField="code"
              textField="name_jp"
              placeholder={t('asset_type_for_corporate_tax_placeholder')}
              style={{ width: 144 }}
            />{' '}
            -
            <AutoCompleteText
              name="asset_structure_for_corporate_tax"
              placeholder={t('asset_structure_for_corporate_tax_placeholder')}
              style={{ width: 103 }}
              maxLength={50}
            />{' '}
            -
            <AutoCompleteText
              name="asset_detail_for_corporate_tax"
              placeholder={t('asset_detail_for_corporate_tax_placeholder')}
              style={{ width: 101 }}
              maxLength={50}
            />
          </InlineWrapper>
        </Item>
        <Item className="last" label={t('search_key')}>
          <Input name="search_key" placeholder={t('placeholder.under_20')} maxLength={20} />
        </Item>
      </Group>
      <SubmitButton isDisableBtn={!isValidFields} />
    </>
  )
}

export default Form
