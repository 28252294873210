import {
  FetchLedgerSettingsListFailure,
  FetchLedgerSettingsListRequest,
  FetchLedgerSettingsListSuccess,
  SaveLedgerSettingsListFailure,
  SaveLedgerSettingsListRequest,
  SaveLedgerSettingsListSuccess,
} from './action.model'
import {
  CLEANUP_LEDGER_SETTINGS,
  FETCH_LEDGER_SETTINGS_LIST_FAILURE,
  FETCH_LEDGER_SETTINGS_LIST_REQUEST,
  FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH,
  FETCH_LEDGER_SETTINGS_LIST_SUCCESS,
  SAVE_HAS_ACCOUNTANT_AND_TAX_LEDGER,
  SAVE_LEDGER_SETTINGS_LIST_FAILURE,
  SAVE_LEDGER_SETTINGS_LIST_REQUEST,
  SAVE_LEDGER_SETTINGS_LIST_SUCCESS,
} from './constant'
import { FetchLedgerSettingsListPayload, SaveLedgerSettingsListPayload } from './model'

export const fetchLedgerSettingsList = (payload?: FetchLedgerSettingsListPayload): FetchLedgerSettingsListRequest => ({
  type: FETCH_LEDGER_SETTINGS_LIST_REQUEST,
  payload,
})

export const fetchLedgerSettingsListAuth = (payload?: FetchLedgerSettingsListPayload) => ({
  type: FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH,
  payload,
})

export const fetchLedgerSettingsListSuccess = (payload: any): FetchLedgerSettingsListSuccess => ({
  type: FETCH_LEDGER_SETTINGS_LIST_SUCCESS,
  payload,
})

export const fetchLedgerSettingsListFailure = (): FetchLedgerSettingsListFailure => ({
  type: FETCH_LEDGER_SETTINGS_LIST_FAILURE,
})

export const saveLedgerSettingsList = (payload: SaveLedgerSettingsListPayload): SaveLedgerSettingsListRequest => ({
  type: SAVE_LEDGER_SETTINGS_LIST_REQUEST,
  payload,
})

export const saveLedgerSettingsListSucess = (payload: any): SaveLedgerSettingsListSuccess => ({
  type: SAVE_LEDGER_SETTINGS_LIST_SUCCESS,
  payload,
})

export const saveLedgerSettingsListFailure = (
  payload: SaveLedgerSettingsListPayload,
  errors: any
): SaveLedgerSettingsListFailure => ({
  type: SAVE_LEDGER_SETTINGS_LIST_FAILURE,
  payload,
  errors,
})

export const saveHasAccountantAndTaxLedger = (payload: boolean) => ({
  type: SAVE_HAS_ACCOUNTANT_AND_TAX_LEDGER,
  payload,
})

export const cleanupLedgerSettings = () => ({
  type: CLEANUP_LEDGER_SETTINGS,
})
