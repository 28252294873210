import get from 'lodash/get'

import { CarouselImageItem } from './model'

export const convertToCarouselImages = (
  inputArr: any[],
  imageUrlKey: string,
  descriptionKey: string
): CarouselImageItem[] => {
  if (!inputArr) return []

  return inputArr.map(item => {
    return {
      ...item,
      url: get(item, imageUrlKey),
      description: get(item, descriptionKey) || '',
    }
  })
}
