/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterResponse,
  PresenterTemporaryDataRequest,
  PresenterTemporaryDataResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Temporary<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description save temporary data
   *
   * @tags temporary
   * @name TempDataCreate
   * @summary Save the temporary data
   * @request POST:/temp_data
   */
  tempDataCreate = (body: PresenterTemporaryDataRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, PresenterError>({
      path: `/temp_data`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description save temporary fixed asset with name
   *
   * @tags temporary
   * @name TempDataDetail
   * @summary get saved data from redis
   * @request GET:/temp_data/{type}
   */
  tempDataDetail = (
    type?: 'create-fixed-asset' | 'edit-fixed-asset' | 'copy-fixed-asset',
    query?: {
      /** extra key of autosaved data. in edit-fixed-asset, it is biid of fixed_asset */
      extra_key?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterTemporaryDataResponse, PresenterError>({
      path: `/temp_data/${type}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description delete temporary fixed asset with name
   *
   * @tags temporary
   * @name TempDataDelete
   * @summary delete saved data from redis
   * @request DELETE:/temp_data/{type}
   */
  tempDataDelete = (
    type?: 'create-fixed-asset' | 'edit-fixed-asset' | 'copy-fixed-asset',
    query?: {
      /** extra key of autosaved data. in edit-fixed-asset, it is biid of fixed_asset */
      extra_key?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any, PresenterError>({
      path: `/temp_data/${type}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      ...params,
    })
}
