import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { CheckboxGroup } from 'aa_common/front-end/components'
import { OfficeUser, Role } from 'aa_common/front-end/models'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { RenderFormProps } from 'components/molecules/Form/index'
import { get } from 'lodash'
import React, { useMemo } from 'react'

import { CheckboxClass, Field, FormWrapper, Group } from './styles'
import SubmitArea from './SubmitArea'

const t = loadNs(['common', 'components/organisms/settings/user-form'])

interface CoreUserFormProps {
  roles: Role[]
  creatableUsers?: OfficeUser[]
  formModule: RenderFormProps
  type: FORM_MODES.NEW | FORM_MODES.EDIT
}

const CoreUserForm = ({ roles, creatableUsers, formModule, type }: CoreUserFormProps) => {
  const { loading } = formModule

  const convertedCreatableUsers = useMemo(() => {
    return (creatableUsers || []).map(item => ({
      textLabel: `${item?.name} ${item?.email}`,
      ...item,
    }))
  }, [creatableUsers])

  const renderUserName = () => {
    const isAddForm = type === FORM_MODES.NEW
    const values = formModule.getValues(['name', 'email'])
    return (
      <Field>
        <div className="label">
          <div className="label-text">
            <h4>{t('username_email')}</h4>
          </div>
          {isAddForm && (
            <div className="required-label-wrapper">
              <div className="required-label">
                <span>{t('required')}</span>
              </div>
            </div>
          )}
        </div>
        <div className="content">
          <div style={{ width: isAddForm ? 250 : '100%' }}>
            {isAddForm ? (
              <AutoCompleteSelect
                disabled={loading}
                name="id"
                valueField="id"
                textField="textLabel"
                items={convertedCreatableUsers}
                control={formModule.control}
                allowClear={false}
              />
            ) : (
              <span>{`${get(values, 'name') || ''} ${get(values, 'email') || ''}`}</span>
            )}
          </div>
        </div>
      </Field>
    )
  }

  const memoizedRoleOptions = useMemo(() => {
    return roles.map(item => ({ label: item.name, value: item.biid })) || []
  }, [roles])

  return (
    <>
      <FormWrapper>
        <Group>
          {renderUserName()}
          <Field className="roles">
            <div className="label">
              <div className="label-text">
                <h4>{t('role')}</h4>
              </div>
              <div className="required-label-wrapper">
                <div className="required-label">
                  <span>{t('required')}</span>
                </div>
              </div>
            </div>
            <div className="content">
              <div style={{ width: 250 }}>
                <CheckboxGroup
                  className={CheckboxClass}
                  disabled={loading}
                  name="role_biids"
                  options={memoizedRoleOptions}
                  control={formModule.control}
                />
              </div>
            </div>
          </Field>
        </Group>
      </FormWrapper>
      <SubmitArea
        formControl={formModule.control}
        loading={formModule.loading}
        submitText={type === FORM_MODES.EDIT ? t('save') : t('invite')}
      />
    </>
  )
}

export default CoreUserForm
