const { REACT_APP_NAVIS_SERVICE_URL } = process.env

export const buildNavisNewTenantURL = (email: string) => {
  const queries = new URLSearchParams({
    redirect_path: '/app/tenant_registration/new',
    login_hint: email,
    return_service: 'aa',
    return_path: '/offices/new',
  })
  if (REACT_APP_NAVIS_SERVICE_URL !== undefined) return `${REACT_APP_NAVIS_SERVICE_URL}/sso?${queries.toString()}`
  return ''
}
