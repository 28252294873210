import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 100%;
  clear: both;
  padding: 15px;
`

export const WrapperMessage = styled.div`
  background: #fff;
  padding: 10px;
  width: 423px;

  border: 1px solid #d4d8dd;
  box-sizing: border-box;
  border-radius: 4px;
`
