import LinkIcon from 'components/atoms/icons/LinkIcon'
import React from 'react'

import { AntLink, Label } from './styles'

type Props = {
  url: string
  style?: React.CSSProperties
  iconColor?: string
}

const ReferenceLink: React.FC<Props> = ({ url, style, iconColor }) => {
  return (
    <AntLink href={url} className="reference-link" target="_blank" style={style} ellipsis>
      <LinkIcon color={iconColor} />
      <Label>{url}</Label>
    </AntLink>
  )
}

export default ReferenceLink
