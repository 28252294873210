export const FETCH_OFFICE_USER_LIST_REQUEST = 'FETCH_OFFICE_USER_LIST_REQUEST'
export const FETCH_OFFICE_USER_LIST_SUCCESS = 'FETCH_OFFICE_USER_LIST_SUCCESS'
export const FETCH_OFFICE_USER_LIST_FAILURE = 'FETCH_OFFICE_USER_LIST_FAILURE'

export const FETCH_OFFICE_USER_ITEM_REQUEST = 'FETCH_OFFICE_USER_ITEM_REQUEST'
export const FETCH_OFFICE_USER_ITEM_SUCCESS = 'FETCH_OFFICE_USER_ITEM_SUCCESS'
export const FETCH_OFFICE_USER_ITEM_FAILURE = 'FETCH_OFFICE_USER_ITEM_FAILURE'

export const FETCH_CREATABLE_OFFICE_USERS_REQUEST = 'FETCH_CREATABLE_OFFICE_USERS_REQUEST'
export const FETCH_CREATABLE_OFFICE_USERS_SUCCESS = 'FETCH_CREATABLE_OFFICE_USERS_SUCCESS'
export const FETCH_CREATABLE_OFFICE_USERS_FAILURE = 'FETCH_CREATABLE_OFFICE_USERS_FAILURE'

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST'
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS'
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE'

export const ASSIGN_ROLES_OFFICE_USER_REQUEST = 'ASSIGN_ROLES_OFFICE_USER_REQUEST'
export const ASSIGN_ROLES_OFFICE_USER_SUCCESS = 'ASSIGN_ROLES_OFFICE_USER_SUCCESS'
export const ASSIGN_ROLES_OFFICE_USER_FAILURE = 'ASSIGN_ROLES_OFFICE_USER_FAILURE'

export const DELETE_OFFICE_USER_REQUEST = 'DELETE_OFFICE_USER_REQUEST'
export const DELETE_OFFICE_USER_SUCCESS = 'DELETE_OFFICE_USER_SUCCESS'
export const DELETE_OFFICE_USER_FAILURE = 'DELETE_OFFICE_USER_FAILURE'

export const FETCH_USER_ROLE_OPTIONS_REQUEST = 'FETCH_USER_ROLE_OPTIONS_REQUEST'
export const FETCH_USER_ROLE_OPTIONS_SUCCESS = 'FETCH_USER_ROLE_OPTIONS_SUCCESS'
export const FETCH_USER_ROLE_OPTIONS_FAILURE = 'FETCH_USER_ROLE_OPTIONS_FAILURE'

export const RESET_OFFICE_USER = 'RESET_OFFICE_USER'
