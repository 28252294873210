export const FETCH_EXCISE_LIST_REQUEST = 'FETCH_EXCISE_LIST_REQUEST'
export const FETCH_EXCISE_LIST_REQUEST_AUTH = 'FETCH_EXCISE_LIST_REQUEST_AUTH'
export const FETCH_EXCISE_LIST_SUCCESS = 'FETCH_EXCISE_LIST_SUCCESS'
export const FETCH_EXCISE_LIST_FAILURE = 'FETCH_EXCISE_LIST_FAILURE'

export const FETCH_EXCISE_RATES_REQUEST = 'FETCH_EXCISE_RATES_REQUEST'
export const FETCH_EXCISE_RATES_SUCCESS = 'FETCH_EXCISE_RATES_SUCCESS'
export const FETCH_EXCISE_RATES_FAILURE = 'FETCH_EXCISE_RATES_FAILURE'

export const FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST = 'FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST'
export const FETCH_EXCISE_SETTINGS_BY_DATE_SUCCESS = 'FETCH_EXCISE_SETTINGS_BY_DATE_SUCCESS'
export const FETCH_EXCISE_SETTINGS_BY_DATE_FAILURE = 'FETCH_EXCISE_SETTINGS_BY_DATE_FAILURE'

export const GET_EXCISE_VAT_INFO_REQUEST = 'GET_EXCISE_VAT_INFO_REQUEST'
export const GET_EXCISE_VAT_INFO_SUCCESS = 'GET_EXCISE_VAT_INFO_SUCCESS'
export const GET_EXCISE_VAT_INFO_FAILURE = 'GET_EXCISE_VAT_INFO_FAILURE'

export const UPDATE_EXCISE_LIST_ITEM_REQUEST = 'UPDATE_EXCISE_LIST_ITEM_REQUEST'
export const UPDATE_EXCISE_LIST_ITEM_SUCCESS = 'UPDATE_EXCISE_LIST_ITEM_SUCCESS'
export const UPDATE_EXCISE_LIST_ITEM_FAILURE = 'UPDATE_EXCISE_LIST_ITEM_FAILURE'

export const CLEANUP_EXCISE = 'CLEANUP_EXCISE'
