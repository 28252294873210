import { Layout } from 'antd'
import { SessionHeader } from 'components/organisms'
import TopMessage from 'components/organisms/app/TopMessage'
import React from 'react'

import { Body, Header, StyledLayout, StyledLayoutContent, Wrapper } from './styles'

export interface MainTemplateProps {
  header?: React.ReactNode
  containerClassName?: string
  topMessage?: React.ReactNode
  bodyLayoutClassName?: string
}

const SessionTemplate: React.FC<MainTemplateProps> = ({
  children,
  containerClassName,
  bodyLayoutClassName,
  header,
  topMessage,
}) => {
  return (
    <Wrapper data-testid="session-template">
      <Layout>
        <Header>{header}</Header>
        <Body>
          <StyledLayout className={bodyLayoutClassName}>
            {topMessage}
            <StyledLayoutContent className={containerClassName}>{children}</StyledLayoutContent>
          </StyledLayout>
        </Body>
      </Layout>
    </Wrapper>
  )
}

SessionTemplate.defaultProps = {
  header: <SessionHeader />,
  topMessage: <TopMessage />,
}

export default SessionTemplate
