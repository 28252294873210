import { CommonErrorMsg } from 'aa_common/front-end/antd'
import classNames from 'classnames'
import { Moment } from 'moment'
import React from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'

import { StyledDatepicker } from './styles'

const dateFormat = 'YYYY/MM/DD'

type DatePickerFormProps = {
  name: string
  required?: boolean
  disabledDate?: (date: Moment) => boolean
  onChange?: (e: any) => void
  format?: any
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year'
}

const DatePicker = ({ name, required, disabledDate, onChange, format, picker }: DatePickerFormProps) => {
  const { errors } = useFormContext()
  const error = get(errors, `${name}.message`)

  return (
    <Controller
      name={name}
      defaultValue={null}
      rules={{ required }}
      render={({ onChange: controllerOnChange, value }) => {
        return (
          <div className="form_control">
            <StyledDatepicker
              name={name}
              picker={picker ?? 'date'}
              className={classNames({ error })}
              value={value}
              format={format ?? dateFormat}
              disabledDate={disabledDate}
              getPopupContainer={triggerNode => triggerNode.parentElement as HTMLElement}
              onChange={e => {
                onChange && onChange(e)
                controllerOnChange(e)
              }}
            />
            {error && <CommonErrorMsg>{error}</CommonErrorMsg>}
          </div>
        )
      }}
    />
  )
}

export default DatePicker
