/* eslint-disable react/no-unused-prop-types, react/prop-types */
import { PlusOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import { ItemRender, UploadChangeParam } from 'antd/es/upload/interface'
import { HttpRequestHeader, UploadFile } from 'antd/lib/upload/interface'
import { CSRF_TOKEN_KEY, IMAGE_UPLOAD_PATH, MAX_IMAGE_UPLOAD_SIZE } from 'common/constants'
import { validateFileSize } from 'common/helpers/file-helper'
import i18n from 'i18n'
import get from 'lodash/get'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { BasicModal, ConfirmModal } from '../../modals'
import { ICoreUploadImage } from './model'
import { PreviewImage, WrapperClass } from './styles'
import { UploadItem } from './UploadItem'

const CoreUploadImage = ({ mode = 'edit', value = [], onChange }: ICoreUploadImage) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [deletedItem, setDeletedItem] = useState<UploadFile | null>(null)
  const [previewImage, setPreviewImage] = useState<UploadFile | null>(null)

  useEffect(() => {
    if (value && value.length > 0) {
      setFileList(value)
    }
  }, [value])

  const handleBeforeUpload = (image: UploadFile) => {
    if (!validateFileSize(image as any, MAX_IMAGE_UPLOAD_SIZE)) {
      message.error(i18n.t('common.messages.over_file_size_limit', { name: image.name }))
      image.status = 'error'
      return false
    }
    return true
  }

  const handleOnChange = ({ file, fileList }: UploadChangeParam<UploadFile<any>>) => {
    if (file.status === 'error') {
      message.error(i18n.t('components.UploadImageListField.upload_failure', { name: file.name }))
    }

    const updatedFileList = fileList
      .filter(item => item.status !== 'error')
      .map(item => {
        const responseData = item.response?.data
        return {
          ...item,
          fileName: responseData ? responseData.filename : item.fileName,
          uid: responseData ? responseData.id : item.uid,
          url: responseData ? responseData.url : item.url,
          thumbUrl: responseData ? responseData.thumbnail_url : item.thumbUrl,
        }
      })

    onChange?.(updatedFileList)
    setFileList(updatedFileList)
  }

  const handlePreviewClick = useCallback((image: UploadFile) => {
    setPreviewImage(image)
  }, [])

  const handleRemoveClick = useCallback((image: UploadFile) => {
    setDeletedItem(image)
    return false
  }, [])

  const handleConfirmDelete = () => {
    const updatedFileList = fileList.filter(item => item.uid !== deletedItem?.uid)

    setDeletedItem(null)
    onChange?.(updatedFileList)
    setFileList(updatedFileList)
  }

  const handleCancelDelete = () => setDeletedItem(null)

  const handleClosePreviewModal = () => setPreviewImage(null)

  const itemRenderFunc: ItemRender = useCallback(
    (_, file, __, actions) => {
      return (
        <UploadItem
          loading={file.status === 'uploading'}
          imageItem={file}
          isEditMode={mode === 'edit'}
          remove={actions.remove}
          preview={actions.preview}
        />
      )
    },
    [mode]
  )

  const requestHeader: HttpRequestHeader = useMemo(
    () => ({
      'X-CSRF-Token': localStorage.getItem(CSRF_TOKEN_KEY) as string,
    }),
    []
  )

  return (
    <>
      <Upload
        action={IMAGE_UPLOAD_PATH}
        fileList={fileList}
        className={WrapperClass}
        listType="picture-card"
        beforeUpload={handleBeforeUpload}
        onChange={handleOnChange}
        accept="image/*"
        multiple
        itemRender={itemRenderFunc}
        headers={requestHeader}
        withCredentials
        onPreview={handlePreviewClick}
        onRemove={handleRemoveClick}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{i18n.t('components.UploadImageListField.upload')}</div>
        </div>
      </Upload>
      <BasicModal isShow={!!previewImage} title="&nbsp;" width={390} onCancel={handleClosePreviewModal}>
        <PreviewImage>
          <img
            alt={get(previewImage, 'name') || ''}
            src={get(previewImage?.response, 'data.url') || previewImage?.url || previewImage?.thumbUrl}
          />
        </PreviewImage>
      </BasicModal>
      <ConfirmModal
        message={i18n.t('common.messages.confirm_delete', { name: `${get(deletedItem, 'name', '')}` })}
        visible={!!deletedItem}
        onOK={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </>
  )
}

export default CoreUploadImage
