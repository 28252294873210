export const updateObjectValues = (object?: any, newValues?: any): any => {
  const result: any = {}

  if (object) {
    Object.keys(object).forEach(key => {
      result[key] = newValues[key] || object[key]
    })
  }

  return result
}

export const getTrimmedData = (obj: any) => {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object') {
        getTrimmedData(obj[key])
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim()
      }
    })
  }
  return obj
}
