import styled from '@emotion/styled'
import { Table as AntTable } from 'antd'
import { Link as RouterLink } from 'react-router-dom'
import { LINK_COLOR } from 'common/constants'

export const Link = styled(RouterLink)`
  color: ${LINK_COLOR};
  cursor: pointer;
  display: block;
  padding-left: 30px;

  &.disabled {
    color: black;
    cursor: default;
  }
`

export const Table = styled(AntTable)`
  .ant-table {
    margin-top: 15px;
    &.ant-table-bordered > .ant-table-container {
      border-left: 0;
    }
    & .ant-table-container {
      .anticon {
        color: #7c8291;
        margin-right: 5px;
      }
      > .ant-table-content > table {
        border-top: 0;
      }
    }
    .ant-table-thead {
      tr {
        > th:last-child {
          border-right: 0 !important;
        }
      }
      tr:first-child {
        > th:first-child {
          border-right: 0 solid #fff !important;
        }
        .ant-table-cell {
          text-align: left;
          font-weight: 500;
          background-color: #fff;
        }
      }
    }
    .ant-table-tbody {
      tr:last-child {
        > td {
          border-bottom: 0;
        }
      }
      tr {
        > td:last-child {
          border-right: 0 !important;
        }
      }
    }
    .ant-table-cell {
      padding: 10px;
      font-weight: 400;
      border-color: #d4d8dd !important;
      &.border-double-left {
        border-left: 3px double #d4d8dd;
      }
      &.no-border-right {
        border-right: 0 solid #fff !important;
      }
    }
  }
`
