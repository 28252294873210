import { css } from '@emotion/css'

export const RowFieldClass = css`
  .field-content {
    align-self: center;
    padding-left: 10px;
  }

  .label-wrapper {
    .label-text {
      white-space: pre-line;

      h4 {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }
`
