import React from 'react'

type Props = {
  mode?: string
}

const EditGroup: React.FC<Props> = ({ mode = 'edit', children }) => {
  if (mode !== 'edit') {
    return null
  }

  return <div>{children}</div>
}

export default EditGroup
