export const selectorIncreaseDecreaseReport = (state: any) => {
  return {
    data: state.getIn(['accounting', 'increaseDecreaseReport', 'data']),
    loading: state.getIn(['accounting', 'increaseDecreaseReport', 'loading']),
    filters: state.getIn(['accounting', 'increaseDecreaseReport', 'filters']),
  }
}

export const selectorExportIncreaseDecreaseReport = (state: any) => {
  return {
    loading: state.getIn(['accounting', 'increaseDecreaseReport', 'export', 'loading']),
  }
}

export const selectorIncreaseDecreaseReportRowKeys = (state: any): string[] => {
  return state.getIn(['accounting', 'increaseDecreaseReport', 'rowKeys'])
}
