export const FETCH_AREAS_REQUEST = 'FETCH_AREAS_REQUEST'
export const FETCH_AREAS_REQUEST_AUTH = 'FETCH_AREAS_REQUEST_AUTH'
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS'
export const FETCH_AREAS_FAILURE = 'FETCH_AREAS_FAILURE'

export const CREATE_AREA_REQUEST = 'CREATE_AREA_REQUEST'
export const INSERT_AREA_REQUEST = 'INSERT_AREA_REQUEST'
export const UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST'

export const FETCH_AREA_REQUEST = 'FETCH_AREA_REQUEST'
export const FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCESS'

export const FETCH_AREA_BY_BIID_REQUEST = 'FETCH_AREA_BY_BIID_REQUEST'
export const FETCH_AREA_BY_BIID_SUCCESS = 'FETCH_AREA_BY_BIID_SUCCESS'

export const DELETE_AREA_REQUEST = 'DELETE_AREA_REQUEST'
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREA_FAILURE = 'DELETE_AREA_FAILURE'

export const DELETE_AREA_BY_ID_REQUEST = 'DELETE_AREA_BY_ID_REQUEST'
export const DELETE_AREA_BY_ID_SUCCESS = 'DELETE_AREA_BY_ID_SUCCESS'
export const DELETE_AREA_BY_ID_FAILURE = 'DELETE_AREA_BY_ID_FAILURE'

export const HANDLE_SUBMIT_AREA_FAILURE = 'HANDLE_SUBMIT_AREA_FAILURE'
export const SUBMIT_AREA_SUCCESS = 'SUBMIT_AREA_SUCCESS'
export const SUBMIT_AREA_FAILURE = 'SUBMIT_AREA_FAILURE'
export const CLEAR_AREA_ITEM_ERRORS = 'CLEAR_AREA_ITEM_ERRORS'

export const IMPORT_AREA_REQUEST = 'IMPORT_AREA_REQUEST'
export const IMPORT_AREA_SUCCESS = 'IMPORT_AREA_SUCCESS'
export const IMPORT_AREA_FAILURE = 'IMPORT_AREA_FAILURE'

export const EXPORT_AREA_REQUEST = 'EXPORT_AREA_REQUEST'
export const EXPORT_AREA_SUCCESS = 'EXPORT_AREA_SUCCESS'
export const EXPORT_AREA_FAILURE = 'EXPORT_AREA_FAILURE'

export const CLEANUP_AREA = 'CLEANUP_AREA'
export const PARTIAL_CLEANUP_AREA = 'PARTIAL_CLEANUP_AREA'
