import { formatDateTime, identifyJournalStatusType } from 'common/helpers'
import { Asset } from 'common/models'
import get from 'lodash/get'
import React from 'react'

import { renderAssetPhoto } from '../helpers'
import { AssetDisplayDataRow, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'

export const AssetDisplayDetail = ({
  asset,
  termMonthId,
  isDeleted,
  labelText,
}: {
  asset: Asset
  termMonthId: number
  isDeleted: boolean
  labelText: string
}) => {
  return (
    <>
      <AssetDisplayHeader>
        <div className="status">
          {!isDeleted && <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={termMonthId} />}
        </div>
        <div className="photo">{renderAssetPhoto(asset)}</div>
        <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
        <div className="asset-name">{asset.name}</div>
      </AssetDisplayHeader>
      <AssetDisplayDataRow>
        <div className="status no-bottom-border">
          {isDeleted && <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={termMonthId} />}
        </div>
        <div className="empty-space no-bottom-border" />
        <div className="short-label">{asset.valid_from}</div>
        <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
        <div className="label text-color-666">{labelText}</div>
        <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
      </AssetDisplayDataRow>
    </>
  )
}
