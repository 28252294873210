import { ComplexTable } from 'aa_common/front-end/antd'
import { FEATURES_ID } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { NoDataImage } from 'components/atoms'
import { ConnectAccPlusText } from 'components/molecules'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'

import { ExciseListTableSettings } from './settings'
import { EmptyImage, Wrapper } from './styles'

const { t, useNsReady } = getNs(['components/organisms/settings/excise-list'])

const ExciseList = ({ data, loading, onReload }: any) => {
  useNsReady()
  const { data: userInfo } = useSelector(selectUserInfo)
  const { data: currentOffice } = useSelector(selectCurrentOffice)
  const {
    permissions: { isRead, isCreate, isEdit, isDelete },
  } = useUserPermission(FEATURES_ID.MASTER_EXICES)
  const CRUD = isRead && isCreate && isEdit && isDelete

  return (
    <Wrapper>
      {CRUD && (
        <ConnectAccPlusText
          userInfo={userInfo}
          currentOffice={currentOffice}
          onReload={onReload}
          endPoint="/excises/list"
        />
      )}
      {data?.length === 0 ? (
        <EmptyImage>
          <NoDataImage text={t('msg_no_data')} />
        </EmptyImage>
      ) : (
        <ComplexTable
          // topTemplate={topTemplate}
          loading={loading}
          columns={ExciseListTableSettings()}
          data={data}
          actions={false}
          rowKey={data => {
            return `excise-rowkey-${data.id}`
          }}
          enableSelectRows={false}
          tableLayout="auto"
        />
      )}
    </Wrapper>
  )
}

export default ExciseList
