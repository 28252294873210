import styled from '@emotion/styled'
import { Divider } from 'antd'

export const Wrapper = styled.div`
  background: #f0f2f5;
  padding-bottom: 15px;
`

export const WrapperContent = styled.div`
  display: flex;
  padding: 15px;
`

export const PaginationWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

export const PaginationButton = styled.button`
  background: transparent;
  padding: 0 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: 0.2s color cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: opacity 0.2s;
  color: #7c8291;

  &.prev {
    margin-right: 7px;
  }

  &.next {
    margin-left: 10px;
  }

  &:disabled {
    color: #d4d8dd;
    cursor: not-allowed;
    &:hover {
      opacity: 1;
      color: #d4d8dd;
    }
  }

  &:hover {
    opacity: 0.5;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`

export const DividerWrapper = styled.div`
  padding-left: 15px;
  max-width: 1060px;
`

export const HorizontalBorder = styled(Divider)`
  &.ant-divider-horizontal {
    border-top: 1px solid #d4d8dd;
    margin: 0;
  }
`
