/* eslint-disable no-restricted-syntax, guard-for-in, jsx-a11y/no-noninteractive-element-interactions */
import { Button, Spin } from 'aa_common/front-end/antd'
import FormField from 'aa_common/front-end/components/form/form-field'
import { AxiosError } from 'axios'
import { API_STATUS_CODE } from 'common/constants'
import { parseValidationError } from 'common/helpers'
import i18n from 'i18n'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useImperativeHandle } from 'react'
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form'

import Divider from './Divider'
import DatePicker from './FormDatePicker'
import Input from './FormInput'
import Item from './Item'
import ItemView from './ItemView'
import { Footer, Group, Header } from './styles'

interface SubmitButtonProps {
  loading?: boolean
  isDisableBtn?: boolean
  submitText?: string
}

const SubmitButton = ({
  loading,
  isDisableBtn,
  submitText = i18n.t('components.BasicForm.register'),
}: SubmitButtonProps) => {
  return (
    <Footer>
      <Button loading={loading} type="submit" disabled={isDisableBtn}>
        {submitText}
      </Button>
    </Footer>
  )
}

export interface RenderFormProps extends UseFormMethods {
  loading?: boolean
  handleOnSubmit?: () => void
}

type Children = React.ReactNode | ((props: RenderFormProps) => React.ReactNode)
type UseFormParameters = Parameters<typeof useForm>[0]
type Props = UseFormParameters & {
  value: any
  loading?: boolean
  errors?: any
  shouldUnregister?: boolean
  onFormSubmit?: (data: any) => any
  children: Children
  withFormSpinner?: boolean
}

export interface FormProps extends React.ForwardRefExoticComponent<Props & React.RefAttributes<RenderFormProps>> {
  Divider: typeof Divider
  Footer: typeof Footer
  Group: typeof Group
  Header: typeof Header
  Item: typeof Item
  ItemView: typeof ItemView
  FormField: typeof FormField
  SubmitButton: typeof SubmitButton
  DatePicker: typeof DatePicker
  Input: typeof Input
}

const CoreForm: React.FC<Props & { moduleForm: RenderFormProps }> = ({
  loading = false,
  withFormSpinner = true,
  errors,
  value,
  children,
  moduleForm,
  onFormSubmit,
}) => {
  const { handleSubmit, setError, clearErrors, getValues, reset } = moduleForm

  const setFormErrors = useCallback(
    (errors: Record<string, string>) => {
      // Fix async server validation
      setTimeout(() => {
        for (const fieldName in errors) {
          const message = get(errors, fieldName)

          setError(fieldName, { message })
        }
      })
    },
    [setError]
  )

  useEffect(() => {
    reset(value)
  }, [value, reset])

  useEffect(() => {
    clearErrors()

    if (!isEmpty(errors)) {
      setFormErrors(errors)
    }
  }, [errors, clearErrors, setFormErrors])

  const handleOnSubmit = useCallback(() => {
    clearErrors()
    Promise.resolve(onFormSubmit && onFormSubmit(getValues())).catch((err: AxiosError) => {
      const status = err?.response?.status
      if (status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
        const errorResponse = err?.response?.data?.errors
        const formErrors = parseValidationError(errorResponse)
        if (!isEmpty(formErrors)) {
          setFormErrors(formErrors)
        }
      }
    })
  }, [clearErrors, setFormErrors, onFormSubmit, getValues])

  const formContext = { ...moduleForm, handleOnSubmit }
  const renderForm = () => {
    return (
      <FormProvider {...formContext}>
        <form
          onSubmit={handleSubmit(handleOnSubmit, handleOnSubmit)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          {typeof children === 'function' ? children(formContext) : children}
        </form>
      </FormProvider>
    )
  }

  return withFormSpinner ? <Spin loading={loading}>{renderForm()}</Spin> : renderForm()
}

const Form = React.forwardRef<RenderFormProps, Props>(function Form(props, ref) {
  const { loading = false, shouldUnregister = false, value: defaultValues, mode = 'onSubmit' } = props

  const moduleForm: RenderFormProps = {
    ...useForm({ mode, defaultValues, shouldUnregister }),
    loading,
  }
  useImperativeHandle(ref, () => moduleForm)

  return <CoreForm {...props} moduleForm={moduleForm} />
}) as FormProps

Form.Divider = Divider
Form.DatePicker = DatePicker

Form.Footer = Footer
Form.Group = Group
Form.Header = Header
Form.Item = Item
Form.ItemView = ItemView
Form.FormField = FormField
Form.SubmitButton = SubmitButton
Form.Input = Input

export default Form
