import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { get } from 'lodash'
import React from 'react'
import { Controller } from 'react-hook-form'

import { IRadioGroup } from './model'
import { DefaultRadioGroupClass } from './styles'

const RadioGroup = ({ name, className, options, formModule, onChange, ...rest }: IRadioGroup) => {
  const handleOnChanged = (event: RadioChangeEvent) => {
    formModule && formModule.setValue(name, event.target.value)
    onChange && onChange(event)
  }

  // *** Form Input ***
  if (formModule) {
    const { errors } = formModule

    const isInvalid = !!(errors && get(errors, name))

    return (
      <>
        <Controller
          name={name}
          control={formModule.control}
          render={({ value }) => (
            <Radio.Group
              name={name}
              className={className || DefaultRadioGroupClass}
              value={value}
              onChange={handleOnChanged}
              options={options}
              {...rest}
            />
          )}
        />
        {isInvalid && <CommonErrorMsg fontSize="13px">{get(errors, `${name}.message`)}</CommonErrorMsg>}
      </>
    )
  }

  // *** Regular Input ***
  return (
    <Radio.Group
      name={name}
      className={className || DefaultRadioGroupClass}
      onChange={handleOnChanged}
      options={options}
      {...rest}
    />
  )
}

export default RadioGroup
