export default {
  common: require('./common.json'),
  'components/molecules/actions-list': require('./components/molecules/actions-list.json'),
  'components/molecules/auto-complete-select': require('./components/molecules/auto-complete-select.json'),
  'components/molecules/auto-complete-text': require('./components/molecules/auto-complete-text.json'),
  'components/molecules/basic-form': require('./components/molecules/basic-form.json'),
  'components/molecules/button-send-journal': require('./components/molecules/button-send-journal.json'),
  'components/molecules/dropdown-toggle': require('./components/molecules/dropdown-toggle.json'),
  'components/molecules/filter-panel': require('./components/molecules/filter-panel.json'),
  'components/molecules/modals/confirm-modal': require('./components/molecules/modals/confirm-modal.json'),
  'components/molecules/modals/confirm-send-journal-modal': require('./components/molecules/modals/confirm-send-journal-modal.json'),
  'components/molecules/modals/error-modal': require('./components/molecules/modals/error-modal.json'),
  'components/molecules/modals/export-modal': require('./components/molecules/modals/export-modal.json'),
  'components/molecules/modals/import-modal': require('./components/molecules/modals/import-modal.json'),
  'components/molecules/pagination': require('./components/molecules/pagination.json'),
  'components/molecules/retrospective-asset-modal': require('./components/molecules/retrospective-asset-modal.json'),
  'components/molecules/upload-file-list-field': require('./components/molecules/upload-file-list-field.json'),
  'components/molecules/upload-image-list-field': require('./components/molecules/upload-image-list-field.json'),
  'components/organisms/accounting/asset-events-report': require('./components/organisms/accounting/asset-events-report.json'),
  'components/organisms/accounting/auto-journal-setting-tab': require('./components/organisms/accounting/auto-journal-setting-tab.json'),
  'components/organisms/accounting/general-journal-setting-tab': require('./components/organisms/accounting/general-journal-setting-tab.json'),
  'components/organisms/accounting/journal-list': require('./components/organisms/accounting/journal-list.json'),
  'components/organisms/accounting/monthly-closing-asset-event-list': require('./components/organisms/accounting/monthly-closing-asset-event-list.json'),
  'components/organisms/accounting/monthly-closing-view': require('./components/organisms/accounting/monthly-closing-view.json'),
  'components/organisms/accounting/under-over-depreciation-report': require('./components/organisms/accounting/under-over-depreciation-report.json'),
  'components/organisms/agent-login-header': require('./components/organisms/agent-login-header.json'),
  'components/organisms/assets/asset-comment': require('./components/organisms/assets/asset-comment.json'),
  'components/organisms/assets/asset-detail': require('./components/organisms/assets/asset-detail.json'),
  'components/organisms/assets/asset-dividing': require('./components/organisms/assets/asset-dividing.json'),
  'components/organisms/assets/asset-event-sell-form': require('./components/organisms/assets/asset-event-sell-form.json'),
  'components/organisms/assets/asset-filter-facet': require('./components/organisms/assets/asset-filter-facet.json'),
  'components/organisms/assets/asset-form': require('./components/organisms/assets/asset-form.json'),
  'components/organisms/assets/asset-impairment': require('./components/organisms/assets/asset-impairment.json'),
  'components/organisms/assets/asset-import-form': require('./components/organisms/assets/asset-import-form.json'),
  'components/organisms/assets/asset-import-result-list': require('./components/organisms/assets/asset-import-result-list.json'),
  'components/organisms/assets/asset-list': require('./components/organisms/assets/asset-list.json'),
  'components/organisms/assets/asset-movement-form': require('./components/organisms/assets/asset-movement-form.json'),
  'components/organisms/assets/asset-retirement-form': require('./components/organisms/assets/asset-retirement-form.json'),
  'components/organisms/assets/asset-type-modify-form': require('./components/organisms/assets/asset-type-modify-form.json'),
  'components/organisms/assets/asset-usage-started-form': require('./components/organisms/assets/asset-usage-started-form.json'),
  'components/organisms/assets/switch-category-form': require('./components/organisms/assets/switch-category-form.json'),
  'components/organisms/depreciation/depreciation-result-list': require('./components/organisms/depreciation/depreciation-result-list.json'),
  'components/organisms/depreciation/ledger-settings': require('./components/organisms/depreciation/ledger-settings.json'),
  'components/organisms/internal-control/log-list-item-detail': require('./components/organisms/internal-control/log-list-item-detail.json'),
  'components/organisms/internal-control/operation-log-filter': require('./components/organisms/internal-control/operation-log-filter.json'),
  'components/organisms/main-header': require('./components/organisms/main-header.json'),
  'components/organisms/main-menu': require('./components/organisms/main-menu.json'),
  'components/organisms/office/select-table': require('./components/organisms/office/select-table.json'),
  'components/organisms/settings/account-item-form': require('./components/organisms/settings/account-item-form.json'),
  'components/organisms/settings/account-item-list': require('./components/organisms/settings/account-item-list.json'),
  'components/organisms/settings/accounting-period-form': require('./components/organisms/settings/accounting-period-form.json'),
  'components/organisms/settings/area-filter': require('./components/organisms/settings/area-filter.json'),
  'components/organisms/settings/area-form': require('./components/organisms/settings/area-form.json'),
  'components/organisms/settings/area-list': require('./components/organisms/settings/area-list.json'),
  'components/organisms/settings/asset-category-filter': require('./components/organisms/settings/asset-category-filter.json'),
  'components/organisms/settings/asset-category-form': require('./components/organisms/settings/asset-category-form.json'),
  'components/organisms/settings/asset-category-list': require('./components/organisms/settings/asset-category-list.json'),
  'components/organisms/settings/asset-code-auto-generation-form-modal': require('./components/organisms/settings/asset-code-auto-generation-form-modal.json'),
  'components/organisms/settings/asset-code-auto-generation-view': require('./components/organisms/settings/asset-code-auto-generation-view.json'),
  'components/organisms/settings/asset-code-start-number-page': require('./components/organisms/settings/asset-code-start-number-page.json'),
  'components/organisms/settings/cause-form': require('./components/organisms/settings/cause-form.json'),
  'components/organisms/settings/cause-list': require('./components/organisms/settings/cause-list.json'),
  'components/organisms/settings/department-filter': require('./components/organisms/settings/department-filter.json'),
  'components/organisms/settings/department-form': require('./components/organisms/settings/department-form.json'),
  'components/organisms/settings/department-list': require('./components/organisms/settings/department-list.json'),
  'components/organisms/settings/excise-list': require('./components/organisms/settings/excise-list.json'),
  'components/organisms/settings/excise-list-filter': require('./components/organisms/settings/excise-list-filter.json'),
  'components/organisms/settings/excise-setting-form-modal': require('./components/organisms/settings/excise-setting-form-modal.json'),
  'components/organisms/settings/excise-setting-view': require('./components/organisms/settings/excise-setting-view.json'),
  'components/organisms/settings/role-list': require('./components/organisms/settings/role-list.json'),
  'components/organisms/settings/setting-import-result-list': require('./components/organisms/settings/setting-import-result-list.json'),
  'components/organisms/settings/submit-destination-form': require('./components/organisms/settings/submit-destination-form.json'),
  'components/organisms/settings/submit-destination-list': require('./components/organisms/settings/submit-destination-list.json'),
  'components/organisms/settings/tag-form': require('./components/organisms/settings/tag-form.json'),
  'components/organisms/settings/tag-list': require('./components/organisms/settings/tag-list.json'),
  'components/organisms/settings/unit-form': require('./components/organisms/settings/unit-form.json'),
  'components/organisms/settings/unit-list': require('./components/organisms/settings/unit-list.json'),
  'components/organisms/settings/user-form': require('./components/organisms/settings/user-form.json'),
  'pages/accounting/auto-journal-setting-page': require('./pages/accounting/auto-journal-setting-page.json'),
  'pages/accounting/depreciation-fixed-asset-page': require('./pages/accounting/depreciation-fixed-asset-page.json'),
  'pages/accounting/corporate-income-tax': require('./pages/accounting/corporate-income-tax.json'),
  'pages/accounting/history-list': require('./pages/accounting/history-list.json'),
  'pages/accounting/increase-decrease-report': require('./pages/accounting/increase-decrease-report.json'),
  'pages/accounting/journal-list': require('./pages/accounting/journal-list.json'),
  'pages/accounting/under-over-depreciatioin-page': require('./pages/accounting/under-over-depreciatioin-page.json'),
  'pages/accounting/corporate-income-tax-report': require('./pages/accounting/corporate-income-tax-report.json'),
  'pages/assets': require('./pages/assets.json'),
  'pages/error-pages': require('./pages/error-pages.json'),
  'pages/internal-control/operation-log': require('./pages/internal-control/operation-log.json'),
  'pages/office/new': require('./pages/office/new.json'),
  'pages/settings/areas': require('./pages/settings/areas.json'),
  'pages/settings/asset-categories': require('./pages/settings/asset-categories.json'),
  'pages/settings/asset-code': require('./pages/settings/asset-code.json'),
  'pages/settings/causes': require('./pages/settings/causes.json'),
  'pages/settings/departments': require('./pages/settings/departments.json'),
  'pages/settings/excise': require('./pages/settings/excise.json'),
  'pages/settings/excise-setting': require('./pages/settings/excise-setting.json'),
  'pages/settings/import': require('./pages/settings/import.json'),
  'pages/settings/office': require('./pages/settings/office.json'),
  'pages/settings/recalculate': require('./pages/settings/recalculate.json'),
  'pages/settings/roles': require('./pages/settings/roles.json'),
  'pages/settings/submit-destinations': require('./pages/settings/submit-destinations.json'),
  'pages/settings/tags': require('./pages/settings/tags.json'),
  'pages/settings/units': require('./pages/settings/units.json'),
  'pages/settings/users': require('./pages/settings/users.json'),
  'pages/user/create-accounting-period': require('./pages/user/create-accounting-period.json'),
  'pages/user/login-page': require('./pages/user/login-page.json'),
  'pages/user/term-of-use': require('./pages/user/term-of-use.json'),
}
