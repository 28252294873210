import { DECLARATION_REPORT_EXPORT_MODE } from 'AppExport/model'
import { buildPaginationParams } from 'common/helpers/request-helper'
import type {
  ExportTaxDeclarationReportsPayload as ExportQueryParams,
  TaxDeclarationDecreaseReportPayload as DecreaseQueryParams,
  TaxDeclarationIncreaseReportPayload as IncreaseQueryParams,
  TaxDeclarationSummaryReportPayload as SummaryQueryParams,
} from 'store/accounting/depreciationFixedAsset/model'

import base from './base'

function getWithParams(path: string, params: Record<string, any>) {
  return base.get(path, { params })
}

export const getTaxDeclarationReportList = (year: number) => {
  return getWithParams('/v1/reports/tax_declaration', { year })
}

export const getTaxDeclarationSummaryReport = ({ id, ...params }: SummaryQueryParams) => {
  return getWithParams(`/v1/reports/tax_declaration/summary/${id}`, params)
}

export const getTaxDeclarationIncreaseReport = ({ id, pageNumber, pageSize, ...params }: IncreaseQueryParams) => {
  return getWithParams(`/v1/reports/tax_declaration/increase/${id}`, {
    ...params,
    ...buildPaginationParams(pageNumber, pageSize),
  })
}

export const getTaxDeclarationDecreaseReport = ({ id, year, pageNumber, pageSize }: DecreaseQueryParams) => {
  return getWithParams(`/v1/reports/tax_declaration/decrease/${id}`, {
    year,
    ...buildPaginationParams(pageNumber, pageSize),
  })
}

export const exportTaxDeclarationReports = ({
  submit_destination_id,
  year,
  type,
  mode = DECLARATION_REPORT_EXPORT_MODE.IN_FISCAL_YEAR,
}: ExportQueryParams) => {
  return getWithParams('/v1/reports/tax_declaration/export', {
    submit_destination_id,
    year,
    type,
    path: 'export',
    mode,
  })
}

export const getExportFixedAssetHistories = (id: number, fiscalYear: any) => {
  return getWithParams(`/v1/tax_declaration/export_histories/${id}`, { year: fiscalYear })
}

export const exportWithCsvFormat = (params: any) => {
  return base.get('/v1/export/fixed_assets/eltax', {
    responseType: 'blob',
    params,
  })
}
