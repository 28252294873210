import { List, Map } from 'immutable'

import { IncreaseDecreaseReportActionTypes } from './action.model'
import {
  ADD_INCREASE_DECREASE_REPORT_ROW_KEY,
  CLEANUP_INCREASE_DECREASE_REPORT,
  EXPORT_INCREASE_DECREASE_REPORT_FAILURE,
  EXPORT_INCREASE_DECREASE_REPORT_REQUEST,
  EXPORT_INCREASE_DECREASE_REPORT_SUCCESS,
  FETCH_INCREASE_DECREASE_REPORT_FAILURE,
  FETCH_INCREASE_DECREASE_REPORT_REQUEST,
  FETCH_INCREASE_DECREASE_REPORT_SUCCESS,
  REMOVE_INCREASE_DECREASE_REPORT_ROW_KEY,
  UPDATE_INCREASE_DECREASE_REPORT_ROW_KEYS,
} from './constant'

export const initialState = Map({
  data: null,
  loading: false,
  filters: null,
  termId: null,
  export: {
    loading: false,
  },
  rowKeys: [],
})

export default (state = initialState, action: IncreaseDecreaseReportActionTypes) => {
  switch (action.type) {
    case FETCH_INCREASE_DECREASE_REPORT_REQUEST:
      return state.setIn(['filters'], action.payload).setIn(['loading'], true)
    case FETCH_INCREASE_DECREASE_REPORT_SUCCESS:
      return state.setIn(['data'], action.payload).setIn(['loading'], false)
    case FETCH_INCREASE_DECREASE_REPORT_FAILURE:
      return state.setIn(['loading'], false)
    case EXPORT_INCREASE_DECREASE_REPORT_REQUEST:
      return state.setIn(['report', 'loading'], true)
    case EXPORT_INCREASE_DECREASE_REPORT_SUCCESS:
    case EXPORT_INCREASE_DECREASE_REPORT_FAILURE:
      return state.setIn(['report', 'loading'], false)
    case CLEANUP_INCREASE_DECREASE_REPORT:
      return initialState
    case ADD_INCREASE_DECREASE_REPORT_ROW_KEY: {
      const rowKeys = state.getIn(['rowKeys']) as string[]
      const updatedKeys = [...rowKeys, action.payload]

      return state.setIn(['rowKeys'], updatedKeys)
    }
    case REMOVE_INCREASE_DECREASE_REPORT_ROW_KEY: {
      const prevRowKeys = state.getIn(['rowKeys']) as string[]
      const deletedIndex = List(prevRowKeys).findIndex(item => item === action.payload)
      const updatedKeys = List(prevRowKeys).remove(deletedIndex).toArray()
      return state.setIn(['rowKeys'], updatedKeys)
    }
    case UPDATE_INCREASE_DECREASE_REPORT_ROW_KEYS:
      return state.setIn(['rowKeys'], action.payload)
    default:
      return state
  }
}
