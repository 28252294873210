import Form from 'aa_common/front-end/components/form'
import { RightDrawer } from 'aa_common/front-end/components/right-drawer'
import { createZustandContext } from 'aa_common/front-end/zustand/create-zustand-hook-provider'
import i18n from 'i18n'
import React from 'react'

// import { RightDrawer } from '../../../components/right-drawer'
// import { createZustandContext } from '../../../zustand/create-zustand-hook-provider'
import { FormContent } from './form-content'
import { AssetDividingFormProps, AssetDividingProps, DividingStoreSchema } from './schema'
import { ModalClass } from './styles'

export const StoreContext = createZustandContext<DividingState>({ dividingData: {} })
export const AssetDividingForm = ({
  isShow,
  setShowForm,
  causes,
  onHandleReloadCauses,
  handleSubmit,
  defaultValues,
  asset,
  ledgerSettings,
  errors,
  terms,
  permissions,
  exciseTaxMethod,
  isLoading,
}: AssetDividingProps) => {
  const [dividingAt, setDividingAt] = React.useState<moment.Moment | null>(null)
  const initStore: DividingStoreSchema = {
    asset,
    terms,
    permissions,
    exciseTaxMethod,
    dividingAt,
    setDividingAt,
  }

  const onSubmit = (values: AssetDividingFormProps) => {
    values.divided_at = dividingAt
    handleSubmit(values)
  }

  return (
    <RightDrawer
      title={i18n.t('components.AssetDividing.dividing')}
      className={ModalClass}
      visible={isShow}
      destroyOnClose
      onClose={() => setShowForm(false)}
      data-testid="dividing_form_right_drawer"
    >
      <StoreContext.Provider dividingData={initStore}>
        <Form value={defaultValues} mode="onChange" loading={isLoading} onFormSubmit={onSubmit} errors={errors}>
          <FormContent
            setShowForm={setShowForm}
            causes={causes}
            onReloadCauses={onHandleReloadCauses}
            ledgerSettings={ledgerSettings}
          />
        </Form>
      </StoreContext.Provider>
    </RightDrawer>
  )
}

export type DividingState = {
  dividingData: DividingStoreSchema
}
