import { getNs } from 'common/i18n-config'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterTaxDeclarationYear } from 'store/masterData/selectors'

import { depreciationFixedAssetSlice } from '../store'

const { useNsReady } = getNs(['common', 'pages/accounting/depreciation-fixed-asset-page'])

export const useDepreciationFixedAsset = () => {
  useNsReady()
  const { data, _loading: loading } = depreciationFixedAssetSlice.useSlice()
  const { masterTaxDeclaration, currentTaxInfo } = useSelector(selectMasterTaxDeclarationYear, isEqual)
  const [taxYearInfo, setTaxYearInfo] = useState<number>(currentTaxInfo?.code)

  useEffect(() => {
    // Set filter to execute the function get list data
    depreciationFixedAssetSlice.setFilters({ year: taxYearInfo }, true)
  }, [taxYearInfo])

  const handleChangeTaxYear = (year: number) => setTaxYearInfo(year)

  return {
    taxDeclarationReportList: data?.reports,
    taxYearInfo,
    masterTaxDeclaration,
    loading,
    handleChangeTaxYear,
  }
}
