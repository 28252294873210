import React from 'react'
import { Controller } from 'react-hook-form'

import { RangeDatePickerInputProps } from './model'
import CoreRangeDatePickerInput from './RangeDatePickerInput'

const RangeDatePickerInput = ({
  name,
  value,
  className,
  disabled = false,
  placeholder,
  control,
  onDateChanged,
}: RangeDatePickerInputProps) => {
  // *** Form RangeDatePickerInput ***
  if (control) {
    return (
      <Controller
        name={name as any}
        control={control}
        render={({ onChange: controllerOnChange, value, name }) => {
          const fromValue = value && value[0]
          const toValue = value && value[1]
          return (
            <CoreRangeDatePickerInput
              value={[fromValue, toValue]}
              name={name}
              className={className}
              disabled={disabled}
              placeholder={placeholder}
              onDateChanged={event => {
                controllerOnChange(event.value)
                onDateChanged && onDateChanged(event)
              }}
            />
          )
        }}
      />
    )
  }

  // *** regular RangeDatePickerInput ***
  return (
    <CoreRangeDatePickerInput
      placeholder={placeholder}
      value={value ? [value[0], value[1]] : undefined}
      name={name}
      className={className}
      disabled={disabled}
      onDateChanged={onDateChanged}
    />
  )
}

export default React.memo(RangeDatePickerInput)
