import styled from '@emotion/styled'

export const UserInfo = styled.div`
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  max-width: 900px;

  .field-item {
    color: #333333;
    background: white;
    display: flex;
    border-bottom: 1px solid #d4d8dd;
    padding: 15px 10px;

    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 4px 4px;
      border-bottom: none;
    }

    .label {
      width: 280px;
      padding-right: 10px;
      h4 {
        color: #333333;
        margin-bottom: 0;
        font-weight: 500;
      }
    }

    .content {
      padding-left: 10px;
    }
  }
`
