import { Tooltip } from 'antd'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { getDepreciationCodeOfAccountingLedger } from 'common/helpers/ledger-helper'
import { Asset } from 'common/models'
import i18n from 'i18n'
import { ComponentProps } from 'react'

type AntTooltipProps = ComponentProps<typeof Tooltip>

export const disableReasonTooltip: AntTooltipProps = { placement: 'top', title: i18n.t('common.actions.no_permission') }

// Ref: https://miro.com/app/board/uXjVOz6KRwc=/?moveToWidget=3458764526527978260&cot=14
export function isInapplicableForImpairment(asset: Asset) {
  const dMethod = getDepreciationCodeOfAccountingLedger(asset?.fixed_asset_ledgers)
  return (
    dMethod &&
    [DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET, DEPRECIATION_METHOD_CODE.NON_DEPRECIATION].includes(
      dMethod
    )
  )
}

function getTooltipMsg(hasPermission: boolean, asset: any) {
  if (!hasPermission) return disableReasonTooltip.title

  if (asset.impaired_at) return i18n.t('components.AssetImpairment.tooltips.inapplicable')

  return ''
}

export function getImpairmentMenuSettingMemo(hasPermission: boolean, asset: any, isRetiredOrSold: boolean) {
  if (isRetiredOrSold) {
    return {
      disabled: true,
    }
  }
  const title = getTooltipMsg(hasPermission, asset)
  if (title) {
    return {
      disabled: true,
      tooltip: { ...disableReasonTooltip, title },
    }
  }
  return {}
}
