import { Map } from 'immutable'

import { OfficeUserActionTypes } from './action.model'
import {
  ASSIGN_ROLES_OFFICE_USER_FAILURE,
  ASSIGN_ROLES_OFFICE_USER_REQUEST,
  ASSIGN_ROLES_OFFICE_USER_SUCCESS,
  DELETE_OFFICE_USER_FAILURE,
  DELETE_OFFICE_USER_REQUEST,
  DELETE_OFFICE_USER_SUCCESS,
  FETCH_CREATABLE_OFFICE_USERS_FAILURE,
  FETCH_CREATABLE_OFFICE_USERS_REQUEST,
  FETCH_CREATABLE_OFFICE_USERS_SUCCESS,
  FETCH_OFFICE_USER_ITEM_FAILURE,
  FETCH_OFFICE_USER_ITEM_REQUEST,
  FETCH_OFFICE_USER_ITEM_SUCCESS,
  FETCH_OFFICE_USER_LIST_FAILURE,
  FETCH_OFFICE_USER_LIST_REQUEST,
  FETCH_OFFICE_USER_LIST_SUCCESS,
  FETCH_USER_ROLE_OPTIONS_FAILURE,
  FETCH_USER_ROLE_OPTIONS_REQUEST,
  FETCH_USER_ROLE_OPTIONS_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  RESET_OFFICE_USER,
} from './constant'

const initialState = Map({
  list: Map({
    data: null,
    loading: false,
    filters: null,
    sort: null,
  }),
  roles: Map({
    data: [],
    loading: false,
  }),
  creatableUsers: Map({
    data: [],
    loading: false,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: OfficeUserActionTypes) => {
  switch (action.type) {
    case FETCH_OFFICE_USER_LIST_REQUEST:
    case DELETE_OFFICE_USER_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_OFFICE_USER_LIST_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_OFFICE_USER_LIST_FAILURE:
    case DELETE_OFFICE_USER_SUCCESS:
    case DELETE_OFFICE_USER_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case FETCH_OFFICE_USER_ITEM_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_OFFICE_USER_ITEM_SUCCESS:
      return state.setIn(['item', 'data'], action.payload).setIn(['item', 'loading'], false)
    case FETCH_OFFICE_USER_ITEM_FAILURE:
      return state.setIn(['item', 'loading'], false)
    case FETCH_USER_ROLE_OPTIONS_REQUEST:
      return state.setIn(['roles', 'loading'], true)
    case FETCH_USER_ROLE_OPTIONS_SUCCESS:
      return state.setIn(['roles', 'data'], action.payload).setIn(['roles', 'loading'], false)
    case FETCH_USER_ROLE_OPTIONS_FAILURE:
      return state.setIn(['roles', 'loading'], false)
    case FETCH_CREATABLE_OFFICE_USERS_REQUEST:
      return state.setIn(['creatableUsers', 'loading'], true)
    case FETCH_CREATABLE_OFFICE_USERS_SUCCESS:
      return state.setIn(['creatableUsers', 'data'], action.payload).setIn(['creatableUsers', 'loading'], false)
    case FETCH_CREATABLE_OFFICE_USERS_FAILURE:
      return state.setIn(['creatableUsers', 'loading'], false)
    case ASSIGN_ROLES_OFFICE_USER_REQUEST:
    case INVITE_USER_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case ASSIGN_ROLES_OFFICE_USER_SUCCESS:
    case INVITE_USER_SUCCESS:
      return state.setIn(['item', 'loading'], false)
    case ASSIGN_ROLES_OFFICE_USER_FAILURE:
    case INVITE_USER_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'errors'], action.payload)
    case RESET_OFFICE_USER:
      return initialState
    default:
      return state
  }
}
