import { Map } from 'immutable'

import { ACTION } from './constant'

export const initialState = Map({
  assetEvents: {
    data: null,
    total: null,
  },
  summary: [],
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION.HANDLE_FETCH_ASSET_EVENTS_REPORT:
      return state.setIn(['assetEvents', 'data'], action.payload)
    case ACTION.HANDLE_FETCH_SUMMARY_FIXED_ASSET_EVENTS:
      return state.set('summary', action.payload)
    default:
      return state
  }
}
