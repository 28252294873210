import styled from '@emotion/styled'
import { If } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import { Pagination } from 'components/molecules'
import { isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'

import { CollectiveCheckbox } from './collective-checkbox'
import { HistoryCheckbox } from './history-checkbox'

const { useNsReady } = getNs(['components/organisms/assets/asset-list'])

export const TableHeaderFunctions = ({ onPageChanged, onListModeChanged }: TableHeaderFunctionsProps) => {
  useNsReady()

  const { totalPages, totalRecords, filters, page } = useSelector(selectAssetsCollectionView, isEqual)

  const isEmptyList = !totalRecords && !totalPages

  return !isEmptyList ? (
    <HeaderWrapper>
      <div className="grid-item">
        <If condition={!filters?.include_history}>
          <CollectiveCheckbox />
        </If>
      </div>
      <div className="pagination-section grid-item">
        <HistoryCheckbox onChange={onListModeChanged} />
        <Pagination
          value={page}
          name="fixed-asset"
          totalPages={totalPages}
          totalRecords={totalRecords}
          onPageChanged={onPageChanged}
        />
      </div>
    </HeaderWrapper>
  ) : null
}

type TableHeaderFunctionsProps = {
  onPageChanged: (currentPage: number) => void
  onListModeChanged: (detailMode: boolean) => void
}

const HeaderWrapper = styled.div`
  border-left: 1px solid #f0f0f0;
  background-color: #ffffff;
  border-top: 1px solid rgb(212, 216, 221);
  border-left: 1px solid rgb(212, 216, 221);
  border-right: 1px solid rgb(212, 216, 221);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .grid-item {
    align-items: center;
    display: flex;
    padding: 10px;
  }
  .pagination-section {
    text-align: right;
    background-color: #ffffff;
    margin-right: 2px;
  }
`
