import { useState } from 'react'

export const useExportDepreciationModal = () => {
  const [visible, setVisible] = useState(false)

  const handleOpenModal = (visible: boolean) => {
    setVisible(visible)
  }
  return {
    visible,
    handleOpenModal,
  }
}
