import { Asset } from 'common/models'
import { isEqual } from 'lodash'
import { rowSelectSliceAssetList } from 'pages/assets/AssetListPage/store'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'

export const useGetSelectedItemOnPage = () => {
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)

  const rowSelected = rowSelectSliceAssetList.useSlice(state => state.data?.selectedItem)

  const selectedItems = assetCollectionViewSelector.assets?.filter(
    (asset: Asset) => asset?.biid && rowSelected[asset.biid]
  )

  return { selectedIds: selectedItems?.map((asset: Asset) => asset.biid), selectedItems }
}
