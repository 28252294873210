import { RequiredLabel } from 'aa_common/front-end/components'

import { ItemContent, ItemLabel, ItemWrapper } from './styles'

type Props = {
  children: any
  className?: string
  label: string
  required: boolean
}

const Item = ({ children, className, label, required = false }: Props) => {
  return (
    <ItemWrapper className={className}>
      <ItemLabel>
        <div className="content">{label}</div>
        {required && (
          <div className="required_label_wrapper">
            <RequiredLabel className="required" />
          </div>
        )}
      </ItemLabel>
      <ItemContent className="item-content">{children}</ItemContent>
    </ItemWrapper>
  )
}

export default Item
