import { Spin } from 'aa_common/front-end/antd'
import { LinkLabel } from 'aa_common/front-end/components'
import { Avatar, List, Skeleton } from 'antd'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import CommentTextbox from 'components/molecules/CommentTextbox'
import { renderComment } from 'components/molecules/CommentTextbox/renderComment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserInfoSelector } from 'store/session/selectors'

import { AssetCommentListItem, AssetCommentListProps } from './model'
import { CommentItem, DefaultListClass } from './styles'

const RenderItem = ({ item, readonly, onItemDeleteClick, onItemEditSubmit, permissions }: AssetCommentListItem) => {
  const [isHovering, setItemHovering] = useState<boolean>(false)
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [textContent, setTextContent] = useState<string>('')
  const t = loadNs('common')
  useEffect(() => {
    item && setTextContent(item.content)
  }, [item])

  const currentUser = useSelector(getUserInfoSelector)
  const isCommentOwner = useMemo(() => {
    if (currentUser && item) {
      return currentUser.id === item.userId
    }

    return false
  }, [currentUser, item])

  const memoizedColor = useMemo(() => Math.floor(Math.random() * 16777215).toString(16), [])

  const handleItemMouseLeave = useCallback(() => setItemHovering(false), [])

  const handleItemMouseEnter = useCallback(() => setItemHovering(true), [])

  const handleOnChanged = useCallback((value: string) => {
    setTextContent(value)
  }, [])

  const handleEditCommentSubmit = useCallback(
    (editValue: string) => {
      setEditMode(false)
      onItemEditSubmit && onItemEditSubmit(item, editValue)
      setTimeout(() => setTextContent(editValue), 1)
    },
    [onItemEditSubmit, item]
  )

  const handleCancelEdit = useCallback(() => {
    textContent !== item.content && setTextContent(item.content)
    setEditMode(false)
  }, [item, textContent])

  const handleDeleteClick = useCallback(() => onItemDeleteClick && onItemDeleteClick(item), [item, onItemDeleteClick])

  const handleEditClick = () => setEditMode(true)

  return (
    <CommentItem
      className={classNames({ 'item-hovered': isHovering, 'is-editing': isEditMode }, 'comment-item')}
      onMouseEnter={handleItemMouseEnter}
      onMouseLeave={handleItemMouseLeave}
    >
      <div className="avatar-wrapper">
        <Avatar style={{ backgroundColor: `#${memoizedColor}`, verticalAlign: 'middle' }} size="large">
          {item.avatarFirstLetter}
        </Avatar>
      </div>
      <div className="comment-content-wrapper">
        <div className="top">
          <div className="comment-info">
            <h4 className="user">{item.userName}</h4>
            <span className="comment-date">{item.commentDate}</span>
          </div>
          {!readonly && isCommentOwner && !isEditMode && (
            <div className={classNames('actions', { show: isHovering, hide: !isHovering })}>
              <LinkLabel
                className="edit-comment"
                style={{ marginRight: 5 }}
                onClick={handleEditClick}
                disabled={!permissions.isEdit}
                showTooltipMessage={!permissions.isEdit ? t('actions.no_permission') : undefined}
              >
                {t('actions.edit')}
              </LinkLabel>
              <LinkLabel
                className="delete-comment"
                type="danger"
                onClick={handleDeleteClick}
                disabled={!permissions.isDelete}
                showTooltipMessage={!permissions.isDelete ? t('actions.no_permission') : undefined}
              >
                {t('actions.delete')}
              </LinkLabel>
            </div>
          )}
        </div>
        <div className="content">
          <p hidden={isEditMode}>{renderComment(textContent)}</p>
          <CommentTextbox
            className={classNames('edit-mode', { show: isEditMode })}
            value={textContent}
            onChange={handleOnChanged}
            type="edit"
            onCancel={handleCancelEdit}
            onCommentSubmit={handleEditCommentSubmit}
            permissions={permissions}
          />
        </div>
      </div>
    </CommentItem>
  )
}

const AssetCommentList = ({
  commentList,
  listWrapperClassName,
  loading,
  readonly,
  onItemDeleteClick,
  onItemEditSubmit,
  permissions,
}: AssetCommentListProps) => {
  const [list, setList] = useState<any[] | null>(null)

  useEffect(() => {
    commentList && setList(commentList)
  }, [commentList])

  return (
    <div>
      {loading && ((list && list.length === 0) || !list) && (
        <div style={{ padding: 15 }}>
          <Skeleton active avatar paragraph={{ rows: 2 }} />
          <Skeleton active avatar paragraph={{ rows: 2 }} />
        </div>
      )}
      {list && list.length > 0 && (
        <Spin loading={loading}>
          <List
            className={classNames(listWrapperClassName, DefaultListClass)}
            dataSource={list}
            renderItem={item => (
              <RenderItem
                onItemDeleteClick={onItemDeleteClick}
                onItemEditSubmit={onItemEditSubmit}
                item={item}
                readonly={readonly}
                permissions={permissions}
              />
            )}
          />
        </Spin>
      )}
    </div>
  )
}

export default AssetCommentList
