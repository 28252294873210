import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const DefaultRadioGroupClass = css`
  .ant-radio-wrapper {
    font-size: 13px;
    margin-right: 33px;
    &:last-child {
      margin-right: 0;
    }
  }
`
