export const getExciseRatesSelector = (state: any) => {
  return {
    data: state.getIn(['settings', 'excise', 'exciseRates', 'data']),
    isLoading: state.getIn(['settings', 'excise', 'exciseRates', 'loading']),
  }
}

export const getExciseListSelector = (state: any) => {
  return {
    data: state.getIn(['settings', 'excise', 'list', 'data']),
    isLoading: state.getIn(['settings', 'excise', 'list', 'loading']),
  }
}

export const getExciseSettingsByDateList = (state: any) => {
  return {
    data: state.getIn(['settings', 'excise', 'exciseSettingsByDateData', 'data']),
    isLoading: state.getIn(['settings', 'excise', 'exciseSettingsByDateData', 'loading']),
  }
}

export const selectExciseList = (state: any): any[] => {
  return state.getIn(['settings', 'excise', 'list', 'data'])
}
