import styled from '@emotion/styled'

export const Icon = styled.div`
  div {
    width: 14px;
    height: 2px;
    background-color: #d4d8dd;
    margin: 4px 0;
  }
`
