import base from './base'

export const getJournalSettingsForAssetCategoryList = () => {
  return base.get('v1/journal_item_settings/latest')
}

export const getAssetCategoryTermsJournalSettings = ({ asset_category_id }: { asset_category_id: string | number }) => {
  return base.get('v1/journal_item_settings', {
    params: {
      asset_category_id,
      sort: '-created_at',
    },
  })
}

export const getGeneralJournalSettingTerms = () => {
  return base.get('v1/journal_settings')
}

export const deleteAssetCategoryJournalSettingsTerm = (deletedId: string | number) => {
  return base.delete(`v1/journal_item_settings/${deletedId}`)
}

export const deleteGeneralJournalSettingsTerm = (deletedId: string | number) => {
  return base.delete(`v1/journal_settings/${deletedId}`)
}

export const saveAssetCategoryJournalSettingsTerm = (payload: any) => {
  return base.post('v1/journal_item_settings', payload)
}

export const updateJournalItemSettings = (id: number, payload: any) => {
  return base.put(`v1/journal_item_settings/${id}`, payload)
}

export const createGeneralJournalSettingsItem = (payload: any) => {
  return base.post('v1/journal_settings', payload)
}

export const updateGeneralJournalSettingsItem = (id: string | number, payload: any) => {
  return base.put(`v1/journal_settings/${id}`, payload)
}
