/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterFixedAssetFilter } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class SelectAll<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get the fixed asset biids with filters
   *
   * @tags select_all
   * @name FixedAssetsSelectAllCreate
   * @summary Get the fixed asset biids with filters
   * @request POST:/search/fixed_assets/select_all
   */
  fixedAssetsSelectAllCreate = (body: PresenterFixedAssetFilter, params: RequestParams = {}) =>
    this.http.request<string[], any>({
      path: `/search/fixed_assets/select_all`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
