import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkLabel, Tooltip } from 'aa_common/front-end'
import { FEATURES_ID } from 'common/constants'
import { formatMonth, getCurrentJournalSettingsItem } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { GeneralJournalSettingItem, MonthlyClosing } from 'common/models'
import { LineAddNew } from 'components/atoms'
import useUserPermission from 'lib/hooks/useUserPermission'
import { first } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'

import { getConstant } from './const'
import { renderItemFieldValue } from './helper'
import { Field, ItemWrapper, ListWrapper, Section } from './styles'

interface IGeneralJournalSettingList {
  isDetailMode: boolean
  data: GeneralJournalSettingItem[]
  currentMonthlyClosing: MonthlyClosing
  onAddNewClick: (item: GeneralJournalSettingItem) => void
  onEditClick: (item: GeneralJournalSettingItem) => void
  onDeleteClick: (item: GeneralJournalSettingItem) => void
}

const { t, useNsReady } = getNs(['common', 'components/organisms/accounting/general-journal-setting-tab'])
const GeneralJournalSettingList = ({
  data,
  isDetailMode,
  currentMonthlyClosing,
  onEditClick,
  onDeleteClick,
  onAddNewClick,
}: IGeneralJournalSettingList) => {
  useNsReady()
  const {
    permissions: { isCRU, isDelete },
  } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)

  const {
    ACCOUNTING_PLUS_STATUS,
    CREATE_JOURNAL_METHODS,
    DEPRECIATION_JOURNAL_CYCLE_TYPES,
    DEPRECIATION_JOURNAL_METHODS,
    DEPRECIATION_JOURNAL_TYPES,
    SLIP_DATE_TYPES,
  } = getConstant()

  const currentGeneralSettingItem = useMemo<GeneralJournalSettingItem | null>(() => {
    if (currentMonthlyClosing && data && data.length > 0) {
      const targetItem = getCurrentJournalSettingsItem(data, currentMonthlyClosing)
      return targetItem
    }
    return null
  }, [currentMonthlyClosing, data])

  return (
    <ListWrapper>
      {isDetailMode && (
        <LineAddNew
          showTooltipMessage={!isCRU ? t('actions.no_permission') : undefined}
          disabled={!isCRU}
          onClick={() => onAddNewClick(first(data) as GeneralJournalSettingItem)}
        />
      )}
      {data && data.length > 0
        ? data.map(item => {
            const isLatestSettings = item.from_term_month && !item.to_term_month
            const currentSettingsStartDate = moment(currentGeneralSettingItem?.from_term_month.start_date)
            const currentSettingsEndDate = moment(currentGeneralSettingItem?.to_term_month?.end_date)
            const currentMonthlyClosingStartDate = moment(currentMonthlyClosing?.from_term_month.start_date)
            const targetTermEndDate = item.to_term_month ? moment(item.to_term_month.end_date) : null
            const isPast = !!(item.to_term_month && currentSettingsStartDate.isAfter(targetTermEndDate, 'date'))

            const isCurrent =
              (isDetailMode && currentGeneralSettingItem?.from_term_month?.id === item.from_term_month.id) ||
              (!isDetailMode &&
                currentMonthlyClosingStartDate.isBetween(
                  currentSettingsStartDate,
                  currentSettingsEndDate,
                  'date',
                  '[]'
                ))

            const isEditDisabled = isPast || !isCRU
            const isDeleteDisabled = !isDelete || !isLatestSettings || data.length === 1

            const showEditTooltipMessage = () => {
              return !isCRU && !isPast ? t('actions.no_permission') : undefined
            }

            const showDeleteTooltipMessage = () => {
              return isLatestSettings && !isDelete && data.length > 1 ? t('actions.no_permission') : undefined
            }

            const renderValidFrom = () => {
              const validFrom = t('format.term_year_month', {
                year: item.from_term_month.term.year,
                month: formatMonth(item.from_term_month.month),
              })
              if (item.to_term_month && item.from_term_month.id !== item.to_term_month.id) {
                const validTo = t('format.term_year_month', {
                  year: item.to_term_month.term.year,
                  month: formatMonth(item.to_term_month.month),
                })
                return `${validFrom}〜${validTo}`
              }
              if (item.to_term_month && item.from_term_month.id === item.to_term_month.id) {
                return validFrom
              }
              return `${validFrom}〜`
            }

            return (
              <ItemWrapper key={item.key_id}>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('fields.valid_from')}</h4>
                    </div>
                  </div>
                  <div className="content">
                    <span>{renderValidFrom()}</span>
                    {isCurrent && (
                      <span style={{ color: '#666666' }}>{t('status.the_processing_year_is_in_this_period')}</span>
                    )}
                  </div>
                </Field>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('fields.create_journal_method')}</h4>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">
                      {renderItemFieldValue(item.create_journal_method, 'id', 'label', CREATE_JOURNAL_METHODS)}
                    </div>
                  </div>
                </Field>
                <Field>
                  <div className="label slip-date-wrapper">
                    <div className="label-text" style={{ display: 'flex' }}>
                      <h4>{t('fields.slip_date')}</h4>
                      <Tooltip
                        content={t('tooltip.slip_date')}
                        tooltipStyles={{ marginLeft: 5, whiteSpace: 'pre-wrap' }}
                        getTooltipContainer={triggerNode => triggerNode}
                        align={{ offset: [-10, 0] }}
                      >
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">
                      {renderItemFieldValue(item.slip_date_type, 'id', 'label', SLIP_DATE_TYPES)}
                    </div>
                  </div>
                </Field>
                <Field>
                  <div className="label accounting-plus-status-tooltip-wrapper">
                    <div className="label-text">
                      <h4 className="accounting-plus-status">{t('fields.accounting_plus_status')}</h4>
                      <Tooltip
                        content={t('tooltip.accounting_plus_status')}
                        tooltipStyles={{ marginLeft: 5, whiteSpace: 'pre-wrap' }}
                        getTooltipContainer={triggerNode => triggerNode}
                        align={{ offset: [-10, 0] }}
                      >
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">
                      {renderItemFieldValue(item.journal_linkage_status, 'id', 'label', ACCOUNTING_PLUS_STATUS)}
                    </div>
                  </div>
                </Field>
                <Section>
                  <h4>{t('depreciation_impairment_journal')}</h4>
                </Section>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('fields.journal_method')}</h4>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">
                      {renderItemFieldValue(
                        item.depreciation_journal_method,
                        'id',
                        'label',
                        DEPRECIATION_JOURNAL_METHODS
                      )}
                    </div>
                  </div>
                </Field>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('fields.journal_cycle_type')}</h4>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">
                      {renderItemFieldValue(
                        item.depreciation_journal_cycle_type,
                        'id',
                        'label',
                        DEPRECIATION_JOURNAL_CYCLE_TYPES
                      )}
                    </div>
                  </div>
                </Field>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('fields.journal_type')}</h4>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">
                      {renderItemFieldValue(item.depreciation_journal_type, 'id', 'label', DEPRECIATION_JOURNAL_TYPES)}
                    </div>
                  </div>
                </Field>
                <Section>
                  <h4>{t('consumption_tax_account')}</h4>
                </Section>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('paid_excise')}</h4>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-text">{item?.paid_excise_debit_account_item?.name}</div>
                  </div>
                </Field>
                <Field>
                  <div className="label">
                    <div className="label-text">
                      <h4>{t('receive_excise')}</h4>
                    </div>
                  </div>
                  <div className="content content-actions-wrapper">
                    <div className="content-text">{item?.received_excise_debit_account_item?.name}</div>
                    <div className="actions">
                      {isDetailMode && (
                        <>
                          {isLatestSettings && (
                            <LinkLabel
                              showTooltipMessage={showDeleteTooltipMessage()}
                              disabled={isDeleteDisabled}
                              type="danger"
                              onClick={() => onDeleteClick(item)}
                              data-testid="delete-button"
                            >
                              {t('actions.cancel_1')}
                            </LinkLabel>
                          )}
                          {!isPast && (
                            <LinkLabel
                              showTooltipMessage={showEditTooltipMessage()}
                              disabled={isEditDisabled}
                              onClick={() => onEditClick(item)}
                              data-testid="edit-button"
                            >
                              {t('actions.modify')}
                            </LinkLabel>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Field>
              </ItemWrapper>
            )
          })
        : null}
    </ListWrapper>
  )
}

export default GeneralJournalSettingList
