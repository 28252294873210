import React from 'react'

import { Label, Wrapper } from './styles'

export const RequiredLabel = (props: { className?: string }) => {
  const { className } = props
  return (
    <Wrapper className={className}>
      <Label>必須</Label>
    </Wrapper>
  )
}
