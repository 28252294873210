import React from 'react'
import { components, SingleValueProps } from 'react-select'

import Text from '../Text'

const SingleValue = ({ children, ...rest }: React.PropsWithChildren<SingleValueProps<any, false, any>>) => (
  <components.SingleValue {...rest}>
    <Text text={children as string} />
  </components.SingleValue>
)

export default SingleValue
