export const FETCH_MONTHLY_CLOSING_CURRENT_REQUEST = 'FETCH_MONTHLY_CLOSING_CURRENT_REQUEST'
export const FETCH_MONTHLY_CLOSING_CURRENT_SUCCESS = 'FETCH_MONTHLY_CLOSING_CURRENT_SUCCESS'
export const FETCH_MONTHLY_CLOSING_CURRENT_FAILURE = 'FETCH_MONTHLY_CLOSING_CURRENT_FAILURE'

export const FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_REQUEST = 'FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_REQUEST'
export const FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_SUCCESS = 'FETCH_MONTHLY_CLOSING_SUMMARY_ASSET_EVENTS_SUCCESS'

export const FETCH_MONTHLY_CLOSING_CLOSED_REQUEST = 'FETCH_MONTHLY_CLOSING_CLOSED_REQUEST'
export const FETCH_MONTHLY_CLOSING_CLOSED_SUCCESS = 'FETCH_MONTHLY_CLOSING_CLOSED_SUCCESS'

export const FETCH_MONTHLY_CLOSING_NEXT_SETTING_REQUEST = 'FETCH_MONTHLY_CLOSING_NEXT_SETTING_REQUEST'
export const FETCH_MONTHLY_CLOSING_NEXT_SETTING_SUCCESS = 'FETCH_MONTHLY_CLOSING_NEXT_SETTING_SUCCESS'

export const UPDATE_MONTHLY_CLOSING_SETTING_REQUEST = 'UPDATE_MONTHLY_CLOSING_SETTING_REQUEST'
export const UPDATE_MONTHLY_CLOSING_SETTING_SUCCESS = 'UPDATE_MONTHLY_CLOSING_SETTING_SUCCESS'

export const FETCH_MONTHLY_CLOSING_ASSET_EVENT_REQUEST = 'FETCH_MONTHLY_CLOSING_ASSET_EVENT_REQUEST'
export const FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_CURRENT_SUCCESS =
  'FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_CURRENT_SUCCESS'
export const FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_PAST_SUCCESS = 'FETCH_MONTHLY_CLOSING_ASSET_EVENT_IN_PAST_SUCCESS'

export const CLEANUP_MONTHLY_CLOSING_NEXT_SETTING = 'CLEANUP_MONTHLY_CLOSING_NEXT_SETTING'
export const CLEANUP_MONTHLY_CLOSING_ASSET_EVENTS = 'CLEANUP_MONTHLY_CLOSING_ASSET_EVENTS'

export const FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_REQUEST = 'FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_REQUEST'
export const FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_SUCCESS = 'FETCH_MONTHLY_CLOSING_PREVIOUS_ASSET_EVENTS_SUCCESS'

export const SUBMIT_CLOSE_MONTHLY_CLOSING_REQUEST = 'SUBMIT_CLOSE_MONTHLY_CLOSING_REQUEST'
export const SUBMIT_CLOSE_MONTHLY_CLOSING_SUCCESS = 'SUBMIT_CLOSE_MONTHLY_CLOSING_SUCCESS'

export const FETCH_MONTHLY_CLOSING_RESELECT_REQUEST = 'FETCH_MONTHLY_CLOSING_RESELECT'
export const FETCH_MONTHLY_CLOSING_RESELECT_SUCCESS = 'FETCH_MONTHLY_CLOSING_RESELECT_SUCCESS'
export const FETCH_MONTHLY_CLOSING_RESELECT_FAILURE = 'FETCH_MONTHLY_CLOSING_RESELECT_FAILURE'

export const SUBMIT_MONTHLY_CLOSING_RESELECT_REQUEST = 'SUBMIT_MONTHLY_CLOSING_RESELECT_REQUEST'
export const SUBMIT_MONTHLY_CLOSING_RESELECT_SUCCESS = 'SUBMIT_MONTHLY_CLOSING_RESELECT_SUCCESS'

export const REOPEN_MONTHLY_CLOSING_REQUEST = 'REOPEN_MONTHLY_CLOSING_REQUEST'
export const REOPEN_MONTHLY_CLOSING_SUCCESS = 'REOPEN_MONTHLY_CLOSING_SUCCESS'
