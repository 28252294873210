import styled from '@emotion/styled'
import Icon from '@ant-design/icons'

export const SuccessIcon = styled(Icon)`
  &.anticon {
    color: #3b7de9;
  }
`

export const FailureIcon = styled(Icon)`
  &.anticon {
    color: #e31c1c;
    top: 5px;
    margin-right: 2px;
    font-size: 20px;
  }
`

export const LinkContent = styled.p`
  position: relative;
  margin: 0 0 0 12.25px;
  & svg {
    position: relative;
    top: 2px;
    margin-right: 5.25px;
  }
`
