import React from 'react'

import { Wrapper } from './styles'

type Props = {
  color?: string
}

export const DownloadIcon: React.FC<Props> = ({ color = '#3B7DE9' }) => {
  return (
    <Wrapper>
      <svg width="13" height="12" viewBox="0 0 13 12" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28571 10.6071C9.28571 10.861 9.07534 11.0714 8.82143 11.0714C8.56752 11.0714 8.35714 10.861 8.35714 10.6071C8.35714 10.3532 8.56752 10.1429 8.82143 10.1429C9.07534 10.1429 9.28571 10.3532 9.28571 10.6071ZM11.1429 10.6071C11.1429 10.861 10.9325 11.0714 10.6786 11.0714C10.4247 11.0714 10.2143 10.861 10.2143 10.6071C10.2143 10.3532 10.4247 10.1429 10.6786 10.1429C10.9325 10.1429 11.1429 10.3532 11.1429 10.6071ZM12.0714 8.98214C12.0714 8.59766 11.7595 8.28571 11.375 8.28571H8.00893L7.02232 9.27232C6.75391 9.53348 6.40569 9.67857 6.03571 9.67857C5.66574 9.67857 5.31752 9.53348 5.04911 9.27232L4.06975 8.28571H0.696429C0.311942 8.28571 0 8.59766 0 8.98214V11.3036C0 11.6881 0.311942 12 0.696429 12H11.375C11.7595 12 12.0714 11.6881 12.0714 11.3036V8.98214ZM9.71373 4.85435C9.64118 4.6875 9.47433 4.57143 9.28571 4.57143H7.42857V1.32143C7.42857 1.06752 7.21819 0.857142 6.96429 0.857142H5.10714C4.85324 0.857142 4.64286 1.06752 4.64286 1.32143V4.57143H2.78571C2.5971 4.57143 2.43025 4.6875 2.3577 4.85435C2.28516 5.02846 2.32143 5.23158 2.45926 5.36216L5.70926 8.61217C5.79632 8.70647 5.91964 8.75 6.03571 8.75C6.15179 8.75 6.27511 8.70647 6.36217 8.61217L9.61217 5.36216C9.75 5.23158 9.78627 5.02846 9.71373 4.85435Z" />
      </svg>
    </Wrapper>
  )
}
