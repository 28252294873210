import {
  CLEANUP_EXCISE,
  FETCH_EXCISE_LIST_FAILURE,
  FETCH_EXCISE_LIST_REQUEST,
  FETCH_EXCISE_LIST_REQUEST_AUTH,
  FETCH_EXCISE_LIST_SUCCESS,
  FETCH_EXCISE_RATES_REQUEST,
  FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST,
  GET_EXCISE_VAT_INFO_REQUEST,
  UPDATE_EXCISE_LIST_ITEM_REQUEST,
} from './constant'
import { ExciseSearchPayload } from './model'

export const fetchExciseList = ({ filters, sort, pageSize = 1000, pageNumber }: ExciseSearchPayload) => {
  return {
    type: FETCH_EXCISE_LIST_REQUEST,
    payload: {
      filters,
      sort,
      pageSize,
      pageNumber,
    },
  }
}

export const fetchExciseListAuth = ({ filters, sort, pageSize = 1000, pageNumber }: ExciseSearchPayload) => ({
  type: FETCH_EXCISE_LIST_REQUEST_AUTH,
  payload: {
    filters,
    sort,
    pageSize,
    pageNumber,
  },
})

export const fetchExciseListSuccess = (payload: any) => ({
  type: FETCH_EXCISE_LIST_SUCCESS,
  payload,
})

export const fetchExciseListFailure = () => ({
  type: FETCH_EXCISE_LIST_FAILURE,
})

export const fetchExciseRates = () => {
  return {
    type: FETCH_EXCISE_RATES_REQUEST,
  }
}

export const fetchExciseSettingsByDate = (date: string) => {
  return {
    type: FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST,
    date,
    meta: { thunk: true },
  }
}

export const updateExciseListItem = (id: string | number, updatedData: any) => {
  return {
    type: UPDATE_EXCISE_LIST_ITEM_REQUEST,
    id,
    updatedData,
  }
}

export const getExciseVatInfoRequest = (at: string, excise_id: number, input_value: number) => {
  return {
    type: GET_EXCISE_VAT_INFO_REQUEST,
    at,
    excise_id,
    input_value,
  }
}

export const cleanupExcise = () => ({ type: CLEANUP_EXCISE })
