import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  background: #7c8291;
  border-radius: 4px;
  color: #ffffff;
  padding: 3px;
  height: 18px;
  font-size: 12px;
  font-weight: 500;
`
