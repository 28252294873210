import { formatDate, identifyJournalStatusType, objectToQueryParams, yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { AssetReport } from 'common/models'
import get from 'lodash/get'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayDataRow } from '../styles'
import { AssetDisplayDetail } from './asset-display-detail'
import { JournalStatus } from './journal-status'
import { Media } from './media'
import { Row } from './row'

const t = loadNs(['common', 'components/organisms/accounting/monthly-closing-asset-event-list'])

export const AcquisitionEventView = ({ assets }: Props) => {
  const dispatch = useDispatch()

  const handleAssetClick = (asset: AssetReport) => {
    if (!asset.deleted_at) {
      const paramObj = {
        valid_at: asset.acquired_at,
      }
      const params = `?${objectToQueryParams(paramObj)}`
      dispatch(changeLocation(`/assets/${asset.biid || ''}${params}`))
    }
  }

  if (!assets) return null
  return (
    <>
      {assets.map(asset => {
        const isDeleted = !!asset.deleted_at
        const termMonthId = asset?.term_month?.month || null

        return (
          <AcquisitionEvent
            key={asset?.biid}
            asset={asset}
            isDeleted={isDeleted}
            termMonthId={termMonthId}
            onAssetClick={handleAssetClick}
          />
        )
      })}
    </>
  )
}

const AcquisitionEvent = ({ asset, onAssetClick, isDeleted, termMonthId }: AcquisitionEventProps) => {
  return (
    <AssetDisplay className={isDeleted ? 'deleted' : ''} onClick={() => onAssetClick(asset)}>
      <AssetDisplayDetail asset={asset} termMonthId={termMonthId} isDeleted={isDeleted} labelText={t('acquired_at')} />
      <Row
        label={t('business_start_date')}
        value={asset?.usage_started_at ? formatDate(asset?.usage_started_at) : null}
      />
      <Row
        label={
          get(asset, 'acquisition_cost_excluded_excise') === get(asset, 'acquisition_cost')
            ? t('acquisition_cost_exclude')
            : t('acquisition_cost_include')
        }
        value={yen(asset.acquisition_cost)}
      />
      <Row label={t('asset_category')} value={get(asset.asset_category, 'name')} />
      <Row label={t('department')} value={get(asset.department, 'name')} />
      <Row label={t('area')} value={get(asset.area, 'name')} />
      {asset?.fixed_asset_ledgers?.map(({ ledger_setting_id, depreciation_method, ledger_setting, service_life }) => (
        <div key={ledger_setting_id}>
          <Row
            label={t('depreciation_method', {
              ledger_name: ledger_setting?.name,
            })}
            value={get(depreciation_method, 'name_jp')}
          />
          <Row
            label={t('service_life', {
              ledger_name: ledger_setting?.name,
            })}
            value={`${service_life || 0}${t('units.year')}`}
          />
        </div>
      ))}
      <Media asset={asset} />
      <Row className={isDeleted ? '' : 'last'} label={t('acquired_by')} value={get(asset.user_info, 'display_name')} />
      {isDeleted && (
        <AssetDisplayDataRow className="last">
          <div className="status">
            <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={termMonthId} />
          </div>
          <div className="empty-space" />
          <div className="short-label top-border">{get(asset, 'change_situations[0].valid_from')}</div>
          <div className="short-value top-border">{t('cancel_event')}</div>
          <div className="label">{t('cancel_acquired_at')}</div>
          <div className="value">{formatDate(get(asset, 'change_situations[0].created_at'))}</div>
        </AssetDisplayDataRow>
      )}
    </AssetDisplay>
  )
}

type Props = {
  assets: AssetReport[]
}

type AcquisitionEventProps = {
  asset: AssetReport
  isDeleted: boolean
  termMonthId: number
  onAssetClick: (asset: AssetReport) => void
}
