import { useEffect, useState } from 'react'

export function useFirstNotNullValue<T>(value: T) {
  const [val, setVal] = useState<T>()

  useEffect(() => {
    if (val === null || typeof val === 'undefined') {
      setVal(value)
    }
  }, [val, value])

  return val
}
