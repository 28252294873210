import { isEqual } from 'lodash'
import { assetEventListReportSlice } from 'pages/accounting/AssetEventListReportPage/store'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetEventsReport } from 'store/accounting/assetEventsReport/selectors'

export const useGetSelectedEventOnPage = (eventType?: number) => {
  const assetCollectionReportSelector = useSelector(selectAssetEventsReport, isEqual)
  const selectedList = assetEventListReportSlice.useSlice(state => state.data?.selectedItem)
  const selectedEvents = useMemo(() => {
    return assetCollectionReportSelector?.data?.data?.filter((item: any) => selectedList && selectedList[item?.id])
  }, [selectedList, assetCollectionReportSelector, eventType])

  const selectedEventIds = selectedEvents?.map((item: any) => item?.id)

  return { selectedEvents, selectedEventIds }
}
