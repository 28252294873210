import { Spin } from 'antd'
import { get, last } from 'lodash'
import React, { useContext, useImperativeHandle, useRef } from 'react'
import useVirtual from 'react-cool-virtual'

import InfiniteScrollContext from '../../context'

interface CustomMenuProps {
  menuListProps: any
  onBottomScrollReach: (event: any) => any
}

const CustomMenuList = React.forwardRef<any, CustomMenuProps>(function MenuList(
  { menuListProps, onBottomScrollReach },
  ref
) {
  const { renderFooter, hasMoreRows, data, isLoading, isFetching, isRefetching } = useContext(InfiniteScrollContext)

  const loadedOptions = menuListProps.selectProps.options
  const childrenArray = React.Children.toArray(menuListProps.children)

  const loadMoreRows = () => {
    const isNotExecuting = !isLoading && !isFetching && !isRefetching
    if (hasMoreRows && isNotExecuting) {
      const lastPage = get(last(data.pages), 'page')
      const event = {
        page: lastPage + 1,
      }

      onBottomScrollReach(event)
    }
  }

  const wrapperDiv = useRef<any>(null)

  const { outerRef, innerRef, items, scrollToItem, scrollTo } = useVirtual<HTMLDivElement>({
    itemCount: loadedOptions.length,
    onScroll: () => {
      const isBottomReach =
        wrapperDiv.current.scrollTop + wrapperDiv.current.offsetHeight >= wrapperDiv.current.scrollHeight
      isBottomReach && loadMoreRows()
    },
  })

  useImperativeHandle(ref, () => ({
    scrollToItem,
    scrollTo,
  }))

  return (
    <>
      <Spin spinning={isLoading || isFetching || isRefetching}>
        <div
          style={{ width: '100%', maxHeight: 250, overflow: 'auto' }}
          ref={ref => {
            wrapperDiv.current = ref
            outerRef.current = ref
          }}
        >
          <div ref={innerRef}>
            {items.length > 0 && loadedOptions.length > 0 ? (
              items.map(({ index, measureRef }) => {
                const child = childrenArray[index]
                return (
                  child && (
                    <div key={index} ref={measureRef}>
                      {child}
                    </div>
                  )
                )
              })
            ) : (
              <div />
            )}
          </div>
        </div>
      </Spin>
      {renderFooter && <div className="footer">{renderFooter}</div>}
    </>
  )
})

export default CustomMenuList
