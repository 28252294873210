import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DATE_FORMAT } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { IMasterData } from 'common/models'
import { RenderFormProps } from 'components/molecules/Form'
import { ASSET_CODE_FIELD, branchCodeField, codeField } from 'components/organisms/assets/AssetForm/const'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'
import { find, findIndex, get, isArray, pick } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

import { BASE_OTHER_CONDITION_FILTERS, OTHER_CONDITION_FILTERS } from '../../forms/AdvancedFilterModalForm/const'
import { Content, Empty, List, ListItem } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

interface IOtherConditionContent {
  formState: any
  masterData: IMasterData
  formModule: RenderFormProps
  onFormSubmit: (event: any) => void
}

const OtherConditionContent = ({ formState, masterData, formModule, onFormSubmit }: IOtherConditionContent) => {
  const [renderFilters, setRenderFilters] = useState(getData(formState))

  useEffect(() => {
    if (formState) {
      setRenderFilters(getData(formState))
    }
  }, [formState])

  useEffect(() => {
    const isFieldAssetCode = getData(formState).some(item =>
      [codeField.text, branchCodeField.text].includes(item.fieldKey)
    )
    if (isFieldAssetCode) {
      const newRenderFilters = [
        ...getData(formState),
        {
          fieldKey: ASSET_CODE_FIELD,
          value: {
            code: findFilterItem(codeField.text)?.value || t('undefined'),
            branch_code: findFilterItem(branchCodeField.text)?.value || t('undefined'),
          },
        },
      ].filter((filterItem: any) => ![codeField.text, branchCodeField.text].includes(filterItem.fieldKey))

      setRenderFilters(newRenderFilters)
    }
  }, [formState]) //eslint-disable-line

  const findFilterItem = (fieldKey: string) => {
    return getData(formState).find((filterItem: any) => filterItem.fieldKey === fieldKey)
  }

  const handleClick = useCallback(
    (fieldKey: string) => {
      const { setValue, getValues, handleSubmit } = formModule

      const fields = getValues('submitFields')
      const deletedIndex = findIndex(fields, (item: any) => item.fieldKey === fieldKey)
      const updated = ImmutableList(fields).remove(deletedIndex).toArray()
      setValue('submitFields', updated)
      setValue(fieldKey, find(OTHER_CONDITION_FILTERS, item => item.value === fieldKey)?.emptyValue)

      if (fieldKey === ASSET_CODE_FIELD) {
        setValue(codeField.text, null)
        setValue(branchCodeField.text, null)
      }

      const submitRemainingFields = getValues('submitRemainingFields')
      const pushFilter = find(OTHER_CONDITION_FILTERS, item => item.value === fieldKey)
      const updatedRemainingFields = ImmutableList(submitRemainingFields).push(pushFilter).toSet()
      setValue('submitRemainingFields', updatedRemainingFields.toArray())

      const submitFormValue = getValues('submitFormValues')
      const updatedSubmitFormValues = ImmutableMap(submitFormValue).remove(fieldKey).toJSON()

      const payload = new Map()
      for (const field of OTHER_CONDITION_FILTERS) {
        if (!get(updatedSubmitFormValues, field.value)) {
          if (field.value === ASSET_CODE_FIELD) {
            payload.set(codeField.text, null)
            payload.set(branchCodeField.text, null)
          }

          payload.set(field.value, field.emptyValue)
        } else {
          payload.set(field.value, get(updatedSubmitFormValues, field.value))
        }
      }
      const convertedPayload = Object.fromEntries(payload.entries())
      setValue('submitFormValues', convertedPayload)
      handleSubmit(onFormSubmit, onFormSubmit)()
    },
    [formModule, onFormSubmit]
  )

  const renderContent = (fieldKey: string, fieldValue: any) => {
    switch (fieldKey) {
      case 'name':
      case 'name_kana':
      case 'summary':
      case 'pre_submit_number':
      case 'acquire_destination_name':
      case 'memo':
        return <span>{fieldValue}</span>

      case 'quantity': {
        const [fromValue, toValue] = fieldValue

        return <span>{`${fromValue} ~ ${toValue}`}</span>
      }

      case 'acquire_category':
      case 'asset_type_for_corporate_tax':
      case 'asset_type_for_depreciable_property_tax':
        return (
          isArray(fieldValue) &&
          fieldValue
            .map(item => {
              const masterDataList = get(masterData, fieldKey, [])
              const valueItem = find(masterDataList, (masterItem: any) => masterItem.code === item)
              return valueItem?.name_jp || ''
            })
            .join(', ')
        )
      case 'created_at': {
        const fromValue = fieldValue && fieldValue[0] ? moment(fieldValue[0]).format(DATE_FORMAT) : t('unset')
        const toValue = fieldValue && fieldValue[1] ? moment(fieldValue[1]).format(DATE_FORMAT) : t('unset')

        return <span>{`${fromValue} ~ ${toValue}`}</span>
      }
      case 'asset_code':
        return (
          <span>
            {fieldValue.code} - {fieldValue.branch_code}
          </span>
        )
      default:
        return null
    }
  }

  return renderFilters.length === 0 ? (
    <Empty>{t('empty')}</Empty>
  ) : (
    <List>
      {renderFilters.map(filter => {
        const conditionItemText = t('condition_item')
        const otherFilterConditions = t(`otherFilterConditions.${filter.fieldKey}`)
        return (
          <ListItem key={filter.fieldKey}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ marginRight: 6, marginTop: 5, cursor: 'pointer' }}
              onClick={() => handleClick(filter.fieldKey)}
            />
            <Content>
              <span>
                {conditionItemText}: {otherFilterConditions}
              </span>
              <br />
              <div>{renderContent(filter.fieldKey, filter.value)}</div>
            </Content>
          </ListItem>
        )
      })}
    </List>
  )
}

const getData = (formState: any) => {
  const data = pick(formState, BASE_OTHER_CONDITION_FILTERS)
  const convertedData = Object.keys(data)
    .map(fieldKey => {
      return {
        fieldKey,
        value: get(data, fieldKey),
      }
    })
    .filter(
      item =>
        typeof item.value === 'string' ||
        typeof item.value === 'number' ||
        (isArray(item.value) && item.value.length > 0)
    )
  return convertedData
}

export default OtherConditionContent
