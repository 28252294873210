export const FETCH_TERMS_REQUEST = 'FETCH_TERMS_REQUEST'
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS'
export const FETCH_TERMS_FAILURE = 'FETCH_TERMS_FAILURE'
export const FETCH_TERMS_FULL_REQUEST = 'FETCH_TERMS_FULL_REQUEST'
export const FETCH_TERMS_FULL_SUCCESS = 'FETCH_TERMS_FULL_SUCCESS'
export const FETCH_TERMS_FULL_FAILURE = 'FETCH_TERMS_FULL_FAILURE'
export const FETCH_TERM_REQUEST = 'FETCH_TERM_REQUEST'
export const FETCH_TERM_SUCCESS = 'FETCH_TERM_SUCCESS'
export const FETCH_TERM_FAILURE = 'FETCH_TERM_FAILURE'
export const FETCH_CHANGED_TERM_REQUEST = 'FETCH_CHANGED_TERM_REQUEST'
export const FETCH_CHANGED_TERM_SUCCESS = 'FETCH_CHANGED_TERM_SUCCESS'
export const FETCH_CHANGED_TERM_FAILURE = 'FETCH_CHANGED_TERM_FAILURE'
export const GENERATE_TERM_PERIODS_REQUEST = 'GENERATE_TERM_PERIODS_REQUEST'
export const GENERATE_TERM_PERIODS_SUCCESS = 'GENERATE_TERM_PERIODS_SUCCESS'
export const CREATE_TERM_PERIODS_REQUEST = 'CREATE_TERM_PERIODS_REQUEST'
export const CREATE_TERM_PERIODS_SUCCESS = 'CREATE_TERM_PERIODS_SUCCESS'
export const UPDATE_TERM_PERIOD_REQUEST = 'UPDATE_TERM_PERIOD_REQUEST'
export const UPDATE_TERM_PERIOD_SUCCESS = 'UPDATE_TERM_PERIOD_SUCCESS'
export const SUBMIT_TERM_PERIODS_FAILURE = 'SUBMIT_TERM_PERIODS_FAILURE'
export const SET_CURRENT_TERM_ID = 'SET_CURRENT_TERM_ID'
export const CLEANUP_TERM_PERIODS = 'CLEANUP_TERM_PERIODS'
export const CLEANUP_TERMS = 'CLEANUP_TERMS'

export const fetchTerms = () => {
  return { type: FETCH_TERMS_REQUEST }
}

export const fetchFullTerms = () => {
  return { type: FETCH_TERMS_FULL_REQUEST }
}

export const fetchTermPeriods = (id: number) => {
  return { type: FETCH_TERM_REQUEST, id, meta: { thunk: true } }
}

export const fetchChangedTermPeriods = (payload: any) => {
  return { type: FETCH_CHANGED_TERM_REQUEST, payload }
}

export const generateTermPeriods = (payload: any) => {
  return { type: GENERATE_TERM_PERIODS_REQUEST, payload }
}

export const createTermPeriodsRequest = (payload: any) => {
  return { type: CREATE_TERM_PERIODS_REQUEST, payload }
}

export const updateTermPeriodRequest = (payload: any) => {
  return {
    type: UPDATE_TERM_PERIOD_REQUEST,
    payload,
    meta: { thunk: true },
  }
}

export const setCurrentTermId = (payload: any) => {
  return { type: SET_CURRENT_TERM_ID, payload }
}

export const cleanupTermPeriods = () => {
  return { type: CLEANUP_TERM_PERIODS }
}

export const cleanupTerms = () => {
  return { type: CLEANUP_TERMS }
}
