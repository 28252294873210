/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAssetCategoryRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterOneAssetCategoryResponse,
  PresenterResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AssetCategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get asset category list
   *
   * @tags asset_categories
   * @name AssetCategoriesList
   * @summary Get the asset category list
   * @request GET:/asset_categories
   */
  assetCategoriesList = (
    query?: {
      'filter[asset_detail_for_corporate_tax]'?: string
      'filter[asset_structure_for_corporate_tax]'?: string
      'filter[asset_type_for_corporate_tax]'?: (
        | 10
        | 11
        | 12
        | 13
        | 14
        | 15
        | 16
        | 17
        | 20
        | 30
        | 40
        | 50
        | 60
        | 99
        | 999
      )[]
      'filter[asset_type_for_depreciable_property_tax]'?: (1 | 2 | 3 | 4 | 5 | 6 | 99)[]
      'filter[code]'?: string
      'filter[created_at]'?: string[]
      'filter[depreciation_method]'?: (10 | 11 | 20 | 21 | 22 | 30 | 40 | 50 | 60 | 80 | 90 | 99)[]
      'filter[excise_id]'?: number | string
      'filter[name]'?: string
      'filter[search_key]'?: string
      sort?: string[]
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneAssetCategoryResponse[], any>({
      path: `/asset_categories`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create asset category with name
   *
   * @tags asset_categories
   * @name AssetCategoriesCreate
   * @summary Create the asset category
   * @request POST:/asset_categories
   */
  assetCategoriesCreate = (body: PresenterAssetCategoryRequest, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/asset_categories`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Import asset category from csv file
   *
   * @tags asset_categories
   * @name ImportOneCreate
   * @summary Import the asset category
   * @request POST:/asset_categories/import_one
   */
  importOneCreate = (
    data: {
      /** asset accounting csv file to import */
      file: File
      /** asset accounting csv file to import */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterResponse, any>({
      path: `/asset_categories/import_one`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description Get asset category with id
   *
   * @tags asset_categories
   * @name AssetCategoriesDetail
   * @summary Get the asset category by ID
   * @request GET:/asset_categories/{id}
   */
  assetCategoriesDetail = (id: number | string, params: RequestParams = {}) =>
    this.http.request<PresenterOneAssetCategoryResponse, PresenterError>({
      path: `/asset_categories/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Replace asset category
   *
   * @tags asset_categories
   * @name AssetCategoriesUpdate
   * @summary Replace the asset category
   * @request PUT:/asset_categories/{id}
   */
  assetCategoriesUpdate = (id: number | string, body: PresenterAssetCategoryRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/asset_categories/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete asset category with id
   *
   * @tags asset_categories
   * @name AssetCategoriesDelete
   * @summary Delete the asset category
   * @request DELETE:/asset_categories/{id}
   */
  assetCategoriesDelete = (
    id: number | string,
    query?: {
      /** dry_run enable to check whether you can delete Asset Category or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/asset_categories/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
