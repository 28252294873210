import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoCompleteSelect, Tooltip } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { AccountItem } from 'common/models/'
import { RenderFormProps } from 'components/molecules/Form'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { getConstant } from '../GeneralJournalSettingList/const'
import { EditFormWrapper, Field, Section } from './styles'

interface EditFormProps {
  accountItems: AccountItem[]
}

const t = loadNs(['common', 'components/organisms/accounting/general-journal-setting-tab'])
const FormField: React.FC<{ children: React.ReactNode; required?: boolean; title: string | React.ReactNode }> = ({
  children,
  required,
  title,
}) => {
  const renderTitle = () => {
    if (typeof title === 'string') {
      return <h4>{title}</h4>
    }
    return title
  }

  return (
    <Field>
      <div className="label">
        <div className="label-text">{renderTitle()}</div>
        {required && (
          <div className="required-label-wrapper">
            <div className="required-label">
              <span>{t('required')}</span>
            </div>
          </div>
        )}
      </div>
      <div className="content">
        <div style={{ width: '250px' }}>{children}</div>
      </div>
    </Field>
  )
}

const EditForm = ({ accountItems }: EditFormProps) => {
  const { loading, control } = useFormContext() as RenderFormProps
  const {
    ACCOUNTING_PLUS_STATUS,
    CREATE_JOURNAL_METHODS,
    DEPRECIATION_JOURNAL_CYCLE_TYPES,
    DEPRECIATION_JOURNAL_METHODS,
    DEPRECIATION_JOURNAL_TYPES,
    SLIP_DATE_TYPES,
  } = getConstant()

  return (
    <EditFormWrapper>
      <div className="top">
        <FormField required title={t('fields.create_journal_method')}>
          <AutoCompleteSelect
            name="create_journal_method"
            disabled={loading}
            items={CREATE_JOURNAL_METHODS}
            valueField="id"
            textField="label"
            control={control}
            allowClear={false}
          />
        </FormField>
        <FormField
          required
          title={
            <div className="slip-date-form-wrapper">
              <h4>{t('fields.slip_date')}</h4>
              <Tooltip
                content={t('tooltip.slip_date')}
                getTooltipContainer={triggerNode => triggerNode}
                align={{ offset: [-13, 0] }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </div>
          }
        >
          <AutoCompleteSelect
            name="slip_date_type"
            items={SLIP_DATE_TYPES}
            valueField="id"
            textField="label"
            disabled={loading}
            control={control}
            allowClear={false}
          />
        </FormField>
        <FormField
          required
          title={
            <div className="accounting-plus-status-form-wrapper">
              <h4 className="accounting-plus-status">{t('fields.accounting_plus_status')}</h4>
              <Tooltip
                content={t('tooltip.accounting_plus_status')}
                getTooltipContainer={triggerNode => triggerNode}
                align={{ offset: [-13, 0] }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </div>
          }
        >
          <AutoCompleteSelect
            name="journal_linkage_status"
            items={ACCOUNTING_PLUS_STATUS}
            valueField="id"
            textField="label"
            disabled={loading}
            control={control}
            allowClear={false}
          />
        </FormField>
      </div>
      <Section>
        <h4>{t('depreciation_impairment_journal')}</h4>
      </Section>
      <div className="middle">
        <FormField title={t('fields.journal_method')} required>
          <AutoCompleteSelect
            name="depreciation_journal_method"
            items={DEPRECIATION_JOURNAL_METHODS}
            valueField="id"
            textField="label"
            disabled={loading}
            control={control}
            allowClear={false}
          />
        </FormField>
        <FormField title={t('fields.journal_cycle_type')} required>
          <AutoCompleteSelect
            name="depreciation_journal_cycle_type"
            items={DEPRECIATION_JOURNAL_CYCLE_TYPES}
            valueField="id"
            textField="label"
            disabled={loading}
            control={control}
            allowClear={false}
          />
        </FormField>
        <FormField title={t('fields.journal_type')} required>
          <AutoCompleteSelect
            name="depreciation_journal_type"
            items={DEPRECIATION_JOURNAL_TYPES}
            valueField="id"
            textField="label"
            control={control}
            disabled={loading}
            allowClear={false}
          />
        </FormField>
      </div>
      <Section>
        <h4>{t('consumption_tax_account')}</h4>
      </Section>
      <div className="bottom">
        <FormField required title={t('paid_excise')}>
          <AutoCompleteSelect
            name="paid_excise_debit_account_item_id"
            valueField="id"
            textField="name"
            disabled={loading}
            items={accountItems}
            control={control}
          />
        </FormField>
        <FormField required title={t('receive_excise')}>
          <AutoCompleteSelect
            name="received_excise_debit_account_item_id"
            valueField="id"
            textField="name"
            disabled={loading}
            items={accountItems}
            control={control}
          />
        </FormField>
      </div>
    </EditFormWrapper>
  )
}

export default EditForm
