import { EMPTY_SYMBOL } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import React from 'react'
import Highlighter from 'react-highlight-words'

const t = loadNs(['pages/assets'])

export const HighlightText = ({ children }: any) => (
  <span className="highlighted-text" style={{ background: '#ffe291' }}>
    {children}
  </span>
)

export const AssetCodeTemplate = ({ data, searchWords }: { data: any; searchWords: string[] }) => {
  return (
    <Highlighter
      searchWords={searchWords}
      highlightTag={HighlightText}
      textToHighlight={`${data.code}-${data.branch_code}`}
      autoEscape
    />
  )
}

export const TextHighlightTemplate = ({ data, searchWords }: { data: string; searchWords: string[] }) => {
  return <Highlighter searchWords={searchWords} highlightTag={HighlightText} textToHighlight={`${data}`} autoEscape />
}

export const DepreciationMethodTemplate = (depreciation_method: any) => {
  return <span>{depreciation_method || EMPTY_SYMBOL}</span>
}

export const ServiceLifeTemplate = (amount: number) => {
  return <span>{amount ? t('table.data.service_life', { amount }) : EMPTY_SYMBOL}</span>
}
