import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { SummaryAssetEventMetrics } from 'common/models'

export const parseSummaryAssetEvents = (data: any) => {
  const summaryAssetEvents: SummaryAssetEventMetrics = {
    acquire_amount: 0,
    retire_amount: 0,
    sell_amount: 0,
    move_amount: 0,
    switch_amount: 0,
    impair_amount: 0,
    dividing: 0,
  }

  if (!data) return summaryAssetEvents

  data.forEach((item: any) => {
    switch (item?.code) {
      case ASSET_CHANGE_SITUATION_TYPES.ACQUISITION:
        summaryAssetEvents.acquire_amount = item?.total
        break
      case ASSET_CHANGE_SITUATION_TYPES.RETIREMENT:
        summaryAssetEvents.retire_amount = item?.total
        break
      case ASSET_CHANGE_SITUATION_TYPES.MOVEMENT:
        summaryAssetEvents.move_amount = item?.total
        break
      case ASSET_CHANGE_SITUATION_TYPES.SELL:
        summaryAssetEvents.sell_amount = item?.total
        break
      case ASSET_CHANGE_SITUATION_TYPES.SWITCH:
        summaryAssetEvents.switch_amount = item?.total
        break
      case ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT:
        summaryAssetEvents.impair_amount = item?.total
        break
      case ASSET_CHANGE_SITUATION_TYPES.DIVIDING:
        summaryAssetEvents.dividing = item?.total
        break
      default:
        break
    }
  })

  return summaryAssetEvents
}
