import { Spin } from 'aa_common/front-end/antd'
import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CorporateIncomeTaxReportPage from '../../pages/accounting/corporate-income-tax-report'
import PrivateRoute from './PrivateRoute'
import RouteWithoutOffice from './RouteWithoutOffice'

const AreaNewPage = lazy(() => import('../../pages/settings/AreaNewPage'))
const AreaListPage = lazy(() => import('../../pages/settings/AreaListPage'))
const AreaDetailPage = lazy(() => import('../../pages/settings/AreaDetailPage'))
const SubmitDestinationListPage = lazy(() => import('../../pages/settings/SubmitDestinationListPage'))
const SubmitDestinationNewPage = lazy(() => import('../../pages/settings/SubmitDestinationNewPage'))
const SubmitDestinationEditPage = lazy(() => import('../../pages/settings/SubmitDestinationEditPage'))
const SubmitDestinationCopyPage = lazy(() => import('../../pages/settings/SubmitDestinationCopyPage'))
const SubmitDestinationDetailPage = lazy(() => import('../../pages/settings/SubmitDestinationDetailPage'))
const AreaCopyPage = lazy(() => import('../../pages/settings/AreaCopyPage'))
const CallbackPage = lazy(() => import('../../pages/user/CallbackPage'))
const AssetListPage = lazy(() => import('../../pages/assets/AssetListPage'))
const AssetNewPage = lazy(() => import('../../pages/assets/AssetNewPage'))
const AssetDetailPage = lazy(() => import('../../pages/assets/AssetDetailPage'))
const TermOfUsePage = lazy(() => import('../../pages/user/TermOfUsePage'))
const OfficeNewPage = lazy(() => import('../../pages/office/NewPage'))
const OfficeSelectPage = lazy(() => import('../../pages/office/SelectPage'))
const AccountingPeriodCreatePage = lazy(() => import('../../pages/user/AccountingPeriodCreatePage'))
const AssetCopyPage = lazy(() => import('../../pages/assets/AssetCopyPage'))
const AssetEditPage = lazy(() => import('../../pages/assets/AssetEditPage'))
const AssetCategoryListPage = lazy(() => import('../../pages/settings/AssetCategoryListPage'))
const AssetCategoryNewPage = lazy(() => import('../../pages/settings/AssetCategoryNewPage'))
const AssetCategoryEditPage = lazy(() => import('../../pages/settings/AssetCategoryEditPage'))
const AssetCategoryCopyPage = lazy(() => import('../../pages/settings/AssetCategoryCopyPage'))
const NotFoundErrorPage = lazy(() => import('../../pages/errors/NotFoundErrorPage'))
const InternalServerErrorPage = lazy(() => import('../../pages/errors/InternalServerErrorPage'))
const ExciseListPage = lazy(() => import('../../pages/settings/ExciseListPage'))
const CauseListPage = lazy(() => import('../../pages/settings/CauseListPage'))
const CauseNewPage = lazy(() => import('../../pages/settings/CauseNewPage'))
const CauseEditPage = lazy(() => import('../../pages/settings/CauseEditPage'))
const CauseCopyPage = lazy(() => import('../../pages/settings/CauseCopyPage'))
const DepartmentPage = lazy(() => import('../../pages/settings/DepartmentPage'))
const DepartmentListPage = lazy(() => import('../../pages/settings/DepartmentListPage'))
const DepartmentCopyPage = lazy(() => import('../../pages/settings/DepartmentCopyPage'))
const DepartmentDetailPage = lazy(() => import('../../pages/settings/DepartmentDetailPage'))
const AccountingPeriodPage = lazy(() => import('../../pages/settings/AccountingPeriodPage'))
const AmountSummaryPage = lazy(() => import('../../pages/depreciation/AmountSummaryPage'))
const LedgerSettingsViewPage = lazy(() => import('../../pages/depreciation/LedgerSettingPage/view'))
const LedgerSettingsEditPage = lazy(() => import('../../pages/depreciation/LedgerSettingPage/edit'))
const AccountItemListPage = lazy(() => import('../../pages/settings/AccountItemListPage'))
const AssetImportPage = lazy(() => import('../../pages/assets/AssetImportPage'))
const AssetImportResultsPage = lazy(() => import('../../pages/assets/AssetImportResultsPage'))
const AutoJournalSettingPage = lazy(() => import('../../pages/accounting/AutoJournalSettingPage'))
const MonthlyClosingPage = lazy(() => import('../../pages/accounting/MonthlyClosingPage'))
const MonthlyClosingSettingPage = lazy(() => import('../../pages/accounting/MonthlyClosingSettingPage'))
const JournalListPage = lazy(() => import('../../pages/accounting/JournalListPage'))
const JournalHistoryPage = lazy(() => import('../../pages/accounting/JournalHistoryPage'))
const MonthlyClosingAssetEventListPage = lazy(() => import('../../pages/accounting/MonthlyClosingAssetEventListPage'))
const AssetEventListReportPage = lazy(() => import('../../pages/accounting/AssetEventListReportPage'))
const AssetCodeAutoGenerationPage = lazy(() => import('../../pages/settings/AssetCodeAutoGenerationPage'))
const AssetCodeStartNumberPage = lazy(() => import('../../pages/settings/AssetCodeStartNumberPage'))
const IncreaseDecreaseReportPage = lazy(() => import('../../pages/accounting/IncreaseDecreaseReportPage'))
const UserListPage = lazy(() => import('../../pages/settings/UserListPage'))
const UserNewPage = lazy(() => import('../../pages/settings/UserNewPage'))
const UserEditPage = lazy(() => import('../../pages/settings/UserEditPage'))
const OperationLogPage = lazy(() => import('../../pages/internalControl/OperationLogPage'))
const ReCalculatePage = lazy(() => import('../../pages/settings/ReCalculatePage'))
const OfficeShowPage = lazy(() => import('../../pages/settings/OfficeShowPage'))
const RoleListPage = lazy(() => import('../../pages/settings/RoleListPage'))
const RoleNewPage = lazy(() => import('../../pages/settings/RoleNewPage'))
const RoleDetailPage = lazy(() => import('../../pages/settings/RoleDetailPage'))
const ExciseSettingPage = lazy(() => import('../../pages/settings/ExciseSettingPage'))
const RoleEditPage = lazy(() => import('../../pages/settings/RoleEditPage'))

const SettingImportPage = lazy(() => import('../../pages/settings/ImportPage'))
const JournalListReportPage = lazy(() => import('../../pages/accounting/JournalListReportPage'))
const SSOPage = lazy(() => import('../../pages/user/SSOPage'))
const LoginPage = lazy(() => import('../../pages/user/LoginPage'))
const SettingImportResultPage = lazy(() => import('../../pages/settings/SettingImportResultPage'))
const NoAuthorizationPage = lazy(() => import('../../pages/errors/NoAuthorizationPage'))
const UnderMaintenancePage = lazy(() => import('../../pages/errors/UnderMaintenancePage'))
const ExpirationOfficePage = lazy(() => import('../../pages/errors/ExpirationOfficePage'))
const DepreciationFixedAssetPage = lazy(() => import('../../pages/accounting/DepreciationFixedAssetPage'))
const DepreciationFixedAssetReviewPage = lazy(() => import('../../pages/accounting/DepreciationFixedAssetReviewPage'))
const JournalLinkageHistoryDetailPage = lazy(() => import('../../pages/accounting/JournalLinkageHistoryDetailPage'))
const AgentLoginCallbackPage = lazy(() => import('../../pages/user/AgentLoginCallbackPage'))
const UnderOverDepreciationReportPage = lazy(() => import('../../pages/accounting/UnderOverDepreciationReportPage'))
const CorporateIncomeTaxPage = lazy(() => import('../../pages/accounting/corporate-income-tax'))
const MonthlyClosingIncreaseDecreaseReportPage = () => <IncreaseDecreaseReportPage />
const MonthlyClosingDepreciationResultPage = () => <AmountSummaryPage />
const AssetCorrectionPage = lazy(() => import('../../pages/assets/asset-correction-page'))

// Master
const TagListPage = lazy(() => import('../../pages/master/tag-list'))
const TagNewPage = lazy(() => import('../../pages/master/tag-new'))
const TagEditPage = lazy(() => import('../../pages/master/tag-edit'))
const UnitListPage = lazy(() => import('../../pages/master/unit-list'))
const UnitNewPage = lazy(() => import('../../pages/master/unit-new'))
const UnitEditPage = lazy(() => import('../../pages/master/unit-edit'))

const Routes = () => {
  return (
    <Suspense
      fallback={
        <div>
          <Spin loading size="large" text="Loading ..." />
        </div>
      }
    >
      <Switch>
        {/* Sign In */}
        <Route path="/mfid/callback" exact component={CallbackPage} />
        <RouteWithoutOffice path="/term-of-use" exact>
          <TermOfUsePage />
        </RouteWithoutOffice>
        <Route path="/sso" exact component={SSOPage} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/accounting-periods/create" exact component={AccountingPeriodCreatePage} />
        {/* Agent Login Page */}
        <Route path="/agent-login/callback" exact component={AgentLoginCallbackPage} />

        <PrivateRoute path="/" exact>
          <Redirect to="/assets" />
        </PrivateRoute>

        <PrivateRoute path="/assets" exact require="isRead">
          <AssetListPage />
        </PrivateRoute>
        <PrivateRoute path="/assets/new" exact require="isCreate">
          <AssetNewPage />
        </PrivateRoute>
        <PrivateRoute path="/assets/import" exact require="isCreate">
          <AssetImportPage />
        </PrivateRoute>
        <PrivateRoute path="/assets/import/results" exact require="isCreate">
          <AssetImportResultsPage />
        </PrivateRoute>
        <PrivateRoute path="/assets/:biid" exact require="isRead">
          <AssetDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/assets/:biid/copy" exact require="isCreate">
          <AssetCopyPage />
        </PrivateRoute>
        <PrivateRoute path="/assets/:biid/edit" exact require="isEdit">
          <AssetEditPage />
        </PrivateRoute>

        <PrivateRoute path="/assets/:biid/correction" exact require="isEdit">
          <AssetCorrectionPage />
        </PrivateRoute>

        {/* Internal Control */}
        <PrivateRoute path="/internal-control/operation-log" exact>
          <OperationLogPage />
        </PrivateRoute>

        {/* Office */}
        <RouteWithoutOffice path="/offices/new" exact>
          <OfficeNewPage />
        </RouteWithoutOffice>
        <RouteWithoutOffice path="/offices/select" exact>
          <OfficeSelectPage />
        </RouteWithoutOffice>

        {/* Accounting */}
        <PrivateRoute path="/accounting/monthly-closing" exact>
          <MonthlyClosingPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/settings" exact>
          <MonthlyClosingSettingPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/asset-events" exact>
          <MonthlyClosingAssetEventListPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/journals" exact>
          <JournalListPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/journals/histories/:id/detail" exact>
          <JournalLinkageHistoryDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/journals/histories">
          <JournalHistoryPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/increase-decrease-report" require="isRead" exact>
          <MonthlyClosingIncreaseDecreaseReportPage />
        </PrivateRoute>
        <PrivateRoute path="/accounting/monthly-closing/amount-summary" require="isRead" exact>
          <MonthlyClosingDepreciationResultPage />
        </PrivateRoute>

        {/* Tax declaration */}
        <PrivateRoute path="/tax-declaration/depreciation-fixed-asset" exact>
          <DepreciationFixedAssetPage />
        </PrivateRoute>
        <PrivateRoute path="/tax-declaration/depreciation-fixed-asset/:submit_destination_id/:tax_year" exact>
          <DepreciationFixedAssetReviewPage />
        </PrivateRoute>
        <PrivateRoute path="/tax-declaration/corporate-income-tax" exact>
          <CorporateIncomeTaxPage />
        </PrivateRoute>

        <PrivateRoute path="/tax-declaration/corporate-income-tax/report" exact>
          <CorporateIncomeTaxReportPage />
        </PrivateRoute>
        {/* Master */}
        <PrivateRoute path="/master/asset-categories" exact>
          <AssetCategoryListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/asset-categories/new" exact>
          <AssetCategoryNewPage />
        </PrivateRoute>
        <PrivateRoute path="/master/asset-categories/:id/edit" exact>
          <AssetCategoryEditPage />
        </PrivateRoute>
        <PrivateRoute path="/master/asset-categories/:id/copy" exact>
          <AssetCategoryCopyPage />
        </PrivateRoute>

        <PrivateRoute path="/master/areas" exact>
          <AreaListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/areas/new" exact>
          <AreaNewPage />
        </PrivateRoute>
        <PrivateRoute path="/master/areas/:biid/detail" exact>
          <AreaDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/master/areas/:id/copy" exact>
          <AreaCopyPage />
        </PrivateRoute>

        <PrivateRoute path="/master/submit-destinations" exact>
          <SubmitDestinationListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/submit-destinations/new" exact>
          <SubmitDestinationNewPage />
        </PrivateRoute>
        <PrivateRoute path="/master/submit-destinations/:id/edit" exact>
          <SubmitDestinationEditPage />
        </PrivateRoute>
        <PrivateRoute path="/master/submit-destinations/:id/copy" exact>
          <SubmitDestinationCopyPage />
        </PrivateRoute>
        <PrivateRoute path="/master/submit-destinations/:id" exact>
          <SubmitDestinationDetailPage />
        </PrivateRoute>

        <PrivateRoute path="/master/units" exact>
          <UnitListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/units/new" exact>
          <UnitNewPage />
        </PrivateRoute>
        <PrivateRoute path="/master/units/:id/edit" exact>
          <UnitEditPage />
        </PrivateRoute>

        <PrivateRoute path="/master/tags" exact>
          <TagListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/tags/new" exact>
          <TagNewPage />
        </PrivateRoute>
        <PrivateRoute path="/master/tags/:id/edit" exact>
          <TagEditPage />
        </PrivateRoute>

        <PrivateRoute path="/master/causes" exact>
          <CauseListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/causes/new" exact>
          <CauseNewPage />
        </PrivateRoute>
        <PrivateRoute path="/master/causes/:id/edit" exact>
          <CauseEditPage />
        </PrivateRoute>
        <PrivateRoute path="/master/causes/:id/copy" exact>
          <CauseCopyPage />
        </PrivateRoute>

        <PrivateRoute path="/master/departments/new" exact>
          <DepartmentPage />
        </PrivateRoute>
        <PrivateRoute path="/master/departments" exact>
          <DepartmentListPage />
        </PrivateRoute>
        <PrivateRoute path="/master/departments/:id/copy" exact>
          <DepartmentCopyPage />
        </PrivateRoute>
        <PrivateRoute path="/master/departments/:biid/detail" exact>
          <DepartmentDetailPage />
        </PrivateRoute>

        <PrivateRoute path="/master/excise" exact>
          <ExciseListPage />
        </PrivateRoute>

        <PrivateRoute path="/master/account-items" exact>
          <AccountItemListPage />
        </PrivateRoute>

        {/* Setting */}
        <PrivateRoute path="/setting/office" exact>
          <OfficeShowPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/accounting-periods" exact>
          <AccountingPeriodPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/ledgers" exact>
          <LedgerSettingsViewPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/ledgers/edit" exact>
          <LedgerSettingsEditPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/asset-codes/auto-generate" exact>
          <AssetCodeAutoGenerationPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/asset-codes/start-number" exact require="isCRU">
          <AssetCodeStartNumberPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/auto-journal-settings" exact>
          <AutoJournalSettingPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/users" exact>
          <UserListPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/users/new" exact>
          <UserNewPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/users/:id/edit" exact>
          <UserEditPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/excises">
          <ExciseSettingPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/recalculate">
          <ReCalculatePage />
        </PrivateRoute>
        <PrivateRoute path="/setting/roles" exact>
          <RoleListPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/roles/new" exact>
          <RoleNewPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/roles/:biid" exact>
          <RoleDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/roles/:biid/edit" exact>
          <RoleEditPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/import" exact require="isExecute">
          <SettingImportPage />
        </PrivateRoute>
        <PrivateRoute path="/setting/import/results" exact require="isExecute">
          <SettingImportResultPage />
        </PrivateRoute>

        {/* Report pages */}
        <PrivateRoute path="/depreciation/amount-summary" exact>
          <AmountSummaryPage />
        </PrivateRoute>
        <PrivateRoute path="/depreciation/increase-decrease-report" exact>
          <IncreaseDecreaseReportPage />
        </PrivateRoute>
        <PrivateRoute path="/depreciation/journal-list-report" exact>
          <JournalListReportPage />
        </PrivateRoute>
        <PrivateRoute path="/depreciation/asset-event-list-report" exact>
          <AssetEventListReportPage />
        </PrivateRoute>
        <PrivateRoute path="/depreciation/under-over-depreciation-report" exact>
          <UnderOverDepreciationReportPage />
        </PrivateRoute>

        {/* Errors */}
        <PrivateRoute path="/errors/403" exact component={NoAuthorizationPage} />
        <PrivateRoute path="/errors/404" exact component={NotFoundErrorPage} />
        <PrivateRoute path="/errors/500" exact component={InternalServerErrorPage} />
        <PrivateRoute path="/errors/503" exact component={UnderMaintenancePage} />
        <Route path="/errors/40103" exact component={ExpirationOfficePage} />
        <Redirect to="/errors/404" />
      </Switch>
    </Suspense>
  )
}

export default Routes
