import styled from '@emotion/styled'
import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import React from 'react'

export const TagSelect = ({ name, ...props }: Props) => {
  const { values, setFieldValue } = useFormikContext()

  const onChange = (value: string[]) => {
    setFieldValue(name, value)
  }

  return (
    <StyledWrapper>
      <Select
        mode="multiple"
        allowClear
        filterOption={(input, option) => ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())}
        onChange={onChange}
        value={get(values, name)}
        {...props}
      />
    </StyledWrapper>
  )
}

type Props = {
  name: string
  options: SelectProps<any>['options']
  styles?: React.CSSProperties
  placeholder?: string
}

const StyledWrapper = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
  }
`
