import { css } from '@emotion/css'

export const modalWrapperClass = css`
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-body {
    padding: 15px 15px 13px;
    font-size: 13px;
  }

  .ant-modal-header {
    padding: 16px 15px 16px 44px;
    border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-family: Noto Sans CJK JP;
    font-weight: 500 height 48px;
  }

  .ant-modal-title {
    line-height: 16px;
  }

  .ant-modal-close {
    left: 20px;

    .ant-modal-close-x {
      height: 14px;
      width: 14px;
      line-height: 48px;
    }
  }

  .ant-radio-wrapper {
    margin-right: 20px;

    span {
      padding: 0px;
    }

    .ant-radio-inner {
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }

  .ant-modal-footer {
    border-radius: 0 0 4px 4px;
    padding: 15px;
    height: 60px;

    .ant-btn {
      &.action-button {
        margin-right: 15px;
        margin-left: 0;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`
