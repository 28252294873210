import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export const useFormReady = () => {
  const { values } = useFormikContext()
  const [isFormReady, setIsFormReady] = useState(false)

  useEffect(() => {
    setIsFormReady(!!values)
  }, [values, setIsFormReady])

  return isFormReady
}
