import { SideNavItem } from 'components/organisms/MainMenu/settings'

import { ChangeMenuNavigation } from './action.model'
import { Message } from './model'

export const INIT_APP_START = 'INIT_APP_START'
export const INIT_APP_CHECK_PROGRESS = 'INIT_APP_CHECK_PROGRESS'
export const INIT_APP_FINISH = 'INIT_APP_FINISH'
export const CHANGE_LOCATION = 'CHANGE_LOCATION'
export const REDIRECT_URL = 'REDIRECT_URL'
export const SET_APP_MESSAGE = 'SET_APP_MESSAGE'
export const CLEAR_APP_MESSAGE = 'CLEAR_APP_MESSAGE'

export const SET_MENU_COLLAPSE = 'SET_MENU_COLLAPSE'
export const SET_MENU_OPEN_SUBNAV_KEYS = 'SET_MENU_OPEN_SUBNAV_KEYS'
export const SAVE_OPEN_SUBNAV_KEYS = 'SAVE_OPEN_SUBNAV_KEYS'
export const CHANGE_MENU_NAVIGATION = 'CHANGE_MENU_NAVIGATION'
export const SHOW_IN_USED_MESSAGE = 'SHOW_IN_USED_MESSAGE'
export const HIDE_IN_USED_MESSAGE = 'HIDE_IN_USED_MESSAGE'
export const SET_LOADING_APP = 'SET_LOADING_APP'
export const SHOW_ERRORS_APP_CALL = 'SHOW_ERRORS_APP_CALL'
export const SET_FLAG_DISABLE_NAVIGATION = 'SET_FLAG_DISABLE_NAVIGATION'

export const initAppStart = () => ({
  type: INIT_APP_START,
})

export const changeLocation = (location: string) => ({
  type: CHANGE_LOCATION,
  payload: location,
})

export const redirectUrl = (url: string) => ({
  type: REDIRECT_URL,
  payload: url,
})

export const changeMenuNavigation = (
  location: SideNavItem,
  triggerFinishedFirstLoad = false
): ChangeMenuNavigation => ({
  type: CHANGE_MENU_NAVIGATION,
  payload: {
    location,
    triggerFinishedFirstLoad,
  },
})

export const setMenuCollapse = (collapseState: boolean) => ({
  type: SET_MENU_COLLAPSE,
  payload: collapseState,
})

export const setMenuOpenSubnavKeys = (key?: string[]) => ({
  type: SET_MENU_OPEN_SUBNAV_KEYS,
  payload: key,
})

export const savedMenuOpenSubnavKeys = (key?: string[]) => ({
  type: SAVE_OPEN_SUBNAV_KEYS,
  payload: key,
})

export const setAppMessage = (message: Message, meta?: any, error?: boolean) => {
  if (!meta)
    return {
      type: SET_APP_MESSAGE,
      payload: message,
    }
  return {
    type: SET_APP_MESSAGE,
    payload: message,
    meta,
    error,
  }
}

export const clearAppMessage = () => ({
  type: CLEAR_APP_MESSAGE,
})

export const hideInUsedMessage = () => ({
  type: HIDE_IN_USED_MESSAGE,
})

export const setLoadingApp = (payload: boolean) => ({
  type: SET_LOADING_APP,
  payload,
})

export const showErrorsAppCall = (payload: any, meta?: any) => ({
  type: SHOW_ERRORS_APP_CALL,
  payload,
  meta,
})

export const setFlagDisableNavigation = (payload: boolean) => ({
  type: SET_FLAG_DISABLE_NAVIGATION,
  payload,
})
