import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants/masterData'
import { formatDateTime, identifyJournalStatusType, objectToQueryParams } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { AssetReport } from 'common/models'
import { NoImage } from 'components/atoms'
import { get, isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanupMonthlyClosingAssetEvents,
  fetchMonthlyClosingPreviousAssetEvents,
} from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingPreviousAssetEvents } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'
import { Media } from './media'

type Props = {
  assets: AssetReport[]
}

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const MovementEventView: React.FC<Props> = ({ assets }) => {
  const dispatch = useDispatch()
  const previousAssetEvents = useSelector(selectMonthlyClosingPreviousAssetEvents, isEqual)

  const previousAssetEventsMap = useMemo(() => {
    if (!previousAssetEvents || previousAssetEvents.length === 0) return null

    const result: any = {}
    previousAssetEvents.forEach(item => {
      result[item.id as number] = get(item, 'previous')
    })

    return result
  }, [previousAssetEvents])

  const renderAssetPhoto = useCallback((asset: AssetReport) => {
    const isEmpty = !asset.photos || asset.photos.length === 0

    return isEmpty ? <NoImage /> : <img src={get(asset, 'photos[0].thumbnail_url')} alt="" />
  }, [])

  const handleAssetClick = (asset: AssetReport) => {
    const paramObj = {
      valid_at: asset.valid_from,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid}${params}`))
  }

  useEffect(() => {
    if (assets && assets.length > 0) {
      // get all movement events of each asset;
      const assetIds: number[] = []

      assets.forEach(asset => {
        const movementEvents = asset.change_situations?.filter(
          item => item.change_situation.code === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT
        )
        movementEvents?.forEach(item => {
          assetIds.push(item.id as number)
        })
      })

      assetIds.length > 0 && dispatch(fetchMonthlyClosingPreviousAssetEvents(assetIds))
    }
  }, [assets, dispatch])

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, []) // eslint-disable-line

  return (
    <>
      {assets?.map(asset => (
        <AssetDisplay key={asset?.biid} onClick={() => handleAssetClick(asset)}>
          <AssetDisplayHeader>
            <div className="status">
              <JournalStatus type={identifyJournalStatusType(asset)} />
            </div>
            <div className="photo">{renderAssetPhoto(asset)}</div>
            <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
            <div className="asset-name">{asset.name}</div>
          </AssetDisplayHeader>
          {asset?.change_situations?.map((asset, index) => (
            <div key={asset?.code}>
              <AssetDisplayDataRow className={index === 0 ? '' : 'border-top'}>
                <div className="short-label">{asset.valid_from}</div>
                <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
                <div className="label text-color-666">{t('move_at')}</div>
                <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('area')}</div>
                <div className="value">
                  {get(previousAssetEventsMap, `[${asset.id}].area.name`)}
                  <label className="arrow">→</label>
                  {get(asset.area, 'name')}
                </div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('submit_destination')}</div>
                <div className="value">
                  {get(previousAssetEventsMap, `[${asset.id}].area.submit_destination.name`)}
                  <label className="arrow">→</label>
                  {get(asset, 'area.submit_destination.name')}
                </div>
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t('department')}</div>
                <div className="value">
                  {get(previousAssetEventsMap, `[${asset.id}].department.name`)}
                  <label className="arrow">→</label>
                  {get(asset.department, 'name')}
                </div>
              </AssetDisplayDataRow>
              <Media asset={asset} />
              <AssetDisplayDataRow className="last">
                <div className="label">{t('move_by')}</div>
                <div className="value">{get(asset.user_info, 'display_name')}</div>
              </AssetDisplayDataRow>
            </div>
          ))}
        </AssetDisplay>
      ))}
    </>
  )
}
