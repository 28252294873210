import styled from '@emotion/styled'

export const Wrapper = styled.div`
  font-size: 13px;
  width: 100%;
  position: relative;
`

export const Header = styled.div`
  background-color: #e7f1fd;
  box-sizing: border-box;
  border: 1px solid #d4d8dd;
  color: #333333;
  padding: 6px 8px;
  border-radius: 4px 4px 0px 0px;
`

export const Group = styled.div``

export const ItemWrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
  border-left: 1px solid #d4d8dd;
  border-right: 1px solid #d4d8dd;
  box-sizing: border-box;
  color: #333333;
  display: flex;
  min-height: 48px;
  padding-top: 10px;
  padding-bottom: 10px;

  &.last {
    border-radius: 0px 0px 4px 4px;
  }
`

export const ItemLabel = styled.div`
  font-weight: bold;
  padding-left: 10px;
  width: 200px;
`

export const ItemContent = styled.div`
  position: relative;

  .required {
    left: -40px;
    position: absolute;
    top: 3px;
  }
`

export const ItemContentView = styled.div`
  width: 690px;
  white-space: pre-line;
`

export const Footer = styled.div`
  margin-top: 15px;
  text-align: center;
`

export const DividerWrapper = styled.div`
  background-color: #eff1f4;
  border-bottom: 1px solid #d4d8dd;
  border-left: 1px solid #d4d8dd;
  border-right: 1px solid #d4d8dd;
  color: #7c8291;
  padding: 4px 9px;
`
