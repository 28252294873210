/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterPhotoInformation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Photos<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Upload photo
   *
   * @tags photos
   * @name UploadCreate
   * @summary Upload the photo
   * @request POST:/photos/upload
   */
  uploadCreate = (
    data: {
      /** photo file */
      file: File
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterPhotoInformation, any>({
      path: `/photos/upload`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
