/* eslint jsx-a11y/anchor-is-valid: 0 */
import { PlusOutlined } from '@ant-design/icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Divider, Select } from 'antd'
import classNames from 'classnames'
import { get } from 'lodash'
import uniqueId from 'lodash/uniqueId'
import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'

import { AutoCompleteOptionTooltip } from './auto-complete-option-tooltip'
import { AutoCompleteSelectProps } from './schema'
import { AddItemWrapper, CoreSelectClass, WrapperList } from './styles'

const { Option } = Select

export const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({
  name,
  defaultValue,
  control,
  error,
  value,
  showSearch = true,
  valueField,
  textField,
  placeholder,
  mode,
  isShowError = true,
  items = [],
  allowClear = true,
  disabled,
  className,
  style,
  onChange,
  onClickNew,
  customDropdownTemplate,
  required,
}) => {
  const hasClickNew = !!(onClickNew && typeof onClickNew === 'function')
  const refContainer = useRef(null)

  if (control) {
    const isInvalid = !!error

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ value, onChange: controllerOnChange, ...rest }) => {
          return (
            <div ref={refContainer} className="auto-complete-select-wrapper" style={style}>
              <Select
                className={classNames(className, CoreSelectClass, {
                  error: isInvalid,
                })}
                dropdownClassName={classNames(`select-dropdown-${name}`, WrapperList)}
                placeholder={placeholder}
                allowClear={allowClear}
                mode={mode}
                disabled={disabled}
                value={value}
                showSearch={showSearch}
                filterOption={(input, option) =>
                  `${option?.children?.props.title || ''}`.toLowerCase().includes(`${input}`.toLowerCase())
                }
                onChange={value => {
                  controllerOnChange(!value ? null : value)
                  onChange && onChange(!value ? null : value)
                }}
                dropdownRender={menu => menuDropDownRender(menu, hasClickNew, customDropdownTemplate, onClickNew)}
                suffixIcon={<FontAwesomeIcon style={{ color: '#7C8291' }} icon={faCaretDown} />}
                showArrow
                getPopupContainer={() => refContainer.current as any}
                {...rest}
              >
                {items?.map(item => (
                  <Option
                    disabled={item?.disabled}
                    key={`select-option-${name}-${item?.id || item?.code || uniqueId()}`}
                    value={get(item, valueField, '')}
                  >
                    <AutoCompleteOptionTooltip title={get(item, textField, 'Text field wrong')} />
                  </Option>
                ))}
              </Select>
              {isInvalid && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
            </div>
          )
        }}
      />
    )
  }

  // *** Regular AutoCompleteSelect ***
  return (
    <div ref={refContainer} className="auto-complete-select-wrapper" style={style}>
      <Select
        className={classNames(className, CoreSelectClass)}
        value={value}
        dropdownClassName={classNames(`select-dropdown-${name}`, WrapperList)}
        placeholder={placeholder}
        allowClear={allowClear}
        defaultValue={defaultValue}
        disabled={disabled}
        mode={mode}
        showSearch={showSearch}
        filterOption={(input, option) =>
          `${option?.children?.props.title || ''}`.toLowerCase().includes(`${input}`.toLowerCase())
        }
        onChange={onChange}
        dropdownRender={menu => menuDropDownRender(menu, hasClickNew, customDropdownTemplate, onClickNew)}
        suffixIcon={<FontAwesomeIcon style={{ color: '#7C8291' }} icon={faCaretDown} />}
        showArrow
        getPopupContainer={() => refContainer.current as any}
      >
        {items?.map(item => (
          <Option
            disabled={item?.disabled}
            key={`select-option-${name}-${item?.id || item?.code || uniqueId()}`}
            value={get(item, valueField, '')}
          >
            <AutoCompleteOptionTooltip title={get(item, textField, 'Text field wrong')} />
          </Option>
        ))}
      </Select>
    </div>
  )
}

const menuDropDownRender = (menu: any, hasClickNew: any, customDropdownTemplate: any, onClickNew?: () => void) => {
  return (
    <div>
      {menu}
      {(hasClickNew || customDropdownTemplate) && <Divider style={{ margin: '4px 0' }} />}
      {customDropdownTemplate}
      {hasClickNew && (
        <AddItemWrapper>
          <a onClick={onClickNew}>
            <PlusOutlined /> Add New
          </a>
        </AddItemWrapper>
      )}
    </div>
  )
}
