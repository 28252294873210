import { Spin } from 'aa_common/front-end/antd'
import { Pagination } from 'components/molecules'
import React from 'react'

import { ContentWrapper, DepreciationFixedAssetWrapper, PaginationWrapper } from './styles'

interface Props {
  isPagination?: boolean
  loading?: boolean
  content: any
  onHandlePageChange?: (pageNumber: number) => void
  currentPage?: number
  totalPages?: number
  totalRecords?: number
}

const DepreciationFixedAssetReview = ({
  isPagination = false,
  content,
  loading,
  onHandlePageChange,
  currentPage = 1,
  totalPages = 0,
  totalRecords = 0,
}: Props) => {
  return (
    <Spin loading={loading}>
      <DepreciationFixedAssetWrapper>
        <ContentWrapper className={!isPagination ? 'non-pagination-wrapper' : ''}>{content}</ContentWrapper>
        {isPagination && (
          <PaginationWrapper>
            <Pagination
              name="depreciation_fixed_asset_review"
              value={currentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              isNoInfo
              onPageChanged={onHandlePageChange}
            />
          </PaginationWrapper>
        )}
      </DepreciationFixedAssetWrapper>
    </Spin>
  )
}

export default DepreciationFixedAssetReview
