import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { checkIsAfterDate } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { MoneyInput } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import React from 'react'

import { getAllLedgerFieldNames } from '../helper'
import { useTransitionBookValues } from '../hooks/useTransitionBookValues'
import { prelineStyle } from '../styles'
import DepreciatedMonthsInput from './DepreciatedMonthsInput'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type TransitionBookValuesProps = {
  usageStartedAt?: string
  bookValueMax?: number
  ledgerIndex: number
  ledgerName: string
  disabled: boolean
  isCopied: boolean
  isTransitionDayIsFirstDayOfTerm: boolean
  isImportMode: boolean
}

const shouldRender = (hasTransition: boolean, depreciationCode: any) => {
  return (
    hasTransition &&
    ![
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
    ].includes(depreciationCode)
  )
}

export const TransitionBookValues = ({
  usageStartedAt = '',
  bookValueMax,
  ledgerIndex,
  ledgerName,
  disabled,
  isCopied,
  isTransitionDayIsFirstDayOfTerm,
  isImportMode,
}: TransitionBookValuesProps) => {
  const { values, setFieldValue } = useFormikContext()
  const {
    depreciationMethodCodeField,
    transitionAtField,
    transitionBeginningBookValueField,
    transitionBookValueField,
    bookValueAtChangeField,
    depreciatedMonthsField,
    revisedAcquisitionAmountField,
    usagePeriodMonthsField,
    serviceLifeField,
  } = getAllLedgerFieldNames(ledgerIndex)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const transitionAt = getIn(values, transitionAtField)
  const transitionBeginningBookValue = getIn(values, transitionBeginningBookValueField)
  const bookValueAtChange = getIn(values, bookValueAtChangeField)

  const shouldRenderReviseAcquisitionAmount = [
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200,
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250,
  ].includes(depreciationCode)

  let beginBookValueMax = bookValueMax
  if (!Number.isNaN(parseInt(String(bookValueAtChange), 10))) {
    beginBookValueMax = bookValueAtChange
  }
  useTransitionBookValues(usageStartedAt, ledgerIndex, isImportMode, isTransitionDayIsFirstDayOfTerm, beginBookValueMax)

  if (!shouldRender(!!transitionAt, depreciationCode)) {
    return null
  }

  const isUsageStartAtBeforeTransitionAt = checkIsAfterDate(transitionAt, usageStartedAt)

  return (
    <>
      <If condition={isUsageStartAtBeforeTransitionAt}>
        <Item
          label={
            <ItemLabelTooltip
              label={t('transition_beginning_book_value')}
              tooltip={parser(t('transition_beginning_book_value_tooltip'))}
            />
          }
          style={prelineStyle}
          required
          jsLabel={t('transition_beginning_book_value_of', { name: ledgerName })}
        >
          <MoneyInput
            name={transitionBeginningBookValueField}
            disabled={disabled || isCopied}
            maxValue={beginBookValueMax}
          />
        </Item>
      </If>
      <If condition={!isTransitionDayIsFirstDayOfTerm && isUsageStartAtBeforeTransitionAt}>
        <Item
          label={
            <ItemLabelTooltip label={t('transition_book_value')} tooltip={parser(t('transition_book_value_tooltip'))} />
          }
          style={prelineStyle}
          required
          jsLabel={t('transition_book_value_of', { name: ledgerName })}
        >
          <MoneyInput
            name={transitionBookValueField}
            disabled={disabled || isCopied}
            maxValue={transitionBeginningBookValue}
            onChange={value =>
              (transitionBeginningBookValue || transitionBeginningBookValue === 0) &&
              value &&
              value > transitionBeginningBookValue &&
              setFieldValue(transitionBookValueField, transitionBeginningBookValue)
            }
          />
        </Item>
      </If>
      <If condition={shouldRenderReviseAcquisitionAmount}>
        <Item
          label={
            <ItemLabelTooltip
              label={t('revised_acquisition_amount')}
              tooltip={parser(t('revised_acquisition_amount_tooltip'))}
              tooltipMaxWidth={600}
            />
          }
          required
          jsLabel={t('revised_acquisition_amount_of', { name: ledgerName })}
        >
          <MoneyInput name={revisedAcquisitionAmountField} disabled={disabled || isCopied} />
        </Item>
      </If>
      <DepreciatedMonthsInput
        name={depreciatedMonthsField}
        dMethodCode={depreciationCode}
        usagePeriodMonthsField={usagePeriodMonthsField}
        serviceLifeField={serviceLifeField}
        usageStartedAt={usageStartedAt}
        transitionAt={transitionAt}
        ledgerName={ledgerName}
        disabled={disabled}
        isCopied={isCopied}
      />
    </>
  )
}
