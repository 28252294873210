import get from 'lodash/get'
import React from 'react'

import { AssetInformation } from './asset-information'
import svgBg from './bg.svg'

const Form = ({ isAllFixedAssetMode, ...props }: any) => {
  return (
    <svg viewBox="0 0 842 596" fill="none" xmlns="http://www.w3.org/2000/svg">
      <image x="0" y="0" width="100%" href={svgBg} />
      <g id="$shinkokusho_04_a.pdf">
        <g id="total">
          <g id="[3]">
            <foreignObject x="461" y="542" width="22" height="17">
              <div data-id="total[3]">{get(props, 'total[3]')}</div>
            </foreignObject>
          </g>
          <g id="[2]">
            <foreignObject x="438" y="542" width="22" height="17">
              <div data-id="total[2]">{get(props, 'total[2]')}</div>
            </foreignObject>
          </g>
          <g id="[1]">
            <foreignObject x="415" y="542" width="22" height="17">
              <div data-id="total[1]">{get(props, 'total[1]')}</div>
            </foreignObject>
          </g>
          <g id="[0]">
            <foreignObject x="392" y="542" width="22" height="17">
              <div data-id="total[0]">{get(props, 'total[0]')}</div>
            </foreignObject>
          </g>
        </g>
        <g id="sub_total">
          <g id="[3]_2">
            <foreignObject x="461" y="523" width="22" height="17">
              <div data-id="sub_total[3]">{get(props, 'sub_total[3]')}</div>
            </foreignObject>
          </g>
          <g id="[2]_2">
            <foreignObject x="438" y="523" width="22" height="17">
              <div data-id="sub_total[2]">{get(props, 'sub_total[2]')}</div>
            </foreignObject>
          </g>
          <g id="[1]_2">
            <foreignObject x="415" y="523" width="22" height="17">
              <div data-id="sub_total[1]">{get(props, 'sub_total[1]')}</div>
            </foreignObject>
          </g>
          <g id="[0]_2">
            <foreignObject x="392" y="523" width="22" height="17">
              <div data-id="sub_total[0]">{get(props, 'sub_total[0]')}</div>
            </foreignObject>
          </g>
        </g>
        <g id="fixed_assets">
          <AssetInformation assets={props?.fixed_assets} isAllFixedAssetMode={isAllFixedAssetMode} />
        </g>
        <g id="page">
          <foreignObject x="760" y="26" width="30" height="12">
            <div data-id="page">{get(props, 'page')}</div>
          </foreignObject>
        </g>
        <g id="total_page">
          <foreignObject x="694" y="26" width="30" height="12">
            <div data-id="total_page">{get(props, 'total_page')}</div>
          </foreignObject>
        </g>
        <g id="mode">
          {isAllFixedAssetMode || get(props, 'mode') === 1 ? (
            <path
              d="M538.5 50C538.5 51.5929 537.607 53.1763 535.846 54.6799C534.087 56.182 531.512 57.5573 528.286 58.7221C521.839 61.0503 512.899 62.5 503 62.5C493.101 62.5 484.161 61.0503 477.714 58.7221C474.488 57.5573 471.913 56.182 470.154 54.6799C468.393 53.1763 467.5 51.5929 467.5 50C467.5 48.4071 468.393 46.8237 470.154 45.3201C471.913 43.818 474.488 42.4427 477.714 41.2779C484.161 38.9497 493.101 37.5 503 37.5C512.899 37.5 521.839 38.9497 528.286 41.2779C531.512 42.4427 534.087 43.818 535.846 45.3201C537.607 46.8237 538.5 48.4071 538.5 50Z"
              stroke="black"
            />
          ) : (
            <path
              d="M464.5 50C464.5 51.5929 463.607 53.1763 461.846 54.6799C460.087 56.182 457.512 57.5573 454.286 58.7221C447.839 61.0503 438.899 62.5 429 62.5C419.101 62.5 410.161 61.0503 403.714 58.7221C400.488 57.5573 397.913 56.182 396.154 54.6799C394.393 53.1763 393.5 51.5929 393.5 50C393.5 48.4071 394.393 46.8237 396.154 45.3201C397.913 43.818 400.488 42.4427 403.714 41.2779C410.161 38.9497 419.101 37.5 429 37.5C438.899 37.5 447.839 38.9497 454.286 41.2779C457.512 42.4427 460.087 43.818 461.846 45.3201C463.607 46.8237 464.5 48.4071 464.5 50Z"
              stroke="black"
            />
          )}
        </g>
        <g id="year_21">
          <foreignObject x="370" y="24" width="67" height="14.9472">
            <div data-id="year">{get(props, 'year')}</div>
          </foreignObject>
        </g>
        <g id="submit_destination.">
          <g id="company_">
            <g id="name_21">
              <foreignObject x="32" y="39" width="203" height="24">
                <div data-id="submit_destination.company_name">{get(props, 'submit_destination.company_name')}</div>
              </foreignObject>
            </g>
          </g>
        </g>
        <g id="circle-section">
          <g id="row_1">
            {get(props, 'fixed_assets[0].cause') === 4 && <circle cx="775.5" cy="109" r="4" stroke="black" />}
            {get(props, 'fixed_assets[0].cause') === 3 && <circle cx="764" cy="109" r="4" stroke="black" />}
            {get(props, 'fixed_assets[0].cause') === 2 && <circle cx="775.5" cy="100.5" r="4" stroke="black" />}
            {get(props, 'fixed_assets[0].cause') === 1 && <circle cx="764" cy="100.5" r="4" stroke="black" />}
          </g>
          <g id="row_2">
            {get(props, 'fixed_assets[1].cause') === 4 && <circle cx="775.5" cy="130.421" r="4" stroke="black" />}
            {get(props, 'fixed_assets[1].cause') === 3 && <circle cx="764" cy="130.421" r="4" stroke="black" />}
            {get(props, 'fixed_assets[1].cause') === 2 && <circle cx="775.5" cy="121.921" r="4" stroke="black" />}
            {get(props, 'fixed_assets[1].cause') === 1 && <circle cx="764" cy="121.921" r="4" stroke="black" />}
          </g>
          <g id="row_3">
            {get(props, 'fixed_assets[2].cause') === 4 && <circle cx="775.5" cy="151.842" r="4" stroke="black" />}
            {get(props, 'fixed_assets[2].cause') === 3 && <circle cx="764" cy="151.842" r="4" stroke="black" />}
            {get(props, 'fixed_assets[2].cause') === 2 && <circle cx="775.5" cy="143.342" r="4" stroke="black" />}
            {get(props, 'fixed_assets[2].cause') === 1 && <circle cx="764" cy="143.342" r="4" stroke="black" />}
          </g>
          <g id="row_4">
            {get(props, 'fixed_assets[3].cause') === 4 && <circle cx="775.5" cy="173.263" r="4" stroke="black" />}
            {get(props, 'fixed_assets[3].cause') === 3 && <circle cx="764" cy="173.263" r="4" stroke="black" />}
            {get(props, 'fixed_assets[3].cause') === 2 && <circle cx="775.5" cy="164.763" r="4" stroke="black" />}
            {get(props, 'fixed_assets[3].cause') === 1 && <circle cx="764" cy="164.763" r="4" stroke="black" />}
          </g>
          <g id="row_5">
            {get(props, 'fixed_assets[4].cause') === 4 && <circle cx="775.5" cy="194.684" r="4" stroke="black" />}
            {get(props, 'fixed_assets[4].cause') === 3 && <circle cx="764" cy="194.684" r="4" stroke="black" />}
            {get(props, 'fixed_assets[4].cause') === 2 && <circle cx="775.5" cy="186.184" r="4" stroke="black" />}
            {get(props, 'fixed_assets[4].cause') === 1 && <circle cx="764" cy="186.184" r="4" stroke="black" />}
          </g>
          <g id="row_6">
            {get(props, 'fixed_assets[5].cause') === 4 && <circle cx="775.5" cy="216.105" r="4" stroke="black" />}
            {get(props, 'fixed_assets[5].cause') === 3 && <circle cx="764" cy="216.105" r="4" stroke="black" />}
            {get(props, 'fixed_assets[5].cause') === 2 && <circle cx="775.5" cy="207.605" r="4" stroke="black" />}
            {get(props, 'fixed_assets[5].cause') === 1 && <circle cx="764" cy="207.605" r="4" stroke="black" />}
          </g>
          <g id="row_7">
            {get(props, 'fixed_assets[6].cause') === 4 && <circle cx="775.5" cy="237.526" r="4" stroke="black" />}
            {get(props, 'fixed_assets[6].cause') === 3 && <circle cx="764" cy="237.526" r="4" stroke="black" />}
            {get(props, 'fixed_assets[6].cause') === 2 && <circle cx="775.5" cy="229.026" r="4" stroke="black" />}
            {get(props, 'fixed_assets[6].cause') === 1 && <circle cx="764" cy="229.026" r="4" stroke="black" />}
          </g>
          <g id="row_8">
            {get(props, 'fixed_assets[7].cause') === 4 && <circle cx="775.5" cy="258.947" r="4" stroke="black" />}
            {get(props, 'fixed_assets[7].cause') === 3 && <circle cx="764" cy="258.947" r="4" stroke="black" />}
            {get(props, 'fixed_assets[7].cause') === 2 && <circle cx="775.5" cy="250.447" r="4" stroke="black" />}
            {get(props, 'fixed_assets[7].cause') === 1 && <circle cx="764" cy="250.447" r="4" stroke="black" />}
          </g>
          <g id="row_9">
            {get(props, 'fixed_assets[8].cause') === 4 && <circle cx="775.5" cy="280.368" r="4" stroke="black" />}
            {get(props, 'fixed_assets[8].cause') === 3 && <circle cx="764" cy="280.368" r="4" stroke="black" />}
            {get(props, 'fixed_assets[8].cause') === 2 && <circle cx="775.5" cy="271.868" r="4" stroke="black" />}
            {get(props, 'fixed_assets[8].cause') === 1 && <circle cx="764" cy="271.868" r="4" stroke="black" />}
          </g>
          <g id="row_10">
            {get(props, 'fixed_assets[9].cause') === 4 && <circle cx="775.5" cy="301.789" r="4" stroke="black" />}
            {get(props, 'fixed_assets[9].cause') === 3 && <circle cx="764" cy="301.789" r="4" stroke="black" />}
            {get(props, 'fixed_assets[9].cause') === 2 && <circle cx="775.5" cy="293.289" r="4" stroke="black" />}
            {get(props, 'fixed_assets[9].cause') === 1 && <circle cx="764" cy="293.289" r="4" stroke="black" />}
          </g>
          <g id="row_11">
            {get(props, 'fixed_assets[10].cause') === 4 && <circle cx="775.5" cy="323.211" r="4" stroke="black" />}
            {get(props, 'fixed_assets[10].cause') === 3 && <circle cx="764" cy="323.211" r="4" stroke="black" />}
            {get(props, 'fixed_assets[10].cause') === 2 && <circle cx="775.5" cy="314.711" r="4" stroke="black" />}
            {get(props, 'fixed_assets[10].cause') === 1 && <circle cx="764" cy="314.711" r="4" stroke="black" />}
          </g>
          <g id="row_12">
            {get(props, 'fixed_assets[11].cause') === 4 && <circle cx="775.5" cy="344.632" r="4" stroke="black" />}
            {get(props, 'fixed_assets[11].cause') === 3 && <circle cx="764" cy="344.632" r="4" stroke="black" />}
            {get(props, 'fixed_assets[11].cause') === 2 && <circle cx="775.5" cy="336.132" r="4" stroke="black" />}
            {get(props, 'fixed_assets[11].cause') === 1 && <circle cx="764" cy="336.132" r="4" stroke="black" />}
          </g>
          <g id="row_13">
            {get(props, 'fixed_assets[12].cause') === 4 && <circle cx="775.5" cy="366.053" r="4" stroke="black" />}
            {get(props, 'fixed_assets[12].cause') === 3 && <circle cx="764" cy="366.053" r="4" stroke="black" />}
            {get(props, 'fixed_assets[12].cause') === 2 && <circle cx="775.5" cy="357.553" r="4" stroke="black" />}
            {get(props, 'fixed_assets[12].cause') === 1 && <circle cx="764" cy="357.553" r="4" stroke="black" />}
          </g>
          <g id="row_14">
            {get(props, 'fixed_assets[13].cause') === 4 && <circle cx="775.5" cy="387.474" r="4" stroke="black" />}
            {get(props, 'fixed_assets[13].cause') === 3 && <circle cx="764" cy="387.474" r="4" stroke="black" />}
            {get(props, 'fixed_assets[13].cause') === 2 && <circle cx="775.5" cy="378.974" r="4" stroke="black" />}
            {get(props, 'fixed_assets[13].cause') === 1 && <circle cx="764" cy="378.974" r="4" stroke="black" />}
          </g>
          <g id="row_15">
            {get(props, 'fixed_assets[14].cause') === 4 && <circle cx="775.5" cy="408.895" r="4" stroke="black" />}
            {get(props, 'fixed_assets[14].cause') === 3 && <circle cx="764" cy="408.895" r="4" stroke="black" />}
            {get(props, 'fixed_assets[14].cause') === 2 && <circle cx="775.5" cy="400.395" r="4" stroke="black" />}
            {get(props, 'fixed_assets[14].cause') === 1 && <circle cx="764" cy="400.395" r="4" stroke="black" />}
          </g>
          <g id="row_16">
            {get(props, 'fixed_assets[15].cause') === 4 && <circle cx="775.5" cy="430.316" r="4" stroke="black" />}
            {get(props, 'fixed_assets[15].cause') === 3 && <circle cx="764" cy="430.316" r="4" stroke="black" />}
            {get(props, 'fixed_assets[15].cause') === 2 && <circle cx="775.5" cy="421.816" r="4" stroke="black" />}
            {get(props, 'fixed_assets[15].cause') === 1 && <circle cx="764" cy="421.816" r="4" stroke="black" />}
          </g>
          <g id="row_17">
            {get(props, 'fixed_assets[16].cause') === 4 && <circle cx="775.5" cy="451.737" r="4" stroke="black" />}
            {get(props, 'fixed_assets[16].cause') === 3 && <circle cx="764" cy="451.737" r="4" stroke="black" />}
            {get(props, 'fixed_assets[16].cause') === 2 && <circle cx="775.5" cy="443.237" r="4" stroke="black" />}
            {get(props, 'fixed_assets[16].cause') === 1 && <circle cx="764" cy="443.237" r="4" stroke="black" />}
          </g>
          <g id="row_18">
            {get(props, 'fixed_assets[17].cause') === 4 && <circle cx="775.5" cy="473.158" r="4" stroke="black" />}
            {get(props, 'fixed_assets[17].cause') === 3 && <circle cx="764" cy="473.158" r="4" stroke="black" />}
            {get(props, 'fixed_assets[17].cause') === 2 && <circle cx="775.5" cy="464.658" r="4" stroke="black" />}
            {get(props, 'fixed_assets[17].cause') === 1 && <circle cx="764" cy="464.658" r="4" stroke="black" />}
          </g>
          <g id="row_19">
            {get(props, 'fixed_assets[18].cause') === 4 && <circle cx="775.5" cy="494.579" r="4" stroke="black" />}
            {get(props, 'fixed_assets[18].cause') === 3 && <circle cx="764" cy="494.579" r="4" stroke="black" />}
            {get(props, 'fixed_assets[18].cause') === 2 && <circle cx="775.5" cy="486.079" r="4" stroke="black" />}
            {get(props, 'fixed_assets[18].cause') === 1 && <circle cx="764" cy="486.079" r="4" stroke="black" />}
          </g>
          <g id="row_20">
            {get(props, 'fixed_assets[19].cause') === 4 && <circle cx="775.5" cy="516" r="4" stroke="black" />}
            {get(props, 'fixed_assets[19].cause') === 3 && <circle cx="764" cy="516" r="4" stroke="black" />}
            {get(props, 'fixed_assets[19].cause') === 2 && <circle cx="775.5" cy="507.5" r="4" stroke="black" />}
            {get(props, 'fixed_assets[19].cause') === 1 && <circle cx="764" cy="507.5" r="4" stroke="black" />}
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Form
