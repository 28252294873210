import get from 'lodash/get'
import padStart from 'lodash/padStart'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { selectAssetCodeAutoGenerateSettingListData, selectTermYearSelected } from 'store/settings/assetCode/selectors'

type Rule = {
  field: number
  value: number | string
}

const ASSET_CODE_FORMAT_RULE = {
  RULE_1: 1,
  RULE_2: 2,
  RULE_3: 3,
  RULE_4: 4,
}

const LIST_RULE_FORMAT = {
  [ASSET_CODE_FORMAT_RULE.RULE_2]: {
    1: { format: 'YYYYMMDD' },
    2: { format: 'YYYYMM' },
    3: { format: 'YYYY' },
  },
  [ASSET_CODE_FORMAT_RULE.RULE_3]: {
    1: { value: '-' },
    2: { value: '_' },
    3: { value: '.' },
  },
} as any

export const useAssetCodePreview = () => {
  const terms = useSelector(selectTermsData)
  const termYearSelected = useSelector(selectTermYearSelected)
  const codeAutoGenerateSettingList = useSelector(selectAssetCodeAutoGenerateSettingListData)

  const rules = useMemo(() => {
    const term = terms?.find(term => term.id === termYearSelected)
    return codeAutoGenerateSettingList?.find(({ from_term, to_term }) => {
      return (
        term &&
        from_term &&
        term?.start_date >= from_term?.start_date &&
        (!to_term?.end_date || term?.end_date <= to_term?.end_date)
      )
    })?.rules
  }, [terms, codeAutoGenerateSettingList, termYearSelected])

  const isIncludeAcquisitionDate = rules?.some(rule => rule.field === ASSET_CODE_FORMAT_RULE.RULE_2)

  const getAssetCodePreview = (assetCategory: any, lengthRuleNumber: number) => {
    const suffix = `${padStart(get(assetCategory, 'initial_value').toString(), lengthRuleNumber, '0')}-001`

    const cases = {
      [ASSET_CODE_FORMAT_RULE.RULE_1]: `${assetCategory.asset_category_code}`,
      [ASSET_CODE_FORMAT_RULE.RULE_2]: (rule: Rule) => {
        const formatType = LIST_RULE_FORMAT[rule.field][`${rule.value}`].format
        return `${moment().format(formatType)}`
      },
      [ASSET_CODE_FORMAT_RULE.RULE_3]: (rule: Rule) => LIST_RULE_FORMAT[rule.field][`${rule.value}`].value,
      [ASSET_CODE_FORMAT_RULE.RULE_4]: (rule: Rule) => rule.value,
    } as any

    const result = rules?.map(rule => {
      const eachCase = cases[rule.field]
      return typeof eachCase === 'function' ? eachCase(rule) : eachCase
    })

    // eslint-disable-next-line no-unsafe-optional-chaining
    return result?.join('') + suffix
  }

  return { getAssetCodePreview, isIncludeAcquisitionDate }
}
