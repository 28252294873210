import { initAsyncSlice } from 'aa_common/front-end/zustand'
import produce from 'immer'

export enum EventKeys {
  Acquisition = 'acquisition',
  Retirement = 'retirement',
  Sale = 'sale',
  Movement = 'movement',
  Impairment = 'impairment',
  Dividing = 'dividing',
}

type State = {
  isDetailMode: boolean
  selectedItem: { [key: string]: boolean }
  idMarkAlls: string[]
  markAll: boolean
  selectAllOnePage: boolean
  bulkChangeEvent: null
  error: null | string
  errorIds: string[]
  termMonthId: number | null
}

type SelectedItem = {
  id: string | number
  isSelected: boolean
}

const initState: State = {
  isDetailMode: true,
  selectedItem: {},
  idMarkAlls: [],
  markAll: false,
  selectAllOnePage: false,
  bulkChangeEvent: null,
  error: null,
  errorIds: [],
  termMonthId: null,
}

export const assetEventListReportSlice = initAsyncSlice('ATR/bulk-change', initState) // ATR = Acquisition transfer report

export const setDetailMode = (isDetailMode: boolean) => {
  assetEventListReportSlice.setData((baseState: State) =>
    produce(baseState, (draftState: State) => {
      draftState.isDetailMode = isDetailMode
    })
  )
}

export const setSelectRows = (items: SelectedItem[]) => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      items.forEach((item: SelectedItem) => {
        draft.selectedItem[item.id] = item.isSelected
      })
    })
  )
}
export const markAllRows = (status: boolean) => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      draft.markAll = status
    })
  )
}

export const setErrorIds = (errorIds: string[]) => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      draft.errorIds = errorIds
    })
  )
}

export const setError = (error: string | null) => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      draft.error = error
    })
  )
}

export const setTermMonthId = (termMonthId: number | null) => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      draft.termMonthId = termMonthId
      draft.errorIds = []
      draft.error = null
    })
  )
}

export const resetStore = () => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      Object.entries(initState).forEach(([key, value]) => {
        if (key !== 'termMonthId') {
          ;(draft as any)[key] = value
        }
      })
    })
  )
}

export const unSelectAllRowOnPage = (ids: string[]) => {
  assetEventListReportSlice.setData((data: State) =>
    produce(data, (draft: State) => {
      ids.forEach((id: string) => {
        draft.selectedItem[id] = false
      })
    })
  )
}
export const assetEventListReportStore = {
  assetEventListReportSlice,
  setDetailMode,
  setSelectRows,
  markAllRows,
  resetStore,
  setTermMonthId,
  unSelectAllRowOnPage,
}
