import { AutoCompleteSelect as ACSelect, Button } from 'aa_common/front-end/antd'
import { formatMonth } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES, TermMonth } from 'common/models'
import { RenderFormProps } from 'components/molecules/Form'
import { find, first, isEqual, pick } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectorGeneralJournalSettingTerms } from 'store/accounting/journal/selectors'
import { selectFullTermsList } from 'store/settings/accountingPeriod/selectors'

import { checkDisable, handleTermYearChanged } from './helper'
import { SubmitAreaWrapper } from './styles'

const requiredFields = [
  'create_journal_method',
  'depreciation_journal_cycle_type',
  'depreciation_journal_method',
  'depreciation_journal_type',
  'paid_excise_debit_account_item_id',
  'received_excise_debit_account_item_id',
  'slip_date_type',
  'date',
]

const t = loadNs(['common', 'components/organisms/accounting/auto-journal-setting-tab'])

const SubmitButton = ({ loading }: SubmitButtonProps) => {
  const watchFormValues = useWatch({})
  const { handleSubmit, handleOnSubmit } = useFormContext() as RenderFormProps

  const formValues = pick(watchFormValues, requiredFields)
  const isValid = Object.values(formValues).every(value => value)

  return (
    <Button
      disabled={!isValid || loading}
      type="button"
      onClick={() => handleOnSubmit && handleSubmit(handleOnSubmit, handleOnSubmit)()}
    >
      {t('actions.save')}
    </Button>
  )
}

const SubmitArea = ({ item, isDetailMode, formMode, nextSettings }: SubmitAreaProps) => {
  const { data: accountPeriods, loading: isAccountPeriodLoading } = useSelector(selectFullTermsList, isEqual)
  const { data: journalItems } = useSelector(selectorGeneralJournalSettingTerms, isEqual)

  const { setValue, control, loading, watch } = useFormContext() as RenderFormProps

  const latestSettings: any = useMemo(() => {
    return first(journalItems)
  }, [journalItems])

  const isAddMode = formMode === FORM_MODES.NEW
  const isEditMode = formMode === FORM_MODES.EDIT && !isDetailMode
  const isAllowEdit = isAddMode || isEditMode

  const termYearValue = watch('term_year_period')

  const memoizedTermYearPeriods = useMemo(() => {
    let compareDate: Moment = moment()
    if (isEditMode) {
      compareDate = moment(item?.from_term_month?.start_date || nextSettings?.start_date)
    } else if (isAddMode) {
      compareDate = moment(latestSettings?.from_term_month?.end_date)
    }

    return (accountPeriods || []).map(termYear => {
      const startDate = moment(termYear.start_date)
      const endDate = moment(termYear.end_date)

      return {
        value: termYear.id,
        disabled: !compareDate.isBetween(startDate, endDate, 'date') && compareDate.isAfter(startDate, 'date'),
        label: t('format.term_year', { year: termYear.year }),
      }
    })
  }, [accountPeriods, latestSettings, nextSettings, item, isAddMode, isEditMode])

  const memoizedTermMonths = useMemo(() => {
    let compareDate: Moment = moment()
    if (isEditMode) {
      compareDate = moment(item?.from_term_month?.start_date || nextSettings?.start_date)
    } else if (isAddMode) {
      compareDate = moment(latestSettings?.from_term_month?.end_date)
    }

    const termYear = find(accountPeriods, item => item.id === termYearValue)
    return (termYear?.term_months || []).map((termMonth: TermMonth) => {
      const startDate = moment(termMonth.start_date)
      const endDate = moment(termMonth.end_date)

      return {
        value: termMonth.id,
        disabled: !compareDate.isBetween(startDate, endDate, 'date') && compareDate.isAfter(startDate, 'date'),
        label: t('format.term_month', { month: formatMonth(termMonth.month) }),
      }
    })
  }, [accountPeriods, nextSettings, latestSettings, termYearValue, item, isAddMode, isEditMode])

  const onTermYearChanged = useCallback(
    termYearPeriod => {
      handleTermYearChanged(termYearPeriod, accountPeriods, setValue, isAddMode, isEditMode, item, nextSettings)
    },
    [isAddMode, nextSettings, isEditMode, item, accountPeriods]
  ) // eslint-disable-line

  const isDisable = useMemo(() => {
    return checkDisable(isAllowEdit, loading, isAccountPeriodLoading)
  }, [loading, accountPeriods, isAllowEdit])

  return (
    <SubmitAreaWrapper>
      <div className="select-month-wrapper">
        <div className="label">{t(isAddMode ? 'add_valid_from_label' : 'edit_valid_from_label')}</div>
        <div className="select-wrapper">
          <ACSelect
            key="term_year_period"
            textField="label"
            name="term_year_period"
            valueField="value"
            control={control}
            items={memoizedTermYearPeriods}
            disabled={isDisable}
            onChange={onTermYearChanged}
            allowClear={false}
            style={{ marginRight: 5, width: 120 }}
          />
          <ACSelect
            name="month_id"
            valueField="value"
            textField="label"
            control={control}
            items={memoizedTermMonths}
            disabled={isDisable}
            allowClear={false}
            style={{ marginRight: 5, width: 120 }}
          />
        </div>
      </div>
      <SubmitButton loading={loading || isAccountPeriodLoading} />
    </SubmitAreaWrapper>
  )
}

interface SubmitAreaProps {
  formMode: FORM_MODES
  isDetailMode: boolean
  nextSettings: any
  item: any
}

interface SubmitButtonProps {
  loading?: boolean
}

export default SubmitArea
