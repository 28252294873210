import styled from '@emotion/styled'
import { Table as AntTable, Divider } from 'antd'
import { css } from '@emotion/css'

export const Wrapper = styled.div`
  width: 100%;
`

export const Table = styled(AntTable)`
  .ant-table {
    border: 1px solid #d4d8dd;
    box-sizing: border-box;
    border-radius: 4px;

    .ant-table-cell {
      padding: 0 15px;
      height: 55px;
      background: #ffffff;

      &.border-double-left {
        border-left: 3px double #d4d8dd;
      }

      &.border-left {
        border-left: 1px solid #d4d8dd;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        font-weight: 500;

        &.padding-5 {
          padding: 5px;
        }

        &.align-center {
          text-align: center;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        &.odd-num-journal {
          background: #f7f7f7;
        }

        .menu-dot {
          margin: -15px;
        }

        &.align-center {
          text-align: center;
        }
      }
    }
  }
`

export const ButtonClass = css`
  box-shadow: none;
  border: none;

  &.isNotActive {
    color: #000000d9;
    background: #d4d8dd;

    &:hover {
      color: #fff;
      background: #1890ff;
    }
  }
`

export const HorizontalBorder = styled(Divider)`
  &.ant-divider-horizontal {
    border-top: 1px solid #d4d8dd;
    margin: 15px 0;
  }
`

export const WrapperButton = styled.div`
  button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`
