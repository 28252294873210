import { Button, ButtonProps } from 'aa_common/front-end/antd'
import i18n from 'i18n'
import React from 'react'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type OmitButtonProps = Omit<ButtonProps, 'type'>

interface ImportExportButtonProps extends OmitButtonProps {
  label?: string
  type: 'import' | 'export'
}

const ImportExportButton: React.FC<ImportExportButtonProps> = ({ label, type, children, onClick, ...rest }) => {
  const renderLabel = () => {
    if (type === 'export') {
      return label || i18n.t('common.actions.export')
    }
    if (type === 'import') {
      return label || i18n.t('common.actions.import')
    }
  }

  return (
    <Button className={type === 'export' ? 'export' : 'import'} size="short" color="grey" onClick={onClick} {...rest}>
      {!children ? (
        <div>
          <span>{renderLabel()}</span>
        </div>
      ) : (
        children
      )}
    </Button>
  )
}

export default ImportExportButton
