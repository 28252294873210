import React from 'react'

import { Wrapper } from './styles'

type Props = {
  color?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const RoundedPlusIcon: React.FC<Props> = ({ color = '#7C8291', style, onClick }) => {
  return (
    <Wrapper style={style} onClick={onClick}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M16 0.71582H2C0.89 0.71582 0 1.61582 0 2.71582V16.7158C0 17.8158 0.89 18.7158 2 18.7158H16C17.1 18.7158 18 17.8158 18 16.7158V2.71582C18 1.61582 17.1 0.71582 16 0.71582ZM14 10.7158H10V14.7158H8V10.7158H4V8.71582H8V4.71582H10V8.71582H14V10.7158Z" />
      </svg>
    </Wrapper>
  )
}

export default RoundedPlusIcon
