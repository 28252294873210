import { Map } from 'immutable'

import {
  CHANGE_ACCOUNT_ITEMS_VALUES,
  CLEANUP_ACCOUNT_ITEMS,
  EXPORT_ACCOUNT_ITEMS_FAILURE,
  EXPORT_ACCOUNT_ITEMS_REQUEST,
  EXPORT_ACCOUNT_ITEMS_SUCCESS,
  FETCH_ACCOUNT_ITEMS_FAILURE,
  FETCH_ACCOUNT_ITEMS_REQUEST,
  FETCH_ACCOUNT_ITEMS_REQUEST_AUTH,
  FETCH_ACCOUNT_ITEMS_SUCCESS,
  IMPORT_ACCOUNT_ITEMS_FAILURE,
  IMPORT_ACCOUNT_ITEMS_REQUEST,
  IMPORT_ACCOUNT_ITEMS_SUCCESS,
  SET_EDITING_ACCOUNT_ITEM,
  SET_ERRORS,
  SET_IS_RESET_FORM,
  SET_NEW_ACCOUNT_ITEMS,
} from './actions'

const initialState = Map({
  list: Map({
    data: null,
    loading: false,
  }),
  editingItem: null,
  newItems: null,
  isResetForm: false,
  errors: null,
  import: Map({
    loading: false,
    error: {
      message: '',
      defails: [],
    },
  }),
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ACCOUNT_ITEMS_REQUEST:
    case FETCH_ACCOUNT_ITEMS_REQUEST_AUTH:
      return state.setIn(['list', 'loading'], true)
    case FETCH_ACCOUNT_ITEMS_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_ACCOUNT_ITEMS_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], null)
    case CHANGE_ACCOUNT_ITEMS_VALUES:
      return state.setIn(['list', 'data'], action.payload)
    case SET_EDITING_ACCOUNT_ITEM:
      return state.setIn(['editingItem'], action.payload)
    case SET_NEW_ACCOUNT_ITEMS:
      return state.setIn(['newItems'], action.payload)
    case SET_IS_RESET_FORM:
      return state.setIn(['isResetForm'], action.payload)
    case SET_ERRORS:
      return state.setIn(['errors'], action.payload)
    case IMPORT_ACCOUNT_ITEMS_REQUEST:
      return state.setIn(['import', 'loading'], true)
    case IMPORT_ACCOUNT_ITEMS_SUCCESS:
      return state
        .setIn(['import', 'loading'], false)
        .setIn(['import', 'error'], initialState.getIn(['import', 'error']))
    case IMPORT_ACCOUNT_ITEMS_FAILURE:
      return state.setIn(['import', 'loading'], false).setIn(['import', 'error'], action.payload)
    case EXPORT_ACCOUNT_ITEMS_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case EXPORT_ACCOUNT_ITEMS_SUCCESS:
    case EXPORT_ACCOUNT_ITEMS_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CLEANUP_ACCOUNT_ITEMS:
      return initialState
    default:
      return state
  }
}
