import { css } from '@emotion/css'

export const CloseIconClass = css`
  margin-right: 15px;
  padding: 0;
  outline: none;
  border: none;
  width: 100%;
  min-width: unset;
  height: 100%;
  min-height: unset;
  font-size: 20px;

  &:hover {
    color: #3b7de9;
  }
`
