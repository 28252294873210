import { JOURNAL_TYPE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import React from 'react'

import { JournalStatusWrapper } from '../styles'

enum COLORS {
  BLUE = 'blue',
  GREY = 'grey',
}

type Props = {
  type: JOURNAL_TYPE
}

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
const getTextByType = (type: JOURNAL_TYPE) => {
  if (type === JOURNAL_TYPE.NOT_EXPORTED) {
    return {
      text: t('not_exported'),
      color: COLORS.BLUE,
    }
  }

  if (type === JOURNAL_TYPE.EXPORTED) {
    return {
      text: t('exported'),
      color: COLORS.GREY,
    }
  }
}

export const JournalStatus: React.FC<Props> = ({ type }) => {
  const status = getTextByType(type)

  return <JournalStatusWrapper className={status?.color}>{status?.text}</JournalStatusWrapper>
}
