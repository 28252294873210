import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 700px;

  .excise_active_checkbox {
    text-align: center;
  }
`

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`
