import { parseError, parseResponse } from 'common/helpers'

import base from './base'

export const list = () => {
  return base
    .get('/v1/account_items')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const listAuth = () => {
  return base
    .get('/v1/account_items/get')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const update = (data: any) => {
  return base.post('/v1/account_items', data)
}

export const importFile = (charset: string, file: any) => {
  const formData = new FormData()
  formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/account_items/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const exportFile = (charset: string) => {
  return base.get(`/v1/export/account_items?charset=${charset}`, {
    responseType: 'blob',
  })
}
