import styled from '@emotion/styled'

export const RangeDateFormWrapper = styled.div`
  padding: 10px;
  max-width: 350px;

  .range-date-input {
    margin-top: 8px;
  }
`

export const Field = styled.div``

export const ActionButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`

export const Label = styled.label`
  font-weight: bold;
`
