import { useParams } from 'react-router-dom'

type CustomParams = {
  [key: string]: string | undefined
}

export const useRouterParams = <T extends CustomParams>(key: keyof T) => {
  // Call the original useParams hook
  const params = useParams<T>()

  // Get the value for the specified key
  const value = params[key]

  return value
}
