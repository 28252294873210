import { Spin } from 'aa_common/front-end/antd'
import { Table } from 'antd'
import React from 'react'

import { getColumns } from './settings'
import { AutoJournalSettingListClass } from './styles'

interface AutoJournalSettingListProps {
  data: any[]
  loading?: boolean
  onSelect?: (selectedItem: any) => void
}

const AutoJournalSettingList = ({ data, loading, onSelect }: AutoJournalSettingListProps) => {
  return (
    <div style={{ clear: 'both', maxWidth: '100%' }}>
      <Spin loading={loading}>
        <Table
          dataSource={data}
          className={AutoJournalSettingListClass}
          columns={getColumns()}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onSelect && onSelect(record)
                const rows = document && document.querySelectorAll('.ant-table-row-level-0')
                if (rows && rows.length > 0 && rowIndex! >= 0) {
                  Array.from(rows).forEach(row => {
                    row.classList.remove('selected')
                  })
                  const selectedRow = typeof rowIndex !== 'undefined' && rows[rowIndex]
                  selectedRow && selectedRow.classList.add('selected')
                }
              },
            }
          }}
          pagination={false}
          scroll={{ y: 'calc(100vh - 360px)' }}
        />
      </Spin>
    </div>
  )
}

export default AutoJournalSettingList
