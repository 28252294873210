export interface ValueFields {
  code: string
  name: string
  prepress_category_code: null | number
  search_key: string
  valid_from: any
  valid_to: null
  ref_id: null
}

export const validateForm = (values: ValueFields) => {
  const { code, name, valid_from, prepress_category_code } = values

  return code.trim() && name.trim() && prepress_category_code && valid_from
}
