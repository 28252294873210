import { Map } from 'immutable'

import { OperationLogActionTypes } from './action.model'
import {
  CLEANUP_OPERATION_LOG,
  CLEANUP_OPERATION_LOG_ITEM,
  FETCH_OPERATION_LOG_ITEM_FAILURE,
  FETCH_OPERATION_LOG_ITEM_REQUEST,
  FETCH_OPERATION_LOG_ITEM_SUCCESS,
  FETCH_OPERATION_LOGS_FAILURE,
  FETCH_OPERATION_LOGS_REQUEST,
  FETCH_OPERATION_LOGS_SUCCESS,
} from './constant'
import { OperationLogsSelector } from './model'

export const initialState = Map({
  list: {
    data: [],
    loading: false,
    totalRecords: 0,
  } as OperationLogsSelector,
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: OperationLogActionTypes): any => {
  switch (action.type) {
    case FETCH_OPERATION_LOGS_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_OPERATION_LOGS_SUCCESS:
      return state
        .setIn(['list', 'loading'], false)
        .setIn(['list', 'data'], action.payload.logs)
        .setIn(['list', 'totalRecords'], action.payload.totalRecords)
    case FETCH_OPERATION_LOGS_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case FETCH_OPERATION_LOG_ITEM_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_OPERATION_LOG_ITEM_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case FETCH_OPERATION_LOG_ITEM_FAILURE:
      return state.setIn(['item', 'loading'], false)
    case CLEANUP_OPERATION_LOG_ITEM:
      return state.setIn(['item', 'data'], null)
    case CLEANUP_OPERATION_LOG:
      return initialState
    default:
      return state
  }
}
