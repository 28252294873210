/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterAttachmentInformation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Attachments<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Download attachment
   *
   * @tags attachments
   * @name DownloadDetail
   * @summary Download the attachment
   * @request GET:/attachments/download/{id}
   */
  downloadDetail = (id: string, params: RequestParams = {}) =>
    this.http.request<File, any>({
      path: `/attachments/download/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description Upload attachment
   *
   * @tags attachments
   * @name UploadCreate
   * @summary Upload the attachment
   * @request POST:/attachments/upload
   */
  uploadCreate = (
    data: {
      /** attachment file */
      file: File
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterAttachmentInformation, any>({
      path: `/attachments/upload`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
