/* eslint-disable react/no-this-in-sfc */
import classNames from 'classnames'
import { debounce, find } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { BaseMonthTermSlider } from './model'
import { SliderMonthWrapper } from './styles'

const MARKER_WIDTH = 8
// https://stackoverflow.com/questions/5598743
function getXCoords(elem: HTMLElement | null) {
  if (!elem) return 0

  const box = elem.getBoundingClientRect()
  const { body } = document
  const docEl = document.documentElement

  const scrollLeft = window.scrollX || docEl.scrollLeft || body.scrollLeft
  const clientLeft = docEl.clientLeft || body.clientLeft || 0
  return box.left + scrollLeft - clientLeft
}

const MonthTermSlider = ({ value, termMonths, onChange }: BaseMonthTermSlider) => {
  const $el = useRef<HTMLDivElement>(null)
  const $table = useRef<HTMLTableElement>(null)
  const [monthIndex, setMonthIndex] = useState(1)
  const [span, setSpan] = useState(1)
  const [left, setLeftPos] = useState(0)
  const [right, setRightPos] = useState(0)

  useEffect(() => {
    $this.current.value = value
    $this.current.onChange = onChange

    const [from = 1, to = 1] = value || []
    $this.current.mIndex = from || 1
    $this.current.span = to - from + 1 || 1
    $this.current.updatePointerPos()
  }, [value, onChange])

  useEffect(() => {
    const { value, termMonths: prevTermMonths } = $this.current
    $this.current.termMonths = termMonths

    if (value) {
      if (prevTermMonths?.length > 0) {
        const [from, to] = value
        const prevFrom = find(prevTermMonths, item => item.month_serial_number === from)?.month
        const previousTo = find(prevTermMonths, item => item.month_serial_number === to)?.month

        const newFrom = find(termMonths, item => item.month === prevFrom)?.month_serial_number || 1
        const newTo = find(termMonths, item => item.month === previousTo)?.month_serial_number || 1

        const span = newTo - newFrom + 1
        $this.current.mIndex = newFrom
        $this.current.span = span > 0 ? span : 1
        $this.current.updatePointerPos()
      }
    }
  }, [termMonths])

  const tableWidth = $table.current?.clientWidth || 0
  useEffect(() => {
    tableWidth && $this.current.updatePointerPos()
  }, [tableWidth])

  const rem = $el.current
  useEffect(() => {
    const { onBodyMouseUp, onMouseDown, onMouseMove, onMouseUp } = $this.current
    if (rem) {
      rem.addEventListener('mousedown', onMouseDown)
      rem.addEventListener('mousemove', onMouseMove)
      rem.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('mouseup', onBodyMouseUp)
      document.addEventListener('mouseleave', onBodyMouseUp)
    }
    return () => {
      if (rem) {
        rem.removeEventListener('mousedown', onMouseDown)
        rem.removeEventListener('mousemove', onMouseMove)
        rem.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('mouseup', onBodyMouseUp)
        document.removeEventListener('mouseleave', onBodyMouseUp)
      }
    }
  }, [rem])

  const $this = useRef({
    value,
    termMonths,
    onChange,
    handleOnChange: debounce(function () {
      if (!$this.current.termMonths?.length) return
      if ($this.current.startPageX) return

      const { mIndex: from, span, value, onChange } = $this.current
      const to = from + span - 1
      if (!value || value[0] !== from || value[1] !== to) {
        onChange && onChange([from, to])
      }
    }, 100),
    get maxSpan() {
      const [hasTerm] = this.termMonths || []
      return hasTerm ? this.termMonths.length : 12
    },
    get width() {
      return ($table.current?.clientWidth || 0) / this.maxSpan
    },
    $mIndex: monthIndex,
    get mIndex() {
      return this.$mIndex
    },
    set mIndex(value: number) {
      let val = Math.floor(value) || 1
      if (val < 0) val = 1
      if (val > this.maxSpan) val = this.maxSpan

      setMonthIndex(val)
      this.$mIndex = val
      this.handleOnChange()
    },
    $span: span,
    get span() {
      return this.$span
    },
    set span(value: number) {
      let val = Math.floor(value) || 1
      if (val < 0 || this.mIndex + val > this.maxSpan + 1) val = 1
      setSpan(val)
      this.$span = val
      this.handleOnChange()
    },
    $left: left,
    get left() {
      return this.$left
    },
    set left(val: number) {
      setLeftPos(val)
      this.$left = val
    },
    $right: right,
    get right() {
      return this.$right
    },
    set right(val: number) {
      setRightPos(val)
      this.$right = val
    },

    isMove: false,
    prefer: '' as 'left' | 'right',
    startPageX: null as null | number,
    clickMonth: null as null | number,
    clickTimer: null as unknown as ReturnType<typeof setTimeout>,
    moveFunc: null as null | (() => void),
    getPosX: (evt: MouseEvent) => evt.pageX - getXCoords($el.current),
    calcPointerPos: (e: number) => e - MARKER_WIDTH / 2,
    updatePointerPos() {
      this.left = (this.mIndex - 1) * this.width
      this.right = this.left + this.width * this.span
    },
    setLeft(e: number) {
      if (e + MARKER_WIDTH / 2 >= 0 && e <= this.right - this.width) {
        this.prefer = 'left'
        this.left = e
      }
    },
    setRight(e: number) {
      const maxRight = this.maxSpan * this.width - 1 + MARKER_WIDTH
      if (e >= this.left + this.width && e <= maxRight) {
        this.prefer = 'right'
        this.right = e
      }
    },
    setMonth() {
      const newMonthIndex = Math.round(this.left / this.width) + 1
      const newSpan = Math.round(this.right / this.width) - newMonthIndex + 1
      if (this.mIndex !== newMonthIndex || this.span !== newSpan) {
        this.mIndex = newMonthIndex
        this.span = newSpan
      }
    },
    choseMonth(month: number) {
      this.clickMonth = null
      this.left = (month - 1) * this.width
      this.right = month * this.width
      this.setMonth()
    },
    changeMonth(mIndex: number) {
      if (mIndex + this.span > this.maxSpan) {
        this.mIndex = this.maxSpan - this.span + 1
      } else {
        this.mIndex = mIndex
      }
      this.updatePointerPos()
    },
    onMouseDown(evt: MouseEvent) {
      evt.stopPropagation()
      evt.preventDefault()

      const target = evt.target as HTMLElement
      const xCoord = $this.current.getPosX(evt)
      const flag =
        target.tagName !== 'TD' &&
        Math.abs(xCoord - $this.current.left) > MARKER_WIDTH &&
        Math.abs($this.current.right - xCoord) > MARKER_WIDTH
      if (!flag) {
        const cellWidth = $this.current.width
        let mToChoose: number
        if (Math.abs(xCoord - $this.current.left) < Math.abs($this.current.right - xCoord)) {
          mToChoose = Math.ceil(xCoord / cellWidth)
          $this.current.prefer = 'left'
        } else {
          mToChoose = Math.floor(xCoord / cellWidth) + 1
          $this.current.prefer = 'right'
        }

        if (mToChoose < 0) mToChoose = 0
        if (mToChoose > $this.current.maxSpan) mToChoose = $this.current.maxSpan

        $this.current.startPageX = $this.current.getPosX(evt)
        $this.current.isMove = false
        $this.current.moveFunc = null
        if (xCoord < $this.current.left - MARKER_WIDTH || xCoord > $this.current.right + MARKER_WIDTH) {
          $this.current.moveFunc = () => $this.current.choseMonth(mToChoose)
        }
      }
    },
    onMouseMove(evt: MouseEvent) {
      evt.stopPropagation()
      evt.preventDefault()

      if ($this.current.startPageX !== null) {
        if ($this.current.isMove || Math.abs($this.current.startPageX - $this.current.getPosX(evt)) >= 20) {
          $this.current.isMove = true
          if ($this.current.moveFunc) {
            $this.current.moveFunc()
            $this.current.moveFunc = null
          }
          const pos = $this.current.getPosX(evt)
          if ($this.current.prefer === 'left') {
            $this.current.setLeft(pos)
            $this.current.setRight(pos)
          } else {
            $this.current.setRight(pos)
            $this.current.setLeft(pos)
          }
          $this.current.setMonth()
        }
      }
    },
    onMouseUp(evt: MouseEvent) {
      evt.stopPropagation()
      evt.preventDefault()

      const { startPageX } = $this.current
      $this.current.startPageX = null
      if (startPageX) {
        $this.current.handleOnChange()
        const target = evt.target as HTMLElement
        if ($this.current.isMove) {
          $this.current.updatePointerPos()
        } else if (target.tagName === 'TD') {
          const { width, clickMonth, span } = $this.current
          const mToChoose = Math.floor(startPageX / width) + 1
          if (clickMonth && clickMonth === mToChoose) {
            clearTimeout($this.current.clickTimer)
            $this.current.clickMonth = null
            $this.current.changeMonth(mToChoose)
            return
          }
          if (span > 1) {
            $this.current.clickMonth = mToChoose
            $this.current.clickTimer = setTimeout(() => $this.current.choseMonth(mToChoose), 100)
          } else {
            $this.current.choseMonth(mToChoose)
          }
        }
      }
    },
    onBodyMouseUp() {
      const { startPageX } = $this.current
      $this.current.startPageX = null
      if (startPageX) {
        $this.current.updatePointerPos()
        $this.current.handleOnChange()
      }
    },
  })

  return (
    <SliderMonthWrapper ref={$el}>
      <div className="jslider-month-selector">
        <table ref={$table}>
          <tbody>
            <tr>
              {(termMonths || []).map((term, i) => {
                const className = classNames(`jslider-month-${i + 1} jslider-month`, {
                  selected: monthIndex <= i + 1 && i + 1 < monthIndex + span,
                })
                return (
                  <td key={term.id} className={className}>
                    {term.month}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
      </div>
      {termMonths?.length > 0 && (
        <>
          <span style={{ left: $this.current.calcPointerPos(left) }} className="jslider-pointer pointer-left" />
          <span style={{ left: $this.current.calcPointerPos(right) }} className="jslider-pointer pointer-right" />
        </>
      )}
    </SliderMonthWrapper>
  )
}

export default MonthTermSlider
