import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import { ERROR_FROM_API } from '../const'

const DEBOUNCE_VALIDATION = 100

export function useFormValidation() {
  const { values, validateForm } = useFormikContext()
  const [debounceValidation] = useDebounce(values, DEBOUNCE_VALIDATION)

  useEffect(() => {
    validateForm()
  }, [debounceValidation, validateForm])
}

export function useSubmitOnError(onSubmit?: (values: any) => void) {
  const { isValid, values, submitCount, errors } = useFormikContext()
  const isApiError = useMemo(() => {
    return errors && Object.keys(errors).some(key => key === ERROR_FROM_API)
  }, [errors])

  useEffect(() => {
    !isValid && submitCount > 0 && !isApiError && onSubmit?.(values)
  }, [submitCount])
}
