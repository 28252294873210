import { Map } from 'immutable'

import { TaxDeclarationReportTypes } from './actions.model'
import {
  ADD_TAX_DECLARATION_DECREASE_REPORT_FILTER,
  FETCH_TAX_DECLARATION_DECREASE_REPORT,
  FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS,
  FETCH_TAX_DECLARATION_INCREASE_REPORT,
  FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS,
  FETCH_TAX_DECLARATION_REPORT_LIST,
  FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE,
  FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE,
  FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS,
} from './constant'

export const initialState = Map({
  data: null,
  list: null,
  increaseReport: null,
  decreaseReport: null,
  loading: false,
  formFilter: null,
})

export default (state = initialState, action: TaxDeclarationReportTypes) => {
  switch (action.type) {
    case FETCH_TAX_DECLARATION_SUMMARY_REPORT:
      return state.setIn(['loading'], true)
    case FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS:
      return state.setIn(['data'], action.payload).setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE:
      return state.setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_REPORT_LIST:
      return state.setIn(['loading'], true)
    case FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS:
      return state.setIn(['list'], action.payload).setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE:
      return state.setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_INCREASE_REPORT:
      return state.setIn(['loading'], true)
    case FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS:
      return state.setIn(['increaseReport'], action.payload).setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE:
      return state.setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_DECREASE_REPORT:
      return state.setIn(['loading'], true)
    case FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS:
      return state.setIn(['decreaseReport'], action.payload).setIn(['loading'], false)
    case FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE:
      return state.setIn(['loading'], false)
    case ADD_TAX_DECLARATION_DECREASE_REPORT_FILTER:
      return state.setIn(['formFilter'], action.payload)
    default:
      return state
  }
}
