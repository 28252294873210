import { Tooltip, Typography } from 'antd'
import React, { useImperativeHandle, useState } from 'react'

import { DefaultTooltipOverlay } from '../../../../assets/styles/commonStyle'

const { Text: AntdText } = Typography

interface OptionTextProps {
  text: string
}

const Text = React.forwardRef<any, OptionTextProps>(function RenderText({ text }, ref) {
  const [ellipsis, setEllipsis] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  // Ref for future programatically use
  useImperativeHandle(ref, () => {
    return {
      showTooltip: setShowTooltip,
    }
  })

  return (
    <Tooltip
      overlayClassName={DefaultTooltipOverlay}
      overlayStyle={{ pointerEvents: 'none', maxWidth: '500px' }}
      visible={showTooltip && ellipsis}
      destroyTooltipOnHide
      title={text}
      onVisibleChange={setShowTooltip}
    >
      <AntdText style={{ color: 'inherit' }} ellipsis={{ onEllipsis: setEllipsis }}>
        {text}
      </AntdText>
    </Tooltip>
  )
})

export default Text
