import styled from '@emotion/styled'

export const ActionWrapper = styled.div`
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    font-size: 13px;
    font-weight: 500;
    margin-right: 12px;

    &.ant-btn {
      min-width: 70px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .button-tooltip-wrapper {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`
