import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadNs } from 'common/i18n-config'
import React from 'react'

const t = loadNs(['common'])

type TriggerTemplateProps = {
  handleCollapse: () => void
  siderCollapse: boolean
}

export const TriggerTemplate = (props: TriggerTemplateProps) => {
  return (
    <div style={{ borderTop: '1px solid #D4D8DD' }} onClick={props.handleCollapse}>
      <div style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        <div>
          {props.siderCollapse ? (
            <FontAwesomeIcon icon={faChevronRight} style={{ verticalAlign: 'middle', fontSize: 18 }} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} style={{ verticalAlign: 'middle', marginRight: 10, fontSize: 18 }} />
          )}
          {!props.siderCollapse && (
            <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{t('actions.close')}</span>
          )}
        </div>
      </div>
    </div>
  )
}
