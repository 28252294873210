import { TempDataTypes } from 'api/app/tempData'
import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { hasEventOccurred } from 'common/helpers'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupAssetItem, fetchAsset } from 'store/asset/actions'
import { selectEventHistories } from 'store/asset/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { clearTempData, fetchTempData } from 'store/tempData/actions'

export const useFetchData = () => {
  const { biid } = useParams<any>()
  const terms = useSelector(selectTermsData, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTempData(TempDataTypes.CREATE_ASSET))
    !terms && dispatch(fetchTerms())

    const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)
    dispatch(fetchAsset(biid, hasDividingEvent))

    dispatch(fetchLedgerSettingsList({}))

    return function cleanup() {
      dispatch(clearTempData())
      dispatch(cleanupAssetItem())
    }
  }, [])
}
