import { Button } from 'aa_common/front-end/antd'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const SubmitButton: React.FC = ({ children }) => {
  const { formState } = useFormContext()
  return (
    <Button disabled={!formState.isValid} type="submit">
      {children}
    </Button>
  )
}

export default SubmitButton
