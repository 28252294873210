import styled from '@emotion/styled'

export const ModalWrapper = styled.div`
  width: 100%;

  .modal-title {
    width: 100%;
    .title-message {
      margin-bottom: 8px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-progress-bg {
    height: 4px !important;
  }
`

export const ActionWrapper = styled.div`
  max-width: 1030px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d4d8dd;

  .radio-title {
    margin-right: 15px;
  }
`
