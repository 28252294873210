import { Map } from 'immutable'

import { RolesActionTypes } from './action.model'
import {
  CLEANUP_ROLES,
  FETCH_USER_ROLES_FAILURE,
  FETCH_USER_ROLES_REQUEST,
  FETCH_USER_ROLES_SUCCESS,
  HANDLE_FETCH_ROLE_DETAIL,
  SET_LOADING_ITEM,
} from './constant'

const initialState = Map({
  list: {
    data: [],
    loading: false,
  },
  item: {
    data: null,
    loading: false,
  },
})

export default (state = initialState, action: RolesActionTypes) => {
  switch (action.type) {
    case FETCH_USER_ROLES_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_USER_ROLES_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_USER_ROLES_FAILURE:
      return state.setIn(['list', 'loading'], false)
    case CLEANUP_ROLES:
      return initialState
    case SET_LOADING_ITEM:
      return state.setIn(['item', 'loading'], action.payload)
    case HANDLE_FETCH_ROLE_DETAIL:
      return state.setIn(['item', 'data'], action.payload)
    default:
      return state
  }
}
