import React from 'react'

type Props = {
  color?: string
}

const TriangleRightIcon: React.FC<Props> = ({ color = 'white' }) => {
  return (
    <svg width="6" height="8" viewBox="0 0 6 8" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M6 4.37695L8.90085e-08 7.84105L6.39053e-09 0.912852L6 4.37695Z" fill={color} />
    </svg>
  )
}

export default TriangleRightIcon
