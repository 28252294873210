import { TaxDeclarationReportSelector } from './model'

export const selectTaxDeclarationReports = (state: any) => {
  return {
    loading: state.getIn(['accounting', 'depreciationFixedAsset', 'loading']),
    summaryReport: state.getIn(['accounting', 'depreciationFixedAsset', 'data']),
    increaseReport: state.getIn(['accounting', 'depreciationFixedAsset', 'increaseReport']),
    decreaseReport: state.getIn(['accounting', 'depreciationFixedAsset', 'decreaseReport']),
  } as Omit<TaxDeclarationReportSelector, 'list'>
}

export const selectTaxDeclarationReportList = (state: any) => {
  return {
    loading: state.getIn(['accounting', 'depreciationFixedAsset', 'loading']),
    list: state.getIn(['accounting', 'depreciationFixedAsset', 'list']),
  } as Pick<TaxDeclarationReportSelector, 'loading' | 'list'>
}

export const selectTaxDeclarationReportFormFilterSetting = (state: any) => {
  return {
    formFilter: state.getIn(['accounting', 'depreciationFixedAsset', 'formFilter']),
  }
}
