import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

const JAPANESE_LANGUAGE = 'ja'

const resources: Record<string, any> = {
  [JAPANESE_LANGUAGE]: { translation: require('./ja').default },
}
// Don't load English translation for `production`
if (process.env.REACT_APP_ENV !== 'production') {
  resources.en = { translation: require('./en').default }
}

const lng = `${navigator.language}`.split('-')[0] || JAPANESE_LANGUAGE

const i18n = i18next.createInstance()

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false,
  },
  lng: Object.prototype.hasOwnProperty.call(resources, lng) ? lng : JAPANESE_LANGUAGE,
  resources,
})

export default i18n
