import styled from '@emotion/styled'
import { Typography } from 'antd'
import { FieldErrors, get, useFormContext } from 'react-hook-form'

import { StyledWrapper } from './styles'

const { Text } = Typography

export const ErrorMessage = ({ errors, name }: Props) => {
  const methods = useFormContext()
  const error = get(errors || methods?.formState?.errors, name)

  if (!error) {
    return null
  }

  return (
    <StyledWrapper>
      <Text type="danger">{error.message}</Text>
    </StyledWrapper>
  )
}

type Props = {
  name: string
  errors: FieldErrors | null
}

type CommonErrorProp = {
  fontSize?: string
}

export const CommonErrorMsg = styled.div<CommonErrorProp>`
  color: #dc3545;
  ${props => (props.fontSize ? `font-size:${props.fontSize};` : '')}
`
