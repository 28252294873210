import { css } from '@emotion/css'

export const OptionWrapperClass = css`
  padding: 5px 12px;
  width: 100%;
  transition: background 0.3s ease;

  &.aa-infinite-scroll__option {
    cursor: pointer;
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    cursor: not-allowed;
  }

  &.focused {
    background-color: #f5f5f5;
  }

  &.selected {
    background-color: #e6f7ff;
    color: black;
  }
`
