/**
 * The constant actions to fetch tax declaration summary report (for preview page)
 */
export const FETCH_TAX_DECLARATION_SUMMARY_REPORT = 'FETCH_TAX_DECLARATION_SUMMARY_REPORT'
export const FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS = 'FETCH_TAX_DECLARATION_SUMMARY_REPORT_SUCCESS'
export const FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE = 'FETCH_TAX_DECLARATION_SUMMARY_REPORT_FAILURE'

/**
 * The constant actions to fetch tax declaration report list (for list page)
 */
export const FETCH_TAX_DECLARATION_REPORT_LIST = 'FETCH_TAX_DECLARATION_REPORT_LIST'
export const FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS = 'FETCH_TAX_DECLARATION_REPORT_LIST_SUCCESS'
export const FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE = 'FETCH_TAX_DECLARATION_REPORT_LIST_FAILURE'

/**
 * The constant actions to fetch tax declaration INCREASE report list
 */
export const FETCH_TAX_DECLARATION_INCREASE_REPORT = 'FETCH_TAX_DECLARATION_INCREASE_REPORT'
export const FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS = 'FETCH_TAX_DECLARATION_INCREASE_REPORT_SUCCESS'
export const FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE = 'FETCH_TAX_DECLARATION_INCREASE_REPORT_FAILURE'

/**
 * The constant actions to fetch tax declaration DECREASE report list
 */
export const FETCH_TAX_DECLARATION_DECREASE_REPORT = 'FETCH_TAX_DECLARATION_DECREASE_REPORT'
export const FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS = 'FETCH_TAX_DECLARATION_DECREASE_REPORT_SUCCESS'
export const FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE = 'FETCH_TAX_DECLARATION_DECREASE_REPORT_FAILURE'

/*
 * The constant actions to EXPORT
 */
export const EXPORT_TAX_DECLARATION_REPORTS = 'EXPORT_TAX_DECLARATION_REPORTS'
export const EXPORT_TAX_DECLARATION_REPORTS_SUCCESS = 'EXPORT_TAX_DECLARATION_REPORTS_SUCCESS'
export const EXPORT_TAX_DECLARATION_REPORTS_FAILURE = 'EXPORT_TAX_DECLARATION_REPORTS_FAILURE'
export const ON_WEBSOCKET_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE'

/*
 * The constant actions save form filter setting
 */
export const ADD_TAX_DECLARATION_DECREASE_REPORT_FILTER = 'ADD_TAX_DECLARATION_DECREASE_REPORT_FILTER'
