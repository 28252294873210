import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-radio-group {
    .ant-radio-wrapper {
      margin-right: 10px;

      span.ant-radio + * {
        padding-right: 0px;
        padding-left: 5px;
      }
    }
  }
`
