import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { Spin } from '../../../antd'
import { DefaultBasicModalClass } from './styles'

type Props = {
  bodyStyle?: React.CSSProperties
  className?: string
  loading?: boolean
  title: React.ReactNode
  isShow: boolean
  width?: number
  allowOutsideClick?: boolean
  destroyOnClose?: boolean
  footer?: React.ReactNode
  onCancel?: () => void
}

export const NewBasicModal: React.FC<Props> = ({
  children,
  loading,
  isShow = false,
  allowOutsideClick = true,
  destroyOnClose = true,
  title,
  width = 600,
  footer = null,
  onCancel,
  bodyStyle,
  className,
}) => {
  return (
    <Modal
      visible={isShow}
      width={width}
      destroyOnClose={destroyOnClose}
      maskClosable={allowOutsideClick}
      closable={false}
      footer={null}
      centered
      onCancel={onCancel}
      className={classNames('basic-modal', DefaultBasicModalClass, className)}
      bodyStyle={bodyStyle}
    >
      <Spin loading={loading}>
        <button type="button" className="ant-modal-close" onClick={onCancel}>
          <CloseOutlined style={{ fontSize: 18, position: 'absolute' }} />
        </button>
        <div className="ant-modal-header">
          <div className="ant-modal-title">{title}</div>
        </div>
        <div className="modal-body-wrapper">{children}</div>
        {footer && <div className="ant-modal-footer">{footer}</div>}
      </Spin>
    </Modal>
  )
}
