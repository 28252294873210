import { Spin } from 'aa_common/front-end/antd'
import classNames from 'classnames'
import { ColumnState, IColumnSortEvent, TableColumn } from 'common/models'
import SortTableCellIcon from 'components/atoms/icons/SortTableCellIcon'
import update from 'immutability-helper'
import { List } from 'immutable'
import filter from 'lodash/filter'
import head from 'lodash/head'
import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import EmptyRow from './empty-row'
import { Body, Header, HeaderCell, Row, Table } from './styles'
import TableRow from './TableRow'

interface BasicTableProps {
  data: any[]
  columns: TableColumn[]
  columnsState?: ColumnState[]
  columnsWidth?: (number | string)[] | null
  draggable?: boolean
  draggableTooltipMessage?: string
  disableDragRow?: boolean
  loading?: boolean
  emptyMessage?: string
  style?: React.CSSProperties
  onCopy?: (event: any) => void
  onEdit?: (event: any) => void
  onDelete?: (event: any) => void
  disableActions?: {
    copy?: (record: any) => boolean
    edit?: (record: any) => boolean
    delete?: (record: any) => boolean
  }
  showActionsTooltip?: {
    copy?: (record: any) => string | undefined
    edit?: (record: any) => string | undefined
    delete?: (record: any) => string | undefined
  }
  onRowFinishDragging?: (event: any) => void
  onHeaderCellSortClick?: IColumnSortEvent
  isRenderLastColumn?: boolean
}

const isSamePosition = (targetId: number | string, replacingId: number | string) => {
  return targetId === replacingId
}

export const BasicTable: React.FC<BasicTableProps> = ({
  data = [],
  columns = [],
  columnsWidth = null,
  draggable = false,
  loading = false,
  emptyMessage,
  style,
  disableDragRow,
  draggableTooltipMessage,
  columnsState,
  disableActions,
  showActionsTooltip,
  onCopy,
  onEdit,
  onDelete,
  onRowFinishDragging,
  onHeaderCellSortClick,
  isRenderLastColumn = true,
}) => {
  const [tableData, setTableData] = useState<any[]>(data)
  const [currentSortColumn, setCurrentSortColumn] = useState<string | null>(null)
  const [tableColumns, setTableColumns] = useState<ColumnState[]>(
    columns.map(({ sortable, ...rest }) => ({
      sortType: '',
      sortable: sortable === undefined ? true : sortable,
      ...rest,
    }))
  )

  useEffect(() => {
    if (columnsState) {
      const tableColumnsState = columnsState.map(({ sortable, ...rest }) => ({
        sortable: sortable === undefined ? true : sortable,
        ...rest,
      }))
      const filterSortedColumns = filter(tableColumnsState, item => item.sortType !== '')
      filterSortedColumns.length > 0 && setCurrentSortColumn(head(filterSortedColumns)!.field)
      setTableColumns(tableColumnsState)
    }
  }, [columnsState, setTableColumns])

  const handleRowFinishDragging = useCallback(
    event => {
      const position = event.index
      const currentItem = event.item
      const beforeItem = tableData[position - 1]
      const afterItem = tableData[position + 1]
      const rowDragEvent = { ...event, beforeItem, currentItem, afterItem }
      if (isSamePosition(currentItem.id, data[position].id)) {
        return
      }
      onRowFinishDragging && onRowFinishDragging(rowDragEvent)
    },
    [onRowFinishDragging, tableData, data]
  )

  const moveTableRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragTableRow = tableData[dragIndex]
      setTableData(
        update(tableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragTableRow],
          ],
        })
      )
    },
    [tableData]
  )

  useEffect(() => {
    setTableData(data)
  }, [data])

  const getWidthStyle = (index: number) => {
    let style = {}

    if (columnsWidth && columnsWidth[index]) {
      style = { width: columnsWidth[index] }
    }

    return style
  }

  const onHeaderCellClick = (item: ColumnState) => {
    if (!item.sortable) {
      return
    }

    // Update columns state;
    const columnList = List.of(...tableColumns)
    const targetColumnIndex = columnList.findIndex(column => column.field === item.field)

    const isDifferentThanLastSortField = currentSortColumn && currentSortColumn !== item.field
    if (!currentSortColumn || isDifferentThanLastSortField) {
      setCurrentSortColumn(item.field)
    }

    if (isDifferentThanLastSortField) {
      // reset all fields if there is new field being sorted
      columnList.map(item => (item.sortType = ''))
    }

    const updatedColumnState = columnList.update(targetColumnIndex, (item: any) => ({
      ...item,
      sortType: getSortType(item),
    }))

    // Return updated target column
    const updatedTargetColumn = updatedColumnState.find(column => column.field === item.field) || null
    setTableColumns(updatedColumnState.toArray())
    onHeaderCellSortClick &&
      onHeaderCellSortClick({
        currentColumn: updatedTargetColumn!,
        tableColumns: updatedColumnState.toArray(),
      })
  }

  const getSortType = (item: ColumnState) => {
    switch (item?.sortType) {
      case 'asc':
        return 'desc'
      case 'desc':
        return ''
      default:
        return 'asc'
    }
  }

  const renderHeader = () => {
    const headerCellClass = (item: TableColumn, index: number) =>
      classNames(item.field, { first: !draggable && index === 0, 'cell-sortable': item.sortable })
    return (
      <Row>
        {draggable && <HeaderCell className="first sortable" />}
        {tableColumns.map((columnItem, index) => (
          <HeaderCell
            key={columnItem.field}
            className={headerCellClass(columnItem, index)}
            style={getWidthStyle(index)}
            onClick={() => onHeaderCellClick(columnItem)}
          >
            <div style={{ display: 'inline-flex' }}>
              <span>{columnItem.text}</span>
              {columnItem.sortable && <SortTableCellIcon sortType={columnItem.sortType} style={{ marginLeft: 8 }} />}
            </div>
          </HeaderCell>
        ))}
        {isRenderLastColumn && <HeaderCell className="last" style={getWidthStyle(tableColumns.length)} />}
      </Row>
    )
  }

  const renderBody = () => {
    return tableData.map((bodyItem, index) => {
      return (
        <TableRow
          key={uniqueId()}
          id={`table-row-${bodyItem.id}`}
          index={index}
          isFirst={index === 0}
          isLast={index === data.length - 1}
          bodyItem={bodyItem}
          draggable={draggable}
          columns={columns}
          onCopy={onCopy}
          onEdit={onEdit}
          onDelete={onDelete}
          moveTableRow={moveTableRow}
          draggableTooltipMessage={draggableTooltipMessage}
          disableActions={disableActions}
          showActionsTooltip={showActionsTooltip}
          disableDragRow={disableDragRow}
          onFinishDragging={handleRowFinishDragging}
          isRenderLastColumns={isRenderLastColumn}
        />
      )
    })
  }

  const renderEmpty = () => {
    return <EmptyRow columns={columns} content={emptyMessage} />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Spin loading={loading}>
        <Table style={style}>
          <Header>{renderHeader()}</Header>
          <Body>{tableData.length > 0 ? renderBody() : renderEmpty()}</Body>
        </Table>
      </Spin>
    </DndProvider>
  )
}
