import { SorterResult } from 'antd/es/table/interface'
import { isArray } from 'lodash'

export const combinedKeys = (columnKeys: string[]) => `-combinedkey-${columnKeys.join(',')}`

export const convertComplexTableSortPayload = (sorter: SorterResult<any> | SorterResult<any>[]) => {
  const sorts: string[] = []

  // Single sort
  if (sorter && !isArray(sorter)) {
    if (sorter.columnKey && /^-combinedkey-/.test(sorter.columnKey as string)) {
      const filters = sorter.columnKey.toString().split('-combinedkey-')[1]
      filters.split(',').forEach(item => {
        switch (sorter.order) {
          case 'ascend':
            sorts.push(`${item}`)
            break
          case 'descend':
            sorts.push(`-${item}`)
            break
          default:
            break
        }
      })
    } else {
      switch (sorter.order) {
        case 'ascend':
          sorts.push(`${sorter.columnKey || ''}`)
          break
        case 'descend':
          sorts.push(`-${sorter.columnKey || ''}`)
          break
        default:
          break
      }
    }
  }

  return sorts
}
