import get from 'lodash/get'
import React, { ReactElement } from 'react'

export const Row: React.FC<Props> = ({ title, item, fields, render }) => (
  <tr>
    <th className="section-name">{title}</th>
    {fields.map(field =>
      field?.isThead ? (
        // @ts-ignore
        <th key={field?.title} className="section-name">
          {field?.title}
        </th>
      ) : (
        <td key={field.name} className="view-table-cell" colSpan={field?.colspan || undefined}>
          {render(get(item, field.name))}
        </td>
      )
    )}
  </tr>
)

type Props = {
  title: string | ReactElement
  item: any
  fields: { name: string; colspan?: number; isThead?: boolean; title?: string | ReactElement }[]
  render: (value: any) => React.ReactNode
}
