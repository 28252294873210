import { Button } from 'aa_common/front-end/antd'
import { DATE_FORMAT_RFC } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { DatePicker } from 'components/molecules'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { DepartmentItem, LatestRecord } from 'store/settings/department/model'

import FormField from './FormField'
import { StyledFooter } from './styles'

const t = loadNs(['common', 'components/organisms/settings/area-form'])

interface Props {
  onHandleCloseAddValidToModal: any
  latestRecord: LatestRecord
  setLatestRecord: (latestRecord: LatestRecord) => void
}

const AddValidToModal = ({ onHandleCloseAddValidToModal, latestRecord, setLatestRecord }: Props) => {
  const { values, setFieldValue } = useFormikContext()
  const [data, setData] = useState<DepartmentItem>(get(values, ['data']))

  const handleChangeDate = (event: any) => {
    const dateValue = event && event.format(DATE_FORMAT_RFC)

    setData({ ...data, valid_to: dateValue })
  }

  useEffect(() => {
    const validFrom = get(data, 'valid_from')
    const validTo = get(data, 'valid_to')

    setFieldValue('valid_from_min', validFrom)

    if (data) {
      setFieldValue('valid_to', validTo || validFrom)
    }

    setLatestRecord({
      ...latestRecord,
      recordItem: data,
      validFromLatestRecord: data?.valid_from,
      validToLatestRecord: data?.valid_to,
    })
  }, [data]) // eslint-disable-line

  return (
    <>
      <FormField label={t('abolition_date')}>
        <DatePicker name="valid_to" minDateField="valid_from_min" afterChanged={handleChangeDate} />
      </FormField>
      <StyledFooter>
        <Button
          color="grey"
          style={{
            marginRight: '15px',
          }}
          size="custom"
          type="button"
          onClick={onHandleCloseAddValidToModal}
        >
          {t('actions.cancel')}
        </Button>
        <Button
          color="blue"
          size="custom"
          type="submit"
          style={{
            maxWidth: '70px',
            width: '100%',
          }}
        >
          {t('actions.ok')}
        </Button>
      </StyledFooter>
    </>
  )
}

export default memo(AddValidToModal)
