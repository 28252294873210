import classNames from 'classnames'
import { useField } from 'formik'
import React from 'react'

import CoreInfiniteScrollSelect from './core-infinite-scroll-select'
import { BaseInfiniteScrollSelectProps } from './model'

export const FormikInfiniteScrollSelect = ({
  name,
  onChange,
  className,
  ...componentProps
}: BaseInfiniteScrollSelectProps) => {
  const [field, meta, helpers] = useField(name)

  const { value, error } = meta
  const { setValue } = helpers

  return (
    <div className="infinite-scroll-select-formik">
      <CoreInfiniteScrollSelect
        {...componentProps}
        {...field}
        className={classNames(className, { error })}
        value={value}
        name={name}
        onChange={(value, selectedOption) => {
          setValue(value)
          onChange?.(value, selectedOption)
        }}
      />
    </div>
  )
}
