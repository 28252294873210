import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { RightArrowIcon, UnderlineText, VerticalLine } from 'aa_common/front-end/components'
import * as assetCategoryApi from 'api/app/assetCategory'
import { loadNs } from 'common/i18n-config'
import Input from 'components/atoms/v2/Input'
import Form from 'components/molecules/Form'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'
import { selectMasterData } from 'store/masterData/selectors'

import { ItemWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-type-modify-form'])
const t1 = loadNs('components/organisms/assets/asset-form')
const t2 = loadNs('common')

const { FormField, Group } = Form

const AssetModificationFormContent = ({ isBulkChangeEvent }: Props) => {
  const Width_UnderlineText = '240px'
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const { control, errors } = useFormContext()
  const masterData: any = useSelector(selectMasterData, isEqual) || {}
  const [resultSelectedAssetCategory] = useResource(assetCategoryApi.getAssetCategory)

  return (
    <Group className="form-wrapper" borderLess>
      <FormField title={t('asset_type_property_tax')}>
        <ItemWrapper>
          {!isBulkChangeEvent && (
            <>
              <UnderlineText width={Width_UnderlineText}>
                {asset?.asset_type_for_depreciable_property_tax?.name_jp}&nbsp;
              </UnderlineText>
              <RightArrowIcon style={{ margin: '0 25px' }} />
            </>
          )}
          <AutoCompleteSelect
            name="asset_type_for_depreciable_property_tax_code"
            disabled={resultSelectedAssetCategory.isLoading}
            items={masterData.asset_type_for_depreciable_property_tax}
            valueField="code"
            textField="name_jp"
            defaultValue={asset?.asset_type_for_depreciable_property_tax?.code}
            control={control}
          />
        </ItemWrapper>
      </FormField>
      <FormField title={t('asset_type_corporate_tax')}>
        <ItemWrapper>
          {!isBulkChangeEvent && (
            <>
              <UnderlineText width={Width_UnderlineText}>
                {asset?.asset_type_for_corporate_tax?.name_jp}&nbsp;
              </UnderlineText>
              <RightArrowIcon style={{ margin: '0 25px' }} />
            </>
          )}
          <AutoCompleteSelect
            name="asset_type_for_corporate_tax_code"
            items={masterData.asset_type_for_corporate_tax}
            disabled={resultSelectedAssetCategory.isLoading}
            valueField="code"
            textField="name_jp"
            defaultValue={asset?.asset_type_for_corporate_tax?.code}
            control={control}
          />
          <VerticalLine style={{ position: 'absolute', top: 29, left: -186 }} />
          <VerticalLine style={{ position: 'absolute', top: 29, left: 120 }} />
          {!isBulkChangeEvent && <VerticalLine style={{ position: 'absolute', top: 29, left: 434 }} />}
        </ItemWrapper>
      </FormField>
      <FormField title={t1('structure')}>
        <ItemWrapper>
          {!isBulkChangeEvent && (
            <>
              <UnderlineText width={Width_UnderlineText}>
                {asset?.asset_structure_for_corporate_tax}&nbsp;
              </UnderlineText>
              <RightArrowIcon style={{ margin: '0 25px' }} />
            </>
          )}
          <Input
            name="asset_structure_for_corporate_tax"
            placeholder={t2('placeholder.under_50')}
            disabled={resultSelectedAssetCategory.isLoading}
            maxLength={50}
            control={control}
            defaultValue={asset?.asset_structure_for_corporate_tax as string}
            error={get(errors, 'asset_structure_for_corporate_tax.message')}
          />
          <VerticalLine style={{ position: 'absolute', top: 27, left: -186 }} />
          <VerticalLine style={{ position: 'absolute', top: 27, left: 120 }} />
          {!isBulkChangeEvent && <VerticalLine style={{ position: 'absolute', top: 27, left: 434 }} />}
        </ItemWrapper>
      </FormField>
      <FormField title={t1('detail')}>
        <ItemWrapper>
          {!isBulkChangeEvent && (
            <>
              <UnderlineText width={Width_UnderlineText}>{asset?.asset_detail_for_corporate_tax}&nbsp;</UnderlineText>
              <RightArrowIcon style={{ margin: '0 25px' }} />
            </>
          )}
          <Input
            name="asset_detail_for_corporate_tax"
            disabled={resultSelectedAssetCategory.isLoading}
            placeholder={t2('placeholder.under_50')}
            maxLength={50}
            control={control}
            defaultValue={asset?.asset_detail_for_corporate_tax as string}
            error={get(errors, 'asset_detail_for_corporate_tax.message')}
          />
        </ItemWrapper>
      </FormField>
    </Group>
  )
}

type Props = {
  isBulkChangeEvent?: boolean
}
export default AssetModificationFormContent
