import { UploadFile } from 'antd/es/upload/interface'

export interface UploadFileProps extends UploadFile {
  filename?: string
  id?: string | number
}
export const convertPhotosSubmitPayload = (files: UploadFileProps[]) => {
  return files.map((file: any) => {
    return {
      id: file.id ? file.id : file.uid,
      url: file.url,
      thumbnail_url: file.thumbnail_url ? file.thumbnail_url : file.thumbUrl,
      filename: file.filename ? file.filename : file.fileName,
    }
  })
}

export const convertAttachmentsSubmitPayload = (files: UploadFileProps[]) => {
  return files.map(file => {
    return {
      id: file.id ? file.id : file.uid,
      url: file.url,
      mime_type: file.type,
      filename: file.filename ? file.filename : file.fileName,
    }
  })
}
