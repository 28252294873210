/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterError, PresenterOneAccountItemResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AccountItems<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List account items
   *
   * @tags account_items
   * @name AccountItemsList
   * @summary List the account items
   * @request GET:/account_items
   */
  accountItemsList = (
    query?: {
      'filter[ca_web_item_id]'?: number | string
      'filter[code]'?: string
      'filter[excise_id]'?: number | string
      'filter[name]'?: string
      'filter[search_key]'?: string
      'filter[smb_item_id]'?: number | string
      sort?: string[]
      'page[number]'?: number | string
      'page[size]'?: number | string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneAccountItemResponse, PresenterError>({
      path: `/account_items`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
