import styled from '@emotion/styled'

export const FormFields = styled.div`
  max-width: 1000px;

  .item {
    width: 100%;

    .label {
      min-width: 204px;
      max-width: 204px;
    }

    .content {
      .required {
        transform: translateX(-10px);
      }
    }

    .retire-required-label {
      top: 13px;
    }
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
`

export const ExpandableBox = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;

  .expandable-content {
    .item {
      .content {
        display: flex;
      }
    }
  }
`

export const Group = styled.div`
  display: flex;

  .expandable-content .item .content > & {
    > *:nth-child(n + 2) {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  .ant-drawer.expanded .expandable-content .item .content > & {
    > *:nth-child(n + 2) {
      opacity: 1;
    }
  }
`

export const UnderlineText = styled.div`
  width: 240px;
  color: #7c8291;
  border-bottom: 1px solid #d4d8dd;
  padding: 0px 7px 4px 7px;
  margin-right: 10px;
`

export const UnderlineSplitedText = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d4d8dd;
  color: #7c8291;
  padding: 0px 7px 4px 7px;
  margin-right: 10px;
`

export const DepreciationEndedMethodsWrapper = styled.div`
  .item {
    display: flex;
    align-items: center;
  }

  .required {
    top: 12px;
  }
`

export const EmptySection = styled.div`
  width: 240px;
`
