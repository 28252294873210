import { ConfirmModal } from 'aa_common/front-end/components'
import { CONFIRM_TYPES } from 'common/constants'
import i18n from 'i18n'
import React, {
  ComponentProps,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ConfirmModalSettings, RegularModalSettings } from './model'

interface Props {
  children: ReactNode
}

interface CancelablePromise<T> extends Promise<T> {
  cancel: () => void
}

interface IModalContext {
  confirm(settings: ConfirmModalSettings & Partial<ComponentProps<typeof ConfirmModal>>): CancelablePromise<any>
  open(settings: RegularModalSettings): Promise<any>
}
const ModalContext = createContext<any>({})

const ModalProvider = ({ children }: Props) => {
  const resolverRef = useRef<any>()
  const modalOptions = useRef<any>()

  const [isOpen, setIsOpen] = useState(false)

  const $close = (flag: boolean) => {
    setIsOpen(false)
    Promise.resolve().then(() => {
      if (resolverRef?.current) {
        resolverRef?.current(flag)
      }

      // clean-up
      modalOptions.current = null
      resolverRef.current = null
    })
  }

  const $open = useCallback(options => {
    const res = new Promise(resolve => {
      modalOptions.current = {
        ...options,
        type: options.type ?? CONFIRM_TYPES.nil,
        title: options.title || null,
      }
      setIsOpen(true)
      resolverRef.current = resolve
    }) as CancelablePromise<any>
    res.cancel = () => $close(false)

    return res
  }, [])

  const confirm = useCallback(options => $open({ ...options }), [$open])
  const contextValue = useMemo(() => ({ confirm }), [confirm])
  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <ConfirmModal
        title={i18n.t('components.AssetForm.messages.please_enter_the_title')}
        message={i18n.t('components.AssetForm.messages.please_enter_the_content')}
        visible={isOpen}
        onOK={() => $close(true)}
        onCancel={() => $close(false)}
        {...modalOptions.current}
      />
    </ModalContext.Provider>
  )
}

export default function useModal() {
  return useContext<IModalContext>(ModalContext)
}

export { ModalContext, ModalProvider }
