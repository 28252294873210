import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { ButtonHTMLType } from 'antd/lib/button/button'
import classNames from 'classnames'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { DefaultTooltipOverlay } from '../../styles/common-style'
import { StyledButton, TooltipWrapper } from './styles'

export interface ButtonProps {
  className?: string
  size?: 'shorter' | 'short' | 'long' | 'medium' | 'big' | 'custom'
  color?: 'blue' | 'grey'
  disabled?: boolean
  loading?: boolean
  type?: ButtonHTMLType
  name?: string
  tooltipSettings?: {
    placement?: TooltipPlacement
  }
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  showTooltipMessage?: string
  style?: React.CSSProperties
  btnRef?: React.RefObject<HTMLElement>
}

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type = 'button',
  size,
  color = 'blue',
  className,
  name,
  children,
  style,
  disabled = false,
  loading = false,
  showTooltipMessage,
  tooltipSettings,
  btnRef,
  onClick,
  ...rest
}) => {
  const coreButton = () => {
    return (
      <StyledButton
        className={classNames(className, {
          [`color-${color}`]: color !== undefined,
          [`size-${size}`]: size !== undefined,
        })}
        onClick={onClick}
        disabled={disabled}
        htmlType={type}
        loading={loading}
        name={name}
        style={style}
        ref={btnRef}
        {...rest}
      >
        {children}
      </StyledButton>
    )
  }

  return showTooltipMessage ? (
    <Tooltip
      placement={tooltipSettings?.placement}
      arrowPointAtCenter
      overlayClassName={classNames(DefaultTooltipOverlay, 'aa-button-tooltip')}
      title={showTooltipMessage}
    >
      <TooltipWrapper disabled={disabled} className={classNames('button-tooltip-wrapper', className)}>
        {coreButton()}
      </TooltipWrapper>
    </Tooltip>
  ) : (
    coreButton()
  )
}
