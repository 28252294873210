export const FEATURES_ID = {
  FIXED_ASSETS: 1,
  EXPORT: 2,
  START_USAGE: 3,
  MOVEMENT: 4,
  SWITCH_CATEGORY: 5,
  RETIRE: 6,
  SALE: 7,
  REPORT_ACQUISITION_TRANSFER: 8,
  REPORT_DEPRECIATION_RESULT: 11,
  REPORT_INCREASE_DECREASE: 12,
  REPORT_JOURNAL_LIST: 13,
  REPORT_UNDER_OVER_DEPRECIATION: 43,
  MONTHLY_CLOSING: 14,
  EXPORT_JOURNAL: 15, // 仕訳エクスポート
  SUBMIT_MONTHLY_CLOSING: 16, // 月次締めを実行
  MASTER_ACCOUNT_ITEMS: 17,
  MASTER_EXICES: 18,
  MASTER_DEPARTMENT: 19,
  MASTER_ASSET_CATEGORIES: 20,
  MASTER_AREAS: 21,
  MASTER_SUBMIT_DESTINATION: 22,
  MASTER_UNITS: 23,
  MASTER_CAUSES: 24,
  MASTER_TAGS: 25,
  SETTING_OFFICES: 26,
  SETTING_USERS: 27,
  SETTING_ROLES: 28,
  SETTING_ACCOUNTING_PERIOD: 29,
  SETTING_AUTO_JOURNAL: 30,
  SETTING_AUTO_GEN_SERIAL_NUMBER: 31,
  SETTING_LEDGER: 32,
  SETTING_EXCISE: 33,
  SETTING_ONBOARDING: 34,
  AUDIT_LOGS: 35,
  COMMENT: 36,
  RESET_ALL_DATA: 37, // 完全に初期化する
  DEPRECIATION_FIXED_ASSET: 38,
  REOPEN_MONTHLY_CLOSING: 39,
  JOURNAL_LINKAGE: 40,
  IMPAIRMENT: 41,
  TYPE_MODIFICATION: 42,
  SETTING_RECALCULATE: 44,
  DIVIDING: 45,
  CORPORATE_INCOME_TAX: 46,
} as const

export enum ACC_PLUS_JOURNAL_LINKAGE_STATUS {
  UNAPPROVED = 1,
  APPROVED = 2,
}

export const SAGA_TIME_DELAY = 5000

export enum RECALCULATE_STATUS {
  PROCESSING = 0,
  SUCCESS = 1,
  ERROR = 2,
}

export enum JOURNAL_TYPE {
  NOT_EXPORTED,
  EXPORTED,
}

export enum ASSET_EVENT_FIELD {
  ACCUMULATED_DEPRECIATION = 'ACCUMULATED_DEPRECIATION',
  LOSS_AND_GAIN = 'LOSS_AND_GAIN',
}

export enum DEFAULT_MEMORANDUM_VALUE {
  INTANGIBLE = 0,
  TANGIBLE = 1,
}

export enum CORPORATE_INCOME_TAX {
  TABLE_16_TYPE_1 = 1,
  TABLE_16_TYPE_2 = 2,
  TABLE_16_TYPE_4 = 4,
  TABLE_16_TYPE_6 = 6,
  TABLE_16_TYPE_7 = 7,
  TABLE_16_TYPE_8 = 8,
}

export enum CORPORATE_INCOME_TAX_NAME {
  TABLE_16_TYPE_1 = '一',
  TABLE_16_TYPE_2 = '二',
  TABLE_16_TYPE_4 = '四',
  TABLE_16_TYPE_6 = '六',
  TABLE_16_TYPE_7 = '七',
  TABLE_16_TYPE_8 = '八',
}

export const CORPORATE_INCOME_TAX_REPORT_DATA = {
  [CORPORATE_INCOME_TAX.TABLE_16_TYPE_1]: CORPORATE_INCOME_TAX_NAME.TABLE_16_TYPE_1,
  [CORPORATE_INCOME_TAX.TABLE_16_TYPE_2]: CORPORATE_INCOME_TAX_NAME.TABLE_16_TYPE_2,
  [CORPORATE_INCOME_TAX.TABLE_16_TYPE_4]: CORPORATE_INCOME_TAX_NAME.TABLE_16_TYPE_4,
  [CORPORATE_INCOME_TAX.TABLE_16_TYPE_6]: CORPORATE_INCOME_TAX_NAME.TABLE_16_TYPE_6,
  [CORPORATE_INCOME_TAX.TABLE_16_TYPE_7]: CORPORATE_INCOME_TAX_NAME.TABLE_16_TYPE_7,
  [CORPORATE_INCOME_TAX.TABLE_16_TYPE_8]: CORPORATE_INCOME_TAX_NAME.TABLE_16_TYPE_8,
}

export enum CONFIRM_TYPES {
  DELETE,
  CONFIRM,
  NOTICE,
  nil,
}
