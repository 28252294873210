import styled from '@emotion/styled'
import { DatePicker } from 'antd'

export const StyledDatepicker = styled(DatePicker)`
  &.ant-picker {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    height: 30px;
  }

  &.error {
    &.ant-picker {
      border-color: #dc3545;
      background-color: #ffeeeb;
    }
  }

  .ant-picker-input {
    & > input {
      text-align: center;
    }
  }
`
