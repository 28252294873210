import styled from '@emotion/styled/macro'
import { Layout } from 'antd'

export const AssetFilterFacetWrapper = styled.div<{ width: number }>`
  padding: 14px;
  transition: opacity 0.4s linear;
  max-height: calc(100vh - 140px);
  overflow: auto;
  flex: ${p => `0 0 ${p.width}px`};
  width: ${p => `${p.width}px`};
  min-width: ${p => `${p.width}px`};
  max-width: ${p => `${p.width}px`};

  opacity: 1;

  .ant-layout-sider-collapsed & {
    opacity: 0;
    pointer-events: none;
  }

  * {
    font-size: 14px;
  }
`

export const AssetFilterFacetCollapseWrapper = styled.div`
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
  padding: 14px 12px;
  transition: opacity 0.4s linear;

  .ant-layout-sider-collapsed & {
    opacity: 1;
    pointer-events: auto;
  }

  .collapse-item {
    margin-bottom: 20px;
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  * {
    font-size: 14px;
  }
`

export const AssetFilterFacetSider = styled(Layout.Sider)`
  min-height: calc(100vh - 91px);
  background: white;
  border-right: 1px solid #d4d8dd;
  overflow-x: hidden;

  .ant-layout-sider-trigger {
    background: white;
    color: #7c8291;
    border-right: 1px solid #d4d8dd;
  }
`

export const BoldLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
`

export const FilterField = styled.div`
  margin-bottom: 10px;

  &.form-filter-field {
    border-top: 1px solid #d4d8dd;
    ~ .form-filter-field {
      border-bottom: 1px solid #d4d8dd;
    }
    padding: 10px 0;
    margin-bottom: 0;
    .label-wrapper {
      display: flex;
      cursor: pointer;

      &:hover {
        ${BoldLabel} {
          color: #7c8291;
        }

        .filter-chevron {
          color: #7c8291;
        }
      }

      .filter-chevron {
        color: #d4d8dd;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
        transition: all 0.2s ease;
      }

      ${BoldLabel} {
        cursor: pointer;
        transition: all 0.2s ease;
        max-width: 88%;
      }
    }

    &:last-child {
      border-bottom: unset;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const TemplateSelectWrapper = styled.div`
  background-color: #eff1f4;
  border-radius: 4px;
  padding: 10px 7px;
  display: flex;

  .template-unselect {
    color: #7c8291;
    transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    margin-left: auto;

    &:hover {
      cursor: pointer;
      color: blue;
    }
  }

  .template-name {
    color: black;
    font-weight: 500;
    font-size: 14px;
  }
`
