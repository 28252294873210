/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client'

export class Ws<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description upgrade the request to websocket connection
   *
   * @tags ws
   * @name GetWs
   * @summary upgrade the request to websocket connection
   * @request GET:/ws
   */
  getWs = (params: RequestParams = {}) =>
    this.http.request<any, any>({
      path: `/ws`,
      method: 'GET',
      ...params,
    })
}
