import { AssetLogItemSelector, OperationLogsSelector } from './model'

export const selectOperationLogs = (state: any): OperationLogsSelector => {
  return state.getIn(['internalControl', 'operationLog', 'list'])
}

export const selectOperationLogItem = (state: any): AssetLogItemSelector => {
  return {
    data: state.getIn(['internalControl', 'operationLog', 'item', 'data']),
    isLoading: state.getIn(['internalControl', 'operationLog', 'item', 'loading']),
  }
}
