import { Button, Spin, Tooltip } from 'aa_common/front-end/antd'
import * as officeAPI from 'api/app/office'
import { CSRF_TOKEN_KEY } from 'common/constants'
import { buildNavisNewTenantURL } from 'common/helpers'
import { RectangleForSession } from 'components/molecules'
import { SessionTemplate } from 'components/templates'
import i18n from 'i18n'
import useResource from 'lib/hooks/useResource'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation, setAppMessage } from 'store/app/actions'
import { getCurrentUserSelector } from 'store/session/selectors'

const OfficeNewPage = () => {
  const [creatableOfficeResponse, getCreatableOffices] = useResource(officeAPI.creatableList)
  const [postOfficeResponse, postOffice] = useResource(officeAPI.post)
  const currentUser = useSelector(getCurrentUserSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    getCreatableOffices()
  }, [getCreatableOffices])

  useEffect(() => {
    document.title = i18n.t('common.page_title')
  }, [])

  const handleNew = (officeId: number) => {
    postOffice(officeId)
  }

  const handleCreateInNavis = () => {
    window.location.href = buildNavisNewTenantURL(currentUser ? currentUser.email : '')
  }

  useEffect(() => {
    if (creatableOfficeResponse.status && creatableOfficeResponse.status >= 400) {
      dispatch(setAppMessage({ type: 'failure', content: i18n.t('common.messages.unexpected_error_occured') }))
    }
    if (postOfficeResponse.status && postOfficeResponse.status >= 400) {
      dispatch(setAppMessage({ type: 'failure', content: i18n.t('common.messages.unexpected_error_occured') }))
    }
    if (creatableOfficeResponse.status === 200 && creatableOfficeResponse.data.length === 0) {
      window.location.href = buildNavisNewTenantURL(currentUser ? currentUser.email : '')
    }
    if (postOfficeResponse.status && postOfficeResponse.status === 201) {
      localStorage.setItem(CSRF_TOKEN_KEY, postOfficeResponse.data.csrf_token)

      // TODO: AA-1383: redirect to onboarding page
      dispatch(changeLocation('/accounting-periods/create'))
    }
  })

  const isLoading = creatableOfficeResponse.isLoading || postOfficeResponse.isLoading

  return (
    <SessionTemplate>
      <RectangleForSession>
        <Spin loading={isLoading}>
          <div style={{ fontSize: '16px', lineHeight: '16px', padding: '17px 0 19px 0', fontWeight: 700 }}>
            {i18n.t('pages.office.New.title')}
          </div>
          {creatableOfficeResponse.data &&
            creatableOfficeResponse.data.map((office: any) => {
              return (
                <div style={{ borderTop: '1px solid #DCE0E6', height: '47px', lineHeight: '46px' }} key={office.id}>
                  <div style={{ position: 'absolute', marginLeft: '15px' }}>{office.name}</div>
                  <div style={{ position: 'absolute', marginLeft: '425px' }}>
                    {office.identification_code.replace(/\d{4}(?=.)/g, '$&-')}
                  </div>
                  <div style={{ position: 'absolute', marginLeft: '503px' }}>
                    {office.is_exist ? (
                      <Tooltip
                        tooltipStyles={{ cursor: 'not-allowed' }}
                        content={i18n.t('pages.office.New.already_in_use')}
                        placement="top"
                      >
                        <Button disabled size="custom" color="grey" style={{ width: '70px', pointerEvents: 'none' }}>
                          {i18n.t('pages.office.New.select')}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        disabled={isLoading}
                        onClick={() => {
                          handleNew(office.id)
                        }}
                        size="custom"
                        color="grey"
                        style={{ width: '70px' }}
                      >
                        {i18n.t('pages.office.New.select')}
                      </Button>
                    )}
                  </div>
                </div>
              )
            })}
          <div style={{ borderTop: '1px solid #DCE0E6', height: '50px', padding: '10px 0 10px 0' }}>
            <Button
              onClick={handleCreateInNavis}
              disabled={isLoading}
              size="custom"
              style={{ fontSize: '13px', width: '163px' }}
            >
              {i18n.t('pages.office.New.create_new')}
            </Button>
          </div>
        </Spin>
      </RectangleForSession>
    </SessionTemplate>
  )
}

export default OfficeNewPage
