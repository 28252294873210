import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const BreadcrumbWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
  vertical-align: middle;
`

export const DefaultBreadcrumbClass = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: inherit;
`

export const BackWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
`

export const BackButtonClass = css`
  margin-right: 15px;
  min-width: 50px;
  padding: 0;
  font-size: 13px;

  svg {
    font-size: 14px;
    margin-top: 4px;
  }

  span {
    margin-left: 7px;
    color: #3b7de9;
  }
`
