import { updateObjectValues } from 'common/helpers/object-helper'
import { FORM_MODES } from 'common/models'
import { BasicForm } from 'components/molecules'
import { get, isEqual } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDepartmentsOnCloudRequest } from 'store/settings/department/actions'
import { selectDepartmentOnCloud } from 'store/settings/department/selectors'

import Form from './Form'
import { ValueFields } from './helpers'
import { Wrapper } from './styles'

const initialValues: ValueFields = {
  code: '',
  name: '',
  prepress_category_code: null,
  search_key: '',
  valid_from: moment(),
  valid_to: null,
  ref_id: null,
}

type Props = {
  errors?: any[]
  type?: FORM_MODES
  values?: any
  loading?: boolean
  prepressCategories: any[]
  onSubmit: (values: any) => void
}

const DepartmentForm = ({
  loading = false,
  values,
  type = FORM_MODES.NEW,
  errors,
  prepressCategories,
  onSubmit,
}: Props) => {
  const dispatch = useDispatch()

  const [formValues, setFormValues] = useState<ValueFields | null>(null)

  const { list: listDepartmentOnCloud } = useSelector(selectDepartmentOnCloud, isEqual)

  useEffect(() => {
    dispatch(fetchDepartmentsOnCloudRequest())
  }, []) // eslint-disable-line

  useMemo(() => {
    if (type === FORM_MODES.NEW) {
      setFormValues(initialValues)
    } else if (values) {
      const objectValues = updateObjectValues(initialValues, values)

      if (type === FORM_MODES.COPY) {
        setFormValues({
          ...objectValues,
          code: '',
          prepress_category_code: get(values, ['prepress_category', 'code']),
          ref_id: get(values, 'ref_id'),
        })
      }
    }
  }, [values, type])

  return (
    <Wrapper>
      <BasicForm initialValues={formValues} onSubmit={onSubmit} errors={errors} loading={loading}>
        <Form prepressCategories={prepressCategories} departmentOnCloud={listDepartmentOnCloud} />
      </BasicForm>
    </Wrapper>
  )
}

export default DepartmentForm
