import { ComplexTable } from 'aa_common/front-end/antd'
import { Asset } from 'common/models'
import React, { useMemo } from 'react'

import { buildEventHistoryColumns } from './settings'
import { Wrapper } from './styles'

interface EventHistoryProps {
  item: Asset
}

const EventHistory = ({ item }: EventHistoryProps) => {
  const data = useMemo(() => [item], [item])

  return (
    <Wrapper>
      {item && (
        <ComplexTable columns={buildEventHistoryColumns()} actions={false} enableSelectRows={false} data={data} />
      )}
    </Wrapper>
  )
}

export default EventHistory
