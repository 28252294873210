import styled from '@emotion/styled/macro'
import { Table } from 'antd'

export const StyledComplexTable = styled(Table)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 10px;
  }

  .ant-table {
    border: 1px solid #d4d8dd;
    border-radius: 4px;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 11px;
    &.active {
      color: #333333;
    }
  }

  .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.38em;
  }

  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .ant-table-header {
      border-radius: 4px;
      table {
        border-radius: 4px 4px 0 0;
      }

      .ant-table-head > tr {
        > th {
          &:first-of-type {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }
  }

  .ant-table-body {
    margin-bottom: 1px;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-row {
    .ant-table-cell {
      background: white;

      &.table-actions {
        padding: 0;
      }
    }

    &:last-child {
      > .ant-table-cell {
        border: none;
      }
    }

    &:hover {
      > .ant-table-cell {
        background-color: #f5f5f5;
      }
    }

    &.ant-table-column-sort {
      background-color: #ffffff;
    }

    &.ant-table-row-selected {
      > .ant-table-cell,
      &:hover > .ant-table-cell {
        background-color: #eaf2fd;
      }
    }
  }

  .ant-table-tbody > tr > td {
    font-size: 13px;
  }

  .ant-table-column-sorters {
    padding: 0;
  }
  .error-row {
    .ant-table-cell {
      background-color: #ffeeeb !important;
    }
  }
`
