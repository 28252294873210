export const FETCH_ASSET_LIST_REQUEST = 'FETCH_ASSET_LIST_REQUEST'
export const FETCH_ASSET_LIST_SUCCESS = 'FETCH_ASSET_LIST_SUCCESS'
export const FETCH_ASSET_LIST_FAILURE = 'FETCH_ASSET_LIST_FAILURE'
export const FETCH_ASSET_EVENT_HISTORY_REQUEST = 'FETCH_ASSET_EVENT_HISTORY_REQUEST'
export const FETCH_ASSET_EVENT_HISTORY_SUCCESS = 'FETCH_ASSET_EVENT_HISTORY_SUCCESS'
export const FETCH_ASSET_EVENT_HISTORY_FAILURE = 'FETCH_ASSET_EVENT_HISTORY_FAILURE'
export const CREATE_ASSET_ITEM_REQUEST = 'CREATE_ASSET_ITEM_REQUEST'
export const CREATE_ASSET_ITEM_SUCCESS = 'CREATE_ASSET_ITEM_SUCCESS'
export const FETCH_ASSET_ITEM_REQUEST = 'FETCH_ASSET_ITEM_REQUEST'
export const FETCH_ASSET_ITEM_SUCCESS = 'FETCH_ASSET_ITEM_SUCCESS'

export const FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST = 'FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST'
export const FETCH_ASSET_ITEM_BY_VALID_AT_SUCCESS = 'FETCH_ASSET_ITEM_BY_VALID_AT_SUCCESS'
export const FETCH_ASSET_ITEM_BY_VALID_AT_FAILURE = 'FETCH_ASSET_ITEM_BY_VALID_AT_FAILURE'

export const UPDATE_ASSET_ITEM_REQUEST = 'UPDATE_ASSET_ITEM_REQUEST'
export const UPDATE_ASSET_ITEM_SUCCESS = 'UPDATE_ASSET_ITEM_SUCCESS'
export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST'
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS'
export const DELETE_ASSET_ITEM_REQUEST = 'DELETE_ASSET_ITEM_REQUEST'
export const DELETE_ASSET_ITEM_SUCCESS = 'DELETE_ASSET_ITEM_SUCCESS'
export const DELETE_ASSET_ITEM_FAILURE = 'DELETE_ASSET_ITEM_FAILURE'
export const DELETE_UNUSED_ASSET_REQUEST = 'DELETE_UNUSED_ASSET_REQUEST'
export const DELETE_UNUSED_ASSET_SUCCESS = 'DELETE_UNUSED_ASSET_SUCCESS'
export const UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST'
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS'
export const HANDLE_SUBMIT_ASSET_ITEM_FAILURE = 'HANDLE_SUBMIT_ASSET_ITEM_FAILURE'
export const SUBMIT_ASSET_ITEM_FAILURE = 'SUBMIT_ASSET_ITEM_FAILURE'
export const CLEANUP_ASSET = 'CLEANUP_ASSET'
export const CLEANUP_ASSET_COLLECTION_LIST = 'CLEANUP_ASSET_COLLECTION_LIST'
export const CLEANUP_ASSET_COLLECTION_ITEM = 'CLEANUP_ASSET_COLLECTION_ITEM'
export const CLEANUP_ASSET_ITEM_BY_VALID_AT = 'CLEANUP_ASSET_ITEM_BY_VALID_AT'

export const CREATE_ASSET_EVENT_USAGE_REQUEST = 'CREATE_ASSET_EVENT_USAGE_REQUEST'
export const CREATE_ASSET_EVENT_USAGE_SUCCESS = 'CREATE_ASSET_EVENT_USAGE_SUCCESS'
export const CREATE_ASSET_EVENT_RETIREMENT_REQUEST = 'CREATE_ASSET_EVENT_RETIREMENT_REQUEST'
export const CREATE_ASSET_EVENT_RETIREMENT_SUCCESS = 'CREATE_ASSET_EVENT_RETIREMENT_SUCCESS'
export const CREATE_ASSET_EVENT_MOVEMENT_REQUEST = 'CREATE_ASSET_EVENT_MOVEMENT_REQUEST'
export const CREATE_ASSET_EVENT_MOVEMENT_SUCCESS = 'CREATE_ASSET_EVENT_MOVEMENT_SUCCESS'
export const CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST = 'CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST'
export const CREATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS = 'CREATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS'
export const CREATE_ASSET_EVENT_SELL_REQUEST = 'CREATE_ASSET_EVENT_SELL_REQUEST'
export const CREATE_ASSET_EVENT_SELL_SUCCESS = 'CREATE_ASSET_EVENT_SELL_SUCCESS'
export const UPDATE_ASSET_EVENT_USAGE_REQUEST = 'UPDATE_ASSET_EVENT_USAGE_REQUEST'
export const UPDATE_ASSET_EVENT_USAGE_SUCCESS = 'UPDATE_ASSET_EVENT_USAGE_SUCCESS'
export const UPDATE_ASSET_EVENT_RETIREMENT_REQUEST = 'UPDATE_ASSET_EVENT_RETIREMENT_REQUEST'
export const UPDATE_ASSET_EVENT_RETIREMENT_SUCCESS = 'UPDATE_ASSET_EVENT_RETIREMENT_SUCCESS'
export const UPDATE_ASSET_EVENT_MOVEMENT_REQUEST = 'UPDATE_ASSET_EVENT_MOVEMENT_REQUEST'
export const UPDATE_ASSET_EVENT_MOVEMENT_SUCCESS = 'UPDATE_ASSET_EVENT_MOVEMENT_SUCCESS'
export const UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST = 'UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST'
export const UPDATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS = 'UPDATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS'
export const UPDATE_ASSET_EVENT_SELL_REQUEST = 'UPDATE_ASSET_EVENT_SELL_REQUEST'
export const UPDATE_ASSET_EVENT_SELL_SUCCESS = 'UPDATE_ASSET_EVENT_SELL_SUCCESS'
export const SUBMIT_ASSET_EVENT_FAILURE = 'SUBMIT_ASSET_EVENT_FAILURE'
export const HANDLE_SUBMIT_ASSET_EVENT_FAILURE = 'HANDLE_SUBMIT_ASSET_EVENT_FAILURE'
export const SUBMIT_IMPAIRMENT_REQUEST = 'SUBMIT_IMPAIRMENT_REQUEST'
export const SUBMIT_IMPAIRMENT_SUCCESS = 'SUBMIT_IMPAIRMENT_SUCCESS'
export const SUBMIT_IMPAIRMENT_FAILURE = 'SUBMIT_IMPAIRMENT_FAILURE'
export const SUBMIT_MODIFIED_ASSET_TYPE_REQUEST = 'SUBMIT_MODIFIED_ASSET_TYPE_REQUEST'
export const SUBMIT_MODIFIED_ASSET_TYPE_SUCCESS = 'SUBMIT_MODIFIED_ASSET_TYPE_SUCCESS'
export const SUBMIT_MODIFIED_ASSET_TYPE_FAILURE = 'SUBMIT_MODIFIED_ASSET_TYPE_FAILURE'

export const UPDATE_COMMENT_COUNT_REQUEST = 'UPDATE_COMMENT_COUNT_REQUEST'
export const UPDATE_COMMENT_COUNT_SUCCESS = 'UPDATE_COMMENT_COUNT_SUCCESS'
export const UPDATE_COMMENT_COUNT_FAILURE = 'UPDATE_COMMENT_COUNT_FAILURE'
export const EXPORT_ASSET_REQUEST = 'EXPORT_ASSET_REQUEST'
export const EXPORT_ASSET_SUCCESS = 'EXPORT_ASSET_SUCCESS'
export const EXPORT_ASSET_FAILURE = 'EXPORT_ASSET_FAILURE'
export const SET_IS_CREATED_SUCCESS_ASSET_EVENT = 'SET_IS_CREATED_SUCCESS_ASSET_EVENT'
export const CLEAR_ASSET_EVENT_ERRORS = 'CLEAR_ASSET_EVENT_ERRORS'
export const SET_SHOW_MEMORANDUM_VALUE_ERROR_MESSAGE = 'SET_SHOW_MEMORANDUM_VALUE_ERROR_MESSAGE'

export const UPLOAD_PHOTO = 'UPLOAD_PHOTO'
