export default function getObject(array: any[], key: string, value: any): any {
  let object
  array.some(function iter(item) {
    if (item[key] === value) {
      object = item
      return true
    }
    return Array.isArray(item.children) && item.children.some(iter)
  })
  return object
}
