import { faExternalLinkAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spin, Tooltip } from 'aa_common/front-end'
import { BasicModal, LinkLabel } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import * as monthlyClosingApi from 'api/app/monthlyClosing'
import { CONFIRM_TYPES, DATETIME_NO_DOT_FORMAT, FEATURES_ID, URL_REOPEN_MONTHLY_GUIDE } from 'common/constants'
import { formatDate, formatMonth } from 'common/helpers'
import * as ledgerHelper from 'common/helpers/ledger-helper'
import { loadNs } from 'common/i18n-config'
import { LedgerSetting, MONTHLY_CLOSING_EXPORT_STATUS, MONTHLY_CLOSING_LINKED_STATUS, TermMonth } from 'common/models'
import { useCheckAgentLogin } from 'hooks/use-check-agent-login'
import useModal from 'hooks/useModalHook'
import parser from 'html-react-parser'
import useResource from 'lib/hooks/useResource'
import useUserPermission from 'lib/hooks/useUserPermission'
import { find, get, isEqual, last } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  fetchMonthlyClosingCurrent,
  fetchMonthlyClosingSummaryAssetEvents,
  reopenMonthlyClosing,
  submitCloseMonthlyClosing,
} from 'store/accounting/monthlyClosing/actions'
import {
  selectMonthlyClosingCurrent,
  selectMonthlyClosingSummaryAssetEvents,
} from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import { selectLoadingApp } from 'store/app/selectors'
import { fetchTermPeriods } from 'store/settings/accountingPeriod/actions'
import { selectTermsList } from 'store/settings/accountingPeriod/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { BoxContent, BoxFooter, BoxItem, BoxWrapper, Title, Wrapper } from './styles'

const SPACE = '　'
const t = loadNs(['common', 'components/organisms/accounting/monthly-closing-view'])
const showNoPermissionMessage = (permission: boolean) => ifElse(!permission, t('actions.no_permission'), undefined)

type Props = {
  isClickSelectTerm: boolean
}

const MonthlyClosingView = ({ isClickSelectTerm }: Props) => {
  const dispatch = useDispatch()
  const modal = useModal()
  const history = useHistory()
  const { location } = history
  const [isShowHistory, setIsShowHistory] = useState<boolean>(false)
  const [prevYear, setPrevYear] = useState<number | null>(null)
  const [prevMonth, setPrevMonth] = useState<number | null>(null)
  const loadingApp = useSelector(selectLoadingApp, isEqual)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent, isEqual)
  const summaryAssetEvents = useSelector(selectMonthlyClosingSummaryAssetEvents, isEqual)
  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const terms = useSelector(selectTermsList, isEqual)
  const [{ data: histories, isLoading: isHistoryLoading }, getMonthlyClosingHistory] = useResource(
    monthlyClosingApi.getMonthlyClosingHistory
  )

  const {
    permissions: { isExecute },
  } = useUserPermission(FEATURES_ID.SUBMIT_MONTHLY_CLOSING)
  const {
    permissions: { isExecute: hasReopenPermission },
  } = useUserPermission(FEATURES_ID.REOPEN_MONTHLY_CLOSING)
  const disableReopen = monthlyClosingCurrent?.is_first_monthly_closing || false

  const isAgentLogin = useCheckAgentLogin()

  useEffect(() => {
    if (!ledgerSettings) {
      dispatch(fetchLedgerSettingsList())
    }
  }, [ledgerSettings]) //eslint-disable-line

  const renderMonthlyClosingStatus = () => {
    const exportStatus = monthlyClosingCurrent?.exported_status.code
    const linkageStatus = monthlyClosingCurrent?.linked_status.code

    const isDone =
      exportStatus === MONTHLY_CLOSING_EXPORT_STATUS.EXPORTED || linkageStatus === MONTHLY_CLOSING_LINKED_STATUS.LINKED
    if (isDone) return t('status.done')

    const isNeedImplementAgain =
      exportStatus === MONTHLY_CLOSING_EXPORT_STATUS.NEED_TO_EXPORT_AGAIN ||
      linkageStatus === MONTHLY_CLOSING_LINKED_STATUS.NEED_TO_LINK_AGAIN
    if (isNeedImplementAgain) return t('status.implement_again')

    const isNotDone =
      exportStatus === MONTHLY_CLOSING_EXPORT_STATUS.NOT_EXPORTED ||
      linkageStatus === MONTHLY_CLOSING_LINKED_STATUS.NOT_EXPORTED
    if (isNotDone) return t('status.not_done')
  }

  const confirmSubmit = () => {
    const isFirstTime = monthlyClosingCurrent?.is_first_monthly_closing
    const isExported = monthlyClosingCurrent?.exported_status?.code === MONTHLY_CLOSING_EXPORT_STATUS.EXPORTED
    const isSendToAccPlus = monthlyClosingCurrent?.linked_status.code === MONTHLY_CLOSING_LINKED_STATUS.LINKED

    const isAllowSubmit = isSendToAccPlus || isExported

    const year = monthlyClosingCurrent?.from_term_month?.term?.year
    const month = formatMonth(monthlyClosingCurrent.from_term_month.month)
    const text = ifElse(isFirstTime, 'firstTime', 'notFirstTime')
    const context = ifElse(isAllowSubmit, text, 'notExported')
    const i18nOpt = { year, month, context }

    const submitFn = isAllowSubmit
      ? (isOKClick: boolean) => {
          isOKClick && dispatch(submitCloseMonthlyClosing())
        }
      : null

    modal
      .confirm({
        title: t('modal.title', i18nOpt),
        message: t('modal.message', i18nOpt),
        type: ifElse(isAllowSubmit, CONFIRM_TYPES.CONFIRM, CONFIRM_TYPES.NOTICE),
        closable: true,
        cancelText: t('close'),
        contentStyle: { padding: '0 15px' },
        messageStyle: { whiteSpace: 'pre-line', margin: '15px 0' },
      })
      .then(submitFn)
  }

  const handleReopen = () => {
    const currentYear = monthlyClosingCurrent.from_term_month.term.year
    const currentMonth = monthlyClosingCurrent.from_term_month.month

    const showCantReopenModal = (assetCount: number) => {
      const title = t('cant_reopen_confirm_modal_title', {
        year: prevYear,
        month: formatMonth(prevMonth),
      })
      const message = parser(
        t('cant_reopen_confirm_modal_content', {
          year: currentYear,
          month: formatMonth(currentMonth),
          assetCount,
        })
      )
      modal
        .confirm({
          title,
          message,
          type: CONFIRM_TYPES.CONFIRM,
          closable: true,
          cancelText: t('cant_reopen_confirm_modal_cancel_button'),
          okText: t('cant_reopen_confirm_modal_ok_button'),
        })
        .then(isOK => {
          if (isOK) {
            history.push('/assets?is_retroactive=true')
          }
        })
    }

    Promise.resolve(dispatch(reopenMonthlyClosing(true))).then((payload: any) => {
      if (payload.assetCount === 0) {
        const title = t('reopen_confirm_modal_title', {
          year: prevYear,
          month: formatMonth(prevMonth),
        })
        const message = t('reopen_confirm_modal_content', {
          year: prevYear,
          month: formatMonth(prevMonth),
        })
        modal
          .confirm({
            title,
            message,
            type: CONFIRM_TYPES.CONFIRM,
            closable: true,
            cancelText: t('reopen_confirm_modal_cancel_button'),
            okText: t('reopen_confirm_modal_ok_button'),
            okStyle: {
              background: 'linear-gradient(180deg, #FFFFFF 0%, #EFF1F4 100%)',
              border: '1px solid #D4D8DD',
              color: '#EC4949',
            },
          })
          .then(isOK => {
            if (isOK) {
              Promise.resolve(dispatch(reopenMonthlyClosing())).then((payload: any) => {
                if (payload.assetCount === 0) {
                  window.location.reload()
                } else {
                  showCantReopenModal(payload.assetCount)
                }
              })
            }
          })
      } else {
        showCantReopenModal(payload.assetCount)
      }
    })
  }

  const handleClickConfirmAssetEvents = () => {
    dispatch(changeLocation('/accounting/monthly-closing/asset-events'))
  }

  const handleClickConfirmDepreciation = () => {
    dispatch(changeLocation('/accounting/monthly-closing/amount-summary'))
  }

  const handleClickJournals = () => {
    if (isAgentLogin) return
    dispatch(changeLocation('/accounting/monthly-closing/journals'))
  }

  const handleClickConfirmIncreaseDecreaseSummary = () => {
    dispatch(changeLocation('/accounting/monthly-closing/increase-decrease-report'))
  }

  const footer = (
    <Button color="grey" size="short" onClick={() => setIsShowHistory(false)}>
      {t('actions.close')}
    </Button>
  )

  useEffect(() => {
    if (monthlyClosingCurrent) {
      dispatch(fetchMonthlyClosingSummaryAssetEvents(monthlyClosingCurrent.id))
    }
  }, [dispatch, monthlyClosingCurrent])

  useEffect(() => {
    if (monthlyClosingCurrent && isShowHistory) {
      getMonthlyClosingHistory()
    }
  }, [getMonthlyClosingHistory, isShowHistory, monthlyClosingCurrent])

  useEffect(() => {
    dispatch(fetchMonthlyClosingCurrent())
  }, []) // eslint-disable-line

  useEffect(() => {
    if (monthlyClosingCurrent && terms?.data) {
      const { start_date: startDate, month: currentMonth, term } = monthlyClosingCurrent.from_term_month || {}

      if (!monthlyClosingCurrent.is_first_monthly_closing) {
        const isFirstMonthInTerm = term.start_date === startDate
        const termID = ifElse(isFirstMonthInTerm, find(terms.data, t => t.period === term.period - 1)?.id, term.id)

        if (!termID) return

        Promise.resolve(dispatch(fetchTermPeriods(termID)))
          .then((payload: any) => {
            const { term_months }: { term_months: TermMonth[] } = payload
            if (isFirstMonthInTerm) return last(term_months)

            const currentTermMonth = find(
              term_months,
              termMonth => termMonth.term_id === term.id && termMonth.month === currentMonth
            )
            const previousTermMonth = find(term_months, termMonth =>
              currentTermMonth ? termMonth.month_serial_number === currentTermMonth.month_serial_number - 1 : false
            )
            const yearOfPrevTermMonth = find(terms?.data || [], termYear => termYear.id === previousTermMonth?.term_id)

            return previousTermMonth
              ? {
                  ...previousTermMonth,
                  term: yearOfPrevTermMonth,
                }
              : null
          })
          .then((prevTermMonth: any) => {
            setPrevYear(prevTermMonth?.term?.year || null)
            setPrevMonth(prevTermMonth?.month || null)
          })
      }
    }
  }, [dispatch, monthlyClosingCurrent, terms])

  const getLastMonthOfFiscalYear = () => {
    const termMonths = get(monthlyClosingCurrent, 'monthly_closing_setting.term.term_months')
    return termMonths?.reduce((a: any, b: any) => (a.month_serial_number >= b.month_serial_number ? a : b), {})
      .month_serial_number
  }

  const tooltipContent = (
    <>
      {parser(t('back_to_previous_month_tooltip'))}
      <a
        href={URL_REOPEN_MONTHLY_GUIDE}
        target="_blank"
        rel="noreferrer"
        style={{ color: '#fff', textDecoration: 'underline' }}
      >
        {t('actions.click_here_for_details')}
      </a>
      &nbsp;
      <FontAwesomeIcon icon={faExternalLinkAlt} style={{ fontSize: 14, color: '#fff' }} />
    </>
  )

  const isHaveBothTwoLedgers = ledgerHelper.isHaveBothTwoLedgers(ledgerSettings)
  const lastMonthOfFiscalYear = getLastMonthOfFiscalYear()
  const isLastMonthOfFiscalYear = monthlyClosingCurrent?.from_term_month?.month_serial_number === lastMonthOfFiscalYear

  return (
    <Spin loading={loadingApp}>
      <Wrapper>
        <Title>
          <div style={{ display: 'flex' }}>
            {monthlyClosingCurrent && (
              <label>
                {t('month_in_process', {
                  year: monthlyClosingCurrent.from_term_month.term.year,
                  month: formatMonth(monthlyClosingCurrent.from_term_month.month),
                })}
              </label>
            )}
            <div className="processing-month-action">
              <div className="reopen-monthly-closing">
                <LinkLabel
                  className="link"
                  showTooltipMessage={ifElse(hasReopenPermission, '', t('messages.no_permissions_error_occured'))}
                  disabled={!hasReopenPermission || disableReopen}
                  onClick={() => handleReopen()}
                >
                  {t('back_to_previous_month')}
                </LinkLabel>
                <Tooltip tooltipStyles={{ marginLeft: 5 }} placement="top" content={tooltipContent}>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    style={{ fontSize: 16, color: '#333333', cursor: 'pointer' }}
                  />
                </Tooltip>
              </div>
              <div style={{ display: 'inline-flex' }}>
                <LinkLabel className="link" onClick={() => setIsShowHistory(true)}>
                  {t('actions.view_history')}
                </LinkLabel>
              </div>
            </div>
          </div>
        </Title>
        <BoxWrapper>
          <BoxContent>
            <BoxItem>
              <div className="title">
                {monthlyClosingCurrent && (
                  <div>
                    {t('acquisition_and_transfer_month', {
                      year: monthlyClosingCurrent.from_term_month.year,
                      month: formatMonth(monthlyClosingCurrent.from_term_month.month),
                    })}
                  </div>
                )}
                <LinkLabel onClick={handleClickConfirmAssetEvents}>{t('actions.confirm')}</LinkLabel>
              </div>
              <div className="body">
                {summaryAssetEvents && (
                  <ul>
                    <li>
                      {t('acquire_event_amount_display', {
                        amount: summaryAssetEvents.acquire_amount,
                      })}
                    </li>
                    <li>
                      {t('retire_event_amount_display', {
                        amount: summaryAssetEvents.retire_amount,
                      })}
                    </li>
                    <li>
                      {t('sale_event_amount_display', {
                        amount: summaryAssetEvents.sell_amount,
                      })}
                    </li>
                    <li>
                      {t('move_event_amount_display', {
                        amount: summaryAssetEvents.move_amount,
                      })}
                    </li>
                    <li>
                      {t('impairment_event_amount_display', {
                        amount: summaryAssetEvents.impair_amount,
                      })}
                    </li>
                    {/* will use in future */}
                    {/* <li>{t('switch_event_amount_display', { amount: summaryAssetEvents.switch_amount })}</li> */}
                  </ul>
                )}
              </div>
            </BoxItem>
            <BoxItem>
              <div className="title">
                <div>{t('depreciation_table')}</div>
                <LinkLabel onClick={handleClickConfirmDepreciation}>{t('actions.confirm')}</LinkLabel>
              </div>
            </BoxItem>
            <BoxItem>
              <div className="title">
                <div>{t('increase_decrease_summary')}</div>
                <LinkLabel onClick={handleClickConfirmIncreaseDecreaseSummary}>{t('actions.confirm')}</LinkLabel>
              </div>
            </BoxItem>
            <BoxItem>
              <div className="title">
                <div>{t('journal')}</div>
                <LinkLabel disabled={isAgentLogin} onClick={handleClickJournals}>
                  {t('actions.confirm')}
                </LinkLabel>
              </div>
              <div className="body" data-testid="test-render-status">
                <ul>
                  <li>
                    {t('status.label_name')}：{renderMonthlyClosingStatus()}&nbsp;
                    <span className="export-date">
                      {monthlyClosingCurrent && formatDate(monthlyClosingCurrent.exported_at)}
                    </span>
                  </li>
                </ul>
              </div>
            </BoxItem>
            {isHaveBothTwoLedgers && isLastMonthOfFiscalYear && (
              <BoxItem>
                <div className="title">
                  <div>{t('depreciation_excess_insufficiency_amount')}</div>
                  <LinkLabel.RedirectLink
                    redirectTo={{
                      pathname: '/depreciation/under-over-depreciation-report',
                      state: {
                        fromPath: location.pathname,
                      },
                    }}
                  >
                    {t('actions.confirm')}
                  </LinkLabel.RedirectLink>
                </div>
              </BoxItem>
            )}
          </BoxContent>
          <BoxFooter>
            <Button
              showTooltipMessage={showNoPermissionMessage(isExecute)}
              disabled={!isExecute || isClickSelectTerm}
              onClick={confirmSubmit}
              data-testid="test-submit-button"
            >
              {t(ifElse(!isLastMonthOfFiscalYear, 'perform_closing_month', 'perform_closing_year'))}
            </Button>
          </BoxFooter>
        </BoxWrapper>
        <BasicModal
          title={t('history')}
          isShow={isShowHistory}
          footer={footer}
          onCancel={() => setIsShowHistory(false)}
        >
          <>
            <Spin loading={isHistoryLoading} />
            {(histories || []).map((history: any) => {
              const createdAt = moment(history.created_at).format(`${DATETIME_NO_DOT_FORMAT}`)
              const yearMonthText = t('closed_month', {
                year: history.from_term_month?.term?.year,
                month: formatMonth(history.from_term_month.month),
              })
              const action = (
                <span style={{ color: history.action === 3 ? '#ec4949' : undefined }}>
                  {t(history.action === 3 ? 'reopen' : 'execute')}
                </span>
              )
              return (
                <div key={history.id}>
                  {yearMonthText}：{action}
                  {SPACE}
                  {createdAt}
                  {SPACE}
                  {history.created_by_user.display_name}
                </div>
              )
            })}
          </>
        </BasicModal>
      </Wrapper>
    </Spin>
  )
}

export default MonthlyClosingView
