import { SessionHeader } from 'components/organisms'
import React from 'react'

import { Body, Content, ErrorContent, Header, Wrapper } from './styles'

type Props = {
  header?: any
  contentClassName?: string
  headerContent: {
    header: string
    title: string
  }
}

const ErrorTemplate: React.FC<Props> = ({ contentClassName, header, headerContent, children }) => {
  return (
    <Wrapper>
      <Header>{header}</Header>
      <Body>
        <Content>
          <ErrorContent className={contentClassName}>
            <div className="header">
              <h1 className="status-header">{headerContent.header}</h1>
              <h2 className="status-title">{headerContent.title}</h2>
            </div>
            <div className="content">{children}</div>
          </ErrorContent>
        </Content>
      </Body>
    </Wrapper>
  )
}

ErrorTemplate.defaultProps = {
  header: <SessionHeader />,
}

export default ErrorTemplate
