import { UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import * as exciseApi from 'api/app/excise'
import { EXCISE_CAN_EDIT_TAX, EXCISE_TAX_INPUT_FORMAT, EXCISE_TAX_METHOD } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import { DisposalCostAndExcise } from '../AssetRetirementForm/components/diposal-cost-excise-fields'
import { DisposalCostInputTaxAndExcise } from '../AssetRetirementForm/components/diposal-cost-input-excise-id-fields'
import { Group } from '../events/styles'
import { ValueFields } from './helper'

const t = loadNs(['components/organisms/assets/asset-event-sell-form'])

const { Item } = UnborderCollapse
const contentItemWidth = 240
const itemWidth = '100%'

const DisposalCostWithExcise = ({ isEditMode }: { isEditMode?: boolean }) => {
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [exciseSettingResponse, getExciseSettingByDate] = useResource(exciseApi.getExciseSettingsByDate)
  const [calculatedExciseValueResponse, getCalculatedExciseValue] = useResource(exciseApi.getExciseVatInfo)

  const soldAt: string | null = get(values, 'sold_at')
  const isTaxMethodExcluded = get(exciseSettingResponse.data, 'tax_method') === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormatExternalTax =
    get(exciseSettingResponse.data, 'tax_input_format') === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX
  const isCanEditTax = get(exciseSettingResponse.data, 'can_edit_tax') === EXCISE_CAN_EDIT_TAX.YES
  const disposalExciseId: number | null = get(values, 'disposal_excise_id')
  const disposalCostInput: number = get(values, 'disposal_cost_input')
  const manualDisposalExciseValue: number | null = get(values, 'manual_disposal_excise_value')
  const isDisposalCostIsZero = disposalCostInput === 0
  const isManualDisposalExciseValue = !!manualDisposalExciseValue || manualDisposalExciseValue === 0
  const isResetManualExciseValue = !isDisposalCostIsZero && isCanEditTax && isManualDisposalExciseValue

  useEffect(() => {
    if (soldAt) {
      getExciseSettingByDate(soldAt)
    }
  }, [soldAt]) // eslint-disable-line

  useEffect(() => {
    if (isDataLoaded && soldAt && disposalExciseId && disposalCostInput) {
      getCalculatedExciseValue(soldAt, disposalExciseId, disposalCostInput)
    }
  }, [disposalExciseId, soldAt, disposalCostInput]) // eslint-disable-line

  useEffect(() => {
    if (isDataLoaded && disposalCostInput === 0) {
      setFieldValue('disposal_cost', 0)
      setFieldValue('disposal_cost_excluded_excise', 0)
      setFieldValue('calculated_disposal_excise_value', 0)
      isCanEditTax && setFieldValue('manual_disposal_excise_value', null)
      setFieldValue('disposal_excise_id', get(exciseSettingResponse.data, 'default_excise_disposal_cost_id'))
    }
  }, [disposalCostInput]) // eslint-disable-line

  useEffect(() => {
    if (exciseSettingResponse.data) {
      const defaultExciseDisposalCostId = get(exciseSettingResponse.data, 'default_excise_disposal_cost_id')
      setFieldValue(
        'disposal_excise_id',
        disposalExciseId !== defaultExciseDisposalCostId && !isEditMode ? defaultExciseDisposalCostId : disposalExciseId
      )
    }
  }, [exciseSettingResponse.data]) // eslint-disable-line

  useEffect(() => {
    if ((manualDisposalExciseValue || manualDisposalExciseValue === 0) && isTaxMethodExcluded) {
      if (isTaxInputFormatExternalTax) {
        const disposalCostExcludedExcise = get(values, 'disposal_cost_excluded_excise')
        setFieldValue('disposal_cost', disposalCostExcludedExcise + manualDisposalExciseValue)
      } else {
        const disposalCostIncludedExcise = get(values, 'disposal_cost')
        setFieldValue('disposal_cost_excluded_excise', disposalCostIncludedExcise - manualDisposalExciseValue)
      }
    }
  }, [manualDisposalExciseValue]) // eslint-disable-line

  useEffect(() => {
    if (calculatedExciseValueResponse.data) {
      setFieldValue('disposal_cost', calculatedExciseValueResponse.data.included_excise_value)
      setFieldValue('disposal_cost_excluded_excise', calculatedExciseValueResponse.data.excluded_excise_value)
      setFieldValue('calculated_disposal_excise_value', calculatedExciseValueResponse.data.excise_value)
      isCanEditTax && setFieldValue('manual_disposal_excise_value', null)
    }
  }, [calculatedExciseValueResponse.data]) // eslint-disable-line

  useEffect(() => {
    if (manualDisposalExciseValue) {
      setFieldValue('calculated_disposal_excise_value', manualDisposalExciseValue)
    }

    setIsDataLoaded(true)
  }, []) // eslint-disable-line

  const handleOnChangeManualDisposalExciseValue = (newValue: number) => {
    setFieldValue('manual_disposal_excise_value', newValue)
  }

  const handleResetManualExciseValue = () => {
    const calculatedExciseValueResponseValue = calculatedExciseValueResponse.data

    if (manualDisposalExciseValue !== null) {
      setFieldValue('manual_disposal_excise_value', null)
    }

    if (calculatedExciseValueResponseValue) {
      setFieldValue('calculated_disposal_excise_value', calculatedExciseValueResponseValue?.excise_value)
      setFieldValue('disposal_cost', calculatedExciseValueResponseValue?.included_excise_value)
      setFieldValue('disposal_cost_excluded_excise', calculatedExciseValueResponseValue?.excluded_excise_value)
    }
  }

  const renderDisposalCostField = () => (
    <Item label={t('disposal_tax_amount')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
      <Group>
        <UnderlineMoneyInput
          name="calculated_disposal_excise_value"
          placeholder={t('disposal_cost_excluded_tax_placeholder')}
          style={{ width: 240 }}
          maxValue={get(values, 'disposal_cost')}
          editable={!isDisposalCostIsZero && isCanEditTax}
          onChange={handleOnChangeManualDisposalExciseValue}
          isResetManualValue={isResetManualExciseValue}
          onHandleClickOnReset={handleResetManualExciseValue}
        />
      </Group>
    </Item>
  )

  return (
    <>
      {isTaxMethodExcluded && isTaxInputFormatExternalTax && (
        <>
          <DisposalCostInputTaxAndExcise
            contentItemWidth={contentItemWidth}
            itemWidth={itemWidth}
            isDisposalCostIsZero={isDisposalCostIsZero}
            labels={{
              disposal_cost_excluded_tax: t('disposal_cost_excluded_tax'),
              excise: t('disposal_cost_excise'),
            }}
          />
          {renderDisposalCostField()}
          <Item label={t('disposal_cost_included_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="disposal_cost"
                placeholder={t('disposal_cost_excluded_tax_placeholder')}
                editable={false}
                style={{ width: 240 }}
              />
            </Group>
          </Item>
        </>
      )}
      {isTaxMethodExcluded && !isTaxInputFormatExternalTax && (
        <>
          <DisposalCostInputTaxAndExcise
            contentItemWidth={contentItemWidth}
            itemWidth={itemWidth}
            isDisposalCostIsZero={isDisposalCostIsZero}
            labels={{
              disposal_cost_excluded_tax: t('disposal_cost_excluded_tax'),
              excise: t('disposal_cost_excise'),
            }}
          />
          {renderDisposalCostField()}
          <Item label={t('disposal_cost_excluded_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="disposal_cost_excluded_excise"
                placeholder={t('disposal_cost_included_tax_placeholder')}
                editable={false}
                style={{ width: 240 }}
              />
            </Group>
          </Item>
        </>
      )}
      {!isTaxMethodExcluded && (
        <DisposalCostAndExcise values={values} t={t} contentItemWidth={contentItemWidth} itemWidth={itemWidth} />
      )}
    </>
  )
}

export default DisposalCostWithExcise
