import { Button } from 'aa_common/front-end/antd'
import { BottomAction } from 'aa_common/front-end/components'
import { formatTime } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { DatePicker } from 'components/molecules'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectShowMemorandumValueErrorMessage, selectUploadPhotoData } from 'store/asset/selectors'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { selectTempDataSaveAt } from 'store/tempData/selectors'

import { SavedAtNotice } from '../styles'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-form', 'common', 'components/molecules/basic-form'])
export const SubmitArea = ({ formMode, confirming, disable }: Props) => {
  useNsReady()
  const buttonSubmitRef = useRef<any>(null)
  const showMemorandumValueErrorMessage = useSelector(selectShowMemorandumValueErrorMessage, isEqual)
  const uploading = useSelector(selectUploadPhotoData, isEqual)
  const tempAssetSavedAt = useSelector(selectTempDataSaveAt, isEqual)
  const terms = useSelector(selectTermsData, isEqual)
  const [bottomSubmitId] = useState<string>(uniqueId('asset-form-bottom-submit'))

  let containerId
  const isDisableRegisterButton =
    showMemorandumValueErrorMessage &&
    Object.keys(showMemorandumValueErrorMessage).some(key => showMemorandumValueErrorMessage[key])

  if (formMode === FORM_MODES.IMPORT) {
    containerId = 'asset-edit-import-form'
  }

  const isEditMode = formMode === FORM_MODES.EDIT
  const hasTerms = terms && terms.length > 0
  return (
    <BottomAction
      id={bottomSubmitId}
      style={{
        justifyContent: 'space-between',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
      containerId={containerId}
    >
      <div>
        {!confirming && tempAssetSavedAt && !isEditMode && (
          <SavedAtNotice>{t('msg_last_backup', { time: formatTime(tempAssetSavedAt) })}</SavedAtNotice>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isEditMode && (
          <>
            <label>{t('edit_start_date')}</label>
            <DatePicker name="edited_at" allowClear={false} style={{ marginLeft: 10, width: 110 }} />
          </>
        )}
        <Button
          type="submit"
          size="custom"
          style={{ marginLeft: 24, width: 120, height: 42 }}
          disabled={!hasTerms || uploading || isDisableRegisterButton || disable}
          btnRef={buttonSubmitRef}
        >
          {t('register')}
        </Button>
      </div>
    </BottomAction>
  )
}

type Props = {
  formMode: keyof typeof FORM_MODES
  confirming?: boolean
  disable?: boolean
}
