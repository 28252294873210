/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled'
import { Checkbox } from 'aa_common/front-end/antd'
import { getNs } from 'common/i18n-config'
import { FixedAssetListItem } from 'common/models'
import { isEqual } from 'lodash'
import { assetListPageStore, rowSelectSliceAssetList } from 'pages/assets/AssetListPage/store'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'

import { useGetSelectedItemOnPage } from '../hooks'

const { t, useNsReady } = getNs(['pages/assets'])

export const CollectiveCheckbox = () => {
  useNsReady()
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const rowSelected = rowSelectSliceAssetList.useSlice(state => state.data?.selectedItem)
  const amountOfSelectedAssets = Object.keys(rowSelected)?.filter((key: string) => rowSelected[key]).length
  // Get the list of biid in current list page
  const numberOfSelectedRowOnPage = useGetSelectedItemOnPage().selectedIds?.length

  const isIndeterminate =
    numberOfSelectedRowOnPage > 0 && numberOfSelectedRowOnPage < assetCollectionViewSelector.assets?.length
  const isCheckedAll = !isIndeterminate && numberOfSelectedRowOnPage > 0
  const remainingAssetsUnselected = assetCollectionViewSelector.totalRecords - amountOfSelectedAssets

  const handleCollectiveChange = () => {
    // Select/unselect all rows
    assetCollectionViewSelector.assets?.forEach((item: FixedAssetListItem) => {
      assetListPageStore.addSelectRows([{ id: `${item.biid}`, isSelected: !(isIndeterminate || isCheckedAll) }])
    })
  }

  return (
    <CheckboxWrapper data-testid="collective-checkbox">
      <Checkbox
        className={isCheckedAll ? '' : 'uncheck-collective'}
        name="collective"
        onChange={handleCollectiveChange}
        checked={isCheckedAll}
        indeterminateValue={isIndeterminate}
      >
        {amountOfSelectedAssets > 0 && (
          <span>{t('selectedItems', { numberOfSelectedRow: amountOfSelectedAssets })}</span>
        )}
      </Checkbox>
      <div>
        {isCheckedAll && assetCollectionViewSelector.totalPages > 1 && remainingAssetsUnselected > 0 && (
          <a
            data-testid="markAllRow"
            onClick={() => {
              assetListPageStore.markAllRows(true)
            }}
          >
            {t('selectedAllItem', { totalRecords: remainingAssetsUnselected })}
          </a>
        )}
      </div>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.div`
  display: inline-block;
  float: left;

  label,
  label > span,
  div {
    display: inline-block;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    height: 2px !important;
  }
  .uncheck-collective {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: transparent;
      border: 1px solid #d9d9d9;
    }
  }
`
