import styled from '@emotion/styled'

export const FormFields = styled.div`
  max-width: 1000px;

  &:after {
    display: table;
    content: '';
    clear: both;
  }
`

export const LongItem = styled.div`
  .item {
    width: 720px;

    .content {
      display: flex;
    }
  }
`
