import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox {
    font-size: 13px;

    .ant-checkbox-inner {
      border-radius: 3px;
      border: 1px solid #c5cbcf;
    }

    input {
      width: 16px;
    }

    & + span {
      padding-left: 5px;
    }
  }
`
