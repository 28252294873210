import styled from '@emotion/styled'

export const List = styled.ul`
  list-style: none;
  margin-bottom: 0;
`

export const ListItem = styled.li`
  margin-bottom: 5px;
  line-height: 1.8em;
  transition: 0.2s opacity cubic-bezier(0.445, 0.05, 0.55, 0.95);
  list-style-type: none;
  color: #7c8291;
  word-break: break-word;

  &:hover {
    opacity: 0.8;
  }
`

export const Empty = styled.span`
  color: #7c8291;
`
