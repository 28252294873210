import { css, Global } from '@emotion/react'

const AntTableStyles = css`
  font-size: 13px;
  border: 1px solid #d4d8dd;
  border-radius: 4px;

  .ant-table-thead {
    tr:first-of-type {
      th:first-of-type {
        border-top-left-radius: 4px;
      }

      th:last-of-type {
        border-top-right-radius: 4px;
      }
    }
  }

  .ant-table-tbody {
    tr:last-of-type {
      td:first-of-type {
        border-bottom-left-radius: 4px;
      }

      td:last-of-type {
        border-bottom-right-radius: 4px;
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 15px 15px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom-color: #d4d8dd;
  }
`

const AntTooltipStyles = css`
  .ant-tooltip-content {
    max-width: 500px;
  }
`

const styles = css`
  * {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #999999;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;
  }

  ::placeholder {
    color: #999999;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #webpack-dev-server-client-overlay {
    display: none;
  }

  .ant-table {
    ${AntTableStyles}
  }

  .ant-tooltip {
    ${AntTooltipStyles}
  }
  .ant-message-success {
    svg {
      fill: #3b7de9;
    }
  }
`

export const GlobalStyles = () => <Global styles={styles} />
