import React, { useImperativeHandle } from 'react'
import useVirtual from 'react-cool-virtual'

import LinkItem from './LinkItem'

interface LinkListProps {
  items: string[]
  onDeleteClick: (item: { index: number; url: string }) => void
  onEditClick: (item: { index: number; url: string }) => void
}

const LinkList = React.forwardRef<any, LinkListProps>(function LinkList({ items, onDeleteClick, onEditClick }, ref) {
  const {
    outerRef,
    innerRef,
    items: listItems,
    scrollTo,
    scrollToItem,
  } = useVirtual<HTMLDivElement>({
    itemCount: items.length,
    itemSize: 50,
  })

  useImperativeHandle(ref, () => {
    return {
      scrollTo,
      scrollToItem,
    }
  })

  return (
    <div style={{ width: '100%', marginTop: 10, maxHeight: 200, overflow: 'auto' }} ref={outerRef}>
      <div ref={innerRef}>
        {listItems.map(({ index, measureRef }) => (
          <div key={index} ref={measureRef}>
            <LinkItem url={items[index]} index={index} onDeleteClick={onDeleteClick} onEditClick={onEditClick} />
          </div>
        ))}
      </div>
    </div>
  )
})

export default LinkList
