/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { messageFn } from 'aa_common/front-end'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getErrorMsg } from 'common/helpers/response-helper'
import qs from 'qs'

import { CSRF_TOKEN_KEY } from '../constants'
import { isAxiosKeepException, isAxiosSilent } from './helper'

const baseURL = '/api/v1'

const defaultConfig: AxiosRequestConfig<any> = {
  timeout: 60000,
  withCredentials: true,
  baseURL,
}

const getErrorCode = (error: Types.ExtAxiosError) =>
  error?.response?.data?.errors?.[0]?.code || error?.response?.status || 200

const addRequestInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
      config.headers = config.headers || {}
      config.headers['X-CSRF-Token'] = localStorage.getItem(CSRF_TOKEN_KEY) || ''
      ;(config as any).paramsSerializer = (params: any) => {
        return qs.stringify(params, {
          arrayFormat: 'comma',
          encode: false,
        })
      }
      return config
    },
    (error: any) => {
      console.error(getErrorMsg(error))
      return Promise.reject(error)
    }
  )
}

let deletePromise: any = null
const getDeletePromise = (instance: AxiosInstance) => {
  if (!deletePromise) {
    deletePromise = instance.post('/auth/logout').finally(() => {
      setTimeout(() => {
        deletePromise = null
      }, 500)
    })
  }
  return deletePromise
}
const onDeleteToken = async (instance: AxiosInstance) => {
  try {
    await getDeletePromise(instance) // delete token
  } catch (e2) {
    // console.error('Error on logout', e2)
  } finally {
    // clearSessionCookie()
  }
}

let refreshPromise: Types.Nullable<Promise<{ data: any }>>
const getRefreshPromise = async (instance: AxiosInstance) => {
  if (refreshPromise) return refreshPromise
  refreshPromise = instance.post('/auth/refresh').finally(() => {
    setTimeout(() => {
      refreshPromise = null
    }, 500)
  })
  return refreshPromise
}

const addResponseInterceptors = (instance: AxiosInstance) => {
  const interceptorId = instance.interceptors.response.use(
    (response: any) => response?.data,
    async error => {
      if (![401, 403].includes(getErrorCode(error)) && isAxiosKeepException(error.config)) {
        return Promise.reject(error)
      }
      if (!isAxiosSilent(error.config)) {
        const errorMsg = getErrorMsg(error)
        errorMsg?.trim().length && messageFn().error(errorMsg)
      }
      if (!isAxiosKeepException(error.config)) {
        return Promise.reject(error)
      }
      instance.interceptors.response.eject(interceptorId)
      localStorage.removeItem(CSRF_TOKEN_KEY)
      try {
        await getRefreshPromise(instance) // refresh token
        return instance.request(error.config) // resume api
      } catch (e) {
        console.warn('Error on refresh token, going to logout', e)
        onDeleteToken(instance)
      } finally {
        addResponseInterceptors(instance)
        // getRouter().reload()
      }
    }
  )
}

export const createAxiosClient = (config: AxiosRequestConfig = defaultConfig) => {
  const instance = axios.create(config)
  addRequestInterceptors(instance)
  addResponseInterceptors(instance)
  return instance
}

/**
 * api with interceptors
 */
export const axiosClient = createAxiosClient()
