import { MoneyUnits } from 'common/models'
import { RenderFormProps } from 'components/molecules/Form'
import React, { useMemo } from 'react'

import Table from './Table'

interface ReportTableProps {
  formModule: RenderFormProps
  reportData: any
  isMonthFilter: boolean
}

const ReportTable = ({ reportData, formModule, isMonthFilter }: ReportTableProps) => {
  const moneyUnit: MoneyUnits = useMemo(() => formModule.watch('money_unit'), [formModule])

  return <Table reportData={reportData} moneyUnit={moneyUnit} isMonthFilter={isMonthFilter} />
}

export default ReportTable
