/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateJournalItemSettingRequest,
  PresenterError,
  PresenterJournalItemSettingResponse,
  PresenterUpdateJournalItemSettingRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class JournalItemSettingsItemSetting<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Create JournalItemSetting
   *
   * @tags journal_item_settings item setting
   * @name JournalItemSettingsCreate
   * @summary Create JournalItemSetting
   * @request POST:/journal_item_settings
   */
  journalItemSettingsCreate = (body: PresenterCreateJournalItemSettingRequest, params: RequestParams = {}) =>
    this.http.request<PresenterJournalItemSettingResponse, PresenterError>({
      path: `/journal_item_settings`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update JournalItemSetting
   *
   * @tags journal_item_settings item setting
   * @name JournalItemSettingsUpdate
   * @summary Update JournalItemSetting
   * @request PUT:/journal_item_settings/{id}
   */
  journalItemSettingsUpdate = (
    id: string,
    body: PresenterUpdateJournalItemSettingRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterJournalItemSettingResponse, PresenterError>({
      path: `/journal_item_settings/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
