import { InputProps } from 'antd/lib/input'
import React, { useCallback } from 'react'
import { Control, Controller } from 'react-hook-form'

import { SearchInput as Input } from './styles'

interface InputSearchEvent {
  name: string
  value: string
  event?:
    | React.ChangeEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLInputElement>
    | React.MouseEvent<HTMLElement, MouseEvent>
}

interface SearchInputProps extends InputProps {
  name: string
  value?: string
  defaultValue?: string
  placeholder?: string
  formControl?: Control<Record<string, any>>
  onSearch?: ({ name, value, event }: InputSearchEvent) => void
}

const SearchInput = ({
  name,
  value,
  defaultValue,
  className,
  disabled,
  placeholder,
  formControl,
  onChange,
  onSearch,
}: SearchInputProps) => {
  const handleOnSearch = useCallback(
    (value: string, event) => {
      onSearch && onSearch({ name, value, event })
    },
    [onSearch, name]
  )

  return formControl ? (
    <Controller
      name={name}
      control={formControl}
      render={({ onChange: controllerOnChange, ...controllerRest }) => {
        return (
          <Input
            onChange={event => {
              controllerOnChange(event)
              onChange && onChange(event)
            }}
            onSearch={handleOnSearch}
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            {...controllerRest}
          />
        )
      }}
    />
  ) : (
    <Input
      name={name}
      value={value}
      defaultValue={defaultValue}
      className={className}
      onChange={onChange}
      onSearch={handleOnSearch}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default SearchInput
