import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-layout-content {
    display: flex;
    justify-content: center;
  }
`

export const UserGuideWrapper = styled.div`
  width: 900px;
  height: 90px;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #d4d8dd;
  background-color: white;
  white-space: pre-wrap;
`
