/* eslint-disable react/display-name */
import React, { useMemo } from 'react'

import { monthSimulationUsageTableSettings } from './settings'
import { Table } from './styles'

interface IMonthSimulationDetailUsage {
  data: any[]
  fiscalYear: number
  isAccountLedger: boolean
  isShowComparison: boolean
  showComparisonTable: () => void
  isSourceAsset: boolean
}

const MonthSimulationDetailUsage = ({
  data,
  fiscalYear,
  isAccountLedger,
  isShowComparison,
  showComparisonTable,
  isSourceAsset,
}: IMonthSimulationDetailUsage) => {
  const columns = useMemo(
    () =>
      monthSimulationUsageTableSettings(
        isAccountLedger,
        isShowComparison,
        fiscalYear,
        isSourceAsset,
        showComparisonTable
      ),
    [isAccountLedger, fiscalYear, showComparisonTable, isShowComparison]
  )

  return (
    <Table
      enableSelectRows={false}
      tableLayout="fixed"
      className="month-simulation-detail-table"
      columns={columns}
      data={data}
      actions={false}
    />
  )
}

export default React.memo(MonthSimulationDetailUsage)
