import styled from '@emotion/styled'
import { Modal } from 'antd'

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    left: 0;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        right: 556px;
      }
    }
  }

  .ant-modal-close-x {
    height: 47px;
    line-height: 47px;
    width: 47px;
    font-size: 14px;

    .anticon {
      position: absolute;
      top: 17px;
      right: 15px;
    }
  }

  .ant-modal-header {
    background-color: #fafafa;
    color: rgba(51, 51, 51, 1);
    padding: 15px;

    .ant-modal-title {
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      padding-left: 30px;
    }
  }

  .ant-modal-body {
    font-size: 12px;
    padding: 15px;

    .upload {
      .attachment-btn {
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            margin: 15px 0;
          }
        }
        margin: 15px 0;
      }

      & > span:first-child {
        display: flex;

        .ant-upload-list-text {
          width: 100%;
          margin-left: 11px;

          .ant-upload-list-item-list-type-text {
            margin-top: 4px;

            .ant-upload-list-item-name {
              padding-left: 18px;
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    text-align: right;
  }
`
