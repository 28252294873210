import { LinkLabel, NewBasicModal } from 'aa_common/front-end/components'
import { loadNs } from 'common/i18n-config'
import {
  AccountItem,
  FORM_MODES,
  GeneralJournalSettingItem,
  GeneralJournalSettingItemFormPayload,
  GeneralJournalSettingItemSubmitPayload,
  TermMonth,
} from 'common/models'
import Form, { RenderFormProps } from 'components/molecules/Form'
import omit from 'lodash/omit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { getConstant } from '../GeneralJournalSettingList/const'
import EditForm from './EditForm'
import { convertFormPayload, convertSubmitPayload } from './helper'
import { CustomModalClass } from './styles'
import SubmitArea from './SubmitArea'

interface GeneralJournalSettingFormProps {
  editItem: GeneralJournalSettingItem | null
  copyItem: GeneralJournalSettingItem | null
  nextSettings: TermMonth | null
  isDetailMode: boolean
  loading?: boolean
  formMode: FORM_MODES
  accountItems: AccountItem[]
  onFormSubmit: (values: GeneralJournalSettingItemSubmitPayload | null, id?: number | null) => void
  onCancelClick: () => void
}

const { ACCOUNTING_PLUS_STATUS_VALUES } = getConstant()
const initialValues = {
  create_journal_method: 1,
  depreciation_journal_cycle_type: 1,
  depreciation_journal_method: 1,
  depreciation_journal_type: 1,
  paid_excise_debit_account_item_id: null,
  received_excise_debit_account_item_id: null,
  slip_date_type: 1,
  term_year_period: null,
  month_id: null,
  journal_linkage_status: ACCOUNTING_PLUS_STATUS_VALUES?.approve?.id,
} as const

const t = loadNs(['common'], 'actions')
const GeneralJournalSettingForm = ({
  editItem,
  copyItem,
  isDetailMode,
  formMode,
  nextSettings,
  loading,
  accountItems,
  onFormSubmit,
  onCancelClick,
}: GeneralJournalSettingFormProps) => {
  const [formValues, setFormValues] = useState<any>(initialValues)
  const formRef = React.createRef<RenderFormProps>()

  useEffect(() => {
    switch (formMode) {
      case FORM_MODES.NEW: {
        if (nextSettings) {
          setFormValues({
            ...initialValues,
            term_year_period: nextSettings.term_id,
            month_id: nextSettings.id,
            date: moment(nextSettings.start_date),
          })
        }
        break
      }
      case FORM_MODES.EDIT: {
        editItem && setFormValues(convertFormPayload(editItem))
        break
      }
      default:
        break
    }
  }, [editItem, nextSettings, formMode])

  const isFormMode = formMode === FORM_MODES.NEW || formMode === FORM_MODES.EDIT

  const onModalCancel = () => {
    onCancelClick()
  }

  const handleCopyClick = () => {
    if (copyItem) {
      const { term_year_period, month_id } = formRef.current?.getValues() as GeneralJournalSettingItemFormPayload
      const previousSettings = omit(convertFormPayload(copyItem), ['term_year_period', 'month_id'])
      const newCopiedValue = { ...previousSettings, term_year_period, month_id }
      formRef.current?.reset(newCopiedValue)
    }
  }

  const handleSubmit = async (values: GeneralJournalSettingItemFormPayload) => {
    return onFormSubmit(convertSubmitPayload(values), editItem?.id)
  }

  const renderTitle = () => {
    let title = null
    if (isDetailMode && formMode === FORM_MODES.NEW) {
      title = t('insert')
    } else if (isDetailMode && formMode === FORM_MODES.EDIT) {
      title = t('modify')
    } else {
      title = t('edit')
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{title}</span>
        {formMode === FORM_MODES.NEW && <LinkLabel onClick={handleCopyClick}>{t('copy_the_status_before')}</LinkLabel>}
      </div>
    )
  }

  const handleFilterAccountItems = () => {
    return accountItems.filter((accountItem: any) => accountItem.is_active)
  }

  return (
    <Form ref={formRef} value={formValues} loading={loading} withFormSpinner={false} onFormSubmit={handleSubmit}>
      <NewBasicModal
        width={700}
        loading={loading}
        allowOutsideClick={false}
        className={CustomModalClass}
        isShow={isFormMode}
        title={renderTitle()}
        onCancel={onModalCancel}
        footer={
          <SubmitArea nextSettings={nextSettings} formMode={formMode} item={editItem} isDetailMode={isDetailMode} />
        }
      >
        <EditForm accountItems={handleFilterAccountItems()} />
      </NewBasicModal>
    </Form>
  )
}

export default GeneralJournalSettingForm
