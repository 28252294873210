import { DECLARATION_REPORT_EXPORT_MODE as ExportMode } from 'AppExport/model'
import { DEFAULT_PAGE_SIZE } from 'common/constants'
import useFiltersSortsPaging from 'lib/hooks/useFiltersSortsPaging'
import { isEqual } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTaxDeclarationDecreaseReport,
  fetchTaxDeclarationIncreaseReport,
  fetchTaxDeclarationSummaryReport,
} from 'store/accounting/depreciationFixedAsset/actions'
import { selectTaxDeclarationReports } from 'store/accounting/depreciationFixedAsset/selectors'
import { useDebouncedCallback } from 'use-debounce'

interface EffectDependencies {
  dispatch: ReturnType<typeof useDispatch>
  id: number
  year: number
  mode: ExportMode
  totalRecords?: number
}

function useSummaryReportEffect({ dispatch, id, year, mode }: EffectDependencies) {
  useEffect(() => {
    dispatch(
      fetchTaxDeclarationSummaryReport({
        id,
        year,
        mode,
      })
    )
  }, [dispatch, id, year, mode])
}

function useIncreaseReportEffect({ dispatch, id, year, mode, totalRecords = 0 }: EffectDependencies) {
  const {
    filters,
    setFilters,
    setPageNumber,
    pageNumber: page,
    pageSize,
    totalPages: total_page,
  } = useFiltersSortsPaging({
    totalRecords,
    initFilters: { mode },
    initPageSize: DEFAULT_PAGE_SIZE,
  })

  const _mode = filters.mode
  setFilters({ mode })
  const debounceDispatch = useDebouncedCallback(action => dispatch(action), 50)
  useEffect(() => {
    debounceDispatch(
      fetchTaxDeclarationIncreaseReport({
        id,
        year,
        mode: _mode,
        pageNumber: page,
        pageSize,
      })
    )
  }, [debounceDispatch, id, year, _mode, page, pageSize])

  return useMemo(
    () => ({
      setPageNumber,
      page,
      total_page,
    }),
    [setPageNumber, page, total_page]
  )
}

function useDecreaseReportEffect({ dispatch, id, year, mode, totalRecords = 0 }: EffectDependencies) {
  const {
    setFilters,
    setPageNumber,
    pageNumber: page,
    pageSize,
    totalPages: total_page,
  } = useFiltersSortsPaging({
    totalRecords,
    initFilters: { mode },
    initPageSize: DEFAULT_PAGE_SIZE,
  })

  setFilters({ mode })
  useEffect(() => {
    if (mode === ExportMode.IN_FISCAL_YEAR) {
      dispatch(
        fetchTaxDeclarationDecreaseReport({
          id,
          year,
          pageNumber: page,
          pageSize,
        })
      )
    }
  }, [dispatch, id, year, mode, page, pageSize])

  return useMemo(
    () => ({
      setPageNumber,
      page,
      total_page,
    }),
    [setPageNumber, page, total_page]
  )
}

export default function useReportsData(id: number, year: number, mode: ExportMode) {
  const { loading, summaryReport, increaseReport, decreaseReport } = useSelector(selectTaxDeclarationReports, isEqual)

  const dispatch = useDispatch()
  useSummaryReportEffect({ dispatch, id, year, mode })
  const increaseReportPaging = useIncreaseReportEffect({
    dispatch,
    id,
    year,
    mode,
    totalRecords: increaseReport?.totalRecord || 1,
  })
  const decreaseReportPaging = useDecreaseReportEffect({
    dispatch,
    id,
    year,
    mode,
    totalRecords: decreaseReport?.totalRecord || 1,
  })

  return useMemo(
    () => ({
      loading,
      summaryReport,
      increaseReport: {
        ...increaseReport,
        ...increaseReportPaging,
      },
      decreaseReport: {
        ...decreaseReport,
        ...decreaseReportPaging,
      },
    }),
    [loading, summaryReport, increaseReport, increaseReportPaging, decreaseReport, decreaseReportPaging]
  )
}
