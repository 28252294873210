import { ErrorsList, ExportModal, ImportModal } from 'aa_common/front-end/components'
import { convertTableSortPayload } from 'common/helpers/sort-helper'
import { CsvCharset, IColumnSortEvent } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import AssetCategoryFilter from 'components/organisms/settings/AssetCategoryFilter'
import AssetCategoryList from 'components/organisms/settings/AssetCategoryList'
import { ListPageTemplate } from 'components/templates'
import { useParserHtmlFromI8n } from 'hooks/useParserHtmlFromI8n'
import i18n from 'i18n'
import { get } from 'immutable'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { selectMasterData } from 'store/masterData/selectors'
import {
  cleanupAssetCategory,
  deleteAssetCategory,
  exportAssetCategories,
  fetchAssetCategoriesAuth,
  importAssetCategories,
} from 'store/settings/assetCategory/actions'
import { selectAssetCategoryImport, selectAssetCategoryList } from 'store/settings/assetCategory/selectors'
import { fetchExciseList } from 'store/settings/excise/actions'

const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.settings.AssetCategories.list')} />

const AssetCategoryListPage = () => {
  const dispatch = useDispatch()

  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const [isImporting, setIsImporting] = useState(false)

  const { assetCategories, listLoading, assetCategoriesFilters, assetCategoriesSort } = useSelector(
    selectAssetCategoryList,
    isEqual
  )
  const { importLoading, importError } = useSelector(selectAssetCategoryImport, isEqual)
  const masterData = useSelector(selectMasterData, isEqual)

  const handleFilterSubmit = useCallback(
    (filters: any) => {
      dispatch(fetchAssetCategoriesAuth({ filters, sort: assetCategoriesSort }))
    },
    [assetCategoriesSort]
  ) // eslint-disable-line

  const handleEdit = (item: any) => {
    dispatch(changeLocation(`/master/asset-categories/${item.id}/edit`))
  }

  const handleDelete = (item: any) => {
    if (item) {
      const { id, name } = item
      dispatch(deleteAssetCategory({ id, name }))
    }
  }

  const handleExport = (charset: CsvCharset) => {
    setIsShowExportModal(false)
    dispatch(exportAssetCategories(charset))
  }

  const handleExportClick = () => {
    setIsShowExportModal(true)
  }

  const handleNew = () => dispatch(changeLocation('/master/asset-categories/new'))

  const handleCopy = (item: any) => {
    dispatch(changeLocation(`/master/asset-categories/${item.id}/copy`))
  }

  const importModalRef = useRef<any>(null)
  const [preImportErrs, setPreImportErrs] = useState<null>()
  const handleImportClick = () => {
    importModalRef.current?.open({
      onValuesCb(params: any) {
        const { charset, file } = params || {}
        setPreImportErrs(file ? null : params)
        if (!file) return

        dispatch(importAssetCategories(charset, file))
      },
    })
  }

  const handleSortTable = useCallback<IColumnSortEvent>(
    ({ tableColumns }) => {
      const sort = convertTableSortPayload(tableColumns)
      dispatch(
        fetchAssetCategoriesAuth({
          filters: assetCategoriesFilters,
          sort,
        })
      )
    },
    [assetCategoriesFilters]
  ) // eslint-disable-line

  const filterPanel = (
    <AssetCategoryFilter loading={listLoading} masterData={masterData} onSubmit={handleFilterSubmit} />
  )
  const errorsList = (importError.message || get(importError, 'details', []).length > 0) && (
    <ErrorsList title={importError.message || i18n.t('common.messages.can_not_import')} errors={importError.details} />
  )

  useEffect(() => {
    dispatch(fetchAssetCategoriesAuth({ sort: ['-code'] }))
    const filters = {
      is_active: true,
    }
    dispatch(fetchExciseList({ filters }))

    return function cleanup(): void {
      dispatch(cleanupAssetCategory())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isImporting && !importLoading) {
      setIsImporting(false)
    }
  }, [importLoading, isImporting])

  const exportModalContent = useParserHtmlFromI8n('common.form_values.export_modal_content', {
    name: i18n.t('components.AssetCategoryList.asset_category'),
    returnObjects: true,
  }).content

  const importModalContent = useParserHtmlFromI8n('common.form_values.import_modal_content', {
    name: i18n.t('components.AssetCategoryList.asset_category'),
    fileUrl: `${process.env.PUBLIC_URL}/files/import_samples/asset_category_sample.csv`,
    fileName: 'asset_category_sample.csv',
    returnObjects: true,
  }).content

  return (
    <ListPageTemplate
      breadcrumb={breadcrumb}
      maxWidth={1280}
      filterPanel={filterPanel}
      errors={(preImportErrs && <ErrorsList errors={preImportErrs} />) || errorsList}
    >
      <AssetCategoryList
        loading={listLoading}
        assetCategories={assetCategories}
        onAddNew={handleNew}
        onImportClick={handleImportClick}
        onExportClick={handleExportClick}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onCopy={handleCopy}
        onTableSort={handleSortTable}
      />
      <ExportModal
        title={i18n.t('common.form_values.export_modal_title', {
          name: i18n.t('components.AssetCategoryList.asset_category'),
        })}
        isShow={isShowExportModal}
        onCancel={() => setIsShowExportModal(false)}
        onExport={handleExport}
      >
        {exportModalContent}
      </ExportModal>
      <ImportModal
        ref={importModalRef}
        title={i18n.t('common.form_values.import_modal_title', {
          name: i18n.t('components.AssetCategoryList.asset_category'),
        })}
      >
        {importModalContent}
      </ImportModal>
    </ListPageTemplate>
  )
}

export default AssetCategoryListPage
