import { Tooltip as TooltipAnt } from 'antd'
import React, { ComponentProps, CSSProperties } from 'react'

type TooltipAntProp = ComponentProps<typeof TooltipAnt>
type TooltipAntProps = Omit<TooltipAntProp, 'title' | 'overlay' | 'getPopupContainer'>

type Props = TooltipAntProps & {
  content: any
  tooltipStyles?: CSSProperties
  getTooltipContainer?: TooltipAntProp['getPopupContainer']
}

export const Tooltip: React.FC<Props> = ({
  tooltipStyles,
  children,
  content,
  placement = 'topLeft',
  getTooltipContainer,
  overlayStyle,
  ...rest
}) => {
  return (
    <TooltipAnt
      {...rest}
      title={content}
      placement={placement}
      getPopupContainer={getTooltipContainer}
      overlayStyle={{ ...defaultOverlayStyle, ...overlayStyle }}
      overlayInnerStyle={overlayInnerStyle as CSSProperties}
    >
      <div style={tooltipStyles}>{children}</div>
    </TooltipAnt>
  )
}

const defaultOverlayStyle = {
  maxWidth: '100%',
}

const overlayInnerStyle = {
  whiteSpace: 'pre-line',
  padding: '10px',
  borderRadius: '4px',
}
