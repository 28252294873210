import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { HTMLProps } from 'react'

import { Icon } from './styles'

const activeColor = '#333333'

interface SortTableCellIconProps extends HTMLProps<any> {
  sortType: 'asc' | 'desc' | ''
}

const SortTableCellIcon: React.FC<SortTableCellIconProps> = ({ sortType, style }) => {
  const isAscendingSort = sortType === 'asc'
  const isDescendingSort = sortType === 'desc'

  return (
    <Icon style={style}>
      <FontAwesomeIcon style={{ color: isAscendingSort ? activeColor : 'inherit' }} icon={faCaretUp} />
      <FontAwesomeIcon
        style={{ color: isDescendingSort ? activeColor : 'inherit', marginTop: -6 }}
        icon={faCaretDown}
      />
    </Icon>
  )
}

export default SortTableCellIcon
