/* eslint-disable react/no-unstable-nested-components */
import { Button, Spin } from 'aa_common/front-end/antd'
import { ExportModal } from 'aa_common/front-end/components'
import { useIntervalApiCall } from 'aa_common/front-end/hooks/useIntervalApiCall'
import { defaultPagination, Pagination as PaginationType } from 'aa_common/front-end/zustand/helper'
import { Pagination, PaginationProps, Radio, Space } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { CORPORATE_INCOME_TAX, CORPORATE_INCOME_TAX_REPORT_DATA, StatusJob } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { SupportedTextCharset } from 'common/models'
import { PresenterJob, ReportsService } from 'common/open-api'
import produce from 'immer'
import querystring from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { REPORT_TYPE } from '../corporate-income-tax/components/filter'
import { ReportRefactor } from './ReportRefactor'
import { detailReportSlice } from './store'
import { StyledWrapperContent, StyledWrapperContentSVG, StyledWrapperTop } from './styles'

const { t, useNsReady } = getNs(['common', 'pages/accounting/corporate-income-tax-report'])

interface Props {
  reportId: number
  termId: number
  reportType: number
}

export const CorporateIncomeTaxReportContainer = ({ reportId, termId, reportType }: Props) => {
  useNsReady()
  const [exportType, setExportType] = useState<EXPORT_TYPE>(EXPORT_TYPE.PDF)
  const [jobId, setJobId] = useState<string | undefined>(undefined)

  const { pagination, assetCategoryIds, assetTypeCodes } = detailReportSlice.useSlice(state => state?.filters) || {}
  const { reportData, loading } = detailReportSlice.useSlice(state => ({
    reportData: state?.data,
    loading: state?._loading,
  }))
  const location = useLocation()

  const { id: reportNumber } = querystring.parse(location.search)
  const isReportNo8 = reportNumber && +reportNumber === CORPORATE_INCOME_TAX.TABLE_16_TYPE_8
  const isGetByAssetType = +reportType === REPORT_TYPE.BY_ASSET_TYPE
  const isHaveReportData = reportData && Object.keys(reportData).length > 0
  const [isShowModal, setIsShowModal] = useState(false)
  useEffect(() => {
    if (termId && reportId) {
      const pagination = { pageSize: 4 }
      if (+reportId === CORPORATE_INCOME_TAX.TABLE_16_TYPE_7) {
        pagination.pageSize = 12
      }
      detailReportSlice.setFilters(
        prevFilters => ({ ...prevFilters, termId, reportType, pagination, reportTemplateId: reportId }),
        true
      )
    }
  }, [termId, reportId, reportType])

  detailReportSlice.usePrepare()

  const exportCallApi = async (charset?: string) => {
    setIsShowModal(false)
    const result = await ReportsService.corporateTaxExportList({
      term_id: termId,
      report_template_id: reportId,
      export_file_format: exportType,
      charset: charset ?? SupportedTextCharset[1],
      report_type: reportType,
      sum_ids: isGetByAssetType ? assetTypeCodes?.join(',') : assetCategoryIds?.join(','),
    })
    setJobId(result?.data?.id)
    setIsShowModal(false)
  }
  const exportReport = async () => {
    if (exportType === 'csv') {
      setIsShowModal(true)
    } else {
      await exportCallApi()
    }
  }

  const checkJob = () => {
    if (jobId) {
      return ReportsService.corporateTaxJobIdList(jobId)
    }
  }
  const { data } = useIntervalApiCall<PresenterJob>(
    () => checkJob(),
    3000,
    data => data?.status === StatusJob.JobStatusCompleted || data?.status === StatusJob.JobStatusTimeOut,
    data => {
      if (data?.status === StatusJob.JobStatusCompleted) {
        window.open(data?.data?.url ?? '')
      }
    },
    30000,
    [jobId]
  )

  const getPagination = (_pagination?: PaginationType, unit?: string) => {
    const { total, current, pageSize } = _pagination || {}
    const itemRender: PaginationProps['itemRender'] = (current, type, originalElement) => {
      if (type === 'next') {
        return !total ? (
          originalElement
        ) : (
          <Space>
            <span>{unit}</span>
            {originalElement}
          </Space>
        )
      }
      return originalElement
    }

    const pagination: PaginationProps = {
      simple: true,
      current,
      ...(pageSize ? { pageSize } : {}),
      ...(total ? { total } : {}),
      itemRender,
      onChange: (page: number) => {
        detailReportSlice.setFilters(
          prevFilter =>
            produce(prevFilter, d => {
              if (!d.pagination) d.pagination = defaultPagination
              d.pagination.current = page
            }),
          true
        )
      },
    }
    return pagination
  }

  const onChange = useCallback((e: RadioChangeEvent) => {
    setExportType(e.target.value)
  }, [])

  return (
    <StyledWrapperContent>
      <Spin loading={loading || data?.status === StatusJob.JobStatusWaiting}>
        <StyledWrapperTop data-testid="coporatetax-income">
          {!isReportNo8 && <Pagination {...getPagination(pagination, t('page'))} />}
          <div className="right-content">
            <Radio.Group onChange={onChange} value={exportType}>
              <Radio value="pdf">
                <span>PDF {t('export_format')}</span>
              </Radio>
              <Radio value="csv">
                <span data-testid="radio-csv">CSV {t('export_format')}</span>
              </Radio>
            </Radio.Group>

            <Button data-testid="export-button" color="grey" onClick={exportReport} disabled={!isHaveReportData}>
              {t('actions.export')}
            </Button>
          </div>
        </StyledWrapperTop>
        <ExportModal
          isShow={isShowModal}
          onCancel={() => setIsShowModal(false)}
          title={t('titleModalCsv', {
            typeReport: CORPORATE_INCOME_TAX_REPORT_DATA[+reportId as CORPORATE_INCOME_TAX] ?? '',
          })}
          onExport={exportCallApi}
        />
        <StyledWrapperContentSVG>
          <ReportRefactor reportId={reportId} />
        </StyledWrapperContentSVG>
      </Spin>
    </StyledWrapperContent>
  )
}

export enum EXPORT_TYPE {
  PDF = 'pdf',
  CSV = 'csv',
}
