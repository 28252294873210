import * as officeApi from 'api/app/office'
import { AxiosResponse } from 'axios'
import { CSRF_TOKEN_KEY } from 'common/constants'
import { parseError } from 'common/helpers'
import i18n from 'i18n'
import { get } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { redirectUrl, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { getUserInfo, SAVE_RECENT_OFFICE } from 'store/session/actions'

import {
  FETCH_OFFICES_FAILURE,
  FETCH_OFFICES_REQUEST,
  FETCH_OFFICES_SUCCESS,
  SELECT_OFFICE_FAILURE,
  SELECT_OFFICE_REQUEST,
  SELECT_OFFICE_SUCCESS,
} from './actions'

const CURRENT_OFFICE_ID = 'CURRENT_OFFICE_ID'

export function* fetchOffices(): Generator<any> {
  try {
    const res = (yield call(officeApi.list)) as AxiosResponse
    yield put({ type: FETCH_OFFICES_SUCCESS, payload: res.data.data || [] })
  } catch (err) {
    const payload = get(err, 'response.data.errors', [i18n.t('common.messages.unexpected_error_occured')])
    yield put({ type: FETCH_OFFICES_FAILURE, payload })
    yield put(showErrorsAppCall(parseError(err)))
  }
}

export function* selectOffice({ payload }: any): Generator<any> {
  try {
    const res = (yield call(officeApi.select, { office_id: payload })) as AxiosResponse
    yield localStorage.setItem(CSRF_TOKEN_KEY, res.data.data.csrf_token)
    yield localStorage.setItem(CURRENT_OFFICE_ID, payload)

    yield put({ type: SELECT_OFFICE_SUCCESS })
    yield put(getUserInfo())
    yield put({ type: SAVE_RECENT_OFFICE, payload })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.switched_office'),
      })
    )
    yield put(redirectUrl('/assets'))
  } catch (err) {
    const message = get(err, 'response.data.meta.message', i18n.t('common.messages.unexpected_error_occured'))
    yield put({ type: SELECT_OFFICE_FAILURE, payload: [message] })
    yield put(showErrorsAppCall(parseError(err)))
  }
}

window.onstorage = (data: StorageEvent) => {
  const { key, newValue, oldValue } = data
  if (key === CURRENT_OFFICE_ID && newValue !== oldValue) {
    window.location.reload()
  }
}

export default function* (): Generator<any> {
  yield takeLatest(FETCH_OFFICES_REQUEST, fetchOffices)
  yield takeLatest(SELECT_OFFICE_REQUEST, selectOffice)
}
