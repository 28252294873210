import styled from '@emotion/styled'
import { Layout } from 'antd'

export const Wrapper = styled.div`
  font-size: 13px;
  position: relative;
  min-width: 1000px;
`

export const Header = styled(Layout.Header)`
  padding: 0;
  height: auto;
`

export const Body = styled(Layout)`
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 40px);
`

export const TopMessage = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 130px);
  z-index: 99;
`

export const StyledLayout = styled(Layout)`
  margin: 0;
  padding: 0;
`

export const StyledLayoutContent = styled(Layout.Content)`
  padding: 0 0 24px;
`
