import Icon from '@ant-design/icons'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Button as AntButton } from 'antd'
import React from 'react'

import { ReactComponent as MenuDot } from '../../assets/icons/menu-dot.svg'

export const Wrapper = styled.div`
  .ant-dropdown.popup-overlay-actions {
    padding: 0;
  }
`

const AntIcon = styled(Icon)``
export const MenuIcon = () => {
  return <AntIcon style={{ fontSize: 27, cursor: 'pointer' }} component={MenuDot} />
}

export const Button = styled(AntButton)`
  padding: 0;
  height: 42px;
  width: 100%;
  text-align: left;

  &.action-button {
    height: 100%;
  }

  &.text {
    padding-left: 12px;
  }

  span {
    font-weight: bold;
  }
`

export const MenuStyle = css`
  border-radius: 4px;

  li {
    padding: 0;
    min-width: 160px;
    border-bottom: 1px solid #dce0e6;
    &:last-child {
      border-bottom: none;
    }
  }

  .ant-tooltip-custom {
    width: 100%;
  }

  .btn-no-permission {
    span {
      font-weight: bold;
    }
  }
`

export const ButtonPerStyle: React.CSSProperties = {
  padding: 0,
  height: 42,
  width: '100%',
  textAlign: 'left',
  border: 'none',
  paddingLeft: 12,
}
