import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { MoneyUnits } from 'common/models'
import { get, isEqual } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addIncreaseDecreaseReportRowKey,
  removeIncreaseDecreaseReportRowKey,
} from 'store/accounting/increaseDecreaseReport/actions'
import { selectorIncreaseDecreaseReportRowKeys } from 'store/accounting/increaseDecreaseReport/selectors'

import { buildColumns, parseReportData } from './settings'
import { Table as CoreTable } from './styles'

interface IReportTable {
  moneyUnit: MoneyUnits
  reportData: any
  isMonthFilter: boolean
}

const customExpandIcon = ({ expanded, onExpand, record }: any) =>
  get(record, 'children') &&
  (expanded ? (
    <CaretDownOutlined onClick={e => onExpand(record, e)} />
  ) : (
    <CaretRightOutlined onClick={e => onExpand(record, e)} />
  ))

const ReportTable = ({ reportData, moneyUnit, isMonthFilter }: IReportTable) => {
  const dispatch = useDispatch()
  const rowKeys = useSelector(selectorIncreaseDecreaseReportRowKeys, isEqual)
  const [eventKeys, setEventKeys] = useState<any>(['asset-type'])

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      dispatch(addIncreaseDecreaseReportRowKey(record.key))
    } else {
      dispatch(removeIncreaseDecreaseReportRowKey(record.key))
    }
  }

  return (
    <CoreTable
      columns={buildColumns(isMonthFilter, reportData?.ledger_setting?.accounting_journal_target)}
      pagination={false}
      dataSource={parseReportData(reportData, moneyUnit, isMonthFilter)}
      scroll={{ x: 1675, y: 'calc(100vh - 240px)' }}
      expandedRowKeys={rowKeys.length > 0 ? rowKeys : undefined}
      expandable={{
        expandedRowKeys: eventKeys,
        onExpandedRowsChange: expandedKeys => setEventKeys(expandedKeys),
        defaultExpandAllRows: false,
        expandRowByClick: false,
        expandIcon: customExpandIcon,
      }}
      onExpand={handleExpand}
    />
  )
}

export default ReportTable
