import { CsvCharset } from 'common/models'

import { FetchSubmitDestinationsSuccess } from './action.model'
import {
  CLEANUP_SUBMIT_DESTINATION,
  CREATE_SUBMIT_DESTINATION_REQUEST,
  DELETE_SUBMIT_DESTINATION_REQUEST,
  EXPORT_SUBMIT_DESTINATION_REQUEST,
  FETCH_SUBMIT_DESTINATION_REQUEST,
  FETCH_SUBMIT_DESTINATIONS_REQUEST,
  FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH,
  FETCH_SUBMIT_DESTINATIONS_SUCCESS,
  IMPORT_SUBMIT_DESTINATION_REQUEST,
  UPDATE_SUBMIT_DESTINATION_REQUEST,
} from './constant'
import { SubmitDestinationsSearchPayload } from './model'

export const fetchSubmitDestinations = (payload: SubmitDestinationsSearchPayload = {}) => ({
  type: FETCH_SUBMIT_DESTINATIONS_REQUEST,
  payload,
})

export const fetchSubmitDestinationsAuth = (payload: SubmitDestinationsSearchPayload = {}) => ({
  type: FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH,
  payload,
})

export const fetchSubmitDestinationsSuccess = (payload: any): FetchSubmitDestinationsSuccess => ({
  type: FETCH_SUBMIT_DESTINATIONS_SUCCESS,
  payload,
})

export const fetchSubmitDestination = (id: number): any => {
  return {
    type: FETCH_SUBMIT_DESTINATION_REQUEST,
    id,
  }
}

export const createSubmitDestination = (payload: any): any => {
  return {
    type: CREATE_SUBMIT_DESTINATION_REQUEST,
    payload,
  }
}

export const updateSubmitDestination = (id: number, payload: any): any => {
  return {
    type: UPDATE_SUBMIT_DESTINATION_REQUEST,
    id,
    payload,
  }
}

export const deleteSubmitDestination = (payload: any): any => {
  return {
    type: DELETE_SUBMIT_DESTINATION_REQUEST,
    payload,
  }
}

export const importSubmitDestinations = (charset: CsvCharset, file: any) => {
  return {
    type: IMPORT_SUBMIT_DESTINATION_REQUEST,
    payload: charset,
    file,
  }
}

export const exportSubmitDestinations = (charset: CsvCharset) => {
  return {
    type: EXPORT_SUBMIT_DESTINATION_REQUEST,
    payload: charset,
  }
}

export const cleanupSubmitDestination = (): any => {
  return {
    type: CLEANUP_SUBMIT_DESTINATION,
  }
}
