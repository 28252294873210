import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkLabel } from 'aa_common/front-end/components'
import { Tooltip } from 'antd'
import { formatMonth } from 'common/helpers/format-helper'
import i18n from 'i18n'
import React from 'react'

import { JournalLinkageHistoryRecord } from './model'
import { TooltipClass } from './styles'

const { RedirectLink } = LinkLabel

const JournalLinkageFirstCellRender = ({
  id,
  monthly_closing,
  serial_number,
  total_fail = 0,
}: JournalLinkageHistoryRecord) => {
  const isSuccess = total_fail === 0

  const tooltipContent = isSuccess
    ? i18n.t('pages.accounting.HistoryList.messages.accplus_send_success')
    : i18n.t('pages.accounting.HistoryList.messages.accplus_send_fail', { numberOfErrors: total_fail })

  return (
    <RedirectLink redirectTo={`/accounting/monthly-closing/journals/histories/${id}/detail`} className="history-name">
      <div className="journal-linkage-history">
        <div className="status">
          <Tooltip
            title={tooltipContent}
            overlayClassName={TooltipClass}
            placement="topRight"
            getTooltipContainer={triggerNode => triggerNode}
            arrowPointAtCenter
            align={{
              offset: [19.5, 8],
            }}
          >
            <div>
              <FontAwesomeIcon
                className={`status-icon ${isSuccess ? 'success' : 'error'}`}
                icon={isSuccess ? faCheckCircle : faExclamationCircle}
              />
            </div>
          </Tooltip>
        </div>
        <div className="content">
          {i18n.t('pages.accounting.HistoryList.linkage_history_item', {
            year: monthly_closing?.from_term_month?.term?.year,
            month: formatMonth(monthly_closing?.from_term_month?.month),
            serial_number,
          })}
        </div>
      </div>
    </RedirectLink>
  )
}

export default JournalLinkageFirstCellRender
