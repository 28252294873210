import { SearchOutlined } from '@ant-design/icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spin } from 'antd'
import classNames from 'classnames'
import { INFINITE_SCROLL_PAGE_SIZE } from 'common/constants'
import { calculatePaginationTotalPages } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import Input from 'components/atoms/v2/Input'
import { List } from 'immutable'
import { filter, flatten, get, last } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useVirtual from 'react-cool-virtual'
import { QueryClient, QueryClientProvider, useInfiniteQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'

import { convertSelectOptionItems } from '../helper'
import { useDisableRemoveAll, useDisableSelectAll, useHandleOptionChanged } from '../hooks'
import { IInfinityScrollerPicker } from '../model'
import {
  EmptyWrapper,
  ListItem,
  LoadingContainer,
  MultiselectInputWrapper,
  MultiselectPickerActionWrapper,
  MultiselectPickerWrapper,
} from '../styles'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000,
    },
  },
})

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

const CoreInfinityScrollerPicker = ({
  name,
  value,
  valueField,
  textField,
  listHeight,
  additionalQueries,
  pageSize = INFINITE_SCROLL_PAGE_SIZE,
  searchFunction,
  renderOptions = item => {
    return <span>{item.label}</span>
  },
  onChange,
  onDataSourceChanged,
}: IInfinityScrollerPicker) => {
  const valueRef = useRef<any>(null)
  const [renderItems, setRenderItems] = useState<ReturnType<typeof convertSelectOptionItems>>([])

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedValues, setSelectedValues] = useState<any[]>([])

  const { data, isFetching, isLoading, isRefetching, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [`${name}`, { textSearch: searchValue, additionalQueries }] as const,
    async ({ pageParam = 1, queryKey }) => {
      const { textSearch, additionalQueries } = queryKey[1] || {}
      const result = await searchFunction({ page: pageParam, textSearch, pageSize, additionalQueries })
      const totalRecords = Number(get(result, 'data.meta.total', 0))
      const totalPages = calculatePaginationTotalPages(totalRecords, pageSize)
      const pageInfo = {
        page: pageParam,
        totalPages,
        totalRecords,
      }
      return {
        ...result,
        ...pageInfo,
      }
    },
    {
      getNextPageParam: lastPage => {
        const { page, totalPages } = lastPage
        return page < totalPages ? page + 1 : undefined
      },
    }
  )

  useEffect(() => {
    return () => {
      queryClient.clear()
    }
  }, [])

  const convertedDatas = useMemo(() => {
    if (data) {
      const flattenDatas = flatten(data.pages.map(page => get(page, 'data.data', []))).map(item => {
        return {
          key: get(item, valueField),
          value: get(item, valueField),
          label: get(item, textField),
        }
      })
      return flattenDatas
    }

    return []
  }, [data, valueField, textField]) // eslint-disable-line

  const onBottomScrollReach = useCallback(
    (event: any) => {
      fetchNextPage({ pageParam: event.page })
    },
    [fetchNextPage]
  )

  const loadMoreRows = () => {
    const isNotExecuting = !isLoading && !isFetching && !isRefetching
    if (hasNextPage && isNotExecuting) {
      const lastPage = get(last(data?.pages), 'page')
      const event = {
        page: lastPage + 1,
      }

      onBottomScrollReach(event)
    }
  }

  const wrapperDiv = useRef<any>(null)

  const { outerRef, innerRef, items } = useVirtual<HTMLDivElement>({
    itemCount: renderItems.length,
    onScroll: () => {
      const isBottomReach =
        wrapperDiv.current.scrollTop + wrapperDiv.current.offsetHeight >= wrapperDiv.current.scrollHeight
      isBottomReach && loadMoreRows()
    },
  })

  useEffect(() => {
    setSelectedValues(prevValue => {
      const disabledItems = List(convertedDatas)
        .filter((item: any) => item.disabled)
        .map((item: any) => item.value)
        .toArray()
      let result = List(prevValue)
      if (result.toArray().length > 0) {
        for (const disabledItem of disabledItems) {
          const deletedIndex = result.findIndex(item => item === disabledItem)
          if (deletedIndex >= 0) {
            result = result.delete(deletedIndex)
          }
        }
      }
      return result.toArray()
    })

    setTimeout(() => {
      onDataSourceChanged && onDataSourceChanged(valueRef.current)
    }, 100)
  }, [convertedDatas]) //eslint-disable-line

  // update the value ref
  useEffect(() => {
    valueRef.current = selectedValues
  })

  useEffect(() => {
    if (!isLoading) {
      const allItems = convertSelectOptionItems({ items: convertedDatas, renderOptions, selectedValues })
      const filterItems = filter(
        allItems,
        item => item.optionSearchKey.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      )
      setRenderItems(filterItems)
    }
  }, [selectedValues, convertedDatas]) //eslint-disable-line

  useEffect(() => {
    setSelectedValues(!value ? [] : value)
  }, [value])

  const handleInputOnChange = useDebouncedCallback(async (value: string) => {
    setSearchValue(value)
  }, 500)

  const handleOptionChanged = useHandleOptionChanged(selectedValues, setSelectedValues, onChange)

  const handleResetAll = useCallback(() => {
    if (searchValue !== '') {
      const updatedSelectedValues = selectedValues.filter(
        selectedValue => convertedDatas.findIndex(convertedData => selectedValue.value === convertedData.value) === -1
      )
      setSelectedValues(updatedSelectedValues)
      onChange && onChange(updatedSelectedValues)
    } else {
      setSelectedValues([])
      onChange && onChange([])
    }
  }, [searchValue, selectedValues, convertedDatas, setSelectedValues, onChange])

  const handleSelectAll = useCallback(() => {
    if (searchValue !== '') {
      const filterDuplicate = convertedDatas.filter(
        selectedValue => selectedValues.findIndex(searchItem => selectedValue.value === searchItem.value) === -1
      )
      const updatedSelectedValue = [...selectedValues, ...filterDuplicate]
      setSelectedValues(updatedSelectedValue)
      onChange && onChange(updatedSelectedValue)
    } else {
      const values = convertedDatas.filter((item: any) => item.value && !item.disabled)
      setSelectedValues(values)
      onChange && onChange(values)
    }
  }, [searchValue, convertedDatas, selectedValues, onChange])

  const disableSelectAll = useDisableSelectAll(renderItems, selectedValues)

  const disableRemoveAll = useDisableRemoveAll(renderItems, selectedValues)

  return (
    <MultiselectPickerWrapper className={`multi-select-picker-${name}`}>
      <MultiselectInputWrapper>
        <Input
          name={`multipicker-search-${name}`}
          className="multipicker-search"
          onChange={e => handleInputOnChange(e.target.value)}
          allowClear
          suffix={<SearchOutlined style={{ color: '#999999' }} />}
        />
      </MultiselectInputWrapper>
      <MultiselectPickerActionWrapper>
        <Button type="link" disabled={disableSelectAll} onClick={handleSelectAll}>
          {t('select_all_selection')}
        </Button>
        <Button type="link" disabled={disableRemoveAll} onClick={handleResetAll}>
          {t('remove_all_selection')}
        </Button>
      </MultiselectPickerActionWrapper>
      <div
        style={{ height: listHeight, overflow: 'auto', position: 'relative' }}
        ref={ref => {
          wrapperDiv.current = ref
          outerRef.current = ref
        }}
      >
        <div ref={innerRef}>
          {renderItems && renderItems.length > 0 ? (
            items.map(({ index, measureRef }) => (
              <ListItem
                key={index}
                className={classNames({
                  selected: renderItems[index]?.selected,
                  disabled: renderItems[index]?.disabled,
                })}
                onClick={() => handleOptionChanged(renderItems[index])}
                ref={measureRef}
              >
                <div className="option-content">{renderItems[index] && renderOptions(renderItems[index])}</div>
                {renderItems[index] && renderItems[index].selected && (
                  <div className="option-selected-item">
                    <FontAwesomeIcon style={{ color: '#3B7DE9', marginRight: 5 }} icon={faCheck} />
                  </div>
                )}
              </ListItem>
            ))
          ) : (
            <EmptyWrapper>
              <span>{t('no_select_items')}</span>
            </EmptyWrapper>
          )}
          {isLoading && (
            <LoadingContainer>
              <Spin />
            </LoadingContainer>
          )}
        </div>
      </div>
    </MultiselectPickerWrapper>
  )
}

const InfinityScrollerPicker = (props: IInfinityScrollerPicker) => (
  <QueryClientProvider client={queryClient}>
    <CoreInfinityScrollerPicker {...props} />
  </QueryClientProvider>
)

export default InfinityScrollerPicker
