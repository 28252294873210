import { Typography } from 'antd'
import { ErrorTemplate } from 'components/templates'
import i18n from 'i18n'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { WrapperClass } from './styles'

const { Link: AntdLink } = Typography

const headerContent = {
  header: '404',
  title: 'Not Found',
}

const NotFoundErrorPage = () => {
  useEffect(() => {
    document.title = i18n.t('common.page_title')
  }, [])

  return (
    <ErrorTemplate contentClassName={WrapperClass} headerContent={headerContent}>
      <div className="text">
        <span>{i18n.t('pages.errorPages.not_found.text_1')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.not_found.text_2')}</span>
        <br />
        <span>{i18n.t('pages.errorPages.not_found.text_3')}</span>
        <br />
      </div>
      <div className="link">
        <Link to="/" component={AntdLink}>
          {i18n.t('pages.errorPages.go_to_landing_page')}
        </Link>
      </div>
    </ErrorTemplate>
  )
}

export default NotFoundErrorPage
