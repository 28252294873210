export const FETCH_USER_ROLES_REQUEST = 'SETTING_ROLE_FETCH_USER_ROLES_REQUEST'
export const FETCH_USER_ROLES_SUCCESS = 'SETTING_ROLE_FETCH_USER_ROLES_SUCCESS'
export const FETCH_USER_ROLES_FAILURE = 'SETTING_ROLE_FETCH_USER_ROLES_FAILURE'

export const CLEANUP_ROLES = 'SETTING_ROLE_CLEANUP_ROLES'

export const SET_LOADING_ITEM = 'SETTING_ROLE_SET_LOADING_ITEM'
export const FETCH_ROLE_SAMPLE = 'SETTING_ROLE_FETCH_ROLE_SAMPLE'
export const FETCH_ROLE_DETAIL = 'SETTING_ROLE_FETCH_ROLE_DETAIL'
export const HANDLE_FETCH_ROLE_DETAIL = 'SETTING_ROLE_HANDLE_FETCH_ROLE_DETAIL'
export const CREATE_NEW_ROLE_CALL = 'SETTING_ROLE_CREATE_NEW_ROLE_CALL'
export const UPDATE_ROLE_CALL = 'SETTING_ROLE_UPDATE_ROLE_CALL'
export const DELETE_ROLE_CALL = 'SETTING_ROLE_DELETE_ROLE_CALL'
