import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { Cell } from './schema'

export const FormData = ({ reportData, reportPeriods, companyName, reportBackground }: Props) => {
  return (
    <div
      style={{
        background: `url(${reportBackground}) 0% 0% / cover no-repeat`,
        width: '100%',
      }}
    >
      <svg viewBox="0 0 793.62665 1122.6667" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="coporate-tax-report">
          {/* Draw header with report period and company name */}
          <g id="report-header">
            <g id="period">
              {reportPeriods?.map((period: Cell[]) => (
                <g id={uniqueId('Period-')} key={uniqueId('Period-')}>
                  {period?.map((cell, cellIndex) => (
                    <g id={`period-start-[${cellIndex}]`} key={uniqueId('period_start_')}>
                      <foreignObject x={cell.x} y={cell.y} width={cell.w} height={cell.h}>
                        <div className="cell-wrapper" data-id={`data-period-start-${cellIndex}`}>
                          <span>{cell.value}</span>
                        </div>
                      </foreignObject>
                    </g>
                  ))}
                </g>
              ))}
            </g>
            <g id="company-name">
              <foreignObject x={companyName.x} y={companyName.y} width={companyName.w} height={companyName.h}>
                <div className="cell-wrapper" data-id="company-name">
                  <span>{companyName.value}</span>
                </div>
              </foreignObject>
            </g>
          </g>
          {/* Report content */}
          {reportData.map((row, rowIndex) => (
            <g id={`row-${rowIndex}`} key={uniqueId('row_')}>
              {row.map((cell, cellIndex) => (
                <g id={`cell-${rowIndex}-[${cellIndex}]`} key={uniqueId('cell_')}>
                  <foreignObject x={cell.x} y={cell.y} width={cell.w} height={cell.h}>
                    <div className="cell-wrapper" data-id={`data-${rowIndex}-${cellIndex}`}>
                      <span>{cell.value}</span>
                    </div>
                  </foreignObject>
                </g>
              ))}
            </g>
          ))}
        </g>
      </svg>
    </div>
  )
}

interface Props {
  reportData: Cell[][]
  reportPeriods: Cell[][]
  companyName: Cell
  reportBackground: string
}
