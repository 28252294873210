import styled from '@emotion/styled'

export const SvgFormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
    div {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 9px;
      font-weight: 400;
      padding: 0 3px;
      span {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
        width: calc(100% - 6px);
        text-align: center;
        font-weight: 500;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
      }
    }
  }
`
