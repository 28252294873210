export const FETCH_ASSET_CATEGORIES_REQUEST = 'FETCH_ASSET_CATEGORIES_REQUEST'
export const FETCH_ASSET_CATEGORIES_REQUEST_AUTH = 'FETCH_ASSET_CATEGORIES_REQUEST_AUTH'
export const FETCH_ASSET_CATEGORIES_SUCCESS = 'FETCH_ASSET_CATEGORIES_SUCCESS'
export const FETCH_ASSET_CATEGORIES_FAILURE = 'FETCH_ASSET_CATEGORIES_FAILURE'

export const CREATE_ASSET_CATEGORY_REQUEST = 'CREATE_ASSET_CATEGORY_REQUEST'
export const UPDATE_ASSET_CATEGORY_REQUEST = 'UPDATE_ASSET_CATEGORY_REQUEST'
export const SUBMIT_ASSET_CATEGORY_SUCCESS = 'SUBMIT_ASSET_CATEGORY_SUCCESS'
export const SUBMIT_ASSET_CATEGORY_FAILURE = 'SUBMIT_ASSET_CATEGORY_FAILURE'
export const HANDLE_SUBMIT_ASSET_CATEGORY_FAILURE = 'HANDLE_SUBMIT_ASSET_CATEGORY_FAILURE'

export const FETCH_ASSET_CATEGORY_REQUEST = 'FETCH_ASSET_CATEGORY_REQUEST'
export const FETCH_ASSET_CATEGORY_SUCCESS = 'FETCH_ASSET_CATEGORY_SUCCESS'

export const DELETE_ASSET_CATEGORY_REQUEST = 'DELETE_ASSET_CATEGORY_REQUEST'
export const DELETE_ASSET_CATEGORY_SUCCESS = 'DELETE_ASSET_CATEGORY_SUCCESS'
export const DELETE_ASSET_CATEGORY_FAILURE = 'DELETE_ASSET_CATEGORY_FAILURE'
export const IMPORT_ASSET_CATEGORY_REQUEST = 'IMPORT_ASSET_CATEGORY_REQUEST'
export const IMPORT_ASSET_CATEGORY_SUCCESS = 'IMPORT_ASSET_CATEGORY_SUCCESS'
export const IMPORT_ASSET_CATEGORY_FAILURE = 'IMPORT_ASSET_CATEGORY_FAILURE'
export const EXPORT_ASSET_CATEGORY_REQUEST = 'EXPORT_ASSET_CATEGORY_REQUEST'
export const EXPORT_ASSET_CATEGORY_SUCCESS = 'EXPORT_ASSET_CATEGORY_SUCCESS'
export const EXPORT_ASSET_CATEGORY_FAILURE = 'EXPORT_ASSET_CATEGORY_FAILURE'
export const CLEANUP_ASSET_CATEGORY = 'CLEANUP_ASSET_CATEGORY'
