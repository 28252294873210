import { List, Map } from 'immutable'

import { ExciseActionTypes } from './action.model'
import {
  CLEANUP_EXCISE,
  FETCH_EXCISE_LIST_FAILURE,
  FETCH_EXCISE_LIST_REQUEST,
  FETCH_EXCISE_LIST_REQUEST_AUTH,
  FETCH_EXCISE_LIST_SUCCESS,
  FETCH_EXCISE_RATES_FAILURE,
  FETCH_EXCISE_RATES_REQUEST,
  FETCH_EXCISE_RATES_SUCCESS,
  FETCH_EXCISE_SETTINGS_BY_DATE_FAILURE,
  FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST,
  FETCH_EXCISE_SETTINGS_BY_DATE_SUCCESS,
  UPDATE_EXCISE_LIST_ITEM_SUCCESS,
} from './constant'

const initialState = Map({
  list: Map({
    data: [],
    loading: false,
    filters: null,
    sort: null,
  }),
  item: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  exciseRates: Map({
    data: null,
    loading: false,
  }),
  exciseSettingsByDateData: Map({
    data: null,
    loading: false,
  }),
})

export default (state = initialState, action: ExciseActionTypes) => {
  switch (action.type) {
    case FETCH_EXCISE_LIST_REQUEST:
    case FETCH_EXCISE_LIST_REQUEST_AUTH:
      return state
        .setIn(['list', 'loading'], true)
        .setIn(['list', 'filters'], action.payload.filters)
        .setIn(['list', 'sort'], action.payload.sort)
    case FETCH_EXCISE_LIST_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_EXCISE_LIST_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], [])
    case UPDATE_EXCISE_LIST_ITEM_SUCCESS: {
      const immutableExciseList = state.getIn(['list', 'data']) as any[]
      const exciseList = List.of(...immutableExciseList)

      const targetExciseIndex = exciseList.findIndex(item => item.id === action.payload.id)
      const updatedList = exciseList.setIn([targetExciseIndex], action.payload)
      return state.setIn(['list', 'data'], updatedList.toArray())
    }
    case FETCH_EXCISE_RATES_REQUEST:
      return state.setIn(['exciseRates', 'loading'], true)
    case FETCH_EXCISE_RATES_SUCCESS:
      return state.setIn(['exciseRates', 'loading'], false).setIn(['exciseRates', 'data'], action.payload)
    case FETCH_EXCISE_RATES_FAILURE:
      return state.setIn(['exciseRates', 'loading'], false)
    case FETCH_EXCISE_SETTINGS_BY_DATE_REQUEST:
      return state.setIn(['exciseSettingsByDateData', 'loading'], true)
    case FETCH_EXCISE_SETTINGS_BY_DATE_SUCCESS:
      return state
        .setIn(['exciseSettingsByDateData', 'loading'], false)
        .setIn(['exciseSettingsByDateData', 'data'], action.payload)
    case FETCH_EXCISE_SETTINGS_BY_DATE_FAILURE:
      return state.setIn(['exciseSettingsByDateData', 'loading'], false)
    case CLEANUP_EXCISE:
      return initialState
    default:
      return state
  }
}
