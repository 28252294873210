import styled from '@emotion/styled'
import { css } from '@emotion/css'

export const ModalWrapperClass = css`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      background-color: #fafafa;
      padding: 16px 15px;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #f0f0f0;

      .modal-title {
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .ant-modal-body {
      padding: 15px;
      font-size: 13px;
    }

    .ant-modal-footer {
      border-top: 1px solid #d4d8dd;
      padding: 0;
      border-radius: 0 0 4px 4px;
    }
  }
`

export const Actions = styled.div`
  text-align: right;
  padding: 15px;

  button:last-of-type {
    margin-left: 10px;
  }
`
