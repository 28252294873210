import Input from 'components/atoms/v2/Input'
import { RenderFormProps } from 'components/molecules/Form'
import React from 'react'

import { InputV2Props } from '../Input/model'

type RegexInputType = 'code' | 'phone' | 'number' | 'custom'

interface RegexInputV2Props extends InputV2Props {
  regexType?: RegexInputType
  maxLength?: number
  customRegex?: RegExp
  formModule?: RenderFormProps
  style?: React.CSSProperties
}

const RegexInput = ({
  name,
  className,
  disabled,
  type = 'code',
  placeholder,
  maxLength = 1000,
  formModule,
  customRegex,
  style,
  onChange,
  ...rest
}: RegexInputV2Props) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const pressedKey = event.key

    let regexFormat: any = /.*/
    switch (type) {
      case 'code':
        regexFormat = /^[0-9A-Za-z]*$/
        break
      case 'phone':
        regexFormat = /^[0-9-]*$/
        break
      case 'number':
        regexFormat = /^\d+$/
        break
      case 'custom':
        regexFormat = customRegex!
        break
      default:
        break
    }

    if (!regexFormat.test(pressedKey) && pressedKey !== '') {
      event.preventDefault()
      return false
    }
  }

  return (
    <Input
      name={name}
      className={className}
      disabled={disabled}
      onKeyPress={handleKeyPress}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={maxLength}
      control={formModule?.control}
      style={style}
      {...rest}
    />
  )
}

export default RegexInput
