import { ExportModal, If, Spin } from 'aa_common/front-end'
import { Layout } from 'antd'
import { getNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import {
  AssetModificationForm,
  AssetMovementForm,
  AssetRetirementForm,
  AssetUsageStartedForm,
} from 'components/organisms'
import AssetFilterFacet from 'components/organisms/assets/AssetFilterFacet'
import AssetList from 'components/organisms/assets/AssetList'
import AssetListTopTemplate from 'components/organisms/assets/AssetList/components/AssetListTopTemplate'
import { TableHeaderFunctions } from 'components/organisms/assets/AssetList/components/AssetListTopTemplate/components/table-header-functions'
import { FilterFacetTemplate } from 'components/templates'
import useRetrospectiveAssetModal from 'components/templates/modal/RetroactiveAssetModal'
import i18n from 'i18n'
import isEqual from 'lodash/isEqual'
import { ImportExportEventTypeSelector } from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { assetListPageStore, BulkChangeEvent, importExportStore } from 'pages/assets/AssetListPage/store'
import { ContentWrapper } from 'pages/assets/AssetListPage/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { hookFnc } from './helper'
import {
  useActionAssetList,
  useAssetPermissions,
  useBulkChangeLongPooling,
  useExportAssets,
  useFetchAssetData,
  useGetSelectedFa,
} from './hooks'

const { t, useNsReady } = getNs(['pages/assets', 'common'])

const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.assets.list')} />

export const AssetListContainer = () => {
  useNsReady()
  const { assetCollectionViewSelector, hasIncludeHistory, currentMonthlyClosing, causes, isDetailMode } =
    useFetchAssetData()
  const {
    handleColumnSortChanged,
    handleListModeChanged,
    handleFilterReset,
    handleSubmitFilter,
    handleDelete,
    handleCopy,
    handleValidAtChanged,
    handlePageChanged,
    handleShowItem,
    generateSearchWords,
  } = useActionAssetList()

  const { numberOfExportItems, handleExportClick, handleExport, handleCancelModal, isShowExportModal } =
    useExportAssets()
  const {
    movementPolling,
    retirementPolling,
    assetTypeModificationPolling,
    startedUsagePolling,
    bulkChangeEvent,
    errorsBulkChange,
    biids,
  } = useBulkChangeLongPooling()
  const [loadingBulkChange, setLoadingBulkChange] = useState(false)
  const confirmModal = useRetrospectiveAssetModal()

  const { fixedAssetPermissions, exportPermissions, commentPermissions } = useAssetPermissions()
  const { lastEvent } = useGetSelectedFa()
  const ledgerSettings = useSelector(selectLedgerSettingList, isEqual)

  return (
    <FilterFacetTemplate breadcrumb={breadcrumb}>
      <Spin
        indicatorSize={40}
        loading={
          assetCollectionViewSelector.listLoading ||
          movementPolling.loading ||
          retirementPolling.loading ||
          startedUsagePolling.loading ||
          assetTypeModificationPolling.loading ||
          loadingBulkChange
        }
      >
        <Layout>
          <AssetFilterFacet
            filterDate={assetCollectionViewSelector.filters?.valid_at}
            onSubmit={handleSubmitFilter}
            onFilterReset={handleFilterReset}
          />
          <ContentWrapper>
            <AssetListTopTemplate
              valid_at={assetCollectionViewSelector.filters?.valid_at}
              totalRecords={assetCollectionViewSelector.totalRecords}
              onExportClick={handleExportClick}
              onValidAtChanged={handleValidAtChanged}
              fixedAssetPermissions={fixedAssetPermissions}
              exportPermissions={exportPermissions}
            />
            <TableHeaderFunctions onPageChanged={handlePageChanged} onListModeChanged={handleListModeChanged} />
            <AssetList
              filterAt={assetCollectionViewSelector.filters?.valid_at}
              assets={assetCollectionViewSelector.assets || []}
              sorter={assetCollectionViewSelector.sorterValue}
              searchWords={generateSearchWords}
              isDetailMode={hasIncludeHistory}
              ledgerSettings={ledgerSettings}
              fixedAssetPermissions={fixedAssetPermissions}
              commentPermissions={commentPermissions}
              onShowItem={handleShowItem}
              onCopy={handleCopy}
              onDelete={handleDelete}
              onTableSort={handleColumnSortChanged}
            />

            <AssetRetirementForm
              isShow={bulkChangeEvent === BulkChangeEvent.RETIREMENT}
              causes={causes || []}
              onClose={() => assetListPageStore.setBulkChangeEvent()}
              onSubmit={values => hookFnc.bulkChangesRetirement(values, setLoadingBulkChange, biids, retirementPolling)}
              errors={errorsBulkChange}
              isBulkChangeEvent
            />

            <AssetMovementForm
              isShow={bulkChangeEvent === BulkChangeEvent.MOVEMENT}
              causes={causes || []}
              latestEvent={lastEvent}
              onSubmit={values => {
                hookFnc.bulkChangeMovement(values, setLoadingBulkChange, biids, movementPolling)
              }}
              onClose={() => assetListPageStore.setBulkChangeEvent()}
              errors={errorsBulkChange}
            />

            <AssetUsageStartedForm
              onClose={() => assetListPageStore.setBulkChangeEvent()}
              causes={causes || []}
              isShow={bulkChangeEvent === BulkChangeEvent.START_USAGE}
              isBulkChangeEvent
              errors={errorsBulkChange}
              onSubmit={values =>
                hookFnc.bulkChangeStartedUsage(
                  values,
                  currentMonthlyClosing,
                  setLoadingBulkChange,
                  startedUsagePolling,
                  confirmModal,
                  biids
                )
              }
            />

            <AssetModificationForm
              isShow={bulkChangeEvent === BulkChangeEvent.CHANGE_ASSET_TYPE}
              setShowForm={() => assetListPageStore.setBulkChangeEvent()}
              onSubmit={values => {
                hookFnc.bulkChangeAssetTypeUpdate(values, setLoadingBulkChange, biids, assetTypeModificationPolling)
              }}
              isBulkChangeEvent
            />

            <ExportModal
              title={t('export_modal_title', { quantity: numberOfExportItems })}
              isShow={isShowExportModal}
              onCancel={handleCancelModal}
              onExport={handleExport}
              isShortCharsetLabel
            >
              <If condition={!isDetailMode}>
                <ImportExportEventTypeSelector
                  name="exportEventType"
                  onChange={(exportEventType: number) => importExportStore.setExportEventType(exportEventType)}
                />
              </If>
              <div style={{ marginBottom: '5px', fontWeight: 500 }}>{t('character_code')}</div>
            </ExportModal>
          </ContentWrapper>
        </Layout>
      </Spin>
    </FilterFacetTemplate>
  )
}
