import { Button } from 'aa_common/front-end/antd'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import { TriangleRightIcon as TriangleIcon } from 'components/atoms'
import React, { useCallback, useMemo, useState } from 'react'

import { Wrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-event-sell-form', 'common'])

type Props = {
  ledgerCount: number
  onClick?: () => void
}

export const ExpandLedgerButton = ({ onClick, ledgerCount }: Props) => {
  if (ledgerCount < 2) return null
  return (
    <Wrapper>
      <Button color="grey" className="expand-button" onClick={() => onClick?.()}>
        <div className="btn-text-content">
          <span className="expand-text">{t('show_all_ledger')}</span>
          <span className="collapse-text">{t('actions.close')}</span>
          <span className="triangle-icon">
            <TriangleIcon color="#3B7DE9" />
          </span>
        </div>
      </Button>
    </Wrapper>
  )
}

// TODO write UT for `useExpandLedgersHook`
const baseWidth = 546
const withPerLedger = 240
export const useExpandLedgersHook = () => {
  const [width, setWidth] = useState(baseWidth)
  const [isExpanded, setIsExpanded] = useState(false)

  const updateState = useCallback(
    (ledgerCount: number) => {
      const additionalWidth = isExpanded ? 0 : withPerLedger * (ledgerCount - 1)
      setWidth(baseWidth + additionalWidth)
      setIsExpanded(val => !val)
    },
    [isExpanded]
  )

  return useMemo(() => {
    return {
      width,
      className: classNames({ expanded: isExpanded }),
      renderer(ledgerCount: number) {
        return <ExpandLedgerButton ledgerCount={ledgerCount} onClick={() => updateState(ledgerCount)} />
      },
    }
  }, [width, isExpanded, updateState])
}
