import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Button, Typography } from 'antd'
import { loadNs } from 'common/i18n-config'
import { getIn, useFormikContext } from 'formik'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { Wrapper, WrapperReset } from './styles'

const { Text } = Typography
const t = loadNs('common')

export const UnderlineInput = ({
  name,
  placeholder,
  type = 'text',
  style,
  editable = false,
  isResetManualValue,
  onHandleClickOnReset,
  max,
  min = 0,
  className,
  ellipsis,
  align,
  defaultValue,
}: PropsWithChildren<Props>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { values, errors, setFieldValue } = useFormikContext()
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const isViewMode = mode === 'view'
  const tempFieldValue = getIn(values, name)
  const fieldValue = tempFieldValue !== null && tempFieldValue !== undefined ? tempFieldValue : ''
  const [value, setValue] = useState(fieldValue)
  const [previousValue, setPreviousValue] = useState(fieldValue)
  const error = getIn(errors, name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.trim())
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setMode('view')
      inputRef.current?.blur()
    }
  }

  const handleBlur = () => {
    setMode('view')
    if (String(previousValue) === String(value)) return

    let currentValue = parseInt(String(getValueWithDefault(value, type, defaultValue)), 10)
    if (currentValue < min) {
      currentValue = min
    } else if (max && currentValue > max) {
      currentValue = max
    }

    setValue(currentValue)
    setPreviousValue(currentValue)
    setFieldValue(name, currentValue)
  }

  const handleClickOnReset = () => {
    onHandleClickOnReset?.()
  }

  useEffect(() => {
    if (!isViewMode && inputRef) {
      inputRef.current?.focus()
    }
  }, [mode, isViewMode])

  useEffect(() => {
    const currentValue = getValueWithDefault(fieldValue, type, defaultValue)
    setValue(currentValue)
    setPreviousValue(currentValue)
    setFieldValue(name, currentValue)
  }, [fieldValue, defaultValue])

  const textAlignStyle = align ? { textAlign: align } : {}
  const wrapperStyle = textAlignStyle
  const inputStyle = {
    ...style,
    ...textAlignStyle,
  }

  return (
    <Wrapper className={className} style={wrapperStyle}>
      {isViewMode || !editable ? (
        <Text className="value-view" ellipsis={ellipsis ? { tooltip: value } : false}>
          {value !== '' ? value : placeholder}
        </Text>
      ) : (
        <input
          value={value}
          name={name}
          onChange={handleChange}
          style={inputStyle}
          placeholder={placeholder}
          autoComplete="off"
          disabled={!editable || isViewMode}
          onBlur={handleBlur}
          ref={inputRef}
          onKeyPress={handleKeyPress}
          type={type}
        />
      )}
      {editable && isViewMode && (
        <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={() => setMode('edit')} />
      )}
      {isResetManualValue && (
        <WrapperReset>
          <span className="reset-label">{t('label.reset_manual_value')}</span>
          <Button className="btn-reset" type="link" onClick={handleClickOnReset}>
            {t('actions.reset')}
          </Button>
        </WrapperReset>
      )}
      {error && <CommonErrorMsg style={{ textAlign: 'left' }}>{error}</CommonErrorMsg>}
    </Wrapper>
  )
}

const getValueWithDefault = (value: string | number, type: 'number' | 'text', defaultValue?: number) => {
  const _defaultValue = defaultValue || 0
  if (type === 'number') return String(value).length ? parseInt(value as string, 10) : _defaultValue
  return value || ''
}

type Props = {
  name: string
  placeholder?: string
  type?: 'number' | 'text'
  style?: React.CSSProperties
  editable?: boolean
  isResetManualValue?: boolean
  onHandleClickOnReset?: () => void
  max?: number
  min?: number
  className?: string
  ellipsis?: boolean
  align?: 'left' | 'right'
  defaultValue?: number
}
