import { useMemo } from 'react'

const AGENT_LOGIN_KEY = 'is_agent_login'

export const useCheckAgentLogin = () => {
  return useMemo(() => {
    const isMatch = document.cookie.match(new RegExp(`(^| )${AGENT_LOGIN_KEY}=([^;]+)`))
    return Boolean(isMatch)
  }, [])
}
