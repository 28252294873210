import React from 'react'

type Props = {
  mode?: string
}

const ViewGroup: React.FC<Props> = ({ mode = 'view', children }) => {
  if (mode !== 'view') {
    return null
  }

  return <div>{children}</div>
}

export default ViewGroup
