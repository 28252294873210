import { ASSET_STATUSES } from 'common/constants'
import { isRetroactiveEvent, objectToQueryParams } from 'common/helpers'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams, FixedAssetListItem } from 'common/models'
import moment from 'moment'
import { assetListPageStore } from 'pages/assets/AssetListPage/store'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchAssetList } from 'store/asset/actions'

export const getSingleViewParam = (validAt: string, fixedAssetItem?: FixedAssetListItem) => {
  const validAtDate = moment(validAt)

  let paramObj: AssetSingleViewQueryParams = {
    valid_at: validAt,
  }

  if (fixedAssetItem) {
    const acquiredAtDate = moment(fixedAssetItem.acquired_at)
    const assetStatus = fixedAssetItem.asset_status
    if (validAtDate.isBefore(acquiredAtDate) && assetStatus.code === ASSET_STATUSES.ACQUIRED_BEFORE_USE) {
      // To be acquired
      paramObj = {
        valid_at: fixedAssetItem.acquired_at,
        type: ASSET_ITEM_STATUS_PARAM_TYPE.TO_BE_ACQUIRED,
      }
    } else if (assetStatus.code === ASSET_STATUSES.RETIRED_OR_SOLD) {
      // Retired or sold
      paramObj = {
        valid_at: (fixedAssetItem.retired_at || fixedAssetItem.sold_at) as string,
        type: ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
      }
    }
  }

  const paramString = `?${objectToQueryParams(paramObj)}`
  return paramString
}

export const useFetchAssetListCallback = () => {
  const { search } = useLocation()
  const store = assetListPageStore.rowSelectSliceAssetList.useSlice(data => data).data
  return useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const is_retroactive = searchParams.get('is_retroactive') === 'true'
    const journal_id = Number(searchParams.get('journal_id'))

    return (...args: Parameters<typeof fetchAssetList>) => {
      const [payload, ...rest] = args
      let filters = { ...payload?.filters }
      if (store.idMarkAlls.length > 0) {
        filters.biid = store.idMarkAlls
      } else {
        delete filters.biid
      }
      if (is_retroactive) {
        filters = { ...filters, is_retroactive }
      } else if (journal_id) {
        filters = { ...filters, journal_id }
      } else {
        delete filters.is_retroactive
        delete filters.journal_id
      }
      return fetchAssetList({ ...payload, filters }, ...rest)
    }
  }, [search, store.idMarkAlls])
}

export const verifyClosingMonthStartUsageEvent = (data: { currentMonthlyClosing: any; assetEvent: any }) => {
  return isRetroactiveEvent(data.assetEvent, data.currentMonthlyClosing)
}
