/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class TermOfUseAgreements<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Create term_of_use_agreement
   *
   * @tags term_of_use_agreements
   * @name TermOfUseAgreementsCreate
   * @summary Create term_of_use_agreement
   * @request POST:/term_of_use_agreements
   */
  termOfUseAgreementsCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/term_of_use_agreements`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
