import { Checkbox } from 'antd'
import { getIn, useFormikContext } from 'formik'
import React from 'react'

import { Wrapper } from './styles'

type CheckboxItem = {
  name: string
  label: string
}

type Props = {
  fields: Array<CheckboxItem>
  trueValue?: boolean | number | string
  falseValue?: boolean | number | string
}

const BoolCheckboxes = ({ fields, falseValue = false, trueValue = true }: Props) => {
  const { values, setFieldValue } = useFormikContext()

  const handleChange = (e: any) => {
    const value = e.target.checked ? trueValue : falseValue
    setFieldValue(e.target.name, value)
  }

  return (
    <Wrapper>
      {fields.map(field => (
        <Checkbox
          checked={getIn(values, field.name) === trueValue}
          onChange={handleChange}
          key={field.name}
          name={field.name}
        >
          {field.label}
        </Checkbox>
      ))}
    </Wrapper>
  )
}

export default BoolCheckboxes
