import MainTemplate, { MainTemplateProps } from 'components/templates/MainTemplate'
import React from 'react'

import { StyledContentWrapper, StyledError, StyledWrapper } from './styles'

interface FilterFacetTemplateProps extends MainTemplateProps {
  errors?: any
  maxWidth?: string | number
  actions?: any
  filterPanel?: any
}

const FilterFacetTemplate: React.FC<FilterFacetTemplateProps> = ({
  actions = null,
  breadcrumb,
  children,
  filterPanel = null,
  errors,
  maxWidth,
}) => {
  return (
    <StyledWrapper>
      <MainTemplate
        bodyLayoutClassName="filter-facet-body-layout"
        containerClassName="filter-facet-template"
        breadcrumb={breadcrumb}
        actions={actions}
        filterPanel={filterPanel}
      >
        <StyledContentWrapper data-testid="facet-wrapper" style={{ maxWidth, marginTop: filterPanel ? 36 : undefined }}>
          {errors && <StyledError data-testid="errors-id">{errors}</StyledError>}
          {children}
        </StyledContentWrapper>
      </MainTemplate>
    </StyledWrapper>
  )
}

export default FilterFacetTemplate
