import classNames from 'classnames'
import RangeDatePickerInput from 'components/molecules/v2/RangeDatePickerInput'
import i18n from 'i18n'
import React, { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { DateRangePickerClass, DateWrapper, Label } from './styles'

interface SearchDateFormProps {
  monthlyClosingCurrent: any
  handleSearchValuesChanged: (searchValues: any) => void
}

const SearchDateForm = ({ monthlyClosingCurrent, handleSearchValuesChanged }: SearchDateFormProps) => {
  const { control, setValue } = useForm({
    defaultValues: {
      dateRange: '',
    },
  })

  useEffect(() => {
    const term = monthlyClosingCurrent?.monthly_closing_setting?.term
    if (term) {
      setValue('dateRange', [term.start_date, term.end_date])
      handleSearchValuesChanged({ created_at: `${term.start_date},${term.end_date}` })
    }
  }, [monthlyClosingCurrent, setValue]) // eslint-disable-line

  const handleDateChanged = (event: any) => {
    const { value } = event
    handleSearchValuesChanged({ created_at: value ? value.map((date: any) => date ?? '').join(',') : null })
  }

  return (
    <DateWrapper>
      <Label>{i18n.t('pages.internalControl.operationLog.lb_date_range')}</Label>
      <RangeDatePickerInput
        placeholder={i18n.t('components.OperationLogFilter.no_specification')}
        name="dateRange"
        control={control}
        onDateChanged={handleDateChanged}
        className={classNames(DateRangePickerClass)}
      />
    </DateWrapper>
  )
}

export default memo(SearchDateForm)
