import React from 'react'

import { RequiredLabel } from '../required-label'
import { ItemContent, ItemLabel, ItemWrapper } from './styles'

type Props = {
  children: any
  className?: string
  label: string
  required: boolean
}

const Item = ({ children, className, label, required }: Props) => {
  return (
    <ItemWrapper className={className}>
      <ItemLabel>
        <div className="content">{label}</div>
        {required && (
          <div className="required_label_wrapper">
            <RequiredLabel className="required" />
          </div>
        )}
      </ItemLabel>
      <ItemContent className="item-content">{children}</ItemContent>
    </ItemWrapper>
  )
}

Item.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  required: false,
}

export default Item
