import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { isImmutableEvent } from 'components/organisms/assets/helper/isImmutableEvent'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'

export const useCheckEventAtClosingMonth = (props: { records: any[]; eventType?: ASSET_CHANGE_SITUATION_TYPES }) => {
  const { records, eventType } = props
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const keyEvents = {
    [ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED]: 'usage_start',
    [ASSET_CHANGE_SITUATION_TYPES.MOVEMENT]: 'movement',
    [ASSET_CHANGE_SITUATION_TYPES.RETIREMENT]: 'retirement',
    [ASSET_CHANGE_SITUATION_TYPES.SELL]: 'sell',
    [ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT]: 'impairment',
    [ASSET_CHANGE_SITUATION_TYPES.DIVIDING]: 'dividing',
  } as Record<any, string>

  const checkClosingMonthEvent = useMemo(() => {
    const data = { key: '', checkEventInClosingMonth: false, ids: [] as any }
    if (eventType) {
      data.key = keyEvents[eventType]
    }
    records?.forEach((record: any) => {
      if (data.key && isImmutableEvent(record, currentMonthlyClosing)) {
        data.checkEventInClosingMonth = true
        data.ids.push(record?.id.toString())
      }
    })
    return data
  }, [currentMonthlyClosing, records])
  return { checkClosingMonthEvent }
}
