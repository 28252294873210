import { defaultTo } from 'aa_common/front-end/helpers'
import { PresenterOneAreaResponse, PresenterOneDepartmentResponse } from 'common/open-api'
import get from 'lodash/get'
import moment from 'moment'

export interface ValueFields {
  moved_at: null
  event_cause_id: null
  area_biid: null
  department_biid: null
  event_photos: any
  event_attachments: any
  event_urls: any
}

export const validateForm = (values: ValueFields) => {
  const { moved_at, event_cause_id } = values

  return moved_at && event_cause_id
}

interface AssetMovementSubmitPayload {
  id?: number | string
  area_biid: string
  department_biid: string
  event_attachments: any[]
  event_cause_id: number
  event_photos: any[]
  event_urls: string[]
  moved_at: string
}

export const convertSubmitPayload = (values: any): AssetMovementSubmitPayload => {
  const result = {
    id: get(values, 'id', undefined),
    area_biid: get(values, 'area_biid.value', null),
    department_biid: get(values, 'department_biid.value', null),
    event_cause_id: get(values, 'event_cause_id', null),
    event_attachments: get(values, 'event_attachments', []),
    event_photos: get(values, 'event_photos', []),
    event_urls: get(values, 'event_urls', []),
    moved_at: get(values, 'moved_at', null),
  }

  return result
}
export const validateAreaDepartment = (values: any) => {
  const area_biid = get(values, 'area_biid.value', null)
  const department_biid = get(values, 'department_biid.value', null)
  return !area_biid && !department_biid
}

export const setDataField = async (
  service: any,
  biid: string,
  fieldName: string,
  moveAt: string | null,
  setFieldValue: any
) => {
  const response = await service({
    'filter[biid]': [biid],
  })
  const itemsByBiid = defaultTo(response?.data, [])

  const item = itemsByBiid?.find((item: PresenterOneDepartmentResponse | PresenterOneAreaResponse) => {
    const { valid_from, valid_to } = defaultTo(item, {})
    if (valid_from && valid_to) {
      return moment(moveAt).isSameOrAfter(valid_from) && moment(moveAt).isSameOrBefore(valid_to) ? item : null
    }
    if (valid_from && !valid_to) {
      return moment(moveAt).isSameOrAfter(valid_from) ? item : null
    }
    return null
  })

  setFieldValue(fieldName, get(item, 'name'))
  if (fieldName === 'area_name') {
    setFieldValue('submit_destination_name', get(item, ['submit_destination', 'name']))
  }
}
