import { Col, Row } from 'antd'
import { DATE_FORMAT_RFC } from 'common/constants'
import Datepicker from 'components/atoms/v2/Datepicker'
import { DatepickerChangeEvent } from 'components/atoms/v2/Datepicker/model'
import { get } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

import { CoreRangeDatePickerInputProps } from './model'

const CoreRangeDatePickerInput = ({
  name,
  value,
  className,
  disabled,
  placeholder,
  onDateChanged,
}: CoreRangeDatePickerInputProps) => {
  const [fromValue, setFromValue] = useState<Date | Moment | string | null>(null)
  const [toValue, setToValue] = useState<Date | Moment | string | null>(null)

  useEffect(() => {
    if (value) {
      const fromValue = value[0]
      const toValue = value[1]
      setFromValue(fromValue)
      setToValue(toValue)
    }
  }, [value])

  const handleFromChanged = (event: DatepickerChangeEvent) => {
    const newFromValue = event.value ? moment(event.value).format(DATE_FORMAT_RFC) : null
    const newRangeDateValue = getReturnValue(newFromValue, toValue)
    setFromValue(newFromValue)
    onDateChanged &&
      onDateChanged({
        name,
        value: newRangeDateValue,
      })
  }

  const handleToChanged = (event: DatepickerChangeEvent) => {
    const newToValue = event.value ? moment(event.value).format(DATE_FORMAT_RFC) : null
    const newRangeDateValue = getReturnValue(fromValue, newToValue)
    setToValue(newToValue)
    onDateChanged &&
      onDateChanged({
        name,
        value: newRangeDateValue,
      })
  }

  const renderPlaceholder = useCallback(
    (type: string) => {
      if (placeholder) {
        return typeof placeholder === 'string' ? placeholder : get(placeholder, type)
      }
      return undefined
    },
    [placeholder]
  )

  return (
    <Row className={`${name}-range-date-input range-date-input`}>
      <Col span={11}>
        <Datepicker
          disabled={disabled}
          value={fromValue}
          maxDate={toValue}
          name="from"
          className={className}
          placeholder={renderPlaceholder('from')}
          onDateChanged={handleFromChanged}
        />
      </Col>
      <Col span={2} style={{ textAlign: 'center' }}>
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>~</span>
      </Col>
      <Col span={11}>
        <Datepicker
          disabled={disabled}
          value={toValue}
          minDate={fromValue}
          name="to"
          className={className}
          placeholder={renderPlaceholder('to')}
          onDateChanged={handleToChanged}
        />
      </Col>
    </Row>
  )
}

function getReturnValue(from: any, to: any) {
  if (from || to) {
    return [from, to]
  }

  return null
}

export default CoreRangeDatePickerInput
