import { Button } from 'aa_common/front-end/antd'
import i18n from 'i18n'
import useUserPermission from 'lib/hooks/useUserPermission'
import React from 'react'

import ImportExportButton from '../ImportExportButton'
import { ActionWrapper } from './styles'

interface ActionButton {
  onClick: () => void
  disabled?: boolean
}

interface ListPageActionsProps {
  add?: ActionButton
  onImport?: ActionButton
  onExport?: ActionButton
  featureId: number
}

const ListPageActions = ({ add, onExport, onImport, featureId }: ListPageActionsProps) => {
  const { permissions } = useUserPermission(featureId)

  const showNoPermissionMessage = (permission: boolean) =>
    !permission ? i18n.t('common.actions.no_permission') : undefined

  const importPermission = permissions.isExecute || permissions.isCRUD || permissions.isCRU

  return (
    <ActionWrapper>
      {onExport && (
        <ImportExportButton
          type="export"
          disabled={!permissions.isRead || onExport.disabled}
          onClick={onExport.onClick}
          showTooltipMessage={showNoPermissionMessage(permissions.isRead)}
        />
      )}
      {onImport && (
        <ImportExportButton
          disabled={!importPermission || onImport.disabled}
          type="import"
          onClick={onImport.onClick}
          showTooltipMessage={showNoPermissionMessage(importPermission)}
        />
      )}
      {add && (
        <Button
          disabled={!permissions.isCreate || add.disabled}
          showTooltipMessage={showNoPermissionMessage(permissions.isCreate)}
          className="add-new"
          onClick={add.onClick}
        >
          {i18n.t('components.ActionsList.add_new')}
        </Button>
      )}
    </ActionWrapper>
  )
}

export default ListPageActions
