import { loadNs } from 'common/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'
import { SharedRow } from './RetirementSettingTableView'
import { Row } from './row'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const SaleSettingTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('sale_settings_table')}>
      <tbody>
        <SharedRow item={item} event="sale" />
        <Row
          title={
            <>
              {t('income_subject')}
              <br />({t('income_subject_noted')})
            </>
          }
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            { name: 'sale_debit_revenue_account_item.name' },
            { name: 'sale_debit_revenue_account_sub_item.name' },
            {
              name: '',
              isThead: true,
              title: (
                <>
                  {t('payment_category')}
                  <br />({t('disposal_cost')})
                </>
              ),
            },
            { name: 'sale_credit_payment_category_account_item.name' },
            { name: 'sale_credit_payment_category_account_sub_item.name' },
          ]}
        />
        <Row
          title={t('sale_loss')}
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            { name: 'sale_debit_loss_account_item.name' },
            { name: 'sale_debit_loss_account_sub_item.name' },
            { name: 'sale_gain_title', isThead: true, title: t('sale_gain') },
            { name: 'sale_credit_gain_account_item.name' },
            { name: 'sale_credit_gain_account_sub_item.name' },
          ]}
        />
      </tbody>
    </JournalItemSettingTable>
  )
}

export default SaleSettingTableView
