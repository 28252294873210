export const FETCH_EXCISE_SETTING_REQUEST = 'FETCH_EXCISE_SETTING_REQUEST'
export const FETCH_EXCISE_SETTING_REQUEST_AUTH = 'FETCH_EXCISE_SETTING_REQUEST_AUTH'
export const FETCH_EXCISE_SETTING_SUCCESS = 'FETCH_EXCISE_SETTING_SUCCESS'
export const FETCH_EXCISE_SETTING_FAILURE = 'FETCH_EXCISE_SETTING_FAILURE'

export const CREATE_EXCISE_SETTING_REQUEST = 'CREATE_EXCISE_SETTING_REQUEST'
export const CREATE_EXCISE_SETTING_SUCCESS = 'CREATE_EXCISE_SETTING_SUCCESS'
export const CREATE_EXCISE_SETTING_FAILURE = 'CREATE_EXCISE_SETTING_FAILURE'

export const DELETE_EXCISE_SETTING_REQUEST = 'DELETE_EXCISE_SETTING_REQUEST'
export const DELETE_EXCISE_SETTING_SUCCESS = 'DELETE_EXCISE_SETTING_SUCCESS'
export const DELETE_EXCISE_SETTING_FAILURE = 'DELETE_EXCISE_SETTING_FAILURE'
