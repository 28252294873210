import { ReactComponent as AALogo } from 'aa_common/front-end/assets/icons/aa-logo.svg'
import history from 'browserHistory'
import React from 'react'

import { Wrapper } from './styles'

const SessionHeader = () => {
  const handleLogoClick = () => {
    history.push('/')
  }

  return (
    <Wrapper>
      <AALogo onClick={handleLogoClick} style={{ cursor: 'pointer' }} />
    </Wrapper>
  )
}

export default SessionHeader
