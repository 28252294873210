import { ACCOUNTING_PERIOD_TERM_MONTH_TYPES, DATE_FORMAT_RFC } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { Term, TermMonth } from 'common/models'
import { last } from 'lodash'
import moment from 'moment'

const MONTH_AMOUNT_OF_QUARTERS = [6, 9, 12]

const t = loadNs(['components/organisms/settings/accounting-period-form', 'common'])

export const defaultTermMonth = [
  {
    label: t('start_date_of_month'),
    value: ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_START_DATE,
  },
  {
    label: t('end_date_of_month'),
    value: ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_END_DATE,
  },
]

export const parseTerms = (terms: Term[]) => {
  return terms && terms.length > 0
    ? terms.map(term => {
        return {
          value: term.id,
          text: t('year_term_format', { year: term.year, term: term.period }),
        }
      })
    : []
}

export const parseTermMonths = (termMonths: TermMonth[] | null) => {
  if (!termMonths) return []

  return termMonths.map(termMonth => {
    return {
      value: termMonth.month_serial_number,
      text: t('format.month', { month: termMonth.month }),
    }
  })
}

export const determineMaxEndDate = (startDate: string | null) => {
  if (!startDate) return null

  const startDateMoment = moment(startDate)

  return startDateMoment.add(1, 'year').subtract(1, 'day')
}

export const determineDefaultTermMonthDate = (termMonth: TermMonth | null) => {
  if (termMonth) {
    const monthOfStartDate = moment(termMonth.start_date).month() + 1

    return termMonth.month === monthOfStartDate
      ? ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_START_DATE
      : ACCOUNTING_PERIOD_TERM_MONTH_TYPES.BASE_ON_END_DATE
  }
  return ACCOUNTING_PERIOD_TERM_MONTH_TYPES.DEFAULT
}

export const determineLastTermMonth = (termMonths: TermMonth[], endDate: string): TermMonth => {
  let lastTermMonth = last(termMonths) as TermMonth

  termMonths.forEach((termMonth: TermMonth) => {
    if (termMonth.start_date <= endDate && termMonth.end_date >= endDate) {
      lastTermMonth = termMonth
    }
  })

  return lastTermMonth
}

export const isFullYearRange = (startDate: string, endDate: string) => {
  const lastDateOfYear = moment(startDate).add(1, 'year').subtract(1, 'day').format(DATE_FORMAT_RFC)

  return lastDateOfYear === endDate
}

export const hasLackOfQuarter = (monthAmount: number) => {
  return monthAmount > 3 && !MONTH_AMOUNT_OF_QUARTERS.includes(monthAmount)
}
