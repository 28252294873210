import { Button, UnderlineInput } from 'aa_common/front-end/antd'
import {
  FormikInfiniteScrollSelect,
  RedirectToMasterPageLink,
  RightArrowIcon,
  UnborderCollapse,
} from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import * as areaApi from 'api/app/area'
import { search as searchAreas } from 'api/app/area'
import * as assetApi from 'api/app/asset'
import { search as searchDepartments } from 'api/app/department'
import { ENDPOINTS, FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { AreasService, DepartmentsService } from 'common/open-api'
import { AutoCompleteSelect, DatePicker, MediaUploader } from 'components/molecules'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { BulkChangeEvent, rowSelectSliceAssetList } from 'pages/assets/AssetListPage/store'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'
import { selectUserPermission } from 'store/session/selectors'

import { setDataField, validateForm, ValueFields } from './helpers'
import { FormFields } from './styles'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-movement-form', 'common'])

const { Item, Actions } = UnborderCollapse

export const AREA_DEPARTMENT_EMPTY = 'area-department-empty'

export type ErrorCustom = {
  type: string
  message: string
}

type Props = {
  causes: any[]
  formMode?: FORM_MODES
  onClose?: (e: any) => void
  onHandleReloadCauses?: () => void
  errorCustoms?: ErrorCustom
}

const AssetMovementForm: React.FC<Props> = ({ causes, formMode, onClose, onHandleReloadCauses, errorCustoms }) => {
  useNsReady()
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const [previousEvent, getPreviousEvent] = useResource(assetApi.getPreviousOfId)
  const [area, getArea] = useResource(areaApi.getById)
  const movedAt = get(values, 'moved_at')
  const bulkChangeEvent = rowSelectSliceAssetList.useSlice(state => state?.data?.bulkChangeEvent)
  const isBulkChangeMovement = bulkChangeEvent === BulkChangeEvent.MOVEMENT
  const { data: permissions } = useSelector(selectUserPermission, isEqual)
  const { asset: latestEvent } = useSelector(selectAssetDetailPage, isEqual)

  const handleOnAreaChanged = (_: any, selectedOption: any) => {
    setFieldValue('new_submit_destination_name', get(selectedOption, ['submit_destination', 'name']))
  }

  const handleOnMovedAtChanged = () => {
    // When moved at changed, bitemporal data such as: area, department should be reset
    // in order to avoid deprecated area, department data remains in the form values;
    setFieldValue('area_biid', null)
    setFieldValue('department_biid', null)
    setFieldValue('new_submit_destination_name', null)
  }

  useEffect(() => {
    if (previousEvent.data) {
      const previousEventData = previousEvent.data
      getArea(get(previousEventData, ['area', 'id']))

      setFieldValue('area_name', get(previousEventData, ['area', 'name']))
      setFieldValue('department_name', get(previousEventData, ['department', 'name']))
    }
  }, [previousEvent]) // eslint-disable-line

  useEffect(() => {
    if (area.data) {
      const areaData = area.data

      setFieldValue('submit_destination_name', get(areaData, ['submit_destination', 'name']))
    }
  }, [area]) // eslint-disable-line

  useEffect(() => {
    if (formMode === FORM_MODES.EDIT) {
      getPreviousEvent(get(values, 'biid'), get(values, 'id'))
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const { moved_at } = values
    const isMoveAtBeforeValidFromOfLatestEvent = moment(moved_at).isBefore(latestEvent?.valid_from)

    // When move_at field changed, we need to check if the moved_at date is before the valid_from date of the latest event,
    // then we need to set the value of area_name and department_name fields to null
    // When move_at field changed, we need to check if the moved_at date is not before the valid_from date of the latest event,
    // then we need to call api to get the area and department data and find the correct data by the moved_at date
    if (!isMoveAtBeforeValidFromOfLatestEvent) {
      setDataField(AreasService.areasList, latestEvent?.area_biid as string, 'area_name', moved_at, setFieldValue)
      setDataField(
        DepartmentsService.departmentsList,
        latestEvent?.department_biid as string,
        'department_name',
        moved_at,
        setFieldValue
      )
    } else {
      setFieldValue('area_name', null)
      setFieldValue('submit_destination_name', null)
      setFieldValue('department_name', null)
    }
  }, [values.moved_at])

  const isValidFields = validateForm(values)
  const isAreaDepartmentError = errorCustoms && errorCustoms.type === AREA_DEPARTMENT_EMPTY
  return (
    <>
      <FormFields>
        <Item label={t('movement_at')} required>
          <DatePicker name="moved_at" allowClear={false} afterChanged={handleOnMovedAtChanged} />
        </Item>
        <Item label={t('movement_reason')} required>
          <AutoCompleteSelect
            name="event_cause_id"
            items={causes}
            valueField="id"
            textField="content"
            childrenNode={
              <RedirectToMasterPageLink
                permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_CAUSES, permissions)}
                urlName={ENDPOINTS.CAUSES}
                pageName={t('master.causes')}
                onHandleReload={onHandleReloadCauses}
              />
            }
          />
        </Item>
        <Item label={t('area')}>
          {!isBulkChangeMovement && (
            <>
              <UnderlineInput name="area_name" className="underline-input" ellipsis />
              <RightArrowIcon />
            </>
          )}
          <FormikInfiniteScrollSelect
            name="area_biid"
            valueField="biid"
            textField="name"
            className={ifElse(isAreaDepartmentError, 'error-field error', '')}
            additionalQueries={movedAt}
            executeInfiniteScroll={({ textSearch, pageSize, page, additionalQueries: movedAt }) => {
              return searchAreas({ filters: { name: textSearch, valid_at: movedAt }, pageSize, pageNumber: page })
            }}
            onChange={handleOnAreaChanged}
            renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
              const isExecuting = isRefetching || isLoading || isFetching
              return (
                <RedirectToMasterPageLink
                  permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_AREAS, permissions)}
                  urlName={ENDPOINTS.AREAS}
                  isDisabledReload={isExecuting}
                  pageName={t('master.areas')}
                  onHandleReload={refetch}
                />
              )
            }}
          />
        </Item>
        <Item label={t('submit_destination')}>
          {!isBulkChangeMovement && (
            <>
              <UnderlineInput name="submit_destination_name" className="underline-input" ellipsis />
              <RightArrowIcon />
            </>
          )}
          <UnderlineInput name="new_submit_destination_name" className="underline-input" ellipsis />
        </Item>
        <Item label={t('department')}>
          {!isBulkChangeMovement && (
            <>
              <UnderlineInput name="department_name" className="underline-input" ellipsis />
              <RightArrowIcon />
            </>
          )}
          <FormikInfiniteScrollSelect
            name="department_biid"
            valueField="biid"
            textField="name"
            className={ifElse(isAreaDepartmentError, 'error-field error', '')}
            additionalQueries={movedAt}
            executeInfiniteScroll={({ textSearch, pageSize, page, additionalQueries: movedAt }) => {
              return searchDepartments({ filters: { name: textSearch, valid_at: movedAt }, pageSize, pageNumber: page })
            }}
            renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
              const isExecuting = isRefetching || isLoading || isFetching
              return (
                <RedirectToMasterPageLink
                  permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_DEPARTMENT, permissions)}
                  urlName={ENDPOINTS.DEPARTMENTS}
                  isDisabledReload={isExecuting}
                  pageName={t('master.departments')}
                  onHandleReload={refetch}
                />
              )
            }}
          />
        </Item>
        {!isBulkChangeMovement && <MediaUploader />}
      </FormFields>
      <Actions>
        <Button color="grey" onClick={onClose}>
          {t('actions.cancel')}
        </Button>
        <Button type="submit" disabled={!isValidFields}>
          {t('actions.register')}
        </Button>
      </Actions>
      {errorCustoms && <div style={{ width: '100%', color: 'red', textAlign: 'center' }}>{errorCustoms?.message}</div>}
    </>
  )
}

export default AssetMovementForm
