import { buildQueryFilterParams } from 'common/helpers'
import { Asset } from 'common/models'
import { SelectAllService } from 'common/open-api'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { assetListPageStore } from 'pages/assets/AssetListPage/store'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'

import { useFiltersSort } from './use-filters-sort'

export const useGetSelectedFa = () => {
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)
  const { filters, sort } = useFiltersSort()

  const store = assetListPageStore.rowSelectSliceAssetList.useSlice(data => data).data
  const { markAll } = store

  const { selectedRow, biids, totalBiidOfSelectedItems } = React.useMemo(() => {
    const selectedRow = assetCollectionViewSelector.assets.filter((asset: Asset) =>
      asset && asset.biid ? store.selectedItem[asset.biid] : undefined
    )

    const totalBiidOfSelectedItems = Object.keys(store.selectedItem).filter(key => store.selectedItem[key])

    const biids = selectedRow.map((item: Asset) => item.biid)
    return { selectedRow, biids, totalBiidOfSelectedItems }
  }, [assetCollectionViewSelector.assets, store.selectedItem])

  const lastEvent = useMemo(() => {
    return selectedRow.find((item: Asset) => item.usage_started_at)
  }, [selectedRow])

  const filtersWithoutBiid = useMemo(() => {
    const newFilters = omit(filters, ['biid'])
    return newFilters
  }, [filters])

  const markAllFa = useCallback(async () => {
    const queryFilterParams = buildQueryFilterParams({ ...filters, calculate_cost: true } ?? {})
    const res = await SelectAllService.fixedAssetsSelectAllCreate({
      ...queryFilterParams,
      sort,
    })
    assetListPageStore.setIdMarkAll(res.data)
  }, [JSON.stringify(filtersWithoutBiid), JSON.stringify(sort)])

  useEffect(() => {
    if (markAll) {
      markAllFa()
    }
  }, [markAll])

  useEffect(() => {
    if (!markAll) {
      assetListPageStore.resetSelectRows()
    }
  }, [JSON.stringify(filtersWithoutBiid), JSON.stringify(sort)])

  return {
    selectedRow,
    biids,
    totalBiidOfSelectedItems,
    lastEvent,
  }
}
