import { Map } from 'immutable'

import {
  CLEANUP_ASSET_CODE_AUTO_GENERATE_SETTING,
  CLEANUP_ASSET_CODE_INITIAL_NUMBER_SETTING,
  CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE,
  CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS,
  DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE,
  DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS,
  FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE,
  FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST,
  FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH,
  FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS,
  FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS,
  SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_FAILURE,
  SET_TERM_YEAR_SELECTED,
} from './actions'

export const initialState = Map({
  list: Map({
    data: null,
  }),
  item: Map({
    data: null,
    loading: false,
  }),
  initialNumberSettingList: Map({
    data: null,
    errors: null,
  }),
  termYearSelected: null,
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST:
    case FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST_AUTH:
    case DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_REQUEST:
      return state.setIn(['item', 'loading'], true)
    case FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE:
      return state.setIn(['item', 'loading'], false).setIn(['list', 'data'], null)
    case CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS:
      return state.setIn(['item', 'loading'], false).setIn(['item', 'data'], action.payload)
    case CREATE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE:
    case DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_SUCCESS:
    case DELETE_ASSET_CODE_AUTO_GENERATE_SETTING_FAILURE:
      return state.setIn(['item', 'loading'], false)
    case FETCH_ASSET_CODE_INITIAL_NUMBER_SETTING_SUCCESS:
      return state.setIn(['initialNumberSettingList', 'data'], action.payload)
    case SAVE_ASSET_CODE_INITIAL_NUMBER_SETTING_FAILURE:
      return state.setIn(['initialNumberSettingList', 'errors'], action.payload)
    case CLEANUP_ASSET_CODE_INITIAL_NUMBER_SETTING:
      return state.setIn(['initialNumberSettingList', 'data'], null).setIn(['initialNumberSettingList', 'errors'], null)
    case CLEANUP_ASSET_CODE_AUTO_GENERATE_SETTING:
      return initialState
    case SET_TERM_YEAR_SELECTED:
      return state.setIn(['termYearSelected'], action.payload)
    default:
      return state
  }
}
