import { all } from 'redux-saga/effects'

import accounting from './accounting/saga'
import app from './app/saga'
import assets from './asset/saga'
import assetComment from './assetComment/saga'
import assetImport from './assetImport/saga'
import depreciation from './depreciation/saga'
import internalControl from './internalControl/saga'
import masterData from './masterData/saga'
import office from './office/saga'
import session from './session/saga'
import settings from './settings/saga'
import tempData from './tempData/saga'

export default function* rootSaga() {
  yield all([
    assets(),
    tempData(),
    settings(),
    masterData(),
    session(),
    app(),
    depreciation(),
    office(),
    assetComment(),
    assetImport(),
    accounting(),
    internalControl(),
  ])
}
