import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: contents;
  display: flex;
  flex-direction: column;
  position: relative;

  input,
  .value-view.ant-typography {
    border: none;
    border-bottom: 1px solid #d4d8dd;
    box-sizing: border-box;
    width: 100%;
    padding: 7px;
    padding-right: 18px;
    font-size: 13px;
    color: #7c8291;
    background: none;

    &:focus {
      outline: 0;
    }
  }

  .edit-icon {
    position: absolute;
    top: 10px;
    right: 0px;
    color: #7c8291;
    cursor: pointer;
  }
`

export const WrapperReset = styled.div`
  color: #666666;
  font-size: 12px;
  text-align: right;

  .btn-reset {
    padding: 0 5px;
    color: #3b7de9;
    font-size: 12px;

    &:hover {
      opacity: 0.8;
    }

    &:focus,
    &:active {
      opacity: 0.5;
    }
  }
`
