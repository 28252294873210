import { css } from '@emotion/css'
import styled from '@emotion/styled/macro'

export const Wrapper = styled.div`
  text-align: left;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
    border-radius: 4px;
    font-size: 13px;

    input.ant-select-selection-search-input {
      height: 28px;
    }

    .ant-select-selection-placeholder {
      color: #999999;
      line-height: 28px;
      opacity: 1;
    }

    .ant-select-selection-item {
      line-height: 28px;
    }
  }

  .ant-select {
    width: 100%;

    &.error {
      .ant-select-selector {
        border-color: #dc3545;
        background-color: #ffeeeb;
      }
    }
  }
`

// Styles for dropdown list
export const WrapperList = css`
  .ant-select-item {
    &.last {
      border-top: 1px solid rgba(0, 0, 0, 0.06);

      label {
        cursor: pointer;
        color: #3b7de9;
      }
    }

    &.link {
      text-align: right;
      font-size: 13px;
    }
  }
`
