import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Input } from 'antd'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  name: string
  placeholder?: string
  type?: 'code' | 'phone' | 'custom' | 'branch_code' | null
  maxLength?: number | string
  regex?: RegExp
  disabled?: boolean
  style?: React.CSSProperties
  isShowError?: boolean
  errorStyle?: React.CSSProperties
  className?: string
}

export const RegexInput = ({
  maxLength = 1000,
  name,
  placeholder,
  regex,
  type = 'code',
  style,
  disabled,
  isShowError = true,
  errorStyle,
  className,
}: Props) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name))
  const [previousValue, setPreviousValue] = useState(getIn(values, name))
  const fieldValue = getIn(values, name)

  let isInValid = null

  if (get(errors, name)) {
    isInValid = true
  }

  const handleChange = (e: any) => {
    const { value } = e.target
    let regexFormat: any = /.*/

    switch (type) {
      case 'code':
        regexFormat = /^[0-9A-Za-z`~!@#$%^&*()\-_+={}[\]:";'<>.?/]*$/
        break
      case 'phone':
        regexFormat = /^[0-9-]*$/
        break
      case 'custom':
        regexFormat = regex
        break
      case 'branch_code':
        regexFormat = /^\d+$/
        break
      default:
        break
    }

    if (regexFormat.test(value) || value === '') {
      setValue(value)
    }
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (previousValue !== newValue) {
      setPreviousValue(newValue.trim())
      setFieldValue(name, newValue.trim())
    }
  }

  const handlePressEnter = () => {
    setFieldValue(name, value.trim())
  }

  useEffect(() => {
    setValue(fieldValue)
    setPreviousValue(fieldValue)
  }, [fieldValue])

  return (
    <Wrapper>
      <Input
        value={value}
        name={name}
        className={classNames(className, { error: isInValid })}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={typeof maxLength === 'string' ? parseInt(maxLength, 10) : maxLength}
        style={style}
        onBlur={handleBlur}
        onPressEnter={handlePressEnter}
      />
      {isInValid && isShowError && <CommonErrorMsg style={errorStyle}>{get(errors, name)}</CommonErrorMsg>}
    </Wrapper>
  )
}

export default RegexInput
