import React, { CSSProperties } from 'react'

import { Wrapper } from './styles'

type Props = {
  height?: number
  color?: string
  style?: CSSProperties
}

export const VerticalLine: React.FC<Props> = ({ height = 11, color = '#7C8291', style }) => {
  return (
    <Wrapper style={style} className="vertical-line">
      <svg height={height} width="1">
        <line x1="0" y1="0" x2="0" y2={height} stroke={color} data-testid="line" />
      </svg>
    </Wrapper>
  )
}
