import { Form as FormAnt, useFormikContext } from 'formik'
import React, { useEffect } from 'react'

type Props = {
  children: any
  onInit: (actions: any) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void
}

const Form = ({ children, onKeyPress, onInit }: Props) => {
  const { setErrors, setTouched } = useFormikContext()

  useEffect(() => {
    onInit({ setErrors, setTouched })
  }, []) // eslint-disable-line

  return <FormAnt onKeyPress={onKeyPress}>{children}</FormAnt>
}

export default Form
