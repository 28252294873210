import { ConfirmModal } from 'aa_common/front-end/components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideInUsedMessage } from 'store/app/actions'
import { selectInUsedMessage } from 'store/app/selectors'

const InUsedMessage = () => {
  const dispatch = useDispatch()
  const inUsedMessage = useSelector(selectInUsedMessage)

  const handleClose = () => {
    dispatch(hideInUsedMessage())
  }

  return inUsedMessage ? (
    <ConfirmModal
      type={ConfirmModal.CONFIRM_TYPES.NOTICE}
      message={inUsedMessage}
      visible={!!inUsedMessage}
      onOK={handleClose}
      onCancel={handleClose}
    />
  ) : null
}

export default InUsedMessage
