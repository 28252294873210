import parser from 'html-react-parser'
import i18n from 'i18n'
import React from 'react'

/**
 * deprecated ! avoid to use this
 */
export const useParserHtmlFromI8n = (pathText: string, params: any, deps?: React.DependencyList) => {
  const contentParseFromI18n: any = i18n.t(pathText, params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const content = React.useMemo(() => parser(contentParseFromI18n.join('')), [...(deps ?? [])])
  return { content }
}
