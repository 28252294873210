import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Input as AntdInput } from 'antd'
import { InputProps } from 'antd/lib/input'
import classNames from 'classnames'
import { get } from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Wrapper } from './styles'

interface Props extends InputProps {
  isShowError?: boolean
  className?: string
  required?: boolean
  handlePressEnter?: () => void
}

const Input: React.FC<Props> = ({ name = '', isShowError = true, required, className, handlePressEnter, ...rest }) => {
  const { errors } = useFormContext()
  const error = get(errors, `${name}.message`)

  return (
    <Controller
      name={name}
      defaultValue={null}
      rules={{ required }}
      render={({ onChange: controllerOnChange, value, onBlur }) => {
        return (
          <Wrapper>
            <AntdInput
              value={value}
              name={name}
              className={classNames(className, { error })}
              onChange={controllerOnChange}
              onBlur={onBlur}
              onPressEnter={handlePressEnter}
              {...rest}
            />
            {error && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
          </Wrapper>
        )
      }}
    />
  )
}

export default Input
