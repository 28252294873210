import { Button } from 'aa_common/front-end/antd'
import { RightDrawer } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import Form from 'components/molecules/Form'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'
import { selectorLedgerSettingsList } from 'store/settings/ledgerSetting/selectors'

import { useGetSelectedItemOnPage } from '../AssetList/components/AssetListTopTemplate/hooks'
import FormContent from './Form'
import { ModalClass } from './styles'
import SubmitButton from './SubmitButton'

const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-type-modify-form'])

interface Props {
  isShow?: boolean
  setShowForm: (isShow: boolean) => void
  isBulkChangeEvent?: boolean
  onSubmit?: (value: any) => void
}

interface ModificationAssetType {
  asset_type_for_depreciable_property_tax_code: number | null
  asset_type_for_corporate_tax_code: number | null
  asset_structure_for_corporate_tax: string | null
  asset_detail_for_corporate_tax: string | null
}

const { Footer } = Form

const AssetModificationForm = ({ isShow, setShowForm, isBulkChangeEvent, onSubmit }: Props) => {
  useNsReady()
  const numberOfSelectedItems = useGetSelectedItemOnPage().selectedIds?.length
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const { loading: isLedgersLoading } = useSelector(selectorLedgerSettingsList, isEqual)
  const { itemHistoryLoading } = useSelector(selectAssetDetailPage, isEqual)
  const [defaultValues, setDefaultValues] = useState<ModificationAssetType>()

  useEffect(() => {
    setDefaultValues({
      asset_type_for_depreciable_property_tax_code: asset?.asset_type_for_depreciable_property_tax?.code || null,
      asset_type_for_corporate_tax_code: asset?.asset_type_for_corporate_tax?.code || null,
      asset_structure_for_corporate_tax: asset?.asset_structure_for_corporate_tax || null,
      asset_detail_for_corporate_tax: asset?.asset_detail_for_corporate_tax || null,
    })
  }, [asset])

  const handleSubmit = (value: any) => {
    onSubmit && onSubmit(value)
  }

  const closeForm = () => {
    setShowForm(false)
  }

  return (
    <RightDrawer
      title={!isBulkChangeEvent ? t('modify_type') : t('modal_title_for_bulk_change', { numberOfSelectedItems })}
      className={ModalClass}
      visible={isShow}
      destroyOnClose
      onClose={closeForm}
      width={!isBulkChangeEvent ? 844 : 600}
    >
      {defaultValues && (
        <Form
          value={defaultValues}
          loading={isLedgersLoading || itemHistoryLoading}
          onFormSubmit={handleSubmit}
          mode="onChange"
        >
          <FormContent isBulkChangeEvent={isBulkChangeEvent} />
          <Footer>
            <div className="footer-form">
              <Button color="grey" onClick={closeForm}>
                {t('actions.cancel')}
              </Button>
              <SubmitButton>{t('actions.register')}</SubmitButton>
            </div>
          </Footer>
        </Form>
      )}
    </RightDrawer>
  )
}

export default AssetModificationForm
