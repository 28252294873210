import { Button, Spin } from 'aa_common/front-end/antd'
import { OfficeUser } from 'aa_common/front-end/models'
import { CONFIRM_TYPES, FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { UserList } from 'components/organisms'
import { ListPageTemplate } from 'components/templates'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { deleteOfficeUser, fetchOfficeUserList, resetOfficeUser } from 'store/settings/officeUser/actions'
import { selectorOfficeUserList } from 'store/settings/officeUser/selectors'

const t = loadNs(['common', 'pages/settings/users'])

const UserListPage = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('breadcrumb_title')} />
  const modal = useModal()
  const dispatch = useDispatch()
  const {
    permissions: { isCreate },
  } = useUserPermission(FEATURES_ID.SETTING_USERS)

  const officeUserListSelector = useSelector(selectorOfficeUserList, isEqual)
  const memoizedList = useMemo(() => officeUserListSelector.data, [officeUserListSelector.data])

  useEffect(() => {
    dispatch(fetchOfficeUserList())

    return () => {
      dispatch(resetOfficeUser())
    }
  }, []) // eslint-disable-line

  const handleItemDeleteClick = useCallback(
    (deletedItem: OfficeUser) => {
      modal
        .confirm({
          title: t('delete_user_modal_title'),
          message: (
            <div>
              <span>{t('delete_user_modal_content')}</span>
              <br />
              <span>{t('delete_user_modal_content2')}</span>
            </div>
          ),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isOKClick => {
          isOKClick && dispatch(deleteOfficeUser(deletedItem.id, deletedItem.name))
        })
    },
    [dispatch]
  ) //eslint-disable-line

  const handleAddNew = () => {
    dispatch(changeLocation('/setting/users/new'))
  }

  return (
    <ListPageTemplate maxWidth={900} breadcrumb={breadcrumb}>
      <Spin loading={officeUserListSelector.loading}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleAddNew}
            color="grey"
            showTooltipMessage={!isCreate ? t('actions.no_permission') : undefined}
            disabled={!isCreate}
          >
            {t('invite')}
          </Button>
        </div>
        <UserList data={memoizedList} onItemDeleteClick={handleItemDeleteClick} />
      </Spin>
    </ListPageTemplate>
  )
}

export default UserListPage
