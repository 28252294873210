import styled from '@emotion/styled'

export const List = styled.ul`
  list-style: none;
  margin-bottom: 0;
`

export const Content = styled.div``

export const ListItem = styled.li`
  margin-bottom: 5px;
  line-height: 1.8em;
  transition: 0.2s opacity cubic-bezier(0.445, 0.05, 0.55, 0.95);
  color: #7c8291;
  background-color: #eff1f4;
  display: flex;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }

  span {
    overflow-wrap: anywhere;
  }
`

export const Empty = styled.span`
  color: #7c8291;
`
