import { Map } from 'immutable'

import { OfficeActionTypes } from './action.model'
import {
  CLEANUP_OFFICE,
  FETCH_OFFICES_FAILURE,
  FETCH_OFFICES_REQUEST,
  FETCH_OFFICES_SUCCESS,
  SELECT_OFFICE_FAILURE,
  SELECT_OFFICE_REQUEST,
  SELECT_OFFICE_SUCCESS,
} from './actions'

const initialState = Map({
  list: Map({
    data: null,
    loading: false,
    errors: undefined,
  }),
})

export default (state = initialState, action: OfficeActionTypes) => {
  switch (action.type) {
    case FETCH_OFFICES_REQUEST:
    case SELECT_OFFICE_REQUEST:
      return state.setIn(['list', 'loading'], true)
    case FETCH_OFFICES_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_OFFICES_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'errors'], action.payload)
    case SELECT_OFFICE_SUCCESS:
      return state.setIn(['list', 'loading'], false)
    case SELECT_OFFICE_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'errors'], action.payload)
    case CLEANUP_OFFICE:
      return initialState
    default:
      return state
  }
}
