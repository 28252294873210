import React from 'react'

import { AssetDisplayDataRow } from '../styles'

export const Row = ({ label, value, className }: Props) => {
  return (
    <AssetDisplayDataRow className={className}>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </AssetDisplayDataRow>
  )
}

type Props = {
  label: string
  value: string | number | null | undefined
  className?: string
}
