import styled from '@emotion/styled'

export const Wrapper = styled.div`
  .ant-picker {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    height: 30px;

    .ant-picker-input {
      & > input {
        text-align: center;
      }
    }

    &.error {
      border-color: #dc3545;
      background-color: #ffeeeb;
    }
  }
`

export const Pending = styled.div`
  color: #7c8291;

  .edit-icon {
    margin-left: 15px;
    cursor: pointer;
  }
`
