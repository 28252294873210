import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 0px;
  position: relative;

  .search-panel-collapse {
    background-color: white;
    border: none;

    &.show {
      .has-search-panel & {
        border-top: 1px solid #d4d8dd;
      }
    }

    .ant-collapse-item {
      .ant-collapse-header {
        display: none;
      }
      .ant-collapse-content {
        border-top: none;
        .ant-collapse-content-box {
          border-top: unset;
          padding: 0;
        }
      }
    }
  }
`

export const Body = styled.div`
  border-bottom: 1px solid #d4d8dd;
  margin: 15px 15px 0px 15px;
`

export const Footer = styled.div`
  padding: 10px;
  text-align: center;
`

export const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const Field = styled.div`
  margin-right: 30px;
  width: 140px;
  position: relative;
`

export const Label = styled.div`
  color: #333333;
  font-weight: bold;
  line-height: 22px;
  min-width: 50px;
  margin-bottom: 2px;
  white-space: nowrap;
`

export const Symbol = styled.div`
  position: absolute;
  top: 24px;
  left: -18px;
`

export const Anchor = styled.div`
  height: 33px;
  width: 137px;
  position: absolute;
  bottom: -32px;
  right: 15px;
  border: 1px solid #d4d8dd;
  border-top: none;
  background: #ffffff;
  color: #3b7de9;
  cursor: pointer;
  padding-top: 5px;
  padding-left: 15px;
  border-radius: 0px 0px 4px 4px;

  label {
    cursor: pointer;
  }

  &:hover {
    label {
      font-weight: bold;
    }

    .anticon {
      color: #3b7de9;
    }
  }

  .anticon {
    color: #7c8291;
    margin-left: 6px;
    margin-top: 2px;
  }
`
