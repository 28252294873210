import i18n from 'i18n'

export enum UpdateChangeMode {
  BEFORE = 'before',
  AFTER = 'after',
}

export enum OperationLogType {
  REGISTRATION = 1,
  UPDATE = 2,
  DELETE = 3,
  CORRECT = 4,
  REMOVE = 5,
  CORRECT_FA = 7,
}

export enum OperationLogTarget {
  FIXED_ASSET = 1,
  EVENT_OF_FIXED_ASSET = 2,
}

export const UPDATE_CHANGE_MODES = [
  {
    label: i18n.t('components.LogListItemDetail.before'),
    value: UpdateChangeMode.BEFORE,
  },
  {
    label: i18n.t('components.LogListItemDetail.after'),
    value: UpdateChangeMode.AFTER,
  },
]
