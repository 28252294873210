import styled from '@emotion/styled'
import { Button as AntButton } from 'antd'

export const Wrapper = styled.div`
  padding: 15px;
  width: 900px;
  font-size: 13px;
  line-height: 150%;
  color: #333333;
  background-color: #ffffff;

  p {
    margin-bottom: 0;
  }
`

export const ContentWrapper = styled.div`
  border-bottom: 1px solid #e2e2e2;
`

export const TopSection = styled.div`
  margin-bottom: 20px;

  a.ant-typography {
    color: #3b7de9;
  }
`

export const NoteSection = styled.div`
  padding: 0 15px;
  margin: 0 -15px;

  p {
    font-weight: 700;
  }

  ul {
    margin: 5px 0 15px 25px;
  }
`

export const ResultSection = styled.div`
  padding: 0 15px;
  margin: 0 -15px;

  p {
    font-weight: 700;
  }
`

export const ResultContent = styled.div`
  margin: 5px 0 15px;

  p {
    font-weight: 400;
  }

  span {
    margin-left: 10px;
  }
`

export const BottomSection = styled.div`
  padding-top: 15px;
  text-align: center;

  .ant-btn {
    &.ant-btn-primary {
      color: #ffffff;
      font-size: 13px;
      border-color: #3b7de9;
      background: linear-gradient(180deg, #3b7de9 0%, #0054ac 100%);
      border-radius: 4px;

      &:disabled {
        color: #00000040;
        border-color: #d9d9d9;
        background: #f5f5f5;
      }
    }
  }
`

export const ProgressBarWrapper = styled.div`
  width: 510px;
  margin: 0 auto;
  line-height: 150%;

  p {
    width: 297px;
    text-align: left;
    margin: 0 auto;
  }
`

export const ButtonStyled = styled(AntButton)`
  color: #3b7de9;
  font-size: 13px;
  border-radius: 4px;

  &:hover {
    color: #3b7de9;
    border: 1px solid #d4d8dd;
    background: linear-gradient(180deg, #eff1f4 0%, #ffffff 100%);
  }
`
