import React from 'react'
import get from 'lodash/get'
import { AssetTypeAmounts } from './asset-type-amounts'

import svgBg from './bg.svg'

export default React.forwardRef(function Form({ isAllFixedAssetMode, ...props }, ref) {
  return (
    <svg ref={ref} viewBox="0 0 968 685" fill="none" xmlns="http://www.w3.org/2000/svg">
      <image x="0" y="0" width="100%" href={svgBg} />
      <g id="$shinkokusho_02_a.pdf">
        {isAllFixedAssetMode && (
          <g data-testid="owner_code">
            <foreignObject x="665" y="65" width="228" height="25">
              <div data-id="submit_destination.owner_code" style={{ textAlign: 'center' }}>
                {get(props, 'submit_destination.owner_code')}
              </div>
            </foreignObject>
          </g>
        )}
        <g id="acquisition_cost_">
          <g id="summary.">
            <g id="total">
              <g id="[3]">
                <foreignObject x="632.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_summary.total[3]">
                    {get(props, 'acquisition_cost_summary.total[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]">
                <foreignObject x="601.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_summary.total[2]">
                    {get(props, 'acquisition_cost_summary.total[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]">
                <foreignObject x="570.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_summary.total[1]">
                    {get(props, 'acquisition_cost_summary.total[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]">
                <foreignObject x="539.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_summary.total[0]">
                    {get(props, 'acquisition_cost_summary.total[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="asset_types">
              <g id="[5].total">
                <g id="[3]_2">
                  <foreignObject x="632.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[5].total[3]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[5].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_2">
                  <foreignObject x="601.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[5].total[2]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[5].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_2">
                  <foreignObject x="570.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[5].total[1]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[5].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_2">
                  <foreignObject x="539.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[5].total[0]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[5].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[4].total">
                <g id="[3]_3">
                  <foreignObject x="632.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[4].total[3]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[4].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_3">
                  <foreignObject x="601.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[4].total[2]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[4].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_3">
                  <foreignObject x="570.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[4].total[1]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[4].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_3">
                  <foreignObject x="539.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[4].total[0]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[4].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[3].total">
                <g id="[3]_4">
                  <foreignObject x="632.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[3].total[3]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[3].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_4">
                  <foreignObject x="601.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[3].total[2]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[3].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_4">
                  <foreignObject x="570.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[3].total[1]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[3].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_4">
                  <foreignObject x="539.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[3].total[0]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[3].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[2].total">
                <g id="[3]_5">
                  <foreignObject x="632.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[2].total[3]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[2].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_5">
                  <foreignObject x="601.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[2].total[2]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[2].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_5">
                  <foreignObject x="570.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[2].total[1]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[2].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_5">
                  <foreignObject x="539.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[2].total[0]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[2].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[1].total">
                <g id="[3]_6">
                  <foreignObject x="632.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[1].total[3]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[1].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_6">
                  <foreignObject x="601.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[1].total[2]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[1].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_6">
                  <foreignObject x="570.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[1].total[1]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[1].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_6">
                  <foreignObject x="539.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[1].total[0]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[1].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[0].total">
                <g id="[3]_7">
                  <foreignObject x="632.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[0].total[3]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[0].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_7">
                  <foreignObject x="601.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[0].total[2]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[0].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_7">
                  <foreignObject x="570.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[0].total[1]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[0].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_7">
                  <foreignObject x="539.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_summary.asset_types[0].total[0]"
                    >
                      {get(props, 'acquisition_cost_summary.asset_types[0].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
            </g>
          </g>
          <g id="increased_current_year.">
            <g id="total_2">
              <g id="[3]_8">
                <foreignObject x="494.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_increased_current_year.total[3]">
                    {get(props, 'acquisition_cost_increased_current_year.total[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_8">
                <foreignObject x="463.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_increased_current_year.total[2]">
                    {get(props, 'acquisition_cost_increased_current_year.total[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_8">
                <foreignObject x="432.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_increased_current_year.total[1]">
                    {get(props, 'acquisition_cost_increased_current_year.total[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_8">
                <foreignObject x="401.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_increased_current_year.total[0]">
                    {get(props, 'acquisition_cost_increased_current_year.total[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="asset_types_2">
              <g id="[5].total_2">
                <g id="[3]_9">
                  <foreignObject x="494.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[5].total[3]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[5].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_9">
                  <foreignObject x="463.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[5].total[2]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[5].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_9">
                  <foreignObject x="432.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[5].total[1]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[5].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_9">
                  <foreignObject x="401.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[5].total[0]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[5].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[4].total_2">
                <g id="[3]_10">
                  <foreignObject x="494.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[4].total[3]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[4].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_10">
                  <foreignObject x="463.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[4].total[2]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[4].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_10">
                  <foreignObject x="432.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[4].total[1]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[4].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_10">
                  <foreignObject x="401.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[4].total[0]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[4].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[3].total_2">
                <g id="[3]_11">
                  <foreignObject x="494.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[3].total[3]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[3].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_11">
                  <foreignObject x="463.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[3].total[2]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[3].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_11">
                  <foreignObject x="432.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[3].total[1]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[3].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_11">
                  <foreignObject x="401.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[3].total[0]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[3].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[2].total_2">
                <g id="[3]_12">
                  <foreignObject x="494.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[2].total[3]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[2].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_12">
                  <foreignObject x="463.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[2].total[2]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[2].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_12">
                  <foreignObject x="432.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[2].total[1]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[2].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_12">
                  <foreignObject x="401.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[2].total[0]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[2].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[1].total_2">
                <g id="[3]_13">
                  <foreignObject x="494.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[1].total[3]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[1].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_13">
                  <foreignObject x="463.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[1].total[2]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[1].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_13">
                  <foreignObject x="432.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[1].total[1]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[1].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_13">
                  <foreignObject x="401.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[1].total[0]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[1].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[0].total_2">
                <g id="[3]_14">
                  <foreignObject x="494.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[0].total[3]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[0].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_14">
                  <foreignObject x="463.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[0].total[2]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[0].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_14">
                  <foreignObject x="432.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[0].total[1]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[0].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_14">
                  <foreignObject x="401.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_increased_current_year.asset_types[0].total[0]"
                    >
                      {get(props, 'acquisition_cost_increased_current_year.asset_types[0].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
            </g>
          </g>
          <g id="decreased_current_year.">
            <g id="total_3">
              <g id="[3]_15">
                <foreignObject x="352.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_decreased_current_year.total[3]">
                    {get(props, 'acquisition_cost_decreased_current_year.total[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_15">
                <foreignObject x="321.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_decreased_current_year.total[2]">
                    {get(props, 'acquisition_cost_decreased_current_year.total[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_15">
                <foreignObject x="290.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_decreased_current_year.total[1]">
                    {get(props, 'acquisition_cost_decreased_current_year.total[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_15">
                <foreignObject x="259.415" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_decreased_current_year.total[0]">
                    {get(props, 'acquisition_cost_decreased_current_year.total[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="asset_types_3">
              <g id="[5].total_3">
                <g id="[3]_16">
                  <foreignObject x="352.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[5].total[3]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[5].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_16">
                  <foreignObject x="321.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[5].total[2]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[5].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_16">
                  <foreignObject x="290.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[5].total[1]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[5].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_16">
                  <foreignObject x="259.415" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[5].total[0]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[5].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[4].total_3">
                <g id="[3]_17">
                  <foreignObject x="352.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[4].total[3]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[4].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_17">
                  <foreignObject x="321.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[4].total[2]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[4].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_17">
                  <foreignObject x="290.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[4].total[1]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[4].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_17">
                  <foreignObject x="259.415" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[4].total[0]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[4].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[3].total_3">
                <g id="[3]_18">
                  <foreignObject x="352.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[3].total[3]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[3].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_18">
                  <foreignObject x="321.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[3].total[2]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[3].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_18">
                  <foreignObject x="290.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[3].total[1]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[3].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_18">
                  <foreignObject x="259.415" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[3].total[0]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[3].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[2].total_3">
                <g id="[3]_19">
                  <foreignObject x="352.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[2].total[3]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[2].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_19">
                  <foreignObject x="321.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[2].total[2]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[2].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_19">
                  <foreignObject x="290.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[2].total[1]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[2].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_19">
                  <foreignObject x="259.415" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[2].total[0]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[2].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[1].total_3">
                <g id="[3]_20">
                  <foreignObject x="352.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[1].total[3]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[1].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_20">
                  <foreignObject x="321.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[1].total[2]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[1].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_20">
                  <foreignObject x="290.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[1].total[1]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[1].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_20">
                  <foreignObject x="259.415" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[1].total[0]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[1].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[0].total_3">
                <g id="[3]_21">
                  <foreignObject x="352.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[0].total[3]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[0].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_21">
                  <foreignObject x="321.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[0].total[2]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[0].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_21">
                  <foreignObject x="290.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[0].total[1]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[0].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_21">
                  <foreignObject x="259.415" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_decreased_current_year.asset_types[0].total[0]"
                    >
                      {get(props, 'acquisition_cost_decreased_current_year.asset_types[0].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
            </g>
          </g>
          <g id="previous_year.">
            <g id="total_4">
              <g id="[3]_22">
                <foreignObject x="213.533" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_previous_year.total[3]">
                    {get(props, 'acquisition_cost_previous_year.total[3]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[2]_22">
                <foreignObject x="182.533" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_previous_year.total[2]">
                    {get(props, 'acquisition_cost_previous_year.total[2]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[1]_22">
                <foreignObject x="151.533" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_previous_year.total[1]">
                    {get(props, 'acquisition_cost_previous_year.total[1]')}
                  </div>
                </foreignObject>
              </g>
              <g id="[0]_22">
                <foreignObject x="120.533" y="468.485" width="29.7027" height="16.4437">
                  <div xmlns="http://www.w3.org/1999/xhtml" data-id="acquisition_cost_previous_year.total[0]">
                    {get(props, 'acquisition_cost_previous_year.total[0]')}
                  </div>
                </foreignObject>
              </g>
            </g>
            <g id="asset_types_4">
              <g id="[5].total_4">
                <g id="[3]_23">
                  <foreignObject x="213.533" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[5].total[3]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[5].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_23">
                  <foreignObject x="182.533" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[5].total[2]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[5].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_23">
                  <foreignObject x="151.533" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[5].total[1]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[5].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_23">
                  <foreignObject x="120.533" y="444.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[5].total[0]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[5].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[4].total_4">
                <g id="[3]_24">
                  <foreignObject x="213.533" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[4].total[3]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[4].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_24">
                  <foreignObject x="182.533" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[4].total[2]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[4].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_24">
                  <foreignObject x="151.533" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[4].total[1]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[4].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_24">
                  <foreignObject x="120.533" y="418.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[4].total[0]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[4].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[3].total_4">
                <g id="[3]_25">
                  <foreignObject x="213.533" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[3].total[3]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[3].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_25">
                  <foreignObject x="182.533" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[3].total[2]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[3].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_25">
                  <foreignObject x="151.533" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[3].total[1]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[3].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_25">
                  <foreignObject x="120.533" y="393.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[3].total[0]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[3].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[2].total_4">
                <g id="[3]_26">
                  <foreignObject x="213.533" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[2].total[3]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[2].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_26">
                  <foreignObject x="182.533" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[2].total[2]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[2].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_26">
                  <foreignObject x="151.533" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[2].total[1]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[2].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_26">
                  <foreignObject x="120.533" y="368.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[2].total[0]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[2].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[1].total_4">
                <g id="[3]_27">
                  <foreignObject x="213.533" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[1].total[3]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[1].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_27">
                  <foreignObject x="182.533" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[1].total[2]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[1].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_27">
                  <foreignObject x="151.533" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[1].total[1]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[1].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_27">
                  <foreignObject x="120.533" y="343.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[1].total[0]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[1].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
              <g id="[0].total_4">
                <g id="[3]_28">
                  <foreignObject x="213.533" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[0].total[3]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[0].total[3]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[2]_28">
                  <foreignObject x="182.533" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[0].total[2]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[0].total[2]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[1]_28">
                  <foreignObject x="151.533" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[0].total[1]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[0].total[1]')}
                    </div>
                  </foreignObject>
                </g>
                <g id="[0]_28">
                  <foreignObject x="120.533" y="318.485" width="29.7027" height="16.4437">
                    <div
                      xmlns="http://www.w3.org/1999/xhtml"
                      data-id="acquisition_cost_previous_year.asset_types[0].total[0]"
                    >
                      {get(props, 'acquisition_cost_previous_year.asset_types[0].total[0]')}
                    </div>
                  </foreignObject>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="submit_destination.">
          <g id="remark">
            <foreignObject x="575" y="515.511" width="315" height="72.0179">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.remark">
                {get(props, 'submit_destination.remark')}
              </div>
            </foreignObject>
          </g>
          <g id="owner_category_of_building_of_company_rented_house">
            {get(props, 'submit_destination.owner_category_of_building_of_company_rented_house') && (
              <ellipse cx="872.5" cy="466.5" rx="12.5" ry="9.5" stroke="#333333"></ellipse>
            )}
          </g>
          <g id="owner_category_of_building_of_company_self_owned">
            {get(props, 'submit_destination.owner_category_of_building_of_company_self_owned') && (
              <ellipse cx="834.5" cy="467.5" rx="23.5" ry="9.5" stroke="#333333"></ellipse>
            )}
          </g>
          <g id="lender_name">
            <foreignObject x="728" y="399" width="162" height="48">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.lender_name">
                {get(props, 'submit_destination.lender_name')}
              </div>
            </foreignObject>
          </g>
          <g id="has_borrow_asset">
            {!get(props, 'submit_destination.has_borrow_asset') && (
              <circle cx="706" cy="430.5" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_borrow_asset') && (
              <circle cx="682.851" cy="430.5" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="asset_location_">
            <g id="3">
              <foreignObject x="739.545" y="344.745" width="149.455" height="15.9181">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="submit_destination.asset_location_3"
                  data-js-autofontsize="true"
                >
                  {get(props, 'submit_destination.asset_location_3')}
                </div>
              </foreignObject>
            </g>
            <g id="2">
              <foreignObject x="739.545" y="318.745" width="149.455" height="15.9181">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="submit_destination.asset_location_2"
                  data-js-autofontsize="true"
                >
                  {get(props, 'submit_destination.asset_location_2')}
                </div>
              </foreignObject>
            </g>
            <g id="1">
              <foreignObject x="739.545" y="294.745" width="149.455" height="15.9181">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="submit_destination.asset_location_1"
                  data-js-autofontsize="true"
                >
                  {get(props, 'submit_destination.asset_location_1')}
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="has_blue_return">
            {!get(props, 'submit_destination.has_blue_return') && (
              <circle cx="881.5" cy="271.656" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_blue_return') && (
              <circle cx="858.351" cy="271.656" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="has_depreciation_method_on_ta_for_slm">
            {get(props, 'submit_destination.has_depreciation_method_on_ta_for_slm') && (
              <ellipse cx="881.027" cy="246.667" rx="9.9617" ry="6.30037" stroke="#333333"></ellipse>
            )}
          </g>
          <g id="has_depreciation_method_on_ta_for_dbm">
            {get(props, 'submit_destination.has_depreciation_method_on_ta_for_dbm') && (
              <ellipse cx="858.462" cy="246.667" rx="9.9617" ry="6.30037" stroke="#333333"></ellipse>
            )}
          </g>
          <g id="has_special_depreciation_or_compress_entry">
            {!get(props, 'submit_destination.has_special_depreciation_or_compress_entry') && (
              <circle cx="881.5" cy="218.223" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_special_depreciation_or_compress_entry') && (
              <circle cx="858.351" cy="218.223" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="has_taxation_standard_special">
            {!get(props, 'submit_destination.has_taxation_standard_special') && (
              <circle cx="881.5" cy="190.989" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_taxation_standard_special') && (
              <circle cx="858.351" cy="190.989" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="has_non_taxable_asset">
            {!get(props, 'submit_destination.has_non_taxable_asset') && (
              <circle cx="881.5" cy="163.755" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_non_taxable_asset') && (
              <circle cx="858.351" cy="163.755" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="has_additional_depreciation_report">
            {!get(props, 'submit_destination.has_additional_depreciation_report') && (
              <circle cx="881.5" cy="136.521" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_additional_depreciation_report') && (
              <circle cx="858.351" cy="136.521" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="has_approval_of_shortened_service_life">
            {!get(props, 'submit_destination.has_approval_of_shortened_service_life') && (
              <circle cx="881.5" cy="109.287" r="8.5" stroke="#333333"></circle>
            )}
            {get(props, 'submit_destination.has_approval_of_shortened_service_life') && (
              <circle cx="858.351" cy="109.287" r="8.5" stroke="#333333"></circle>
            )}
          </g>
          <g id="tax_accountant_">
            <g id="telephone">
              <foreignObject x="564" y="272" width="133" height="11">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.tax_accountant_telephone">
                  {get(props, 'submit_destination.tax_accountant_telephone')}
                </div>
              </foreignObject>
            </g>
            <g id="name">
              <foreignObject x="532" y="242" width="176" height="27.3796">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.tax_accountant_name">
                  {get(props, 'submit_destination.tax_accountant_name')}
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="person_in_charge_">
            <g id="telephone_2">
              <foreignObject x="564" y="226" width="133" height="11">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.person_in_charge_telephone">
                  {get(props, 'submit_destination.person_in_charge_telephone')}
                </div>
              </foreignObject>
            </g>
            <g id="department_and_name">
              <foreignObject x="532" y="192" width="174.944" height="34">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="submit_destination.person_in_charge_department_and_name"
                >
                  {get(props, 'submit_destination.person_in_charge_department_and_name')}
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="company_established_at_">
            <g id="month">
              <foreignObject x="629" y="178" width="52" height="12">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.company_established_at_month">
                  {get(props, 'submit_destination.company_established_at_month')}
                </div>
              </foreignObject>
            </g>
            <g id="year">
              <foreignObject x="536" y="178" width="75" height="12">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.company_established_at_year">
                  {get(props, 'submit_destination.company_established_at_year')}
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="capital_amount">
            <foreignObject x="542" y="150" width="127" height="13">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.capital_amount">
                {get(props, 'submit_destination.capital_amount')}
              </div>
            </foreignObject>
          </g>
          <g id="industry_category">
            <foreignObject x="532.041" y="125" width="176.099" height="22.4728">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                data-id="submit_destination.industry_category"
                data-js-autofontsize="true"
              >
                {get(props, 'submit_destination.industry_category')}
              </div>
            </foreignObject>
          </g>
          <g id="corporate_code">
            <g id="[12]">
              <foreignObject x="694.54" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[12]">
                  {get(props, 'submit_destination.corporate_code[12]')}
                </div>
              </foreignObject>
            </g>
            <g id="[11]">
              <foreignObject x="680.54" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[11]">
                  {get(props, 'submit_destination.corporate_code[11]')}
                </div>
              </foreignObject>
            </g>
            <g id="[10]">
              <foreignObject x="667.54" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[10]">
                  {get(props, 'submit_destination.corporate_code[10]')}
                </div>
              </foreignObject>
            </g>
            <g id="[9]">
              <foreignObject x="654.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[9]">
                  {get(props, 'submit_destination.corporate_code[9]')}
                </div>
              </foreignObject>
            </g>
            <g id="[8]">
              <foreignObject x="641.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[8]">
                  {get(props, 'submit_destination.corporate_code[8]')}
                </div>
              </foreignObject>
            </g>
            <g id="[7]">
              <foreignObject x="627.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[7]">
                  {get(props, 'submit_destination.corporate_code[7]')}
                </div>
              </foreignObject>
            </g>
            <g id="[6]">
              <foreignObject x="613.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[6]">
                  {get(props, 'submit_destination.corporate_code[6]')}
                </div>
              </foreignObject>
            </g>
            <g id="[5]">
              <foreignObject x="600.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[5]">
                  {get(props, 'submit_destination.corporate_code[5]')}
                </div>
              </foreignObject>
            </g>
            <g id="[4]">
              <foreignObject x="586.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[4]">
                  {get(props, 'submit_destination.corporate_code[4]')}
                </div>
              </foreignObject>
            </g>
            <g id="[3]_29">
              <foreignObject x="572.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[3]">
                  {get(props, 'submit_destination.corporate_code[3]')}
                </div>
              </foreignObject>
            </g>
            <g id="[2]_29">
              <foreignObject x="559.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[2]">
                  {get(props, 'submit_destination.corporate_code[2]')}
                </div>
              </foreignObject>
            </g>
            <g id="[1]_29">
              <foreignObject x="545.54" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[1]">
                  {get(props, 'submit_destination.corporate_code[1]')}
                </div>
              </foreignObject>
            </g>
            <g id="[0]_29">
              <foreignObject x="532.04" y="101.48" width="12" height="19.44">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.corporate_code[0]">
                  {get(props, 'submit_destination.corporate_code[0]')}
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="commercial_name">
            <foreignObject x="238" y="267" width="151" height="11">
              <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.commercial_name">
                {get(props, 'submit_destination.commercial_name')}
              </div>
            </foreignObject>
          </g>
          <g id="representative_">
            <g id="position">
              <foreignObject x="137" y="229" width="327.61" height="30">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  data-id="submit_destination.representative_position"
                  data-js-autofontsize="true"
                >
                  {get(props, 'submit_destination.representative_position')}
                  <span
                    data-id="submit_destination.representative_name"
                    data-furigana={get(props, 'submit_destination.representative_name_kana')}
                  >
                    {get(props, 'submit_destination.representative_name')}
                  </span>
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="company_name">
            <foreignObject x="137" y="187.5" width="327.31" height="39">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                data-id="submit_destination.company_name"
                data-furigana={get(props, 'submit_destination.company_name_kana')}
                data-js-autofontsize="true"
              >
                {get(props, 'submit_destination.company_name')}
              </div>
            </foreignObject>
          </g>
          <g id="owner_">
            <g id="telephone_3">
              <foreignObject x="299" y="163" width="145" height="14">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.owner_telephone">
                  {get(props, 'submit_destination.owner_telephone')}
                </div>
              </foreignObject>
            </g>
            <g id="address">
              <foreignObject x="136" y="127.5" width="327.504" height="32.5">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.owner_address">
                  {get(props, 'submit_destination.owner_address')}
                </div>
              </foreignObject>
            </g>
            <g id="zip_code">
              <foreignObject x="136" y="112" width="327.631" height="11">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.owner_zip_code">
                  {get(props, 'submit_destination.owner_zip_code')}
                </div>
              </foreignObject>
            </g>
            <g id="address_kana">
              <foreignObject x="136" y="98" width="327.631" height="11">
                <div xmlns="http://www.w3.org/1999/xhtml" data-id="submit_destination.owner_address_kana">
                  {get(props, 'submit_destination.owner_address_kana')}
                </div>
              </foreignObject>
            </g>
          </g>
          <g id="apply_destination">
            <foreignObject x="94" y="76" width="185" height="20">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                data-id="submit_destination.apply_destination"
                data-js-autofontsize="true"
              >
                {get(props, 'submit_destination.apply_destination')}
              </div>
            </foreignObject>
          </g>
        </g>
        <g id="year_2">
          <foreignObject x="428.449" y="40.0264" width="69.4903" height="14.9472">
            <div xmlns="http://www.w3.org/1999/xhtml" data-id="year">
              {get(props, 'year')}
            </div>
          </foreignObject>
        </g>
        {isAllFixedAssetMode && <AssetTypeAmounts assetTypeAmounts={props?.asset_type_amounts} />}
      </g>
    </svg>
  )
})
