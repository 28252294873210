import { css } from '@emotion/css'
import { getIn, useFormikContext } from 'formik'
import React, { useEffect } from 'react'

type Props = {
  name: string
  value: string | number | null
  width?: string
}

const HiddenField: React.FC<Props> = ({ name = '', value, width }) => {
  const { values, setFieldValue } = useFormikContext()
  const fieldValue = getIn(values, name)
  useEffect(() => {
    if (fieldValue !== value) {
      setFieldValue(name, value)
    }
  }, [value]) // eslint-disable-line

  return (
    <div
      className={css`
        width: ${width || 'unset'};
      `}
    >
      <input type="hidden" value={fieldValue || ''} />
    </div>
  )
}

export default HiddenField
