export const FETCH_SUBMIT_DESTINATIONS_REQUEST = 'FETCH_SUBMIT_DESTINATIONS_REQUEST'
export const FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH = 'FETCH_SUBMIT_DESTINATIONS_REQUEST_AUTH'
export const FETCH_SUBMIT_DESTINATIONS_SUCCESS = 'FETCH_SUBMIT_DESTINATIONS_SUCCESS'

export const CREATE_SUBMIT_DESTINATION_REQUEST = 'CREATE_SUBMIT_DESTINATION_REQUEST'
export const UPDATE_SUBMIT_DESTINATION_REQUEST = 'UPDATE_SUBMIT_DESTINATION_REQUEST'
export const SUBMIT_SUBMIT_DESTINATION_SUCCESS = 'SUBMIT_SUBMIT_DESTINATION_SUCCESS'
export const SUBMIT_SUBMIT_DESTINATION_FAILURE = 'SUBMIT_SUBMIT_DESTINATION_FAILURE'
export const HANDLE_SUBMIT_SUBMIT_DESTINATION_FAILURE = 'HANDLE_SUBMIT_SUBMIT_DESTINATION_FAILURE'

export const FETCH_SUBMIT_DESTINATION_REQUEST = 'FETCH_SUBMIT_DESTINATION_REQUEST'
export const FETCH_SUBMIT_DESTINATION_SUCCESS = 'FETCH_SUBMIT_DESTINATION_SUCCESS'

export const DELETE_SUBMIT_DESTINATION_REQUEST = 'DELETE_SUBMIT_DESTINATION_REQUEST'
export const DELETE_SUBMIT_DESTINATION_SUCCESS = 'DELETE_SUBMIT_DESTINATION_SUCCESS'
export const DELETE_SUBMIT_DESTINATION_FAILURE = 'DELETE_SUBMIT_DESTINATION_FAILURE'
export const IMPORT_SUBMIT_DESTINATION_REQUEST = 'IMPORT_SUBMIT_DESTINATION_REQUEST'
export const IMPORT_SUBMIT_DESTINATION_SUCCESS = 'IMPORT_SUBMIT_DESTINATION_SUCCESS'
export const IMPORT_SUBMIT_DESTINATION_FAILURE = 'IMPORT_SUBMIT_DESTINATION_FAILURE'
export const EXPORT_SUBMIT_DESTINATION_REQUEST = 'EXPORT_SUBMIT_DESTINATION_REQUEST'
export const EXPORT_SUBMIT_DESTINATION_SUCCESS = 'EXPORT_SUBMIT_DESTINATION_SUCCESS'
export const EXPORT_SUBMIT_DESTINATION_FAILURE = 'EXPORT_SUBMIT_DESTINATION_FAILURE'
export const CLEANUP_SUBMIT_DESTINATION = 'CLEANUP_SUBMIT_DESTINATION'
