import { DEFAULT_PAGE_NUMBER, PAGE_SIZE_COMMON } from 'common/constants'
import get from 'lodash/get'
import qs from 'qs'

export interface RequestParam {
  [key: string]: string | number
}

export const buildQueryFilterParams = (filters: any = {}): RequestParam => {
  const results: RequestParam = {}

  Object.keys(filters)?.forEach(key => {
    const filterFieldValue = get(filters, key)
    if (filterFieldValue) {
      results[`filter[${key}]`] = filterFieldValue
    }
  })

  return results
}

export const buildPaginationParams = (pageNumber = DEFAULT_PAGE_NUMBER, pageSize = PAGE_SIZE_COMMON) => ({
  'page[number]': pageNumber,
  'page[size]': pageSize,
})

export const convertSortValue = (sorts: string[] = []) => sorts.join(',') || undefined

export const objectToQueryParams = (obj: Record<any, any>) => {
  return qs.stringify(obj, { arrayFormat: 'comma' })
}
