export const FETCH_DEPARTMENTS_REQUEST = 'FETCH_DEPARTMENTS_REQUEST'
export const FETCH_DEPARTMENTS_REQUEST_AUTH = 'FETCH_DEPARTMENTS_REQUEST_AUTH'
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS'
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE'

export const FETCH_DEPARTMENTS_ON_CLOUD_REQUEST = 'FETCH_DEPARTMENTS_ON_CLOUD_REQUEST'
export const HANDLE_FETCH_DEPARTMENTS_ON_CLOUD = 'HANDLE_FETCH_DEPARTMENTS_ON_CLOUD'

export const CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST'

export const INSERT_DEPARTMENT_REQUEST = 'INSERT_DEPARTMENT_REQUEST'

export const FETCH_DEPARTMENT_REQUEST = 'FETCH_DEPARTMENT_REQUEST'
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS'

export const FETCH_DEPARTMENT_BY_BIID_REQUEST = 'FETCH_DEPARTMENT_BY_BIID_REQUEST'
export const FETCH_DEPARTMENT_BY_BIID_SUCCESS = 'FETCH_DEPARTMENT_BY_BIID_SUCCESS'

export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST'

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST'
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS'
export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE'
export const DELETE_DEPARTMENT_BY_ID_REQUEST = 'DELETE_DEPARTMENT_BY_ID_REQUEST'
export const DELETE_DEPARTMENT_BY_ID_SUCCESS = 'DELETE_DEPARTMENT_BY_ID_SUCCESS'
export const DELETE_DEPARTMENT_BY_ID_FAILURE = 'DELETE_DEPARTMENT_BY_ID_FAILURE'

export const SUBMIT_DEPARTMENT_SUCCESS = 'SUBMIT_DEPARTMENT_SUCCESS'
export const SUBMIT_DEPARTMENT_FAILURE = 'SUBMIT_DEPARTMENT_FAILURE'
export const HANDLE_SUBMIT_DEPARTMENT_FAILURE = 'HANDLE_SUBMIT_DEPARTMENT_FAILURE'
export const CLEAR_DEPARTMENT_ITEM_ERRORS = 'CLEAR_DEPARTMENT_ITEM_ERRORS'

export const IMPORT_DEPARTMENT_REQUEST = 'IMPORT_DEPARTMENT_REQUEST'
export const IMPORT_DEPARTMENT_SUCCESS = 'IMPORT_DEPARTMENT_SUCCESS'
export const IMPORT_DEPARTMENT_FAILURE = 'IMPORT_DEPARTMENT_FAILURE'

export const EXPORT_DEPARTMENT_REQUEST = 'EXPORT_DEPARTMENT_REQUEST'
export const EXPORT_DEPARTMENT_SUCCESS = 'EXPORT_DEPARTMENT_SUCCESS'
export const EXPORT_DEPARTMENT_FAILURE = 'EXPORT_DEPARTMENT_FAILURE'

export const CLEANUP_DEPARTMENT = 'CLEANUP_DEPARTMENT'
export const PARTIAL_CLEANUP_DEPARTMENT = 'PARTIAL_CLEANUP_DEPARTMENT'
