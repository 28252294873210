export const FETCH_INCREASE_DECREASE_REPORT_REQUEST = 'FETCH_INCREASE_DECREASE_REPORT_REQUEST'
export const FETCH_INCREASE_DECREASE_REPORT_SUCCESS = 'FETCH_INCREASE_DECREASE_REPORT_SUCCESS'
export const FETCH_INCREASE_DECREASE_REPORT_FAILURE = 'FETCH_INCREASE_DECREASE_REPORT_FAILURE'

export const EXPORT_INCREASE_DECREASE_REPORT_REQUEST = 'EXPORT_INCREASE_DECREASE_REPORT_REQUEST'
export const EXPORT_INCREASE_DECREASE_REPORT_SUCCESS = 'EXPORT_INCREASE_DECREASE_REPORT_SUCCESS'
export const EXPORT_INCREASE_DECREASE_REPORT_FAILURE = 'EXPORT_INCREASE_DECREASE_REPORT_FAILURE'

export const CLEANUP_INCREASE_DECREASE_REPORT = 'CLEANUP_INCREASE_DECREASE_REPORT'

export const ADD_INCREASE_DECREASE_REPORT_ROW_KEY = 'ADD_INCREASE_DECREASE_REPORT_ROW_KEY'
export const REMOVE_INCREASE_DECREASE_REPORT_ROW_KEY = 'REMOVE_INCREASE_DECREASE_REPORT_ROW_KEY'
export const UPDATE_INCREASE_DECREASE_REPORT_ROW_KEYS = 'UPDATE_INCREASE_DECREASE_REPORT_ROW_KEYS'
