import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  button {
    width: 26px;
    height: 100%;
    padding: 0;
    white-space: pre-wrap;
    transition: none !important;
    background: #e6eaed !important;
  }

  div.btn-text-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .ant-drawer & {
    .triangle-icon {
      transition: transform 50ms;
      transform-origin: 50% 50%;
    }
    .expand-text {
      display: inline;
    }
    .collapse-text {
      display: none;
    }
  }

  .ant-drawer.expanded & {
    .triangle-icon {
      transform: rotate(180deg);
    }
    .expand-text {
      display: none;
    }
    .collapse-text {
      display: inline;
    }
  }
`
