/* eslint-disable react/display-name */
import { combinedKeys } from 'aa_common/front-end/shared/helpers'
import { ColumnsType } from 'antd/es/table'
import PlaceholderImage from 'assets/images/image-placeholder.jpg'
import { ASSET_STATUSES, DATE_FORMAT, Permission } from 'common/constants'
import { yen } from 'common/helpers'
import instance, { loadNs } from 'common/i18n-config'
import { FixedAssetListItem, LedgerSetting } from 'common/models'
import CommentPopup from 'components/organisms/assets/AssetList/components/CommentPopup'
import TagPopup from 'components/organisms/assets/AssetList/components/TagPopup'
import { find, isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import { AssetCodeTemplate, TextHighlightTemplate } from '../components/tables/columnTemplates'
import { FixedAssetTableImage } from '../styles'
import { generateColumns, renderDefaultSort } from './helper'

const t = loadNs(['pages/assets'])

const StatusTemplate = ({ data, filterAt }: { data: any; filterAt: string }) => {
  const masterData = useSelector(selectMasterData, isEqual)
  const { assetStatus, acquiredAt } = data
  const validAt = moment(filterAt)
  const assetAcquiredAt = moment(acquiredAt)

  let renderText = ''
  const isToBeAcquired = assetAcquiredAt.isAfter(validAt) && assetStatus?.code === ASSET_STATUSES.ACQUIRED_BEFORE_USE
  if (isToBeAcquired) {
    const toBeAcquired = find(masterData.asset_status, item => item.code === ASSET_STATUSES.TO_BE_ACQUIRED)
    renderText = (instance.language === 'ja' ? toBeAcquired?.name_jp : toBeAcquired?.name_en) || ''
  } else {
    renderText = (instance.language === 'ja' ? assetStatus?.name_jp : assetStatus?.name_en) || ''
  }

  return <span>{renderText}</span>
}
const DateTemplate = (date: string) => <span>{`${date ? moment(date).format(DATE_FORMAT) : ''}`}</span>
const MoneyTemplate = (amount: string | number) => <span>{yen(amount)}</span>
const TableImage = (photos: any[]) => {
  const hasImage = photos && photos.length > 0
  return (
    <div style={{ display: 'flex' }}>
      <FixedAssetTableImage preview={false} src={hasImage ? photos[0].thumbnail_url : ''} fallback={PlaceholderImage} />
    </div>
  )
}
const CommentTemplate = ({ item, permissions }: { item: any; permissions: Permission }) => {
  return <CommentPopup item={item} permissions={permissions} />
}

const getTitle = (key: string) => t(`table.field_name.${key}`)

export const fixedAssetTableColumns = ({
  ledgerSettings,
  filterAt,
  searchWords,
  sorter,
  permissions,
}: {
  searchWords: string[]
  filterAt: string
  ledgerSettings: LedgerSetting[]
  sorter: any
  activePopup?: any
  permissions: Permission
}): ColumnsType<any> => {
  return [
    {
      title: <div style={{ textAlign: 'center' }}>{getTitle('photo')}</div>,
      width: 60,
      dataIndex: 'photos',
      key: 'photos',
      fixed: 'left',
      className: 'fixed-asset-photo',
      render: TableImage,
    },
    {
      title: getTitle('asset_code'),
      width: 110,
      key: combinedKeys(['code', 'branch_code']),
      fixed: 'left',
      sorter: true,
      defaultSortOrder: renderDefaultSort(sorter, combinedKeys(['code', 'branch_code'])),
      showSorterTooltip: false,
      render: data => <AssetCodeTemplate data={data} searchWords={searchWords} />,
    },
    {
      title: getTitle('name'),
      dataIndex: 'name',
      key: 'name',
      width: 170,
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'name'),
      fixed: 'left',
      render: data => <TextHighlightTemplate data={data} searchWords={searchWords} />,
    },
    {
      title: getTitle('acquired_at'),
      dataIndex: 'acquired_at',
      key: 'acquired_at',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'acquired_at'),
      width: 110,
      render: DateTemplate,
    },
    {
      title: <span style={{ whiteSpace: 'pre-line' }}>{getTitle('used_at')}</span>,
      dataIndex: 'usage_started_at',
      key: 'usage_started_at',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'usage_started_at'),
      width: 110,
      render: DateTemplate,
    },
    {
      title: getTitle('acquisition_cost'),
      key: 'acquisition_cost',
      showSorterTooltip: false,
      width: 110,
      className: 'asset-table-money',
      render: (data: FixedAssetListItem) => {
        const isExcluded = data.is_excluded
        return MoneyTemplate(isExcluded ? data.acquisition_cost_excluded_excise : data.acquisition_cost)
      },
    },
    {
      title: getTitle('asset_category'),
      dataIndex: ['asset_category', 'name'],
      key: 'asset_category',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'asset_category'),
      width: 110,
    },
    {
      title: getTitle('department'),
      dataIndex: ['department', 'name'],
      key: 'department',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'department'),
      width: 110,
    },
    {
      title: getTitle('area'),
      dataIndex: ['area', 'name'],
      key: 'area',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'area'),
      width: 140,
    },
    ...generateColumns(sorter, ledgerSettings),
    {
      title: getTitle('asset_status'),
      dataIndex: 'asset_status',
      key: 'asset_status',
      sorter: false,
      width: 130,
      render: (assetStatus, record) => (
        <StatusTemplate data={{ assetStatus, acquiredAt: record?.acquired_at }} filterAt={filterAt} />
      ),
    },
    {
      title: getTitle('created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'created_at'),
      width: 110,
      render: DateTemplate,
    },
    {
      title: getTitle('created_by'),
      dataIndex: ['user_info', 'display_name'],
      key: 'created_by',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: renderDefaultSort(sorter, 'created_by'),
      width: 110,
    },
    {
      sorter: false,
      width: 35,
      fixed: 'right',
      align: 'center',
      className: 'col-fixed-right',
      render: ({ tags }: any) => <TagPopup tags={tags} />,
      onCell: () => ({
        onClick: event => event.stopPropagation(),
      }),
    },
    {
      sorter: false,
      width: 50,
      fixed: 'right',
      render: (item: any) => <CommentTemplate item={item} permissions={permissions} />,
      onCell: () => ({
        onClick: event => event.stopPropagation(),
      }),
    },
  ]
}
