import styled from '@emotion/styled'

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  color: #333333;
  font-size: 13px;
  width: 100%;
`

export const Header = styled.thead`
  background-color: white;
  box-sizing: border-box;
  font-size: 12px;
`

export const Body = styled.tbody`
  background-color: #ffffff;
`

export const Row = styled.tr`
  &.draggable-row {
    opacity: 1;
    &:hover {
      cursor: grab;
    }
    &:active {
      &.dragging {
        cursor: grabbing;
      }
    }
    &.dragging {
      opacity: 0.5;
      cursor: grabbing;
    }
  }

  &.last {
    td {
      border-bottom: 1px solid #d4d8dd;

      &.first {
        border-bottom-left-radius: 4px;
      }

      &.last {
        border-bottom-right-radius: 4px;
        border-right: 1px solid #d4d8dd;
      }
    }
  }
`

export const HeaderCell = styled.th`
  padding: 10px 5px;
  white-space: pre-line;
  border-top: 1px solid #d4d8dd;
  text-align: left;

  &.cell-sortable {
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
    }
  }

  &.first {
    border-top-left-radius: 4px;
    border-left: 1px solid #d4d8dd;
    width: 20px;
    padding-left: 10px;
  }

  &.last {
    border-right: 1px solid #d4d8dd;
    border-top-right-radius: 4px;
    width: 108px;
  }

  span {
    font-size: 13px;
    color: #333333;
  }
`

export const Cell = styled.td`
  padding: 10px 5px 8px;
  vertical-align: top;
  white-space: pre-line;
  border-top: 1px solid #d4d8dd;

  &.first {
    box-sizing: border-box;
    vertical-align: top;
    border-left: 1px solid #d4d8dd;
    padding-left: 10px;

    &.sortable {
      background-color: white;
      vertical-align: middle;
      text-align: center;
      &.disabled {
        .icon {
          opacity: 0.5;
        }

        &:hover {
          cursor: not-allowed;
        }
      }

      &:hover {
        cursor: grab;
      }
      &:active {
        .dragging & {
          cursor: grabbing;
        }
      }
    }
  }

  &.last {
    text-align: center;
    border-right: 1px solid #d4d8dd;
  }
`

export const Icons = styled.div`
  display: flex;
  justify-content: center;

  .table-action {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`
