import { Button } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { RangeInput } from 'components/molecules'
import React, { useCallback, useEffect } from 'react'

import { BaseFormFilter } from '../../model'
import { ActionButtonWrapper, Field, Label, MoneyAmountWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

const serviceLifeLimit = {
  min: 2,
  max: 100,
}

const ServiceLifeForm = ({ formModule, setPopupShow, onFormSubmit }: BaseFormFilter) => {
  const service_life = formModule.watch('service_life')

  useEffect(() => {
    if (!service_life) {
      formModule.setValue('service_life', [serviceLifeLimit.min, serviceLifeLimit.max])
    }
  }, [service_life]) // eslint-disable-line

  const { handleSubmit } = formModule

  const handleFormSubmit = useCallback(() => handleSubmit(onFormSubmit, onFormSubmit)(), [handleSubmit, onFormSubmit])

  const handleFormReset = () => {
    formModule.reset({
      service_life: null,
    })
  }

  const handleClickSubmit = () => {
    const { getValues } = formModule

    const submitFormValues = {
      service_life: getValues('service_life'),
    }
    formModule.setValue('submitFormValues', submitFormValues)
    handleFormSubmit()
    setPopupShow(false)
  }

  return (
    <MoneyAmountWrapper>
      <Field>
        <Label htmlFor="service_life">{t('filterSection.service_life')}</Label>
        <RangeInput
          type="number"
          name="service_life"
          control={formModule.control}
          range={serviceLifeLimit}
          suffix={t('units.year')}
        />
      </Field>
      <ActionButtonWrapper>
        <Button type="button" style={{ marginTop: 10 }} color="grey" onClick={handleFormReset}>
          {t('actions.clear')}
        </Button>
        <Button type="button" style={{ marginTop: 10 }} onClick={handleClickSubmit}>
          {t('actions.ok')}
        </Button>
      </ActionButtonWrapper>
    </MoneyAmountWrapper>
  )
}

export default ServiceLifeForm
