/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAssignRolesForUserRequest,
  PresenterCreateOfficeUserRequest,
  PresenterError,
  PresenterGetPermissionResponse,
  PresenterIDResponse,
  PresenterInviteUserRequest,
  PresenterOneOfficeUserResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class OfficeUsers<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get office user list
   *
   * @tags office_users
   * @name CreatableOfficeUsersList
   * @summary Get office user list from Navis
   * @request GET:/creatable_office_users
   */
  creatableOfficeUsersList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeUserResponse[], any>({
      path: `/creatable_office_users`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get office users list
   *
   * @tags office_users
   * @name OfficeUsersList
   * @summary Get office users list
   * @request GET:/office_users
   */
  officeUsersList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeUserResponse[], any>({
      path: `/office_users`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create office user
   *
   * @tags office_users
   * @name OfficeUsersCreate
   * @summary Create office user
   * @request POST:/office_users
   */
  officeUsersCreate = (body: PresenterCreateOfficeUserRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeUserResponse, any>({
      path: `/office_users`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Assign roles for office user
   *
   * @tags office_users
   * @name AssignRolesCreate
   * @summary assign roles for office user
   * @request POST:/office_users/assign_roles
   */
  assignRolesCreate = (body: PresenterAssignRolesForUserRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeUserResponse, any>({
      path: `/office_users/assign_roles`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description InviteUser office_user
   *
   * @tags office_users
   * @name InviteCreate
   * @summary InviteUser the office_user
   * @request POST:/office_users/invite
   */
  inviteCreate = (body: PresenterInviteUserRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeUserResponse, PresenterError>({
      path: `/office_users/invite`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get user permission map
   *
   * @tags office_users
   * @name PermissionList
   * @summary Get user permission map
   * @request GET:/office_users/permission
   */
  permissionList = (params: RequestParams = {}) =>
    this.http.request<PresenterGetPermissionResponse, any>({
      path: `/office_users/permission`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetByID office_user
   *
   * @tags office_users
   * @name OfficeUsersDetail
   * @summary GetByID the office_user
   * @request GET:/office_users/{id}
   */
  officeUsersDetail = (id: number | string, params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeUserResponse, PresenterError>({
      path: `/office_users/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete office user by id
   *
   * @tags office_users
   * @name OfficeUsersDelete
   * @summary Delete office user
   * @request DELETE:/office_users/{id}
   */
  officeUsersDelete = (id: number | string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, any>({
      path: `/office_users/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
