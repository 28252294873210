import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/css'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1rem);
  }
`

export const DefaultClass = css`
  &.error {
    .aa-infinite-scroll__control {
      border-color: #dc3545;
      background-color: #ffeeeb;

      &:hover {
        border-color: #dc3545;
      }

      &--is-focused {
        border-color: #dc3545;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);

        &.aa-infinite-scroll__control--menu-is-open .aa-infinite-scroll__single-value {
          color: #bfbfbf;
        }
      }
    }
  }

  .aa-infinite-scroll__placeholder {
    color: #bfbfbf;
    font-size: 13px;
  }

  .aa-infinite-scroll__control {
    min-height: unset;
    height: 30px;
    transition: 0.3s all ease-in;

    &:hover {
      border-color: #40a9ff;
      .aa-infinite-scroll__value-container--has-value + .aa-infinite-scroll__indicators {
        .aa-infinite-scroll__clear-indicator {
          display: flex;
          padding: 0 8px 0 2px;
        }
        .aa-infinite-scroll__dropdown-indicator {
          display: none;
        }
      }
    }

    &--is-focused {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);

      &.aa-infinite-scroll__control--menu-is-open .aa-infinite-scroll__single-value {
        color: #bfbfbf;
      }
    }
  }

  .aa-infinite-scroll__menu {
    border-radius: 0;
    z-index: 3;

    &.menu {
      animation: ${fadeIn} 0.2s ease-in-out;
    }

    &.menu--close {
      animation: ${fadeOut} 0.2s ease-in-out;
    }
  }

  .aa-infinite-scroll__input-container {
    padding: 0;
  }

  .aa-infinite-scroll__single-value {
    z-index: 1;
  }

  .aa-infinite-scroll__value-container {
    height: 30px;
    &.aa-infinite-scroll__value-container--has-value + .aa-infinite-scroll__indicators {
      .aa-infinite-scroll__clear-indicator {
        display: none;
        color: #00000040;
        cursor: pointer;
        &:hover {
          color: #00000073;
        }
      }
    }
  }

  .aa-infinite-scroll__indicator-separator {
    display: none;
  }

  .aa-infinite-scroll__indicator {
    padding: 0;
    &.aa-infinite-scroll__dropdown-indicator {
      padding: 0 8px;
    }
  }
`
