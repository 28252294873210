import { AxiosResponse } from 'axios'
import { parseError, parseResponse } from 'common/helpers'
import {
  buildPaginationParams,
  buildQueryFilterParams,
  convertSortValue,
  objectToQueryParams,
  RequestParam,
} from 'common/helpers/request-helper'
import { SubmitDestinationsSearchPayload } from 'store/settings/submitDestination/model'

import base from './base'

export const search = ({ filters, sort, pageNumber, pageSize }: SubmitDestinationsSearchPayload) => {
  return base
    .get('/v1/submit_destinations', {
      params: {
        ...buildQueryFilterParams(filters),
        ...buildPaginationParams(pageNumber, pageSize),
        sort: convertSortValue(sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const searchAuth = (payload: SubmitDestinationsSearchPayload) => {
  return base
    .get('/v1/submit_destinations/get', {
      params: {
        ...buildPaginationParams(payload.pageNumber, payload.pageSize),
        ...buildQueryFilterParams(payload.filters),
        sort: convertSortValue(payload.sort || []),
      },
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const create = (data: any): Promise<AxiosResponse<any>> => {
  return base.post('/v1/submit_destinations', data)
}

export const get = (id: number): Promise<AxiosResponse<any>> => {
  return base.get(`/v1/submit_destinations/${id}`)
}

export const update = (id: number, data: any): Promise<AxiosResponse<any>> => {
  return base.put(`/v1/submit_destinations/${id}`, data)
}

export const destroy = (id: number, dryRun: boolean) => {
  return base.delete(`/v1/submit_destinations/${id}`, { params: { dry_run: dryRun } })
}

export const importFile = (charset: string, file: any): Promise<AxiosResponse<any>> => {
  const formData = new FormData()
  formData.append('charset', charset)
  formData.append('file', file)

  return base.post('/v1/submit_destinations/import_one', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const exportFile = (charset: string, params: RequestParam) => {
  const filter = objectToQueryParams(buildQueryFilterParams(params))
  return base.get(`/v1/export/submit_destinations?charset=${charset}&${filter}`, {
    responseType: 'blob',
  })
}
