import { CaretRightOutlined } from '@ant-design/icons'
import { Button } from 'aa_common/front-end/antd'
import { ifElse } from 'aa_common/front-end/helpers'
import { Role } from 'aa_common/front-end/models'
import { Collapse, Radio } from 'antd'
import classNames from 'classnames'
import { FEATURES_ID, ICON_DEFAULT_COLOR } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers/permission-helper'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import Input from 'components/atoms/v2/Input'
import React, { memo, useCallback, useEffect } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { selectUserPermission } from 'store/session/selectors'
import { createNewRoleCall, handleFetchRoleDetail, updateRoleCall } from 'store/settings/role/actions'

import { getChoices } from './contants'
import { handleDataPost } from './helper'
import {
  BtnClass,
  ButtonStyled,
  ColClass,
  CollapseClass,
  ColStyled,
  DividerStyled,
  FormClass,
  ItemStyled,
  NameLabelStyled,
  RadioClass,
  RowClass,
  RowStyled,
  Wrapper,
} from './styles'

const t = loadNs(['common', 'pages/settings/roles'])

const { Panel } = Collapse

interface RoleDetailFormProps {
  data: Role
  type: FORM_MODES
  biid?: string
}

interface IsolateButtonProps {
  text: string
  control: any
  type: FORM_MODES
  disabled: boolean
}

const IsolateButton = ({ text, control, type, disabled }: IsolateButtonProps) => {
  const name = useWatch({
    control,
    name: 'name',
    defaultValue: '',
  })
  return (
    <Button type="submit" className={classNames(BtnClass)} disabled={disabled || (type === FORM_MODES.NEW && !name)}>
      {text}
    </Button>
  )
}

const RoleDetailForm = ({ data: roleData, type, biid }: RoleDetailFormProps) => {
  const { control, register, handleSubmit, reset, setValue } = useForm()
  const { fields } = useFieldArray({
    control,
    name: 'categories',
  })
  const dispatch = useDispatch()
  const isViewMode = type === FORM_MODES.VIEW
  const { data: userPermissions } = useSelector(selectUserPermission)
  const { isCreate, isEdit } = handlePermissionByFeatureId(FEATURES_ID.SETTING_ROLES, userPermissions)

  useEffect(() => {
    return () => {
      dispatch(handleFetchRoleDetail(null))
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    reset(roleData)
  }, [reset, roleData])

  const onSubmit = (data: any) => {
    const { name, categories: categoriesMap } = data
    const dataPost = {
      ...roleData,
      name,
      categories: handleDataPost(roleData.categories, categoriesMap),
    }
    if (biid) {
      dispatch(updateRoleCall(biid, dataPost))
    } else {
      dispatch(createNewRoleCall(dataPost))
    }
  }

  const genRowHeader = () => (
    <RowStyled>
      <ColStyled className={classNames(ColClass, { first: true })} span={4} />
      {getChoices()?.map((choice, index) => (
        <ColStyled key={choice} className={classNames(ColClass, { isLongText: index === 3 })} span={4}>
          {choice}
        </ColStyled>
      ))}
    </RowStyled>
  )

  const onChange = (position: string, value: string) => {
    setValue(position, value)
  }

  const toEditPage = () => {
    biid && dispatch(changeLocation(`/setting/roles/${biid}/edit`))
  }

  const renderExpandIcon = useCallback(
    ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: ICON_DEFAULT_COLOR }} />,
    []
  )

  return (
    <Wrapper>
      {isViewMode && roleData?.editable && (
        <ButtonStyled disabled={!isEdit} className="add-new" color="grey" onClick={toEditPage}>
          {t('edit')}
        </ButtonStyled>
      )}
      {fields?.length ? (
        <form onSubmit={handleSubmit(onSubmit)} className={classNames(FormClass)}>
          {!isViewMode ? (
            <ItemStyled required label={t('role_name')}>
              <Input
                defaultValue=""
                name="name"
                maxLength={100}
                control={control}
                placeholder={t('placeholder.under_100')}
              />
            </ItemStyled>
          ) : (
            <NameLabelStyled>
              <span className="name-title">{t('role_name')}</span>
              <span className="name-content">{ifElse(roleData?.name, roleData?.name, '')}</span>
            </NameLabelStyled>
          )}
          <DividerStyled>{t('authority')}</DividerStyled>
          <Collapse className={classNames(CollapseClass)} expandIcon={renderExpandIcon} expandIconPosition="right">
            {fields.map((field: any, indexCate: number) => (
              <Panel header={field.category_name_jp} key={field.category_name_jp}>
                {genRowHeader()}
                {field.permissions.map((fieldPer: any, indexPer: number) => {
                  const { choices } = fieldPer
                  const defaultValue = Object.keys(choices).filter(keyChoice => choices[keyChoice] === true)[0]
                  return (
                    <Radio.Group
                      className={classNames(RadioClass)}
                      disabled={type === FORM_MODES.VIEW}
                      key={`categories.${fieldPer.feature_id}.${fieldPer.feature_id}`}
                      // @ts-ignore
                      {...register(`categories[${indexCate}].permission_${indexPer}`)}
                      defaultValue={defaultValue}
                      onChange={e => onChange(`categories[${indexCate}].permission_${indexPer}`, e.target.value)}
                    >
                      <RowStyled className={classNames(RowClass, { last: indexPer === field.permissions.length - 1 })}>
                        <ColStyled className={classNames(ColClass, { first: true })} span={4}>
                          {fieldPer.feature_name_jp}
                        </ColStyled>
                        {Object.keys(choices).map((keyChoice: string) => (
                          <ColStyled span={4} key={keyChoice}>
                            {choices[keyChoice] !== null ? <Radio value={keyChoice} /> : null}
                          </ColStyled>
                        ))}
                      </RowStyled>
                    </Radio.Group>
                  )
                })}
              </Panel>
            ))}
          </Collapse>
          {!isViewMode && (
            <IsolateButton
              disabled={type === FORM_MODES.NEW ? !isCreate : !isEdit}
              type={type}
              control={control}
              text={type === FORM_MODES.NEW ? t('create') : t('save')}
            />
          )}
        </form>
      ) : null}
    </Wrapper>
  )
}

export default memo(RoleDetailForm)
