export const selectAssetImportListData = (state: any): any[] => {
  return state.getIn(['assetImport', 'list', 'data'])
}

export const selectAssetImportListErrors = (state: any): any[] => {
  return state.getIn(['assetImport', 'list', 'errors'])
}

export const selectAssetImportItemData = (state: any): any => {
  return state.getIn(['assetImport', 'item', 'data'])
}
