import { Image } from 'antd'
import React from 'react'

import { Wrapper } from './styles'

type Props = {
  src: string
  style?: React.CSSProperties
}

const PhotoView: React.FC<Props> = ({ src, style }) => {
  return (
    <Wrapper style={style}>
      <Image src={src} />
    </Wrapper>
  )
}

export default PhotoView
