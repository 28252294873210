import styled from '@emotion/styled'
import { DatePicker } from 'antd'

export const Wrapper = styled.div`
  width: 900px;

  .add-new-modal {
    .ant-modal-title {
      display: flex;
      justify-content: space-between;
    }

    .item-form {
      margin-bottom: 15px;
    }
  }

  .copy-the-status {
    color: #3b7de9;
    font-size: 13px;
    cursor: pointer;
  }

  .copied {
    color: #c2d1fc;
    cursor: not-allowed;
  }

  .add-valid-to {
    .item-form {
      margin-bottom: 15px;
    }

    .label {
      width: auto;
      margin-right: 10px;
      margin-left: 15px;
    }
  }

  .wrapper-date-picker {
    max-width: 180px;
    width: 100%;
  }
`

export const ActionListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DetailInfoGroup = styled.div`
  display: flex;
  align-items: center;

  .ant-picker-disabled {
    background-color: #d4d8dd;
    color: #999999;
  }

  .display-status,
  .display-all-check-box {
    font-size: 13px;
  }
`

export const ButtonActionGroup = styled.div`
  display: flex;

  .ant-btn {
    min-width: 70px;
    margin-left: 6px;
  }

  .disabled {
    span {
      color: #999999;
    }
  }

  .btn-abolition {
    span {
      color: #ec4949;
    }
  }

  .btn-edit {
    color: #3b7de9;

    &:disabled {
      color: #999999;
    }
  }

  button {
    font-size: 13px;
  }
`

export const SearchFilterDatePicker = styled(DatePicker)`
  border-radius: 4px;
  height: 30px;

  .ant-picker-input {
    input {
      font-size: 13px;
    }
  }
`

export const Table = styled.div`
  width: 100%;
  margin: 5px 0;
  border-radius: 4px;
  border: solid 1px #d4d8dd;
  background-color: #ffffff;

  .items {
    display: flex;
    align-items: center;
    padding: 11px 10px;
  }

  .action-row {
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px;
  }
`

export const Row = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid #d4d8dd;
  display: flex;

  .label {
    width: 200px;
  }

  &:last-child {
    border-bottom: none;
  }

  .btn-action {
    margin-left: 10px;
    font-size: 13px;
    padding: 0;
  }

  .delete {
    color: #ec4949;
  }

  .edit {
    color: #3b7de9;
  }

  .disabled {
    color: #d4d8dd;
  }
`

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #333333;
`

export const ItemForm = styled.div`
  display: flex;
  align-items: center;

  .label {
    max-width: 171px;
    width: 100%;
  }

  .content {
    position: relative;
    width: 240px;
    max-width: 240px;

    .ant-input,
    .ant-select-selector,
    .ant-input {
      font-size: 13px;
    }
  }

  .required {
    position: absolute;
    top: 4px;
    left: -54px;
  }
`

export const EditActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #333333;
  padding: 15px 15px 0 15px;
  border-top: 1px solid #d4d8dd;
  margin-top: 15px;

  .btn-save {
    color: #ffffff;
    margin-left: 15px;

    span {
      padding: 0px 10px;
      font-size: 13px;
    }
  }
`

export const EmptyImage = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  padding-top: 15px;
  border-top: 1px solid #d4d8dd;
`
