import styled from '@emotion/styled'

export const Wrapper = styled.textarea`
  margin: 0;
  width: 100%;
  padding: 4px 7px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &:hover {
    border-color: #40a9ff;
  }
`
