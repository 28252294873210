/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateOfficeRequest,
  PresenterMFIDSessionResponse,
  PresenterOneCreatableOfficeResponse,
  PresenterOneOfficeResponse,
  PresenterSwitchOfficeRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Offices<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description creatable offices
   *
   * @tags offices
   * @name CreatableOfficesList
   * @summary Get creatable offices
   * @request GET:/creatable_offices
   */
  creatableOfficesList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneCreatableOfficeResponse[], any>({
      path: `/creatable_offices`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get office list
   *
   * @tags offices
   * @name OfficesList
   * @summary Get office list
   * @request GET:/offices
   */
  officesList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeResponse[], any>({
      path: `/offices`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create an office from Navis tenantID
   *
   * @tags offices
   * @name OfficesCreate
   * @summary Create an office from Navis tenant
   * @request POST:/offices
   */
  officesCreate = (body: PresenterCreateOfficeRequest, params: RequestParams = {}) =>
    this.http.request<PresenterMFIDSessionResponse[], any>({
      path: `/offices`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Switch office in JWT
   *
   * @tags offices
   * @name SwitchUpdate
   * @summary Switch Office
   * @request PUT:/offices/switch
   */
  switchUpdate = (body: PresenterSwitchOfficeRequest, params: RequestParams = {}) =>
    this.http.request<PresenterMFIDSessionResponse, any>({
      path: `/offices/switch`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Sync & Get office list
   *
   * @tags offices
   * @name SyncList
   * @summary Synchronize offices from Navis and Get office list
   * @request GET:/offices/sync
   */
  syncList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeResponse[], any>({
      path: `/offices/sync`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
