import styled from '@emotion/styled'

export const ActionWrapper = styled.div`
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button.add-new {
    background: linear-gradient(180deg, #3b7de9 0%, #0054ac 100%) !important;
    color: white !important;
  }
`
