import React from 'react'

import { IndeterminateLoading } from '../indeterminate-loading'
import { StyledWrapper } from './styles'

export const UnderlineText: React.FC<{ loading?: boolean; contentAlign?: 'left' | 'right'; width?: string }> = ({
  children,
  loading,
  contentAlign = 'left',
  width,
}) => {
  return (
    <StyledWrapper widthContent={width}>
      <div className={`underline-text-content ${contentAlign}`}>
        {children}
        {loading && (
          <div className="underline-loading">
            <IndeterminateLoading />
          </div>
        )}
      </div>
    </StyledWrapper>
  )
}
