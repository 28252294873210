import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Col, Row } from 'antd'

import Form from 'components/molecules/Form'
import { Button } from 'aa_common/front-end/antd'

const { Item } = Form

export const Wrapper = styled.div`
  position: relative;
  background: #fff;
  width: 900px;
  border: 1px solid #d4d8dd;
  border-bottom: none;
  border-radius: 4px;
  font-size: 13px;
  margin-top: 40px;
`

export const ItemStyled = styled(Item)`
  padding: 12px 16px 12px 0;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;

  input {
    width: 240px;
  }
`

export const DividerStyled = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid #d4d8dd;
  background: #f4f4f4;
  color: #7c8291;
`

export const ColStyled = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2px 0;
  height: 50px;
  font-size: 13px;
  border-left: 1px solid #ccc;
`

export const RowStyled = styled(Row)`
  border-bottom: 1px solid #ccc;
`

export const NameLabelStyled = styled.div`
  display: flex;
  padding: 15px 10px;
  border-bottom: 1px solid #d4d8dd;
  align-items: center;

  .name-title {
    width: 220px;
  }
  .name-content {
    width: calc(100% - 220px);
    word-break: break-all;
  }
`

export const ButtonStyled = styled(Button)`
  position: absolute;
  right: 0;
  top: -40px;
  min-width: 70px !important;
`

export const FormClass = css`
  position: relative;
  margin-bottom: 40px;
`

export const CollapseClass = css`
  border: none;
  .ant-collapse-header {
    background: #fff;
    font-size: 13px;
    padding-left: 10px !important;
  }
  .ant-collapse-content-box {
    background: #fafafa;
    padding: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

export const ColClass = css`
  &.first {
    border-left: none;
    justify-content: start;
    padding-left: 10px;
  }
  &.isLongText {
    letter-spacing: -1px;
  }
`

export const RowClass = css`
  &.last {
    border-bottom: none;
  }
`

export const RadioClass = css`
  width: 100%;

  .ant-radio-wrapper {
    width: 100%;
    height: 100%;
    .ant-radio {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export const BtnClass = css`
  min-width: 70px !important;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
`
