export const clearNullDataDeep = (data: any): any => {
  if (data === null) {
    return undefined
  }

  if (Array.isArray(data)) {
    return data.map(clearNullDataDeep)
  }

  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      if (data[key] === null) {
        return acc
      }

      return {
        ...acc,
        [key]: clearNullDataDeep(data[key]),
      }
    }, {})
  }

  return data
}
