import { UploadFile } from 'antd/lib/upload/interface'

export enum UPLOAD_ATTACHMENT_MODE {
  EDIT = 'edit',
  VIEW = 'view',
}

export interface ICoreUploadAttachment {
  name: string
  mode?: UPLOAD_ATTACHMENT_MODE
  value?: UploadFile[]
  onChange?: (fileList: UploadFile[]) => void
}
