import React from 'react'

export const useParamsQuery = (keys: string[]) => {
  const [data, setData] = React.useState<(string | undefined | null)[]>([])
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    keys.forEach(key => {
      if (params.has(key)) {
        setData([...data, params.get(key)])
      } else {
        setData([...data, undefined])
      }
    })
  }, [])
  return data
}
