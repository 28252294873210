import React from 'react'

import { Wrapper } from './styles'

type Props = {
  color?: string
}

const FileIcon: React.FC<Props> = ({ color = '#3B7DE9' }) => {
  return (
    <Wrapper>
      <svg width="12" height="17" viewBox="0 0 12 17" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 0.28418C0.675 0.28418 0.00750017 1.00418 0.00750017 1.88418L0 14.6842C0 15.5642 0.6675 16.2842 1.4925 16.2842H10.5C11.325 16.2842 12 15.5642 12 14.6842V5.08418L7.5 0.28418H1.5ZM6.75 5.88418V1.48418L10.875 5.88418H6.75Z" />
      </svg>
    </Wrapper>
  )
}

export default FileIcon
