import { CORPORATE_INCOME_TAX } from 'common/constants'
import get from 'lodash/get'

import { Cell } from './schema'

export const getPeriod = (reportData: any, xCoordinate: number[], yCoordinate: number[], height: number = 19) => [
  [
    { x: xCoordinate[0], y: yCoordinate[0], w: 65, h: height, value: get(reportData, 'era_years[0][0]', '') },
    { x: xCoordinate[1], y: yCoordinate[0], w: 22, h: height, value: get(reportData, 'era_years[0][1]', '') },
    { x: xCoordinate[2], y: yCoordinate[0], w: 30, h: height, value: get(reportData, 'era_years[0][2]', '') },
  ],
  [
    { x: xCoordinate[0], y: yCoordinate[1], w: 65, h: height, value: get(reportData, 'era_years[1][0]', '') },
    { x: xCoordinate[1], y: yCoordinate[1], w: 22, h: height, value: get(reportData, 'era_years[1][1]', '') },
    { x: xCoordinate[2], y: yCoordinate[1], w: 30, h: height, value: get(reportData, 'era_years[1][2]', '') },
  ],
]

export const getRow = (
  xCoordinates: number[],
  yCoordinate: number,
  cellWidth: number,
  height: number,
  reportData: any,
  rowIndex: number
) =>
  xCoordinates?.map((xCoordinate: number, index: number) => {
    return {
      x: xCoordinate,
      y: yCoordinate,
      w: cellWidth,
      h: height,
      value: get(reportData, `data[${index}][${rowIndex}]`, ''),
    }
  })
export const getReportGrid = (
  heightPerRow: number[],
  cellWidth: number,
  xCoordinates: number[],
  yCoordinate: number,
  reportData: any,
  reportType?: CORPORATE_INCOME_TAX
) => {
  const grid: Cell[][] = []
  heightPerRow?.forEach((height: number, index) => {
    if (reportType && +reportType === CORPORATE_INCOME_TAX.TABLE_16_TYPE_7 && index === heightPerRow.length - 1) {
      // calculate xCoordinates in last item
      xCoordinates[4] = xCoordinates[3] + (xCoordinates[4] - xCoordinates[3]) / 2
      grid.push(getRow(xCoordinates, yCoordinate, cellWidth, height, reportData, index))
    } else {
      grid.push(getRow(xCoordinates, yCoordinate, cellWidth, height, reportData, index))
    }
    yCoordinate += height
  })
  return grid
}
