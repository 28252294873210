import get from 'lodash/get'
import React from 'react'

import { detailReportSlice } from '../../corporate-income-tax-report-container/store'
import { FormData } from '../common-report/form-data'
import { getPeriod, getReportGrid } from '../common-report/helper'
import { SvgFormWrapper } from '../common-report/styles'
import reportBackground from './background.svg'

export const ReportForm = () => {
  const cellWidth = 86
  const yCoordinate = 91
  const heightPerRow: number[] = [
    28.5, 28.5, 28, 28.5, 28, 28.5, 31, 28, 28.5, 28, 28.5, 28, 28, 28.5, 28, 28.5, 28, 28, 28, 28.5, 28, 28, 28, 28.5,
    28, 42, 29, 28, 28, 28, 28, 28, 28.5,
  ]

  const xCoordinates: number[] = [287, 372, 457, 541, 625]

  const xCoordinatesPeriod: number[] = [403, 458, 480]
  const yCoordinatesPeriod: number[] = [54, 63]

  const reportData = detailReportSlice.useSlice(state => state?.data)

  const propsData = {
    reportData: getReportGrid(heightPerRow, cellWidth, xCoordinates, yCoordinate, reportData),
    reportPeriods: getPeriod(reportData, xCoordinatesPeriod, yCoordinatesPeriod),
    companyName: { x: 548, y: 47, w: 163, h: 45, value: get(reportData, 'office_name', '') },
    reportBackground,
  }

  return (
    <SvgFormWrapper>
      <FormData {...propsData} />
    </SvgFormWrapper>
  )
}
