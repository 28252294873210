import styled from '@emotion/styled/macro'

export const Wrapper = styled.div`
  display: inline-flex;
  color: #3b7de9;
  align-items: center;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`

export const Label = styled.label`
  margin-left: 4px;
  cursor: pointer;
  border-bottom: 1px solid #3b7de9;
`
