import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 900px;
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
`

export const Content = styled.p`
  margin: 0;
  padding: 10px;
`

export const FirstContent = styled(Content)`
  border-bottom: 1px solid #d4d8dd;
  background: #e7f1fd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`
