import { SearchOutlined } from '@ant-design/icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import Input from 'components/atoms/v2/Input'
import { List } from 'immutable'
import { filter } from 'lodash'
import VirtualList from 'rc-virtual-list'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { convertSelectOptionItems } from './helper'
import { useDisableRemoveAll, useDisableSelectAll, useHandleOptionChanged } from './hooks'
import { ICoreMultiSelectPicker } from './model'
import {
  EmptyWrapper,
  ListItem,
  MultiselectInputWrapper,
  MultiselectPickerActionWrapper,
  MultiselectPickerWrapper,
} from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

const CoreMultiSelectPicker = ({
  name,
  value,
  items,
  listHeight,
  renderOptions = item => {
    return <span>{item.label}</span>
  },
  onChange,
  onDataSourceChanged,
}: ICoreMultiSelectPicker) => {
  const [renderItems, setRenderItems] = useState<ReturnType<typeof convertSelectOptionItems>>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedValues, setSelectedValues] = useState<any[]>([])
  const valueRef = useRef<any>(null)

  useEffect(() => {
    setSelectedValues(prevValue => {
      const disabledItems = List(items)
        .filter(item => item.disabled)
        .map(item => item.value)
        .toArray()
      let result = List(prevValue)
      if (result.toArray().length > 0) {
        for (const disabledItem of disabledItems) {
          const deletedIndex = result.findIndex(item => item === disabledItem)
          if (deletedIndex >= 0) {
            result = result.delete(deletedIndex)
          }
        }
      }
      return result.toArray()
    })

    setTimeout(() => {
      onDataSourceChanged && onDataSourceChanged(valueRef.current)
    }, 100)
  }, [items]) //eslint-disable-line

  // update the value ref
  useEffect(() => {
    valueRef.current = selectedValues
  })

  useEffect(() => {
    const allItems = convertSelectOptionItems({ items, renderOptions, selectedValues })
    const filterItems = filter(
      allItems,
      item => item.optionSearchKey.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    )
    setRenderItems(filterItems)
  }, [selectedValues]) //eslint-disable-line

  useEffect(() => {
    setSelectedValues(!value ? [] : value)
  }, [value])

  const handleInputOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
      const allItems = convertSelectOptionItems({ items, renderOptions, selectedValues })
      const filterItems = filter(
        allItems,
        item => item.optionSearchKey.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      )
      setRenderItems(filterItems)
      setSearchValue(inputValue)
    },
    [items, selectedValues, renderOptions]
  )

  const handleOptionChanged = useHandleOptionChanged(selectedValues, setSelectedValues, onChange)

  const handleResetAll = useCallback(() => {
    if (searchValue !== '') {
      const allItems = convertSelectOptionItems({ items, renderOptions, selectedValues })
      const filterItems = filter(
        allItems,
        item => item.optionSearchKey.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      )
      const updatedSelectedValues = selectedValues.filter(
        selectedValue => filterItems.findIndex(item => selectedValue.value === item.value) === -1
      )
      setSelectedValues(updatedSelectedValues)
      onChange && onChange(updatedSelectedValues)
    } else {
      setSelectedValues([])
      onChange && onChange([])
    }
  }, [searchValue, items, selectedValues, renderOptions, onChange])

  const handleSelectAll = useCallback(() => {
    if (searchValue !== '') {
      const allItems = convertSelectOptionItems({ items, renderOptions, selectedValues })
      const filterItems = filter(
        allItems,
        item => item.optionSearchKey.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      )
      const filterDuplicate = filterItems.filter(
        item => selectedValues.findIndex(searchItem => item.value === searchItem.value) === -1
      )
      const updatedSelectedValue = [...selectedValues, ...filterDuplicate]
      setSelectedValues(updatedSelectedValue)
      onChange && onChange(updatedSelectedValue)
    } else {
      const values = items.filter((item: any) => item.value && !item.disabled)
      setSelectedValues(values)
      onChange && onChange(values)
    }
  }, [searchValue, items, selectedValues, renderOptions, onChange])

  const disableSelectAll = useDisableSelectAll(renderItems, selectedValues)

  const disableRemoveAll = useDisableRemoveAll(renderItems, selectedValues)

  return (
    <MultiselectPickerWrapper className={`multi-select-picker-${name}`}>
      <MultiselectInputWrapper>
        <Input
          value={searchValue}
          name={`multipicker-search-${name}`}
          className="multipicker-search"
          onChange={handleInputOnChange}
          allowClear
          suffix={<SearchOutlined style={{ color: '#999999' }} />}
        />
      </MultiselectInputWrapper>
      <MultiselectPickerActionWrapper>
        <Button type="link" disabled={disableSelectAll} onClick={handleSelectAll}>
          {t('select_all_selection')}
        </Button>
        <Button type="link" disabled={disableRemoveAll} onClick={handleResetAll}>
          {t('remove_all_selection')}
        </Button>
      </MultiselectPickerActionWrapper>
      {renderItems && renderItems.length > 0 ? (
        <VirtualList data={renderItems} itemHeight={30} height={listHeight} itemKey="key">
          {item => (
            <ListItem
              className={classNames({ selected: item.selected, disabled: item.disabled })}
              onClick={() => handleOptionChanged(item)}
            >
              <div className="option-content">{renderOptions(item)}</div>
              {item.selected && (
                <div className="option-selected-item">
                  <FontAwesomeIcon style={{ color: '#3B7DE9', marginRight: 5 }} icon={faCheck} />
                </div>
              )}
            </ListItem>
          )}
        </VirtualList>
      ) : (
        <EmptyWrapper>
          <span>{t('no_select_items')}</span>
        </EmptyWrapper>
      )}
    </MultiselectPickerWrapper>
  )
}

export default React.memo(CoreMultiSelectPicker)
