import { LedgerSettingsEditItem, LedgerSettingsItem } from 'common/models'

export interface ILedgerSettingsTable {
  data: LedgerSettingsItem[]
  readonly?: boolean
  loading?: boolean
  onEdit?: () => void
  onSubmitForm?: (values: LedgerSettingsEditItem[]) => void
}

export interface ILedgerSettingsViewTable {
  data: LedgerSettingsItem[]
  loading?: boolean
  onEdit?: () => void
}
export interface ILedgerSettingsEditTable {
  data: LedgerSettingsItem[]
  loading?: boolean
  onEdit?: () => void
  onSubmitForm?: (values: LedgerSettingsEditItem[]) => void
}

export enum DEPRECIATION_METHOD {
  CONTINUE_DEPRECIATION = 1,
  STOP_DEPRECIATION = 2,
}
