import { combineReducers } from 'redux-immutable'

import accountingPeriod from './accountingPeriod/reducer'
import accountItem from './accountItem/reducer'
import area from './area/reducer'
import assetCategory from './assetCategory/reducer'
import assetCode from './assetCode/reducer'
import cause from './cause/reducer'
import department from './department/reducer'
import excise from './excise/reducer'
import exciseSetting from './exciseSetting/reducer'
import ledgerSetting from './ledgerSetting/reducer'
import officeUser from './officeUser/reducer'
import role from './role/reducer'
import submitDestination from './submitDestination/reducer'

export default combineReducers({
  assetCategory,
  area,
  excise,
  submitDestination,
  cause,
  department,
  accountingPeriod,
  accountItem,
  ledgerSetting,
  assetCode,
  exciseSetting,
  role,
  officeUser,
})
