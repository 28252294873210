import { ColumnsType, ColumnType, SorterResult, TablePaginationConfig } from 'antd/es/table/interface'
import { List } from 'immutable'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { StyledComplexTable } from '../../shared'
import { Spin } from '../spin'
import { TableDropdownMenu } from '../table-dropdown-menu'
import { ComplexTableProps } from './model'

export const ComplexTable = ({
  topTemplate,
  className,
  tableLayout = 'auto',
  data,
  columns,
  expandableSettings = undefined,
  enableSelectRows = true,
  actions = true,
  loading = false,
  showHeader = true,
  fixedAction = true,
  selectRowType = 'checkbox',
  rowKey,
  fixedColumnsTableSettings,
  renderActions,
  rowSelectionCellRender,
  onRow,
  onSelectSingleRow,
  // TODO: Enhance row select change event
  onRowSelectedChanged,
  onRowClick,
  onRowMouseOver,
  onRowMouseLeave,
  onColumnSortChanged,
  onCopyClicked,
  onDeleteClicked,
  rowSelection,
  rowClassName,
}: PropsWithChildren<ComplexTableProps>) => {
  const [tableColumns, setTableColumns] = useState<ColumnsType<any>>()

  useEffect(() => {
    if (columns) {
      let newTableColumns = List(columns).toArray()
      if (typeof actions !== 'boolean') {
        const actionColumn = actions as ColumnType<any>
        newTableColumns = List(columns).push(actionColumn).toArray()
      } else if (actions) {
        newTableColumns = List(columns)
          .push({
            key: 'action',
            fixed: fixedAction ? 'right' : undefined,
            width: 30,
            className: 'table-actions',
            onCell: () => {
              return {
                onClick: event => event.stopPropagation(),
              }
            },
            // eslint-disable-next-line react/display-name
            render: (item: any) => {
              if (renderActions) {
                return renderActions(item)
              }
              return <TableDropdownMenu data={item} onCopy={onCopyClicked} onDelete={onDeleteClicked} />
            },
          })
          .toArray()
      }
      setTableColumns(newTableColumns)
    }
  }, [columns, actions, fixedAction, onCopyClicked, onDeleteClicked, renderActions])

  const handleTableChanged = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, (string | number | boolean)[] | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
      extra: any
    ) => {
      switch (extra.action) {
        case 'sort':
          onColumnSortChanged && onColumnSortChanged(sorter)
          break
        default:
      }
    },
    [onColumnSortChanged]
  )

  return data && tableColumns ? (
    <Spin loading={loading}>
      {topTemplate}
      <StyledComplexTable
        tableLayout={tableLayout}
        className={className}
        pagination={false}
        showHeader={showHeader}
        rowSelection={
          enableSelectRows
            ? {
                ...rowSelection,
                type: selectRowType,
                columnWidth: 36,
                checkStrictly: false,
                onSelect: (record, selected, selectedRows, event) => {
                  // TODO: enhancement selection
                  onSelectSingleRow && onSelectSingleRow(record, selected, selectedRows, event)
                },
                onChange: (selectedRowKeys, selectedRows) => {
                  // TODO: enhancement selection
                  onRowSelectedChanged && onRowSelectedChanged(selectedRowKeys, selectedRows)
                },
                renderCell: (value, record, index, originNode) => {
                  return rowSelectionCellRender ? rowSelectionCellRender(value, record, index, originNode) : originNode
                },
              }
            : undefined
        }
        onChange={handleTableChanged}
        columns={tableColumns}
        onRow={(record, rowIndex) => {
          let customOnRow = {}
          if (onRow) {
            customOnRow = onRow(record, rowIndex)
          }

          return {
            ...customOnRow,
            onClick: event => {
              const targetItem = event.target as Element
              const rowData: any = record

              const parentCheckboxWrapper = document
                .querySelector(`tr[data-row-key="${rowData?.id as string}"]`)
                ?.querySelector('.ant-table-selection-column')
              const ignoreRowSelectionClick = isChildrenElement(targetItem, parentCheckboxWrapper)

              const excludedRowClick =
                targetItem.classList.contains('ant-table-selection-column') || ignoreRowSelectionClick
              if (!excludedRowClick) {
                onRowClick && onRowClick({ event, record, rowIndex })
              }
            }, // click row
            onMouseEnter: event => onRowMouseOver && onRowMouseOver({ event, record, rowIndex }),
            onMouseLeave: event => onRowMouseLeave && onRowMouseLeave({ event, record, rowIndex }),
          }
        }}
        rowKey={rowKey || undefined}
        expandable={expandableSettings}
        dataSource={data.map((item: any) => ({ ...item, key: item.id }))}
        scroll={fixedColumnsTableSettings ? fixedColumnsTableSettings.scroll : undefined}
        rowClassName={rowClassName}
      />
    </Spin>
  ) : null
}

function isChildrenElement(obj: any, parentObj: any) {
  while (obj !== undefined && obj !== null && obj.tagName.toUpperCase() !== 'BODY') {
    if (obj === parentObj) {
      return true
    }
    obj = obj.parentNode
  }
  return false
}
