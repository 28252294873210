import * as ledgerSettingApi from 'api/app/ledgerSetting'
import { AxiosResponse } from 'axios'
import { LEDGER_PURPOSE } from 'common/constants'
import { parseError } from 'common/helpers'
import { LedgerSetting } from 'common/models'
import i18n from 'i18n'
import get from 'lodash/get'
import { call, put, takeLatest } from 'redux-saga/effects'
import { changeLocation, setAppMessage, showErrorsAppCall } from 'store/app/actions'

import { FetchLedgerSettingsListRequest, SaveLedgerSettingsListRequest } from './action.model'
import {
  fetchLedgerSettingsListFailure,
  fetchLedgerSettingsListSuccess,
  saveHasAccountantAndTaxLedger,
  saveLedgerSettingsListFailure,
  saveLedgerSettingsListSucess,
} from './actions'
import {
  FETCH_LEDGER_SETTINGS_LIST_REQUEST,
  FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH,
  SAVE_LEDGER_SETTINGS_LIST_REQUEST,
} from './constant'
import { convertArrayFieldsErrorPayload } from './helper'

function* fetchLedgerSettingsList({ payload = {} }: FetchLedgerSettingsListRequest) {
  const { data, errors } = yield call(ledgerSettingApi.get, payload)
  if (data) {
    const ledgerSettings = data?.data as LedgerSetting[]
    const hasAccountantLedger =
      ledgerSettings?.findIndex(ledgerSetting => ledgerSetting.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING) > -1
    const hasTaxLedger =
      ledgerSettings?.findIndex(ledgerSetting => ledgerSetting.ledger_purpose === LEDGER_PURPOSE.TAX) > -1

    yield put(saveHasAccountantAndTaxLedger(hasAccountantLedger && hasTaxLedger))
    yield put(fetchLedgerSettingsListSuccess(ledgerSettings || []))
  } else if (errors) {
    yield put(showErrorsAppCall(errors))
    yield put(fetchLedgerSettingsListFailure())
  }
}

function* fetchLedgerSettingsListAuth({ payload = {} }: FetchLedgerSettingsListRequest) {
  const { data, errors } = yield call(ledgerSettingApi.getAuth, payload)
  if (data) {
    const ledgerSettings = data?.data as LedgerSetting[]
    const hasAccountantLedger =
      ledgerSettings?.findIndex(ledgerSetting => ledgerSetting.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING) > -1
    const hasTaxLedger =
      ledgerSettings?.findIndex(ledgerSetting => ledgerSetting.ledger_purpose === LEDGER_PURPOSE.TAX) > -1

    yield put(saveHasAccountantAndTaxLedger(hasAccountantLedger && hasTaxLedger))
    yield put(fetchLedgerSettingsListSuccess(ledgerSettings || []))
  } else if (errors) {
    yield put(showErrorsAppCall(errors))
    yield put(fetchLedgerSettingsListFailure())
  }
}

function* saveLedgerSettingsList({ payload }: SaveLedgerSettingsListRequest) {
  try {
    const response: AxiosResponse = yield call(ledgerSettingApi.save, payload)
    yield put(saveLedgerSettingsListSucess(response?.data?.data || null))
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('components.LedgerSettings.updated_success'),
      })
    )
    yield put(changeLocation('/setting/ledgers'))
  } catch (error) {
    const response = get(error, 'response', null)
    if (response) {
      const { status } = response
      const responseError = get(error, 'response.data.errors')[0]
      const errorMessage = responseError.detail
      const formFieldErrors = get(error, 'response.data.errors')
      if (status === 422 || responseError.code === 'ERR_DUPLICATED_LEDGER_PURPOSE') {
        yield put(saveLedgerSettingsListFailure(payload, convertArrayFieldsErrorPayload(formFieldErrors)))
        yield put(
          setAppMessage({
            type: 'failure',
            content: errorMessage,
          })
        )
      } else if (status !== 422) {
        const responseError = get(error, 'response.data.errors')[0]
        const errorMessage = responseError.detail
        yield put(
          setAppMessage({
            type: 'failure',
            content: errorMessage,
          })
        )
        yield put(saveLedgerSettingsListFailure(payload, null))
      }
    } else {
      yield put(saveLedgerSettingsListFailure(payload, null))
    }
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_LEDGER_SETTINGS_LIST_REQUEST, fetchLedgerSettingsList)
  yield takeLatest(FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH, fetchLedgerSettingsListAuth)
  yield takeLatest(SAVE_LEDGER_SETTINGS_LIST_REQUEST, saveLedgerSettingsList)
}
