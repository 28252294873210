import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Button } from 'aa_common/front-end/antd'
import { Collapse } from 'antd'
import classNames from 'classnames'
import Form, { RenderFormProps } from 'components/molecules/Form'
import i18n from 'i18n'
import React, { useCallback, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { Anchor, Body, Field, Footer, Label, Row, Symbol, Wrapper } from './styles'

const { Panel } = Collapse

interface BaseFilterPanelProps {
  renderForm: (form: RenderFormProps) => JSX.Element
  initialValues: any
  loading?: boolean
  onSubmit?: (values: any) => void
}

interface FilterPanelProps extends React.FC<BaseFilterPanelProps> {
  Row: typeof Row
  Field: typeof Field
  Label: typeof Label
  Symbol: typeof Symbol
}

const FilterPanel: FilterPanelProps = ({ renderForm, initialValues, loading, onSubmit }: BaseFilterPanelProps) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  const handleSubmitForm = (values: any) => {
    onSubmit && onSubmit(values)
  }

  const toggleCollapse = useCallback(() => {
    setIsShow((prev: boolean) => {
      return !prev
    })
  }, [setIsShow])

  return (
    <Wrapper>
      <Collapse
        className={classNames('search-panel-collapse', { show: isShow, hidden: !isShow })}
        accordion
        activeKey={isShow ? 'search-panel' : undefined}
      >
        <Panel header="Form" key="search-panel">
          <Form loading={loading} value={initialValues} onFormSubmit={handleSubmitForm}>
            {formModule => {
              return (
                <CSSTransition in={isShow} timeout={300}>
                  <div>
                    <Body>{renderForm(formModule)}</Body>
                    <Footer>
                      <Button className="filter-search" type="submit" disabled={formModule.loading}>
                        {i18n.t('components.FilterPanel.search')}
                      </Button>
                    </Footer>
                  </div>
                </CSSTransition>
              )
            }}
          </Form>
        </Panel>
      </Collapse>
      <Anchor className="collapse-anchor" onClick={toggleCollapse}>
        <label>{i18n.t('components.FilterPanel.conditions')}</label>
        {isShow ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </Anchor>
    </Wrapper>
  )
}

FilterPanel.Row = Row
FilterPanel.Field = Field
FilterPanel.Label = Label
FilterPanel.Symbol = Symbol

export default FilterPanel
