import styled from '@emotion/styled'
import { ErrorMessage } from '@hookform/error-message'
import { Col, Typography } from 'antd'
import i18n from 'i18n'
import { cloneElement, PropsWithChildren, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { LabelRequired } from '../form-styles'

const { Text } = Typography
export type FormItemProps = {
  label?: ReactNode
  required?: boolean
  name: string
  element: any
  isEdit?: boolean
  span?: number
  style?: React.CSSProperties
}

export const FormItem = ({
  label,
  name,
  element,
  isEdit = true,
  span,
  required,
  style,
}: PropsWithChildren<FormItemProps>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <ColWrapper span={span} className="form-item" style={style}>
      {label && <Typography>{label}</Typography>}
      <Controller
        control={control}
        render={field => {
          return cloneElement(element, { ...field, disabled: !isEdit })
        }}
        name={name}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <ErrorText type="danger">{message}</ErrorText>}
      />
      {required && <LabelRequired>{i18n.t('common.required')}</LabelRequired>}
    </ColWrapper>
  )
}

const ColWrapper = styled(Col)`
  padding: 16px;
  .label {
    font-size: 14px;
    font-weight: 400;
  }
  input {
    border-radius: 6px;
  }
`

const ErrorText = styled(Text)`
  font-size: 12px;
`
