import styled from '@emotion/styled'

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .datepicker-section {
    display: flex;
    align-items: center;

    .ant-picker-footer-extra {
      padding: 0;
    }

    .datepicker {
      min-width: 180px;
    }

    .display-status {
      margin-left: 5px;
      font-size: 13px;
    }
  }
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;

  .actions {
    button {
      margin-bottom: 10px;
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

export const TopTemplateWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;

  .date-picker {
    .ant-picker-suffix {
      font-size: 18px;
      color: #7c8291;
    }
  }
`
