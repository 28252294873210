import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #d4d8dd;
  padding: 4px 0px;
`

export const Label = styled.div`
  color: #7c8291;
  font-weight: 700;
`

export const Value = styled.div`
  color: #333333;
  font-weight: 700;
`
