/* eslint-disable global-require */
import type { MessageApi } from 'antd/lib/message'

let messages: MessageApi
export function messageFn() {
  if (!messages) {
    messages = require('antd/lib/message').default as MessageApi
    messages.config({
      duration: 5,
      maxCount: 3,
    })
  }
  return messages
}
