import { Map } from 'immutable'

import {
  CLEANUP_TERM_PERIODS,
  CLEANUP_TERMS,
  CREATE_TERM_PERIODS_REQUEST,
  CREATE_TERM_PERIODS_SUCCESS,
  FETCH_CHANGED_TERM_FAILURE,
  FETCH_CHANGED_TERM_REQUEST,
  FETCH_CHANGED_TERM_SUCCESS,
  FETCH_TERM_FAILURE,
  FETCH_TERM_REQUEST,
  FETCH_TERM_SUCCESS,
  FETCH_TERMS_FAILURE,
  FETCH_TERMS_FULL_FAILURE,
  FETCH_TERMS_FULL_REQUEST,
  FETCH_TERMS_FULL_SUCCESS,
  FETCH_TERMS_REQUEST,
  FETCH_TERMS_SUCCESS,
  GENERATE_TERM_PERIODS_REQUEST,
  GENERATE_TERM_PERIODS_SUCCESS,
  SET_CURRENT_TERM_ID,
  SUBMIT_TERM_PERIODS_FAILURE,
  UPDATE_TERM_PERIOD_REQUEST,
  UPDATE_TERM_PERIOD_SUCCESS,
} from './actions'

const initialState = Map({
  terms: {
    data: null,
    loading: false,
  },
  fullTerms: {
    data: null,
    loading: false,
  },
  term: {
    data: null,
    loading: false,
  },
  termPeriods: Map({
    data: null,
    loading: false,
    errors: null,
  }),
  currentTermId: null,
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_TERMS_FULL_REQUEST:
      return state.setIn(['fullTerms', 'loading'], true)
    case FETCH_TERMS_FULL_SUCCESS:
      return state.setIn(['fullTerms', 'data'], action.payload).setIn(['fullTerms', 'loading'], false)
    case FETCH_TERMS_FULL_FAILURE:
      return state.setIn(['fullTerms', 'loading'], false)
    case FETCH_TERMS_REQUEST:
      return state.setIn(['terms', 'loading'], true)
    case FETCH_TERMS_SUCCESS:
      return state.setIn(['terms', 'data'], action.payload).setIn(['terms', 'loading'], false)
    case FETCH_TERMS_FAILURE:
      return state.setIn(['terms', 'loading'], false)
    case FETCH_TERM_REQUEST:
    case FETCH_CHANGED_TERM_REQUEST:
      return state.setIn(['term', 'loading'], true)
    case FETCH_TERM_SUCCESS:
    case FETCH_CHANGED_TERM_SUCCESS:
      return state.setIn(['term', 'data'], action.payload).setIn(['term', 'loading'], false)
    case FETCH_TERM_FAILURE:
    case FETCH_CHANGED_TERM_FAILURE:
      return state.setIn(['term', 'loading'], false)
    case GENERATE_TERM_PERIODS_REQUEST:
    case CREATE_TERM_PERIODS_REQUEST:
    case UPDATE_TERM_PERIOD_REQUEST:
      return state.setIn(['termPeriods', 'loading'], true)
    case GENERATE_TERM_PERIODS_SUCCESS:
    case CREATE_TERM_PERIODS_SUCCESS:
    case UPDATE_TERM_PERIOD_SUCCESS:
      return state
        .setIn(['termPeriods', 'loading'], false)
        .setIn(['termPeriods', 'errors'], null)
        .setIn(['termPeriods', 'data'], action.payload)
    case SUBMIT_TERM_PERIODS_FAILURE:
      return state.setIn(['termPeriods', 'loading'], false).setIn(['termPeriods', 'errors'], action.payload)
    case SET_CURRENT_TERM_ID:
      return state.setIn(['currentTermId'], action.payload)
    case CLEANUP_TERM_PERIODS:
      return state.setIn(['termPeriods', 'data'], null).setIn(['termPeriods', 'errors'], null)
    case CLEANUP_TERMS:
      return initialState
    default:
      return state
  }
}
