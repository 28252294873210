import styled from '@emotion/styled'

export const Wrapper = styled.label`
  &.color-notice {
    color: #7c8291;
  }

  &.color-disabled {
    color: #d4d8dd;
  }

  &.color-normal {
    color: #333333;
  }
`
