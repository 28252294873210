import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoCompleteSelect } from 'aa_common/front-end/antd'
import { loadNs } from 'common/i18n-config'
import { IFilterFacetSettingsData, IMasterData } from 'common/models'
import Input from 'components/atoms/v2/Input'
import { RenderFormProps } from 'components/molecules/Form'
import RangeInput from 'components/molecules/RangeInput'
import RangeDatePickerInput from 'components/molecules/v2/RangeDatePickerInput'
import { ASSET_CODE_FIELD, branchCodeField, codeField, nameField } from 'components/organisms/assets/AssetForm/const'
import { get } from 'lodash'
import React from 'react'

import { AssetCodeSectionWrapper, AutoCompleteSelectStyle, InputStyle, RangeDatePickerInputStyle } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet'])

interface IRenderFilterTemplate {
  field?: string
  masterData: IMasterData
  settingsData: IFilterFacetSettingsData
  formModule: RenderFormProps
}

const RenderFilterTemplate = ({ field, masterData, formModule }: IRenderFilterTemplate) => {
  const renderTemplate = () => {
    switch (field) {
      case 'name_kana':
      case 'summary':
      case 'acquire_destination_name':
      case 'memo':
      case nameField.text:
        return (
          <Input
            className={InputStyle}
            name={field}
            control={formModule.control}
            maxLength={field === nameField.text ? nameField.maxLength : undefined}
          />
        )

      case 'acquire_category':
      case 'asset_type_for_corporate_tax':
      case 'asset_type_for_depreciable_property_tax':
        return (
          <AutoCompleteSelect
            className={AutoCompleteSelectStyle}
            valueField="code"
            textField="name_jp"
            mode="multiple"
            items={get(masterData, field, [])}
            name={field}
            control={formModule.control}
          />
        )
      case 'quantity':
        return <RangeInput name={field} control={formModule.control} range={{ min: 0, max: 10000 }} />

      case 'created_at':
        return (
          <RangeDatePickerInput
            className={RangeDatePickerInputStyle}
            placeholder={t('select_date_placeholder')}
            name={field}
            control={formModule.control}
          />
        )
      case ASSET_CODE_FIELD:
        return (
          <AssetCodeSectionWrapper>
            <Input
              control={formModule.control}
              name={codeField.text}
              maxLength={codeField.maxLength}
              placeholder={t('otherFilterConditions.code')}
            />
            <FontAwesomeIcon icon={faMinus} />
            <Input
              control={formModule.control}
              name={branchCodeField.text}
              maxLength={branchCodeField.maxLength}
              placeholder={t('otherFilterConditions.branch_code')}
            />
          </AssetCodeSectionWrapper>
        )

      default:
        return null
    }
  }

  return renderTemplate()
}

export default RenderFilterTemplate
