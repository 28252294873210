import { css } from '@emotion/css'

export const TabButtons = css`
  > button,
  > a,
  > div {
    margin-left: 10px !important;
    font-size: 13px !important;
  }
  button {
    margin-right: 0 !important;
  }
`

export const LinkItem = css`
  cursor: 'pointer',
  color: '#3B7CE9'
`
