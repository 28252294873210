export enum PERMISSIONS {
  READ = 1,
  CREATE = 2,
  EDIT = 4,
  DELETE = 8,
  EXECUTE = 16,
}

export type Permission = {
  isRead: boolean
  isCreate: boolean
  isEdit: boolean
  isDelete: boolean
  isExecute: boolean
  isCRU: boolean
  isCRUD: boolean
}

export type Require = 'isRead' | 'isCreate' | 'isEdit' | 'isDelete' | 'isExecute' | 'isCRU' | 'isCRUD'
