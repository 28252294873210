import { ASSET_CHANGE_SITUATION_TYPES, EMPTY_SYMBOL } from 'common/constants'
import { FileLink } from 'components/atoms'
import get from 'lodash/get'
import React from 'react'

export const Attachments = ({ assetData }: any) => {
  let attachments = get(assetData, 'attachments') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    attachments = get(assetData, 'event_attachments') || []
  }

  if (attachments.length === 0) return EMPTY_SYMBOL

  return attachments.map((item: any) => (
    <div key={item?.id}>
      <FileLink file={item} maxLength={28} />
    </div>
  ))
}
