import styled from '@emotion/styled/macro'
import { Typography } from 'antd'

export const AntLink = styled(Typography.Link)`
  display: inline-flex;
  color: #3b7de9;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  > div {
    display: inline-block;
  }
`

export const Label = styled.label`
  margin-left: 4px;
  cursor: pointer;
  border-bottom: 1px solid #3b7de9;
`
