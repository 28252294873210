import { BreadcrumbNavigation } from 'components/molecules'
import JournalListReport from 'components/organisms/accounting/JournalListReport'
import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectJournalList } from 'store/accounting/journal/selectors'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { fetchExciseList, fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList, selectExciseList } from 'store/settings/excise/selectors'

import { convertJournalToTableFormat } from './helper'

const JournalListReportPage = () => {
  const dispatch = useDispatch()

  const journalList = useSelector(selectJournalList)
  const monthlyClosingCurrent = useSelector(selectMonthlyClosingCurrent)
  const exciseSettingByDate = useSelector(getExciseSettingsByDateList)
  const exciseList = useSelector(selectExciseList)

  useEffect(() => {
    dispatch(
      fetchExciseList({
        filters: {
          is_active: true,
        },
      })
    )
  }, []) // eslint-disable-line

  useEffect(() => {
    if (monthlyClosingCurrent === null) {
      dispatch(fetchMonthlyClosingCurrent())
    }
  }, [monthlyClosingCurrent, dispatch])

  useEffect(() => {
    if (monthlyClosingCurrent) {
      const date = monthlyClosingCurrent?.from_term_month?.start_date
      dispatch(fetchExciseSettingsByDate(date))
    }
  }, [dispatch, monthlyClosingCurrent])

  const breadcrumb = <BreadcrumbNavigation sectionName={i18n.t('pages.accounting.JournalList.journal_list')} />

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <JournalListReport
        journals={convertJournalToTableFormat(journalList.data, exciseList || [])}
        totalRecords={journalList.total}
        exciseSettingByDate={exciseSettingByDate}
        monthlyClosingCurrent={monthlyClosingCurrent}
      />
    </MainTemplate>
  )
}

export default memo(JournalListReportPage)
