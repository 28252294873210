import styled from '@emotion/styled'

export const UsageStartFormWrapper = styled.div`
  padding: 10px;
  .range-date-input {
    margin-top: 8px;
  }
`

export const Field = styled.div`
  display: block;
`

export const ActionButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`

export const Label = styled.label`
  font-weight: bold;
`
