export const FETCH_CAUSES_REQUEST = 'FETCH_CAUSES_REQUEST'
export const FETCH_CAUSES_REQUEST_AUTH = 'FETCH_CAUSES_REQUEST_AUTH'
export const FETCH_CAUSES_SUCCESS = 'FETCH_CAUSES_SUCCESS'
export const FETCH_CAUSES_FAILURE = 'FETCH_CAUSES_FAILURE'

export const CREATE_CAUSE_REQUEST = 'CREATE_CAUSE_REQUEST'
export const UPDATE_CAUSE_REQUEST = 'UPDATE_CAUSE_REQUEST'

export const DELETE_CAUSE_REQUEST = 'DELETE_CAUSE_REQUEST'
export const DELETE_CAUSE_SUCCESS = 'DELETE_CAUSE_SUCCESS'
export const DELETE_CAUSE_FAILURE = 'DELETE_CAUSE_FAILURE'

export const HANDLE_SUBMIT_CAUSE_FAILURE = 'HANDLE_SUBMIT_CAUSE_FAILURE'
export const SUBMIT_CAUSE_SUCCESS = 'SUBMIT_CAUSE_SUCCESS'
export const SUBMIT_CAUSE_FAILURE = 'SUBMIT_CAUSE_FAILURE'
export const FETCH_CAUSE_REQUEST = 'FETCH_CAUSE_REQUEST'
export const FETCH_CAUSE_SUCCESS = 'FETCH_CAUSE_SUCCESS'
export const FETCH_CAUSE_FAILURE = 'FETCH_CAUSE_FAILURE'

export const EXPORT_CAUSE_REQUEST = 'EXPORT_CAUSE_REQUEST'
export const EXPORT_CAUSE_SUCCESS = 'EXPORT_CAUSE_SUCCESS'
export const EXPORT_CAUSE_FAILURE = 'EXPORT_CAUSE_FAILURE'

export const IMPORT_CAUSE_REQUEST = 'IMPORT_CAUSE_REQUEST'
export const IMPORT_CAUSE_SUCCESS = 'IMPORT_CAUSE_SUCCESS'
export const IMPORT_CAUSE_FAILURE = 'IMPORT_CAUSE_FAILURE'

export const CLEANUP_CAUSE = 'CLEANUP_CAUSE'
