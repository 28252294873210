import { LinkLabel } from 'aa_common/front-end/components'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetCodeStartNumberForm } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'
import { fetchAutoGenerateSettings } from 'store/settings/assetCode/actions'
import { selectAssetCodeAutoGenerateSettingData } from 'store/settings/assetCode/selectors'

const t = loadNs(['common', 'pages/settings/asset-code'])

const AssetCodeStartNumberPage: React.FC = () => {
  const dispatch = useDispatch()
  const assetCodeAutoGenerateSettingData = useSelector(selectAssetCodeAutoGenerateSettingData, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)

  const clickCancel = useCallback(() => {
    dispatch(changeLocation('/setting/asset-codes/auto-generate'))
  }, [dispatch])

  const cancelLink = useMemo(
    () => (
      <LinkLabel id="cancel-monthly-closing-setting" style={{ fontSize: 13, marginRight: 15 }} onClick={clickCancel}>
        {t('actions.cancel')}
      </LinkLabel>
    ),
    [clickCancel]
  )

  const breadcrumb = (
    <BreadcrumbNavigation
      enableBackButton={false}
      sectionName={t('start_number_setting')}
      customBackElement={cancelLink}
    />
  )

  useEffect(() => {
    !assetCodeAutoGenerateSettingData && dispatch(fetchAutoGenerateSettings())
    !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <AssetCodeStartNumberForm />
    </MainTemplate>
  )
}

export default AssetCodeStartNumberPage
