import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-btn-link:focus, .ant-btn-link:active {
    opacity: 0.5;
  }

  .link {
    color: #3B7DE9;
    width: '100%';
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #D4D8DD;
    padding: 7px 10px 7px;
    font-size: 14px;
    &:disabled {
      color: #DDD;
    }
  }
`
