import styled from '@emotion/styled'

export const Wrapper = styled.div`
  border: 1px solid #d4d8dd;
  border-radius: 4px;
  box-sizing: border-box;
  height: 54px;
  padding: 1px;
  position: relative;
  width: 54px;
  cursor: pointer;

  img {
    width: 49px;
    height: 49px;
  }
`
