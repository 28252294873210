import base from './base'

export const list = () => {
  return base.get('/v1/offices')
}

export const creatableList = () => {
  return base.get('/v1/creatable_offices')
}

export const post = (id: number) => {
  return base.post('/v1/offices', { id })
}

export const select = (data: any) => {
  return base.put('/v1/offices/switch', data)
}
