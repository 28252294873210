import { MainTemplate } from 'components/templates'
import i18n from 'i18n'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setFlagDisableNavigation } from 'store/app/actions'

import { Content, FirstContent, Wrapper } from './styles'

const ExpirationOfficePage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setFlagDisableNavigation(true))
    return () => {
      dispatch(setFlagDisableNavigation(false))
    }
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={null}>
      <Wrapper>
        <FirstContent>{i18n.t('pages.errorPages.expiration_office.text_1')}</FirstContent>
        <Content>{i18n.t('pages.errorPages.expiration_office.text_2')}</Content>
      </Wrapper>
    </MainTemplate>
  )
}

export default ExpirationOfficePage
