import { Button } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { DATE_FORMAT_RFC } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { Input } from 'components/atoms'
import { AutoCompleteSelect, DatePicker } from 'components/molecules'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import {
  DepartmentItem,
  DepartmentItemOnCloud,
  IDepartmentDataItem,
  LatestRecord,
  PrepressCategories,
} from 'store/settings/department/model'

import { getValidData } from '../AreaDetail/helpers'
import FormField from './FormField'
import { validateForm } from './helpers'
import { initialLatestRecordValue } from './index'
import { EditActionWrapper } from './styles'

const t = loadNs(['common', 'components/organisms/settings/department-form'])

interface Props {
  prepressCategories: PrepressCategories[]
  isValidTo: boolean
  isAddNewDepartment: boolean
  isTheNewestFormItem: boolean
  isEdit: boolean
  isEditLatestRecord: boolean
  loading: boolean
  isCopy: boolean
  initialValueModal: DepartmentItem
  latestRecord: LatestRecord
  formDataList: IDepartmentDataItem[]
  listDepartmentOnCloud: DepartmentItemOnCloud[]
  setLatestRecord: (latestRecord: LatestRecord) => void
}

const DepartmentDetailForm = ({
  prepressCategories,
  isValidTo,
  isTheNewestFormItem,
  isAddNewDepartment,
  isEdit,
  initialValueModal,
  formDataList,
  latestRecord,
  isEditLatestRecord,
  listDepartmentOnCloud,
  loading,
  isCopy,
  setLatestRecord,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [isLatestRecord, setIsLatestRecord] = useState(false)
  const [initialValues, setInitialValues] = useState<DepartmentItem | null>(null)

  useEffect(() => {
    const idLatestRecord = latestRecord?.recordItem?.id
    const idInitialValue = initialValueModal?.id
    const isLatestItem = idLatestRecord === idInitialValue && !!idLatestRecord

    setIsLatestRecord(isLatestItem)
    setInitialValues(initialValueModal)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (initialValueModal) {
      const dataValue = getValidData(initialValueModal, initialValues, formDataList)

      setFieldValue('name', initialValueModal.name)
      setFieldValue('search_key', initialValueModal.search_key)
      setFieldValue('prepress_category_code', initialValueModal?.prepress_category?.code)
      setFieldValue('ref_id', initialValueModal?.ref_id)
      dataValue.id !== null && setFieldValue('valid_to_max', dataValue.nextValidTo)

      switch (true) {
        case isEditLatestRecord:
          setFieldValue('valid_from_min', initialValueModal?.valid_from)
          break
        case dataValue.isIdAfter && dataValue.id !== null:
          setFieldValue('valid_from_min', dataValue.beforeValidFrom)
          break
        case dataValue.isIdBefore && dataValue.id === null:
          setFieldValue('valid_to_max', dataValue.validToMaxNotCopy)
          break
        case dataValue.id === null:
          setFieldValue('valid_from_min', dataValue.validFromMin)
          setFieldValue('valid_to_max', ifElse(dataValue.isValidToMax, dataValue.validToMaxValue, null))
          break
        default:
          setFieldValue('valid_from_min', null)
          break
      }
    }

    setLatestRecord({
      ...initialLatestRecordValue,
      recordItem: { ...initialValueModal, valid_to: initialValueModal?.valid_to },
    })
  }, [initialValueModal]) // eslint-disable-line

  useEffect(() => {
    isLatestRecord &&
      setLatestRecord({
        ...latestRecord,
        recordItem: values,
      })
  }, [values]) // eslint-disable-line

  useEffect(() => {
    if (isCopy) {
      const validToMax = moment(get(initialValues, 'valid_to_max')).format(DATE_FORMAT_RFC)
      const validFromMin = moment(get(initialValues, 'valid_from_min')).format(DATE_FORMAT_RFC)
      const isValidToMax = !!get(initialValues, 'valid_to_max')
      const validToMaxValue = ifElse(isValidToMax, validToMax, null)

      setFieldValue('valid_from_min', validFromMin)
      setFieldValue('ref_id', initialValueModal?.ref_id)
      setFieldValue('valid_to_max', ifElse(isValidToMax, validToMaxValue, null))
    }
  }, [isCopy]) // eslint-disable-line

  const handleChangeValidToInEditForm = (event: any) => {
    const newValidTo = event && event.format(DATE_FORMAT_RFC)
    const initialValidToValue = get(initialValueModal, 'valid_to')

    if (!isAddNewDepartment && isLatestRecord && initialValidToValue !== newValidTo) {
      setLatestRecord({
        recordItem: { ...initialValueModal, valid_to: newValidTo },
        validToLatestRecord: newValidTo,
        validFromLatestRecord: initialValueModal?.valid_from,
        isValidToChanged: true,
        validToBeforeChanged: initialValueModal?.valid_to,
      })
    }
  }

  const isValidFields = validateForm(values)

  return (
    <>
      <div style={{ padding: '0 15px' }}>
        <FormField required label={t('name')}>
          <Input name="name" className="input-modal" />
        </FormField>
        <FormField label={t('department_on_cloud')}>
          <AutoCompleteSelect
            wrapperStyle={{
              width: '240px',
            }}
            name="ref_id"
            placeholder=""
            items={listDepartmentOnCloud}
            valueField="id"
            textField="name"
          />
        </FormField>
        <FormField required label={t('prepress_category')}>
          <AutoCompleteSelect
            wrapperStyle={{
              width: '240px',
            }}
            name="prepress_category_code"
            placeholder={t('placeholders.prepress_category_placeholder')}
            items={prepressCategories}
            valueField="code"
            textField="name_jp"
          />
        </FormField>
        <FormField label={t('search_key')}>
          <Input name="search_key" className="input-modal" />
        </FormField>
      </div>
      <EditActionWrapper>
        <span style={{ marginRight: '5px' }}>
          {ifElse(!isValidTo && isEditLatestRecord, t('label.edit_start_date'), t('valid_from'))}
        </span>
        <DatePicker
          wrapperStyle="wrapper-date-picker"
          name="valid_from"
          placeholder={t('placeholders.valid_from_placeholder')}
          allowClear={false}
          minDateField="valid_from_min"
          maxDateField="valid_to"
        />
        <If condition={isAddNewDepartment || isValidTo || isEdit}>
          <>
            <span
              style={{
                marginRight: '5px',
                marginLeft: '15px',
              }}
            >
              {t('valid_to')}
            </span>
            <DatePicker
              wrapperStyle="wrapper-date-picker"
              name="valid_to"
              placeholder={t('placeholders.valid_to_placeholder')}
              unsetable={isTheNewestFormItem}
              allowClear={isTheNewestFormItem}
              minDateField="valid_from"
              maxDateField="valid_to_max"
              afterChanged={handleChangeValidToInEditForm}
            />
          </>
        </If>
        <Button color="blue" className="btn-save" size="custom" type="submit" disabled={loading || !isValidFields}>
          {t('actions.save')}
        </Button>
      </EditActionWrapper>
    </>
  )
}

export default memo(DepartmentDetailForm)
