import { BE_BASE_URL } from 'common/constants'
import { buildPaginationParams, objectToQueryParams } from 'common/helpers/request-helper'

import base from './base'
import { IExportIncreaseDecreaseReport, IFetchIncreaseDecreaseReport } from './model/report'

export const getIncreaseDecreaseReport: IFetchIncreaseDecreaseReport = payload => {
  return base.get('/v1/reports/increase_decrease_cost', {
    params: payload,
  })
}

export const exportIncreaseDecreaseReportURL: IExportIncreaseDecreaseReport = payload => {
  const params = objectToQueryParams(payload)
  return `${BE_BASE_URL}/v1/export/increase_decrease_cost?${params}`
}

export const getSummaryFixedAsset = (term_month_id: number) => {
  return base.get(`/v1/reports/summary_fixed_asset_events/by_term_month/${term_month_id}`)
}

export const getFixedAssetEvents = (
  termMonthId: number,
  changeSituationCode: number,
  pageNumber: number,
  pageSize: number
) => {
  const params = {
    term_month_id: termMonthId,
    change_situation_code: changeSituationCode,
    ...buildPaginationParams(pageNumber, pageSize),
  }

  return base.get('/v1/reports/fixed_asset_events', { params })
}

export const getUnderOverDepreciationReport = (payload: any) => {
  return base.get('/v1/reports/annual_depreciation', { params: payload })
}
