import { Map } from 'immutable'

import { AppActionTypes } from './action.model'
import {
  CHANGE_MENU_NAVIGATION,
  CLEAR_APP_MESSAGE,
  HIDE_IN_USED_MESSAGE,
  INIT_APP_FINISH,
  INIT_APP_START,
  SAVE_OPEN_SUBNAV_KEYS,
  SET_APP_MESSAGE,
  SET_FLAG_DISABLE_NAVIGATION,
  SET_LOADING_APP,
  SET_MENU_COLLAPSE,
  SET_MENU_OPEN_SUBNAV_KEYS,
  SHOW_IN_USED_MESSAGE,
} from './actions'

const initialState = Map({
  loading: false,
  initialSuccess: false,
  errors: [],
  message: null,
  navigation: Map({
    isFirstLoad: true,
    isCollapsed: false,
    selectedNavItem: null,
    openSubnavKeys: [],
    savedOpenSubnavKeys: null,
    isDisabled: false,
  }),
  inUsedMessage: null,
  loadingApp: false,
})

export default (state = initialState, action: AppActionTypes) => {
  switch (action.type) {
    case INIT_APP_START:
      return state.setIn(['loading'], true)
    case INIT_APP_FINISH:
      return state.set('loading', false).set('initialSuccess', true)
    case SET_MENU_COLLAPSE:
      return state.setIn(['navigation', 'isCollapsed'], action.payload)
    case CHANGE_MENU_NAVIGATION:
      if (action.payload.triggerFinishedFirstLoad) {
        return state
          .setIn(['navigation', 'selectedNavItem'], action.payload.location)
          .setIn(['navigation', 'isFirstLoad'], false)
      }
      return state.setIn(['navigation', 'selectedNavItem'], action.payload.location)
    case SET_MENU_OPEN_SUBNAV_KEYS:
      return state.setIn(['navigation', 'openSubnavKeys'], action.payload)
    case SAVE_OPEN_SUBNAV_KEYS:
      return state.setIn(['navigation', 'savedOpenSubnavKeys'], action.payload)
    case SET_APP_MESSAGE:
      return state.set('message', action.payload as any)
    case CLEAR_APP_MESSAGE:
      return state.set('message', null)
    case SHOW_IN_USED_MESSAGE:
      return state.setIn(['inUsedMessage'], action.payload)
    case HIDE_IN_USED_MESSAGE:
      return state.setIn(['inUsedMessage'], null)
    case SET_LOADING_APP:
      return state.set('loadingApp', action.payload)
    case SET_FLAG_DISABLE_NAVIGATION:
      return state.setIn(['navigation', 'isDisabled'], action.payload)
    default:
      return state
  }
}
