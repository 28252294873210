import { Tabs as TabsAnt } from 'antd'
import React, { CSSProperties } from 'react'

import { Wrapper } from './styles'

const { TabPane } = TabsAnt

type Props = {
  style?: CSSProperties
  activeKey?: string
  onTabClick?: (key: string) => void
  children?: React.ReactNode
}

interface TabsProps extends React.FC<Props> {
  TabPane: typeof TabPane
}

const Tabs: TabsProps = ({ children, style, ...props }: Props) => {
  return (
    <Wrapper style={style}>
      <TabsAnt {...props}>{children}</TabsAnt>
    </Wrapper>
  )
}

Tabs.TabPane = TabPane

export default Tabs
