import { DATE_FORMAT_RFC } from 'common/constants'
import { convertAttachmentsSubmitPayload, convertPhotosSubmitPayload } from 'common/helpers'
import { Term } from 'common/models'
import moment from 'moment'

export const convertSubmitPayload = ({ event_photos, event_attachments, impaired_at, ...rest }: any) => {
  return {
    ...rest,
    impaired_at: moment(impaired_at).format(DATE_FORMAT_RFC),
    event_photos: convertPhotosSubmitPayload(event_photos),
    event_attachments: convertAttachmentsSubmitPayload(event_attachments),
  }
}

export function findPreviousTermMonth(date: string, terms: Term[]) {
  const termMonths =
    (terms || []).find(({ start_date, end_date }) => start_date <= date && date <= end_date)?.term_months || []

  const idx = termMonths.findIndex(({ start_date, end_date }) => {
    return start_date <= date && date <= end_date
  })

  return termMonths[idx - 1] || {}
}
