import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Divider } from 'antd'
import { DefaultRadioGroupClass } from 'components/atoms/v2/RadioGroup/styles'

export const HorizontalBorder = styled(Divider)`
  &.ant-divider-horizontal {
    border-top: 1px solid #d4d8dd;
    margin: 15px 0;
  }
`

export const FormField = styled.div`
  display: flex;
  align-items: center;

  .field-label {
    margin-right: 10px;
  }

  &.term-year {
    .field-value {
      width: 130px;
    }
  }

  &.display-unit {
    .field-value {
      width: 90px;
    }
  }
`

export const SummaryLedgerClass = css`
  margin-bottom: 10px;
  .ant-radio-button-wrapper {
    margin-right: 10px;
    border-radius: 15px;
    background: #d4d8dd;
    color: #666666;

    &:last-child {
      border-radius: 15px;
      margin-right: 0;
    }

    &.ant-radio-button-wrapper-checked,
    &:hover {
      background: #3b7de9;
      color: white;
    }

    &:not(:first-child) {
      border-left-width: 1px;
      &::before {
        display: none;
      }
    }

    &.ant-radio-button-wrapper-disabled:hover {
      background: #d4d8dd;
    }
  }
`

export const MoneyUnitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .unit-label {
    margin-right: 10px;
  }
`

export const MoneyUnitClass = css`
  ${DefaultRadioGroupClass}
  .ant-radio-button-wrapper {
    min-width: 60px;
    text-align: center;
    color: #3b7de9;

    &:hover {
      border: 1px solid #3b7de9;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      margin-right: 0;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: #e0e5f2;
  }
`
