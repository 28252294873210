import { ConfirmModal } from 'aa_common/front-end/components'
import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants'
import { hasEventOccurred, parseDataForForm } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { FORM_MODES, LedgerSetting } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import AssetForm from 'components/organisms/assets/AssetForm'
import { validateRequiredField } from 'components/organisms/assets/AssetForm/helper'
import { getGeneralValidationErrors } from 'components/organisms/assets/AssetForm/validations/asset-schema'
import { MainTemplate } from 'components/templates'
import { get, isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupAssetItem, deleteUnusedAsset, fetchAsset, updateAsset } from 'store/asset/actions'
import {
  selectAssetItemData,
  selectAssetItemErrors,
  selectAssetItemLoading,
  selectEventHistories,
} from 'store/asset/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

const { t, useNsReady } = getNs(['pages/assets', 'components/organisms/assets/asset-form'])

const AssetEditPageOld: React.FC = () => {
  useNsReady()

  const { biid } = useParams<any>()
  const dispatch = useDispatch()

  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />

  const [showRequiredError, setShowRequiredError] = useState<boolean>(false)
  const errors = useSelector(selectAssetItemErrors, isEqual)
  const loading = useSelector(selectAssetItemLoading, isEqual)
  const assetData = useSelector(selectAssetItemData, isEqual)
  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)

  const [formValues, setFormValues] = useState<any>(null)
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false)
  const [submitedAsset, setSubmitedAsset] = useState<any>(null)

  useEffect(() => {
    const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)

    dispatch(fetchAsset(biid, hasDividingEvent))
    !ledgerSettings && dispatch(fetchLedgerSettingsList({}))

    return function cleanup() {
      dispatch(cleanupAssetItem())
    }
  }, []) // eslint-disable-line

  const handleSubmit = (values: any) => {
    setSubmitedAsset(values)
    if (validateRequiredField(values) || !!getGeneralValidationErrors().join(',')) {
      setShowRequiredError(true)
      return
    }
    if (get(values, 'usage_started_at')) {
      dispatch(updateAsset(biid, values))
    } else {
      setIsShowConfirm(true)
      setFormValues(values)
    }
  }

  const handleDelete = (biid: string) => {
    dispatch(deleteUnusedAsset(biid))
  }

  const parsedAssetDataForm = useMemo(() => {
    if (!assetData) return null

    return parseDataForForm({ ...assetData })
  }, [assetData])

  const handleCancelSubmit = () => {
    setIsShowConfirm(false)
    setFormValues(null)
  }

  const handleOkSubmit = () => {
    dispatch(updateAsset(biid, formValues))
    setIsShowConfirm(false)
  }

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      {assetData && (
        <AssetForm
          loading={loading}
          errors={errors}
          onSubmit={handleSubmit}
          initialAssetData={parsedAssetDataForm}
          formMode={FORM_MODES.EDIT}
          onDelete={handleDelete}
          showRequiredError={showRequiredError}
          asset={submitedAsset}
        />
      )}
      <ConfirmModal
        type={ConfirmModal.CONFIRM_TYPES.CONFIRM}
        title={t('update_confirm_title')}
        message={t('update_confirm_message')}
        visible={isShowConfirm}
        onCancel={handleCancelSubmit}
        onOK={handleOkSubmit}
      />
    </MainTemplate>
  )
}

export default AssetEditPageOld
