import { Modal } from 'antd'
import classNames from 'classnames'
import { CONFIRM_TYPES } from 'common/constants'
import { getNs } from 'common/i18n-config'
import React, { ComponentProps, useMemo } from 'react'

import { Button } from '../../../antd'
import { Actions, Content, Message, TitleHeader, Wrapper, WrapperClass } from './styles'

const { t, useNsReady } = getNs('components/molecules/modals/confirm-modal')

export const ConfirmModal = ({
  message,
  title,
  destroyOnClose,
  getContainer,
  visible,
  width = 540,
  okText,
  cancelText = t('cancel'),
  showCancelButton = true,
  type = CONFIRM_TYPES.DELETE,
  closable = false,
  onOK,
  onCancel,
  cancelStyle,
  okStyle,
  className,
  messageStyle,
  contentStyle,
  isLoading = false,
  ...rest
}: Props) => {
  useNsReady()
  const handleCancel = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    onCancel?.()
  }

  const handleOK = () => onOK?.()

  const renderTitle = useMemo(() => {
    switch (typeof title) {
      case 'string':
      case 'number': {
        return (
          <h4 className="modal-title" role="title">
            {title}
          </h4>
        )
      }
      case 'object':
        return title
      default:
        return null
    }
  }, [title])

  return (
    <Modal
      {...rest}
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={null}
      title={
        title && (
          <TitleHeader className={className} role="title2">
            {renderTitle}
          </TitleHeader>
        )
      }
      destroyOnClose={destroyOnClose}
      closable={closable}
      getContainer={getContainer}
      width={width}
      centered
      zIndex={9999}
      wrapClassName={classNames(className, WrapperClass({ closable }))}
      bodyStyle={{ padding: 0 }}
      confirmLoading={isLoading}
      data-testid="modal"
    >
      <Wrapper>
        <Content style={contentStyle}>
          <Message style={messageStyle}>{message}</Message>
        </Content>
        <Actions>
          {showCancelButton && (
            <Button disabled={isLoading} color="grey" style={cancelStyle} className="btn-cancel" onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          {type === CONFIRM_TYPES.DELETE && (
            <Button disabled={isLoading} style={cancelStyle} color="grey" className="btn-delete" onClick={handleOK}>
              {okText || t('delete')}
            </Button>
          )}
          {type === CONFIRM_TYPES.CONFIRM && (
            <Button disabled={isLoading} style={okStyle} color="blue" className="btn-confirm" onClick={handleOK}>
              {okText || t('ok')}
            </Button>
          )}
        </Actions>
      </Wrapper>
    </Modal>
  )
}

ConfirmModal.CONFIRM_TYPES = CONFIRM_TYPES

type Props = Partial<ComponentProps<typeof Modal>> & {
  message: React.ReactNode
  title?: React.ReactNode
  visible: boolean
  destroyOnClose?: boolean
  showCancelButton?: boolean
  isLoading?: boolean
  width?: number
  className?: string
  getContainer?: any
  type?: CONFIRM_TYPES
  okText?: string
  cancelText?: string
  closable?: boolean
  onOK: () => void
  onCancel: () => void
  cancelStyle?: React.CSSProperties
  okStyle?: React.CSSProperties
  messageStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
}
