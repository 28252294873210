/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MasterData<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description get master data
   *
   * @tags master_data
   * @name MasterDataList
   * @summary Get the master data
   * @request GET:/master_data
   */
  masterDataList = (
    query?: {
      /** MasterData Name */
      'filter[name]'?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterResponse, PresenterResponse>({
      path: `/master_data`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
