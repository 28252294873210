import { Tooltip, Typography } from 'antd'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { DefaultTooltipOverlay } from '../../styles/common-style'
import { LinkLabelProps } from './model'
import { StyledLink } from './styles'

const { Text } = Typography

const LinkLabel: React.FC<PropsWithChildren<LinkLabelProps>> = ({
  style,
  type,
  className,
  disabled,
  enableEllipsis,
  children,
  id,
  showTooltipMessage,
  tooltipSettings,
  href,
  target,
  onClick,
}) => {
  const renderLink = () => {
    if (enableEllipsis) {
      return (
        <Text style={{ color: 'inherit' }} ellipsis>
          {children}
        </Text>
      )
    }
    return children
  }

  const coreLink = () => (
    <StyledLink
      id={id}
      type={type}
      disabled={disabled}
      className={classNames('aa-link', className)}
      style={style}
      {...(href ? { href, target } : { onClick })}
    >
      {renderLink()}
    </StyledLink>
  )

  return showTooltipMessage ? (
    <Tooltip
      overlayClassName={classNames(DefaultTooltipOverlay, 'aa-link-tooltip', tooltipSettings?.overlayClassName)}
      placement={tooltipSettings?.placement}
      overlayStyle={tooltipSettings?.overlayStyle}
      destroyTooltipOnHide
      title={showTooltipMessage}
    >
      {coreLink()}
    </Tooltip>
  ) : (
    coreLink()
  )
}

export default LinkLabel
