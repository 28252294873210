import { UnborderCollapse } from 'aa_common/front-end/components'
import { MoneyInput } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import React from 'react'

import { Group } from '../../events/styles'

const { Item } = UnborderCollapse

type Props = {
  contentItemWidth: number
  itemWidth: string
  isDisposalCostIsZero: boolean
  labels: {
    disposal_cost_excluded_tax: string
    excise: string
  }
}

export const DisposalCostInputTaxAndExcise: React.FC<Props> = ({
  labels,
  contentItemWidth,
  itemWidth,
  isDisposalCostIsZero,
}) => {
  return (
    <>
      <Item label={labels.disposal_cost_excluded_tax} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
        <Group>
          <MoneyInput name="disposal_cost_input" style={{ width: 240 }} />
        </Group>
      </Item>
      <Item label={labels.excise} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
        <Group>
          <ExciseSelect name="disposal_excise_id" style={{ width: 240 }} isDisabled={isDisposalCostIsZero} />
        </Group>
      </Item>
    </>
  )
}
