import styled from '@emotion/styled'

export const Wrapper = styled.div`
  background-color: #ffeeeb;
  border: 2px solid #f57575;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ec4949;
  padding: 11px 10px;
  margin-bottom: 10px;
`

export const Title = styled.div`
  color: #ec4949;
  margin-bottom: 21px;
`

export const Error = styled.div`
  margin-bottom: 5px;
  font-size: 13px;
  color: #ec4949;
  white-space: pre-line;

  &:last-of-type {
    margin-bottom: 0;
  }
`
