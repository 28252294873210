/* eslint-disable no-restricted-syntax */
import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { mapCoordinatesWithData } from '../helpers'

export const AssetInformation = ({ assets, isAllFixedAssetMode }: Props) => {
  const keys = [
    'asset_type_for_depreciable_property_tax_code',
    'name',
    'quantity',
    'acquiredAt',
    'acquisitionCost',
    'service_life',
    'depreciation_residual_rate',
    'residual_amount',
    'special_rate',
    'tax_standard_amount',
  ]

  const getObjectValueByKeys = (obj: any, keys: any) => {
    return keys.map((key: any) => {
      const nestedKeys = key.split('.')
      let value = obj

      for (const nestedKey of nestedKeys) {
        if (typeof value[nestedKey] === 'object') {
          value = Object.values(value[nestedKey])
        } else {
          value = value && value[nestedKey]
        }
      }

      return value
    })
  }

  const convertObjToArray = () => {
    return assets?.map((asset: any) => getObjectValueByKeys(asset, keys).flat())
  }

  // x coordinate will correspond to each column
  // y coordinate will correspond to each row
  // As you can see, we will have 12 columns and 6 rows such as zones 4, 5, 6, 7 on ticket https://moneyforwardvietnam.atlassian.net/browse/AA-9345
  const yCoordinates = [
    96, 117, 139, 160, 181, 203, 224, 246, 267, 288, 310, 331, 353, 374, 395, 417, 438, 460, 481, 502,
  ]
  const clsName = isAllFixedAssetMode ? 'show' : 'hide'
  const cellConfig = [
    { x: 103, w: 19 },
    { x: 127, w: 193, className: 'text-left' },
    { x: 320, w: 22 },
    { x: 342, w: 13 },
    { x: 354, w: 20 },
    { x: 374, w: 19 },
    { x: 393, w: 23 },
    { x: 415, w: 24 },
    { x: 438, w: 23 },
    { x: 461, w: 24 },
    { x: 484, w: 20 },
    { x: 503, w: 40, className: clsName },
    { x: 542, w: 22, className: clsName },
    { x: 564, w: 22, className: clsName },
    { x: 585, w: 22, className: clsName },
    { x: 606, w: 22, className: clsName },
    { x: 628, w: 29, className: clsName },
    { x: 671, w: 24, className: clsName },
    { x: 694, w: 22, className: clsName },
    { x: 715, w: 22, className: clsName },
    { x: 736, w: 22, className: clsName },
  ]

  const data = mapCoordinatesWithData(convertObjToArray(), yCoordinates, cellConfig)
  return (
    <>
      {data.map(({ x, y, w, value, className }) => (
        <g key={uniqueId()}>
          <foreignObject x={x} y={y} width={w} height="21">
            <div className={className}>{value}</div>
          </foreignObject>
        </g>
      ))}
    </>
  )
}

type Props = { assets: any; isAllFixedAssetMode: boolean }
