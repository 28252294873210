import { LEDGER_PURPOSE, Permission } from 'common/constants'
import { Asset, AssetLedger, LedgerSettingsItem, Term } from 'common/models'

export type AssetDividingProps = {
  asset: any
  errors?: any
  causes: any[]
  isShow: boolean
  onHandleReloadCauses?: () => void
  setShowForm: (isShow: boolean) => void
  handleSubmit: (values: any) => void
  defaultValues: AssetDividingFormProps
  ledgerSettings: LedgerSettingsItem[]
  terms?: Term[]
  permissions?: Permission
  exciseTaxMethod: number
  isLoading: boolean
}
export type AssetDividingFormProps = {
  event_cause_id?: number | null
  divided_at?: moment.Moment | null
  asset_name_kana_des?: string
  divided_asset_code: string
  new_code: string
  new_branch_code: string
  divided_asset_name: string
  new_asset_name: string
  divided_asset_name_kana: string
  new_asset_name_kana: string
  divided_acquisition_cost: number | null
  new_acquisition_cost: number | string | null
  divided_excise_amount: number | undefined | null
  new_excise_amount: number | null
  divided_acquisition_cost_tax_included: number | null
  new_acquisition_cost_tax_included: number | null
  new_quantity?: number
  divided_quantity?: number
  divided_memorandum_value?: number
  new_memorandum_value?: number | null
  divided_residual_amount?: number
  new_residual_amount?: number
  event_photos?: any
  event_attachments?: any
  event_urls?: string | null
  ledgers?: Ledger[]
}

export type BookDateItem = {
  content: string
  ledger_setting_id: number | string
  name?: string
}
export type BookValueItem = {
  value: string | number
  ledger_setting_id: number | string
  name?: string
}
export type DividingBookingDataProps = {
  bookDate: BookDateItem[]
  bookValue: BookValueItem[]
}

export type FormContentProps = {
  causes: any[]
  onReloadCauses?: () => void
  initValue?: AssetDividingFormProps
  isShow: boolean
  originalAcquisitionCost: number
  setShowForm: (isShow: boolean) => void
}

export const FIELDS = {
  event_cause_id: 'event_cause_id',
  divided_at: 'divided_at',
  asset_name_kana_des: 'asset_name_kana_des',
  dividing_method: 'dividing_method',
  divided_asset_code: 'divided_asset_code',
  new_code: 'new_code',
  new_branch_code: 'new_branch_code',
  divided_asset_name: 'divided_asset_name',
  new_asset_name: 'new_asset_name',
  divided_asset_name_kana: 'divided_asset_name_kana',
  new_asset_name_kana: 'new_asset_name_kana',
  divided_acquisition_cost: 'divided_acquisition_cost',
  new_acquisition_cost: 'new_acquisition_cost',
  divided_excise_amount: 'divided_excise_amount',
  new_excise_amount: 'new_excise_amount',
  divided_acquisition_cost_tax_included: 'divided_acquisition_cost_tax_included',
  new_acquisition_cost_tax_included: 'new_acquisition_cost_tax_included',
  new_quantity: 'new_quantity',
  divided_quantity: 'divided_quantity',
  divided_memorandum_value: 'divided_memorandum_value',
  memorandum_value: 'memorandum_value',
  divided_residual_amount: 'divided_residual_amount',
  residual_amount: 'residual_amount',
  event_photos: 'event_photos',
  event_attachments: 'event_attachments',
  event_urls: 'event_urls',
  ledgers: 'ledgers',
}
export type DividingStoreSchema = {
  asset?: Asset
  terms?: Term[]
  permissions?: Permission
  exciseTaxMethod?: number
  dividingAt?: moment.Moment | null
  setDividingAt?: any
}

export type LegedItem = {
  ledger_setting_id: number
  date: string
  book_value: number
  name?: string
}

export type StandDate = {
  ledger_setting_id: number
  ledger_purpose: LEDGER_PURPOSE
  standard_date: string
}

export type Ledger = Pick<
  AssetLedger,
  'ledger_setting_id' | 'depreciation_method_code' | 'memorandum_value' | 'residual_amount'
>
