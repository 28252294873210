import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import i18n from 'i18n'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentOffice, selectUserInfo } from 'store/session/selectors'

import { Button } from './styles'

const { REACT_APP_ACC_PLUS } = process.env

interface AccountingPlusLinkProps {
  path: string
  className?: string
  style?: React.CSSProperties
}

const AccountingPlusLink: React.FC<AccountingPlusLinkProps> = ({ path, children, style, className }) => {
  const { data: userInfo } = useSelector(selectUserInfo)
  const { data: currentOffice } = useSelector(selectCurrentOffice)

  const accPlusLink = useMemo(() => {
    const queryParams = new URLSearchParams({
      tenant_uid: `${currentOffice?.id}`,
      login_hint: encodeURI(userInfo?.email),
      redirect_path: encodeURI(path),
    })

    return `${REACT_APP_ACC_PLUS}/sso?${queryParams.toString()}`
  }, [path, userInfo, currentOffice])

  return (
    <Button
      type="link"
      href={accPlusLink}
      target="_blank"
      rel="noreferrer"
      className={classNames('acc-plus', className)}
      style={style}
    >
      {children || (
        <span>
          <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: 5 }} />
          {i18n.t('common.acc_plus')}
        </span>
      )}
    </Button>
  )
}

export default AccountingPlusLink
