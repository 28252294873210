import { loadNs } from 'common/i18n-config'
import { JournalItemSettingTable } from 'components/templates'
import React from 'react'

import { BaseTableViewProps, renderAccountNameFieldValue } from './helper'
import { Row } from './row'

const t = loadNs(['components/organisms/accounting/auto-journal-setting-tab'])
const GetSettingTableView = ({ item }: BaseTableViewProps) => {
  return (
    <JournalItemSettingTable title={t('get_settings_table')}>
      <tbody>
        <Row
          title={t('asset_category')}
          item={item}
          render={renderAccountNameFieldValue}
          fields={[
            { name: 'acquisition_debit_asset_category_account_item.name' },
            { name: 'acquisition_debit_asset_category_account_sub_item.name' },
            { name: '', isThead: true, title: t('payment_category') },
            { name: 'acquisition_credit_payment_category_account_item.name' },
            { name: 'acquisition_credit_payment_category_account_sub_item.name' },
          ]}
        />
      </tbody>
    </JournalItemSettingTable>
  )
}

export default GetSettingTableView
