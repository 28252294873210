import { Map } from 'immutable'
import { find, isArray } from 'lodash'

import { LedgerSettingsActionTypes } from './action.model'
import {
  CLEANUP_LEDGER_SETTINGS,
  FETCH_LEDGER_SETTINGS_LIST_FAILURE,
  FETCH_LEDGER_SETTINGS_LIST_REQUEST,
  FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH,
  FETCH_LEDGER_SETTINGS_LIST_SUCCESS,
  SAVE_HAS_ACCOUNTANT_AND_TAX_LEDGER,
  SAVE_LEDGER_SETTINGS_LIST_FAILURE,
  SAVE_LEDGER_SETTINGS_LIST_REQUEST,
  SAVE_LEDGER_SETTINGS_LIST_SUCCESS,
} from './constant'

const initialState = Map({
  hasAccountantAndTaxLedger: null as boolean | null,
  list: Map({
    data: null,
    loading: false,
    errors: null,
  }),
})

export default (state = initialState, action: LedgerSettingsActionTypes) => {
  switch (action.type) {
    case FETCH_LEDGER_SETTINGS_LIST_REQUEST:
    case FETCH_LEDGER_SETTINGS_LIST_REQUEST_AUTH:
      return state.setIn(['list', 'loading'], true)
    case FETCH_LEDGER_SETTINGS_LIST_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case FETCH_LEDGER_SETTINGS_LIST_FAILURE:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], null)
    case SAVE_LEDGER_SETTINGS_LIST_REQUEST: {
      // Clear errors of each items
      const updatedItems = action.payload.items.map(item => ({ ...item, errors: null }))
      return state.setIn(['list', 'loading'], true).setIn(['list', 'data'], updatedItems)
    }
    case SAVE_LEDGER_SETTINGS_LIST_SUCCESS:
      return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], action.payload)
    case SAVE_LEDGER_SETTINGS_LIST_FAILURE: {
      if (isArray(action.errors)) {
        // Handling form error
        const updatedItems = action.payload.items.map((item, index) => {
          const errors = find(action.errors, (item: any) => item.key === index)

          return {
            ...item,
            errors: errors ? errors.fields : null,
          }
        })
        return state.setIn(['list', 'loading'], false).setIn(['list', 'data'], updatedItems)
      }
      return state.setIn(['list', 'loading'], false)
    }
    case SAVE_HAS_ACCOUNTANT_AND_TAX_LEDGER:
      return state.set('hasAccountantAndTaxLedger', action.payload)
    case CLEANUP_LEDGER_SETTINGS:
      return state.setIn(['list'], initialState.getIn(['list']))
    default:
      return state
  }
}
