import { pick } from 'lodash'
import React from 'react'
import { Control, useWatch } from 'react-hook-form'

import Form from '../index'

export const SubmitArea = ({ formControl, loading, submitText, requiredFields = ['name'] }: Props) => {
  const formValues = useWatch({
    control: formControl,
  })
  const { SubmitButton } = Form
  const watchFormValues = pick(formValues, requiredFields)
  const isValid = Object.values(watchFormValues).every(
    value => value && (!Array.isArray(value) ? !!value || !!value?.trim() : value.length > 0)
  )

  return <SubmitButton isDisableBtn={!isValid || loading} submitText={submitText} />
}

export default SubmitArea

interface Props {
  formControl: Control<Record<string, any>>
  loading?: boolean
  submitText?: any
  requiredFields: Array<string>
}
