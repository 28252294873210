import moment, { Moment } from 'moment'

export function checkIsToday(date: Moment | string) {
  return !!date && moment(date).isSame(moment(), 'date')
}

export const checkTheSameDate = (date1: Moment | string, date2: Moment | string) => {
  return !!(date1 && date2) && moment(date1).isSame(moment(date2), 'date')
}

export const checkIsAfterDate = (date1: Moment | string, date2: Moment | string) => {
  return !!(date1 && date2) && moment(date1).isAfter(date2, 'date')
}

export const checkIsBeforeDate = (date1: Moment | string, date2: Moment | string) => {
  return !!(date1 && date2) && moment(date1).isBefore(date2, 'date')
}

export const checkIsAfterOrEqualDate = (date1: Moment | string, date2: Moment | string) => {
  return !!(date1 && date2) && moment(date1).isSameOrAfter(date2, 'date')
}
