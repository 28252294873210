import { initSlice } from 'aa_common/front-end/zustand'
import { produce } from 'immer'

type LongPolling = {
  [key: string]: string
}
const initData: LongPolling = {}
export const longPollingSlice = initSlice<LongPolling>('longPolling', initData, {
  isPersist: true,
})

export const removePollingByKey = (keyToRemove: string) => {
  longPollingSlice.setState(
    (data: LongPolling) => {
      const result = produce(data, (draft: LongPolling) => {
        delete draft[keyToRemove]
      })
      return result
    },
    'removeJobId',
    true
  )
}
